/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AcceptInvitationRequest,
  BaseRequestRequest,
  Category,
  CheckEmailAvailabilityRequest,
  CheckEmailAvailabilityResponse,
  Checkout,
  CheckoutMergeRequest,
  CheckoutRequest,
  CustomerAccount,
  CustomerAccountInvitation,
  CustomerAccountInvitationDetail,
  CustomerAccountInvitationRequest,
  CustomerAccountMembership,
  CustomerAccountMembershipManagement,
  CustomerAccountMembershipManagementRequest,
  CustomerAccountRelation,
  CustomerAccountRelationCreateRequest,
  CustomerAccountRelationRequest,
  CustomerAccountSettings,
  CustomerAccountSettingsRequest,
  CustomerAddress,
  CustomerAddressRequest,
  CustomerAgent,
  CustomerAgentCreateRequest,
  CustomerAgentRequest,
  CustomerContactInfo,
  CustomerContactInfoRequest,
  CustomerCreditLimit,
  Discount,
  DiscountRequestRequest,
  DRFAttributeBanner,
  ExternalRequest,
  FieldType,
  ImpersonateRequest,
  LoanApplicationRequest,
  LoanCalculationResponse,
  Login,
  LoginRequest,
  OptionsResponse,
  PaginatedCheckoutList,
  PaginatedProductInventoryList,
  PaginatedProductListList,
  PatchedCheckoutRequest,
  PatchedCustomerAccountInvitationRequest,
  PatchedCustomerAccountMembershipManagementRequest,
  PatchedCustomerAccountRelationRequest,
  PatchedCustomerAccountSettingsRequest,
  PatchedCustomerAddressRequest,
  PatchedCustomerAgentRequest,
  PatchedCustomerContactInfoRequest,
  PaymentToken,
  PaymentTokenRequest,
  PolledLoginRequest,
  PollLogin,
  Prepayment,
  ProductDetail,
  ProductInventory,
  ProductSync,
  ProductSyncRequest,
  RemittanceSlips,
  RemittanceSlipsRequestRequest,
  SalesInvoice,
  SalesInvoiceDetail,
  SalesInvoiceRequestRequest,
  SalesTransaction,
  SetEmailRequest,
  SyncMessage,
  SyncMessageRequest,
  User,
  VerifyEmailRequest,
  VirtualCard,
  VirtualCardRequest,
  Warehouse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags account-settings
   * @name AccountSettingsRetrieve
   * @request GET:/api/account-settings/
   * @secure
   * @response `200` `CustomerAccountSettings`
   */
  accountSettingsRetrieve = (params: RequestParams = {}) =>
    this.request<CustomerAccountSettings, any>({
      path: `/api/account-settings/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags account-settings
   * @name AccountSettingsUpdate
   * @request PUT:/api/account-settings/
   * @secure
   * @response `200` `CustomerAccountSettings`
   */
  accountSettingsUpdate = (data: CustomerAccountSettingsRequest, params: RequestParams = {}) =>
    this.request<CustomerAccountSettings, any>({
      path: `/api/account-settings/`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags account-settings
   * @name AccountSettingsPartialUpdate
   * @request PATCH:/api/account-settings/
   * @secure
   * @response `200` `CustomerAccountSettings`
   */
  accountSettingsPartialUpdate = (data: PatchedCustomerAccountSettingsRequest, params: RequestParams = {}) =>
    this.request<CustomerAccountSettings, any>({
      path: `/api/account-settings/`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsList
   * @request GET:/api/accounts/
   * @secure
   * @response `200` `(CustomerAccount)[]`
   */
  accountsList = (params: RequestParams = {}) =>
    this.request<CustomerAccount[], any>({
      path: `/api/accounts/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsRetrieve
   * @request GET:/api/accounts/{ssn}/
   * @secure
   * @response `200` `CustomerAccount`
   */
  accountsRetrieve = (ssn: string, params: RequestParams = {}) =>
    this.request<CustomerAccount, any>({
      path: `/api/accounts/${ssn}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsAddressesList
   * @request GET:/api/accounts/addresses/
   * @secure
   * @response `200` `(CustomerAddress)[]`
   */
  accountsAddressesList = (params: RequestParams = {}) =>
    this.request<CustomerAddress[], any>({
      path: `/api/accounts/addresses/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsAddressesCreate
   * @request POST:/api/accounts/addresses/
   * @secure
   * @response `201` `CustomerAddress`
   */
  accountsAddressesCreate = (data: CustomerAddressRequest, params: RequestParams = {}) =>
    this.request<CustomerAddress, any>({
      path: `/api/accounts/addresses/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsAddressesRetrieve
   * @request GET:/api/accounts/addresses/{id}/
   * @secure
   * @response `200` `CustomerAddress`
   */
  accountsAddressesRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<CustomerAddress, any>({
      path: `/api/accounts/addresses/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsAddressesUpdate
   * @request PUT:/api/accounts/addresses/{id}/
   * @secure
   * @response `200` `CustomerAddress`
   */
  accountsAddressesUpdate = (id: number, data: CustomerAddressRequest, params: RequestParams = {}) =>
    this.request<CustomerAddress, any>({
      path: `/api/accounts/addresses/${id}/`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsAddressesPartialUpdate
   * @request PATCH:/api/accounts/addresses/{id}/
   * @secure
   * @response `200` `CustomerAddress`
   */
  accountsAddressesPartialUpdate = (id: number, data: PatchedCustomerAddressRequest, params: RequestParams = {}) =>
    this.request<CustomerAddress, any>({
      path: `/api/accounts/addresses/${id}/`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsAddressesDestroy
   * @request DELETE:/api/accounts/addresses/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  accountsAddressesDestroy = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/accounts/addresses/${id}/`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * @description Sets address as a primary address and removes primary status of existing addresses (if any) for the users account. The response contains all addresses.
   *
   * @tags accounts
   * @name AccountsAddressesSetPrimaryCreate
   * @request POST:/api/accounts/addresses/{id}/set-primary/
   * @secure
   * @response `200` `(CustomerAddress)[]`
   */
  accountsAddressesSetPrimaryCreate = (id: number, params: RequestParams = {}) =>
    this.request<CustomerAddress[], any>({
      path: `/api/accounts/addresses/${id}/set-primary/`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsAgentsList
   * @request GET:/api/accounts/agents/
   * @secure
   * @response `200` `(CustomerAgent)[]`
   */
  accountsAgentsList = (params: RequestParams = {}) =>
    this.request<CustomerAgent[], any>({
      path: `/api/accounts/agents/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsAgentsCreate
   * @request POST:/api/accounts/agents/
   * @secure
   * @response `201` `CustomerAgent`
   */
  accountsAgentsCreate = (data: CustomerAgentCreateRequest, params: RequestParams = {}) =>
    this.request<CustomerAgent, any>({
      path: `/api/accounts/agents/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsAgentsRetrieve
   * @request GET:/api/accounts/agents/{id}/
   * @secure
   * @response `200` `CustomerAgent`
   */
  accountsAgentsRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<CustomerAgent, any>({
      path: `/api/accounts/agents/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsAgentsUpdate
   * @request PUT:/api/accounts/agents/{id}/
   * @secure
   * @response `200` `CustomerAgent`
   */
  accountsAgentsUpdate = (id: number, data: CustomerAgentRequest, params: RequestParams = {}) =>
    this.request<CustomerAgent, any>({
      path: `/api/accounts/agents/${id}/`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsAgentsPartialUpdate
   * @request PATCH:/api/accounts/agents/{id}/
   * @secure
   * @response `200` `CustomerAgent`
   */
  accountsAgentsPartialUpdate = (id: number, data: PatchedCustomerAgentRequest, params: RequestParams = {}) =>
    this.request<CustomerAgent, any>({
      path: `/api/accounts/agents/${id}/`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsContactInfoList
   * @request GET:/api/accounts/contact-info/
   * @secure
   * @response `200` `(CustomerContactInfo)[]`
   */
  accountsContactInfoList = (params: RequestParams = {}) =>
    this.request<CustomerContactInfo[], any>({
      path: `/api/accounts/contact-info/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsContactInfoCreate
   * @request POST:/api/accounts/contact-info/
   * @secure
   * @response `201` `CustomerContactInfo`
   */
  accountsContactInfoCreate = (data: CustomerContactInfoRequest, params: RequestParams = {}) =>
    this.request<CustomerContactInfo, any>({
      path: `/api/accounts/contact-info/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsContactInfoRetrieve
   * @request GET:/api/accounts/contact-info/{id}/
   * @secure
   * @response `200` `CustomerContactInfo`
   */
  accountsContactInfoRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<CustomerContactInfo, any>({
      path: `/api/accounts/contact-info/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsContactInfoUpdate
   * @request PUT:/api/accounts/contact-info/{id}/
   * @secure
   * @response `200` `CustomerContactInfo`
   */
  accountsContactInfoUpdate = (id: number, data: CustomerContactInfoRequest, params: RequestParams = {}) =>
    this.request<CustomerContactInfo, any>({
      path: `/api/accounts/contact-info/${id}/`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsContactInfoPartialUpdate
   * @request PATCH:/api/accounts/contact-info/{id}/
   * @secure
   * @response `200` `CustomerContactInfo`
   */
  accountsContactInfoPartialUpdate = (
    id: number,
    data: PatchedCustomerContactInfoRequest,
    params: RequestParams = {},
  ) =>
    this.request<CustomerContactInfo, any>({
      path: `/api/accounts/contact-info/${id}/`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsContactInfoDestroy
   * @request DELETE:/api/accounts/contact-info/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  accountsContactInfoDestroy = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/accounts/contact-info/${id}/`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsCreditLimitRetrieve
   * @request GET:/api/accounts/credit-limit/
   * @secure
   * @response `200` `CustomerCreditLimit`
   */
  accountsCreditLimitRetrieve = (params: RequestParams = {}) =>
    this.request<CustomerCreditLimit, any>({
      path: `/api/accounts/credit-limit/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsInvitationsList
   * @request GET:/api/accounts/invitations/
   * @secure
   * @response `200` `(CustomerAccountInvitation)[]`
   */
  accountsInvitationsList = (params: RequestParams = {}) =>
    this.request<CustomerAccountInvitation[], any>({
      path: `/api/accounts/invitations/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsInvitationsCreate
   * @request POST:/api/accounts/invitations/
   * @secure
   * @response `201` `CustomerAccountInvitation`
   */
  accountsInvitationsCreate = (data: CustomerAccountInvitationRequest, params: RequestParams = {}) =>
    this.request<CustomerAccountInvitation, any>({
      path: `/api/accounts/invitations/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsInvitationsRetrieve
   * @request GET:/api/accounts/invitations/{id}/
   * @secure
   * @response `200` `CustomerAccountInvitation`
   */
  accountsInvitationsRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<CustomerAccountInvitation, any>({
      path: `/api/accounts/invitations/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsInvitationsUpdate
   * @request PUT:/api/accounts/invitations/{id}/
   * @secure
   * @response `200` `CustomerAccountInvitation`
   */
  accountsInvitationsUpdate = (id: number, data: CustomerAccountInvitationRequest, params: RequestParams = {}) =>
    this.request<CustomerAccountInvitation, any>({
      path: `/api/accounts/invitations/${id}/`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsInvitationsPartialUpdate
   * @request PATCH:/api/accounts/invitations/{id}/
   * @secure
   * @response `200` `CustomerAccountInvitation`
   */
  accountsInvitationsPartialUpdate = (
    id: number,
    data: PatchedCustomerAccountInvitationRequest,
    params: RequestParams = {},
  ) =>
    this.request<CustomerAccountInvitation, any>({
      path: `/api/accounts/invitations/${id}/`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsInvitationsDestroy
   * @request DELETE:/api/accounts/invitations/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  accountsInvitationsDestroy = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/accounts/invitations/${id}/`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsInvitationsResendCreate
   * @request POST:/api/accounts/invitations/{id}/resend/
   * @secure
   * @response `200` `CustomerAccountInvitation`
   */
  accountsInvitationsResendCreate = (id: number, params: RequestParams = {}) =>
    this.request<CustomerAccountInvitation, any>({
      path: `/api/accounts/invitations/${id}/resend/`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsInvitationsAcceptCreate
   * @request POST:/api/accounts/invitations/accept/
   * @secure
   * @response `200` `Login`
   */
  accountsInvitationsAcceptCreate = (data: AcceptInvitationRequest, params: RequestParams = {}) =>
    this.request<Login, any>({
      path: `/api/accounts/invitations/accept/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsInvitationsDetailsRetrieve
   * @request GET:/api/accounts/invitations/details/
   * @secure
   * @response `200` `CustomerAccountInvitationDetail`
   */
  accountsInvitationsDetailsRetrieve = (query: { token: string }, params: RequestParams = {}) =>
    this.request<CustomerAccountInvitationDetail, any>({
      path: `/api/accounts/invitations/details/`,
      method: "GET",
      query: query || { format: "json" },
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsMembershipsList
   * @request GET:/api/accounts/memberships/
   * @secure
   * @response `200` `(CustomerAccountMembership)[]`
   */
  accountsMembershipsList = (params: RequestParams = {}) =>
    this.request<CustomerAccountMembership[], any>({
      path: `/api/accounts/memberships/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsMembershipsRetrieve
   * @request GET:/api/accounts/memberships/{id}/
   * @secure
   * @response `200` `CustomerAccountMembership`
   */
  accountsMembershipsRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<CustomerAccountMembership, any>({
      path: `/api/accounts/memberships/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsMembershipsSwitchAccountCreate
   * @request POST:/api/accounts/memberships/{id}/switch-account/
   * @secure
   * @response `200` `Login`
   */
  accountsMembershipsSwitchAccountCreate = (id: number, params: RequestParams = {}) =>
    this.request<Login, any>({
      path: `/api/accounts/memberships/${id}/switch-account/`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsMembershipsManagementList
   * @request GET:/api/accounts/memberships/management/
   * @secure
   * @response `200` `(CustomerAccountMembershipManagement)[]`
   */
  accountsMembershipsManagementList = (params: RequestParams = {}) =>
    this.request<CustomerAccountMembershipManagement[], any>({
      path: `/api/accounts/memberships/management/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsMembershipsManagementRetrieve
   * @request GET:/api/accounts/memberships/management/{id}/
   * @secure
   * @response `200` `CustomerAccountMembershipManagement`
   */
  accountsMembershipsManagementRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<CustomerAccountMembershipManagement, any>({
      path: `/api/accounts/memberships/management/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsMembershipsManagementUpdate
   * @request PUT:/api/accounts/memberships/management/{id}/
   * @secure
   * @response `200` `CustomerAccountMembershipManagement`
   */
  accountsMembershipsManagementUpdate = (
    id: number,
    data: CustomerAccountMembershipManagementRequest,
    params: RequestParams = {},
  ) =>
    this.request<CustomerAccountMembershipManagement, any>({
      path: `/api/accounts/memberships/management/${id}/`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsMembershipsManagementPartialUpdate
   * @request PATCH:/api/accounts/memberships/management/{id}/
   * @secure
   * @response `200` `CustomerAccountMembershipManagement`
   */
  accountsMembershipsManagementPartialUpdate = (
    id: number,
    data: PatchedCustomerAccountMembershipManagementRequest,
    params: RequestParams = {},
  ) =>
    this.request<CustomerAccountMembershipManagement, any>({
      path: `/api/accounts/memberships/management/${id}/`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsRelationsList
   * @request GET:/api/accounts/relations/
   * @secure
   * @response `200` `(CustomerAccountRelation)[]`
   */
  accountsRelationsList = (query?: { ssn?: string }, params: RequestParams = {}) =>
    this.request<CustomerAccountRelation[], any>({
      path: `/api/accounts/relations/`,
      method: "GET",
      query: query || { format: "json" },
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsRelationsCreate
   * @request POST:/api/accounts/relations/
   * @secure
   * @response `201` `CustomerAccountRelation`
   */
  accountsRelationsCreate = (data: CustomerAccountRelationCreateRequest, params: RequestParams = {}) =>
    this.request<CustomerAccountRelation, any>({
      path: `/api/accounts/relations/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsRelationsRetrieve
   * @request GET:/api/accounts/relations/{id}/
   * @secure
   * @response `200` `CustomerAccountRelation`
   */
  accountsRelationsRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<CustomerAccountRelation, any>({
      path: `/api/accounts/relations/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsRelationsUpdate
   * @request PUT:/api/accounts/relations/{id}/
   * @secure
   * @response `200` `CustomerAccountRelation`
   */
  accountsRelationsUpdate = (id: number, data: CustomerAccountRelationRequest, params: RequestParams = {}) =>
    this.request<CustomerAccountRelation, any>({
      path: `/api/accounts/relations/${id}/`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsRelationsPartialUpdate
   * @request PATCH:/api/accounts/relations/{id}/
   * @secure
   * @response `200` `CustomerAccountRelation`
   */
  accountsRelationsPartialUpdate = (
    id: number,
    data: PatchedCustomerAccountRelationRequest,
    params: RequestParams = {},
  ) =>
    this.request<CustomerAccountRelation, any>({
      path: `/api/accounts/relations/${id}/`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsRelationsDestroy
   * @request DELETE:/api/accounts/relations/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  accountsRelationsDestroy = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/accounts/relations/${id}/`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsSettingsRetrieve
   * @request GET:/api/accounts/settings/
   * @secure
   * @response `200` `CustomerAccountSettings`
   */
  accountsSettingsRetrieve = (params: RequestParams = {}) =>
    this.request<CustomerAccountSettings, any>({
      path: `/api/accounts/settings/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsSettingsUpdate
   * @request PUT:/api/accounts/settings/
   * @secure
   * @response `200` `CustomerAccountSettings`
   */
  accountsSettingsUpdate = (data: CustomerAccountSettingsRequest, params: RequestParams = {}) =>
    this.request<CustomerAccountSettings, any>({
      path: `/api/accounts/settings/`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags accounts
   * @name AccountsSettingsPartialUpdate
   * @request PATCH:/api/accounts/settings/
   * @secure
   * @response `200` `CustomerAccountSettings`
   */
  accountsSettingsPartialUpdate = (data: PatchedCustomerAccountSettingsRequest, params: RequestParams = {}) =>
    this.request<CustomerAccountSettings, any>({
      path: `/api/accounts/settings/`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Display all categories with total product count
   *
   * @tags categories
   * @name CategoriesList
   * @request GET:/api/categories/
   * @secure
   * @response `200` `(Category)[]`
   */
  categoriesList = (params: RequestParams = {}) =>
    this.request<Category[], any>({
      path: `/api/categories/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Display all categories with total product count
   *
   * @tags categories
   * @name CategoriesRetrieve
   * @request GET:/api/categories/{id}/
   * @secure
   * @response `200` `Category`
   */
  categoriesRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<Category, any>({
      path: `/api/categories/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Display all categories with total product count
   *
   * @tags categories
   * @name CategoriesRentalList
   * @request GET:/api/categories/rental/
   * @secure
   * @response `200` `(Category)[]`
   */
  categoriesRentalList = (params: RequestParams = {}) =>
    this.request<Category[], any>({
      path: `/api/categories/rental/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags checkouts
   * @name CheckoutsList
   * @request GET:/api/checkouts/
   * @secure
   * @response `200` `PaginatedCheckoutList`
   */
  checkoutsList = (query?: { limit?: number; offset?: number }, params: RequestParams = {}) =>
    this.request<PaginatedCheckoutList, any>({
      path: `/api/checkouts/`,
      method: "GET",
      query: query || { format: "json" },
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags checkouts
   * @name CheckoutsRetrieve
   * @request GET:/api/checkouts/{token}/
   * @secure
   * @response `200` `Checkout`
   */
  checkoutsRetrieve = (token: string | "current", params: RequestParams = {}) =>
    this.request<Checkout, any>({
      path: `/api/checkouts/${token}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags checkouts
   * @name CheckoutsUpdate
   * @request PUT:/api/checkouts/{token}/
   * @secure
   * @response `200` `Checkout`
   */
  checkoutsUpdate = (token: string | "current", data: CheckoutRequest, params: RequestParams = {}) =>
    this.request<Checkout, any>({
      path: `/api/checkouts/${token}/`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags checkouts
   * @name CheckoutsPartialUpdate
   * @request PATCH:/api/checkouts/{token}/
   * @secure
   * @response `200` `Checkout`
   */
  checkoutsPartialUpdate = (token: string | "current", data: PatchedCheckoutRequest, params: RequestParams = {}) =>
    this.request<Checkout, any>({
      path: `/api/checkouts/${token}/`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Connect logged in user to checkout
   *
   * @tags checkouts
   * @name CheckoutsConnectRetrieve
   * @request GET:/api/checkouts/{token}/connect/
   * @secure
   * @response `200` `Checkout`
   */
  checkoutsConnectRetrieve = (token: string, params: RequestParams = {}) =>
    this.request<Checkout, any>({
      path: `/api/checkouts/${token}/connect/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Merge checkouts
   *
   * @tags checkouts
   * @name CheckoutsMergeUpdate
   * @request PUT:/api/checkouts/{token}/merge/
   * @secure
   * @response `200` `Checkout`
   */
  checkoutsMergeUpdate = (token: string | "current", data: CheckoutMergeRequest, params: RequestParams = {}) =>
    this.request<Checkout, any>({
      path: `/api/checkouts/${token}/merge/`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags credit-limit
   * @name CreditLimitRetrieve
   * @request GET:/api/credit-limit/
   * @secure
   * @response `200` `CustomerCreditLimit`
   */
  creditLimitRetrieve = (params: RequestParams = {}) =>
    this.request<CustomerCreditLimit, any>({
      path: `/api/credit-limit/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags customer-agents
   * @name CustomerAgentsList
   * @request GET:/api/customer-agents/
   * @secure
   * @response `200` `(CustomerAgent)[]`
   */
  customerAgentsList = (params: RequestParams = {}) =>
    this.request<CustomerAgent[], any>({
      path: `/api/customer-agents/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags customer-agents
   * @name CustomerAgentsCreate
   * @request POST:/api/customer-agents/
   * @secure
   * @response `201` `CustomerAgent`
   */
  customerAgentsCreate = (data: CustomerAgentCreateRequest, params: RequestParams = {}) =>
    this.request<CustomerAgent, any>({
      path: `/api/customer-agents/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags customer-agents
   * @name CustomerAgentsRetrieve
   * @request GET:/api/customer-agents/{id}/
   * @secure
   * @response `200` `CustomerAgent`
   */
  customerAgentsRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<CustomerAgent, any>({
      path: `/api/customer-agents/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags customer-agents
   * @name CustomerAgentsUpdate
   * @request PUT:/api/customer-agents/{id}/
   * @secure
   * @response `200` `CustomerAgent`
   */
  customerAgentsUpdate = (id: number, data: CustomerAgentRequest, params: RequestParams = {}) =>
    this.request<CustomerAgent, any>({
      path: `/api/customer-agents/${id}/`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags customer-agents
   * @name CustomerAgentsPartialUpdate
   * @request PATCH:/api/customer-agents/{id}/
   * @secure
   * @response `200` `CustomerAgent`
   */
  customerAgentsPartialUpdate = (id: number, data: PatchedCustomerAgentRequest, params: RequestParams = {}) =>
    this.request<CustomerAgent, any>({
      path: `/api/customer-agents/${id}/`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags customer-contacts-info
   * @name CustomerContactsInfoList
   * @request GET:/api/customer-contacts-info/
   * @secure
   * @response `200` `(CustomerContactInfo)[]`
   */
  customerContactsInfoList = (params: RequestParams = {}) =>
    this.request<CustomerContactInfo[], any>({
      path: `/api/customer-contacts-info/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags customer-contacts-info
   * @name CustomerContactsInfoCreate
   * @request POST:/api/customer-contacts-info/
   * @secure
   * @response `201` `CustomerContactInfo`
   */
  customerContactsInfoCreate = (data: CustomerContactInfoRequest, params: RequestParams = {}) =>
    this.request<CustomerContactInfo, any>({
      path: `/api/customer-contacts-info/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags customer-contacts-info
   * @name CustomerContactsInfoRetrieve
   * @request GET:/api/customer-contacts-info/{id}/
   * @secure
   * @response `200` `CustomerContactInfo`
   */
  customerContactsInfoRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<CustomerContactInfo, any>({
      path: `/api/customer-contacts-info/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags customer-contacts-info
   * @name CustomerContactsInfoUpdate
   * @request PUT:/api/customer-contacts-info/{id}/
   * @secure
   * @response `200` `CustomerContactInfo`
   */
  customerContactsInfoUpdate = (id: number, data: CustomerContactInfoRequest, params: RequestParams = {}) =>
    this.request<CustomerContactInfo, any>({
      path: `/api/customer-contacts-info/${id}/`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags customer-contacts-info
   * @name CustomerContactsInfoPartialUpdate
   * @request PATCH:/api/customer-contacts-info/{id}/
   * @secure
   * @response `200` `CustomerContactInfo`
   */
  customerContactsInfoPartialUpdate = (
    id: number,
    data: PatchedCustomerContactInfoRequest,
    params: RequestParams = {},
  ) =>
    this.request<CustomerContactInfo, any>({
      path: `/api/customer-contacts-info/${id}/`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags customer-contacts-info
   * @name CustomerContactsInfoDestroy
   * @request DELETE:/api/customer-contacts-info/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  customerContactsInfoDestroy = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/customer-contacts-info/${id}/`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags customer-relations
   * @name CustomerRelationsList
   * @request GET:/api/customer-relations/
   * @secure
   * @response `200` `(CustomerAccountRelation)[]`
   */
  customerRelationsList = (query?: { ssn?: string }, params: RequestParams = {}) =>
    this.request<CustomerAccountRelation[], any>({
      path: `/api/customer-relations/`,
      method: "GET",
      query: query || { format: "json" },
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags customer-relations
   * @name CustomerRelationsCreate
   * @request POST:/api/customer-relations/
   * @secure
   * @response `201` `CustomerAccountRelation`
   */
  customerRelationsCreate = (data: CustomerAccountRelationCreateRequest, params: RequestParams = {}) =>
    this.request<CustomerAccountRelation, any>({
      path: `/api/customer-relations/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags customer-relations
   * @name CustomerRelationsRetrieve
   * @request GET:/api/customer-relations/{id}/
   * @secure
   * @response `200` `CustomerAccountRelation`
   */
  customerRelationsRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<CustomerAccountRelation, any>({
      path: `/api/customer-relations/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags customer-relations
   * @name CustomerRelationsUpdate
   * @request PUT:/api/customer-relations/{id}/
   * @secure
   * @response `200` `CustomerAccountRelation`
   */
  customerRelationsUpdate = (id: number, data: CustomerAccountRelationRequest, params: RequestParams = {}) =>
    this.request<CustomerAccountRelation, any>({
      path: `/api/customer-relations/${id}/`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags customer-relations
   * @name CustomerRelationsPartialUpdate
   * @request PATCH:/api/customer-relations/{id}/
   * @secure
   * @response `200` `CustomerAccountRelation`
   */
  customerRelationsPartialUpdate = (
    id: number,
    data: PatchedCustomerAccountRelationRequest,
    params: RequestParams = {},
  ) =>
    this.request<CustomerAccountRelation, any>({
      path: `/api/customer-relations/${id}/`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags customer-relations
   * @name CustomerRelationsDestroy
   * @request DELETE:/api/customer-relations/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  customerRelationsDestroy = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/customer-relations/${id}/`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags event-trigger
   * @name EventTriggerCreate
   * @request POST:/api/event-trigger/
   * @secure
   * @response `200` `DRFAttributeBanner`
   * @response `400` `DRFAttributeBanner`
   * @response `500` `DRFAttributeBanner`
   */
  eventTriggerCreate = (
    data: { metricName: string; profile: { email?: string; first_name?: string }; properties: Record<string, string> },
    params: RequestParams = {},
  ) =>
    this.request<DRFAttributeBanner, DRFAttributeBanner>({
      path: `/api/event-trigger/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags external-request
   * @name ExternalRequestAttachmentRetrieve
   * @request GET:/api/external-request/attachment/
   * @secure
   * @response `200` `ExternalRequest`
   */
  externalRequestAttachmentRetrieve = (
    query: { attachment: string; ssn?: string; type: "invoice" | "remittance" },
    params: RequestParams = {},
  ) =>
    this.request<ExternalRequest, any>({
      path: `/api/external-request/attachment/`,
      method: "GET",
      query: query || { format: "json" },
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags external-request
   * @name ExternalRequestAttachmentBundleRetrieve
   * @request GET:/api/external-request/attachment-bundle/
   * @secure
   * @response `200` `ExternalRequest`
   */
  externalRequestAttachmentBundleRetrieve = (
    query: {
      date_from: string;
      date_to: string;
      department?: string;
      job?: string;
      ssn?: string;
      type: "details" | "invoice" | "remittance";
    },
    params: RequestParams = {},
  ) =>
    this.request<ExternalRequest, any>({
      path: `/api/external-request/attachment-bundle/`,
      method: "GET",
      query: query || { format: "json" },
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags external-request
   * @name ExternalRequestDiscountsCreate
   * @request POST:/api/external-request/discounts/
   * @secure
   * @response `200` `Discount`
   */
  externalRequestDiscountsCreate = (data: DiscountRequestRequest, params: RequestParams = {}) =>
    this.request<Discount, any>({
      path: `/api/external-request/discounts/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags external-request
   * @name ExternalRequestPrepaymentsCreate
   * @request POST:/api/external-request/prepayments/
   * @secure
   * @response `200` `Prepayment`
   */
  externalRequestPrepaymentsCreate = (data: BaseRequestRequest, params: RequestParams = {}) =>
    this.request<Prepayment, any>({
      path: `/api/external-request/prepayments/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags external-request
   * @name ExternalRequestRemittanceSlipsCreate
   * @request POST:/api/external-request/remittance-slips/
   * @secure
   * @response `200` `RemittanceSlips`
   */
  externalRequestRemittanceSlipsCreate = (data: RemittanceSlipsRequestRequest, params: RequestParams = {}) =>
    this.request<RemittanceSlips, any>({
      path: `/api/external-request/remittance-slips/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags external-request
   * @name ExternalRequestSalesInvoiceDetailsCreate
   * @request POST:/api/external-request/sales-invoice-details/
   * @secure
   * @response `200` `SalesInvoiceDetail`
   */
  externalRequestSalesInvoiceDetailsCreate = (data: SalesInvoiceRequestRequest, params: RequestParams = {}) =>
    this.request<SalesInvoiceDetail, any>({
      path: `/api/external-request/sales-invoice-details/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags external-request
   * @name ExternalRequestSalesInvoicesCreate
   * @request POST:/api/external-request/sales-invoices/
   * @secure
   * @response `200` `SalesInvoice`
   */
  externalRequestSalesInvoicesCreate = (data: SalesInvoiceRequestRequest, params: RequestParams = {}) =>
    this.request<SalesInvoice, any>({
      path: `/api/external-request/sales-invoices/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags external-request
   * @name ExternalRequestSalesTransactionsCreate
   * @request POST:/api/external-request/sales-transactions/
   * @secure
   * @response `200` `SalesTransaction`
   */
  externalRequestSalesTransactionsCreate = (data: BaseRequestRequest, params: RequestParams = {}) =>
    this.request<SalesTransaction, any>({
      path: `/api/external-request/sales-transactions/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags field-types
   * @name FieldTypesList
   * @request GET:/api/field-types/
   * @secure
   * @response `200` `(FieldType)[]`
   */
  fieldTypesList = (params: RequestParams = {}) =>
    this.request<FieldType[], any>({
      path: `/api/field-types/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags invitations
   * @name InvitationsList
   * @request GET:/api/invitations/
   * @secure
   * @response `200` `(CustomerAccountInvitation)[]`
   */
  invitationsList = (params: RequestParams = {}) =>
    this.request<CustomerAccountInvitation[], any>({
      path: `/api/invitations/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags invitations
   * @name InvitationsCreate
   * @request POST:/api/invitations/
   * @secure
   * @response `201` `CustomerAccountInvitation`
   */
  invitationsCreate = (data: CustomerAccountInvitationRequest, params: RequestParams = {}) =>
    this.request<CustomerAccountInvitation, any>({
      path: `/api/invitations/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags invitations
   * @name InvitationsRetrieve
   * @request GET:/api/invitations/{id}/
   * @secure
   * @response `200` `CustomerAccountInvitation`
   */
  invitationsRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<CustomerAccountInvitation, any>({
      path: `/api/invitations/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags invitations
   * @name InvitationsUpdate
   * @request PUT:/api/invitations/{id}/
   * @secure
   * @response `200` `CustomerAccountInvitation`
   */
  invitationsUpdate = (id: number, data: CustomerAccountInvitationRequest, params: RequestParams = {}) =>
    this.request<CustomerAccountInvitation, any>({
      path: `/api/invitations/${id}/`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags invitations
   * @name InvitationsPartialUpdate
   * @request PATCH:/api/invitations/{id}/
   * @secure
   * @response `200` `CustomerAccountInvitation`
   */
  invitationsPartialUpdate = (id: number, data: PatchedCustomerAccountInvitationRequest, params: RequestParams = {}) =>
    this.request<CustomerAccountInvitation, any>({
      path: `/api/invitations/${id}/`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags invitations
   * @name InvitationsDestroy
   * @request DELETE:/api/invitations/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  invitationsDestroy = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/invitations/${id}/`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags invitations
   * @name InvitationsResendCreate
   * @request POST:/api/invitations/{id}/resend/
   * @secure
   * @response `200` `CustomerAccountInvitation`
   */
  invitationsResendCreate = (id: number, params: RequestParams = {}) =>
    this.request<CustomerAccountInvitation, any>({
      path: `/api/invitations/${id}/resend/`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags invitations
   * @name InvitationsAcceptCreate
   * @request POST:/api/invitations/accept/
   * @secure
   * @response `200` `Login`
   */
  invitationsAcceptCreate = (data: AcceptInvitationRequest, params: RequestParams = {}) =>
    this.request<Login, any>({
      path: `/api/invitations/accept/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags invitations
   * @name InvitationsDetailsRetrieve
   * @request GET:/api/invitations/details/
   * @secure
   * @response `200` `CustomerAccountInvitationDetail`
   */
  invitationsDetailsRetrieve = (query: { token: string }, params: RequestParams = {}) =>
    this.request<CustomerAccountInvitationDetail, any>({
      path: `/api/invitations/details/`,
      method: "GET",
      query: query || { format: "json" },
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags loan-application
   * @name LoanApplicationApplyCreate
   * @request POST:/api/loan-application/apply/
   * @secure
   * @response `200` `void` No response body
   */
  loanApplicationApplyCreate = (data: LoanApplicationRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/loan-application/apply/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags loan-application
   * @name LoanApplicationCalculatorPreviewRetrieve
   * @request GET:/api/loan-application/calculator-preview/
   * @secure
   * @response `200` `OptionsResponse`
   */
  loanApplicationCalculatorPreviewRetrieve = (query: { amount: string }, params: RequestParams = {}) =>
    this.request<OptionsResponse, any>({
      path: `/api/loan-application/calculator-preview/`,
      method: "GET",
      query: query || { format: "json" },
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags loan-application
   * @name LoanApplicationCalculatorResultRetrieve
   * @request GET:/api/loan-application/calculator-result/
   * @secure
   * @response `200` `LoanCalculationResponse`
   */
  loanApplicationCalculatorResultRetrieve = (
    query: { amount: string; number_of_installments: string },
    params: RequestParams = {},
  ) =>
    this.request<LoanCalculationResponse, any>({
      path: `/api/loan-application/calculator-result/`,
      method: "GET",
      query: query || { format: "json" },
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags loan-application
   * @name LoanApplicationStatusRetrieve
   * @request GET:/api/loan-application/status/
   * @secure
   * @response `200` `void` No response body
   */
  loanApplicationStatusRetrieve = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/loan-application/status/`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags membership-management
   * @name MembershipManagementList
   * @request GET:/api/membership-management/
   * @secure
   * @response `200` `(CustomerAccountMembershipManagement)[]`
   */
  membershipManagementList = (params: RequestParams = {}) =>
    this.request<CustomerAccountMembershipManagement[], any>({
      path: `/api/membership-management/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags membership-management
   * @name MembershipManagementRetrieve
   * @request GET:/api/membership-management/{id}/
   * @secure
   * @response `200` `CustomerAccountMembershipManagement`
   */
  membershipManagementRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<CustomerAccountMembershipManagement, any>({
      path: `/api/membership-management/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags membership-management
   * @name MembershipManagementUpdate
   * @request PUT:/api/membership-management/{id}/
   * @secure
   * @response `200` `CustomerAccountMembershipManagement`
   */
  membershipManagementUpdate = (
    id: number,
    data: CustomerAccountMembershipManagementRequest,
    params: RequestParams = {},
  ) =>
    this.request<CustomerAccountMembershipManagement, any>({
      path: `/api/membership-management/${id}/`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags membership-management
   * @name MembershipManagementPartialUpdate
   * @request PATCH:/api/membership-management/{id}/
   * @secure
   * @response `200` `CustomerAccountMembershipManagement`
   */
  membershipManagementPartialUpdate = (
    id: number,
    data: PatchedCustomerAccountMembershipManagementRequest,
    params: RequestParams = {},
  ) =>
    this.request<CustomerAccountMembershipManagement, any>({
      path: `/api/membership-management/${id}/`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags memberships
   * @name MembershipsList
   * @request GET:/api/memberships/
   * @secure
   * @response `200` `(CustomerAccountMembership)[]`
   */
  membershipsList = (params: RequestParams = {}) =>
    this.request<CustomerAccountMembership[], any>({
      path: `/api/memberships/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags memberships
   * @name MembershipsRetrieve
   * @request GET:/api/memberships/{id}/
   * @secure
   * @response `200` `CustomerAccountMembership`
   */
  membershipsRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<CustomerAccountMembership, any>({
      path: `/api/memberships/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags memberships
   * @name MembershipsSwitchAccountCreate
   * @request POST:/api/memberships/{id}/switch-account/
   * @secure
   * @response `200` `Login`
   */
  membershipsSwitchAccountCreate = (id: number, params: RequestParams = {}) =>
    this.request<Login, any>({
      path: `/api/memberships/${id}/switch-account/`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags products
   * @name ProductsList
   * @request GET:/api/products/
   * @secure
   * @response `200` `PaginatedProductListList`
   */
  productsList = (
    query?: {
      category?: number[];
      category_slug?: string[];
      has_images?: boolean;
      id?: number[];
      ordering?: string;
      page?: number;
      page_size?: number;
      sku?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<PaginatedProductListList, any>({
      path: `/api/products/`,
      method: "GET",
      query: query || { format: "json" },
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags products
   * @name ProductsRetrieve
   * @request GET:/api/products/{slug}/
   * @secure
   * @response `200` `ProductDetail`
   */
  productsRetrieve = (slug: string, params: RequestParams = {}) =>
    this.request<ProductDetail, any>({
      path: `/api/products/${slug}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags products
   * @name ProductsInventoryRetrieve
   * @request GET:/api/products/{slug}/inventory/
   * @secure
   * @response `200` `ProductInventory`
   */
  productsInventoryRetrieve = (slug: string, params: RequestParams = {}) =>
    this.request<ProductInventory, any>({
      path: `/api/products/${slug}/inventory/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags products
   * @name ProductsPricesRetrieve
   * @request GET:/api/products/{slug}/prices/
   * @secure
   * @response `200` `Record<string, { price: { vat: number, net: number, gross: number, perSqm?: number | null }, discounted: { vat: number, net: number, gross: number, perSqm?: number | null, name: string, percentage: number }, isSqmProduct?: boolean, packagingQuantity?: number, sqm: number | null }>`
   */
  productsPricesRetrieve = (slug: string, params: RequestParams = {}) =>
    this.request<
      Record<
        string,
        {
          price: { vat: number; net: number; gross: number; perSqm?: number | null };
          discounted: {
            vat: number;
            net: number;
            gross: number;
            perSqm?: number | null;
            name: string;
            percentage: number;
          };
          isSqmProduct?: boolean;
          packagingQuantity?: number;
          sqm: number | null;
        }
      >,
      any
    >({
      path: `/api/products/${slug}/prices/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags products
   * @name ProductsApiProductsMediaBySkuRetrieve
   * @request GET:/api/products/api/products/media/by-sku/{sku}/
   * @secure
   * @response `200` `ProductDetail`
   */
  productsApiProductsMediaBySkuRetrieve = (sku: string, params: RequestParams = {}) =>
    this.request<ProductDetail, any>({
      path: `/api/products/api/products/media/by-sku/${sku}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags products
   * @name ProductsBySlugRetrieve
   * @request GET:/api/products/by-slug/{slug}/
   * @secure
   * @response `200` `ProductDetail`
   */
  productsBySlugRetrieve = (slug: string, params: RequestParams = {}) =>
    this.request<ProductDetail, any>({
      path: `/api/products/by-slug/${slug}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags products
   * @name ProductsInventoryList
   * @request GET:/api/products/inventory/
   * @secure
   * @response `200` `PaginatedProductInventoryList`
   */
  productsInventoryList = (
    query?: {
      category?: number[];
      category_slug?: string[];
      has_images?: boolean;
      id?: number[];
      ordering?: string;
      page?: number;
      page_size?: number;
      sku?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<PaginatedProductInventoryList, any>({
      path: `/api/products/inventory/`,
      method: "GET",
      query: query || { format: "json" },
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags products
   * @name ProductsLowestPricesList
   * @request GET:/api/products/lowest-prices/
   * @secure
   * @response `200` `{ count: number, next: string | null, previous: string | null, results: Record<string, Record<string, { price: { vat: number, net: number, gross: number, perSqm?: number | null }, discounted: { vat: number, net: number, gross: number, perSqm?: number | null, name: string, percentage: number }, isSqmProduct?: boolean, packagingQuantity?: number, sqm: number | null }>> }`
   */
  productsLowestPricesList = (
    query?: {
      category?: number[];
      category_slug?: string[];
      has_images?: boolean;
      id?: number[];
      ordering?: string;
      sku?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<
      {
        count: number;
        next: string | null;
        previous: string | null;
        results: Record<
          string,
          Record<
            string,
            {
              price: { vat: number; net: number; gross: number; perSqm?: number | null };
              discounted: {
                vat: number;
                net: number;
                gross: number;
                perSqm?: number | null;
                name: string;
                percentage: number;
              };
              isSqmProduct?: boolean;
              packagingQuantity?: number;
              sqm: number | null;
            }
          >
        >;
      },
      any
    >({
      path: `/api/products/lowest-prices/`,
      method: "GET",
      query: query || { format: "json" },
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags products
   * @name ProductsMediaBySkuRetrieve
   * @request GET:/api/products/media/by-sku/{sku}
   * @secure
   * @response `200` `ProductDetail`
   */
  productsMediaBySkuRetrieve = (sku: string, params: RequestParams = {}) =>
    this.request<ProductDetail, any>({
      path: `/api/products/media/by-sku/${sku}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags products
   * @name ProductsPricesList
   * @request GET:/api/products/prices/
   * @secure
   * @response `200` `{ count: number, next: string | null, previous: string | null, results: Record<string, { price: { vat: number, net: number, gross: number, perSqm?: number | null }, discounted: { vat: number, net: number, gross: number, perSqm?: number | null, name: string, percentage: number }, isSqmProduct?: boolean, packagingQuantity?: number, sqm: number | null }> }`
   */
  productsPricesList = (
    query?: {
      category?: number[];
      category_slug?: string[];
      has_images?: boolean;
      id?: number[];
      ordering?: string;
      sku?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<
      {
        count: number;
        next: string | null;
        previous: string | null;
        results: Record<
          string,
          {
            price: { vat: number; net: number; gross: number; perSqm?: number | null };
            discounted: {
              vat: number;
              net: number;
              gross: number;
              perSqm?: number | null;
              name: string;
              percentage: number;
            };
            isSqmProduct?: boolean;
            packagingQuantity?: number;
            sqm: number | null;
          }
        >;
      },
      any
    >({
      path: `/api/products/prices/`,
      method: "GET",
      query: query || { format: "json" },
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags products
   * @name ProductsSyncCreate
   * @request POST:/api/products/sync/
   * @secure
   * @response `200` `ProductSync`
   */
  productsSyncCreate = (data: ProductSyncRequest, params: RequestParams = {}) =>
    this.request<ProductSync, any>({
      path: `/api/products/sync/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags products
   * @name ProductsTestLoggingRetrieve
   * @request GET:/api/products/test-logging/
   * @secure
   * @response `200` `ProductDetail`
   */
  productsTestLoggingRetrieve = (params: RequestParams = {}) =>
    this.request<ProductDetail, any>({
      path: `/api/products/test-logging/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags products
   * @name ProductsThrowExcRetrieve
   * @request GET:/api/products/throw-exc/
   * @secure
   * @response `200` `ProductDetail`
   */
  productsThrowExcRetrieve = (params: RequestParams = {}) =>
    this.request<ProductDetail, any>({
      path: `/api/products/throw-exc/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags saltpay
   * @name SaltpayPaymentTokensList
   * @request GET:/api/saltpay/payment-tokens/
   * @secure
   * @response `200` `(PaymentToken)[]`
   */
  saltpayPaymentTokensList = (params: RequestParams = {}) =>
    this.request<PaymentToken[], any>({
      path: `/api/saltpay/payment-tokens/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags saltpay
   * @name SaltpayPaymentTokensCreate
   * @request POST:/api/saltpay/payment-tokens/
   * @secure
   * @response `201` `PaymentToken`
   */
  saltpayPaymentTokensCreate = (data: PaymentTokenRequest, params: RequestParams = {}) =>
    this.request<PaymentToken, any>({
      path: `/api/saltpay/payment-tokens/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags saltpay
   * @name SaltpayPaymentsCallbackCreate
   * @request POST:/api/saltpay/payments/{id}/callback/
   * @secure
   * @response `200` `void` No response body
   */
  saltpayPaymentsCallbackCreate = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/saltpay/payments/${id}/callback/`,
      method: "POST",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags saltpay
   * @name SaltpayVirtualCardsList
   * @request GET:/api/saltpay/virtual-cards/
   * @secure
   * @response `200` `(VirtualCard)[]`
   */
  saltpayVirtualCardsList = (params: RequestParams = {}) =>
    this.request<VirtualCard[], any>({
      path: `/api/saltpay/virtual-cards/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags saltpay
   * @name SaltpayVirtualCardsCreate
   * @request POST:/api/saltpay/virtual-cards/
   * @secure
   * @response `201` `VirtualCard`
   */
  saltpayVirtualCardsCreate = (data: VirtualCardRequest, params: RequestParams = {}) =>
    this.request<VirtualCard, any>({
      path: `/api/saltpay/virtual-cards/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags saltpay
   * @name SaltpayVirtualCardsDestroy
   * @request DELETE:/api/saltpay/virtual-cards/{id}/
   * @secure
   * @response `204` `void` No response body
   */
  saltpayVirtualCardsDestroy = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/saltpay/virtual-cards/${id}/`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * @description OpenApi3 schema for this API. Format can be selected via content negotiation. - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
   *
   * @tags schema
   * @name SchemaRetrieve
   * @request GET:/api/schema/
   * @secure
   * @response `200` `Record<string, any>`
   */
  schemaRetrieve = (query?: { format?: "json" | "yaml"; lang?: "en" | "is" }, params: RequestParams = {}) =>
    this.request<Record<string, any>, any>({
      path: `/api/schema/`,
      method: "GET",
      query: query || { format: "json" },
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags spec-field-types
   * @name SpecFieldTypesList
   * @request GET:/api/spec-field-types/
   * @secure
   * @response `200` `({ id: string, name: Record<string, string>, unit: string, index: number, category: string, defaultValue: (string | number | number | boolean | null), isMultiValue: boolean, template: number })[]`
   */
  specFieldTypesList = (params: RequestParams = {}) =>
    this.request<
      {
        id: string;
        name: Record<string, string>;
        unit: string;
        index: number;
        category: string;
        defaultValue: string | number | number | boolean | null;
        isMultiValue: boolean;
        template: number;
      }[],
      any
    >({
      path: `/api/spec-field-types/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags sync-messages
   * @name SyncMessagesCreate
   * @request POST:/api/sync-messages/
   * @secure
   * @response `200` `SyncMessage`
   */
  syncMessagesCreate = (data: SyncMessageRequest, params: RequestParams = {}) =>
    this.request<SyncMessage, any>({
      path: `/api/sync-messages/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags users
   * @name UsersList
   * @request GET:/api/users/
   * @secure
   * @response `200` `(User)[]`
   */
  usersList = (query?: { query?: string }, params: RequestParams = {}) =>
    this.request<User[], any>({
      path: `/api/users/`,
      method: "GET",
      query: query || { format: "json" },
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags users
   * @name UsersRetrieve
   * @request GET:/api/users/{id}/
   * @secure
   * @response `200` `User`
   */
  usersRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<User, any>({
      path: `/api/users/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags users
   * @name UsersSettingsRetrieve
   * @request GET:/api/users/{id}/settings/
   * @secure
   * @response `200` `object`
   */
  usersSettingsRetrieve = (id: number, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/users/${id}/settings/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags users
   * @name UsersSettingsCreate
   * @request POST:/api/users/{id}/settings/
   * @secure
   * @response `200` `object`
   */
  usersSettingsCreate = (id: number, data: Record<string, any>, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/users/${id}/settings/`,
      method: "POST",
      body: data,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags users
   * @name UsersSettingsPartialUpdate
   * @request PATCH:/api/users/{id}/settings/
   * @secure
   * @response `200` `object`
   */
  usersSettingsPartialUpdate = (id: number, data: Record<string, any>, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/users/${id}/settings/`,
      method: "PATCH",
      body: data,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags users
   * @name UsersAcceptTermsCreate
   * @request POST:/api/users/accept_terms/
   * @secure
   * @response `200` `User`
   */
  usersAcceptTermsCreate = (params: RequestParams = {}) =>
    this.request<User, any>({
      path: `/api/users/accept_terms/`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags users
   * @name UsersCheckEmailAvailabilityCreate
   * @request POST:/api/users/check-email-availability/
   * @secure
   * @response `200` `CheckEmailAvailabilityResponse`
   */
  usersCheckEmailAvailabilityCreate = (data: CheckEmailAvailabilityRequest, params: RequestParams = {}) =>
    this.request<CheckEmailAvailabilityResponse, any>({
      path: `/api/users/check-email-availability/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags users
   * @name UsersImpersonateCreate
   * @request POST:/api/users/impersonate/
   * @secure
   * @response `200` `Login`
   */
  usersImpersonateCreate = (data: ImpersonateRequest, params: RequestParams = {}) =>
    this.request<Login, any>({
      path: `/api/users/impersonate/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags users
   * @name UsersLoginCreate
   * @request POST:/api/users/login/
   * @secure
   * @response `200` `Login`
   */
  usersLoginCreate = (data: LoginRequest, params: RequestParams = {}) =>
    this.request<Login, any>({
      path: `/api/users/login/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags users
   * @name UsersMeRetrieve
   * @request GET:/api/users/me/
   * @secure
   * @response `200` `User`
   */
  usersMeRetrieve = (params: RequestParams = {}) =>
    this.request<User, any>({
      path: `/api/users/me/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags users
   * @name UsersPolledLoginCreate
   * @request POST:/api/users/polled-login/
   * @secure
   * @response `200` `PollLogin`
   */
  usersPolledLoginCreate = (data: PolledLoginRequest, params: RequestParams = {}) =>
    this.request<PollLogin, any>({
      path: `/api/users/polled-login/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags users
   * @name UsersSetEmailCreate
   * @request POST:/api/users/set_email/
   * @secure
   * @response `200` `Login`
   */
  usersSetEmailCreate = (data: SetEmailRequest, params: RequestParams = {}) =>
    this.request<Login, any>({
      path: `/api/users/set_email/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags users
   * @name UsersVerifyEmailCreate
   * @request POST:/api/users/verify_email/
   * @secure
   * @response `200` `Login`
   */
  usersVerifyEmailCreate = (data: VerifyEmailRequest, params: RequestParams = {}) =>
    this.request<Login, any>({
      path: `/api/users/verify_email/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags warehouses
   * @name WarehousesList
   * @request GET:/api/warehouses/
   * @secure
   * @response `200` `(Warehouse)[]`
   */
  warehousesList = (params: RequestParams = {}) =>
    this.request<Warehouse[], any>({
      path: `/api/warehouses/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags warehouses
   * @name WarehousesRetrieve
   * @request GET:/api/warehouses/{id}/
   * @secure
   * @response `200` `Warehouse`
   */
  warehousesRetrieve = (id: string, params: RequestParams = {}) =>
    this.request<Warehouse, any>({
      path: `/api/warehouses/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags warehouses
   * @name WarehousesHasContractRetrieve
   * @request GET:/api/warehouses/has_contract/
   * @secure
   * @response `200` `Warehouse`
   */
  warehousesHasContractRetrieve = (params: RequestParams = {}) =>
    this.request<Warehouse, any>({
      path: `/api/warehouses/has_contract/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
}
