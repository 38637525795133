/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AcceptInvitationRequest {
  /** @format uuid */
  token: string;
}

export interface Address {
  fullName: string;
  companyName?: string;
  streetAddress1?: string;
  streetAddress2?: string;
  city?: string;
  postalCode?: string;
  phone?: string;
}

export interface AddressRequest {
  companyName?: string;
  streetAddress1?: string;
  streetAddress2?: string;
  city?: string;
  postalCode?: string;
  phone?: string;
}

export interface BaseRequestRequest {
  /** @format date */
  dateFrom: string;

  /** @format date */
  dateTo: string;
  asXlsx?: boolean;
  overrideSsn?: string;
}

export interface Category {
  id: number;
  name: string;

  /** @pattern ^[-\w]+$ */
  slug: string;
  description?: Record<string, any>;
  descriptionPlaintext?: string;
  parent?: number | null;
  backgroundImage: { headerImage?: string };
  backgroundImageAlt?: string;
  productCount: number;
}

export interface Channel {
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string;
  name: string;
}

export interface CheckEmailAvailabilityRequest {
  /** @format email */
  email: string;
}

export interface CheckEmailAvailabilityResponse {
  available: boolean;
}

export interface Checkout {
  /** @format uuid */
  token: string;
  shippingAddress: Address;
  billingAddress: Address;

  /** @format email */
  email: string;
  lines: CheckoutLine[];
  shippingMethod?: number | null;

  /** @format uuid */
  collectionPoint?: string | null;
  quantity: number;
  totalPrice: TaxedMoney;
  shippingPrice: TaxedMoney;
  subTotalPrice: TaxedMoney;
  discount: number;
  voucherCode: string;
  note?: string;
  availableShippingMethods: ShippingMethod[];
  availableCollectionPoints: Warehouse[];
  availablePaymentGateways: PaymentGateway[];
  division?: string;
  project?: string;
  projectKey?: string;
  purchaseOrder?: string;
  paymentGateway?: string;
}

export interface CheckoutLine {
  id: number;
  variant: CheckoutLineVariant;
  totalPrice: CheckoutTaxedPrices;
  quantity: number;
}

export interface CheckoutLineProduct {
  id: number;

  /** @pattern ^[-\w]+$ */
  slug: string;
  name: string;
  shortDescription: { is: string; en: string };
  firstImage: ProductMedia;
  categories: number[];

  /** @format date-time */
  createdAt: string;

  /** @format date-time */
  updatedAt: string;
  chargeTaxes?: boolean;
  weight?: string | null;
  sellingPoints: string[];
  idealAccessories: string[];
  salesAvailability: string;
  listings: ProductChannelListingMapping;
  defaultVariant?: number | null;
}

export interface CheckoutLineVariant {
  id: number;
  sku?: string | null;
  name?: string;
  firstImage: ProductMedia;
  trackInventory?: boolean;
  inventoryStatus: Record<string, any>;
  inStock: boolean;
  webstoreInStock: boolean;
  outlet: DRFAttributeOutlet;
  banner: DRFAttributeBanner;
  price: { net: number; gross: number; currency: string };
  pricePerSqm?: { net: number; gross: number; currency: string };
  packagingQuantity?: number;
  sqm?: number;
  meta: VariantMeta;
  product: CheckoutLineProduct;
}

export interface CheckoutMergeRequest {
  /** @format uuid */
  token: string;
}

export interface CheckoutRequest {
  shippingMethod?: number | null;

  /** @format uuid */
  collectionPoint?: string | null;
  note?: string;
  division?: string;
  project?: string;
  projectKey?: string;
  purchaseOrder?: string;
  paymentGateway?: string;
}

export interface CheckoutTaxedPrices {
  amount: TaxedMoney;
  discountedAmount: TaxedMoney;
  discountType?: string;
}

export interface ClaimOptions {
  minNumberOfInstallments: number;
  maxNumberOfInstallments: number;
  availableExtraMonths: number;
}

export enum ClickAndCollectOptionEnum {
  Disabled = "disabled",
  Local = "local",
  All = "all",
}

export enum CountryEnum {
  AF = "AF",
  AX = "AX",
  AL = "AL",
  DZ = "DZ",
  AS = "AS",
  AD = "AD",
  AO = "AO",
  AI = "AI",
  AQ = "AQ",
  AG = "AG",
  AR = "AR",
  AM = "AM",
  AW = "AW",
  AU = "AU",
  AT = "AT",
  AZ = "AZ",
  BS = "BS",
  BH = "BH",
  BD = "BD",
  BB = "BB",
  BY = "BY",
  BE = "BE",
  BZ = "BZ",
  BJ = "BJ",
  BM = "BM",
  BT = "BT",
  BO = "BO",
  BQ = "BQ",
  BA = "BA",
  BW = "BW",
  BV = "BV",
  BR = "BR",
  IO = "IO",
  BN = "BN",
  BG = "BG",
  BF = "BF",
  BI = "BI",
  CV = "CV",
  KH = "KH",
  CM = "CM",
  CA = "CA",
  KY = "KY",
  CF = "CF",
  TD = "TD",
  CL = "CL",
  CN = "CN",
  CX = "CX",
  CC = "CC",
  CO = "CO",
  KM = "KM",
  CG = "CG",
  CD = "CD",
  CK = "CK",
  CR = "CR",
  CI = "CI",
  HR = "HR",
  CU = "CU",
  CW = "CW",
  CY = "CY",
  CZ = "CZ",
  DK = "DK",
  DJ = "DJ",
  DM = "DM",
  DO = "DO",
  EC = "EC",
  EG = "EG",
  SV = "SV",
  GQ = "GQ",
  ER = "ER",
  EE = "EE",
  SZ = "SZ",
  ET = "ET",
  FK = "FK",
  FO = "FO",
  FJ = "FJ",
  FI = "FI",
  FR = "FR",
  GF = "GF",
  PF = "PF",
  TF = "TF",
  GA = "GA",
  GM = "GM",
  GE = "GE",
  DE = "DE",
  GH = "GH",
  GI = "GI",
  GR = "GR",
  GL = "GL",
  GD = "GD",
  GP = "GP",
  GU = "GU",
  GT = "GT",
  GG = "GG",
  GN = "GN",
  GW = "GW",
  GY = "GY",
  HT = "HT",
  HM = "HM",
  VA = "VA",
  HN = "HN",
  HK = "HK",
  HU = "HU",
  IS = "IS",
  IN = "IN",
  ID = "ID",
  IR = "IR",
  IQ = "IQ",
  IE = "IE",
  IM = "IM",
  IL = "IL",
  IT = "IT",
  JM = "JM",
  JP = "JP",
  JE = "JE",
  JO = "JO",
  KZ = "KZ",
  KE = "KE",
  KI = "KI",
  KW = "KW",
  KG = "KG",
  LA = "LA",
  LV = "LV",
  LB = "LB",
  LS = "LS",
  LR = "LR",
  LY = "LY",
  LI = "LI",
  LT = "LT",
  LU = "LU",
  MO = "MO",
  MG = "MG",
  MW = "MW",
  MY = "MY",
  MV = "MV",
  ML = "ML",
  MT = "MT",
  MH = "MH",
  MQ = "MQ",
  MR = "MR",
  MU = "MU",
  YT = "YT",
  MX = "MX",
  FM = "FM",
  MD = "MD",
  MC = "MC",
  MN = "MN",
  ME = "ME",
  MS = "MS",
  MA = "MA",
  MZ = "MZ",
  MM = "MM",
  NA = "NA",
  NR = "NR",
  NP = "NP",
  NL = "NL",
  NC = "NC",
  NZ = "NZ",
  NI = "NI",
  NE = "NE",
  NG = "NG",
  NU = "NU",
  NF = "NF",
  KP = "KP",
  MK = "MK",
  MP = "MP",
  NO = "NO",
  OM = "OM",
  PK = "PK",
  PW = "PW",
  PS = "PS",
  PA = "PA",
  PG = "PG",
  PY = "PY",
  PE = "PE",
  PH = "PH",
  PN = "PN",
  PL = "PL",
  PT = "PT",
  PR = "PR",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RU = "RU",
  RW = "RW",
  BL = "BL",
  SH = "SH",
  KN = "KN",
  LC = "LC",
  MF = "MF",
  PM = "PM",
  VC = "VC",
  WS = "WS",
  SM = "SM",
  ST = "ST",
  SA = "SA",
  SN = "SN",
  RS = "RS",
  SC = "SC",
  SL = "SL",
  SG = "SG",
  SX = "SX",
  SK = "SK",
  SI = "SI",
  SB = "SB",
  SO = "SO",
  ZA = "ZA",
  GS = "GS",
  KR = "KR",
  SS = "SS",
  ES = "ES",
  LK = "LK",
  SD = "SD",
  SR = "SR",
  SJ = "SJ",
  SE = "SE",
  CH = "CH",
  SY = "SY",
  TW = "TW",
  TJ = "TJ",
  TZ = "TZ",
  TH = "TH",
  TL = "TL",
  TG = "TG",
  TK = "TK",
  TO = "TO",
  TT = "TT",
  TN = "TN",
  TR = "TR",
  TM = "TM",
  TC = "TC",
  TV = "TV",
  UG = "UG",
  UA = "UA",
  AE = "AE",
  GB = "GB",
  UM = "UM",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VU = "VU",
  VE = "VE",
  VN = "VN",
  VG = "VG",
  VI = "VI",
  WF = "WF",
  EH = "EH",
  YE = "YE",
  ZM = "ZM",
  ZW = "ZW",
}

export interface CreditCardOptions {
  minNumberOfInstallments: number;
  maxNumberOfInstallments: number;
  availableExtraMonths: number;
}

export interface CustomerAccount {
  ssn: string;
  name?: string;
  designation?: DesignationEnum;

  /** @format date-time */
  createdAt: string;
  externallySynced?: boolean | null;
  postPaymentDiscount?: boolean;
  showCreditLimit?: boolean | null;
  hasContract?: boolean;
}

export interface CustomerAccountInvitation {
  id: number;

  /** @format email */
  email?: string;
  phoneNumber?: string;
  unvalidatedName?: string;
  createAsAgent?: boolean;
  isRepresentative?: boolean;
  isUserManager?: boolean;
  canViewSalesTransactions?: boolean;
  canPurchase?: boolean;
  tokens: CustomerAccountInvitationToken[];

  /** @format date-time */
  acceptedAt: string | null;

  /** @format date-time */
  createdAt: string;

  /** @format date-time */
  updatedAt: string;
}

export interface CustomerAccountInvitationDetail {
  id: number;
  account: CustomerAccount;

  /** @format email */
  email: string;
  phoneNumber: string;
  unvalidatedName: string;
  createAsAgent: boolean;
  isRepresentative: boolean;
  isUserManager: boolean;
  canViewSalesTransactions: boolean;
  canPurchase: boolean;

  /** @format date-time */
  acceptedAt: string | null;

  /** @format date-time */
  createdAt: string;

  /** @format date-time */
  updatedAt: string;
}

export interface CustomerAccountInvitationRequest {
  /** @format email */
  email?: string;
  phoneNumber?: string;
  unvalidatedName?: string;
  createAsAgent?: boolean;
  isRepresentative?: boolean;
  isUserManager?: boolean;
  canViewSalesTransactions?: boolean;
  canPurchase?: boolean;
}

export interface CustomerAccountInvitationToken {
  /** @format date-time */
  createdAt: string;

  /** @format date-time */
  sentAt: string | null;
}

export interface CustomerAccountMembership {
  id: number;
  role: RoleEnum;
  account: CustomerAccount;
  isRepresentative?: boolean;
  isUserManager?: boolean;
  canViewSalesTransactions?: boolean;
  canPurchase?: boolean;
  canViewDiscounts?: boolean;

  /** @format date-time */
  createdAt: string;
  active?: boolean;
  activeAccount: boolean;
}

export interface CustomerAccountMembershipManagement {
  id: number;
  role: RoleEnum;
  user: StrippedUser;
  isRepresentative?: boolean;
  isUserManager?: boolean;
  canViewSalesTransactions?: boolean;
  canViewDiscounts?: boolean;
  canPurchase?: boolean;

  /** @format date-time */
  createdAt: string;
  active?: boolean;
}

export interface CustomerAccountMembershipManagementRequest {
  isRepresentative?: boolean;
  isUserManager?: boolean;
  canViewSalesTransactions?: boolean;
  canViewDiscounts?: boolean;
  canPurchase?: boolean;
  active?: boolean;
}

export interface CustomerAccountRelation {
  id: number;
  relationType: RelationTypeEnum;
  description: string;
  externalId: string;
  stopped: boolean;
  canModify: boolean;

  /** @format date-time */
  created: string;

  /** @format date-time */
  updatedAt: string;
}

export interface CustomerAccountRelationCreateRequest {
  relationType: RelationTypeEnum;
  description: string;
  externalId: string;
  stopped: boolean;
}

export interface CustomerAccountRelationRequest {
  relationType: RelationTypeEnum;
  description: string;
  externalId: string;
  stopped: boolean;
}

export interface CustomerAccountRequest {
  ssn: string;
  name?: string;
  designation?: DesignationEnum;
  externallySynced?: boolean | null;
  postPaymentDiscount?: boolean;
  showCreditLimit?: boolean | null;
  hasContract?: boolean;
}

export interface CustomerAccountSettings {
  account: CustomerAccount;

  /** @format email */
  emailOnPos?: string;

  /** @format email */
  emailOnEndOfMonth?: string;
  limitedPayment?: boolean;
  mandatoryCustomerAgent?: boolean;
  mandatoryProjectKey?: boolean;
  mandatoryRequest?: boolean;
  mandatoryRetailLoyaltyCard?: boolean;
  sendEmailOnPos?: boolean;
  sendEmailOnEndOfMonth?: boolean;
  showLimitedPayment: boolean;
}

export interface CustomerAccountSettingsRequest {
  /** @format email */
  emailOnPos?: string;

  /** @format email */
  emailOnEndOfMonth?: string;
  limitedPayment?: boolean;
  mandatoryCustomerAgent?: boolean;
  mandatoryProjectKey?: boolean;
  mandatoryRequest?: boolean;
  mandatoryRetailLoyaltyCard?: boolean;
  sendEmailOnPos?: boolean;
  sendEmailOnEndOfMonth?: boolean;
}

export interface CustomerAddress {
  id: number;
  primary: boolean;
  name: string;
  topic: TopicEnum;
  country: CountryEnum;
  city: string;
  postalCode: string;
  streetAddress: string;

  /** @format date-time */
  created: string;

  /** @format date-time */
  updatedAt: string;
}

export interface CustomerAddressRequest {
  name: string;
  topic: TopicEnum;
  country: CountryEnum;
  city: string;
  postalCode: string;
  streetAddress: string;
}

export interface CustomerAgent {
  id: number;
  account: CustomerAccount;
  name: string;
  phoneNumber: string;
  retailLoyaltyCard: string;
  ssn: string;
  stopped: boolean;
}

export interface CustomerAgentCreateRequest {
  ssn: string;
  phoneNumber: string;
  stopped: boolean;
}

export interface CustomerAgentRequest {
  phoneNumber: string;
  stopped: boolean;
}

export interface CustomerContactInfo {
  /** @format date-time */
  created: string;
  description?: string;
  id: number;
  info: string;
  primary: boolean;
  method: CustomerContactInfoMethodEnum;

  /** @format date-time */
  updatedAt: string;
}

export enum CustomerContactInfoMethodEnum {
  PHONE_NUMBER = "PHONE_NUMBER",
  EMAIL = "EMAIL",
}

export interface CustomerContactInfoRequest {
  description?: string;
  info: string;
  primary: boolean;
  method: CustomerContactInfoMethodEnum;
}

export interface CustomerCreditLimit {
  /** @format double */
  creditLimit: number;

  /** @format double */
  creditRemain: number;

  /** @format double */
  extraCreditLimit: number;

  /** @format date-time */
  extraCreditPeriodEnd: string;
  showCreditLimit: boolean;
  showExtraCreditLimit: boolean;
}

export type DRFAttributeBanner = string;

export type DRFAttributeOutlet = boolean;

export enum DesignationEnum {
  Individual = "Individual",
  Unverified = "Unverified",
  Verified = "Verified",
}

export interface Discount {
  accountNumber: string;
  name: string;
  address: string;
  city: string;
  contract: string;
  discounts: DiscountTop[];
}

export interface DiscountElement {
  code: string;
  name: string;

  /**
   * @format decimal
   * @pattern ^-?\d{0,3}(?:\.\d{0,2})?$
   */
  percentage: string;
}

export interface DiscountMiddle {
  code: string;
  name: string;

  /**
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,1})?$
   */
  percentage?: string;
  discounts: DiscountElement[];
}

export interface DiscountRequestRequest {
  overrideSsn?: string;
}

export interface DiscountTop {
  code: string;
  name: string;

  /**
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,1})?$
   */
  percentage?: string;
  discounts: DiscountMiddle[];
}

export enum ExternalMethodEnum {
  Invoice = "invoice",
  Details = "details",
  Slips = "slips",
  Transactions = "transactions",
  Prepayments = "prepayments",
  Discounts = "discounts",
}

export interface ExternalRequest {
  method: ExternalMethodEnum;

  /** @format date */
  dateFrom?: string | null;

  /** @format date */
  dateTo?: string | null;
  unpaidOnly?: boolean | null;

  /** @format date-time */
  requestedAt: string;

  /**
   * @min 0
   * @max 32767
   */
  statusCode?: number | null;
}

export interface FieldType {
  key: string;
  index: number;
  label: string;
  category: string;
  multiValue: boolean;
  info: string;
  isProperty: boolean;
}

export interface ImpersonateRequest {
  userId: number;
}

export interface InstallmentResponse {
  /** @format date-time */
  dueDate: string;

  /** @format date-time */
  finalDueDate: string;

  /** @format double */
  principal: number;

  /** @format double */
  contractInterest: number;

  /** @format double */
  lendingFee: number;

  /** @format double */
  noticeAndPaymentFee: number;

  /** @format double */
  installmentFee: number;

  /** @format double */
  total: number;
}

export interface LoanApplicationRequest {
  ssn: string;
  name: string;
  phoneNumber: string;

  /** @format email */
  email: string;

  /** @format double */
  amount: number;
  numberOfInstallments: number;
}

export interface LoanCalculationResponse {
  installments: InstallmentResponse[];

  /** @format double */
  total: number;

  /** @format double */
  annualPercentageRate: number;
  isFactored: boolean;

  /** @format double */
  sumContractInterest: number;

  /** @format double */
  sumLendingFee: number;

  /** @format double */
  sumNoticeAndPaymentFee: number;

  /** @format double */
  sumInstallmentFee: number;

  /** @format double */
  sumPrincipal: number;
}

export interface Login {
  user: User;
  saleor: LoginSaleor;
}

export interface LoginRequest {
  phoneNumber: string;
}

export interface LoginSaleor {
  csrfToken: string;
  accessToken: string;
  refreshToken: string;
}

export interface OptionsResponse {
  claimOptions: ClaimOptions;
  creditCardOptions: CreditCardOptions;
}

export interface PaginatedCheckoutList {
  /** @example 123 */
  count?: number;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=400&limit=100
   */
  next?: string | null;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=200&limit=100
   */
  previous?: string | null;
  results?: Checkout[];
}

export interface PaginatedProductInventoryList {
  /** @example 123 */
  count?: number;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?page=4
   */
  next?: string | null;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?page=2
   */
  previous?: string | null;
  results?: ProductInventory[];
}

export interface PaginatedProductListList {
  /** @example 123 */
  count?: number;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?page=4
   */
  next?: string | null;

  /**
   * @format uri
   * @example http://api.example.org/accounts/?page=2
   */
  previous?: string | null;
  results?: ProductList[];
}

export interface PatchedCheckoutRequest {
  shippingMethod?: number | null;

  /** @format uuid */
  collectionPoint?: string | null;
  note?: string;
  division?: string;
  project?: string;
  projectKey?: string;
  purchaseOrder?: string;
  paymentGateway?: string;
}

export interface PatchedCustomerAccountInvitationRequest {
  /** @format email */
  email?: string;
  phoneNumber?: string;
  unvalidatedName?: string;
  createAsAgent?: boolean;
  isRepresentative?: boolean;
  isUserManager?: boolean;
  canViewSalesTransactions?: boolean;
  canPurchase?: boolean;
}

export interface PatchedCustomerAccountMembershipManagementRequest {
  isRepresentative?: boolean;
  isUserManager?: boolean;
  canViewSalesTransactions?: boolean;
  canViewDiscounts?: boolean;
  canPurchase?: boolean;
  active?: boolean;
}

export interface PatchedCustomerAccountRelationRequest {
  relationType?: RelationTypeEnum;
  description?: string;
  externalId?: string;
  stopped?: boolean;
}

export interface PatchedCustomerAccountSettingsRequest {
  /** @format email */
  emailOnPos?: string;

  /** @format email */
  emailOnEndOfMonth?: string;
  limitedPayment?: boolean;
  mandatoryCustomerAgent?: boolean;
  mandatoryProjectKey?: boolean;
  mandatoryRequest?: boolean;
  mandatoryRetailLoyaltyCard?: boolean;
  sendEmailOnPos?: boolean;
  sendEmailOnEndOfMonth?: boolean;
}

export interface PatchedCustomerAddressRequest {
  name?: string;
  topic?: TopicEnum;
  country?: CountryEnum;
  city?: string;
  postalCode?: string;
  streetAddress?: string;
}

export interface PatchedCustomerAgentRequest {
  phoneNumber?: string;
  stopped?: boolean;
}

export interface PatchedCustomerContactInfoRequest {
  description?: string;
  info?: string;
  primary?: boolean;
  method?: CustomerContactInfoMethodEnum;
}

export interface PaymentGateway {
  id: string;
  name: string;
  options: string[];
}

export interface PaymentToken {
  /** @format uuid */
  id: string;
  pan: string;
  expMonth: number;
  expYear: number;
  enabled: boolean;
  used: boolean;

  /** @format date-time */
  validUntil: string;
  brand: string | null;

  /** @format date-time */
  created: string;

  /** @format date-time */
  modified: string;
}

export interface PaymentTokenRequest {
  token: string;
}

export interface PollLogin {
  complete: boolean;
  verificationCode: string;
  authRequest: string;

  /** @format date-time */
  nextAttempt: string | null;
  login: Login | null;
}

export enum PolledLoginMethodEnum {
  App = "App",
  Sim = "Sim",
  Poll = "Poll",
}

export interface PolledLoginRequest {
  method: PolledLoginMethodEnum;
  auth: string;
}

export interface Prepayment {
  prepayments: PrepaymentElement[];

  /**
   * @format decimal
   * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
   */
  totalSum: string;
}

export interface PrepaymentElement {
  accountNumber: string;
  customer: string;

  /** @format date-time */
  date: string;
  note: string;
  attachmentName: string;
  attachment: string;
  attachment_AS400?: string;

  /**
   * @format decimal
   * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
   */
  total: string;
  recordId: string;
  documentId: string;
}

export interface ProductChannelListing {
  /** @format date */
  availableForPurchase?: string | null;
  channel: Channel;
}

export interface ProductChannelListingMapping {
  webstore: ProductChannelListing;
  physicalStores: ProductChannelListing;
}

export interface ProductDetail {
  id: number;

  /** @pattern ^[-\w]+$ */
  slug: string;
  name: string;
  shortDescription: { is: string; en: string };
  firstImage: ProductMedia;
  categories: number[];

  /** @format date-time */
  createdAt: string;

  /** @format date-time */
  updatedAt: string;
  chargeTaxes?: boolean;
  weight?: string | null;
  sellingPoints: string[];
  idealAccessories: string[];
  salesAvailability: string;
  listings: ProductChannelListingMapping;
  defaultVariant?: number | null;
  variants: VariantDetail[];
  inStock: boolean;
  webstoreInStock: boolean;
  description: { is: string; en: string };
  collectAtWarehouse: boolean;
  attributes: Record<string, any>;
  specs: {
    id: string;
    name: string;
    unit: string;
    value: string | number | number | boolean | (string | number | number | boolean)[];
  }[];
  media: ProductMedia[];
  resources: Resource[];
  energyLabel: ProductMedia;
}

export interface ProductInventory {
  id: number;

  /** @pattern ^[-\w]+$ */
  slug: string;
  variants: VariantInventory[];
  inStock: boolean;
  webstoreInStock: boolean;
}

export interface ProductList {
  id: number;

  /** @pattern ^[-\w]+$ */
  slug: string;
  name: string;
  shortDescription: { is: string; en: string };
  firstImage: ProductMedia;
  categories: number[];

  /** @format date-time */
  createdAt: string;

  /** @format date-time */
  updatedAt: string;
  chargeTaxes?: boolean;
  weight?: string | null;
  sellingPoints: string[];
  idealAccessories: string[];
  salesAvailability: string;
  listings: ProductChannelListingMapping;
  defaultVariant?: number | null;
  variants: VariantList[];
  inStock: boolean;
  webstoreInStock: boolean;
}

export interface ProductMedia {
  id: number;
  type?: ProductMediaTypeEnum;
  image: { productGallery?: string; productList?: string; productGallery2x?: string };
  alt?: string;
  externalUrl?: string | null;
  oembedData?: Record<string, any>;
  sortOrder: number | null;
}

export enum ProductMediaTypeEnum {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export interface ProductSync {
  productIds: number[];
  variantIds: number[];
}

export interface ProductSyncRequest {
  ids: number[];
}

export enum RelationTypeEnum {
  Project = "Project",
  Key = "Key",
  Division = "Division",
}

export interface RemittanceSlipElement {
  accountNumber: string;
  customer: string;

  /** @format date-time */
  date: string;
  billNumber: string;
  FL: string;
  bank: string;
  HB: string;

  /** @format date-time */
  dueDate: string;

  /**
   * @format decimal
   * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
   */
  total: string;
  attachment: string;
  status: number;
  statusDescription: string;
  documentId: string;
}

export interface RemittanceSlips {
  slips: RemittanceSlipElement[];

  /**
   * @format decimal
   * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
   */
  totalSum: string;

  /**
   * @format decimal
   * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
   */
  balanceBefore: string | null;

  /**
   * @format decimal
   * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
   */
  balanceAfter: string | null;
}

export interface RemittanceSlipsRequestRequest {
  /** @format date */
  dateFrom: string;

  /** @format date */
  dateTo: string;
  asXlsx?: boolean;
  overrideSsn?: string;
  onlyUnpaid: boolean;
}

export interface Resource {
  /** @format uri */
  url: string;
  fileName: string;
  fileExt: string;
}

export enum RoleEnum {
  Owner = "Owner",
  Admin = "Admin",
  Contractor = "Contractor",
  Employee = "Employee",
}

export interface SalesInvoice {
  invoices: SalesInvoiceElement[];

  /** @format double */
  invoiceSum: number | null;
}

export interface SalesInvoiceDetail {
  details: SalesInvoiceDetailElement[];

  /** @format double */
  amountDifferenceSum: number;

  /** @format double */
  discountAmountSum: number;

  /**
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  aDiscountSum: string;

  /**
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  bDiscountSum: string;

  /**
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  abDiscountSum: string;
}

export interface SalesInvoiceDetailElement {
  accountNumber: string;
  customer: string;

  /** @format date-time */
  date: string;
  attachment: string;
  attachment_AS400: string;
  attachmentName: string;
  department: string;
  job: string;
  request: string;
  buyerSsn: string;
  buyer: string;
  sku: string;
  productName: string;

  /** @format double */
  quantity: number;

  /** @format double */
  amountWithoutDiscount: number;

  /**
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  aDiscount: string;

  /**
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  bDiscount: string;

  /** @format double */
  discountAmount: number;

  /** @format double */
  discountPercentage: number;

  /** @format double */
  amountDifference: number;

  /** @format double */
  amount: number;
  paymentMethod: string;
  contract: string;
  company: string;
  comment: string;
  documentId: string;
}

export interface SalesInvoiceElement {
  accountNumber: string;
  customer: string;

  /** @format date-time */
  date: string;
  attachmentName: string;
  attachment: string;
  attachment_AS400?: string;

  /** @format double */
  totalWithVat: number;

  /** @format double */
  totalWithoutVat: number;
  department: string;
  job?: string;
  request?: string;
  store: string;
  comment?: string;
  paymentMethod: string;
  buyerSsn: string;
  buyer: string;
  type: string;
  documentId: string;
}

export interface SalesInvoiceRequestRequest {
  /** @format date */
  dateFrom: string;

  /** @format date */
  dateTo: string;
  asXlsx?: boolean;
  overrideSsn?: string;
  department?: string;
  job?: string;
}

export interface SalesTransaction {
  transactions: SalesTransactionElement[];

  /**
   * @format decimal
   * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
   */
  balanceBefore: string | null;

  /**
   * @format decimal
   * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
   */
  balanceAfter: string | null;

  /**
   * @format decimal
   * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
   */
  totalSum: string;
}

export interface SalesTransactionElement {
  accountNumber: string;
  customer: string;

  /** @format date-time */
  date: string;
  note: string;
  attachmentName: string;
  attachment: string;
  attachment_AS400?: string;

  /**
   * @format decimal
   * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
   */
  total: string;

  /**
   * @format decimal
   * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
   */
  balance: string;

  /**
   * @format decimal
   * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
   */
  previousBalance: string;
  recordId: string;
  documentId: string;
}

export interface SetEmailRequest {
  /** @format email */
  email: string;
}

export interface ShippingMethod {
  id: number;
  name: string;
  type: ShippingMethodTypeEnum;
  description?: Record<string, any>;
}

export enum ShippingMethodTypeEnum {
  Price = "price",
  Weight = "weight",
}

export enum StatusEnum {
  Success = "success",
}

export interface StrippedUser {
  id: number;

  /** @format email */
  email: string;
  avatar: { default: string };
  verified: StrippedUserVerified;
  customerAgentId: string;
}

export interface StrippedUserRequest {
  avatar: { default: File };
  verified: StrippedUserVerifiedRequest;
}

export interface StrippedUserVerified {
  ssn?: string;
  phoneNumber?: string;
  name?: string;

  /** @format email */
  email?: string;
}

export interface StrippedUserVerifiedRequest {
  ssn?: string;
  phoneNumber?: string;
  name?: string;

  /** @format email */
  email?: string;
}

export interface SyncMessage {
  status: StatusEnum;
}

export interface SyncMessageRequest {
  properties: Record<string, any>;
  content: Record<string, any>;
}

export interface TaxedMoney {
  net: number;
  gross: number;
}

export enum TopicEnum {
  Business = "Business",
  Delivery = "Delivery",
  Home = "Home",
}

export interface User {
  id: number;
  firstName?: string;
  isStaff?: boolean;
  avatar: { default: string };

  /**
   * Síðasta innskráning
   * @format date-time
   */
  lastLogin?: string | null;
  profile: number;
  verified: UserVerified;
  memberships: CustomerAccountMembership[];
}

export interface UserVerified {
  /** @format date-time */
  createdAt: string;

  /** @format date-time */
  updatedAt: string;
  ssn?: string;
  phoneNumber?: string;
  name?: string;
  address?: string;
  postalCode?: string;
  city?: string;

  /** @format email */
  email?: string;

  /** @format date-time */
  emailVerified?: string | null;

  /** @format date-time */
  emailVerificationSentAt?: string | null;

  /** @format date-time */
  termsAccepted?: string | null;
  isDeveloper?: boolean;
}

export interface VariantDetail {
  id: number;
  sku?: string | null;
  name?: string;
  firstImage: ProductMedia;
  trackInventory?: boolean;
  inventoryStatus: Record<string, any>;
  inStock: boolean;
  webstoreInStock: boolean;
  outlet: DRFAttributeOutlet;
  banner: DRFAttributeBanner;
  price: { net: number; gross: number; currency: string };
  pricePerSqm?: { net: number; gross: number; currency: string };
  packagingQuantity?: number;
  sqm?: number;
  meta: VariantMeta;
  media: ProductMedia[];
  resources: Resource[];
  attributes: Record<string, any>;
  specs: {
    id: string;
    name: string;
    unit: string;
    value: string | number | number | boolean | (string | number | number | boolean)[];
  }[];
}

export interface VariantInventory {
  id: number;
  sku?: string | null;
  inStock: boolean;
  webstoreInStock: boolean;
  inventoryStatus: Record<string, any>;
}

export interface VariantList {
  id: number;
  sku?: string | null;
  name?: string;
  firstImage: ProductMedia;
  trackInventory?: boolean;
  inventoryStatus: Record<string, any>;
  inStock: boolean;
  webstoreInStock: boolean;
  outlet: DRFAttributeOutlet;
  banner: DRFAttributeBanner;
  price: { net: number; gross: number; currency: string };
  pricePerSqm?: { net: number; gross: number; currency: string };
  packagingQuantity?: number;
  sqm?: number;
  meta: VariantMeta;
}

export interface VariantMeta {
  style: string;
  size: string;
  color: string;
  unit: string;
}

export interface VerifyEmailRequest {
  /** @format uuid */
  token: string;
}

export interface VirtualCard {
  /** @format uuid */
  id: string;
  pan: string;
  expMonth: number;
  expYear: number;
  enabled: boolean;
  brand: string;
  verified: boolean;

  /** @format date-time */
  created: string;

  /** @format date-time */
  modified: string;
}

export interface VirtualCardRequest {
  /** @format uuid */
  creationToken: string;
}

export interface Warehouse {
  /** @format uuid */
  id?: string;
  name: string;

  /** @pattern ^[-\w]+$ */
  slug: string;
  shippingZones?: number[];
  address: string[];

  /** @format email */
  email?: string;
  clickAndCollectOption?: ClickAndCollectOptionEnum;
}
