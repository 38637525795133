import NextLink from "next/link";
import * as React from "react";
import { useMemo } from "react";

import { PMedium } from "@byko/component-typography";
import { theme } from "@byko/lib-styles";

import { ButtonContainer, HrefContainer } from "./styles";

import type { ButtonProps } from "./interface";
import type { ReactNode } from "react";

export const Button = ({
  label,
  onClick,
  icon: Icon,
  stretch = false,
  className,
  small = false,
  disabled = false,
  buttonColor = "whiteButton",
  customClassName = "button-container",
  as = "button",
  href = "#",
  hrefBehaviour = "internal",
  type,
  ...props
}: ButtonProps): JSX.Element => {
  const iconColor = useMemo((): string => {
    switch (buttonColor) {
      case "blueButton":
        return theme.palette.yellow.main;
      case "greenButton":
        return theme.palette.white.main;
      case "orangeButton":
        return theme.palette.white.main;
      case "redButton":
        return theme.palette.triggers.warning.main;
      case "whiteButton":
        return theme.palette.blue.main;
      case "yellowButton":
        return theme.palette.blue.main;
      default:
        return theme.palette.blue.main;
    }
  }, [buttonColor]);

  const Parent = useMemo(() => {
    if (hrefBehaviour === "internal") {
      return ({ children }: { children: ReactNode }) => (
        <NextLink href={href ?? "#"} legacyBehavior={true}>
          {children}
        </NextLink>
      );
    }
    return ({ children }: { children: ReactNode }) => <>{children}</>;
  }, [href, hrefBehaviour]);

  if (as === "button") {
    return (
      <ButtonContainer
        aria-label={label}
        buttonColor={buttonColor}
        className={`${customClassName ?? ""}${customClassName ? " " : ""}${className ?? ""}`}
        disabled={!!disabled}
        hasIcon={Boolean(Icon)}
        small={small}
        stretch={stretch}
        type={type ?? "button"}
        onClick={onClick ?? undefined}
        {...props}
      >
        <PMedium lineHeight="100%">{label}</PMedium>
        {Icon && (
          <Icon
            className="button-icon"
            iconColor={disabled ? theme.palette.gray[30] : iconColor}
            size={small ? 16 : 24}
          />
        )}
      </ButtonContainer>
    );
  }
  return (
    <Parent>
      <HrefContainer
        aria-label={label}
        buttonColor={buttonColor}
        className={customClassName ?? undefined}
        hasIcon={Boolean(Icon)}
        href={href ?? "#"}
        small={small}
        stretch={stretch}
        target={hrefBehaviour === "internal" ? "_self" : "_blank"}
        type={type ?? "button"}
        {...props}
      >
        <PMedium lineHeight="100%">{label}</PMedium>
        {Icon && (
          <Icon
            className="button-icon"
            iconColor={disabled ? theme.palette.gray[30] : iconColor}
            size={small ? 16 : 24}
          />
        )}
      </HrefContainer>
    </Parent>
  );
};
