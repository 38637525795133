/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const ContextAlternateIcons: BykoReactIconComponentType = ({ iconColor, size }) => {
  return (
    <svg height={size} viewBox="0 0 89.314 82.262" width={size} xmlns="http://www.w3.org/2000/svg">
      <g id="Group_10102" transform="translate(-20178.314 -17401.335)">
        <g id="Group_10101" transform="translate(22034.777 18503.922)">
          <path
            d="M-1783.516-934.867a16.387,16.387,0,0,0-16.369,16.369,1.819,1.819,0,0,0,1.819,1.819,1.846,1.846,0,0,0,.218-.014h28.664a1.846,1.846,0,0,0,.218.014,1.819,1.819,0,0,0,1.819-1.819A16.387,16.387,0,0,0-1783.516-934.867Zm0,3.638a12.73,12.73,0,0,1,12.63,11.152h-25.26A12.73,12.73,0,0,1-1783.516-931.229Z"
            fill={iconColor}
            transform="translate(0 -126.522)"
          />
          <path
            d="M-1781.846-1070.436v.02h1.81a10.924,10.924,0,0,0,10.281,7.265,10.914,10.914,0,0,0,10.3-7.3h1.414c.05,0,.1.008.152.008s.111,0,.165-.008h.068v-.008a1.681,1.681,0,0,0,1.446-1.663,1.681,1.681,0,0,0-1.446-1.663v-.007h-.053a1.722,1.722,0,0,0-.342,0h-.925v-.033c0-.171,0-.321,0-.47a10.766,10.766,0,0,0-5.724-9.607.969.969,0,0,1-.352-.4,4.933,4.933,0,0,0-4.694-3.1h-.012a4.945,4.945,0,0,0-4.7,3.115.966.966,0,0,1-.355.4,10.672,10.672,0,0,0-5.655,8.588c-.04.391-.054.775-.069,1.182,0,.112-.008.225-.013.339h-1.07l-.222,0v.019a1.68,1.68,0,0,0-1.449,1.661A1.679,1.679,0,0,0-1781.846-1070.436Zm12.091,3.648a7.271,7.271,0,0,1-6.284-3.609l.587,0h11.445l.538-.01A7.27,7.27,0,0,1-1769.755-1066.788Zm-5.07-12.983v3.507l.006.182v.06h.019a1.693,1.693,0,0,0,1.674,1.464,1.694,1.694,0,0,0,1.676-1.464h.01v-.089c0-.046.006-.092.006-.138s0-.077-.006-.115v-2.045q0-1.979,0-3.959a1.67,1.67,0,0,1,1.666-1.684,1.683,1.683,0,0,1,1.207.481,1.687,1.687,0,0,1,.5,1.209q0,1.968,0,3.935v1.626l0,.781h.021a1.675,1.675,0,0,0,1.652,1.424,1.675,1.675,0,0,0,1.649-1.41l.022,0v-.016h0v-.483h0v-3.26a7.305,7.305,0,0,1,2.351,5.945h-14.771A7.5,7.5,0,0,1-1774.825-1079.771Z"
            fill={iconColor}
            transform="translate(-13.761)"
          />
        </g>
        <path
          d="M39.483,15.382,26.53,2.5a8.587,8.587,0,0,0-12.077,0L1.5,15.382a5.041,5.041,0,0,0-1.5,3.6V35.649a5.108,5.108,0,0,0,5.123,5.093H35.861a5.108,5.108,0,0,0,5.123-5.093V18.983A5.041,5.041,0,0,0,39.483,15.382ZM25.615,37.347H15.369V30.668a5.123,5.123,0,0,1,10.246,0Zm11.954-1.7a1.7,1.7,0,0,1-1.708,1.7H29.03V30.668a8.538,8.538,0,0,0-17.077,0v6.679H5.123a1.7,1.7,0,0,1-1.708-1.7V18.983a1.706,1.706,0,0,1,.5-1.2L16.868,4.909a5.158,5.158,0,0,1,7.247,0L37.068,17.788a1.706,1.706,0,0,1,.5,1.2Z"
          fill={iconColor}
          transform="translate(20178.314 17419.979)"
        />
        <path
          d="M-2307.452-1300.364c0-1.04,0-2.018,0-3a1.423,1.423,0,0,1,.571-1.186,1.358,1.358,0,0,1,1.483-.173,1.322,1.322,0,0,1,.842,1.232c.011,2.067.03,4.135,0,6.2a2.6,2.6,0,0,1-2.634,2.642c-2.067.036-4.134.018-6.2.005a1.425,1.425,0,0,1-1.373-1.43,1.428,1.428,0,0,1,1.38-1.458c1.393-.016,2.787-.007,4.18-.008h.474c-.308-.288-.546-.522-.8-.742a14.412,14.412,0,0,0-7.672-3.511,14.191,14.191,0,0,0-7.548.989,14.4,14.4,0,0,0-5.2,3.645,14.177,14.177,0,0,0-3.451,6.674c-.122.58-.206,1.167-.293,1.754a1.458,1.458,0,0,1-1.883,1.351,1.453,1.453,0,0,1-1.042-1.64c.1-.779.224-1.56.4-2.326a17.006,17.006,0,0,1,4.263-7.968,17.1,17.1,0,0,1,9.3-5.188,17.069,17.069,0,0,1,12.224,2c.935.537,1.775,1.24,2.657,1.868C-2307.687-1300.557-2307.6-1300.478-2307.452-1300.364Z"
          fill={iconColor}
          transform="matrix(0.966, 0.259, -0.259, 0.966, 22132.066, 19266.5)"
        />
        <path
          d="M-2307.452-1300.364c0-1.04,0-2.018,0-3a1.423,1.423,0,0,1,.571-1.186,1.358,1.358,0,0,1,1.483-.173,1.322,1.322,0,0,1,.842,1.232c.011,2.067.03,4.135,0,6.2a2.6,2.6,0,0,1-2.634,2.642c-2.067.036-4.134.018-6.2.005a1.425,1.425,0,0,1-1.373-1.43,1.428,1.428,0,0,1,1.38-1.458c1.393-.016,2.787-.007,4.18-.008h.474c-.308-.288-.546-.522-.8-.742a14.412,14.412,0,0,0-7.672-3.511,14.191,14.191,0,0,0-7.548.989,14.4,14.4,0,0,0-5.2,3.645,14.177,14.177,0,0,0-3.451,6.674c-.122.58-.206,1.167-.293,1.754a1.458,1.458,0,0,1-1.883,1.351,1.453,1.453,0,0,1-1.042-1.64c.1-.779.224-1.56.4-2.326a17.006,17.006,0,0,1,4.263-7.968,17.1,17.1,0,0,1,9.3-5.188,17.069,17.069,0,0,1,12.224,2c.935.537,1.775,1.24,2.657,1.868C-2307.687-1300.557-2307.6-1300.478-2307.452-1300.364Z"
          fill={iconColor}
          transform="matrix(-0.966, -0.259, 0.259, -0.966, 18319.934, 15618.43)"
        />
      </g>
    </svg>
  );
};
