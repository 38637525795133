import { gql } from "graphql-tag";
import { useQuery } from "react-query";
import { graphQLClient } from "../dato-graphql-client";
import type { HeaderTextRecord } from "../generated-gql/graphql";

// fetches text on page from dato
export function useGetHeaderText() {
  const { data, isLoading, error } = useQuery(
    "get-header-text",
    async () => {
      const request = await graphQLClient.request<{
        headerText: HeaderTextRecord;
      }>(gql`
        query HeaderTextQuery {
          headerText {
            descriptionForLoginPopover
            descriptionForAudkennisappLoginPopover
            headingForLoginPopover
            hintForPhoneNumberInput
            hintForKennitalaInput
            hintTextForSearchBarLargeScreens
            hintTextForSearchBarSmallScreens
            labelForAssistanceWithElectronicIdLink
            labelForLoginButton
            labelForRememberPhoneNumberCheckbox
            labelForRememberKennitalaCheckbox
            labelForMegamenuButton
          }
        }
      `);
      return request;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return { data: data?.headerText, isLoading, error };
}
