/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const ContextCommonUserIcons: BykoReactIconComponentType = ({ iconColor, size }) => {
  return (
    <svg height={size} viewBox="0 0 40.984 40.721" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.483,15.382,26.53,2.5a8.587,8.587,0,0,0-12.077,0L1.5,15.382a5.041,5.041,0,0,0-1.5,3.6V35.649a5.108,5.108,0,0,0,5.123,5.093H35.861a5.108,5.108,0,0,0,5.123-5.093V18.983A5.041,5.041,0,0,0,39.483,15.382ZM25.615,37.347H15.369V30.668a5.123,5.123,0,0,1,10.246,0Zm11.954-1.7a1.7,1.7,0,0,1-1.708,1.7H29.03V30.668a8.538,8.538,0,0,0-17.077,0v6.679H5.123a1.7,1.7,0,0,1-1.708-1.7V18.983a1.706,1.706,0,0,1,.5-1.2L16.868,4.909a5.158,5.158,0,0,1,7.247,0L37.068,17.788a1.706,1.706,0,0,1,.5,1.2Z"
        fill={iconColor}
        transform="translate(0 -0.021)"
      />
    </svg>
  );
};
