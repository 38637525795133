import styled from "styled-components";

import { theme } from "@byko/lib-styles";

export const Content = styled.div`
  display: flex;
  height: fit-content;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;
`;

export const DrawerContent = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 40px;
`;

export const CheckboxAndButton = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 14px 0 14px;
  margin-top: 20px;
  gap: 24px;
`;

// --- term-text.tsx --- //
export const TermsWrapper = styled.div`
  max-height: 250px;
  padding: 24px 14px;
  border: 1px solid ${theme.palette.gray[10]};
  overflow-x: none;
  overflow-y: scroll;
  &::-webkit-scrollbar-track {
    margin-top: 20px;
    background-color: ${theme.palette.white.main};
  }
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${theme.palette.blue.main};
    &::-webkit-scrollbar {
      width: 2px;
    }
  }
`;
