import { categoriesData } from "@byko/lib-category-helper";

import type { Category, CheckoutLineVariant, ProductDetail, ProductList } from "@byko/lib-api-rest";
import type { CheckourVariantParsed } from "@byko/lib-api-products";

export type AnalyticsProductListSection =
  | "front-page-product-slider"
  | "content-page-product-slider"
  | "product-page-product-slider"
  | "filter-results"
  | "search-results"
  | "quick-search-results"
  | "related-product-slider";

export type ShippingTiers = "pickup" | "home-delivery";

export type PaymentType = "Card" | "Invoice" | "Netgíró";

interface AnalyticsProduct {
  item_name: string;
  item_id: string;
  price: string;
  item_brand: string;
  item_variant: string;
  index?: number;
  quantity: number;
  item_list_name?: string;
  item_list_id?: AnalyticsProductListSection;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
}

function snakeCaseToTitleCase(snakeCaseString: string): string {
  return snakeCaseString
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function buildCategoryTree(data: Category[], parent: number | null): Category[] {
  const result: Category[] = [];

  for (const category of data) {
    if (category.parent === parent) {
      result.push(category, ...buildCategoryTree(data, category.id));
    }
  }

  return result;
}

export function buildAnalyticsProduct(
  product: ProductList | ProductDetail | CheckourVariantParsed | CheckoutLineVariant,
  price: string,
  name: string,
  listIndex?: number,
  analyticsSectionId?: AnalyticsProductListSection,
  quantity = 1,
): AnalyticsProduct {
  let sortedCategories: Category[] = [];
  // checking if product has categories, and if it is an array.
  //  CheckoutLineVariant does not have categories, so we need to check for that.
  if ("categories" in product && Array.isArray(product.categories)) {
    const categories: Category[] = [];
    product.categories.forEach((categoryId: number) => {
      const category = categoriesData.get().find((cat: Category) => cat.id === categoryId);
      if (category) {
        categories.push(category);
      }
    });

    sortedCategories = buildCategoryTree(categories ?? [], null);
  }
  const analyticsPrice = price.replace("kr", "").replace(".", "").trim() ?? "0";
  let brand = "";
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    brand = product.attributes.brand ?? "N/A";
    // check if brand is a string or array
    if (Array.isArray(brand)) {
      brand = brand[0];
    }
  } catch (e) {
    brand = "N/A";
  }

  const analyticsProduct: AnalyticsProduct = {
    item_name: name,
    item_id: product.id.toString(),
    price: analyticsPrice,
    item_brand: brand,
    item_variant: "N/A",
    quantity,
  };

  if (analyticsSectionId) {
    analyticsProduct["item_list_name"] = snakeCaseToTitleCase(analyticsSectionId);
    analyticsProduct["item_list_id"] = analyticsSectionId;
  }

  if (listIndex) {
    analyticsProduct["index"] = listIndex;
  }

  const categoryFields = sortedCategories.slice(0, 5).reduce<Record<string, string>>((acc, category, index) => {
    acc[`item_category${index > 0 ? index + 1 : ""}`] = category.name;
    return acc;
  }, {});

  return { ...analyticsProduct, ...categoryFields };
}
