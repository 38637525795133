import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const ArrowRightIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg
      className="byko-icon"
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 6L16 12L10 18" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
    </svg>
  );
};
