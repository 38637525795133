import { useMegaMenuData } from "@byko/lib-api-dato-client";
import { useRecoilState } from "@byko/lib-recoil";
import { useMemo } from "react";

import { isOpenState } from "./store";

import type { UseMegamenuProps } from "./interface";
import type { BykoMenu } from "@byko/lib-api-dato-client";

export const useMegamenu = (): UseMegamenuProps => {
  const [isOpen, setIsOpen] = useRecoilState<boolean>(isOpenState);
  const { data } = useMegaMenuData();
  const menuContent: BykoMenu[] = useMemo(() => (data != null ? (data as BykoMenu[]) : []), [data]);

  const filteredMenuContent = useMemo(() => {
    return menuContent.filter((item) => item.showInMegamenu);
  }, [menuContent]);

  return {
    isOpen,
    setIsOpen,
    menuContent: filteredMenuContent,
  };
};
