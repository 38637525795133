import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const ShoppingBagIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M21 23H3V7H21V23Z" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path
        d="M8 10V5C8 2.8 9.8 1 12 1C14.2 1 16 2.8 16 5V10"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
};
