import styled from "styled-components";

import { gridTheme } from "@byko/lib-styles";

export const Container = styled.div`
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    * {
      transition: 0ms;
    }
    p {
      font-size: 14px;
    }
  }
`;
