import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const CreditLimitIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg
      className="byko-icon"
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17 11H23V22H1V11H6" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path
        d="M9 14H6V11L15 2L18 5L9 14Z"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path d="M6 18H18" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
    </svg>
  );
};
