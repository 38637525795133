import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const RefreshIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg
      className="byko-icon"
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 12C2 6.5 6.5 2 12 2C15.9 2 19.3 4.2 20.9 7.5"
        stroke={iconColor}
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M22 12C22 17.5 17.5 22 12 22C8.09998 22 4.69998 19.8 3.09998 16.5"
        stroke={iconColor}
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M21.8 1.69995L21 7.59995L15 6.79995"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M2.19995 22.2999L2.99995 16.3999L8.99995 17.1999"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
};
