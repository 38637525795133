import styled from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";

export const Container = styled.div`
  display: grid;
  padding-bottom: 16px;
  column-gap: 16px;
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    grid-template-columns: 1fr;
  }
`;

export const Item = styled.div`
  display: flex;
  min-height: 56px;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 6px 16px;
  border-bottom: 1px solid ${theme.palette.gray[20]};
  gap: 16px;
  button {
    min-width: 187px;
  }
  @media screen and (max-width: ${gridTheme.breakpoints.xs}px) {
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 16px;
    row-gap: 8px;
  }
`;

export const ButtonContainer = styled.div`
  width: 189px;
`;
