import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const MeasureBigLineDecorative: BykoReactDecorativeIconComponentType = ({ size, color = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M2 20H6" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
      <path d="M2 28H6" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
      <path d="M2 36H6" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
      <path d="M12 26V36H22L12 26Z" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
      <path d="M2 4V44H42L2 4Z" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
    </svg>
  );
};
