import React from "react";

import { KeyValueField } from "./key-value-field";
import { Container } from "./styles";

import type { KeyValueFieldsProps } from "./interface";

export const KeyValueFields = ({ collection }: KeyValueFieldsProps): JSX.Element => {
  return (
    <Container>
      {collection.map((item) => {
        return <KeyValueField key={item.id ? item.id : item.fileName} item={item} />;
      })}
    </Container>
  );
};
