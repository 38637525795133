import styled from "styled-components";

import { theme } from "@byko/lib-styles";

import type { RadioButtonProps } from "./interface";

export const LabelText = styled.p<RadioButtonProps>`
  position: absolute;
  top: 50%;
  left: ${(props): string => (props.isSimple ? "0" : "-200px")};
  padding: 0px 24px;
  color: ${(props): string => (props.backgroundColor ? props.backgroundColor : theme.palette.white.main)};
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 170%;
  opacity: ${(props): string => (props.hasLabel ? "1" : "0")};
  transform: ${(props): string => (props.isSimple ? "translate3d(0, -45%, 0)" : "translate3d(0, -50%, 0)")};
`;

export const SimpleRadioButtonWrapper = styled.div<RadioButtonProps>`
  position: relative;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: ${(props): string => (props.hasLabel ? "end" : "center")};
  border: 3px solid ${(props): string => (props.backgroundColor ? props.backgroundColor : theme.palette.gray[20])};
  border-radius: 50%;
`;

export const SimpleRadioButtonLabel = styled.label`
  position: absolute;
  top: 2px;
  left: 2px;
  width: 14px;
  height: 14px;
  align-self: center;
  border-radius: 50%;
  pointer-events: auto;
`;

export const StyledSimpleRadioButton = styled.input<RadioButtonProps>`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: ${(props): string => (props.hasLabel ? "245px" : "56px")};
  height: 24px;
  margin-right: 10px;
  cursor: pointer;
  opacity: 0;
  &:hover ~ ${SimpleRadioButtonLabel} {
    background: ${(props): string => (props.disabled ? "" : props.backgroundColor || "")};
    &::after {
      display: block;
      width: 12px;
      height: 12px;
      margin: 4px;
      color: ${theme.palette.white.main};
    }
  }
  &:checked {
    background: ${(props): string => (props.backgroundColor ? props.backgroundColor : theme.palette.gray[20])};
  }
  &:checked + ${SimpleRadioButtonLabel} {
    background: ${(props): string => (props.backgroundColor ? props.backgroundColor : theme.palette.gray[20])};
    &::after {
      display: block;
      width: 12px;
      height: 12px;
      margin: 4px;
      color: ${theme.palette.white.main};
    }
  }
`;
