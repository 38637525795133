export { H1, H2, H3, H4, H5, H6, HeadingsStyles } from "./headings";
export {
  BtnText,
  EyebrowLarge,
  EyebrowMedium,
  EyebrowSmall,
  Subtitle,
  SubtitleBold,
  PLarge,
  PLargeBold,
  PMediumCond,
  PMedium,
  PMediumBold,
  PSmall,
  PSmallBold,
  paragraphStyles,
} from "./non-responsive";
