import * as React from "react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@byko/component-buttons";
import { H6, PLarge } from "@byko/component-typography";
import { LongArrowSideIcons } from "@byko/lib-icons";
import { theme } from "@byko/lib-styles";

import { Container, TextContainer } from "./styles";

export const Guest = (): JSX.Element => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <Container>
      <TextContainer>
        <H6>Ertu viss um að þú viljir halda áfram sem gestur?</H6>
        <PLarge textColor={theme.palette.blue.dark}>
          Gestir fá ekki aðgang að afsláttum, hinu þessu og öllu öðru sem við höfum upp á að bjóða. Vinstamlegast
          staðfestu að þú viljir halda áfram sem gestur.
        </PLarge>
      </TextContainer>

      <Button
        buttonColor="blueButton"
        icon={LongArrowSideIcons}
        label="Já halda áfram"
        stretch={true}
        onClick={handleClick}
      />
    </Container>
  );
};
