import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const ReceiptTwoIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 20V23H13V20.417C13 19.036 14.119 18 15.5 18H3C1.895 18 1 18.895 1 20Z"
        stroke={iconColor}
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M15.5 18C16.794 18 18 18.924 18 20.5C18 21.881 19.119 23 20.5 23C21.881 23 23 21.881 23 20.5V1H7V18"
        stroke={iconColor}
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path d="M12 7H18" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M12 12H18" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
    </svg>
  );
};
