/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const FacebookIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg
      className="byko-icon"
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_751_4392)">
        <path
          d="M24 12.072C23.9997 9.77878 23.3424 7.53367 22.1058 5.60245C20.8692 3.67123 19.1051 2.13478 17.0225 1.17498C14.9398 0.215189 12.6257 -0.127747 10.3542 0.186774C8.08263 0.501294 5.94878 1.4601 4.20524 2.94968C2.4617 4.43926 1.18149 6.39723 0.516158 8.59179C-0.149172 10.7864 -0.171753 13.1256 0.451088 15.3326C1.07393 17.5396 2.31611 19.5219 4.03057 21.0449C5.74503 22.5679 7.85996 23.5677 10.125 23.926V15.541H7.078V12.072H10.125V9.42798C10.125 6.42098 11.917 4.75898 14.657 4.75898C15.5571 4.77189 16.4552 4.8501 17.344 4.99298V7.94698H15.83C15.3742 7.88648 14.9131 8.00946 14.548 8.28887C14.1829 8.56828 13.9437 8.98125 13.883 9.43698C13.8658 9.5646 13.8632 9.69376 13.875 9.82198V12.072H17.2L16.668 15.541H13.868V23.926C16.692 23.4809 19.2644 22.0423 21.1219 19.8691C22.9795 17.6959 24.0001 14.9309 24 12.072Z"
          fill={iconColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_751_4392">
          <rect fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
};
