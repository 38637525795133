import * as React from "react";
import { useCallback, useEffect, useState } from "react";

import { Button } from "@byko/component-buttons";
import { FormikCheckbox } from "@byko/component-selectors";
import { H6, PSmall } from "@byko/component-typography";
import { useAuth } from "@byko/hooks-auth-next";
import { useModal } from "@byko/hooks-modal";
import { FormikProvider, useFormik } from "@byko/lib-formik";
import { CancelLargeIcons, LongArrowSideIcons } from "@byko/lib-icons";

import { acceptTermsStepText } from "./steps";
import { CheckboxAndButton, Content, DrawerContent } from "./styles";
import { TermsText } from "./terms-text";

import type { FormValues } from "./interface";

export const Terms = (): JSX.Element | null => {
  const { acceptTerms, logout } = useAuth();
  const { show, hide } = useModal();

  const [showAlert, setShowAlert] = useState<boolean>(false);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    }
  }, [showAlert]);

  const initialValues: FormValues = {
    acceptTerms: false,
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: async () => {
      await acceptTerms();
    },
  });

  const handleLogout = useCallback(() => {
    hide();
    logout();
  }, [hide, logout]);

  const handleLogoutPrompt = useCallback(() => {
    show({
      title: "Skrá út",
      paragraph: "Ertu viss um að þú viljir hætta við innskráningu?",
      cancelLabel: "Hætta við",
      confirmCallback: handleLogout,
      cancelIcon: CancelLargeIcons,
      confirmLabel: "Staðfesta",
    });
  }, [handleLogout, show]);

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <DrawerContent>
          <Content>
            <H6 bottom={16}>{acceptTermsStepText.head}</H6>
            <PSmall bottom={32}>{acceptTermsStepText.main}</PSmall>
            <TermsText />
          </Content>

          <CheckboxAndButton>
            <FormikCheckbox
              checked={formik.values.acceptTerms}
              id="acceptTerms"
              label="Samþykkja skilmála BYKO"
              name="acceptTerms"
              smallBox={true}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            <Button
              buttonColor="blueButton"
              disabled={formik.isSubmitting || !formik.dirty || !formik.values.acceptTerms}
              icon={LongArrowSideIcons}
              label="Áfram"
              stretch={true}
              type="submit"
            />
            <Button
              buttonColor="redButton"
              icon={CancelLargeIcons}
              label="Hætta við"
              stretch={true}
              type="button"
              onClick={handleLogoutPrompt}
            />
          </CheckboxAndButton>
        </DrawerContent>
      </form>
    </FormikProvider>
  );
};
