import * as React from "react";

import { CardContentTabs } from "@byko/card-content-tabs";

import { TabsContainer } from "./styles";

import type { TabSectionProps } from "./interface";

export const TabsSection = ({ tabCollection }: TabSectionProps): JSX.Element | null => {
  if (tabCollection === null) {
    return null;
  }

  return (
    <TabsContainer>
      <CardContentTabs tabCollection={tabCollection} />
    </TabsContainer>
  );
};
