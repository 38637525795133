import * as React from "react";

import { Tag, TagsWrapper } from "./styles";

import type { TagsProps } from "./interface";

export const Tags = ({ tags }: TagsProps): JSX.Element => {
  return (
    <TagsWrapper>
      {tags.map(({ label, color }) => (
        <Tag key={label} color={color ?? "blue"}>
          {label}
        </Tag>
      ))}
    </TagsWrapper>
  );
};
