import React from "react";
import styled from "styled-components";

import { EyebrowSmall } from "@byko/component-typography";
import { CancelLargeIcons } from "@byko/lib-icons";
import { gridTheme, theme } from "@byko/lib-styles";

export const TagWrapper = styled.div`
  position: relative;
  display: flex;
  min-width: fit-content;
  align-items: center;
  padding: 10px 16px;
  margin-bottom: 2px;
  background-color: ${theme.palette.white.main};
  gap: 8px;
  outline: 1px solid ${theme.palette.blue.light};
  p {
    margin-top: 4px;
  }
  @media screen and (min-width: ${gridTheme.breakpoints.sm}px) {
    padding: 10px 16px;
  }
`;

export const ClearButton = styled.button`
  display: grid;
  outline: none;
  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    border-bottom: 2px solid ${theme.palette.blue.dark};
    content: "";
    transition: 300ms;
  }
  &:focus,
  &:hover {
    &:after {
      width: 100%;
    }
  }
`;

export const FilterChip = ({ children, ...props }: React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element => {
  return (
    <TagWrapper>
      <EyebrowSmall lineHeight="100%" textColor={theme.palette.blue.dark}>
        {children}
      </EyebrowSmall>

      <ClearButton type="button" {...props}>
        <CancelLargeIcons iconColor={theme.palette.blue.dark} size={14} />
      </ClearButton>
    </TagWrapper>
  );
};
