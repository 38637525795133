import React, { useCallback, useEffect, useMemo, useState } from "react";

import { DotLoader } from "@byko/component-loaders";

import { Select } from "@byko/component-select";
import { useAuth } from "@byko/hooks-auth-next";
import { useProductVariants } from "@byko/hooks-product-variants";
import { getPrice } from "@byko/lib-api-price";
import { theme } from "@byko/lib-styles";
import { analytics } from "@byko/lib-analytics";

import {
  Brand,
  Descriptions,
  DiscountedPrice,
  EnergyWrapper,
  FlexColumn,
  FlexRow,
  HiddenAttribute,
  LoadingWrapper,
  OriginalPrice,
  Price,
  PriceContainer,
  PriceText,
  ProductDetailContainer,
  ProductName,
  SkuNumber,
  VariantSelectionOptions,
} from "./styles";

import type { ProductDetail as ProductDetailType, VariantDetail } from "@byko/lib-api-rest";
import type { Maybe } from "@byko/types-utils";
import type { ProductVariantPrices } from "@byko/lib-api-products";

export interface ProductLimitedDetailProps {
  product: ProductDetailType;
  brand?: Maybe<string>;
  activeVariant: VariantDetail;
  setActiveVariantSKU: (value: string) => void;
  skuPrices: ProductVariantPrices | undefined;
  EnergyLabel?: React.FC | null;
}

export const ProductLimitedDetail = ({
  brand,
  product,
  activeVariant,
  setActiveVariantSKU,
  skuPrices,
  EnergyLabel = null,
}: ProductLimitedDetailProps): JSX.Element => {
  const { user } = useAuth();
  const activeVariantSKU = useMemo(() => {
    if (activeVariant) {
      return activeVariant.sku as string;
    } else {
      return "";
    }
  }, [activeVariant]);
  const { activeColor, colorSelectionList, sizeSelectionList, activeSize, handleColorChange, handleSizeChange } =
    useProductVariants(product, activeVariantSKU, setActiveVariantSKU, null);

  const activeVariantPrice = skuPrices?.[activeVariantSKU];
  const [firedAnalyticsImpression, setFiredAnalyticsImpression] = useState<boolean>(false);

  const price = useMemo(() => {
    if (activeVariantPrice && activeVariantPrice.discounted && activeVariantPrice.price) {
      return {
        discounted: {
          gross: getPrice(activeVariantPrice?.discounted?.gross ?? 0),
          net: getPrice(activeVariantPrice?.discounted?.net ?? 0),
        },
        price: {
          gross: getPrice(activeVariantPrice?.price?.gross ?? 0),
          net: getPrice(activeVariantPrice?.price?.net ?? 0),
        },
      };
    } else if (activeVariantPrice && activeVariantPrice.price) {
      return {
        discounted: null,
        price: {
          gross: getPrice(activeVariantPrice?.price?.gross ?? 0),
          net: getPrice(activeVariantPrice?.price?.net ?? 0),
        },
      };
    } else {
      return null;
    }
  }, [activeVariantPrice]);

  const productNotAvailableYet = useMemo(() => {
    return activeVariantPrice?.price.gross === 0;
  }, [activeVariantPrice?.price.gross]);

  const handleItemToString = useCallback((option: { label?: string | null } | null): string => option?.label ?? "", []);

  const descriptionHTML = useMemo(
    () => ({
      __html: product?.description?.is ?? "",
    }),
    [product?.description?.is],
  );

  useEffect(() => {
    if (!firedAnalyticsImpression) {
      setFiredAnalyticsImpression(true);
      let analyticsPrice = "0";
      try {
        analyticsPrice = activeVariant?.price?.gross?.toString() ?? "0";
      } catch (e) {
        // silent
      }
      analytics.addProductPageViewImpression(product, analyticsPrice);
    }
  }, [activeVariant?.price?.gross, firedAnalyticsImpression, product]);

  const eanNumber = activeVariant?.attributes["eanNumber"];

  return (
    <ProductDetailContainer>
      <HiddenAttribute id="product-ean-numbers">{eanNumber}</HiddenAttribute>
      <FlexRow>
        <Brand>{brand ?? ""}</Brand>
        <SkuNumber>VNR. {activeVariant?.sku}</SkuNumber>
      </FlexRow>

      <ProductName fontWeight="400" maxFontSize="48px" textColor={theme.palette.blue.dark} uppercase={true}>
        {product?.name}
      </ProductName>

      <Descriptions className="showMore" dangerouslySetInnerHTML={descriptionHTML} />
      {EnergyLabel && (
        <EnergyWrapper>
          <EnergyLabel />
        </EnergyWrapper>
      )}

      {productNotAvailableYet ? <Price maxFontSize="24px">Vara væntanleg</Price> : null}
      {!productNotAvailableYet ? (
        <PriceContainer>
          {price !== null ? (
            <FlexRow>
              {activeVariantPrice?.isSqmProduct ? (
                <FlexColumn>
                  <PriceText>Fermetraverð</PriceText>
                  {activeVariantPrice?.discounted?.perSqm ? (
                    <DiscountedPrice maxFontSize="16px">
                      {getPrice(activeVariantPrice?.price?.perSqm ?? 0)?.humanReadable} /m²
                    </DiscountedPrice>
                  ) : null}
                  <Price maxFontSize="36px">
                    {price?.discounted?.gross?.original
                      ? getPrice(activeVariantPrice?.discounted?.perSqm ?? 0)?.humanReadable
                      : getPrice(activeVariantPrice?.price?.perSqm ?? 0)?.humanReadable}{" "}
                    /m²
                  </Price>
                </FlexColumn>
              ) : (
                <FlexColumn>
                  <PriceText>{user ? "MITT VERÐ" : "VERÐ"}</PriceText>
                  <Price maxFontSize="36px">
                    {price?.discounted?.gross?.humanReadable ?? price?.price?.gross?.humanReadable}
                  </Price>
                </FlexColumn>
              )}
              {activeVariantPrice?.isSqmProduct ? (
                <FlexColumn>
                  <PriceText>{user ? "MITT VERÐ" : "VERÐ"}</PriceText>
                  <DiscountedPrice maxFontSize="16px">
                    {price?.discounted?.gross?.original
                      ? `${getPrice(price?.price?.gross?.original ?? 0)?.humanReadable} /pk`
                      : null}
                  </DiscountedPrice>
                  <Price maxFontSize="36px">
                    {price?.discounted?.gross?.original
                      ? `${getPrice(price?.discounted?.gross?.original)?.humanReadable} /pk`
                      : `${getPrice(price?.price?.gross?.original ?? 0)?.humanReadable} /pk`}
                  </Price>
                </FlexColumn>
              ) : (
                <FlexColumn>
                  {price?.discounted?.gross?.humanReadable ? (
                    <>
                      <PriceText>Verð áður</PriceText>
                      <OriginalPrice maxFontSize="36px">{price?.price.gross?.humanReadable}</OriginalPrice>
                    </>
                  ) : null}
                </FlexColumn>
              )}
            </FlexRow>
          ) : (
            <LoadingWrapper>
              <DotLoader size={84} />
            </LoadingWrapper>
          )}
        </PriceContainer>
      ) : null}

      <VariantSelectionOptions className="limited-detail">
        {colorSelectionList.length > 1 && (
          <Select
            align="left"
            // disableInactive={true}  FIXME
            fit={true}
            handleSelectedItemChange={handleColorChange}
            hideDefaultPlaceholder={true}
            hideValue={true}
            items={colorSelectionList}
            itemToString={handleItemToString}
            placeholder={activeColor?.label ?? "Veldu lit"}
            selectedItem={activeColor}
            textColor={activeColor?.label ? theme.palette.blue.main : theme.palette.gray[40]}
          />
        )}
        {sizeSelectionList.length > 1 && (
          <Select
            align="left"
            // disableInactive={true}  FIXME
            fit={true}
            handleSelectedItemChange={handleSizeChange}
            hideDefaultPlaceholder={true}
            hideValue={true}
            items={sizeSelectionList}
            itemToString={handleItemToString}
            placeholder={activeSize?.label ?? "Veldu stærð"}
            selectedItem={activeSize}
            textColor={activeSize?.label ? theme.palette.blue.main : theme.palette.gray[40]}
          />
        )}
      </VariantSelectionOptions>
    </ProductDetailContainer>
  );
};
