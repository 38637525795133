import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const ScissorsFilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M29.0669 36.179C28.5299 37.663 34.4629 30.978 32.9239 31.777L6.61494 1.08301C3.82794 5.72801 4.55994 11.672 8.38994 15.502L29.0669 36.179Z"
        fill="#AEAEAE"
      />
      <path
        // eslint-disable-next-line max-len
        d="M28 39C28 34.589 31.589 31 36 31C40.411 31 44 34.589 44 39C44 43.411 40.411 47 36 47C31.589 47 28 43.411 28 39ZM30 39C30 42.309 32.691 45 36 45C39.309 45 42 42.309 42 39C42 35.691 39.309 33 36 33C32.691 33 30 35.691 30 39Z"
        fill="#00416A"
      />
      <path d="M26.327 24.0811L21.894 29.0061L26.121 33.2331L30.456 28.8981L26.327 24.0811Z" fill="#959595" />
      <path
        // eslint-disable-next-line max-len
        d="M39.6101 15.502C43.4401 11.672 44.1721 5.72801 41.3851 1.08301L15.0761 31.777C13.5371 30.978 19.4691 37.663 18.9331 36.179L39.6101 15.502Z"
        fill="#AEAEAE"
      />
      <path
        // eslint-disable-next-line max-len
        d="M12 47C7.589 47 4 43.411 4 39C4 34.589 7.589 31 12 31C16.411 31 20 34.589 20 39C20 43.411 16.411 47 12 47ZM12 33C8.691 33 6 35.691 6 39C6 42.309 8.691 45 12 45C15.309 45 18 42.309 18 39C18 35.691 15.309 33 12 33Z"
        fill="#00416A"
      />
      <path
        // eslint-disable-next-line max-len
        d="M24 28C25.1046 28 26 27.1046 26 26C26 24.8954 25.1046 24 24 24C22.8954 24 22 24.8954 22 26C22 27.1046 22.8954 28 24 28Z"
        fill="#00416A"
      />
    </svg>
  );
};
