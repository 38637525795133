import { fromPairs } from "lodash";

import { getBykoConfig } from "@byko/lib-utils";

export const envPrefix = getBykoConfig("ALGOLIA_ENV_PREFIX");
export const APP_ID = getBykoConfig("ALGOLIA_APP_ID");
export const API_KEY = getBykoConfig("ALGOLIA_API_KEY");

export type SortKey = "" | "popular" | "price_desc" | "price_asc" | "new" | "sku";

export interface AlgoliaSortOption {
  value: SortKey;
  label: string;
}

export const PRODUCT_DEFAULT_SORTING: SortKey = "new";

export const PRODUCT_SORT_OPTIONS: AlgoliaSortOption[] = [
  { value: "", label: "Raða eftir" },
  { value: "popular", label: "Vinsælt" },
  { value: "new", label: "Nýjast efst" },
  { value: "price_desc", label: "Dýrast efst" },
  { value: "price_asc", label: "Ódýrast efst" },
  { value: "sku", label: "Vörunúmer" },
];

export const PRODUCT_SORT_OPTIONS_MAP: Record<string, AlgoliaSortOption> = fromPairs(
  PRODUCT_SORT_OPTIONS.map((o) => [o.value, o]),
);

export const DEFAULT_PAGE_SIZE = 24;

export const SEARCH_INDEXES = {
  products: `${envPrefix}_products`,
  content: `${envPrefix}_content`,
  employees: `${envPrefix}_employees`,
};

export const PRICE_PATH = "variants.price.gross";

export const RANGE_FACETS = [PRICE_PATH];

export const FACET_NAME_OVERRIDES: Record<string, string> = {
  categories: "Vöruflokkar",
  "attributes.brand": "Vörumerki",
  "attributes.type": "Tegund",
  "attributes.typeParent": "Yfirtegund",
  "attributes.environmentalInfo": "Umhverfisupplýsingar",
  [PRICE_PATH]: "Verðbil",
};

export const FACET_UNIT_OVERRIDES: Record<string, string> = {
  [PRICE_PATH]: "kr.",
};
