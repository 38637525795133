import styled from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";

export const Wrapper = styled.div`
  position: relative;
  z-index: 500;
  top: 0;
  width: 100%;
  background-color: ${theme.palette.blue.dark};
`;

export const Banner = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-height: 64px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  padding-right: 40px;
  padding-left: 10px;
  background: ${theme.palette.yellow.main};

  p {
    line-height: 24px;
    text-align: center;
  }
  @media screen and (max-width: 1079px) {
    padding-block: 16px;
    padding-inline: 64px;
  }
`;

export const FormLink = styled.a`
  text-decoration: underline;
  &:focus {
    border-bottom: 1px solid ${theme.palette.gray[60]};
  }
`;

export const BannerCloseWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    top: 16px;
  }
`;
