import { useRecoilValueLoadable } from "@byko/lib-recoil";

import { pricing } from "./store";

import type { ProductPricing } from "./store";

export const usePrice = (sku: string): ProductPricing | null => {
  const data = useRecoilValueLoadable(pricing(sku));
  switch (data.state) {
    case "hasValue":
      return data.contents;
    default:
      return null;
  }
};

export { getPrice } from "./store";
export type { PriceItem } from "./batch-fetch";

export const pricingStore = pricing;
