import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { isNotNull } from "@byko/types-utils";

import { CheckoutLineDeleteDocument } from "../../../generated/graphql";

import type {
  CheckoutFragment,
  CheckoutLineDeleteMutation,
  CheckoutLineDeleteMutationVariables,
} from "../../../generated/graphql";
import type { Maybe } from "@byko/types-utils";

interface UseClearCheckoutProps {
  token: string;
  checkout: Maybe<CheckoutFragment>;
}

export const useClearCheckout = ({ checkout, token }: UseClearCheckoutProps): (() => void) => {
  const [handleDelete] = useMutation<CheckoutLineDeleteMutation, CheckoutLineDeleteMutationVariables>(
    CheckoutLineDeleteDocument,
  );
  return useCallback(async () => {
    const skus = (checkout?.lines ?? []).filter(isNotNull).map(({ id }) => id);
    await Promise.allSettled(
      skus.map((sku) =>
        handleDelete({
          variables: {
            token,
            lineId: sku,
          },
        }),
      ),
    );
  }, [checkout?.lines, handleDelete, token]);
};
