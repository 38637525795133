import React, { useCallback, useMemo } from "react";

import { Button } from "@byko/component-buttons";
import { PLarge, PLargeBold, PMedium } from "@byko/component-typography";
import { DownloadIcons } from "@byko/lib-icons";
import { theme } from "@byko/lib-styles";
import { useWindowSize } from "@byko/lib-styles";

import { ButtonContainer, Item } from "./styles";

import type { KeyValueFieldProps } from "./interface";

export const KeyValueField = ({ item }: KeyValueFieldProps): JSX.Element | null => {
  const { activeBreakPoint } = useWindowSize();
  const isDownloadField = useMemo((): boolean => {
    return Boolean(item.url || item.filePath);
  }, [item.url, item.filePath]);

  const handleDownload = useCallback(() => {
    if (!item.url && !item.filePath) {
      return;
    }

    if (item.filePath) {
      window.open(item.filePath, "_blank");
      return;
    }

    window.open(item.url, "_blank");
  }, [item.filePath, item.url]);

  if (!isDownloadField && !item.value) {
    return null;
  }

  const label = item.key ? item.key : item.fileName;
  const shortLabel = label && label.length > 20 ? `${label.substring(0, 20)}...` : label;
  const value = item.value ? item.value : item.fileName;

  return (
    <Item>
      {["lg", "md", "sm"].includes(activeBreakPoint) ? (
        <PLargeBold textColor={theme.palette.blue.dark}>{label}</PLargeBold>
      ) : (
        <PMedium textColor={theme.palette.blue.dark}>{shortLabel}</PMedium>
      )}
      {isDownloadField && (
        <ButtonContainer>
          <Button icon={DownloadIcons} label="Sækja skjal" small={true} stretch={true} onClick={handleDownload} />
        </ButtonContainer>
      )}
      {!isDownloadField && ["lg", "md", "sm"].includes(activeBreakPoint) && (
        <PLarge textColor={theme.palette.blue.dark}>{value}</PLarge>
      )}
      {!isDownloadField && ["s", "xs"].includes(activeBreakPoint) && (
        <PMedium textColor={theme.palette.blue.dark}>{value}</PMedium>
      )}
    </Item>
  );
};
