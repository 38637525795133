import { httpLink } from "@trpc/client/links/httpLink";

import { getBykoConfig } from "@byko/lib-utils";

import { trpc } from "./base";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useMegaMenuData = () => {
  return trpc.useQuery(["getMenu"], {
    refetchOnWindowFocus: false,
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getMegaMenuData = (url: string) => () => {
  const fullurl = getBykoConfig("CMS_API_URL");
  return trpc
    .createClient({
      links: [httpLink({ url: url ?? fullurl })],
    })
    .query("getMenu");
};
