import { getBykoConfig } from "@byko/lib-utils";

export function getConfig() {
  const values = {
    DATO_GRAPHQL_URL: getBykoConfig("DATO_GRAPHQL_URL"),
    DATO_TOKEN_CMS: getBykoConfig("DATO_TOKEN_CMS"),
  };

  return values;
}
