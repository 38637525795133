import React from "react";

import { Button } from "@byko/component-buttons";

import { MobileAccordionRowWrapper, MobileAccordionWrapper } from "./styles";

import type { ResultsSortingBlockProps } from "../../interface";

export const MobileSortingBlock = ({ sortingOptions }: ResultsSortingBlockProps): JSX.Element => {
  // TODO: onClick
  return (
    <MobileAccordionRowWrapper>
      <MobileAccordionWrapper>
        {sortingOptions?.map((item) => (
          <Button key={item.id} customClassName="accordion-button-sorting" label={item.label} stretch={true} />
        ))}
      </MobileAccordionWrapper>
    </MobileAccordionRowWrapper>
  );
};
