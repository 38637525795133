import styled from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";

import type { SliderProgressProps } from "./interface";

export const SliderButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: ${gridTheme.maxContentWidth}px;
  align-items: center;
  justify-content: end;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
  gap: 12px;
  button {
    border: 1px solid ${theme.palette.gray[20]};
  }
`;

export const SliderProgress = styled.div<SliderProgressProps>`
  position: relative;
  width: 100%;
  height: 1px;
  background-color: ${theme.palette.gray[30]};
  &:after {
    position: absolute;
    width: ${(props): string => String(props.progress)}%;
    height: 3px;
    background-color: ${theme.palette.blue.main};
    content: "";
    transform: translate3d(0, -1px, 0);
    transition: 300ms;
  }
`;
