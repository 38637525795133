import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  height: 100%;
  justify-content: space-between;
  padding-bottom: 40px;
  margin-top: 40px;
  grid-template-rows: 1fr auto;
`;

export const TextContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 16px;
`;
