import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const PaintBucket2LineDecorative: BykoReactDecorativeIconComponentType = ({ size, color = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1144_7152)">
        <path
          d="M4.80005 24L26 39.6C27.6 41.2 32.3 38.9001 36.6 34.7001C40.9 30.5001 43.1 25.7 41.5 24.1L26 2.80005"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="2.5"
        />
        <path
          // eslint-disable-next-line max-len
          d="M19.6315 17.6693C25.4893 11.8115 28.3385 5.16337 25.9954 2.82025C23.6523 0.477126 17.0041 3.32634 11.1463 9.18415C5.28849 15.042 2.43927 21.6901 4.78239 24.0332C7.12552 26.3764 13.7737 23.5272 19.6315 17.6693Z"
          stroke={color}
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2.5"
        />
        <path
          d="M2 40C2 37.8 6 32 6 32C6 32 10 37.8 10 40C10 42.2 8.2 44 6 44C3.8 44 2 42.2 2 40Z"
          stroke={color}
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2.5"
        />
        <path
          d="M24 22H32C36.4 22 40 18.4 40 14C40 9.6 36.4 6 32 6H28.4"
          stroke={color}
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_1144_7152">
          <rect fill="white" height="48" width="48" />
        </clipPath>
      </defs>
    </svg>
  );
};
