import Script from "next/script";
import React, { createContext, useContext, useMemo } from "react";

import type { Warehouse } from "@byko/lib-api-rest";

type WarehouseContext = Warehouse[] | null;
const WarehouseContext = createContext<WarehouseContext>(null);

export const useWarehouses = (): Warehouse[] => {
  const data = useContext(WarehouseContext);
  if (!data) {
    throw new Error("Invalid usage of context");
  }
  return data;
};

export const WarehousesProvider = ({
  warehouses,
  children,
}: {
  warehouses: Warehouse[];
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  const script = useMemo(() => {
    return {
      __html: `window.__BYKO_WAREHOUSES__ = JSON.parse(${JSON.stringify(JSON.stringify(warehouses))})`,
    } as { __html: string };
  }, [warehouses]);
  return (
    <>
      <Script dangerouslySetInnerHTML={script} id="byko-warehouses" strategy="beforeInteractive" />
      <WarehouseContext.Provider value={warehouses}>{children}</WarehouseContext.Provider>
    </>
  );
};
