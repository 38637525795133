import { atom } from "@byko/lib-recoil";

export const drawerState = atom<boolean>({
  key: "drawerState",
  default: false,
});

export const drawerStateLoading = atom<boolean>({
  key: "drawerStateLoading",
  default: false,
});
