import styled from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";

import type { ProductCardContainerProps, TagBlockProps } from "./interface";
import Link from "next/link";
import { ImageWrapper } from "./components/product-image/styles";
import { ProdInfoWrapper } from "./components/product-card-information/styles";

export const ProductCardContainer = styled.div<ProductCardContainerProps>`
  position: relative;
  display: flex;
  width: 328px;
  max-height: 570px;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${theme.palette.gray[10]};
  background-color: ${theme.palette.white.main};
  box-shadow: ${(props): string => (props.showDetail ? "0px 4px 20px rgba(0, 0, 0, 0.1)" : "none")};
  transition: all 0.5s;
  &:hover {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  }
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    width: ${(p): string => (p.smallMobileCard ? "202px" : "273px")};
    min-height: 509px;
  }
  .icon-button {
    transition: 0ms !important;
    * {
      transition: 0ms !important;
    }
  }

  ${ImageWrapper} {
    border-bottom: 1px solid ${theme.palette.gray[10]};
  }

  ${ProdInfoWrapper} {
    border-bottom: 1px solid ${theme.palette.gray[10]};
  }

  &.rent {
    border: 1px solid ${theme.palette.yellow.main};
    .bottom-card-content {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: flex-end;
    }
    ${ImageWrapper} {
      border-bottom: 1px solid ${theme.palette.yellow.main};
    }
    ${ProdInfoWrapper} {
      border-bottom: none;
    }
  }
  &.special-no-price {
    border: 1px solid ${theme.palette.blue.lighter};
    ${ImageWrapper} {
      border-bottom: 1px solid ${theme.palette.blue.lighter};
    }
    ${ProdInfoWrapper} {
      border-bottom: none;
    }
    .bottom-card-content {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
  .custom-button {
    border: 1px solid ${theme.palette.blue.main};
    ${ImageWrapper} {
      border-bottom: 1px solid ${theme.palette.blue.main};
    }
    ${ProdInfoWrapper} {
      border-bottom: 1px solid ${theme.palette.blue.main};
    }
  }
`;

export const TagContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: fit-content;
  pointer-events: none;
`;

export const IconBackdrop = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  background-color: ${theme.palette.green.dark};
`;

export const IconContainer = styled(Link)`
  position: absolute;
  z-index: 1;
  top: 16px;
  right: 16px;
  width: fit-content;
  svg {
    pointer-events: none;
  }
`;

export const TagText = styled.p`
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  text-align: center;
`;

export const TagBlock = styled.div<TagBlockProps>`
  position: relative;
  display: flex;
  overflow: hidden;
  width: fit-content;
  align-items: center;
  padding: 16px 36px 16px 20px;
  margin-right: 8px;
  margin-bottom: 8px;
  background-color: ${(props): string =>
    props.color === "blue" ? theme.palette.blue.main : theme.palette.yellow.main};
  ${TagText} {
    color: ${(props): string => (props.color === "blue" ? theme.palette.yellow.main : theme.palette.blue.dark)};
    -webkit-font-smoothing: antialiased;
  }
  &:before {
    position: absolute;
    top: 0;
    right: 0;
    width: 28px;
    height: 100%;
    background-color: white;
    content: "";
    transform: rotate(45deg);
    transform-origin: bottom left;
  }
  &:after {
    position: absolute;
    top: 0;
    right: 0;
    width: 28px;
    height: 100%;
    background-color: white;
    content: "";
    transform: rotate(-45deg);
    transform-origin: top left;
  }
`;
