import styled from "styled-components";
import { ifProp } from "styled-tools";

import { PSmall } from "@byko/component-typography";
import { theme } from "@byko/lib-styles";

import type { LabelProps, TextProps } from "./interface";

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 2px;
`;

export const Label = styled.label<LabelProps>`
  position: relative;
  display: flex;
  flex-direction: ${ifProp({ reverse: true }, "row-reverse", "row")};
  align-items: center;
  justify-content: ${ifProp({ reverse: true }, "flex-end", "flex-start")};
  cursor: pointer;
  gap: ${(props): string => (props.smallBox ? "8px" : "20px")};
  opacity: 1;
`;

export const Text = styled(PSmall)<TextProps>`
  color: ${ifProp({ white: true }, `${theme.palette.white.main}`, `${theme.palette.blue.main}`)};
  cursor: pointer;
`;

export const QuantityNumberWrapper = styled(PSmall)`
  color: ${theme.palette.gray[50]};
`;

export const StyledCheckbox = styled.div<TextProps>`
  position: relative;
  display: flex;
  width: ${(props): string => (props.smallBox ? "16px" : "24px")};
  min-width: ${(props): string => (props.smallBox ? "16px" : "24px")};
  height: ${(props): string => (props.smallBox ? "16px" : "24px")};
  min-height: ${(props): string => (props.smallBox ? "16px" : "24px")};
  align-items: center;
  justify-content: center;
  border: 1px solid ${ifProp({ white: true }, `${theme.palette.white.main}`, `${theme.palette.blue.lighter}`)};
  background-color: ${ifProp({ white: true }, `${theme.palette.gray[5]}`, `${theme.palette.white.main}`)};
  outline: 1px solid ${ifProp({ white: true }, `${theme.palette.white.main}`, `${theme.palette.blue.lighter}`)};
  pointer-events: none;
  transition: all 150ms;
`;

export const CheckboxInput = styled.input<TextProps>`
  position: absolute;
  border-radius: 0;
  cursor: pointer;
  opacity: 0;

  &:hover ~ ${StyledCheckbox} {
    border: 1px solid ${ifProp({ white: true }, `${theme.palette.white.main}`, `${theme.palette.blue.main}`)};
    outline: 1px solid ${ifProp({ white: true }, `${theme.palette.white.main}`, `${theme.palette.blue.main}`)};
  }

  &:checked ~ ${StyledCheckbox} {
    width: ${(props): string => (props.smallBox ? "16px" : "24px")};
    height: ${(props): string => (props.smallBox ? "16px" : "24px")};
    border: 1px solid ${theme.palette.transparent.main};
    background-color: ${ifProp({ white: true }, `${theme.palette.white.main}`, `${theme.palette.blue.main}`)};
    outline: 1px solid ${theme.palette.transparent.main};
  }

  &:focus ~ ${StyledCheckbox} {
    border: 1px solid ${ifProp({ white: true }, ` ${theme.palette.gray[5]}`, `${theme.palette.white.main}`)};
    outline: 1px solid ${ifProp({ white: true }, `${theme.palette.white.main}`, `${theme.palette.blue.main}`)};
  }

  &:not(:checked) {
    &:focus ~ ${StyledCheckbox} {
      width: ${(props): string => (props.smallBox ? "16px" : "24px")};
      height: ${(props): string => (props.smallBox ? "16px" : "24px")};
      svg {
        opacity: 0;
      }
      &:after {
        position: absolute;
        border: 2px solid ${ifProp({ white: true }, `${theme.palette.white.main}`, `${theme.palette.blue.main}`)};
        background-color: ${ifProp({ white: true }, ` ${theme.palette.gray[5]}`, `${theme.palette.white.main}`)};
        content: "";
        inset: 0;
      }
    }
  }

  &:disabled {
    ~ ${Text} {
      color: ${ifProp({ white: true }, `${theme.palette.white.main}`, `${theme.palette.gray[20]}`)};
      cursor: not-allowed;
    }
    ~ ${Label} {
      border: 2px solid ${theme.palette.gray[20]};
      cursor: not-allowed;
    }
    ~ ${StyledCheckbox} {
      border: 2px solid ${ifProp({ white: true }, `${theme.palette.white.main}`, `${theme.palette.gray[20]}`)};
      background-color: ${ifProp({ white: true }, `${theme.palette.gray[5]}`, `${theme.palette.white.main}`)};
      cursor: not-allowed;
      outline: 1px solid ${ifProp({ white: true }, `${theme.palette.white.main}`, `${theme.palette.gray[20]}`)};
    }
  }
`;

export const ErrorText = styled.p`
  padding-left: 24px;
  margin-top: 4px;
  color: ${theme.palette.triggers.warning.main};
  font-size: 14px;
  text-transform: uppercase;
`;
