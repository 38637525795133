import { useCallback, useEffect, useRef } from "react";

import { useRecoilValueLoadable, useSetRecoilState } from "@byko/lib-recoil";

import {
  COMPARE_PRODUCT_LOCAL_STORAGE_KEY,
  MAX_PRODUCTS_TO_COMPARE,
  productHasBeenInitialzed,
  productSlugState,
  productsForComparisonState,
} from "./store";

import type { UseCompareProductsProps } from "./interface";
import type { ProductDetailAugmented } from "@byko/lib-api-products";

export const useCompareProducts = (): UseCompareProductsProps => {
  const setProductSlug = useSetRecoilState(productSlugState);
  const setHasBeenInitialized = useSetRecoilState(productHasBeenInitialzed);
  const productsForComparison = useRecoilValueLoadable(productsForComparisonState);
  const productsRef = useRef<ProductDetailAugmented[]>([]);

  if (productsForComparison.state === "hasValue") {
    productsRef.current = productsForComparison.contents;
  }

  useEffect(() => {
    const productSlugLocalStorage = localStorage.getItem(COMPARE_PRODUCT_LOCAL_STORAGE_KEY);
    if (!productSlugLocalStorage) {
      setHasBeenInitialized(true);
      return;
    }
    const productSlugLocalStorageParsed = JSON.parse(productSlugLocalStorage);
    setProductSlug(productSlugLocalStorageParsed);
    setHasBeenInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeProduct = useCallback(
    (slug: string) => {
      setProductSlug((value) => value.filter((product) => product !== slug));
    },
    [setProductSlug],
  );

  const addProduct = useCallback(
    (slug: string): void => {
      setProductSlug((value) => [...value, slug].slice(0, MAX_PRODUCTS_TO_COMPARE));
    },
    [setProductSlug],
  );

  const clearAllProducts = useCallback(() => {
    localStorage.removeItem(COMPARE_PRODUCT_LOCAL_STORAGE_KEY);
    setProductSlug([]);
  }, [setProductSlug]);

  return {
    products: productsRef.current,
    removeProduct,
    addProduct,
    clearAllProducts,
  };
};
