import React, { useCallback } from "react";

import { H6 } from "@byko/component-typography";
import { ArrowRightIcons } from "@byko/lib-icons";
import { theme } from "@byko/lib-styles";

import { NavigationOption } from "./styles";

import type { NavigationOptionRowProps } from "./interface";

export const NavigationOptionRow = ({
  active,
  index,
  setActive,
  label,
  isLink,
  tabIndex,
  goToPage,
}: NavigationOptionRowProps): JSX.Element => {
  const setLocalActive = useCallback(() => {
    setActive(index);
  }, [index, setActive]);

  return (
    <NavigationOption active={active} isLink={isLink} tabIndex={tabIndex} onClick={isLink ? goToPage : setLocalActive}>
      {isLink === false && <ArrowRightIcons iconColor={theme.palette.yellow.main} size={32} />}
      <H6 maxFontSize="28px" textColor={theme.palette.white.main}>
        {label}
      </H6>
    </NavigationOption>
  );
};
