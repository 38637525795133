import React, { useCallback } from "react";

import { Container, Label } from "./styles";

import type { TabProps } from "./interface";

export const Tab = ({ label, icon: Icon, highlightColor, active, index, setActive }: TabProps): JSX.Element => {
  const handleClick = useCallback(() => {
    setActive(index);
  }, [setActive, index]);

  return (
    <Container active={active} onClick={handleClick}>
      <Label textColor={highlightColor}>{label}</Label>
      {Icon && <Icon iconColor={highlightColor} size={24} />}
    </Container>
  );
};
