import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const NewsletterineDecorative: BykoReactDecorativeIconComponentType = ({ size, color = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M10 16.2L2 22V46H46V22L38 16.2" stroke={color} strokeMiterlimit="10" strokeWidth="2" />
      <path d="M10 26.4V2H38V26.4" stroke={color} strokeMiterlimit="10" strokeWidth="2" />
      <path d="M2 22L46 46" stroke={color} strokeMiterlimit="10" strokeWidth="2" />
      <path d="M46 22L24 34" stroke={color} strokeMiterlimit="10" strokeWidth="2" />
      <path d="M18 12H30" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M18 20H30" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
    </svg>
  );
};
