import React, { useCallback, useEffect, useState } from "react";

import { Button, IconButton } from "@byko/component-buttons";
import { BagAddIcons, LongArrowRightIcons } from "@byko/lib-icons";
import { MainLoader } from "@byko/component-loaders";
import { ButtonWrapper, LoaderContainer } from "./styles";

import type ProductCardButtonsProps from "./interface";

export const ProductCardButtons = ({
  onClickProductDetailPage,
  addToCartClick,
  disabled,
  disabledAddToCart,
  showDetail,
  outOfStock,
}: ProductCardButtonsProps): JSX.Element => {
  const [addingToCart, setAddingToCart] = useState<boolean>(false);
  const handleClickAddToCart = useCallback(() => {
    setAddingToCart(true);
    addToCartClick();
  }, [addToCartClick]);

  useEffect(() => {
    if (!disabledAddToCart) {
      setAddingToCart(false);
    }
  }, [disabledAddToCart]);

  return (
    <ButtonWrapper className="swiper-no-swiping" disabledAddToCart={disabledAddToCart ?? false}>
      {!showDetail ? (
        <>
          {addingToCart ? (
            <LoaderContainer>
              <MainLoader size={34} />
            </LoaderContainer>
          ) : (
            <IconButton
              ariaLabel="Setja vöru í körfu"
              buttonColor="blueButton"
              className="add-to-cart-button"
              disabled={addingToCart || outOfStock}
              icon={BagAddIcons}
              small={false}
              onClick={handleClickAddToCart}
            />
          )}
        </>
      ) : null}

      <Button
        disabled={disabled}
        icon={LongArrowRightIcons}
        label="Skoða vöru"
        stretch={true}
        onClick={onClickProductDetailPage}
      />
    </ButtonWrapper>
  );
};
