/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/prefer-namespace-keyword */
import { restApi } from "@byko/lib-api-rest";
import { isNotNull, isSSR } from "@byko/types-utils";

import type { Category } from "@byko/lib-api-rest";

interface Item {
  slug: string;
  children: Item[];
}
const getCategoryID = (slug: string): number | null => {
  try {
    const id = slug?.split("-")?.pop() ?? null;
    const numberID = parseInt(id ?? "0", 10);
    return numberID;
  } catch (e) {
    return null;
  }
};
export const getCategoriesWithChildren = (slug: string, value: Item[]): number[] | null => {
  const categories = value?.find((e) => e.slug === "voruflokkar")?.children ?? [];
  const newValue = categories.map((e) => {
    if (e.slug === slug) {
      if (e.children) {
        return [e, ...e.children];
      }
      return [e];
    }
    return getCategoriesWithChildren(slug, e.children ?? null);
  });
  if (newValue.length === 0) {
    return null;
  }
  return newValue
    .flat()
    .filter(isNotNull)
    .map((e) => (typeof e === "number" ? e : getCategoryID(e.slug)))
    .filter(isNotNull);
};

const findCategory =
  (cat: Category[]) =>
  (id: number): number[] => {
    return (cat || [])
      .map((e): number[] => {
        if (e.parent === id) {
          return [e.id, ...findCategory(cat)(e.id)].flat();
        }
        return [];
      })
      .flat();
  };

const MAX_LENGTH = 3;

declare global {
  interface Window {
    __BYKO_CATEGORIES__: Category[];
  }
}

// eslint-disable-next-line unused-imports/no-unused-vars
declare module global {
  let __BYKO_CATEGORIES__: Category[];
}

export const initialFetch = async (): Promise<Category[]> => {
  return (await restApi.categoriesList())?.data ?? [];
};

export const categoriesData = {
  get: (): Category[] => {
    return isSSR() ? global.__BYKO_CATEGORIES__ ?? [] : window.__BYKO_CATEGORIES__ ?? [];
  },
};

export const getCategoriesAndChildren = (id: number): number[] => {
  const data = categoriesData.get();
  const cats = findCategory(data)(id);
  return cats;
};

export const getCategoriesAndBreadcrumb = (
  ids: number[],
  product?: { slug: string; title: string } | null | undefined,
): {
  title: string;
  url: string;
}[] => {
  const data = categoriesData.get();
  const cats = data.filter((e) => ids.includes(e.id));
  let searchIds = ids;
  const catArr: { title: string; url: string }[] = [];
  while (searchIds.length > 0) {
    const primaryId = cats.find((e) => searchIds.includes(e.id) && !searchIds.includes(e.parent ?? -1));
    if (primaryId) {
      searchIds = searchIds.filter((e) => e !== primaryId.id);
      catArr.push({
        title: primaryId.name,
        url: `/voruflokkar/${primaryId.slug ?? ""}`,
      });
      if (catArr.length === MAX_LENGTH) {
        break;
      }
    } else {
      break;
    }
  }

  if (product) {
    return [
      {
        title: "Forsíða",
        url: "/",
      },
      ...(catArr ?? []),
      {
        title: product.title,
        url: `/vara/${product.slug}`,
      },
    ];
  }
  return [
    {
      title: "Forsíða",
      url: "/",
    },
    ...(catArr ?? []),
  ];
};
