import cn from "classnames";
import { range } from "lodash";
import Link from "next/link";
import React from "react";
import styled from "styled-components";

import { theme } from "@byko/lib-styles";

import type { ParsedUrlQuery } from "querystring";
import { ArrowLeftIcons, ArrowRightIcons } from "@byko/lib-icons";

interface Props {
  page?: number;
  maxPage?: number;
  pageMargin?: number;
  pathname: string;
  query: ParsedUrlQuery;
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
`;

const FlipLink = styled.div`
  display: flex;
  width: 40px;
  height: 36px;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.15s;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const PageLink = styled.span`
  display: flex;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 1px solid ${theme.palette.gray[10]};
  border-radius: 0;
  margin: 0;
  background-color: ${theme.palette.white.main};
  color: ${theme.palette.blue.main};
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: 150ms;

  &:hover {
    background-color: ${theme.palette.gray[5]};
  }
  &.selected {
    background-color: ${theme.palette.blue.main};
    color: ${theme.palette.white.main};
    pointer-events: none;
  }
`;

const Spacer = styled.div`
  width: 2px;
  height: 12px;
  background-color: ${theme.palette.blue.main};
  margin-inline: 8px;
`;

function scrollTop() {
  if (typeof window !== "undefined") {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
}

export default function PaginationMenu({ page = 1, maxPage = 1, pageMargin = 2, pathname, query }: Props): JSX.Element {
  const hasLess = page > 1;
  const hasMore = page < maxPage;

  const showMinEdge = page > pageMargin + 1;
  const showMaxEdge = page < maxPage - pageMargin;

  const min = Math.max(page - pageMargin, 1);
  const max = Math.min(page + pageMargin, maxPage);

  const linkProps = (p: number) => ({
    href: {
      pathname,
      query: { ...query, page: p },
    },
    shallow: true,
  });

  return (
    <Wrapper>
      {hasLess && (
        <Link {...linkProps(page - 1)} onClick={scrollTop}>
          <FlipLink>{<ArrowLeftIcons iconColor={theme.palette.blue.main} size={18} />}</FlipLink>
        </Link>
      )}
      {showMinEdge && (
        <>
          <Link {...linkProps(1)} onClick={scrollTop}>
            <PageLink>1</PageLink>
          </Link>
          <Spacer />
        </>
      )}
      {range(min, max + 1).map((p: number) => (
        <Link key={p} {...linkProps(p)} onClick={scrollTop}>
          <PageLink className={cn({ selected: p === page })}>{p}</PageLink>
        </Link>
      ))}
      {showMaxEdge && (
        <>
          <Spacer />
          <Link {...linkProps(maxPage)} onClick={scrollTop}>
            <PageLink>{maxPage}</PageLink>
          </Link>
        </>
      )}
      {hasMore && (
        <Link {...linkProps(page + 1)} onClick={scrollTop}>
          <FlipLink>{<ArrowRightIcons iconColor={theme.palette.blue.main} size={18} />}</FlipLink>
        </Link>
      )}
    </Wrapper>
  );
}
