/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const TimeClock1FilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 45C35.598 45 45 35.598 45 24C45 12.402 35.598 3 24 3C12.402 3 3 12.402 3 24C3 35.598 12.402 45 24 45Z"
        fill="white"
      />
      <path
        d="M24 47C19.451 47 15.0042 45.6511 11.2219 43.1238C7.43956 40.5965 4.4916 37.0044 2.75078 32.8017C1.00997 28.599 0.554489 23.9745 1.44195 19.5129C2.32941 15.0514 4.51995 10.9532 7.73655 7.73655C10.9532 4.51995 15.0514 2.32941 19.5129 1.44195C23.9745 0.554489 28.599 1.00997 32.8017 2.75078C37.0044 4.4916 40.5965 7.43956 43.1238 11.2219C45.6511 15.0042 47 19.451 47 24C46.9931 30.0979 44.5677 35.944 40.2559 40.2559C35.944 44.5677 30.0979 46.9931 24 47ZM24 5.00001C20.2422 5.00001 16.5687 6.11434 13.4442 8.20209C10.3196 10.2898 7.88436 13.2572 6.4463 16.729C5.00823 20.2008 4.63197 24.0211 5.36509 27.7067C6.09821 31.3924 7.90778 34.7778 10.565 37.435C13.2222 40.0922 16.6077 41.9018 20.2933 42.6349C23.9789 43.3681 27.7992 42.9918 31.271 41.5537C34.7428 40.1157 37.7102 37.6804 39.7979 34.5558C41.8857 31.4313 43 27.7579 43 24C42.9945 18.9626 40.9909 14.1331 37.4289 10.5711C33.8669 7.00913 29.0374 5.00557 24 5.00001Z"
        fill="#0A3A5C"
      />
      <path
        d="M15.0001 36C14.8109 35.9998 14.6257 35.9459 14.4659 35.8446C14.3061 35.7433 14.1783 35.5988 14.0974 35.4278C14.0164 35.2568 13.9857 35.0664 14.0086 34.8786C14.0316 34.6908 14.1073 34.5134 14.2271 34.367L23.2271 23.367C23.3964 23.1663 23.6378 23.0403 23.8993 23.0161C24.1607 22.9919 24.4212 23.0715 24.6244 23.2378C24.8277 23.404 24.9574 23.6435 24.9856 23.9045C25.0138 24.1656 24.9382 24.4272 24.7751 24.633L15.7751 35.633C15.6812 35.7478 15.5629 35.8403 15.4289 35.9038C15.2949 35.9672 15.1484 36.0001 15.0001 36Z"
        fill="#F85E00"
      />
      <path
        d="M24 27C25.6569 27 27 25.6569 27 24C27 22.3431 25.6569 21 24 21C22.3431 21 21 22.3431 21 24C21 25.6569 22.3431 27 24 27Z"
        fill="#0A3A5C"
      />
      <path
        d="M33 25H24C23.8354 25 23.6734 24.9594 23.5282 24.8818C23.3831 24.8042 23.2593 24.6919 23.168 24.555L15.168 12.555C15.0951 12.4457 15.0445 12.3232 15.0189 12.1944C14.9934 12.0655 14.9935 11.9329 15.0192 11.8041C15.0712 11.544 15.2243 11.3152 15.445 11.168C15.6657 11.0208 15.9358 10.9673 16.1959 11.0192C16.456 11.0712 16.6848 11.2243 16.832 11.445L24.535 23H33C33.2652 23 33.5196 23.1054 33.7071 23.2929C33.8946 23.4804 34 23.7348 34 24C34 24.2652 33.8946 24.5196 33.7071 24.7071C33.5196 24.8946 33.2652 25 33 25Z"
        fill="#0A3A5C"
      />
      <path
        d="M24 11C23.7348 11 23.4804 10.8946 23.2929 10.7071C23.1054 10.5196 23 10.2652 23 10V8C23 7.73478 23.1054 7.48043 23.2929 7.29289C23.4804 7.10536 23.7348 7 24 7C24.2652 7 24.5196 7.10536 24.7071 7.29289C24.8946 7.48043 25 7.73478 25 8V10C25 10.2652 24.8946 10.5196 24.7071 10.7071C24.5196 10.8946 24.2652 11 24 11Z"
        fill="#959595"
      />
      <path
        d="M37 24C37 23.7348 37.1054 23.4804 37.2929 23.2929C37.4804 23.1054 37.7348 23 38 23H40C40.2652 23 40.5196 23.1054 40.7071 23.2929C40.8946 23.4804 41 23.7348 41 24C41 24.2652 40.8946 24.5196 40.7071 24.7071C40.5196 24.8946 40.2652 25 40 25H38C37.7348 25 37.4804 24.8946 37.2929 24.7071C37.1054 24.5196 37 24.2652 37 24Z"
        fill="#959595"
      />
      <path
        d="M24 37C24.2652 37 24.5196 37.1054 24.7071 37.2929C24.8946 37.4804 25 37.7348 25 38V40C25 40.2652 24.8946 40.5196 24.7071 40.7071C24.5196 40.8946 24.2652 41 24 41C23.7348 41 23.4804 40.8946 23.2929 40.7071C23.1054 40.5196 23 40.2652 23 40V38C23 37.7348 23.1054 37.4804 23.2929 37.2929C23.4804 37.1054 23.7348 37 24 37Z"
        fill="#959595"
      />
      <path
        d="M11 24C11 24.2652 10.8946 24.5196 10.7071 24.7071C10.5196 24.8946 10.2652 25 10 25H8C7.73478 25 7.48043 24.8946 7.29289 24.7071C7.10536 24.5196 7 24.2652 7 24C7 23.7348 7.10536 23.4804 7.29289 23.2929C7.48043 23.1054 7.73478 23 8 23H10C10.2652 23 10.5196 23.1054 10.7071 23.2929C10.8946 23.4804 11 23.7348 11 24Z"
        fill="#959595"
      />
    </svg>
  );
};
