import * as React from "react";
import { useCallback } from "react";

import { Button } from "@byko/component-buttons";
import { blockDrawerClickOutsideState, drawerState } from "@byko/component-drawer";
import { H6, PLarge } from "@byko/component-typography";
import { useAuth } from "@byko/hooks-auth-next";
import { LongArrowSideIcons } from "@byko/lib-icons";
import { useSetRecoilState } from "@byko/lib-recoil";
import { theme } from "@byko/lib-styles";

import { Container, TextContainer } from "./styles";

import type { WelcomeProps } from "./interface";

export const Welcome = ({ userName }: WelcomeProps): JSX.Element => {
  const setBlockClose = useSetRecoilState<boolean>(blockDrawerClickOutsideState);
  const setOpen = useSetRecoilState<boolean>(drawerState);
  const { finalizeOnboarding } = useAuth();

  const handleClose = useCallback(() => {
    finalizeOnboarding();
    setBlockClose(false);
    setOpen(false);
    window.location.reload();
  }, [finalizeOnboarding, setBlockClose, setOpen]);

  return (
    <Container>
      <TextContainer>
        <H6>Hæ {userName}</H6>
        <PLarge textColor={theme.palette.blue.dark}>
          Aðgangur hefur verið stofnaður fyrir þig. Þú getur nú verslað á vefnum á þínum kjörum og séð um stillingar
          fyrir þinn reikning. Á Mitt BYKO er hægt að nálgast yfirlit yfir öll þín viðskipti.
        </PLarge>
        <PLarge textColor={theme.palette.blue.dark}>Takk fyrir að velja BYKO.</PLarge>
      </TextContainer>

      <Button buttonColor="blueButton" icon={LongArrowSideIcons} label="Loka" stretch={true} onClick={handleClose} />
    </Container>
  );
};
