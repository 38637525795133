export const enum TriggerColorsEnum {
  WarningMainColor = "#FF4E43",
  WarningLightestColor = "#FFE7E5",
  AttentionMainColor = "#FFA51F",
  AttentionLightestColor = "#FFE5BF",
  SuccessMainColor = "#3FC12A",
  SuccessLightestColor = "#D3F2CE",
  InfoMainColor = "#00416A",
  InfoLightestColor = "#E5F0F6",
}

export enum BlueColorsEnum {
  BlueDarkColor = "#0A3A5C",
  BlueMainColor = "#00416A",
  BlueLightColor = "#5995BE",
  BlueLighterColor = "#80B3D2",
  BlueLightestColor = "#E5F0F6",
}

export const enum YellowColorsEnum {
  YellowDarkColor = "#D6B200",
  YellowMainColor = "#FFD330",
  YellowLightColor = "#FFE24D",
  YellowLighterColor = "#FFEC8E",
  YellowLightestColor = "#FFF6CC",
}

export const enum OrangeColorsEnum {
  OrangeDarkColor = "#C74B00",
  OrangeMainColor = "#F85E00",
  OrangeLightColor = "#FA8E4D",
  OrangeLighterColor = "#FFB784",
  OrangeLightestColor = "#FFEBDD",
}

export const enum GreenColorsEnum {
  GreenDarkColor = "#008F69",
  GreenMainColor = "#04B485",
  GreenLightColor = "#52C6A8",
  GreenLighterColor = "#8FDBC7",
  GreenLightestColor = "#D9EEE8",
}

export const enum GrayColorsEnum {
  Gray90Color = "#191919",
  Gray80Color = "#323232",
  Gray70Color = "#4A4A4A",
  Gray60Color = "#636363",
  Gray50Color = "#7C7C7C",
  Gray40Color = "#959595",
  Gray30Color = "#AEAEAE",
  Gray20Color = "#C6C6C6",
  Gray10Color = "#E6E7E8",
  Gray5Color = "#F6F6F6",
}

export const enum WhiteColorsEnum {
  WhiteMainColor = "#FFFFFF",
}
export const enum BlackColorsEnum {
  BlackMainColor = "#000000",
}

export const enum TransparentColorsEnum {
  TransparentMainColor = "rgba(0, 0, 0, 0)",
}

export const enum ShadowColorsEnum {
  ShadowMainColor = "rgba(0, 0, 0, 0.09)",
}

export const enum BoxShadowInput {
  BoxShadowInput = "0px 18px 45px rgba(0, 0, 0, 0.09)",
}

interface WarningColors {
  main: TriggerColorsEnum.WarningMainColor;
  lightest: TriggerColorsEnum.WarningLightestColor;
}

interface AttentionColors {
  main: TriggerColorsEnum.AttentionMainColor;
  lightest: TriggerColorsEnum.AttentionLightestColor;
}

interface SuccessColors {
  main: TriggerColorsEnum.SuccessMainColor;
  lightest: TriggerColorsEnum.SuccessLightestColor;
}

interface TriggerColors {
  warning: WarningColors;
  attention: AttentionColors;
  success: SuccessColors;
}

interface BlueColors {
  dark: BlueColorsEnum.BlueDarkColor;
  main: BlueColorsEnum.BlueMainColor;
  light: BlueColorsEnum.BlueLightColor;
  lighter: BlueColorsEnum.BlueLighterColor;
  lightest: BlueColorsEnum.BlueLightestColor;
}

interface YellowColors {
  dark: YellowColorsEnum.YellowDarkColor;
  main: YellowColorsEnum.YellowMainColor;
  light: YellowColorsEnum.YellowLightColor;
  lighter: YellowColorsEnum.YellowLighterColor;
  lightest: YellowColorsEnum.YellowLightestColor;
}

interface OrangeColors {
  dark: OrangeColorsEnum.OrangeDarkColor;
  main: OrangeColorsEnum.OrangeMainColor;
  light: OrangeColorsEnum.OrangeLightColor;
  lighter: OrangeColorsEnum.OrangeLighterColor;
  lightest: OrangeColorsEnum.OrangeLightestColor;
}

interface GreenColors {
  dark: GreenColorsEnum.GreenDarkColor;
  main: GreenColorsEnum.GreenMainColor;
  light: GreenColorsEnum.GreenLightColor;
  lighter: GreenColorsEnum.GreenLighterColor;
  lightest: GreenColorsEnum.GreenLightestColor;
}

interface BlackColors {
  main: BlackColorsEnum.BlackMainColor;
}

interface TransparentColors {
  main: TransparentColorsEnum.TransparentMainColor;
}

interface ShadowColors {
  main: ShadowColorsEnum.ShadowMainColor;
}

interface GrayColors {
  90: GrayColorsEnum.Gray90Color;
  80: GrayColorsEnum.Gray80Color;
  70: GrayColorsEnum.Gray70Color;
  60: GrayColorsEnum.Gray60Color;
  50: GrayColorsEnum.Gray50Color;
  40: GrayColorsEnum.Gray40Color;
  30: GrayColorsEnum.Gray30Color;
  20: GrayColorsEnum.Gray20Color;
  10: GrayColorsEnum.Gray10Color;
  5: GrayColorsEnum.Gray5Color;
}

interface BoxShadow {
  input: BoxShadowInput.BoxShadowInput;
}

interface WhiteColors {
  main: WhiteColorsEnum.WhiteMainColor;
}

interface ColorTheme {
  palette: {
    blue: BlueColors;
    yellow: YellowColors;
    orange: OrangeColors;
    green: GreenColors;
    black: BlackColors;
    transparent: TransparentColors;
    shadow: ShadowColors;
    gray: GrayColors;
    white: WhiteColors;
    triggers: TriggerColors;
    boxShadow: BoxShadow;
  };
}

export const theme: ColorTheme = {
  palette: {
    blue: {
      dark: BlueColorsEnum.BlueDarkColor,
      main: BlueColorsEnum.BlueMainColor,
      light: BlueColorsEnum.BlueLightColor,
      lighter: BlueColorsEnum.BlueLighterColor,
      lightest: BlueColorsEnum.BlueLightestColor,
    },
    yellow: {
      dark: YellowColorsEnum.YellowDarkColor,
      main: YellowColorsEnum.YellowMainColor,
      light: YellowColorsEnum.YellowLightColor,
      lighter: YellowColorsEnum.YellowLighterColor,
      lightest: YellowColorsEnum.YellowLightestColor,
    },
    orange: {
      dark: OrangeColorsEnum.OrangeDarkColor,
      main: OrangeColorsEnum.OrangeMainColor,
      light: OrangeColorsEnum.OrangeLightColor,
      lighter: OrangeColorsEnum.OrangeLighterColor,
      lightest: OrangeColorsEnum.OrangeLightestColor,
    },
    green: {
      dark: GreenColorsEnum.GreenDarkColor,
      main: GreenColorsEnum.GreenMainColor,
      light: GreenColorsEnum.GreenLightColor,
      lighter: GreenColorsEnum.GreenLighterColor,
      lightest: GreenColorsEnum.GreenLightestColor,
    },
    black: {
      main: BlackColorsEnum.BlackMainColor,
    },
    gray: {
      90: GrayColorsEnum.Gray90Color,
      80: GrayColorsEnum.Gray80Color,
      70: GrayColorsEnum.Gray70Color,
      60: GrayColorsEnum.Gray60Color,
      50: GrayColorsEnum.Gray50Color,
      40: GrayColorsEnum.Gray40Color,
      30: GrayColorsEnum.Gray30Color,
      20: GrayColorsEnum.Gray20Color,
      10: GrayColorsEnum.Gray10Color,
      5: GrayColorsEnum.Gray5Color,
    },
    shadow: {
      main: ShadowColorsEnum.ShadowMainColor,
    },
    transparent: {
      main: TransparentColorsEnum.TransparentMainColor,
    },
    white: {
      main: WhiteColorsEnum.WhiteMainColor,
    },
    triggers: {
      warning: {
        main: TriggerColorsEnum.WarningMainColor,
        lightest: TriggerColorsEnum.WarningLightestColor,
      },
      attention: {
        main: TriggerColorsEnum.AttentionMainColor,
        lightest: TriggerColorsEnum.AttentionLightestColor,
      },
      success: {
        main: TriggerColorsEnum.SuccessMainColor,
        lightest: TriggerColorsEnum.SuccessLightestColor,
      },
    },
    boxShadow: {
      input: BoxShadowInput.BoxShadowInput,
    },
  },
};
