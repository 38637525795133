import * as React from "react";

import { IconButton } from "@byko/component-buttons";
import { ArrowLeftIcons, ArrowRightIcons } from "@byko/lib-icons";
import { useWindowSize } from "@byko/lib-styles";

import { SliderButtonsWrapper, SliderProgress } from "./styles";

import type { SliderButtonsProps } from "./interface";
import { useSwiper } from "swiper/react";
import { useCallback } from "react";

export const SliderButtons = ({ progress }: SliderButtonsProps): JSX.Element => {
  const { activeBreakPoint } = useWindowSize();
  const swiperClick = useSwiper();

  const handlePrevClick = useCallback(() => {
    swiperClick.slidePrev();
  }, [swiperClick]);

  const handleNextClick = useCallback(() => {
    swiperClick.slideNext();
  }, [swiperClick]);

  return (
    <SliderButtonsWrapper>
      <SliderProgress progress={progress} />
      <IconButton
        ariaLabel={"Áfram"}
        icon={ArrowLeftIcons}
        small={["s", "xs"].includes(activeBreakPoint)}
        onClick={handlePrevClick}
      />
      <IconButton
        ariaLabel={"Til baka"}
        icon={ArrowRightIcons}
        small={["s", "xs"].includes(activeBreakPoint)}
        onClick={handleNextClick}
      />
    </SliderButtonsWrapper>
  );
};
