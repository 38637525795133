import { useQuery } from "@apollo/client";
import { useEffect, useMemo, useRef } from "react";

import { useUser } from "@byko/hooks-auth-next";
import { usePrevious } from "@byko/lib-utils";

import type {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
} from "@apollo/client";

/**
 * Apollo sometimes returns null while fetching new data.
 * This hook will return the previous data while fetching new data.
 *
 */

export const useQueryWithPollAndRefetchOnUserStateChange = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TData = any,
  TVariables = OperationVariables,
>(
  _name: string,
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables> & {
    allowUpdate?: boolean;
    pollInterval?: number | null | undefined | false;
    bindToUserStateChange?: boolean | undefined;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, unused-imports/no-unused-vars
  _deps?: any[] | undefined | null,
): QueryResult<TData, TVariables> => {
  /* Options */
  const bindToUserStateChange = options?.bindToUserStateChange ?? true;
  const pollInterval = options?.pollInterval ?? 1000 * 60 * 5;
  const allowUpdate = options?.allowUpdate ?? true;

  /* State */
  const user = useUser();
  const prevUser = usePrevious(user);
  const ref = useRef<null | TData>(null);
  const optionsWithPoll = useMemo(() => {
    if (pollInterval) {
      return {
        ...options,
        pollInterval,
      };
    }
    return options;
  }, [options, pollInterval]);
  const { data, error, loading, refetch, ...rest } = useQuery<TData, TVariables>(query, optionsWithPoll);
  const errorObj = error ? { error } : {};
  useEffect(() => {
    if (options?.skip || !allowUpdate) {
      return;
    }
    /**
     * Refetch if user state changes
     */
    if (user !== prevUser && bindToUserStateChange) {
      void refetch(options?.variables);
    }
    return;
  }, [prevUser, user, refetch, options, bindToUserStateChange, allowUpdate]);
  //useEffect(() => {
  /**
   * Refetch if deps change
   */
  /* if (options?.skip || !allowUpdate) {
      return;
    }
    console.log("refetch ueQuery");
    void refetch(options?.variables);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deps]);*/

  /**
   * if user state has changed, return undefined data.
   */
  if (bindToUserStateChange && user !== prevUser) {
    return {
      data: undefined,
      ...errorObj,
      loading,
      ...rest,
      refetch,
    };
  }
  /**
   * If data exists update ref.
   */
  if (data) {
    ref.current = data;
  }

  /**
   * Always show undefined if skip is true.
   */
  if (options?.skip) {
    return {
      ...rest,
      data: undefined,
      loading: false,
      refetch,
    };
  }
  /**
   * Returns previous data or loaded data.
   */
  return {
    data: ref.current ?? undefined,
    loading: ref.current ? false : loading,
    ...errorObj,
    refetch,
    ...rest,
  };
};
