import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const LongArrowLeftIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M22 12H2" stroke={iconColor} strokeMiterlimit="10" strokeWidth="2" />
      <path d="M9 19L2 12L9 5" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
    </svg>
  );
};
