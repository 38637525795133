/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const RecycleFilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 24C4.997 20.599 5.90776 17.2597 7.63705 14.3311C9.36634 11.4025 11.8506 8.99236 14.8302 7.35254C17.8098 5.71272 21.1752 4.9035 24.5746 5.00949C27.9739 5.11547 31.2824 6.13277 34.154 7.95501L31.125 10.524C30.9864 10.6413 30.8824 10.794 30.824 10.9659C30.7657 11.1378 30.7552 11.3223 30.7937 11.4997C30.8323 11.6771 30.9184 11.8407 31.0428 11.9728C31.1672 12.105 31.3253 12.2008 31.5 12.25L42.367 15.278C42.4542 15.3028 42.5444 15.3153 42.635 15.315C42.9002 15.315 43.1546 15.2097 43.3421 15.0221C43.5296 14.8346 43.635 14.5802 43.635 14.315C43.6349 14.2732 43.6322 14.2315 43.627 14.19L42.24 3.12301C42.2177 2.94405 42.1473 2.77447 42.0365 2.63221C41.9256 2.48996 41.7783 2.38033 41.6103 2.31494C41.4422 2.24956 41.2595 2.23084 41.0817 2.26078C40.9038 2.29072 40.7374 2.3682 40.6 2.48501L37.332 5.25801C33.8915 2.81063 29.8437 1.35751 25.6322 1.05788C21.4207 0.758245 17.208 1.62367 13.4557 3.55932C9.70334 5.49497 6.55623 8.42614 4.35919 12.0316C2.16216 15.6372 0.999977 19.7779 1 24C1 24.5304 1.21071 25.0392 1.58579 25.4142C1.96086 25.7893 2.46957 26 3 26C3.53043 26 4.03914 25.7893 4.41421 25.4142C4.78929 25.0392 5 24.5304 5 24Z"
        fill="#00416A"
      />
      <path
        d="M45 22C44.4696 22 43.9608 22.2107 43.5858 22.5858C43.2107 22.9609 43 23.4696 43 24C43.0032 27.401 42.0926 30.7404 40.3635 33.669C38.6343 36.5976 36.1501 39.0078 33.1705 40.6476C30.191 42.2875 26.8256 43.0967 23.4263 42.9907C20.027 42.8847 16.7185 41.8673 13.847 40.045L16.875 37.476C17.0136 37.3587 17.1176 37.206 17.176 37.0341C17.2343 36.8622 17.2448 36.6777 17.2063 36.5003C17.1677 36.3229 17.0816 36.1593 16.9572 36.0272C16.8328 35.895 16.6747 35.7992 16.5 35.75L5.62899 32.722C5.47028 32.6779 5.30313 32.6737 5.1424 32.7097C4.98167 32.7458 4.83233 32.8209 4.70765 32.9286C4.58297 33.0363 4.48682 33.173 4.42774 33.3268C4.36866 33.4806 4.34848 33.6466 4.36899 33.81L5.75999 44.877C5.78245 45.0558 5.8528 45.2252 5.9636 45.3673C6.0744 45.5094 6.22153 45.6189 6.38944 45.6842C6.55735 45.7496 6.7398 45.7684 6.91751 45.7386C7.09521 45.7088 7.26157 45.6315 7.39899 45.515L10.668 42.742C14.1085 45.1894 18.1563 46.6425 22.3678 46.9421C26.5793 47.2418 30.792 46.3763 34.5443 44.4407C38.2967 42.505 41.4438 39.5739 43.6408 35.9684C45.8378 32.3629 47 28.2222 47 24C47 23.4696 46.7893 22.9609 46.4142 22.5858C46.0391 22.2107 45.5304 22 45 22Z"
        fill="#00416A"
      />
    </svg>
  );
};
