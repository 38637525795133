import * as React from "react";

import { theme } from "@byko/lib-styles";

import { InputSleave } from "./input-sleave";
import { InputField, TextAreaField } from "./styles";

import type { InputProps } from "./interface";

export const Input: React.FC<InputProps> = ({
  icon: Icon,
  placeholder,
  error,
  errorTextAboveInput,
  errorText,
  stretch,
  forwardRef,
  autoComplete,
  label,
  fieldName,
  type,
  borderColor = theme.palette.gray[10],
  iconColor = theme.palette.blue.main,
  customClassName,
  ...props
}) => {
  return (
    <>
      <InputSleave
        customClassName={customClassName}
        error={error}
        errorText={errorText || undefined}
        errorTextAboveInput={!!errorTextAboveInput}
        icon={Icon}
        iconColor={iconColor}
        label={label}
        placeholder={placeholder}
        stretch={!!stretch}
      >
        {type === "textarea" ? (
          <>
            <TextAreaField
              autoCapitalize="sentences"
              borderColor={borderColor}
              error={error}
              placeholder={placeholder}
              {...props}
            />
          </>
        ) : (
          <InputField
            ref={forwardRef}
            autoComplete={autoComplete ?? "off"}
            borderColor={borderColor}
            error={error}
            id={label}
            name={fieldName}
            placeholder={placeholder}
            {...props}
          />
        )}
      </InputSleave>
    </>
  );
};
