import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const SendMessageineDecorative: BykoReactDecorativeIconComponentType = ({ size, color = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M45 3L20 28" stroke={color} strokeMiterlimit="10" strokeWidth="2" />
      <path
        d="M45 3L28 45L20 28L3 20L45 3Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
};
