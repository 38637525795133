import * as React from "react";

import { MainLoader } from "@byko/component-loaders";
import { H6 } from "@byko/component-typography";
import { PipulagnirIllustration } from "@byko/lib-illustrations";

import { Container, IllustrationContainer, LoaderContainer } from "./styles";

export const Loading = (): JSX.Element => {
  return (
    <Container>
      <H6>Þetta er allt í pípunum</H6>
      <LoaderContainer>
        <MainLoader size={48} />
      </LoaderContainer>
      <IllustrationContainer>
        <PipulagnirIllustration />
      </IllustrationContainer>
    </Container>
  );
};
