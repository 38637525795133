/* eslint-disable react-perf/jsx-no-new-object-as-prop */

import React, { useCallback, useEffect } from "react";
import { useRouter } from "next/router";
import styled from "styled-components";
import { fromPairs, mapValues, pickBy } from "lodash";
import { useThrottle, useUpdateEffect } from "react-use";
import type { ParsedUrlQuery } from "querystring";
import type { UseSelectStateChange } from "downshift";
import type algoliasearchHelper from "algoliasearch-helper";

import { Select } from "@byko/component-select";
import { ensureList } from "@byko/lib-utils";
import { atom, useSetRecoilState } from "@byko/lib-recoil";
import { algoliaHitToProduct, useProductLowestPrices } from "@byko/lib-api-products";
import { useAlgoliaInsights } from "@byko/lib-algolia";
import type { ProductList } from "@byko/lib-api-rest";

import FacetsBar from "./filters/facets-bar";
import PaginationMenu from "./pagination-menu";
import { FilterHeader } from "./filter-header";
import { ProductSearchProvider, useProductSearch } from "../context";
import { compileAlgoliaProductFilters, useAlgoliaProductQuery } from "../api";
import { FacetsContainer, ProductsContainer, SceneContainer } from "./styles";
import { DEFAULT_PAGE_SIZE } from "../conf";
import { ProductResultsList, isRentalProduct } from "./product-list";
import { useAuth } from "@byko/hooks-auth-next";

export interface ProductSearchResultsProps {
  description?: string | undefined;
  attrNameMap: Record<string, string> | undefined;
  categoryId?: number | undefined;
  initialData?: algoliasearchHelper.SearchResults<ProductList> | undefined;
  query?: ParsedUrlQuery;
  pathname?: string;
  rentals: boolean;
  disabled?: boolean;
}

interface Option {
  value: number;
  label: string;
}

const SelectContainer = styled.div`
  width: 296px;
`;

const PaginationContainer = styled.div`
  width: fit-content;
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 56px;
  gap: 24px;
`;

export const productHitCountState = atom<number>({
  key: "product-search-hit-count",
  default: 0,
});

interface ResultFooterProps {
  pathname: string;
  query: ParsedUrlQuery;
}

const resultsOptions: Option[] = [
  { value: 24, label: "24 vörur á síðu" },
  { value: 48, label: "48 vörur á síðu" },
  { value: 96, label: "96 vörur á síðu" },
];

function ResultsFooter({ pathname, query }: ResultFooterProps) {
  const { searchResults, page, pageSize, setPageSize } = useProductSearch();

  const resultsOptionsMap = fromPairs(resultsOptions.map((o) => [o.value, o]));

  const handlePageSizeChange = useCallback(
    (changes: UseSelectStateChange<Option>) => {
      setPageSize(changes.selectedItem?.value);
    },
    [setPageSize],
  );

  const itemToString = useCallback((item: Option | null): string => {
    return item?.label || "";
  }, []);

  if (!searchResults || searchResults.nbHits <= pageSize) {
    return null;
  }

  return (
    <FooterWrapper>
      <SelectContainer>
        <Select
          handleSelectedItemChange={handlePageSizeChange}
          // icon={LayoutMasonryFillIcon}
          items={resultsOptions}
          itemToString={itemToString}
          placeholder="Fjöldi vara á síðu"
          selectedItem={resultsOptionsMap[pageSize]}
        />
      </SelectContainer>
      <PaginationContainer>
        <PaginationMenu maxPage={searchResults?.nbPages} page={page} pathname={pathname} query={query} />
      </PaginationContainer>
    </FooterWrapper>
  );
}

const ProductSearchResults = ({
  categoryId,
  initialData,
  query,
  pathname,
  rentals,
  disabled,
}: ProductSearchResultsProps) => {
  const setProductHitCount = useSetRecoilState<number>(productHitCountState);
  const router = useRouter();
  const { setLastAlgoliaParamters } = useAlgoliaInsights();
  const { activeMembership } = useAuth();

  /* prettier-ignore */
  const q: Record<string, string> = pickBy(
    mapValues(
      router?.query ?? query,
      (v) => ensureList(v).join(",")
    )
  );

  const page = parseInt(q["page"] || "1", 10);
  const pageSize = parseInt(q["pageSize"] || `${DEFAULT_PAGE_SIZE}`, 10);

  const value = useThrottle(q["q"], 500);

  const { data: results, isLoading } = useAlgoliaProductQuery(
    {
      categoryId,
      sortBy: q["sort"],
      query: value,
      page,
      pageSize,
      filters: compileAlgoliaProductFilters(q),
    },
    initialData,
  );

  const products = ensureList(results?.hits.map(algoliaHitToProduct));

  const pricedProducts = products
    ?.filter(
      (p) =>
        !(
          p.salesAvailability === "custom" ||
          p.salesAvailability === "display" ||
          isRentalProduct(p) ||
          (!activeMembership?.account.hasContract && p.salesAvailability === "by_contract")
        ),
    )
    .map((p) => p.id);

  const { prices } = useProductLowestPrices(rentals ? [] : pricedProducts);

  useEffect(() => {
    setProductHitCount(results?.nbHits ?? 0);
  }, [results?.nbHits, setProductHitCount]);

  useUpdateEffect(() => {
    setLastAlgoliaParamters({
      index: results?.index ?? "",
      queryID: results?.queryID ?? "",
    });
  }, [results?.index, results?.queryID]);

  if (disabled) return null;

  return (
    <ProductSearchProvider query={query} results={results}>
      <SceneContainer flexDirection="row" offsetNav={true}>
        <FilterHeader />

        <ProductsContainer>
          {!results?.hits?.length && !isLoading && <>Engar vörur fundust</>}

          <ProductResultsList prices={prices} products={products} rentals={rentals} results={results} />

          <ResultsFooter pathname={router?.pathname ?? pathname} query={router?.query ?? query} />
        </ProductsContainer>

        <FacetsContainer>
          <FacetsBar />
        </FacetsContainer>
      </SceneContainer>
    </ProductSearchProvider>
  );
};

export default ProductSearchResults;
