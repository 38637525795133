import React, { useMemo } from "react";

import { DotLoader } from "@byko/component-loaders";
import { EyebrowSmall, H6, Subtitle } from "@byko/component-typography";
import { useProductPricing } from "@byko/hooks-product-variants";
import { theme } from "@byko/lib-styles";

import { FlexWrapper, LoadingWrapper, PriceContainer, SqmSpan } from "./styles";

import type { PriceWrapperProps } from "./interface";

export const PriceWrapper = ({ product, prices, activeVariantSKU }: PriceWrapperProps): JSX.Element | null => {
  const { loadingPrices, mainPrice, previousPrice, isSqmProduct } = useProductPricing(
    product,
    prices,
    activeVariantSKU,
  );

  const notYetAvailable = useMemo(() => {
    let hasValidPrice = false;
    product.variants.forEach((variant) => {
      if (variant.price.gross != undefined && variant.price.gross != 0) {
        hasValidPrice = true;
      }
    });

    return !hasValidPrice;
  }, [product.variants]);

  return (
    <PriceContainer>
      {notYetAvailable ? (
        <>
          <FlexWrapper>
            <H6 maxFontSize="18px" textColor={theme.palette.blue.main} top={24}>
              Vara væntanleg
            </H6>
          </FlexWrapper>
        </>
      ) : (
        <>
          <FlexWrapper>
            <EyebrowSmall textColor={theme.palette.gray[60]}>{mainPrice?.label}</EyebrowSmall>
            {previousPrice ? (
              <EyebrowSmall textColor={theme.palette.gray[60]}>{previousPrice.label}</EyebrowSmall>
            ) : null}
          </FlexWrapper>

          <FlexWrapper>
            {loadingPrices ? (
              <LoadingWrapper>
                <DotLoader size={84} />
              </LoadingWrapper>
            ) : null}

            {mainPrice ? (
              <H6 maxFontSize="24px" textColor={theme.palette.blue.main}>
                {mainPrice.price} <SqmSpan>{isSqmProduct ? "/m²" : null}</SqmSpan>
              </H6>
            ) : null}
            {previousPrice ? (
              <Subtitle textColor={theme.palette.gray[30]}>
                {previousPrice.price} <SqmSpan>{isSqmProduct ? "/m²" : null}</SqmSpan>
              </Subtitle>
            ) : null}
          </FlexWrapper>
        </>
      )}
    </PriceContainer>
  );
};
