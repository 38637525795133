import { theme } from "./theme";

import type { TableStyles } from "react-data-table-component";

// styles for all tables EXCEPT the sale invoice table
export const globalCustomListStyles: TableStyles = {
  pagination: {
    style: {
      color: theme.palette.blue.dark,
    },
    pageButtonsStyle: {
      fill: theme.palette.blue.dark,
    },
  },
  rows: {
    style: {
      minHeight: "72px",
      backgroundColor: theme.palette.white.main,
      borderWidth: "1px",
      borderStyle: "solid",
      borderTopColor: theme.palette.transparent.main,
      borderBottomColor: theme.palette.gray[5],
      borderLeftColor: theme.palette.transparent.main,
      borderRightColor: theme.palette.transparent.main,
    },
    highlightOnHoverStyle: {
      fontWeight: "normal",
      borderWidth: "1px",
      borderStyle: "solid",
      backgroundColor: theme.palette.gray[5],
      borderTopColor: theme.palette.blue.dark,
      borderBottomColor: theme.palette.blue.dark,
      borderLeftColor: theme.palette.white.main,
      borderRightColor: theme.palette.white.main,
      color: theme.palette.blue.dark,
    },
  },
  expanderButton: {
    style: {
      color: theme.palette.blue.main,
      backgroundColor: theme.palette.white.main,
      "&:hover:enabled": {
        cursor: "pointer",
      },
      "&:disabled": {
        color: theme.palette.gray[10],
      },
      "&:hover:not(:disabled)": {
        cursor: "pointer",
        backgroundColor: theme.palette.white.main,
      },
      "&:focus": {
        outline: "none",
        backgroundColor: theme.palette.white.main,
      },
    },
  },
  headCells: {
    style: {
      fontSize: 16,
      fontWeight: "800",
      height: "56px",
      paddingLeft: "24px",
      paddingRight: "24px",
      color: theme.palette.blue.dark,
      backgroundColor: theme.palette.white.main,
    },
  },
  cells: {
    style: {
      fontSize: 16,
      paddingLeft: "24px",
      paddingRight: "24px",
    },
  },
};

// Styles JUST for the sale invoice table
export const saleInvoiceCustomListStyles: TableStyles = {
  pagination: {
    style: {
      color: theme.palette.blue.dark,
    },
    pageButtonsStyle: {
      fill: theme.palette.blue.dark,
    },
  },
  rows: {
    style: {
      minHeight: "72px",
      borderTopWidth: "16px",
      borderTopStyle: "solid",
      borderTopColor: theme.palette.gray[5],
      backgroundColor: theme.palette.white.main,
      color: theme.palette.blue.dark,
      "&:not(:last-of-type)": {
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomColor: theme.palette.white.main,
      },
    },
  },
  expanderButton: {
    style: {
      color: theme.palette.blue.main,
      backgroundColor: theme.palette.white.main,
      "&:hover:enabled": {
        cursor: "pointer",
      },
      "&:disabled": {
        color: theme.palette.gray[10],
      },
      "&:hover:not(:disabled)": {
        cursor: "pointer",
        backgroundColor: theme.palette.white.main,
      },
      "&:focus": {
        outline: "none",
        backgroundColor: theme.palette.white.main,
      },
    },
  },
  headCells: {
    style: {
      fontSize: 16,
      fontWeight: "800",
      height: "56px",
      paddingLeft: "24px",
      paddingRight: "24px",
      color: theme.palette.blue.dark,
      backgroundColor: theme.palette.white.main,
      borderBottomColor: theme.palette.white.main,
    },
  },
  cells: {
    style: {
      fontSize: 16,
      paddingLeft: "24px",
      paddingRight: "24px",
    },
  },
};
