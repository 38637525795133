import { httpLink } from "@trpc/client/links/httpLink";

import { getBykoConfig } from "@byko/lib-utils";

import { trpc } from "./base";

import type { AppRouter } from "@byko/lib-api-dato-ssr";
import type { Maybe } from "@byko/types-utils";
import type { TRPCClient } from "@trpc/client";
import type { QueryClient } from "react-query";

interface Props<T> {
  secret?: string | undefined;
  forPro?: Maybe<boolean>;
  initialData?: T | undefined;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useFrontpage = <T>({ secret, initialData, forPro }: Props<T>) => {
  return trpc.useQuery(["getFrontpage", { secret, forPro: forPro ?? undefined }], {
    initialData,
    refetchOnWindowFocus: false,
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useFrontpageBySlug = <T>({ secret, initialData, slug }: Props<T> & { slug: string }) => {
  return trpc.useQuery(["getFrontpageBySlug", { secret, slug }], { initialData, refetchOnWindowFocus: false });
};

export const getFrontpage =
  (
    _url: string | undefined,
    secret?: string | undefined,
    forPro?: Maybe<boolean>,
    props:
      | { queryClient?: QueryClient | undefined; trpcClient: TRPCClient<AppRouter> | undefined }
      | undefined = undefined,
  ) =>
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  () => {
    const fullurl = getBykoConfig("CMS_API_URL");
    return (
      props?.trpcClient ??
      trpc.createClient({
        links: [httpLink({ url: fullurl })],
      })
    ).query("getFrontpage", { secret, forPro: forPro ?? undefined });
  };

export const getFrontpageBySlug =
  (
    _url: string | undefined,
    slug: string,
    secret?: string | undefined,
    props:
      | { queryClient?: QueryClient | undefined; trpcClient: TRPCClient<AppRouter> | undefined }
      | undefined = undefined,
  ) =>
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  () => {
    const fullurl = getBykoConfig("CMS_API_URL");
    return (
      props?.trpcClient ??
      trpc.createClient({
        links: [httpLink({ url: fullurl })],
      })
    ).query("getFrontpageBySlug", { secret, slug });
  };
