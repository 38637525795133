import styled from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const Tabs = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${theme.palette.gray[10]};
  margin-bottom: 24px;
  @media screen and (max-width: ${gridTheme.container.maxWidth.md}px) {
    margin-bottom: 8px;
  }
`;

export const Content = styled.div`
  width: 100%;
`;
