import styled from "styled-components";

import { BtnText } from "@byko/component-typography";
import { theme } from "@byko/lib-styles";

export const Label = styled(BtnText)<{ textColor: string }>`
  color: ${(props): string => props.textColor} !important;
  transition: 200ms;
  user-select: none;
`;

export const Container = styled.button<{ active: boolean }>`
  display: flex;
  width: fit-content;
  height: 56px;
  align-items: center;
  padding-right: 24px;
  padding-left: 24px;
  border-bottom: 2px solid
    ${(props): string => (props.active ? theme.palette.blue.main : theme.palette.transparent.main)};
  background-color: ${(props): string => (props.active ? theme.palette.gray[5] : theme.palette.white.main)};
  cursor: pointer;
  gap: 43px;
  transition: 200ms;
  ${Label} {
    color: ${(props): string => (props.active ? theme.palette.blue.main : theme.palette.blue.dark)};
  }
  &:hover {
    background-color: ${theme.palette.gray[5]};
    ${Label} {
      color: ${theme.palette.blue.main};
    }
  }
  &:not(:first-of-type):not(:last-of-type) {
    border-right: 1px solid ${theme.palette.gray[10]};
    border-left: 1px solid ${theme.palette.gray[10]};
  }
`;
