import React, { useCallback } from "react";

import { SlideButton } from "./styles";

import type { ClickableSlideProps } from "./interface";

export const ClickableSlide = ({ onClick, index, slideContent }: ClickableSlideProps): JSX.Element => {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(index);
    }
  }, [index, onClick]);

  return (
    <SlideButton type="button" onClick={handleClick}>
      {slideContent}
    </SlideButton>
  );
};
