/* eslint-disable react-perf/jsx-no-new-function-as-prop */
/* eslint-disable react/jsx-no-bind */
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { IconButton } from "@byko/component-buttons";
import { H6, PSmallBold } from "@byko/component-typography";
import { ArrowLeftIcons, CancelLargeIcons } from "@byko/lib-icons";
import { theme } from "@byko/lib-styles";

import { NavigationOptionRow, SubNavigationOptions } from "./components";
import {
  CloseButtonWrapper,
  ColumnContainer,
  Container,
  Heading,
  MainContent,
  NavigationOptions,
  SubContent,
} from "./styles";

import type { MegamenuProps, MenuLevel } from "./interface";

const DEFAULT_LABEL = "Valmynd";

export const MegamenuMobile = ({ open, toggleOpen, configuration }: MegamenuProps): JSX.Element => {
  const router = useRouter();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [activeSubIndexOne, setActiveSubIndexOne] = useState<number | null>(null);
  const [activeSubIndexTwo, setActiveSubIndexTwo] = useState<number | null>(null);
  const [activeSubIndexThree, setActiveSubIndexThree] = useState<number | null>(null);

  const stepBack = useCallback(() => {
    if (typeof activeSubIndexTwo === "number") {
      setActiveSubIndexTwo(null);
    } else if (typeof activeSubIndexOne === "number") {
      setActiveSubIndexOne(null);
    } else if (typeof activeIndex === "number") {
      setActiveIndex(null);
    }
  }, [activeIndex, activeSubIndexOne, activeSubIndexTwo]);

  useEffect(() => {
    if (open === false) {
      setTimeout(() => {
        // clearing open state after fadeout animation
        setActiveIndex(null);
        setActiveSubIndexOne(null);
        setActiveSubIndexTwo(null);
        setActiveSubIndexThree(null);
      }, 400);
    }
  }, [open]);

  const subMenuOne = useMemo((): MenuLevel | null => {
    const child = typeof activeIndex === "number" ? configuration[activeIndex] : null;
    if (child) {
      const filteredChildren = child.children?.filter((item) => {
        return item.showInMegamenu;
      });

      const sortedChildren = filteredChildren?.sort((a, b) => {
        return a.menuWeight - b.menuWeight;
      });

      return { ...child, children: sortedChildren } as MenuLevel;
    }

    return null;
  }, [activeIndex, configuration]);

  const subMenuTwo = useMemo((): MenuLevel | null => {
    const child = typeof activeSubIndexOne === "number" ? subMenuOne?.children?.[activeSubIndexOne] : null;
    if (child) {
      const filteredChildren = child.children?.filter((item) => {
        return item.showInMegamenu;
      });

      const sortedChildren = filteredChildren?.sort((a, b) => {
        return a.menuWeight - b.menuWeight;
      });

      return { ...child, children: sortedChildren } as MenuLevel;
    }

    return null;
  }, [activeSubIndexOne, subMenuOne?.children]);

  const subMenuThree = useMemo((): MenuLevel | null => {
    const child = typeof activeSubIndexTwo === "number" ? subMenuTwo?.children?.[activeSubIndexTwo] : null;
    if (child) {
      const filteredChildren = child.children?.filter((item) => {
        return item.showInMegamenu;
      });

      const sortedChildren = filteredChildren?.sort((a, b) => {
        return a.menuWeight - b.menuWeight;
      });

      return { ...child, children: sortedChildren } as MenuLevel;
    }

    return null;
  }, [activeSubIndexTwo, subMenuTwo?.children]);

  const subMenuTwoStartingTabIndex = useMemo((): number => {
    if (subMenuOne === null) {
      return -1;
    }
    return configuration.length + subMenuOne.children.length;
  }, [configuration.length, subMenuOne]);

  const subMenuThreeStartingTabIndex = useMemo((): number => {
    if (subMenuOne === null || subMenuTwo === null) {
      return -1;
    }
    return configuration.length + subMenuOne.children.length;
  }, [configuration.length, subMenuOne, subMenuTwo]);

  useEffect(() => {
    setActiveSubIndexOne(null);
  }, [activeIndex]);

  useEffect(() => {
    setActiveSubIndexTwo(null);
  }, [activeSubIndexOne]);

  const headingLabel = useMemo((): string => {
    return subMenuThree?.title || subMenuTwo?.title || subMenuOne?.title || DEFAULT_LABEL;
  }, [subMenuOne?.title, subMenuThree?.title, subMenuTwo?.title]);

  const mainUrl = useMemo((): string | null => {
    return subMenuThree?.url || subMenuTwo?.url || subMenuOne?.url || null;
  }, [subMenuOne?.url, subMenuThree?.url, subMenuTwo?.url]);

  const navigatingProducts = useMemo((): boolean => {
    return subMenuOne?.slug === "voruflokkar" || subMenuOne?.slug === "bykoleiga";
  }, [subMenuOne?.slug]);

  const permitHeaderNavigation = useMemo((): boolean => {
    return (
      (navigatingProducts === true && (subMenuThree == null && subMenuTwo == null && subMenuOne != null) == false) ||
      subMenuThree?.canBeNavigatedToInMegamenu === true ||
      subMenuTwo?.canBeNavigatedToInMegamenu === true ||
      subMenuOne?.canBeNavigatedToInMegamenu === true
    );
  }, [navigatingProducts, subMenuOne, subMenuThree, subMenuTwo]);

  const goToPage = useCallback(
    (url: string) => {
      // check if first character is not /, then add it
      if (url.charAt(0) !== "/") {
        url = `/${url}`;
      }
      router.push(url);
      toggleOpen();
    },
    [router, toggleOpen],
  );

  const handleGoToMain = useCallback(() => {
    if (mainUrl) {
      goToPage(mainUrl);
    }
  }, [goToPage, mainUrl]);

  return (
    <Container open={open}>
      <MainContent show={typeof activeIndex !== "number"}>
        <NavigationOptions>
          {configuration.map((menuItem, index) => {
            return (
              <NavigationOptionRow
                key={menuItem.slug}
                active={activeIndex === index}
                goToPage={(): void => (menuItem?.url ? goToPage(menuItem.url) : void 0)}
                index={index}
                isLink={menuItem && menuItem.children && menuItem.children.length === 0}
                label={menuItem.title}
                setActive={setActiveIndex}
                tabIndex={index}
              />
            );
          })}
        </NavigationOptions>
      </MainContent>
      <SubContent>
        <ColumnContainer>
          <SubNavigationOptions
            activeSubIndex={activeSubIndexThree}
            goToChildPage={goToPage}
            goToPage={(): void => (subMenuThree?.url ? goToPage(subMenuThree.url) : void 0)}
            hideHeader={true}
            menu={subMenuThree}
            navigatingProducts={navigatingProducts}
            setActiveSubIndex={setActiveSubIndexThree}
            startingTabIndex={subMenuThreeStartingTabIndex}
          />
          <SubNavigationOptions
            activeSubIndex={activeSubIndexTwo}
            goToChildPage={goToPage}
            goToPage={(): void => (subMenuTwo?.url ? goToPage(subMenuTwo.url) : void 0)}
            hideHeader={true}
            menu={subMenuTwo}
            navigatingProducts={navigatingProducts}
            setActiveSubIndex={setActiveSubIndexTwo}
            startingTabIndex={subMenuTwoStartingTabIndex}
          />
          <SubNavigationOptions
            activeSubIndex={activeSubIndexOne}
            goToChildPage={goToPage}
            goToPage={(): void => (subMenuOne?.url ? goToPage(subMenuOne.url) : void 0)}
            hideHeader={true}
            menu={subMenuOne}
            navigatingProducts={navigatingProducts}
            setActiveSubIndex={setActiveSubIndexOne}
            startingTabIndex={configuration.length}
          />
        </ColumnContainer>
      </SubContent>
      <Heading darkBackdrop={typeof activeIndex !== "number"}>
        {typeof activeIndex === "number" && (
          <IconButton ariaLabel="Til baka" icon={ArrowLeftIcons} onClick={stepBack}></IconButton>
        )}
        {typeof activeIndex === "number" && permitHeaderNavigation && (
          <button type="button" onClick={handleGoToMain}>
            <PSmallBold textColor={theme.palette.blue.dark}>{headingLabel}</PSmallBold>
          </button>
        )}
        {typeof activeIndex === "number" && !permitHeaderNavigation && (
          <PSmallBold textColor={theme.palette.blue.dark}>{headingLabel}</PSmallBold>
        )}
        {typeof activeIndex !== "number" && <H6 textColor={theme.palette.white.main}>{headingLabel}</H6>}
        {typeof activeIndex !== "number" && (
          <CloseButtonWrapper onClick={toggleOpen}>
            <CancelLargeIcons iconColor={theme.palette.yellow.main} size={32} />
          </CloseButtonWrapper>
        )}
      </Heading>
    </Container>
  );
};
