import styled from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";

import type { StretchProps } from "./interface";

export const QtyButtonContainer = styled.div<StretchProps>`
  display: flex;
  width: ${(props): string => (props.stretch ? "100%" : "fit-content")};
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${theme.palette.gray[10]};
  background-color: ${theme.palette.white.main};
`;

export const QtyInputNumber = styled.input<StretchProps>`
  width: ${(props): string => (props.stretch ? "100%" : "58px")};
  height: 40px;
  border: none;
  border-right: 1px solid ${theme.palette.gray[10]};
  border-left: 1px solid ${theme.palette.gray[10]};
  background-color: ${theme.palette.white.main};
  color: ${theme.palette.blue.dark};
  font-size: 20px;
  outline: none;
  text-align: center;
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    width: ${(props): string => (props.stretch ? "100%" : "42px")};
    height: ${(props): string => (props.stretch ? "100%" : "38px")};
    min-height: 38px;
  }
`;

export const QtyButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const QtyIconButton = styled.button<StretchProps>`
  display: flex;
  width: 100%;
  min-width: 38px;
  height: 40px;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    width: ${(props): string => (props.stretch ? "100%" : "38px")};
    height: ${(props): string => (props.stretch ? "100%" : "38px")};
    min-height: 38px;
  }

  &:disabled {
    pointer-events: none;
  }
`;
