import { gql } from "graphql-tag";
import { useQuery } from "react-query";
import { graphQLClient } from "../dato-graphql-client";

import type { ProductBySlugQuery, ProductBySlugQueryVariables } from "../generated-gql/graphql";
import { ProductParts } from "./fragments";

export function useGetProductBySlug({ slug }: ProductBySlugQueryVariables) {
  const { data, isLoading, error } = useQuery(`get-product-by-${slug}`, async () => {
    const request = await graphQLClient.request<{
      allProductPages: ProductBySlugQuery;
    }>(
      gql`
        query ProductBySlug($slug: String!) {
          allProductPages(filter: { slug: { eq: $slug } }) {
            ...ProductParts
          }
        }
        ${ProductParts}
      `,
      { slug },
    );
    return request;
  });

  return { data: data?.allProductPages, isLoading, error };
}
