import { gql } from "graphql-tag";
import { useQuery } from "react-query";
import { graphQLClient } from "../dato-graphql-client";
import { DatoFrontpageParts } from "./fragments";

import type { GetFrontPageQuery, GetFrontPageQueryVariables } from "../generated-gql/graphql";

export function useGetFrontPage({ forPro, limitDate }: GetFrontPageQueryVariables) {
  const { data, isLoading, error } = useQuery(
    `get-specific-front-page-by-${forPro ? "forPro" : "common"}-${limitDate}`,
    async () => {
      const request = await graphQLClient.request<{
        allFrontpages: GetFrontPageQuery;
      }>(
        gql`
          query GetFrontPage($limitDate: DateTime, $forPro: BooleanType!) {
            allFrontpages(
              filter: { published: { lt: $limitDate }, forsidaFyrirFagadila: { eq: $forPro } }
              first: 1
              orderBy: [published_DESC]
            ) {
              ...DatoFrontpageParts
            }
          }
          ${DatoFrontpageParts}
        `,
        { limitDate, forPro },
      );
      return request;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return { data: data?.allFrontpages, isLoading, error };
}
