import styled from "styled-components";

import { theme } from "@byko/lib-styles";

import type { QuantityInputContainerProps, TextWrapperProps } from "./interface";

export const QtyButtonContainer = styled.div<QuantityInputContainerProps>`
  display: flex;
  width: ${(props): string => (props.stretch ? "100%" : "192px")};
  height: ${(props): string => (props.textInRow ? "38px" : "54px")};
  align-items: center;
  justify-content: space-between;
  padding: 10px 2px 10px 17px;
  border: 1px solid ${theme.palette.gray[10]};
  background-color: ${theme.palette.white.main};
`;

export const TextWrapper = styled.div<TextWrapperProps>`
  display: flex;
  width: 100%;
  flex-direction: ${(props): string => (props.textInRow ? "row" : "column")};
  align-items: ${(props): string => (props.textInRow ? "center" : "flex-start")};
  gap: ${(props): string => (props.textInRow ? "10px" : "0")};
  p {
    font-size: 12px;
    text-transform: ${(props): string => (props.uppercase ? "uppercase" : "capitalize")};
    user-select: none;
  }
`;
export const SeperatorLine = styled.div`
  width: 1px;
  height: 24px;
  background-color: ${theme.palette.gray[10]};
`;

export const QtyInputNumber = styled.input`
  max-width: 50px;
  border: none;
  background-color: ${theme.palette.white.main};
  color: ${theme.palette.black.main};
  outline: none;
`;

export const QtyButtonWrapper = styled.div`
  display: flex;
`;

export const QtyIconButton = styled.button<React.ButtonHTMLAttributes<HTMLButtonElement>>`
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-left: 1px solid ${theme.palette.gray[10]};

  &:disabled {
    pointer-events: none;
  }
`;
