/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const NetgiroIcons: BykoReactIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 20" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 18.2667V15.2334H0.780267V15.4743C1.01934 15.2718 1.3233 15.162 1.63661 15.1651C1.83649 15.1634 2.03424 15.2063 2.21545 15.2906C2.39666 15.375 2.55677 15.4987 2.68412 15.6528C2.88894 15.8927 2.9972 16.1941 2.9972 16.7256V18.2784H2.1789V16.6652C2.1789 16.3648 2.10672 16.2077 1.99748 16.0995C1.93651 16.0423 1.86471 15.9979 1.78632 15.9689C1.70792 15.94 1.62452 15.927 1.54103 15.9307C1.39834 15.9334 1.25812 15.9685 1.13091 16.0332C1.00371 16.0979 0.892824 16.1906 0.806601 16.3043V18.2784H0V18.2667Z"
        fill="#64C2A1"
      />
      <path
        d="M6.94418 17.7848C6.65158 18.134 6.16391 18.3261 5.69282 18.3261C4.78966 18.3261 4.104 17.7009 4.104 16.75C4.104 15.9073 4.6814 15.165 5.58456 15.165C6.42725 15.165 7.01635 15.8751 7.01635 16.7178C7.01558 16.7944 7.00775 16.8708 6.99294 16.946H4.88622C4.90656 17.1473 5.00216 17.3335 5.15389 17.4673C5.30561 17.6011 5.50228 17.6727 5.70453 17.6677C5.84975 17.6644 5.99281 17.6318 6.12518 17.572C6.25756 17.5122 6.37654 17.4263 6.47504 17.3196L6.94418 17.7848ZM6.18634 16.3647C6.16821 16.2181 6.09694 16.0832 5.98604 15.9856C5.87513 15.888 5.73229 15.8345 5.58456 15.8351C5.43133 15.833 5.28227 15.885 5.1635 15.9818C5.04473 16.0787 4.96384 16.2142 4.93499 16.3647H6.18634Z"
        fill="#64C2A1"
      />
      <path
        d="M10.399 18.2666C10.2337 18.307 10.064 18.327 9.89382 18.3261C9.4003 18.3261 9.05113 18.1574 8.84631 17.893C8.69025 17.6882 8.61808 17.4112 8.61808 16.9899V15.917H7.77539V15.2343H8.61808V14.3916H9.43638V15.2343H10.4361V15.917H9.4237V17.0728C9.41301 17.2084 9.44729 17.3438 9.52124 17.4581C9.57492 17.5104 9.63938 17.5505 9.71013 17.5754C9.78088 17.6004 9.85621 17.6096 9.93088 17.6024C10.0438 17.5995 10.1564 17.5875 10.2674 17.5663L10.399 18.2666Z"
        fill="#64C2A1"
      />
      <path
        d="M14.5031 18.0982C14.5031 18.6395 14.4055 18.9643 14.2261 19.2296C13.9471 19.6149 13.4594 19.847 12.8742 19.847C12.289 19.847 11.7185 19.57 11.4181 19.2208L11.936 18.6912C12.0522 18.8295 12.1978 18.9401 12.3621 19.0152C12.5264 19.0903 12.7053 19.1279 12.886 19.1252C13.0014 19.1311 13.1167 19.1127 13.2246 19.0712C13.3324 19.0297 13.4304 18.9661 13.5121 18.8843C13.6331 18.7517 13.7072 18.5595 13.7072 18.1743V18.0768C13.4758 18.2638 13.1853 18.362 12.8879 18.3538C12.67 18.3521 12.4549 18.3052 12.2561 18.216C12.0573 18.1269 11.8792 17.9974 11.7331 17.8359C11.4787 17.5368 11.3418 17.1555 11.3478 16.763C11.3478 15.8725 12.0101 15.1742 12.8401 15.1742C13.1651 15.1669 13.4816 15.2782 13.7306 15.4872V15.2463H14.5109V18.0992L14.5031 18.0982ZM13.6847 16.2929C13.5991 16.1757 13.4868 16.0806 13.357 16.0156C13.2272 15.9507 13.0838 15.9176 12.9386 15.9193C12.4568 15.9193 12.1584 16.3163 12.1584 16.7503C12.1538 16.8584 12.1714 16.9664 12.21 17.0675C12.2486 17.1686 12.3073 17.2608 12.3827 17.3385C12.4581 17.4162 12.5485 17.4777 12.6485 17.5193C12.7484 17.5608 12.8557 17.5816 12.964 17.5803C13.1075 17.583 13.2496 17.5505 13.3776 17.4855C13.5056 17.4205 13.6158 17.3252 13.6984 17.2077V16.2929H13.6847Z"
        fill="#64C2A1"
      />
      <path
        d="M16.7299 18.2668H15.9116V15.2335H16.7299V18.2668ZM17.2956 13.7646L16.5495 14.7634H15.9838L16.4168 13.7646H17.2956Z"
        fill="#64C2A1"
      />
      <path
        d="M19.9943 15.9551C19.8993 15.9276 19.8005 15.9154 19.7017 15.919C19.5525 15.9228 19.4068 15.9646 19.2783 16.0404C19.1498 16.1161 19.0428 16.2234 18.9672 16.352V18.2666H18.1489V15.2333H18.9292V15.5464C19.1096 15.3172 19.4471 15.1729 19.807 15.1729C19.8999 15.173 19.9925 15.1812 20.084 15.1972L19.9943 15.9551Z"
        fill="#64C2A1"
      />
      <path
        d="M22.4472 15.173C22.7587 15.179 23.0615 15.2767 23.3177 15.454C23.5739 15.6312 23.7721 15.8801 23.8874 16.1695C24.0028 16.4589 24.0302 16.7759 23.9662 17.0808C23.9022 17.3857 23.7497 17.6649 23.5278 17.8835C23.3058 18.1021 23.0243 18.2504 22.7184 18.3097C22.4126 18.369 22.0961 18.3368 21.8085 18.217C21.5209 18.0973 21.275 17.8953 21.1017 17.6365C20.9283 17.3776 20.8352 17.0734 20.834 16.7619C20.8324 16.5507 20.8732 16.3413 20.954 16.1462C21.0348 15.9511 21.154 15.7743 21.3044 15.6261C21.4549 15.4779 21.6336 15.3615 21.8299 15.2836C22.0262 15.2058 22.2361 15.1682 22.4472 15.173ZM22.4472 17.5802C22.6126 17.585 22.7756 17.5404 22.9155 17.4521C23.0553 17.3637 23.1656 17.2356 23.2322 17.0842C23.2988 16.9328 23.3188 16.765 23.2894 16.6022C23.2601 16.4394 23.1828 16.289 23.0676 16.1704C22.9523 16.0518 22.8042 15.9702 22.6423 15.9363C22.4804 15.9023 22.3121 15.9174 22.1588 15.9797C22.0056 16.0419 21.8744 16.1485 21.7821 16.2858C21.6898 16.423 21.6405 16.5847 21.6406 16.7501C21.6523 17.2076 21.9771 17.5802 22.4472 17.5802ZM23.4821 13.7646L22.7359 14.7634H22.1702L22.6033 13.7646H23.4821Z"
        fill="#64C2A1"
      />
      <path
        d="M3.80371 5.95009V11.4588C5.26497 11.4588 6.66643 10.8785 7.69998 9.8455C8.73352 8.8125 9.31455 7.41135 9.31532 5.95009V0.4375C8.59145 0.4375 7.87466 0.580094 7.2059 0.85714C6.53713 1.13419 5.92949 1.54026 5.41768 2.05216C4.90587 2.56406 4.4999 3.17177 4.22298 3.84059C3.94605 4.5094 3.80358 5.22621 3.80371 5.95009Z"
        fill="#00ADEF"
      />
      <path
        d="M14.7441 5.95009V11.4588C15.4681 11.4589 16.185 11.3164 16.8539 11.0394C17.5227 10.7624 18.1305 10.3564 18.6424 9.84446C19.1543 9.33255 19.5604 8.72479 19.8374 8.05592C20.1144 7.38704 20.2569 6.67015 20.2567 5.94619V0.4375C19.5328 0.437372 18.8159 0.579872 18.147 0.85686C17.4781 1.13385 16.8704 1.5399 16.3585 2.05181C15.8465 2.56373 15.4405 3.17148 15.1635 3.84036C14.8865 4.50924 14.744 5.22613 14.7441 5.95009Z"
        fill="#FFE200"
      />
      <path
        d="M14.7802 5.95009C14.7803 5.22597 14.6377 4.50891 14.3606 3.83991C14.0835 3.17091 13.6773 2.56307 13.1652 2.05113C12.653 1.53918 12.0451 1.13317 11.376 0.856301C10.7069 0.579427 9.98975 0.437116 9.26562 0.437501V5.95009C9.26601 6.67389 9.40895 7.39052 9.68629 8.05907C9.96363 8.72762 10.3699 9.335 10.882 9.84653C11.3941 10.3581 12.0019 10.7637 12.6707 11.0404C13.3396 11.317 14.0564 11.4592 14.7802 11.4588V5.95009Z"
        fill="#64C2A1"
      />
    </svg>
  );
};
