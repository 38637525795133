/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled from "styled-components";

interface ImageProps {
  src: string;
}

export const BackgroundImageCover = styled.div<ImageProps>`
  width: 100%;
  height: 100%;
  background-image: url("${(p) => p.src.replace("//", "")}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const BackgroundImageContain = styled.div<ImageProps>`
  width: 100%;
  height: 100%;
  background-image: url("${(p) => p.src.replace("//", "")}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;
