import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const BeraSamanIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg
      className="byko-icon"
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2 15L13.8 15.7C14.6 16.5 15.6 17 16.8 17H22"
        stroke={iconColor}
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path d="M1 7H4.2C5.3 7 6.4 7.5 7.2 8.3L7.8 9" stroke={iconColor} strokeMiterlimit="10" strokeWidth="2" />
      <path
        d="M1 17H4.2C5.3 17 6.4 16.5 7.2 15.7L13.8 8.3C14.6 7.5 15.6 7 16.8 7H22"
        stroke={iconColor}
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path d="M19 14L22 17L19 20" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M19 10L22 7L19 4" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
    </svg>
  );
};
