import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const ArrowDownIcons: BykoReactIconComponentType = ({ size, iconColor = "#00416A" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M18 10L12 16L6 10" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
    </svg>
  );
};
