import styled from "styled-components";

import { theme } from "@byko/lib-styles";

import type { ButtonWrapperProps } from "./interface";

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  position: relative;
  display: grid;
  width: 100%;
  border-top: 1px solid ${theme.palette.gray[10]};
  grid-template-columns: 56px auto;
  @media screen and (max-width: 1078px) {
    grid-template-columns: 56px auto;
  }
  @media screen and (max-width: 698px) {
    grid-template-columns: 40px auto;
  }
  .icon-button {
    height: 100%;
    border: none;
  }
  .button-container,
  .add-to-cart-button {
    width: 100%;
    border: none;
  }
  .add-to-cart-button {
    cursor: ${(props): string => (props.disabledAddToCart ? "wait" : "pointer")} !important;
    pointer-events: ${(props): string => (props.disabledAddToCart ? "none" : "initial")} !important;
    transition: 300ms !important;
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  padding: 10px;
  margin: auto;
  background-color: ${theme.palette.blue.dark};
`;
