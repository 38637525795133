import { restApi } from "@byko/lib-api-rest";

/**
 * Config
 */
const TIME_BETWEEN_FETCH_MS = 150;
const MAX_ITEMS_IN_BATCH = 48;

/**
 * PriceItem - interface for a single item from the batch.
 */
export interface PriceItem {
  // discounted.name can have two values: "RETAIL" or "CUSTOMER"
  price: { vat: number; net: number; gross: number; perSqm?: number | null };
  discounted: { vat: number; net: number; gross: number; perSqm?: number | null; name: string; percentage: number };
  isSqmProduct?: boolean;
  packagingQuantity?: number;
  sqm: number | null;
}

/**
 * PriceItems - multiple PriceITem record.
 */
type PriceItems = Record<string, PriceItem>;

/**
 * This is an helper for the batch-request to resolve, reject a single variant.
 */
interface SlugRequest {
  sku: string;
  resolve: (value: PriceItem) => void;
  reject: () => void;
}

/** DO NOT TOUCH. These are global objects used to control the batch process. */
const FETCH_STACK: SlugRequest[] = [];
let timer: number | null = null;

/**
 * DO NOT USE OUTSIDE OF THIS FILE!
 * Internal util for batch request. Should only be called with window.setTimeout or similar.
 * Removes first MAX_ITEMS_IN_BATCH from the stack, and processes (elements array).
 *
 * Resolves and rejects all sku in the elements array.
 */
const _fetchNow = async (): Promise<void> => {
  timer = null;
  const elements = FETCH_STACK.splice(0, MAX_ITEMS_IN_BATCH);

  if (elements.length === 0) {
    return;
  }
  /**
   * Creates an object with all promise (resolve and reject) in elements array.
   */
  const ObjectSKUResolver = elements.reduce((a, b) => {
    a[b.sku] = {
      resolve: b.resolve,
      reject: b.reject,
    };
    return a;
  }, {} as Record<string, Omit<SlugRequest, "sku">>);

  /**
   * internal helper functions for the internal util _fetchNow.
   */

  /** Reject single variant - called when price could not be fetched (404, 5xx) */
  const rejectSKU = (sku: keyof typeof ObjectSKUResolver): (() => void) => {
    const obj = ObjectSKUResolver[sku];
    if (!obj) {
      throw new Error("Variant rejected which was not in original batch. Should not happen?");
    }
    return obj.reject;
  };

  /**
   * Reject all variants in element array.
   */
  const rejectAll = (): void => Object.keys(ObjectSKUResolver).forEach((sku) => rejectSKU(sku)());

  /**
   * Resolve variant with PriceItem.
   */
  const resolveSKU = (sku: keyof typeof ObjectSKUResolver): ((value: PriceItem) => void) => {
    const obj = ObjectSKUResolver[sku];
    if (!obj) {
      throw new Error(`Price API returned an variant which was not in the original batch: ${sku}`);
    }
    return obj.resolve;
  };

  /**
   * Clean process.
   */
  const clean = (): void => {
    if (FETCH_STACK.length && !timer) {
      timer = window.setTimeout(_fetchNow, TIME_BETWEEN_FETCH_MS);
    }
  };

  /** End of internal helper functions for internal helper util. */

  /** All SKU's in the element array.  */
  const skus = elements.map(({ sku }) => sku).filter((e) => e != null) as string[];

  /** SKU's string - solely for rest api url. */
  const data = await restApi.productsPricesList({
    sku: skus,
  });

  /**
   * Rest API responded with error. REJECT ALL PRICES.
   * TODO: Add again to batch with a timeout?
   */
  if (!data.ok) {
    rejectAll();
    clean();
    return;
  }

  /**
   * Results from API - SWAGGER AUTOGENERATED TYPE DEFINITION IS WRONG.
   * WE PLAY GOD AGAIN!
   */
  const priceResults = (data.data.results ?? {}) as PriceItems;

  /**
   * Found variant's sku's.
   */
  const found = Object.keys(priceResults)
    .map((sku) => sku)
    .filter((sku) => skus.includes(sku));

  /**
   *  Not found variant's sku's. Included in batch request but not in found
   */
  const notFound = skus.filter((sku) => !found.includes(sku));

  /** ACTION: Reject all not found variants! */
  notFound.forEach((sku) => rejectSKU(sku)());

  /** ACTION: Resolve all found variants! */
  found.forEach((sku) => {
    const value = priceResults[sku as keyof typeof priceResults] as PriceItem;
    resolveSKU(sku)(value);
  });

  /** ACTION: Clean up! */
  clean();
};

/**
 * Batch request for price api.
 * Returns an promise that is resolved when price is fetched from the API for the variant.
 * Rejects a promise if variant is not found or API returns an error.
 */
export const priceApiBatchGet = ({ sku }: { sku: string }): Promise<PriceItem> => {
  /*
    Pushes an object: sku and promises, resolve and reject, to the stack.
  */
  return new Promise((resolve, reject) => {
    const obj: SlugRequest = {
      sku,
      resolve,
      reject,
    };
    FETCH_STACK.push(obj);

    /**
     * If timer is null, no action is pending. Create a new one.
     */
    if (timer == null) {
      timer = window.setTimeout(_fetchNow, TIME_BETWEEN_FETCH_MS);
    }
  });
};
