import { selector, selectorFamily } from "@byko/lib-recoil";

import { checkout } from "./checkout";
import { Key } from "./const";

import type { CheckoutParsed } from "./checkout";

type CheckoutFragmentValue = NonNullable<CheckoutParsed>;
type LinesGQL = NonNullable<CheckoutFragmentValue["lines"]>;
export type Line = NonNullable<LinesGQL[number]>;
export type LinesNonNull = Line[];
export type CartItem = Line;

export type CartObject = Record<string, Line>;

export const checkoutLines = selector<LinesNonNull>({
  key: Key("CheckoutLines"),
  get: ({ get }) => {
    const checkoutValue = get(checkout);
    return checkoutValue?.lines ?? [];
  },
});

export const cartObject = selector<CartObject>({
  key: Key("CartObject"),
  get: ({ get }) => {
    const cart = get(checkoutLines);
    return cart.reduce((a, b) => {
      const id = b.variant.parsedID;
      if (!id) {
        return a;
      }
      a[id] = b;
      return a;
    }, {} as CartObject);
  },
});

export const totalCartCount = selector<number>({
  key: Key("TotalCartCount"),
  get: ({ get }) => {
    const items = get(checkoutLines);

    return items.reduce((count, item) => {
      if (item?.quantity && typeof item?.quantity === "number" && !Number.isNaN(item?.quantity)) {
        return count + item.quantity;
      }
      return count;
    }, 0);
  },
});

export const selectorCartItem = selectorFamily<Line | null, string>({
  key: Key("SelectorCartItem"),
  get:
    (id) =>
    ({ get }): Line | null => {
      const value = get(cartObject);
      if (value[id]) {
      }
      return value[id] ?? null;
    },
});

export const selectorCartItemCount = selectorFamily<number, string>({
  key: Key("SelectorCartItemCount"),
  get:
    (id) =>
    ({ get }): number => {
      const value = get(cartObject);
      if (!value[id]) {
        return 0;
      }
      const count =
        typeof value[id]?.quantity === "number" && !Number.isNaN(value[id]?.quantity)
          ? (value[id]?.quantity as number)
          : 0;
      return count;
    },
});
