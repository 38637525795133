import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const PaintRollFilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25 26H23V21H2C1.447 21 1 20.552 1 20V8C1 7.448 1.447 7 2 7H8V9H3V19H24C24.553 19 25 19.448 25 20V26Z"
        fill="#AEAEAE"
      />
      <path d="M28 26H20V46H28V26Z" fill="#FFD330" />
      <path
        // eslint-disable-next-line max-len
        d="M28 47H20C19.447 47 19 46.552 19 46V26C19 25.448 19.447 25 20 25H28C28.553 25 29 25.448 29 26V46C29 46.552 28.553 47 28 47ZM21 45H27V27H21V45Z"
        fill="#FFD330"
      />
      <path
        d="M38 32C38 29.791 42 24 42 24C42 24 46 29.791 46 32C46 34.209 44.209 36 42 36C39.791 36 38 34.209 38 32Z"
        fill="#00416A"
      />
      <path
        // eslint-disable-next-line max-len
        d="M42 37C39.243 37 37 34.757 37 32C37 29.601 40.479 24.441 41.177 23.432L42 22.24L42.823 23.431C43.521 24.441 47 29.601 47 32C47 34.757 44.757 37 42 37ZM42 25.808C40.561 28.057 39 30.908 39 32C39 33.654 40.346 35 42 35C43.654 35 45 33.654 45 32C45 30.907 43.439 28.057 42 25.808Z"
        fill="#00416A"
      />
      <path d="M40 15H8C6.895 15 6 14.105 6 13V4H42V13C42 14.105 41.105 15 40 15Z" fill="#E6E7E8" />
      <path d="M42 3V5H6V3C6 1.895 6.895 1 8 1H40C41.105 1 42 1.895 42 3Z" fill="#00416A" />
    </svg>
  );
};
