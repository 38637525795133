import { atom, useRecoilValue, useSetRecoilState } from "@byko/lib-recoil";

type LoginStateStore = "finished" | "in-progress" | null;

export const loginStateStore = atom<LoginStateStore>({
  key: "login-component-login=state",
  default: null,
});

export const useLoginStateStore = (): LoginStateStore => useRecoilValue(loginStateStore);
export const useSetLoginStateStore = (): ((value: LoginStateStore) => void) => useSetRecoilState(loginStateStore);
