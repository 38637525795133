import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";

import { Alert } from "@byko/component-alert";
import { Button } from "@byko/component-buttons";
import { blockDrawerClickOutsideState } from "@byko/component-drawer";
import { FormikInput, showFormikError } from "@byko/component-inputs";
import { MainLoader } from "@byko/component-loaders";
import { Checkbox } from "@byko/component-selectors";
import { PMedium, PMediumBold } from "@byko/component-typography";
import { EyebrowSmall, H6 } from "@byko/component-typography";
import { useAuth } from "@byko/hooks-auth-next";
import { FormikProvider, useFormik } from "@byko/lib-formik";
import { LongArrowRightIcons, LongArrowSideIcons } from "@byko/lib-icons";
import { useSetRecoilState } from "@byko/lib-recoil";
import { theme } from "@byko/lib-styles";
import { isSSR } from "@byko/types-utils";
import { datoQueries } from "@byko/lib-dato";
import { PolledLoginMethodEnum } from "@byko/lib-api-rest";
import { Tabs } from "../tabs";

import {
  Buttons,
  Container,
  FlexRow,
  Form,
  Inputs,
  LoadingWrapper,
  SecurityCodeSectionContent,
  SecurityCodeSectionWrapper,
} from "./styles";

import type { FormValues } from "./interface";
import classNames from "classnames";

const regex = /[^0-9]/g;

export const LoginForm = (): JSX.Element => {
  const { data } = datoQueries.useGetHeaderText();
  const [saveAuth, setSaveAuth] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<string>(PolledLoginMethodEnum.Sim);
  const setBlockDrawerClose = useSetRecoilState<boolean>(blockDrawerClickOutsideState);
  const { login, loginError, loading, setError, handleSetPrefersAnonymousCheckout, showAsGuestOption, securityCode } =
    useAuth();

  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const isSim = activeTab === PolledLoginMethodEnum.Sim;
  const authTypeName = isSim ? "phoneNumber" : "ssn";

  useEffect(() => {
    const auth = localStorage.getItem(authTypeName);
    if (auth === undefined) {
      setSaveAuth(false);
    }
  }, [authTypeName]);

  useEffect(() => {
    if (showSuccess) {
      setTimeout(() => {
        setShowSuccess(false);
      }, 5000);
    }
  }, [showSuccess]);

  useEffect(() => {
    if (!loginError) {
      setError("");
    } else {
      setShowSuccess(true);
    }
  }, [loginError, setError]);

  const storedPhoneNumber = isSSR() ? undefined : localStorage.getItem("phoneNumber");
  const storedSsn = isSSR() ? undefined : localStorage.getItem("ssn");

  const initialValues: FormValues = {
    phoneNumber: storedPhoneNumber ? storedPhoneNumber : "",
    ssn: storedSsn ? storedSsn : "",
  };

  const phoneSchema = Yup.object({
    phoneNumber: Yup.string()
      .required("Nauðsynlegt")
      .min(7, "Lágmark 7 stafir")
      .max(15, "Aðeins 15 tölustafir eru leyfðir"),
  });

  const ssnSchema = Yup.object({
    ssn: Yup.string().required("Nauðsynlegt").min(10, "Lágmark 10 stafir").max(10, "Aðeins 10 tölustafir eru leyfðir"),
  });

  const formik = useFormik({
    initialValues,
    validateOnChange: true,
    validateOnBlur: false,
    validateOnMount: false,
    validationSchema: isSim ? phoneSchema : ssnSchema,
    onSubmit: async (values) => {
      setBlockDrawerClose(true);
      const value = isSim ? values.phoneNumber : values.ssn;
      const method = isSim ? PolledLoginMethodEnum.Sim : PolledLoginMethodEnum.App;
      if (saveAuth) {
        localStorage.setItem(authTypeName, value);
      }
      await login(value, method);
      setBlockDrawerClose(false);
    },
  });

  const handleCheckboxChange = useCallback(() => {
    setSaveAuth((current) => {
      return !current;
    });
  }, []);

  useEffect(() => {
    if (saveAuth === false) {
      localStorage.removeItem(authTypeName);
    }
  }, [authTypeName, saveAuth]);

  // only allow to write numbers
  const handleInputs = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      void formik.setFieldValue(authTypeName, `${value.replace(regex, "")}`);
    },
    [authTypeName, formik],
  );

  const tabs = useMemo(
    () => [
      {
        id: PolledLoginMethodEnum.Sim,
        label: "Rafræn skilríki",
        content: (
          <FormikInput
            error={showFormikError(formik, "phoneNumber")}
            errorText={formik.errors.phoneNumber ?? false}
            id="phoneNumber"
            inputMode="tel"
            label={data?.descriptionForLoginPopover ?? "Skráðu þig inn með rafrænum skilríkjum"}
            maxLength={15}
            pattern="[0-9]*"
            placeholder={data?.hintForPhoneNumberInput ?? "Sláðu inn símanúmer"}
            type="tel"
            {...formik.getFieldProps("phoneNumber")}
            stretch={true}
            onChange={handleInputs}
          />
        ),
      },
      {
        id: PolledLoginMethodEnum.App,
        label: "Auðkennisapp",
        content: (
          <FormikInput
            error={showFormikError(formik, "ssn")}
            errorText={formik.errors.ssn ?? false}
            id="ssn"
            inputMode="numeric"
            label={data?.descriptionForAudkennisappLoginPopover ?? "Skráðu þig inn með auðkennisappi"}
            maxLength={10}
            pattern="[0-9]*"
            placeholder={data?.hintForKennitalaInput ?? "Sláðu inn kennitölu"}
            type="ssn"
            {...formik.getFieldProps("ssn")}
            stretch={true}
            onChange={handleInputs}
          />
        ),
      },
    ],
    [
      data?.descriptionForAudkennisappLoginPopover,
      data?.descriptionForLoginPopover,
      data?.hintForKennitalaInput,
      data?.hintForPhoneNumberInput,
      formik,
      handleInputs,
    ],
  );

  return (
    <>
      {loading ? (
        <LoadingWrapper>
          <PMedium textColor={theme.palette.blue.dark}>Auðkenning send, kíktu á símann þinn. </PMedium>
          <MainLoader size={48} />

          <SecurityCodeSectionWrapper className={classNames({ show: !!securityCode })}>
            <SecurityCodeSectionContent>
              <PMedium lineHeight={"150%"} textAlign="center" top={12}>
                Aðeins staðfesta auðkenninguna ef öryggistalan er sú sama og birtist á símanum þínum.{" "}
              </PMedium>
              <PMediumBold lineHeight={"150%"} textAlign="center" top={12}>
                Öryggistalan þín er: {securityCode}
              </PMediumBold>
              <PMedium lineHeight={"150%"} textAlign="center" top={12}>
                ATH pinnið þitt er ekki það sama og öryggistalan.
              </PMedium>
            </SecurityCodeSectionContent>
          </SecurityCodeSectionWrapper>
        </LoadingWrapper>
      ) : (
        <>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <Container>
                <Inputs>
                  <H6 bottom={32} maxFontSize="32px">
                    {data?.headingForLoginPopover ?? "Innskráning"}
                  </H6>

                  <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />

                  <FlexRow>
                    <Checkbox
                      checked={saveAuth}
                      label={
                        isSim
                          ? data?.labelForRememberPhoneNumberCheckbox ?? "Muna símanúmer"
                          : data?.labelForRememberKennitalaCheckbox ?? "Muna kennitölu"
                      }
                      smallBox={true}
                      onChange={handleCheckboxChange}
                    />
                    <a
                      aria-label={data?.labelForAssistanceWithElectronicIdLink ?? "Þarftu aðstoð með rafræn skilríki?"}
                      href="https://www.skilriki.is/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <EyebrowSmall>
                        {data?.labelForAssistanceWithElectronicIdLink ?? "Aðstoð með rafræn skilríki?"}
                      </EyebrowSmall>
                    </a>
                  </FlexRow>
                </Inputs>
                <Buttons>
                  <Button
                    buttonColor="blueButton"
                    disabled={formik.isSubmitting}
                    icon={LongArrowSideIcons}
                    label={data?.labelForLoginButton ?? "Innskrá"}
                    stretch={true}
                    type="submit"
                  />
                  {showAsGuestOption && (
                    <Button
                      buttonColor="whiteButton"
                      icon={LongArrowRightIcons}
                      label="Halda áfram sem gestur"
                      stretch={true}
                      type="submit"
                      onClick={handleSetPrefersAnonymousCheckout}
                    />
                  )}

                  <Alert
                    alertType="warning"
                    showSuccess={showSuccess}
                    stretch={true}
                    text={loginError || "Eitthvað fór úrskeiðis"}
                    top="20"
                  />
                </Buttons>
              </Container>
            </Form>
          </FormikProvider>
        </>
      )}
    </>
  );
};
