/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const WrenchIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_924_5307)">
        <path
          d="M22.6974 5.07656L23.649 4.76912L23.1476 3.21738L21.9919 4.36785L22.6974 5.07656ZM19.4629 8.29638L18.7575 9.00516L19.463 9.70732L20.1684 9.0051L19.4629 8.29638ZM15.6885 4.53975L14.9829 3.83115L14.2711 4.53993L14.983 5.24853L15.6885 4.53975ZM18.9219 1.31992L19.6275 2.02852L20.836 0.825188L19.1958 0.358153L18.9219 1.31992ZM12.6673 2.93628L11.9228 2.26865L12.6673 2.93628ZM11.4862 8.72625L12.0898 9.52354L12.7009 9.0609L12.413 8.35058L11.4862 8.72625ZM0.920898 16.725L0.317295 15.9277L-0.0791016 16.2278V16.725H0.920898ZM0.920898 23H-0.0791016V24H0.920898V23ZM7.27348 23V24H7.76983L8.06996 23.6047L7.27348 23ZM15.2586 12.4818L15.5396 11.5221L14.8786 11.3286L14.4621 11.8772L15.2586 12.4818ZM21.1228 11.2279L21.8015 11.9623L21.1228 11.2279ZM21.9919 4.36785L18.7574 7.58767L20.1684 9.0051L23.4029 5.78527L21.9919 4.36785ZM20.1684 7.5876L16.3939 3.83097L14.983 5.24853L18.7575 9.00516L20.1684 7.5876ZM16.3941 5.24835L19.6275 2.02852L18.2163 0.611324L14.9829 3.83115L16.3941 5.24835ZM10.8826 7.92897L0.317295 15.9277L1.5245 17.5223L12.0898 9.52354L10.8826 7.92897ZM8.06996 23.6047L16.0551 13.0865L14.4621 11.8772L6.477 22.3953L8.06996 23.6047ZM0.920898 24H7.27348V22H0.920898V24ZM-0.0791016 16.725V23H1.9209V16.725H-0.0791016ZM21.8015 11.9623C23.7915 10.123 24.4717 7.31555 23.649 4.76912L21.7459 5.384C22.3372 7.2143 21.8424 9.20106 20.444 10.4936L21.8015 11.9623ZM14.9776 13.4415C17.336 14.1321 19.9044 13.7158 21.8015 11.9623L20.444 10.4936C19.1309 11.7073 17.3171 12.0426 15.5396 11.5221L14.9776 13.4415ZM11.9228 2.26865C10.3633 4.0077 9.61239 6.76548 10.5595 9.10192L12.413 8.35058C11.8021 6.84363 12.2686 4.87867 13.4118 3.6039L11.9228 2.26865ZM19.1958 0.358153C16.6343 -0.371227 13.8519 0.117419 11.9228 2.26865L13.4118 3.6039C14.737 2.12603 16.6775 1.72057 18.6481 2.28169L19.1958 0.358153Z"
          fill={iconColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_924_5307">
          <rect fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
};
