import { useMemo } from "react";

import type { ParagraphProps } from "./interface";

export const Paragraph = ({ paragraph }: ParagraphProps): JSX.Element => {
  const localParagraph = useMemo((): JSX.Element => {
    try {
      // Removes all instances of h1 and h2 tags, data originates from DatoCMS
      const children = paragraph.props.data.value.document.children.filter((child: { type: string; level: number }) => {
        return !(child.type === "heading" && child.level === 1) && !(child.type === "heading" && child.level === 2);
      });

      return {
        ...paragraph,
        props: {
          ...paragraph.props,
          data: {
            ...paragraph.props.data,
            value: {
              ...paragraph.props.data.value,
              document: {
                ...paragraph.props.data.value.document,
                children,
              },
            },
          },
        },
      };
    } catch (e) {
      return paragraph;
    }
  }, [paragraph]);

  return localParagraph;
};
