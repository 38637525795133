import React, { useCallback, useMemo, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { PageContainer } from "@byko/component-page-container";

import { SliderButtons } from "./components";
import { ClickableSlide } from "./components/clickable-slide";
import { SwiperContainer, SwiperStyledContainer } from "./styles";

import type { SliderProps } from "./interface";
import { gridTheme, useWindowSize } from "@byko/lib-styles";
import type { SwiperOptions } from "swiper/types";
import type { Swiper as Swiperclass } from "swiper/types";

export const Slider = ({
  slideCollection,
  slidesPerGroup = 1,
  spaceBetween = 20,
  breakpoints,
  paginationGutter = false,
  autoPadLeft = false,
  autoPadRight = false,
  autoPad = false,
  slideWidth = 325,
  onClick,
  resetCondition,
}: SliderProps): JSX.Element | null => {
  const { width } = useWindowSize();

  const sidePadding = useMemo((): number => {
    if (width <= 699) {
      return 24;
    }

    if (width <= 1079) {
      return 48;
    }

    if (width <= 1439) {
      return 56;
    }

    if (width >= gridTheme.maxContentWidth + 309) {
      return (width - gridTheme.maxContentWidth) * 0.5;
    }

    return 56 + width * 0.05;
  }, [width]);

  const localBreakpoints = useMemo((): { [width: number]: SwiperOptions } => {
    if (breakpoints) {
      return breakpoints;
    }

    const content: { [width: number]: SwiperOptions } = {};
    const localSlideWidth = slideWidth ?? 325;
    for (let i = 320; i < 2400; i++) {
      const sliderPerView = i / localSlideWidth;
      content[i] = {
        slidesPerView: sliderPerView < 1 ? 1 : sliderPerView,
        spaceBetween: 10,
      };
    }

    return content;
  }, [breakpoints, slideWidth]);

  const [progress, setProgress] = useState<number>(0);
  const localSlideCollection = useMemo(() => {
    return slideCollection.map((slide, index) => {
      return {
        ...slide,
        key: index,
      };
    }, []);
  }, [slideCollection]);

  const keys = useMemo(() => Object.keys(localBreakpoints), [localBreakpoints]);

  const getSlidesPerView = useMemo((): number => {
    let perView = 1;
    if (typeof window !== "undefined") {
      for (let i = keys.length - 1; i > 0; i--) {
        if (window.innerWidth >= Number(keys[i])) {
          perView = Number(localBreakpoints[Number(keys[i])]?.slidesPerView);
          break;
        }
      }
    }
    return perView;
  }, [keys, localBreakpoints]);

  const handleChangeHandler = useCallback(
    (swiper: Swiperclass, slidesPerView: number) => {
      const localProgress = ((slidesPerView + swiper.activeIndex) / slideCollection.length) * 100;
      setProgress(localProgress <= 100 ? localProgress : 100);
    },
    [slideCollection.length],
  );

  const handleChange = useCallback(
    (swiper: Swiperclass): void => {
      handleChangeHandler(swiper, getSlidesPerView);
    },
    [getSlidesPerView, handleChangeHandler],
  );

  const showPagination = useMemo((): boolean => {
    return getSlidesPerView < slideCollection.length;
  }, [getSlidesPerView, slideCollection.length]);

  if (width === 0) {
    return null;
  }

  return (
    <SwiperStyledContainer>
      <SwiperContainer>
        <Swiper
          key={resetCondition}
          followFinger={true}
          slidesOffsetAfter={autoPad || autoPadRight ? sidePadding : 0}
          slidesOffsetBefore={autoPad || autoPadLeft ? sidePadding : 0}
          slidesPerGroup={slidesPerGroup}
          slidesPerView="auto"
          spaceBetween={spaceBetween}
          onAfterInit={handleChange}
          onResize={handleChange}
          onTransitionStart={handleChange}
        >
          {localSlideCollection?.map((slideContent, index) =>
            onClick ? (
              <SwiperSlide key={slideContent.key}>
                <ClickableSlide key={slideContent.key} index={index} slideContent={slideContent} onClick={onClick} />
              </SwiperSlide>
            ) : (
              <SwiperSlide key={slideContent.key}>{slideContent}</SwiperSlide>
            ),
          )}
          {showPagination && Boolean(paginationGutter) && (
            <PageContainer flexDirection="column" offsetNav={false}>
              <SliderButtons progress={progress} />
            </PageContainer>
          )}
          {showPagination && !Boolean(paginationGutter) && <SliderButtons progress={progress} />}
        </Swiper>
      </SwiperContainer>
    </SwiperStyledContainer>
  );
};
