/* stylelint-disable no-extra-semicolons */
/* stylelint-disable no-extra-semicolons */
import styled from "styled-components";

import { theme } from "@byko/lib-styles";

import type { AlertProps, AlertType } from "./interface";

export const handleColorType = (alertType: AlertType): string => {
  switch (alertType) {
    case "info":
      return `background-color: ${theme.palette.blue.lightest};
              border-top: 1px solid ${theme.palette.blue.main};
              border-bottom: 1px solid ${theme.palette.blue.main}};
              svg {path {stroke: ${theme.palette.blue.main}}};`;
    case "warning":
      return `background-color: ${theme.palette.triggers.warning.lightest};
              border-top: 1px solid ${theme.palette.triggers.warning.main};
              border-bottom: 1px solid ${theme.palette.triggers.warning.main}};`;
    case "success":
      return `background-color: ${theme.palette.triggers.success.lightest};
              border-top: 1px solid ${theme.palette.triggers.success.main};
              border-bottom: 1px solid ${theme.palette.triggers.success.main}};`;
    case "attention":
      return `background-color: ${theme.palette.triggers.attention.lightest};
              border-top: 1px solid ${theme.palette.triggers.attention.main};
              border-bottom: 1px solid ${theme.palette.triggers.attention.main}};`;
    default:
      return `background-color: ${theme.palette.white.main};`;
  }
};

export const AlertContainer = styled.div<AlertProps>`
  max-width: ${(props): string => (props.stretch ? "100%" : "556px")};
  min-height: ${(props): string => (props.showSuccess ? "56px" : "0")};
  padding: ${(props): string => (props.showSuccess ? "17px" : "0")};
  margin-top: ${(p): string => (p.showSuccess ? `${p.top}px` : "0")};
  margin-bottom: ${(p): string => (p.showSuccess ? `${p.bottom}px` : "0")};
  opacity: ${(props): string => (props.showSuccess ? "1" : "0")};
  pointer-events: none;
  transition: all 0.3s;
  ${(props): string => handleColorType(props.alertType)};
`;

export const AlertContentContainer = styled.div`
  position: relative;
  display: grid;
  align-items: center;
  gap: 15px;
  grid-template-columns: 24px auto;
  pointer-events: none;
  p {
    margin-top: 4px;
  }
`;

export const AlertTooltipContainer = styled.div`
  pointer-events: initial;
`;

export const CloseIconContainer = styled.div`
  position: absolute;
  z-index: 30000000000;
  right: 0px;
  display: block;
  pointer-events: initial;
  .icon-button {
    border: unset;
    background-color: transparent;
    outline: unset;
    &:hover {
      background-color: rgba(255, 255, 255, 0.4);
    }
  }
`;
