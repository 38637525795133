import { checkoutErrorCodeMap } from "../const";

import type { CheckoutError } from "../../../generated/graphql";
import type { Maybe } from "@byko/types-utils";

export const handleCheckoutError = (err: Maybe<CheckoutError[]>): void => {
  const error = err?.[0];
  if (error) {
    const errorMSG =
      checkoutErrorCodeMap[error?.code as keyof typeof checkoutErrorCodeMap] ?? checkoutErrorCodeMap["UNKNOWN"];
    console.error(errorMSG);
  }
};
