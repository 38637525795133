import * as React from "react";
import { useMemo } from "react";

import { Button } from "@byko/component-buttons";
import { H1, H2, H3, H4, H5, H6, PLargeBold, PMediumBold } from "@byko/component-typography";
import { LongArrowSideIcons } from "@byko/lib-icons";
import { theme } from "@byko/lib-styles";
import { useWindowSize } from "@byko/lib-styles";

import { Paragraph } from "./components";
import { BlockLine, Container, HeadingContainer, IntroParagraphContainer, MainParagraphContainer } from "./styles";

import type { TextBlockVariantOneProps } from "./interface";

export const TextBlockVariantOne = ({
  heading,
  introText,
  paragraph,
  darkMode = false,
  headingUnderlineColor = "blueLine",
  showUnderline = true,
  headingDecoration: Icon,
  maxColumns = 1,
  headingSize = 2,
  headingTextColor = theme.palette.blue.dark,
  buttonLabel,
  buttonLink,
  buttonColor = "blueButton",
}: TextBlockVariantOneProps): JSX.Element => {
  const { activeBreakPoint } = useWindowSize();

  const sanitizedHeading = useMemo((): string => {
    // remove nbsp
    return heading.replace(/\u00A0/, " ");
  }, [heading]);

  const Heading = useMemo((): JSX.Element => {
    switch (headingSize) {
      case 1:
        return (
          <H1
            maxFontSize="42px"
            textColor={darkMode ? theme.palette.white.main : headingTextColor ?? theme.palette.blue.dark}
            uppercase={true}
          >
            {sanitizedHeading}
          </H1>
        );
      case 2:
        return (
          <H2
            maxFontSize="32px"
            textColor={darkMode ? theme.palette.white.main : headingTextColor ?? theme.palette.blue.dark}
            uppercase={true}
          >
            {sanitizedHeading}
          </H2>
        );
      case 3:
        return (
          <H3
            textColor={darkMode ? theme.palette.white.main : headingTextColor ?? theme.palette.blue.dark}
            uppercase={true}
          >
            {sanitizedHeading}
          </H3>
        );
      case 4:
        return (
          <H4
            textColor={darkMode ? theme.palette.white.main : headingTextColor ?? theme.palette.blue.dark}
            uppercase={true}
          >
            {sanitizedHeading}
          </H4>
        );
      case 5:
        return (
          <H5
            textColor={darkMode ? theme.palette.white.main : headingTextColor ?? theme.palette.blue.dark}
            uppercase={true}
          >
            {sanitizedHeading}
          </H5>
        );
      case 6:
        return (
          <H6
            textColor={darkMode ? theme.palette.white.main : headingTextColor ?? theme.palette.blue.dark}
            uppercase={true}
          >
            {sanitizedHeading}
          </H6>
        );
      default:
        return (
          <H4 textColor={darkMode ? theme.palette.white.main : headingTextColor ?? theme.palette.blue.dark}>
            {sanitizedHeading}
          </H4>
        );
    }
  }, [headingSize, darkMode, headingTextColor, sanitizedHeading]);

  const lineColor = useMemo((): string => {
    if (darkMode) {
      return theme.palette.yellow.main;
    }

    switch (headingUnderlineColor) {
      case "blueLine":
        return theme.palette.blue.main;
      case "yellowLine":
        return theme.palette.yellow.main;
      default:
        return theme.palette.blue.main;
    }
  }, [darkMode, headingUnderlineColor]);

  return (
    <Container className="text-block-variant-one" columns={maxColumns}>
      <HeadingContainer showUnderline={showUnderline ?? true}>
        {Icon && !darkMode && <Icon size={48} />}
        {Heading}
      </HeadingContainer>
      {showUnderline && <BlockLine color={lineColor} />}
      {introText && introText.length && (
        <IntroParagraphContainer>
          {["md", "lg", "xl"].includes(activeBreakPoint) && (
            <PLargeBold textColor={darkMode ? theme.palette.white.main : theme.palette.blue.dark}>
              {introText}
            </PLargeBold>
          )}
          {["s", "sm", "xs"].includes(activeBreakPoint) && (
            <PMediumBold textColor={darkMode ? theme.palette.white.main : theme.palette.blue.dark}>
              {introText}
            </PMediumBold>
          )}
        </IntroParagraphContainer>
      )}
      <MainParagraphContainer textColor={darkMode ? theme.palette.white.main : theme.palette.blue.dark}>
        <Paragraph paragraph={paragraph} />
      </MainParagraphContainer>
      {buttonLabel && (
        <Button as="href" buttonColor={buttonColor} href={buttonLink} icon={LongArrowSideIcons} label={buttonLabel} />
      )}
    </Container>
  );
};
