import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const InfoSmallIcons: BykoReactIconComponentType = ({ iconColor, size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 16 16" width={size} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_878_5931)">
        <path d="M12.5 1.5H14V15.5H2V1.5H3.5" stroke={iconColor} strokeLinecap="square" strokeWidth="1.5" />
        <path d="M10 0.5H6V2.5H10V0.5Z" stroke={iconColor} strokeLinecap="square" strokeWidth="1.5" />
        <path d="M5 6H11" stroke={iconColor} strokeLinecap="square" strokeWidth="1.5" />
        <path d="M5 9H11" stroke={iconColor} strokeLinecap="square" strokeWidth="1.5" />
        <path d="M5 12H11" stroke={iconColor} strokeLinecap="square" strokeWidth="1.5" />
      </g>
      <defs>
        <clipPath id="clip0_878_5931">
          <rect fill="white" height="16" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
};
