import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const DrillLineDecorative: BykoReactDecorativeIconComponentType = ({ size, color = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.0001 28L18.3291 24.697L18.8421 25.331"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path d="M42 11H46" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
      <path
        // eslint-disable-next-line max-len
        d="M29 20H6C4.93913 20 3.92172 19.5786 3.17157 18.8284C2.42143 18.0783 2 17.0609 2 16V6C2 4.93913 2.42143 3.92172 3.17157 3.17157C3.92172 2.42143 4.93913 2 6 2H29L36 5V17L29 20Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path
        // eslint-disable-next-line max-len
        d="M25.658 35.443L20.858 34.643C19.8279 34.4707 18.906 33.9024 18.2894 33.0595C17.6728 32.2166 17.4103 31.1659 17.558 30.132L19 20H9L7 34L4.514 34.994C3.77181 35.291 3.13563 35.8035 2.68752 36.4655C2.23942 37.1275 1.99995 37.9086 2 38.708V46H29V39.389C29.0001 38.4421 28.6643 37.5259 28.0524 36.8034C27.4404 36.0808 26.592 35.5988 25.658 35.443V35.443Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path d="M29 2V20" stroke={color} strokeMiterlimit="10" strokeWidth="2.5" />
      <path d="M42 8H36V14H42V8Z" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
      <path d="M8 14H12" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
      <path d="M8 8H12" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
      <path d="M2 41H29" stroke={color} strokeMiterlimit="10" strokeWidth="2.5" />
    </svg>
  );
};
