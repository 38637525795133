import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Button } from "@byko/component-buttons";
import { drawerState } from "@byko/component-drawer";
import { H6, PLarge } from "@byko/component-typography";
import { useAuth } from "@byko/hooks-auth-next";
import { useCustomerAccountMembership } from "@byko/hooks-customer-account-membership";
import { CancelLargeIcons, CheckmarkIcons, LongArrowLeftIcons, LongArrowSideIcons } from "@byko/lib-icons";
import { useSetRecoilState } from "@byko/lib-recoil";
import { theme } from "@byko/lib-styles";
import { useToasts } from "@byko/hooks-toasts";

import { ButtonWrapper, InvitationContainer, TextWrapper } from "./styles";

import type { InvitationProps } from "./interface";

export const HandleInvitation = ({ userName }: InvitationProps): JSX.Element | null => {
  const setOpen = useSetRecoilState<boolean>(drawerState);
  const { setSuccessMessage } = useToasts();
  const { user, activeMembership, loading, setActiveMembership } = useAuth();
  const { acceptInvite, fetchInviteDetails, inviteDetails, inviteDetailsLoading } = useCustomerAccountMembership();
  const [declineInviteScreen, setDeclineInviteScreen] = useState<boolean>(false);

  const handleClick = useCallback(() => {
    setDeclineInviteScreen(!declineInviteScreen);
  }, [declineInviteScreen]);

  useEffect(() => {
    if (user && !loading && activeMembership && !inviteDetails) {
      const token = sessionStorage.getItem("invitationToken");
      if (token) {
        void fetchInviteDetails(token ?? "");
      }
    }
  }, [activeMembership, fetchInviteDetails, inviteDetails, loading, user]);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleAcceptInvitation = useCallback(async () => {
    const token = sessionStorage.getItem("invitationToken");

    await acceptInvite(token ?? "").then((res) => {
      sessionStorage.removeItem("invitationToken");

      const membership = res?.user?.memberships?.find((m) => m.account.ssn === inviteDetails?.account.ssn);

      setActiveMembership(membership || null);
      setSuccessMessage(`Boði frá ${membership?.account.name} hefur verið samþykkt`);

      if (window.location.pathname === "/mitt") {
        window.location.reload();
      } else {
        window.location.replace("/mitt");
      }
    });
  }, [inviteDetails, acceptInvite, setSuccessMessage, setActiveMembership]);

  const handleCancelInvitation = useCallback(() => {
    sessionStorage.removeItem("invitationToken");
    window.location.reload();
  }, []);

  const revertDecline = useCallback(() => {
    setDeclineInviteScreen(false);
  }, []);

  const message = useMemo(() => {
    if (declineInviteScreen) {
      // eslint-disable-next-line max-len
      return `Þú átt boð í fyrirtækjareikning hjá: ${inviteDetails?.account.name}. Vinsamlegast staðfestu að þú viljir hafna boðinu.`;
    }
    // eslint-disable-next-line max-len
    return `Þú átt boð í fyrirtækjareikning hjá: ${inviteDetails?.account.name}. Vinsamlegast samþykktu/hafnaðu þessu boði til þess að halda áfram.`;
  }, [declineInviteScreen, inviteDetails?.account.name]);

  if (inviteDetailsLoading && user === null) {
    return null;
  }

  if (!inviteDetailsLoading && inviteDetails === null) {
    return (
      <InvitationContainer>
        <PLarge textColor={theme.palette.blue.dark}>Það er nú þegar búið að samþykkja þetta boð</PLarge>
        <Button buttonColor="blueButton" icon={LongArrowSideIcons} label="Loka" stretch={true} onClick={handleClose} />
      </InvitationContainer>
    );
  }

  return (
    <InvitationContainer>
      <TextWrapper>
        <H6 bottom={8} textColor={theme.palette.blue.main}>
          {declineInviteScreen ? "Ertu viss um að þú viljir hafna boðinu?" : ` Hæ, ${userName}`}
        </H6>
        <PLarge textColor={theme.palette.blue.dark}>{message}</PLarge>
      </TextWrapper>
      <ButtonWrapper>
        {declineInviteScreen ? (
          <>
            <Button
              buttonColor="redButton"
              customClassName="agree-to-decline-button"
              icon={CancelLargeIcons}
              label="Já, hafna boði"
              stretch={true}
              onClick={handleCancelInvitation}
            />
            <Button
              buttonColor="blueButton"
              customClassName="agree-to-decline-button"
              icon={LongArrowLeftIcons}
              label="Nei, fara til baka"
              stretch={true}
              onClick={revertDecline}
            />
          </>
        ) : (
          <>
            <Button
              buttonColor="redButton"
              icon={CancelLargeIcons}
              label="Hafna"
              stretch={true}
              onClick={handleClick}
            />
            <Button
              buttonColor="blueButton"
              icon={CheckmarkIcons}
              label="Samþykkja"
              stretch={true}
              onClick={handleAcceptInvitation}
            />
          </>
        )}
      </ButtonWrapper>
    </InvitationContainer>
  );
};
