/* eslint-disable max-len */
import * as React from "react";
import { BykoReactIconComponentType } from "@byko/lib-core";

export const RadgjofIllustration: BykoReactIconComponentType = ({ size = 200 }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 663 611" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M538.68 530.73C538.68 530.73 527.09 466.73 464.85 467.43C411.28 468 376.31 508.5 312 505.71C262.33 503.55 256.25 530.71 256.25 530.71L538.68 530.73Z"
        fill="#D9EEE8"
      />
      <path
        d="M419.95 467.77C424.36 471.65 441.53 464.65 443.12 454.06C435.95 450.66 419.39 457.97 419.95 467.77Z"
        fill="#D9EEE8"
      />
      <path
        d="M456.33 464.28C465.69 465.36 477.91 461.16 479.5 450.57C472.33 447.2 458.63 453.95 456.33 464.28Z"
        fill="#D9EEE8"
      />
      <path
        d="M550.1 527.72C551 518.34 546.55 506.21 535.93 504.83C532.67 512.06 539.69 525.63 550.1 527.72Z"
        fill="#D9EEE8"
      />
      <path
        d="M450.37 451.28C457.64 450.37 466.1 444.95 465.37 436.67C459.31 435.4 450.21 443.01 450.37 451.28Z"
        fill="#D9EEE8"
      />
      <path
        d="M392.9 531.13L391.55 530.34C422.36 477.5 482.55 447.57 483.11 447.27L483.8 448.68C483.18 448.98 423.45 478.71 392.9 531.13Z"
        fill="white"
      />
      <path
        d="M464.49 545.36L463.49 544.14C463.88 543.83 502.31 512.49 516.67 482.98L518.08 483.67C503.58 513.49 464.9 545.04 464.49 545.36Z"
        fill="white"
      />
      <path
        d="M433.7 536.38L432.53 535.33C432.91 534.91 470.53 493.33 506.27 475.78L506.96 477.19C471.53 494.58 434.07 535.96 433.7 536.38Z"
        fill="white"
      />
      <path d="M580.84 295.05H445.54V371.24H580.84V295.05Z" fill="#408DBC" />
      <path d="M457.216 250.895L452.547 253.782L476.36 292.295L481.03 289.407L457.216 250.895Z" fill="#6EA8CC" />
      <path d="M480.627 250.893L475.958 253.781L499.771 292.293L504.441 289.406L480.627 250.893Z" fill="#6EA8CC" />
      <path d="M504.047 250.897L499.377 253.784L523.191 292.296L527.86 289.409L504.047 250.897Z" fill="#6EA8CC" />
      <path d="M527.466 250.89L522.797 253.777L546.61 292.289L551.28 289.402L527.466 250.89Z" fill="#6EA8CC" />
      <path d="M550.877 250.898L546.208 253.785L570.021 292.297L574.691 289.41L550.877 250.898Z" fill="#6EA8CC" />
      <path d="M569.997 250.826L546.183 289.339L550.853 292.226L574.666 253.714L569.997 250.826Z" fill="#6EA8CC" />
      <path d="M546.58 250.826L522.766 289.338L527.436 292.225L551.249 253.713L546.58 250.826Z" fill="#6EA8CC" />
      <path d="M523.168 250.827L499.354 289.339L504.024 292.226L527.837 253.714L523.168 250.827Z" fill="#6EA8CC" />
      <path d="M501.697 252.197L477.699 290.685L482.365 293.595L506.364 255.107L501.697 252.197Z" fill="#6EA8CC" />
      <path d="M478.19 252.186L454.192 290.674L458.858 293.583L482.857 255.095L478.19 252.186Z" fill="#6EA8CC" />
      <path d="M452.66 235.34H437.96V371.86H452.66V235.34Z" fill="#6EA8CC" />
      <path d="M586.03 235.34H571.33V371.86H586.03V235.34Z" fill="#6EA8CC" />
      <path d="M580.84 245.11H445.54V257.37H580.84V245.11Z" fill="#6EA8CC" />
      <path d="M580.84 282.98H445.54V295.24H580.84V282.98Z" fill="#6EA8CC" />
      <path d="M590.56 370.45H434.48V372.02H590.56V370.45Z" fill="#003C71" />
      <path d="M571.33 295.1H452.66V296.67H571.33V295.1Z" fill="#003C71" />
      <path d="M466.79 296.68H465.22V368.11H466.79V296.68Z" fill="#003C71" />
      <path d="M479.98 296.68H478.41V368.11H479.98V296.68Z" fill="#003C71" />
      <path d="M493.18 296.68H491.61V368.11H493.18V296.68Z" fill="#003C71" />
      <path d="M506.38 296.68H504.81V368.11H506.38V296.68Z" fill="#003C71" />
      <path d="M519.57 296.68H518V368.11H519.57V296.68Z" fill="#003C71" />
      <path d="M532.77 296.68H531.2V368.11H532.77V296.68Z" fill="#003C71" />
      <path d="M545.97 296.68H544.4V368.11H545.97V296.68Z" fill="#003C71" />
      <path d="M559.17 296.68H557.6V368.11H559.17V296.68Z" fill="#003C71" />
      <path
        d="M454.9 380.59C454.9 380.59 460.28 357.41 494.05 361.32C527.82 365.23 552.42 355.06 572.37 349.02C592.32 342.98 603.52 351.56 608.37 357.68L589.79 380.59H454.9Z"
        fill="#85C7B6"
      />
      <path
        d="M455.64 380.76L454.11 380.41C454.17 380.17 460.05 356.59 494.11 360.54C523.18 363.91 545.7 356.69 563.79 350.89C566.69 349.96 569.43 349.08 572.11 348.27C580.99 345.58 589.04 345.48 596.03 347.97L595.5 349.44C588.83 347.07 581.12 347.18 572.57 349.77C569.89 350.58 567.16 351.46 564.27 352.38C546.03 358.23 523.33 365.51 493.93 362.1C461.24 358.28 455.69 380.54 455.64 380.76Z"
        fill="#003C71"
      />
      <path
        d="M454.45 394.13C438.624 384.121 425.594 370.262 416.578 353.85C407.563 337.437 402.857 319.006 402.9 300.28C402.9 298.4 402.95 296.51 403.04 294.64L404.61 294.72C404.52 296.56 404.47 298.43 404.47 300.28C404.429 318.736 409.068 336.902 417.954 353.078C426.84 369.255 439.682 382.914 455.28 392.78L454.45 394.13Z"
        fill="#003C71"
      />
      <path
        d="M537.19 409.04L536.87 407.51C555.521 403.538 572.813 394.767 587.036 382.066C601.26 369.364 611.923 353.171 617.972 335.086C624.02 317.002 625.246 297.652 621.526 278.949C617.807 260.246 609.271 242.837 596.764 228.443C584.256 214.048 568.209 203.167 550.208 196.874C532.207 190.581 512.875 189.094 494.123 192.559C475.372 196.025 457.849 204.324 443.287 216.636C428.724 228.947 417.627 244.846 411.09 262.76L409.62 262.23C416.249 244.061 427.505 227.936 442.275 215.449C457.044 202.962 474.817 194.545 493.835 191.029C512.854 187.514 532.461 189.023 550.718 195.405C568.975 201.788 585.251 212.824 597.937 227.424C610.623 242.023 619.28 259.679 623.052 278.649C626.824 297.618 625.582 317.244 619.447 335.586C613.312 353.928 602.497 370.351 588.071 383.234C573.645 396.116 556.107 405.011 537.19 409.04Z"
        fill="#003C71"
      />
      <path
        d="M92.6701 530.37C107.67 518.93 98.8401 460.95 66.5001 449.31C51.2801 469.75 61.4301 525.99 92.6701 530.37Z"
        fill="#008B66"
      />
      <path d="M70.0517 457.643L68.5576 458.125L91.9502 530.593L93.4443 530.111L70.0517 457.643Z" fill="#87C9B7" />
      <path
        d="M93.6101 531.16H92.6601C74.7201 531.16 60.5501 523.88 51.6601 510.16C44.9001 499.67 42.9001 488.06 42.7601 483.39V482.25L43.8301 482.62C44.2701 482.77 87.3901 497.97 93.4601 530.23L93.6101 531.16ZM44.4001 484.52C45.3162 493.325 48.2561 501.799 52.9901 509.28C61.5201 522.52 74.5501 529.35 91.7101 529.59C85.7501 501.68 51.0601 487.08 44.4001 484.52Z"
        fill="#87C9B7"
      />
      <path
        d="M180.9 302.8V404.08H130.9V388.08C130.9 388.08 102.68 382.84 102.66 352.74C102.66 326.93 116.49 306.49 151.66 301.74V295.38H180.9V302.8Z"
        fill="#008B66"
      />
      <path
        d="M183.9 270.8V276.45C183.9 276.45 188.45 283.56 188.27 285.38C188.09 287.2 183.9 287.02 183.9 287.02L174.78 291.58V309.07L158.78 297.77C158.78 297.77 155.86 276.27 161.51 270.77C167.16 265.27 183.9 270.8 183.9 270.8Z"
        fill="#F8D37D"
      />
      <path
        d="M184.61 272.2L186.03 263.28C186.03 263.28 164.44 259.18 159.79 271.48C158.145 275.943 157.312 280.664 157.33 285.42L164.98 282.01C164.98 282.01 160.2 280.09 161.43 276.13C162.66 272.17 169.82 274.9 169.82 274.9C169.82 274.9 165.9 289.66 169.82 295.13C173.74 300.6 187.03 300.23 183.94 287.02L176.74 284.88C176.74 284.88 175.08 287.88 173.18 287.88C171.28 287.88 171.26 277.72 173.18 272.57L184.61 272.2Z"
        fill="#003C71"
      />
      <path
        d="M178.39 279.4C178.981 279.4 179.46 278.921 179.46 278.33C179.46 277.739 178.981 277.26 178.39 277.26C177.799 277.26 177.32 277.739 177.32 278.33C177.32 278.921 177.799 279.4 178.39 279.4Z"
        fill="#003C71"
      />
      <path d="M157.746 296.678L156.898 297.999L176.331 310.47L177.179 309.148L157.746 296.678Z" fill="#003C71" />
      <path
        d="M150.79 389.49L136.04 389.17C128.91 388.997 122.015 386.587 116.33 382.28C109.74 377.55 101.89 368.57 101.88 352.69C101.88 335.69 107.63 322.5 119 313.42L120 314.65C109 323.41 103.45 336.21 103.46 352.65C103.46 364.87 108.12 374.4 117.26 380.98C122.707 385.087 129.301 387.387 136.12 387.56L149.24 387.84V364.28H150.81L150.79 389.49Z"
        fill="#003C71"
      />
      <path
        d="M137.01 305.77L136.52 304.28C141.409 302.713 146.43 301.588 151.52 300.92L151.73 302.48C146.734 303.13 141.807 304.231 137.01 305.77Z"
        fill="#003C71"
      />
      <path d="M137.55 335.45H135.98V364.67H137.55V335.45Z" fill="#003C71" />
      <path
        d="M108.13 233.38C140.544 233.38 166.82 207.104 166.82 174.69C166.82 142.276 140.544 116 108.13 116C75.7166 116 49.4402 142.276 49.4402 174.69C49.4402 207.104 75.7166 233.38 108.13 233.38Z"
        fill="#FFD400"
      />
      <path
        d="M108.13 234.17C94.1848 234.192 80.6766 229.305 69.9731 220.366C59.2696 211.427 52.0537 199.006 49.5901 185.28L51.1301 185C52.7291 193.834 56.3588 202.176 61.7329 209.368C67.1069 216.559 74.0784 222.404 82.0975 226.441C90.1165 230.477 98.9638 232.596 107.942 232.628C116.919 232.661 125.782 230.608 133.83 226.63L134.53 228.04C126.326 232.107 117.287 234.206 108.13 234.17Z"
        fill="#003C71"
      />
      <path
        d="M85.7301 182.67H130.25C130.25 183.996 129.723 185.268 128.786 186.205C127.848 187.143 126.576 187.67 125.25 187.67H90.7301C89.4041 187.67 88.1323 187.143 87.1946 186.205C86.2569 185.268 85.7301 183.996 85.7301 182.67Z"
        fill="#003C71"
      />
      <path d="M108.77 169.28H107.2V212.61H108.77V169.28Z" fill="#003C71" />
      <path d="M121.863 169.172L120.309 169.398L126.631 212.73L128.184 212.503L121.863 169.172Z" fill="#003C71" />
      <path d="M94.3997 169.16L88.0708 212.49L89.6243 212.717L95.9532 169.387L94.3997 169.16Z" fill="#003C71" />
      <path d="M86.9101 204.06H82.0602V214.39H86.9101V204.06Z" fill="#003C71" />
      <path d="M134.18 204.06H129.33V214.39H134.18V204.06Z" fill="#003C71" />
      <path d="M130.25 208.47H85.7301V210.04H130.25V208.47Z" fill="#003C71" />
      <path
        d="M156 210L154.74 209.07C159.987 201.962 163.538 193.747 165.121 185.055C166.704 176.363 166.277 167.424 163.873 158.922C161.47 150.421 157.152 142.582 151.252 136.006C145.352 129.43 138.025 124.291 129.833 120.982C121.641 117.674 112.801 116.284 103.988 116.918C95.1762 117.553 86.6261 120.196 78.9925 124.644C71.3589 129.092 64.8443 135.228 59.9474 142.581C55.0504 149.935 51.901 158.312 50.7401 167.07L49.1801 166.86C50.3728 157.864 53.6079 149.261 58.6378 141.708C63.6676 134.155 70.3589 127.853 78.1994 123.285C86.0399 118.717 94.8218 116.003 103.873 115.351C112.924 114.699 122.004 116.127 130.418 119.525C138.832 122.923 146.357 128.202 152.417 134.956C158.477 141.71 162.911 149.762 165.38 158.494C167.849 167.226 168.288 176.407 166.662 185.334C165.036 194.262 161.389 202.699 156 210Z"
        fill="#003C71"
      />
      <path
        d="M316.42 88.48H406.5C406.5 88.48 409.2 63.97 384.79 63.97C366.98 63.97 361.42 77.62 361.42 77.62C361.42 77.62 351.17 63.94 331.67 67.5C315.67 70.42 312.35 80.33 312.35 80.33C312.35 80.33 291.35 76.21 285.15 88.48H316.42Z"
        fill="#B2D1E4"
      />
      <path
        d="M474.18 117.75H400.9C400.9 117.75 398.7 97.8099 418.56 97.8099C433.05 97.8099 437.56 108.91 437.56 108.91C437.56 108.91 445.9 97.7899 461.77 100.68C474.83 103.06 477.49 111.12 477.49 111.12C477.49 111.12 494.57 107.77 499.63 117.75H474.18Z"
        fill="#B2D1E4"
      />
      <path d="M269.2 154.01H267.63V225.98H269.2V154.01Z" fill="#003C71" />
      <path d="M279.06 154.01H277.49V225.98H279.06V154.01Z" fill="#003C71" />
      <path
        d="M276.95 233.73H275.38C275.219 231.993 274.415 230.379 273.125 229.204C271.836 228.029 270.155 227.377 268.41 227.377C266.666 227.377 264.984 228.029 263.695 229.204C262.406 230.379 261.601 231.993 261.44 233.73H259.9C259.9 231.465 260.8 229.293 262.401 227.691C264.003 226.09 266.175 225.19 268.44 225.19C270.705 225.19 272.877 226.09 274.479 227.691C276.08 229.293 276.98 231.465 276.98 233.73H276.95Z"
        fill="#003C71"
      />
      <path d="M235.17 129.98H233.6V225.98H235.17V129.98Z" fill="#003C71" />
      <path
        d="M242.9 233.73H241.33C241.169 231.993 240.365 230.379 239.075 229.204C237.786 228.029 236.105 227.377 234.36 227.377C232.616 227.377 230.934 228.029 229.645 229.204C228.356 230.379 227.551 231.993 227.39 233.73H225.9C225.9 232.608 226.121 231.498 226.55 230.462C226.979 229.426 227.608 228.484 228.401 227.691C229.194 226.898 230.136 226.269 231.172 225.84C232.208 225.411 233.319 225.19 234.44 225.19C235.562 225.19 236.672 225.411 237.708 225.84C238.744 226.269 239.686 226.898 240.479 227.691C241.272 228.484 241.901 229.426 242.33 230.462C242.759 231.498 242.98 232.608 242.98 233.73H242.9Z"
        fill="#003C71"
      />
      <path
        d="M286.66 233.73H285.09C285.18 232.76 285.067 231.783 284.758 230.859C284.448 229.936 283.95 229.087 283.294 228.367C282.638 227.648 281.839 227.073 280.948 226.679C280.057 226.286 279.094 226.083 278.12 226.083C277.146 226.083 276.183 226.286 275.292 226.679C274.402 227.073 273.603 227.648 272.947 228.367C272.291 229.087 271.792 229.936 271.483 230.859C271.173 231.783 271.06 232.76 271.15 233.73H269.59C269.551 232.585 269.743 231.443 270.154 230.373C270.565 229.304 271.188 228.327 271.984 227.503C272.78 226.679 273.735 226.024 274.79 225.576C275.845 225.129 276.979 224.898 278.125 224.898C279.271 224.898 280.406 225.129 281.461 225.576C282.516 226.024 283.47 226.679 284.266 227.503C285.063 228.327 285.685 229.304 286.096 230.373C286.508 231.443 286.699 232.585 286.66 233.73Z"
        fill="#003C71"
      />
      <path
        d="M122.01 397.52H249.12V530.37H196.12C186.388 530.375 176.75 528.463 167.758 524.741C158.765 521.02 150.594 515.563 143.712 508.682C136.829 501.802 131.37 493.632 127.646 484.641C123.923 475.649 122.008 466.012 122.01 456.28V397.52Z"
        fill="#4C95C0"
      />
      <path
        d="M206.02 292.15C202.02 293.47 204.55 297.27 200.17 299.06C195.79 300.85 193.17 301.17 193.51 305.72C193.85 310.27 190.42 310.59 190.26 315.14C190.1 319.69 194 317.84 194.48 321.2C194.96 324.56 194.16 329.76 200.66 332.36C207.16 334.96 211.54 329.52 215.44 330.36C219.34 331.2 227.13 332.36 230.22 327.8C233.31 323.24 230.22 320.39 234.28 317.6C238.34 314.81 231.36 310.75 232 305.39C232.64 300.03 224.7 300.68 222.91 297.39C221.12 294.1 216.57 292.51 214.14 294.46C211.71 296.41 209.75 290.92 206.02 292.15Z"
        fill="#003C71"
      />
      <path
        d="M218.36 299.81C218.36 299.81 218.69 304.75 225.78 305.81C232.87 306.87 226.78 311.01 226.78 311.01C226.78 311.01 230.31 313.56 229.19 317.59C228.07 321.62 219.63 320.44 219.63 320.44V328.12C219.63 328.12 231.75 332.5 240.56 338.12C242.08 339.12 240 342.18 241.25 343.2C209.41 343.2 186.06 341.11 186.06 341.11C186.06 341.11 197.97 331.21 205.99 328.92C207.276 327.363 208.211 325.546 208.728 323.593C209.246 321.641 209.336 319.6 208.99 317.61C207.9 310.61 214.84 314.71 213.99 308.21C213.14 301.71 218.36 299.81 218.36 299.81Z"
        fill="#A37160"
      />
      <path
        d="M238.49 336.84C209.99 343.73 199.49 331.84 199.49 331.84C165.87 346.59 144.49 397.54 144.49 397.54H157.07L188.32 364.69C194.86 364.69 192.68 376.77 192.68 376.77L217.1 397.52H157.1H228.17C228.17 397.52 232.17 389.81 238.51 374.2C253.69 338.28 238.49 336.84 238.49 336.84Z"
        fill="#FF7D20"
      />
      <path
        d="M441.36 309.51C447.784 307.467 453.703 304.09 458.73 299.6C460.14 298.405 461.212 296.861 461.836 295.121C462.461 293.382 462.618 291.509 462.29 289.69C461.69 286.83 460.83 283.69 459.78 282.08L449.58 293.46C449.58 293.46 455.01 285.76 451.04 283.69C451.04 283.69 447.46 289.69 438.52 295.89C436.09 302.91 441.36 309.51 441.36 309.51Z"
        fill="#FDC498"
      />
      <path
        d="M442.05 309.07C442.05 309.07 410.71 333.16 352.9 324.41V302.96C352.9 302.96 403.67 311.04 438.54 295.89L442.05 309.07Z"
        fill="#0067A5"
      />
      <path
        d="M332.95 270.65C332.95 270.65 325.22 275.77 323.76 284.65C323.29 287.8 327.71 286.94 328.61 286.52C328.469 288.483 329.087 290.426 330.335 291.947C331.583 293.469 333.367 294.454 335.32 294.7C340.04 295.32 351 288.18 352.66 283.04C354.32 277.9 346.35 263.12 332.95 270.65Z"
        fill="#FDC498"
      />
      <path
        d="M332.13 281.4C332.721 281.4 333.2 280.921 333.2 280.33C333.2 279.739 332.721 279.26 332.13 279.26C331.539 279.26 331.06 279.739 331.06 280.33C331.06 280.921 331.539 281.4 332.13 281.4Z"
        fill="#003C71"
      />
      <path
        d="M219.16 310.14C219.751 310.14 220.23 309.661 220.23 309.07C220.23 308.479 219.751 308 219.16 308C218.569 308 218.09 308.479 218.09 309.07C218.09 309.661 218.569 310.14 219.16 310.14Z"
        fill="#003C71"
      />
      <path
        d="M339.36 287.69C339.36 287.69 340.28 297.89 337.22 304.03C334.16 310.17 359.4 315.86 359.4 315.86L358.5 302.8C358.5 302.8 356.15 275.18 345.8 270.06C335.45 264.94 339.36 287.69 339.36 287.69Z"
        fill="#FDC498"
      />
      <path
        d="M342.08 272.28C342.08 272.28 343.62 276.48 344.64 278.98C344.64 278.98 348.64 278.77 348.8 282.41C348.723 283.038 348.45 283.626 348.019 284.09C347.588 284.553 347.021 284.868 346.4 284.99C346.4 284.99 349.31 288.84 356.48 290.23C357.407 286.515 357.341 282.622 356.29 278.94C355.601 276.594 354.241 274.5 352.377 272.917C350.513 271.334 348.227 270.331 345.8 270.03C344.746 271.051 343.474 271.821 342.08 272.28Z"
        fill="#003C71"
      />
      <path
        d="M356.29 278.97C356.29 278.97 353.35 264.28 340.74 262.52C325.74 260.42 322.2 274 322.2 274L356.29 278.97Z"
        fill="#003C71"
      />
      <path
        d="M332.33 365.74C332.33 365.74 327.03 388.86 335.42 420.12C343.81 451.38 313.35 458 313.35 516.28H338.51C338.51 516.28 367.78 462.34 367.34 431.71C367.34 431.71 362.79 480.09 385.74 516.28H413.11L369.41 365.75L332.33 365.74Z"
        fill="#003C71"
      />
      <path
        d="M370.54 364.71L370.01 314.61C370.01 314.61 346.01 313.16 337.62 303.48C314.81 314.03 294.62 336.79 299.21 362.1C303.89 387.75 345.13 392.8 357.05 373.1V365.75L370.54 364.71Z"
        fill="#0067A5"
      />
      <path
        d="M334.58 291.73C335.045 291.731 335.505 291.641 335.934 291.464C336.364 291.287 336.754 291.027 337.083 290.7C337.412 290.372 337.672 289.982 337.85 289.553C338.029 289.124 338.12 288.664 338.12 288.2H331.05C331.05 289.136 331.422 290.034 332.084 290.696C332.746 291.358 333.644 291.73 334.58 291.73Z"
        fill="white"
      />
      <path
        d="M213.04 464.45V481.28H258.04C258.04 481.28 257.45 475.1 247.74 472.89C238.03 470.68 232.74 468.89 233.32 458.03C233.9 447.17 213.04 464.45 213.04 464.45Z"
        fill="#003C71"
      />
      <path
        d="M202.99 376.75H237.44L269.05 404.75C272.798 408.075 275.333 412.552 276.258 417.476C277.183 422.4 276.445 427.491 274.16 431.95L252.37 474.44C251.241 473.925 250.082 473.478 248.9 473.1V408.71C248.9 405.742 247.721 402.896 245.623 400.797C243.524 398.699 240.678 397.52 237.71 397.52V469.75H211.52V397.52C206.526 397.52 201.737 395.536 198.205 392.005C194.674 388.474 192.69 383.684 192.69 378.69V376.75H202.99Z"
        fill="#FF7D20"
      />
      <path
        d="M316.9 513.96V530.73H361.9C361.9 530.73 361.31 524.55 351.59 522.34C341.87 520.13 336.59 518.34 337.17 507.48C337.75 496.62 316.9 513.96 316.9 513.96Z"
        fill="#003C71"
      />
      <path
        d="M388.09 513.96V530.73H433.09C433.09 530.73 432.5 524.55 422.79 522.34C413.08 520.13 407.79 518.34 408.37 507.48C408.95 496.62 388.09 513.96 388.09 513.96Z"
        fill="#003C71"
      />
      <path
        d="M349.07 496.59L347.65 495.93C363.07 462.64 367.28 437.53 368.1 422.33C368.98 405.87 366.18 397.16 366.15 397.07L367.64 396.58C367.76 396.93 378.9 432.28 349.07 496.59Z"
        fill="#408DBC"
      />
      <path
        d="M357.05 373.09C363.232 368.292 370.844 365.704 378.67 365.74C389.85 365.74 392.21 357.74 392.21 357.74H357.05V373.09Z"
        fill="#FDC498"
      />
      <path d="M357.04 356.9H319.22V358.47H357.04V356.9Z" fill="#408DBC" />
      <path d="M370.8 315.85H369.23V357.68H370.8V315.85Z" fill="#408DBC" />
      <path
        d="M347.97 310.85C338.43 308.1 335.2 301.44 335.07 301.16L336.49 300.49L335.78 300.83L336.49 300.49C336.49 300.55 339.58 306.8 348.4 309.35L347.97 310.85Z"
        fill="#408DBC"
      />
      <path d="M492.04 529.95H75.7202V531.52H492.04V529.95Z" fill="#003C71" />
      <path
        d="M304.04 179.57H366.31V218.57H285.19V198.47C285.185 195.991 285.669 193.535 286.613 191.243C287.558 188.95 288.946 186.867 290.696 185.112C292.447 183.356 294.527 181.963 296.817 181.012C299.106 180.061 301.561 179.571 304.04 179.57Z"
        fill="#0067A5"
      />
      <path
        d="M288.9 179.57H346.4C349.034 179.57 351.559 180.616 353.422 182.478C355.284 184.341 356.33 186.866 356.33 189.5V204.5H279.01V189.5C279.01 186.873 280.051 184.354 281.904 182.492C283.758 180.631 286.273 179.581 288.9 179.57Z"
        fill="#6EA8CC"
      />
      <path
        d="M269.96 204.54H356.35V218.54H270.84C268.87 218.54 266.98 217.757 265.586 216.364C264.193 214.97 263.41 213.08 263.41 211.11C263.409 210.248 263.577 209.395 263.906 208.599C264.234 207.802 264.716 207.078 265.324 206.468C265.933 205.858 266.655 205.374 267.451 205.043C268.246 204.712 269.099 204.541 269.96 204.54Z"
        fill="#6EA8CC"
      />
      <path d="M356.36 203H276.31V204.57H356.36V203Z" fill="#003C71" />
      <path d="M357.14 154.01H355.57V225.98H357.14V154.01Z" fill="#003C71" />
      <path
        d="M364.9 233.73H363.33C363.169 231.993 362.365 230.379 361.075 229.204C359.786 228.029 358.105 227.377 356.36 227.377C354.616 227.377 352.934 228.029 351.645 229.204C350.356 230.379 349.551 231.993 349.39 233.73H347.82C347.82 231.465 348.72 229.293 350.321 227.691C351.923 226.09 354.095 225.19 356.36 225.19C358.625 225.19 360.797 226.09 362.399 227.691C364 229.293 364.9 231.465 364.9 233.73Z"
        fill="#003C71"
      />
      <path
        d="M374.75 233.73H373.18C373.27 232.76 373.157 231.782 372.848 230.859C372.538 229.936 372.04 229.087 371.384 228.367C370.728 227.648 369.929 227.073 369.038 226.679C368.147 226.286 367.184 226.083 366.21 226.083C365.236 226.083 364.273 226.286 363.382 226.679C362.492 227.073 361.693 227.648 361.037 228.367C360.381 229.087 359.882 229.936 359.573 230.859C359.263 231.782 359.15 232.76 359.24 233.73H357.67C357.67 231.465 358.57 229.293 360.171 227.691C361.773 226.09 363.945 225.19 366.21 225.19C368.475 225.19 370.647 226.09 372.249 227.691C373.85 229.293 374.75 231.465 374.75 233.73Z"
        fill="#003C71"
      />
      <path d="M357.14 146.94H263.41V154H357.14V146.94Z" fill="#6EA8CC" />
      <path d="M366.31 146.94H357.14V154H366.31V146.94Z" fill="#0067A5" />
      <path d="M367 146.94H365.43V225.97H367V146.94Z" fill="#003C71" />
      <path
        d="M270.1 160.7C270.98 160.701 271.851 160.529 272.663 160.194C273.476 159.858 274.215 159.365 274.837 158.744C275.459 158.123 275.953 157.385 276.29 156.572C276.627 155.76 276.8 154.889 276.8 154.01H263.41C263.41 155.784 264.115 157.486 265.37 158.741C266.624 159.995 268.326 160.7 270.1 160.7Z"
        fill="#0067A5"
      />
      <path
        d="M283.49 160.7C284.37 160.701 285.241 160.529 286.053 160.194C286.866 159.858 287.605 159.365 288.227 158.744C288.849 158.123 289.343 157.385 289.68 156.572C290.017 155.76 290.19 154.889 290.19 154.01H276.8C276.8 155.784 277.505 157.486 278.76 158.741C280.014 159.995 281.716 160.7 283.49 160.7Z"
        fill="#6EA8CC"
      />
      <path
        d="M296.9 160.7C297.78 160.701 298.651 160.529 299.463 160.194C300.276 159.858 301.015 159.365 301.637 158.744C302.259 158.123 302.753 157.385 303.09 156.572C303.427 155.76 303.6 154.889 303.6 154.01H290.19C290.19 154.89 290.364 155.762 290.701 156.575C291.039 157.388 291.533 158.126 292.157 158.748C292.78 159.369 293.52 159.861 294.334 160.197C295.148 160.532 296.02 160.703 296.9 160.7Z"
        fill="#0067A5"
      />
      <path
        d="M310.27 160.7C311.15 160.701 312.021 160.529 312.833 160.194C313.646 159.858 314.385 159.365 315.007 158.744C315.629 158.123 316.123 157.385 316.46 156.572C316.797 155.76 316.97 154.889 316.97 154.01H303.58C303.58 155.784 304.285 157.486 305.54 158.741C306.794 159.995 308.496 160.7 310.27 160.7Z"
        fill="#6EA8CC"
      />
      <path
        d="M323.66 160.7C324.54 160.701 325.411 160.529 326.223 160.194C327.036 159.858 327.775 159.365 328.397 158.744C329.019 158.123 329.513 157.385 329.85 156.572C330.187 155.76 330.36 154.889 330.36 154.01H316.97C316.97 155.784 317.675 157.486 318.93 158.741C320.184 159.995 321.886 160.7 323.66 160.7Z"
        fill="#0067A5"
      />
      <path
        d="M337.05 160.7C337.93 160.701 338.801 160.529 339.613 160.194C340.426 159.858 341.165 159.365 341.787 158.744C342.409 158.123 342.903 157.385 343.24 156.572C343.577 155.76 343.75 154.889 343.75 154.01H330.36C330.36 155.784 331.065 157.486 332.32 158.741C333.574 159.995 335.276 160.7 337.05 160.7Z"
        fill="#6EA8CC"
      />
      <path
        d="M350.44 160.7C351.32 160.701 352.191 160.529 353.003 160.194C353.816 159.858 354.555 159.365 355.177 158.744C355.799 158.123 356.293 157.385 356.63 156.572C356.967 155.76 357.14 154.889 357.14 154.01H343.75C343.75 155.784 344.455 157.486 345.71 158.741C346.964 159.995 348.666 160.7 350.44 160.7Z"
        fill="#0067A5"
      />
      <path
        d="M361.14 209.35C360.038 209.35 358.961 209.023 358.045 208.411C357.128 207.798 356.414 206.928 355.993 205.909C355.572 204.891 355.462 203.77 355.678 202.69C355.894 201.609 356.425 200.616 357.205 199.838C357.985 199.059 358.978 198.53 360.06 198.316C361.141 198.102 362.261 198.214 363.279 198.637C364.296 199.06 365.165 199.775 365.776 200.693C366.387 201.61 366.712 202.688 366.71 203.79C366.707 205.265 366.119 206.68 365.075 207.722C364.031 208.764 362.616 209.35 361.14 209.35ZM361.14 199.79C360.349 199.79 359.576 200.025 358.918 200.464C358.26 200.904 357.747 201.528 357.445 202.259C357.142 202.99 357.063 203.794 357.217 204.57C357.371 205.346 357.752 206.059 358.312 206.618C358.871 207.178 359.584 207.559 360.36 207.713C361.136 207.867 361.94 207.788 362.671 207.485C363.402 207.183 364.026 206.67 364.466 206.012C364.906 205.354 365.14 204.581 365.14 203.79C365.14 202.729 364.719 201.712 363.969 200.962C363.218 200.211 362.201 199.79 361.14 199.79Z"
        fill="#003C71"
      />
      <path d="M382.9 233.55H207.92V235.12H382.9V233.55Z" fill="white" />
      <path d="M382.9 243.08H260.66V244.65H382.9V243.08Z" fill="white" />
      <path
        d="M249.82 204.54H218.95C217.017 204.54 215.45 206.105 215.45 208.035C215.45 209.965 217.017 211.53 218.95 211.53H249.82C251.753 211.53 253.32 209.965 253.32 208.035C253.32 206.105 251.753 204.54 249.82 204.54Z"
        fill="#FF7D20"
      />
      <path
        d="M234.39 129.88C227.99 129.88 221.853 132.422 217.328 136.948C212.802 141.473 210.26 147.61 210.26 154.01H258.52C258.52 147.61 255.978 141.473 251.453 136.948C246.927 132.422 240.79 129.88 234.39 129.88Z"
        fill="#FF7D20"
      />
      <path
        d="M242.87 202.22L241.41 201.65L247.99 184.62L254.74 183.36L255.03 184.9L249.13 186L242.87 202.22Z"
        fill="#003C71"
      />
      <path d="M248.7 192.06H240.17V204.55H248.7V192.06Z" fill="white" />
      <path d="M249.49 205.33H239.39V191.27H248.71V192.84H240.96V203.76H247.92V195.83H249.49V205.33Z" fill="#003C71" />
      <path d="M248.71 203.76H221.89V205.33H248.71V203.76Z" fill="#003C71" />
      <path d="M249.74 153.22H210.26V154.79H249.74V153.22Z" fill="#003C71" />
      <path
        d="M384.32 98.83C382.273 98.83 380.272 98.223 378.57 97.0857C376.868 95.9484 375.541 94.332 374.758 92.4408C373.975 90.5496 373.77 88.4685 374.169 86.4608C374.568 84.4531 375.554 82.6089 377.002 81.1615C378.449 79.714 380.293 78.7282 382.301 78.3289C384.309 77.9295 386.39 78.1345 388.281 78.9179C390.172 79.7012 391.789 81.0278 392.926 82.7299C394.063 84.4319 394.67 86.433 394.67 88.48C394.667 91.2242 393.576 93.8552 391.636 95.7956C389.695 97.7361 387.064 98.8274 384.32 98.83ZM384.32 79.17C382.478 79.168 380.677 79.7124 379.145 80.7342C377.613 81.756 376.418 83.2093 375.712 84.9103C375.005 86.6113 374.82 88.4835 375.178 90.2902C375.536 92.0968 376.422 93.7566 377.723 95.0596C379.025 96.3627 380.684 97.2503 382.49 97.6104C384.296 97.9704 386.169 97.7866 387.871 97.0823C389.572 96.3779 391.027 95.1846 392.05 93.6534C393.074 92.1222 393.62 90.3218 393.62 88.48C393.617 86.0134 392.637 83.6484 390.894 81.9033C389.151 80.1582 386.787 79.1753 384.32 79.17Z"
        fill="#FFD400"
      />
      <path d="M76.2489 160.561L75.1921 161.722L82.1361 168.043L83.1929 166.882L76.2489 160.561Z" fill="#003C71" />
      <path
        d="M75.6101 159.45H70.4201C69.6138 159.45 68.9601 160.104 68.9601 160.91C68.9601 161.716 69.6138 162.37 70.4201 162.37H75.6101C76.4165 162.37 77.0701 161.716 77.0701 160.91C77.0701 160.104 76.4165 159.45 75.6101 159.45Z"
        fill="#003C71"
      />
      <path d="M140.012 160.559L133.068 166.88L134.124 168.041L141.069 161.72L140.012 160.559Z" fill="#003C71" />
      <path
        d="M140.65 162.38H145.84C146.646 162.38 147.3 161.726 147.3 160.92C147.3 160.114 146.646 159.46 145.84 159.46H140.65C139.844 159.46 139.19 160.114 139.19 160.92C139.19 161.726 139.844 162.38 140.65 162.38Z"
        fill="#003C71"
      />
      <path d="M102.709 136.165L101.221 136.667L102.883 141.594L104.371 141.092L102.709 136.165Z" fill="#003C71" />
      <path d="M113.548 136.158L111.886 141.086L113.374 141.587L115.035 136.66L113.548 136.158Z" fill="#003C71" />
      <path
        d="M115.64 133.05H100.56C99.4225 133.05 98.5002 133.972 98.5002 135.11C98.5002 136.248 99.4225 137.17 100.56 137.17H115.64C116.778 137.17 117.7 136.248 117.7 135.11C117.7 133.972 116.778 133.05 115.64 133.05Z"
        fill="#003C71"
      />
      <path
        d="M134.43 172.72C135.385 168.724 135.422 164.564 134.539 160.552C133.656 156.54 131.875 152.78 129.33 149.555C126.785 146.33 123.542 143.724 119.845 141.932C116.148 140.14 112.093 139.209 107.985 139.209C103.877 139.209 99.8221 140.14 96.1252 141.932C92.4284 143.724 89.1857 146.33 86.6407 149.555C84.0957 152.78 82.3146 156.54 81.4312 160.552C80.5478 164.564 80.585 168.724 81.5401 172.72H134.43Z"
        fill="#0067A5"
      />
      <path d="M135.18 161.25H85.7301V162.82H135.18V161.25Z" fill="#003C71" />
      <path d="M135.18 158.67H85.7301V160.24H135.18V158.67Z" fill="#003C71" />
      <path
        d="M118.43 150.52C120.264 150.52 121.75 149.034 121.75 147.2C121.75 145.366 120.264 143.88 118.43 143.88C116.597 143.88 115.11 145.366 115.11 147.2C115.11 149.034 116.597 150.52 118.43 150.52Z"
        fill="#5298C2"
      />
      <path
        d="M98.8201 168.14C99.3614 168.14 99.8001 167.701 99.8001 167.16C99.8001 166.619 99.3614 166.18 98.8201 166.18C98.2789 166.18 97.8401 166.619 97.8401 167.16C97.8401 167.701 98.2789 168.14 98.8201 168.14Z"
        fill="#5298C2"
      />
      <path
        d="M103.47 168.14C104.011 168.14 104.45 167.701 104.45 167.16C104.45 166.619 104.011 166.18 103.47 166.18C102.929 166.18 102.49 166.619 102.49 167.16C102.49 167.701 102.929 168.14 103.47 168.14Z"
        fill="#5298C2"
      />
      <path
        d="M108.13 168.14C108.671 168.14 109.11 167.701 109.11 167.16C109.11 166.619 108.671 166.18 108.13 166.18C107.589 166.18 107.15 166.619 107.15 167.16C107.15 167.701 107.589 168.14 108.13 168.14Z"
        fill="#5298C2"
      />
      <path
        d="M112.79 168.14C113.331 168.14 113.77 167.701 113.77 167.16C113.77 166.619 113.331 166.18 112.79 166.18C112.249 166.18 111.81 166.619 111.81 167.16C111.81 167.701 112.249 168.14 112.79 168.14Z"
        fill="#5298C2"
      />
      <path
        d="M117.45 168.14C117.991 168.14 118.43 167.701 118.43 167.16C118.43 166.619 117.991 166.18 117.45 166.18C116.909 166.18 116.47 166.619 116.47 167.16C116.47 167.701 116.909 168.14 117.45 168.14Z"
        fill="#5298C2"
      />
      <path
        d="M128.9 397.52C128.9 397.52 134.48 390.62 145.9 390.62H150.29C152.12 390.62 153.875 391.347 155.169 392.641C156.463 393.935 157.19 395.69 157.19 397.52H128.9Z"
        fill="#A37160"
      />
      <path d="M198.27 396.74H113.81V398.31H198.27V396.74Z" fill="#003C71" />
      <path d="M194.287 356.388L167.305 385.659L168.46 386.723L195.442 357.452L194.287 356.388Z" fill="#003C71" />
      <path
        d="M250.39 389.28L234.9 375.6C233.205 374.105 232.134 372.028 231.9 369.78C231.71 367.23 232.72 364.67 234.9 362.17C235.778 361.279 236.457 360.213 236.893 359.041C237.329 357.869 237.512 356.618 237.43 355.37C236.99 350.89 232.77 347.6 232.73 347.57L233.68 346.33C233.87 346.48 238.49 350.06 238.99 355.22C239.102 356.685 238.899 358.156 238.396 359.536C237.892 360.916 237.099 362.172 236.07 363.22C234.17 365.38 233.28 367.54 233.44 369.64C233.632 371.474 234.489 373.175 235.85 374.42L251.36 388.11L250.39 389.28Z"
        fill="#003C71"
      />
      <path
        d="M238.2 469.75H236.63V397.03C236.625 394.077 235.449 391.247 233.361 389.159C231.273 387.071 228.443 385.895 225.49 385.89H211.73V384.28H225.49C228.86 384.283 232.092 385.623 234.475 388.006C236.858 390.389 238.198 393.62 238.2 396.99V469.75Z"
        fill="#003C71"
      />
      <path
        d="M134.94 506.26C151.387 506.26 164.72 492.927 164.72 476.48C164.72 460.033 151.387 446.7 134.94 446.7C118.493 446.7 105.16 460.033 105.16 476.48C105.16 492.927 118.493 506.26 134.94 506.26Z"
        fill="#408DBC"
      />
      <path
        d="M170.21 478.77C182.626 478.77 192.69 468.705 192.69 456.29C192.69 443.875 182.626 433.81 170.21 433.81C157.795 433.81 147.73 443.875 147.73 456.29C147.73 468.705 157.795 478.77 170.21 478.77Z"
        fill="#70AACD"
      />
      <path
        d="M168.45 470.87V467.43H171.97V470.87H168.45ZM171.45 462.67H168.9L168.49 441.67H171.9L171.45 462.67Z"
        fill="#003C71"
      />
      <path
        d="M141.09 474.06L137.55 479.17C137.071 479.922 136.802 480.789 136.77 481.68V483.36H133.65V481.63C133.615 480.163 134.088 478.728 134.99 477.57L138.5 472.5C139.364 471.465 139.867 470.177 139.93 468.83C139.958 468.161 139.848 467.493 139.608 466.868C139.368 466.243 139.002 465.674 138.534 465.195C138.065 464.717 137.504 464.339 136.884 464.085C136.265 463.831 135.6 463.707 134.93 463.72C134.261 463.72 133.598 463.854 132.981 464.114C132.365 464.375 131.807 464.757 131.34 465.237C130.874 465.717 130.509 466.286 130.267 466.91C130.025 467.535 129.91 468.201 129.93 468.87H126.81C126.807 467.81 127.017 466.761 127.428 465.784C127.838 464.807 128.442 463.923 129.201 463.184C129.96 462.445 130.861 461.867 131.849 461.483C132.836 461.099 133.891 460.918 134.95 460.95C136.006 460.906 137.061 461.079 138.048 461.457C139.035 461.834 139.935 462.41 140.693 463.147C141.45 463.885 142.049 464.769 142.452 465.747C142.856 466.724 143.056 467.773 143.04 468.83C142.967 470.737 142.284 472.57 141.09 474.06ZM133.35 492.06V488.38H137.07V492.02L133.35 492.06Z"
        fill="#70AACD"
      />
      <path
        d="M415.26 196.26C421.959 196.26 427.39 190.829 427.39 184.13C427.39 177.431 421.959 172 415.26 172C408.561 172 403.13 177.431 403.13 184.13C403.13 190.829 408.561 196.26 415.26 196.26Z"
        fill="#FFD400"
      />
      <path
        d="M411.34 183.08C410.66 183.08 409.995 182.878 409.429 182.5C408.863 182.122 408.422 181.585 408.162 180.956C407.902 180.328 407.834 179.636 407.966 178.969C408.099 178.302 408.427 177.689 408.908 177.208C409.389 176.726 410.002 176.399 410.669 176.266C411.336 176.133 412.028 176.201 412.657 176.462C413.285 176.722 413.822 177.163 414.2 177.729C414.578 178.294 414.78 178.96 414.78 179.64C414.78 180.552 414.418 181.427 413.773 182.072C413.127 182.718 412.253 183.08 411.34 183.08ZM411.34 177.77C410.971 177.772 410.61 177.883 410.304 178.09C409.998 178.297 409.76 178.589 409.62 178.931C409.48 179.273 409.444 179.649 409.517 180.011C409.591 180.373 409.77 180.705 410.031 180.966C410.293 181.226 410.627 181.403 410.989 181.475C411.351 181.546 411.727 181.508 412.068 181.367C412.409 181.225 412.701 180.985 412.906 180.678C413.111 180.371 413.22 180.009 413.22 179.64C413.22 179.394 413.171 179.15 413.077 178.922C412.982 178.694 412.844 178.488 412.669 178.314C412.494 178.14 412.287 178.003 412.059 177.909C411.831 177.816 411.587 177.769 411.34 177.77Z"
        fill="#003C71"
      />
      <path
        d="M419.21 192.21C418.53 192.21 417.865 192.008 417.299 191.63C416.733 191.252 416.292 190.715 416.032 190.086C415.772 189.458 415.704 188.766 415.836 188.099C415.969 187.432 416.297 186.819 416.778 186.338C417.259 185.856 417.872 185.529 418.539 185.396C419.206 185.263 419.898 185.331 420.527 185.592C421.155 185.852 421.692 186.293 422.07 186.859C422.448 187.424 422.65 188.09 422.65 188.77C422.65 189.682 422.288 190.557 421.643 191.202C420.997 191.848 420.122 192.21 419.21 192.21ZM419.21 186.9C418.84 186.9 418.479 187.01 418.171 187.215C417.864 187.421 417.624 187.713 417.482 188.054C417.341 188.396 417.304 188.772 417.376 189.135C417.448 189.498 417.626 189.831 417.888 190.092C418.149 190.354 418.483 190.532 418.845 190.604C419.208 190.676 419.584 190.639 419.926 190.498C420.267 190.356 420.56 190.116 420.765 189.809C420.97 189.501 421.08 189.14 421.08 188.77C421.078 188.275 420.88 187.801 420.53 187.451C420.179 187.1 419.705 186.903 419.21 186.9Z"
        fill="#003C71"
      />
      <path d="M419.627 179.085L409.275 189.437L410.385 190.547L420.737 180.195L419.627 179.085Z" fill="#003C71" />
      <path
        d="M415.26 197.04C412.704 197.04 410.206 196.282 408.081 194.862C405.956 193.442 404.3 191.423 403.323 189.062C402.345 186.701 402.09 184.102 402.589 181.596C403.088 179.089 404.32 176.787 406.128 174.981C407.936 173.174 410.239 171.944 412.746 171.447C415.253 170.95 417.851 171.207 420.211 172.186C422.572 173.166 424.589 174.823 426.008 176.949C427.426 179.075 428.182 181.574 428.18 184.13C428.175 187.554 426.812 190.836 424.39 193.256C421.967 195.677 418.684 197.037 415.26 197.04ZM415.26 172.78C413.015 172.78 410.821 173.446 408.954 174.693C407.088 175.94 405.633 177.713 404.774 179.787C403.915 181.86 403.69 184.143 404.128 186.344C404.566 188.546 405.647 190.568 407.235 192.156C408.822 193.743 410.844 194.824 413.046 195.262C415.248 195.7 417.53 195.475 419.604 194.616C421.678 193.757 423.45 192.302 424.697 190.436C425.944 188.569 426.61 186.375 426.61 184.13C426.608 181.121 425.411 178.235 423.283 176.107C421.155 173.979 418.27 172.783 415.26 172.78Z"
        fill="#003C71"
      />
      <path
        d="M443.07 224.75C447.831 224.75 451.69 220.891 451.69 216.13C451.69 211.369 447.831 207.51 443.07 207.51C438.309 207.51 434.45 211.369 434.45 216.13C434.45 220.891 438.309 224.75 443.07 224.75Z"
        fill="#E5E5E5"
      />
      <path
        d="M443.07 225.53C441.211 225.53 439.394 224.979 437.848 223.946C436.302 222.913 435.097 221.445 434.386 219.727C433.674 218.01 433.488 216.12 433.851 214.296C434.213 212.473 435.109 210.798 436.423 209.483C437.738 208.169 439.413 207.273 441.236 206.911C443.06 206.548 444.95 206.734 446.667 207.446C448.385 208.157 449.853 209.362 450.886 210.908C451.919 212.453 452.47 214.271 452.47 216.13C452.47 218.623 451.48 221.014 449.717 222.777C447.954 224.54 445.563 225.53 443.07 225.53ZM443.07 208.3C441.522 208.3 440.008 208.759 438.72 209.62C437.432 210.48 436.429 211.703 435.836 213.134C435.244 214.564 435.088 216.139 435.391 217.658C435.693 219.176 436.438 220.572 437.533 221.667C438.629 222.762 440.024 223.507 441.543 223.81C443.061 224.112 444.636 223.957 446.067 223.364C447.497 222.771 448.72 221.768 449.581 220.48C450.441 219.192 450.9 217.679 450.9 216.13C450.901 215.1 450.7 214.081 450.307 213.129C449.914 212.177 449.338 211.312 448.611 210.583C447.884 209.854 447.02 209.276 446.07 208.881C445.119 208.485 444.1 208.281 443.07 208.28V208.3Z"
        fill="#003C71"
      />
      <path d="M603.07 379.8H518.79V381.37H603.07V379.8Z" fill="#003C71" />
      <path
        d="M443.42 216.79L442.34 215.66C446.87 211.364 450.262 206.011 452.212 200.08C454.163 194.15 454.611 187.828 453.515 181.682C452.42 175.536 449.816 169.758 445.936 164.867C442.057 159.975 437.024 156.123 431.29 153.657C425.555 151.19 419.297 150.186 413.078 150.734C406.859 151.283 400.874 153.366 395.659 156.798C390.444 160.23 386.163 164.903 383.2 170.398C380.236 175.893 378.683 182.037 378.68 188.28V204.61H365.9V203.04H377.09V188.28C377.088 181.776 378.702 175.373 381.785 169.646C384.869 163.92 389.326 159.048 394.757 155.47C400.188 151.891 406.423 149.717 412.902 149.143C419.38 148.568 425.9 149.612 431.876 152.179C437.852 154.747 443.097 158.758 447.14 163.853C451.183 168.948 453.897 174.967 455.04 181.37C456.182 187.773 455.716 194.359 453.685 200.538C451.653 206.717 448.119 212.294 443.4 216.77L443.42 216.79Z"
        fill="#003C71"
      />
      <path d="M207.44 203.76H158.05V205.33H207.44V203.76Z" fill="#003C71" />
    </svg>
  );
};
