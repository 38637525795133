import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { CancelLargeIcons } from "@byko/lib-icons";
import { IconButton } from "@byko/component-buttons";
import styled from "styled-components";

export const ModalStyle = styled.div`
  position: fixed;
  z-index: 4000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  background-color: #fff;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

interface ModalProps {
  open: boolean;
  children: React.ReactNode;
  onClose: () => void;
}

export default function Modal({ open, children, onClose }: ModalProps) {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    if (open) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [open, onClose]);

  if (!open) return null;

  return ReactDOM.createPortal(
    <>
      <ModalStyle>
        <ButtonContainer>
          <IconButton ariaLabel="Close Modal" icon={CancelLargeIcons} onClick={onClose}></IconButton>
        </ButtonContainer>
        {children}
      </ModalStyle>
    </>,
    document.getElementById("portal") as HTMLElement,
  );
}
