import * as React from "react";

import { IconButton, SecondaryButton } from "@byko/component-buttons";
import { PageContainer } from "@byko/component-page-container";
import { AdviceIcons, DrillIcons, NotandiIcons } from "@byko/lib-icons";
import { theme } from "@byko/lib-styles";
import { useWindowSize } from "@byko/lib-styles";

import { BottomFooter, ButtonWrapper, CompanyDetail, Divider, InfoParagraph, InfoText, Location } from "./styles";

export const FooterBottom = (): JSX.Element => {
  const { activeBreakPoint } = useWindowSize();

  return (
    <PageContainer backgroundColor={theme.palette.white.main} flexDirection="row" offsetNav={false}>
      <BottomFooter>
        <ButtonWrapper>
          {["s", "sm"].includes(activeBreakPoint) ? (
            <>
              <IconButton ariaLabel="Mitt Byko" icon={NotandiIcons} />
              <IconButton ariaLabel="Leiga" icon={DrillIcons} />
              <IconButton ariaLabel="Ráðgjöf" icon={AdviceIcons} />
            </>
          ) : (
            <>
              <a href="https://mitt.byko.is/">
                <SecondaryButton blueLine={true} icon={NotandiIcons} label="Mitt BYKO" />
              </a>
              <a href="https://byko.is/thjonusta/leiga">
                <SecondaryButton blueLine={true} icon={DrillIcons} label="Leiga" />
              </a>
              <a href="https://byko.is/thjonusta/radgjof-og-thjonusta">
                <SecondaryButton blueLine={true} icon={AdviceIcons} label="Ráðgjöf" />
              </a>
            </>
          )}
        </ButtonWrapper>
        <InfoText>
          <Location href={`http://maps.google.com/?q=BYKO skemmuvegi 2a`} rel="noreferrer" target="_blank">
            <InfoParagraph> Skemmuvegi 2a </InfoParagraph>
            <Divider />
            <InfoParagraph> 200 Kópavogur </InfoParagraph>
            <Divider />
          </Location>
          <CompanyDetail>
            <InfoParagraph>Kt. 460169-3219 </InfoParagraph>
            <Divider />
            <InfoParagraph>Vsk.nr. 03394</InfoParagraph>
            <Divider />
          </CompanyDetail>
        </InfoText>
      </BottomFooter>
    </PageContainer>
  );
};
