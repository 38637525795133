import * as React from "react";

import { theme } from "@byko/lib-styles";

import {
  ErrorText,
  FocusLine,
  InputContainer,
  InputLabel,
  InputOuterWrapper,
  InputWrapper,
  Placeholder,
  PrefixIcon,
} from "./input-sleave-styles";

import type { InputSleaveProps } from "./input-sleave-interface";

export const InputSleave = ({
  icon: Icon,
  placeholder,
  children,
  errorText,
  error = false,
  stretch = false,
  errorTextAboveInput = false,
  iconColor = theme.palette.blue.main,
  label,
  customClassName = "input-sleve",
}: InputSleaveProps): JSX.Element => {
  return (
    <InputOuterWrapper className={customClassName} stretch={stretch}>
      {label && <InputLabel htmlFor={label}>{label}</InputLabel>}
      <InputWrapper className="input-wrapper">
        <InputContainer className="input-container" error={error} stretch={stretch}>
          {children}
          <Placeholder error={error}>{placeholder}</Placeholder>
          <FocusLine error={error} />
          {Boolean(Icon) && (
            <PrefixIcon>
              {Icon && <Icon iconColor={error ? theme.palette.triggers.warning.main : iconColor} size={22} />}
            </PrefixIcon>
          )}
        </InputContainer>
      </InputWrapper>
      {Boolean(errorText) && (
        <ErrorText className="input-error-text" errorTextAboveInput={errorTextAboveInput}>
          {errorText || "Villa"}
        </ErrorText>
      )}
    </InputOuterWrapper>
  );
};
