/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from "styled-components";

import { theme } from "./theme";

import type { FlattenInterpolation, ThemeProps } from "styled-components";

interface Props {
  color?: "dark-blue" | "black" | "light-blue";
}

export const scrollStyle = ({ color = "dark-blue" }: Props): FlattenInterpolation<ThemeProps<any>> => {
  return css`
    overflow-y: auto;

    /* width */
    ::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      border: none;
      background: ${theme.palette.white.main};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${color === "dark-blue"
        ? theme.palette.blue.dark
        : color === "light-blue"
        ? theme.palette.blue.lightest
        : theme.palette.black.main};
    }

    &:focus {
      outline: none;
    }
  `;
};
