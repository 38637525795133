import styled from "styled-components";

import { Subtitle } from "@byko/component-typography";
import { gridTheme, theme } from "@byko/lib-styles";

import type { IconStepProps } from "./interface";

export const StepContainer = styled.div`
  display: flex;
  width: 100%;
  max-height: 200px;
  align-items: center;
  gap: 20px;
  button {
    width: 100%;
    max-width: 120px;
  }
  @media screen and (max-width: ${gridTheme.container.maxWidth.sm}px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;

export const StepWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 600px;
  max-height: 160px;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;

  @media screen and (max-width: ${gridTheme.container.maxWidth.sm}px) {
    height: 100px;
    padding-left: 30px;
    margin: unset;
    margin-bottom: 40px;
  }
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    padding-left: 10px;
    margin-bottom: 10px;
  }
`;

export const Step = styled.div<IconStepProps>`
  z-index: 2;
  display: flex;
  width: 100%;
  max-width: ${(props): string => (props.currentstep ? "64px" : "48px")};
  height: 100%;
  max-height: ${(props): string => (props.currentstep ? "64px" : "48px")};
  align-items: center;
  justify-content: center;
  border-radius: ${(props): string => (props.square ? "0" : "50%")};
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: ${(props): string => (props.isfulfilled ? theme.palette.blue.main : theme.palette.gray[10])};
  transition: all 0.5s ease-in-out;
  white-space: nowrap;
  svg {
    position: absolute;
  }
  p {
    width: 100%;
  }
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    max-width: ${(props): string => (props.currentstep ? "40px" : "32px")};
    max-height: ${(props): string => (props.currentstep ? "40px" : "32px")};
    margin-bottom: 32px;
    svg {
      width: 18px;
      visibility: ${(props): string => (props.currentstep ? "visible" : "hidden")};
    }
  }
`;

export const Label = styled(Subtitle)<IconStepProps>`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 100px;
  color: ${(props): string => (props.isfulfilled ? theme.palette.blue.main : theme.palette.gray[10])};
  font-size: ${(props): string => (props.currentstep ? "20px" : "16px")};
  transition: color 0.5s ease-in-out;
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    font-size: 14px;
    visibility: ${(props): string => (props.currentstep ? "visible" : "hidden")};
  }
`;

export const Connector = styled.div<IconStepProps>`
  z-index: 1;
  display: flex;
  width: 100%;
  height: 4px;
  margin-right: -2px;
  margin-left: -2px;
  background-color: ${(props): string => (props.isfulfilled ? theme.palette.blue.main : theme.palette.gray[10])};
  transition: all 0.5s ease-in-out;
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    margin-bottom: 10px;
  }
`;
