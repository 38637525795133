import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const BarbecueToolsFilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M36 31C35.4477 31 35 30.5522 35 30V18C35 17.4477 35.4477 17 36 17C36.5523 17 37 17.4477 37 18V30C37 30.5522 36.5523 31 36 31Z"
        fill="#AEAEAE"
      />
      <path
        // eslint-disable-next-line max-len
        d="M12 31C11.4477 31 11 30.5522 11 30V18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18V30C13 30.5522 12.5523 31 12 31Z"
        fill="#AEAEAE"
      />
      <path
        // eslint-disable-next-line max-len
        d="M12 19C8.14014 19 5 15.8599 5 12V2C5 1.44775 5.44775 1 6 1C6.55225 1 7 1.44775 7 2V12C7 14.7568 9.24316 17 12 17C14.7568 17 17 14.7568 17 12V2C17 1.44775 17.4478 1 18 1C18.5522 1 19 1.44775 19 2V12C19 15.8599 15.8599 19 12 19Z"
        fill="#AEAEAE"
      />
      <path
        // eslint-disable-next-line max-len
        d="M11.5002 46.9755C8.90274 46.722 6.99999 44.3868 7 41.777V30C7 29.4477 7.44772 29 8 29H16C16.5523 29 17 29.4477 17 30V42C17 44.9217 14.4805 47.2665 11.5002 46.9755Z"
        fill="#FFD330"
      />
      <path
        // eslint-disable-next-line max-len
        d="M16 31H8C7.44772 31 7 30.5523 7 30C7 29.4477 7.44771 29 8 29H16C16.5523 29 17 29.4477 17 30C17 30.5523 16.5523 31 16 31Z"
        fill="#00416A"
      />
      <path
        // eslint-disable-next-line max-len
        d="M35.5002 46.9755C32.9027 46.722 31 44.3868 31 41.777V30C31 29.4477 31.4477 29 32 29H40C40.5523 29 41 29.4477 41 30V42C41 44.9217 38.4805 47.2665 35.5002 46.9755Z"
        fill="#FFD330"
      />
      <path
        // eslint-disable-next-line max-len
        d="M40 31H32C31.4477 31 31 30.5523 31 30C31 29.4477 31.4477 29 32 29H40C40.5523 29 41 29.4477 41 30C41 30.5523 40.5523 31 40 31Z"
        fill="#00416A"
      />
      <path
        // eslint-disable-next-line max-len
        d="M43 1H29C27.8954 1 27 1.89545 27 3V19C27 20.1045 27.8954 21 29 21H43C44.1046 21 45 20.1045 45 19V3C45 1.89545 44.1046 1 43 1ZM34 16C34 16.5522 33.5522 17 33 17C32.4478 17 32 16.5522 32 16V6C32 5.44775 32.4478 5 33 5C33.5522 5 34 5.44775 34 6V16ZM40 16C40 16.5522 39.5522 17 39 17C38.4478 17 38 16.5522 38 16V6C38 5.44775 38.4478 5 39 5C39.5522 5 40 5.44775 40 6V16Z"
        fill="#AEAEAE"
      />
    </svg>
  );
};
