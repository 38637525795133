import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const RulerPencilLineDecorative: BykoReactDecorativeIconComponentType = ({ size, color = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M31 35H45" stroke={color} strokeMiterlimit="10" strokeWidth="2.5" />
      <path d="M31 10H45" stroke={color} strokeMiterlimit="10" strokeWidth="2.5" />
      <path
        d="M45 35L38 44L31 35V2H45V35Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path d="M4 12H12" stroke={color} strokeMiterlimit="10" strokeWidth="2.5" />
      <path d="M4 20H10" stroke={color} strokeMiterlimit="10" strokeWidth="2.5" />
      <path d="M4 28H12" stroke={color} strokeMiterlimit="10" strokeWidth="2.5" />
      <path d="M4 36H10" stroke={color} strokeMiterlimit="10" strokeWidth="2.5" />
      <path d="M20 2H4V46H20V2Z" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
    </svg>
  );
};
