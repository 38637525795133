import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const HouseIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M1 11L12 2L23 11" stroke={iconColor} strokeMiterlimit="10" strokeWidth="2" />
      <path d="M10 23V17H14V23" stroke={iconColor} strokeMiterlimit="10" strokeWidth="2" />
      <path
        d="M4 13V23H10M20 13V23H14"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
};
