/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const EcologyIcons: BykoReactIconComponentType = ({ size, iconColor = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1881_7318)">
        <path
          d="M7.11 3L8.722 4.325L10.367 6.081L9.963 8.821L7.369 10.133L6.667 12.439L7.974 13.839L8.68 16.312L6.717 17.959L6 20"
          stroke={iconColor}
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M23 8C22.3038 8.93503 21.3987 9.69446 20.357 10.2177C19.3153 10.741 18.1658 11.0137 17 11.014C15.9391 11.014 14.9217 11.4354 14.1716 12.1856C13.4214 12.9357 13 13.9531 13 15.014C13 16.0749 13.4214 17.0923 14.1716 17.8424C14.9217 18.5926 15.9391 19.014 17 19.014C18.5913 19.014 20.1174 18.3819 21.2426 17.2566C22.3679 16.1314 23 14.6053 23 13.014V8Z"
          stroke={iconColor}
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M16.757 15.771C16.1992 16.3277 15.7569 16.9891 15.4553 17.7173C15.1538 18.4454 14.9991 19.2259 15 20.014C15 20.8096 14.6839 21.5727 14.1213 22.1353C13.5587 22.6979 12.7956 23.014 12 23.014"
          stroke={iconColor}
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M7.63787 23.1742L8.56999 23.5363L9.29426 21.672L8.36214 21.3099L7.63787 23.1742ZM20 4.47304L20.743 3.8037L20.7362 3.79624L20.7294 3.78892L20 4.47304ZM19.5454 5.46221L20.1945 6.22285L21.7158 4.92451L21.0666 4.16387L19.5454 5.46221ZM8.36214 21.3099C6.96341 20.7665 5.705 19.9151 4.6803 18.8189L3.21922 20.1846C4.44898 21.5002 5.95922 22.522 7.63787 23.1742L8.36214 21.3099ZM4.6803 18.8189C3.65559 17.7226 2.8909 16.4097 2.44297 14.9775L0.534152 15.5746C1.07173 17.2933 1.98945 18.869 3.21922 20.1846L4.6803 18.8189ZM2.44297 14.9775C1.99503 13.5454 1.87534 12.0307 2.0928 10.546L0.113908 10.2562C-0.147061 12.038 -0.00342667 13.8558 0.534152 15.5746L2.44297 14.9775ZM2.0928 10.546C2.31025 9.06125 2.85925 7.64454 3.69907 6.40098L2.04163 5.28165C1.03375 6.77408 0.374877 8.4743 0.113908 10.2562L2.0928 10.546ZM3.69907 6.40098C4.53889 5.15742 5.64796 4.11893 6.94399 3.36259L5.93592 1.63522C4.38054 2.54292 3.04952 3.78923 2.04163 5.28165L3.69907 6.40098ZM6.94399 3.36259C8.24002 2.60625 9.68973 2.15146 11.1855 2.03198L11.0263 0.038327C9.23114 0.18172 7.49131 0.727519 5.93592 1.63522L6.94399 3.36259ZM11.1855 2.03198C12.6814 1.91249 14.1849 2.13138 15.5845 2.6724L16.3056 0.806915C14.6259 0.157628 12.8215 -0.105066 11.0263 0.038327L11.1855 2.03198ZM15.5845 2.6724C16.9842 3.21342 18.2441 4.06268 19.2706 5.15716L20.7294 3.78892C19.4974 2.47541 17.9854 1.4562 16.3056 0.806915L15.5845 2.6724ZM19.257 5.14237C19.3808 5.2797 19.4568 5.35848 19.5454 5.46221L21.0666 4.16387C20.9572 4.0356 20.8192 3.88838 20.743 3.8037L19.257 5.14237Z"
          stroke={iconColor}
        />
        <path
          d="M12.5 1.5L12.939 2.34904L13.559 4.16204L15.843 6.00004L19 4"
          stroke={iconColor}
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1881_7318">
          <rect fill="white" height={size} width={size} />
        </clipPath>
      </defs>
    </svg>
  );
};
