import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const CheckmarkIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M9 21L1 13L4 10L9 15L21 3L24 6L9 21Z" fill={iconColor} />
    </svg>
  );
};
