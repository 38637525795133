import Script from "next/script";

import { getBykoConfig } from "@byko/lib-utils";

export const Klaviyo = (): JSX.Element | null => {
  const klaviyoPublicKey = getBykoConfig("KLAVIYO_PUBLIC_API_KEY");
  if (!klaviyoPublicKey || klaviyoPublicKey.length === 0) {
    return null;
  }
  return <Script async={true} src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${klaviyoPublicKey}`} />;
};
