import styled, { css } from "styled-components";

import { theme } from "@byko/lib-styles";

import type { NonResponsiveProps } from "./interface";

export const paragraphStyles = css<NonResponsiveProps>`
  margin-top: ${(p): string => `${p.top ? p.top : "0"}px`};
  margin-right: ${(p): string => `${p.right ? p.right : "0"}px`};
  margin-bottom: ${(p): string => `${p.bottom ? p.bottom : "0"}px`};
  margin-left: ${(p): string => `${p.left ? p.left : "0"}px`};
  color: ${(p): string => (p.textColor ? p.textColor : theme.palette.blue.main)};
  line-height: ${(p): string => (p.lineHeight ? p.lineHeight : "170%")};
  overflow-wrap: break-word;
  text-align: ${(p): string => (p.textAlign ? p.textAlign : "inherit")};
  &::selection {
    background-color: ${theme.palette.yellow.main};
  }
`;

export const BtnText = styled.p`
  font-size: 16px;
  font-weight: medium;
  ${paragraphStyles}
`;

export const EyebrowLarge = styled.p`
  font-size: 16px;
  font-weight: medium;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  ${paragraphStyles}
`;

export const EyebrowMedium = styled.p`
  font-size: 14px;
  font-weight: medium;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  ${paragraphStyles}
`;

export const EyebrowSmall = styled.p`
  font-size: 12px;
  font-weight: medium;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  ${paragraphStyles}
`;

export const Subtitle = styled.p`
  font-size: 20px;
  font-weight: normal;
  line-height: 150%;
  ${paragraphStyles}
`;

export const SubtitleBold = styled.p`
  font-size: 20px;
  font-weight: bold;
  line-height: 150%;
  ${paragraphStyles}
`;

export const PLarge = styled.p`
  font-size: 20px;
  line-height: 150%;
  ${paragraphStyles}
`;

export const PLargeBold = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 150%;
  ${paragraphStyles}
`;

export const PMediumCond = styled.p`
  font-size: 18px;
  font-stretch: condensed;
  ${paragraphStyles}
`;

export const PMedium = styled.p`
  font-size: 18px;
  ${paragraphStyles}
`;

export const PMediumBold = styled.p`
  font-size: 18px;
  font-weight: bold;
  ${paragraphStyles}
`;

export const PSmall = styled.p`
  font-size: 16px;
  font-weight: normal;
  ${paragraphStyles}
`;

export const PSmallBold = styled.p`
  font-size: 16px;
  font-weight: bold;
  ${paragraphStyles}
`;
