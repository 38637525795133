import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const SendMailIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_716_4188)">
        <path d="M22 2L10 14" stroke={iconColor} strokeMiterlimit="10" strokeWidth="2" />
        <path
          d="M22 2L15 22L10 14L2 9L22 2Z"
          stroke={iconColor}
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_716_4188">
          <rect fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SendMailIcons;
