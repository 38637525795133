import React, { useCallback } from "react";

import { Button } from "@byko/component-buttons";
import { AddIcons, SubtractIcons } from "@byko/lib-icons";

import { AccordionContent, AccordionRowOuter, AccordionRowWrapper } from "../../styles";

import type { AccordionRowProps } from "../../interface";

export const AccordionRow = ({ configuration, toggleActive }: AccordionRowProps): JSX.Element => {
  const handleClick = useCallback(() => {
    if (typeof configuration.id === "number") {
      toggleActive(configuration.id);
    }
  }, [configuration.id, toggleActive]);

  return (
    <AccordionRowOuter>
      <AccordionRowWrapper active={configuration.active ?? false}>
        <Button
          buttonColor="whiteButton"
          customClassName="accordion-button"
          icon={configuration.active ? SubtractIcons : AddIcons}
          label={configuration.label}
          stretch={true}
          onClick={handleClick}
        />
      </AccordionRowWrapper>
      <AccordionContent displayContent={configuration.active ?? false}>{configuration.content}</AccordionContent>
    </AccordionRowOuter>
  );
};
