import * as React from "react";
import { useCallback, useMemo, useState } from "react";

import { theme } from "@byko/lib-styles";

import { IconButtonContainer } from "./styles";

import type { IconButtonProps } from "./interface";

export const IconButton = ({
  icon: Icon,
  ariaLabel,
  className,
  onClick,
  disabled = false,
  small = false,
  buttonColor = "whiteButton",
  customClassName = "icon-button",
}: IconButtonProps): JSX.Element => {
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const iconColor = useMemo((): string => {
    switch (buttonColor) {
      case "blueButton":
        return theme.palette.yellow.main;
      case "greenButton":
        return theme.palette.white.main;
      case "orangeButton":
        return theme.palette.white.main;
      case "redButton":
        return isHovering ? theme.palette.white.main : theme.palette.triggers.warning.main;
      case "whiteButton":
        return theme.palette.blue.main;
      case "yellowButton":
        return theme.palette.blue.main;
      default:
        return theme.palette.blue.main;
    }
  }, [buttonColor, isHovering]);

  const handleMouseOver = useCallback(() => {
    setIsHovering(true);
  }, []);

  const handleMouseOut = useCallback(() => {
    setIsHovering(false);
  }, []);

  return (
    <IconButtonContainer
      aria-label={ariaLabel ?? ""}
      buttonColor={buttonColor}
      className={`${customClassName ?? ""}${customClassName ? " " : ""}${className ?? ""}`}
      disabled={disabled}
      role="button"
      small={small}
      title={ariaLabel}
      onClick={onClick}
      onMouseOut={handleMouseOut}
      onMouseOver={handleMouseOver}
    >
      <Icon iconColor={disabled ? theme.palette.gray[30] : iconColor} size={small ? 16 : 24} />
    </IconButtonContainer>
  );
};
