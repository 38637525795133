/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createContext, useContext } from "react";

import type { Category, FieldType, Warehouse } from "@byko/lib-api-rest";
import type { Config } from "src/interface";

export interface CommonContextProps {
  categories: Category[];
  warehouses: Warehouse[];
  fieldTypes: FieldType[];
  config: Config;
  categoriesRental: Category[];
}

export const CommonContext = createContext<Partial<CommonContextProps>>({});

export function useCommonContext() {
  return useContext(CommonContext) as CommonContextProps;
}
