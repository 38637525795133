/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { CancelLargeIcons } from "@byko/lib-icons";
import { theme } from "@byko/lib-styles";

import { ClickAway, NavigationOptionRow, SubNavigationOptions } from "./components";
import {
  CloseButtonWrapper,
  ColumnContainer,
  Container,
  Heading,
  MainContent,
  NavigationOptions,
  SubContent,
} from "./styles";

import type { MegamenuProps, MenuLevel } from "./interface";

export const Megamenu = ({ open, toggleOpen, configuration }: MegamenuProps): JSX.Element => {
  const router = useRouter();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [activeSubIndexOne, setActiveSubIndexOne] = useState<number | null>(null);
  const [activeSubIndexTwo, setActiveSubIndexTwo] = useState<number | null>(null);
  const [activeSubIndexThree, setActiveSubIndexThree] = useState<number | null>(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const element = document.querySelector("body");
      if (open) {
        element?.classList.add("no-scroll");
      } else {
        element?.classList.remove("no-scroll");
      }
    }
    if (open === false) {
      setTimeout(() => {
        // clearing open state after fadeout animation
        setActiveIndex(null);
        setActiveSubIndexOne(null);
        setActiveSubIndexTwo(null);
        setActiveSubIndexThree(null);
      }, 400);
    }
  }, [open]);

  const subMenuOne = useMemo((): MenuLevel | null => {
    const child = typeof activeIndex === "number" ? configuration[activeIndex] : null;
    if (child) {
      const filteredChildren = child.children?.filter((item) => {
        return item.showInMegamenu;
      });

      const sortedChildren = filteredChildren?.sort((a, b) => {
        return a.menuWeight - b.menuWeight;
      });

      return { ...child, children: sortedChildren } as MenuLevel;
    }

    return null;
  }, [activeIndex, configuration]);

  const subMenuTwo = useMemo((): MenuLevel | null => {
    const child = typeof activeSubIndexOne === "number" ? subMenuOne?.children?.[activeSubIndexOne] : null;
    if (child) {
      const filteredChildren = child.children?.filter((item) => {
        return item.showInMegamenu;
      });

      const sortedChildren = filteredChildren?.sort((a, b) => {
        return a.menuWeight - b.menuWeight;
      });

      return { ...child, children: sortedChildren } as MenuLevel;
    }

    return null;
  }, [activeSubIndexOne, subMenuOne?.children]);

  const subMenuThree = useMemo((): MenuLevel | null => {
    const child = typeof activeSubIndexTwo === "number" ? subMenuTwo?.children?.[activeSubIndexTwo] : null;
    if (child) {
      const filteredChildren = child.children?.filter((item) => {
        return item.showInMegamenu;
      });

      const sortedChildren = filteredChildren?.sort((a, b) => {
        return a.menuWeight - b.menuWeight;
      });

      return { ...child, children: sortedChildren } as MenuLevel;
    }

    return null;
  }, [activeSubIndexTwo, subMenuTwo?.children]);

  const subMenuTwoStartingTabIndex = useMemo((): number => {
    if (subMenuOne === null) {
      return -1;
    }
    return configuration.length + subMenuOne.children.length;
  }, [configuration.length, subMenuOne]);

  const subMenuThreeStartingTabIndex = useMemo((): number => {
    if (subMenuOne === null || subMenuTwo === null) {
      return -1;
    }
    return configuration.length + subMenuOne.children.length;
  }, [configuration.length, subMenuOne, subMenuTwo]);

  useEffect(() => {
    setActiveSubIndexOne(null);
  }, [activeIndex]);

  useEffect(() => {
    setActiveSubIndexTwo(null);
  }, [activeSubIndexOne]);

  const navigatingProducts = subMenuOne?.slug === "voruflokkar" || subMenuOne?.slug === "bykoleiga";

  const goToPage = useCallback(
    (url: string) => {
      // check if first character is not /, then add it
      if (url.charAt(0) !== "/") {
        url = `/${url}`;
      }
      router.push(url);
      toggleOpen();
    },
    [toggleOpen, router],
  );

  return (
    <Container open={open}>
      {open && <ClickAway toggleOpen={toggleOpen} />}
      <SubContent>
        <ColumnContainer>
          <SubNavigationOptions
            activeSubIndex={activeSubIndexOne}
            goToChildPage={goToPage}
            goToPage={(): void => (subMenuOne?.url ? goToPage(subMenuOne.url) : void 0)}
            menu={subMenuOne}
            navigatingProducts={navigatingProducts}
            setActiveSubIndex={setActiveSubIndexOne}
            startingTabIndex={configuration.length}
          />
          <SubNavigationOptions
            activeSubIndex={activeSubIndexTwo}
            goToChildPage={goToPage}
            goToPage={(): void => (subMenuTwo?.url ? goToPage(subMenuTwo.url) : void 0)}
            menu={subMenuTwo}
            navigatingProducts={navigatingProducts}
            setActiveSubIndex={setActiveSubIndexTwo}
            startingTabIndex={subMenuTwoStartingTabIndex}
          />
          <SubNavigationOptions
            activeSubIndex={activeSubIndexThree}
            goToChildPage={goToPage}
            goToPage={(): void => (subMenuThree?.url ? goToPage(subMenuThree.url) : void 0)}
            hideBorder={true}
            menu={subMenuThree}
            navigatingProducts={navigatingProducts}
            setActiveSubIndex={setActiveSubIndexThree}
            startingTabIndex={subMenuThreeStartingTabIndex}
          />
        </ColumnContainer>
      </SubContent>
      <MainContent show={true}>
        <Heading darkBackdrop={true}>
          <CloseButtonWrapper onClick={toggleOpen}>
            <CancelLargeIcons iconColor={theme.palette.yellow.main} size={32} />
          </CloseButtonWrapper>
        </Heading>
        <NavigationOptions>
          {configuration.map((menuItem, index) => {
            return (
              <NavigationOptionRow
                key={menuItem.slug}
                active={activeIndex === index}
                goToPage={(): void => (menuItem?.url ? goToPage(menuItem.url) : void 0)}
                index={index}
                isLink={menuItem && menuItem.children && menuItem.children.length === 0}
                label={menuItem.title}
                setActive={setActiveIndex}
                tabIndex={index}
              />
            );
          })}
        </NavigationOptions>
      </MainContent>
    </Container>
  );
};
