/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const LocationIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1194_7374)">
        <path
          d="M19.8182 9.00012C19.8182 13.9381 11.8182 22.0001 11.8182 22.0001C11.8182 22.0001 3.81817 13.9381 3.81817 9.00012C3.81817 3.93812 7.95217 1.00012 11.8182 1.00012C15.6842 1.00012 19.8182 3.93812 19.8182 9.00012Z"
          stroke={iconColor}
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M11.8182 12.0001C13.475 12.0001 14.8182 10.657 14.8182 9.00012C14.8182 7.34327 13.475 6.00012 11.8182 6.00012C10.1613 6.00012 8.81817 7.34327 8.81817 9.00012C8.81817 10.657 10.1613 12.0001 11.8182 12.0001Z"
          stroke={iconColor}
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1194_7374">
          <rect fill="white" height="24" rx="12" width="24" y="0.00012207" />
        </clipPath>
      </defs>
    </svg>
  );
};
