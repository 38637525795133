import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const AddUserIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg
      className="byko-icon"
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 16H8C4.5 16 1 19.1337 1 23C1 23 22.958 23 23 23"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path d="M20 13V19" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M17 16H23" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <circle cx="12.5" cy="7.5" r="5.5" stroke={iconColor} strokeWidth="2" />
    </svg>
  );
};
