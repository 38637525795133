import styled from "styled-components";
import { atom, useRecoilState } from "@byko/lib-recoil";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { IconButton } from "@byko/component-buttons";
import { CancelLargeIcons } from "@byko/lib-icons";

export const lightboxContentState = atom<JSX.Element | null>({
  key: "lightbox-contents-root",
  default: null,
});

export const showLightboxState = atom<boolean>({
  key: "lightbox-show-root",
  default: true,
});

export default function useLightbox() {
  const [Contents, setContents] = useRecoilState(lightboxContentState);
  const [showLightbox, setShowLightbox] = useRecoilState(showLightboxState);

  const close = useCallback(() => {
    setShowLightbox(false);
    setContents(null);
  }, [setContents, setShowLightbox]);

  const open = useCallback(
    (content: JSX.Element) => {
      setContents(content);
      setShowLightbox(true);
    },
    [setContents, setShowLightbox],
  );

  const Lightbbox = useMemo(() => {
    if (!showLightbox) return null;
    if (!Contents) return null;

    return <LightboxContainer onClose={close}>{Contents}</LightboxContainer>;
  }, [Contents, close, showLightbox]);

  return {
    open,
    close,
    Lightbbox,
  };
}

function LightboxContainer({ onClose, children }: { onClose: () => void; children: JSX.Element }) {
  const containerRef = useRef<HTMLDivElement>(null);
  const handleEsc = useCallback(
    (ev: React.KeyboardEvent<HTMLInputElement>) => {
      if (ev.key === "Escape") {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    container.focus();
  }, [containerRef]);

  return (
    <Container ref={containerRef} tabIndex={0} onClick={onClose} onKeyDown={handleEsc}>
      <ContentWrapper>
        <ButtonContainer>
          <IconButton ariaLabel="Loka" icon={CancelLargeIcons} small={true} onClick={onClose} />
        </ButtonContainer>
        {children}
      </ContentWrapper>
    </Container>
  );
}

const ButtonContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  overflow: auto;
  max-width: 80%;
  max-height: 100%;
  flex-direction: column;
  padding: 64px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
`;

const Container = styled.div`
  position: fixed;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px) grayscale(0.4);
  inset: 0;
  transition: opacity 0.3s ease-in-out;
`;
