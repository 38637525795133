/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const LawnmowerFilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M34 19H22V28H34V19Z" fill="#0A3A5C" />
      <path
        d="M13.409 23.754C13.1735 23.7537 12.9457 23.6702 12.7657 23.5184C12.5857 23.3666 12.465 23.1561 12.425 22.924L9.444 5.65901C9.36366 5.19472 9.12199 4.77371 8.76158 4.4702C8.40117 4.16669 7.94518 4.00017 7.474 4.00001H4C3.73478 4.00001 3.48043 3.89465 3.29289 3.70711C3.10536 3.51958 3 3.26522 3 3.00001C3 2.73479 3.10536 2.48044 3.29289 2.2929C3.48043 2.10536 3.73478 2.00001 4 2.00001H7.474C8.41716 1.99836 9.33043 2.33079 10.0518 2.93834C10.7733 3.54589 11.2562 4.38931 11.415 5.31901L14.4 22.584C14.4248 22.7277 14.4179 22.8751 14.3797 23.0159C14.3415 23.1566 14.2729 23.2873 14.1789 23.3988C14.0849 23.5103 13.9676 23.5998 13.8353 23.6612C13.703 23.7226 13.5588 23.7542 13.413 23.754H13.409Z"
        fill="#FFD330"
      />
      <path
        d="M37.585 24.591L11.585 21.532C10.8837 21.4494 10.1728 21.5163 9.49918 21.7282C8.82556 21.9402 8.20449 22.2925 7.67685 22.7618C7.14921 23.2312 6.72698 23.807 6.43796 24.4513C6.14893 25.0956 5.99967 25.7938 6 26.5V38C6 38.2652 6.10536 38.5196 6.29289 38.7071C6.48043 38.8947 6.73478 39 7 39H41C41.2652 39 41.5196 38.8947 41.7071 38.7071C41.8946 38.5196 42 38.2652 42 38V29.557C42.0001 28.3322 41.5506 27.1499 40.7368 26.2345C39.9229 25.3192 38.8014 24.7343 37.585 24.591Z"
        fill="#00416A"
      />
      <path
        d="M8 43C11.3137 43 14 40.3137 14 37C14 33.6863 11.3137 31 8 31C4.68629 31 2 33.6863 2 37C2 40.3137 4.68629 43 8 43Z"
        fill="white"
      />
      <path
        d="M41 43C43.7614 43 46 40.7614 46 38C46 35.2386 43.7614 33 41 33C38.2386 33 36 35.2386 36 38C36 40.7614 38.2386 43 41 43Z"
        fill="white"
      />
      <path
        d="M8 44C6.61553 44 5.26216 43.5895 4.11101 42.8203C2.95987 42.0511 2.06266 40.9579 1.53285 39.6788C1.00303 38.3997 0.86441 36.9922 1.13451 35.6344C1.4046 34.2765 2.07129 33.0292 3.05026 32.0503C4.02922 31.0713 5.2765 30.4046 6.63437 30.1345C7.99224 29.8644 9.3997 30.003 10.6788 30.5328C11.9579 31.0627 13.0511 31.9599 13.8203 33.111C14.5895 34.2622 15 35.6155 15 37C14.9976 38.8558 14.2594 40.6349 12.9471 41.9471C11.6349 43.2594 9.85579 43.9976 8 44ZM8 32C7.0111 32 6.0444 32.2932 5.22215 32.8427C4.39991 33.3921 3.75904 34.173 3.38061 35.0866C3.00217 36.0002 2.90315 37.0056 3.09608 37.9755C3.289 38.9454 3.76521 39.8363 4.46447 40.5355C5.16373 41.2348 6.05465 41.711 7.02455 41.9039C7.99446 42.0969 8.99979 41.9978 9.91342 41.6194C10.8271 41.241 11.6079 40.6001 12.1574 39.7779C12.7068 38.9556 13 37.9889 13 37C12.9984 35.6744 12.4711 34.4036 11.5338 33.4662C10.5964 32.5289 9.3256 32.0016 8 32Z"
        fill="#FFD330"
      />
      <path
        d="M41 44C39.8133 44 38.6533 43.6481 37.6666 42.9888C36.6799 42.3295 35.9109 41.3925 35.4567 40.2961C35.0026 39.1997 34.8838 37.9933 35.1153 36.8295C35.3468 35.6656 35.9182 34.5965 36.7574 33.7574C37.5965 32.9182 38.6656 32.3468 39.8295 32.1153C40.9933 31.8838 42.1997 32.0026 43.2961 32.4567C44.3925 32.9109 45.3295 33.6799 45.9888 34.6666C46.6481 35.6533 47 36.8133 47 38C46.9984 39.5908 46.3658 41.116 45.2409 42.2409C44.116 43.3658 42.5908 43.9984 41 44ZM41 34C40.2089 34 39.4355 34.2346 38.7777 34.6741C38.1199 35.1137 37.6072 35.7384 37.3045 36.4693C37.0017 37.2002 36.9225 38.0044 37.0769 38.7804C37.2312 39.5563 37.6122 40.269 38.1716 40.8284C38.731 41.3878 39.4437 41.7688 40.2196 41.9231C40.9956 42.0775 41.7998 41.9983 42.5307 41.6955C43.2616 41.3928 43.8864 40.8801 44.3259 40.2223C44.7654 39.5645 45 38.7911 45 38C45 36.9391 44.5786 35.9217 43.8284 35.1716C43.0783 34.4214 42.0609 34 41 34Z"
        fill="#FFD330"
      />
      <path
        d="M36 14H20C19.4477 14 19 14.4477 19 15V19C19 19.5523 19.4477 20 20 20H36C36.5523 20 37 19.5523 37 19V15C37 14.4477 36.5523 14 36 14Z"
        fill="#FFD330"
      />
    </svg>
  );
};
