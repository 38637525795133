import styled, { css } from "styled-components";

import { theme } from "@byko/lib-styles";

import type { HeaderProps } from "./interface";

export const HeadingsStyles = css<HeaderProps>`
  margin-top: ${(p): string => `${p.top ? p.top : "0"}px`};
  margin-right: ${(p): string => `${p.right ? p.right : "0"}px`};
  margin-bottom: ${(p): string => `${p.bottom ? p.bottom : "0"}px`};
  margin-left: ${(p): string => `${p.left ? p.left : "0"}px`};
  color: ${(p): string => (p.textColor ? p.textColor : theme.palette.blue.main)};
  letter-spacing: -0.02em;
  line-height: 110%;
  overflow-wrap: break-word;
  text-align: ${(p): string => (p.textAlign ? p.textAlign : "inherit")};
  text-transform: ${(p): string => (p.uppercase ? "uppercase" : "initial")};
  &::selection {
    background-color: ${theme.palette.yellow.main};
  }
`;

export const H1 = styled.h1`
  color: ${({ textColor }): string => (textColor ? textColor : theme.palette.blue.dark)};
  ${(props): string => (props.maxFontSize ? `font-size: ${props.maxFontSize};` : "font-size: 32px;")}
  ${HeadingsStyles}
`;

export const H2 = styled.h2`
  color: ${({ textColor }): string => (textColor ? textColor : theme.palette.blue.dark)};
  font-size: 24px;
  ${(props): string => (props.maxFontSize ? `font-size: ${props.maxFontSize};` : "font-size: 24px;")}
  @media screen and (max-width: 375px) {
    font-size: 20px;
  }
  ${HeadingsStyles}
`;

export const H3 = styled.h3`
  color: ${({ textColor }): string => (textColor ? textColor : theme.palette.blue.dark)};
  font-size: 20px;
  ${(props): string => (props.maxFontSize ? `font-size: ${props.maxFontSize};` : "font-size: 20px;")}
  @media screen and (max-width: 375px) {
    font-size: 20px;
  }
  ${HeadingsStyles}
`;

export const H4 = styled.h4`
  color: ${({ textColor }): string => (textColor ? textColor : theme.palette.blue.dark)};
  font-size: 20px;
  ${(props): string => (props.maxFontSize ? `font-size: ${props.maxFontSize};` : "font-size: 20px;")}
  @media screen and (max-width: 375px) {
    font-size: 20px;
  }
  ${HeadingsStyles}
`;

export const H5 = styled.h5`
  color: ${({ textColor }): string => (textColor ? textColor : theme.palette.blue.dark)};
  font-size: 20px;
  ${(props): string => (props.maxFontSize ? `font-size: ${props.maxFontSize};` : "font-size: 20px;")}
  @media screen and (max-width: 375px) {
    font-size: 20px;
  }
  ${HeadingsStyles}
`;

export const H6 = styled.h6`
  color: ${({ textColor }): string => (textColor ? textColor : theme.palette.blue.dark)};
  font-size: 20px;
  ${(props): string => (props.maxFontSize ? `font-size: ${props.maxFontSize};` : "font-size: 20px;")}
  @media screen and (max-width: 375px) {
    font-size: 20px;
  }
  ${HeadingsStyles}
`;
