import styled from "styled-components";

import { theme } from "@byko/lib-styles";

import type { AccordionContentProps, AccordionRowWrapperProps } from "./interface";

export const AccordionContent = styled.div<AccordionContentProps>`
  width: 100%;
  max-width: 320px;
  max-height: ${(props): string => (props.displayContent ? "100%" : "0px")};
  padding: ${(props): string => (props.displayContent ? "10px 24px 30px 24px" : "0px 24px")};
  opacity: ${(props): string => (props.displayContent ? "1" : "0")};
  pointer-events: ${(props): string => (props.displayContent ? "initial" : "none")};
`;

export const AccordionRowOuter = styled.div``;

export const AccordionRowWrapper = styled.div<AccordionRowWrapperProps>`
  width: 100%;
  .accordion-button {
    border: none;
    border-top: ${(props): string => (props.active ? "2px" : "1px")} solid
      ${(props): string => (props.active ? theme.palette.blue.main : theme.palette.gray[10])};
    :hover {
      box-shadow: none;
      p {
        color: ${theme.palette.blue.main};
      }
    }
  }
`;

export const AccordionWrapper = styled.div<{ active: boolean }>`
  display: flex;
  width: 100%;
  height: fit-content;
  flex-direction: column;
  padding: 30px 24px;
  background-color: ${theme.palette.white.main};
  opacity: ${({ active }): string => (active ? "1" : "0")};
  outline: 1px solid ${theme.palette.gray[10]};
  pointer-events: ${({ active }): string => (active ? "auto" : "none")};
  .clear-filter-button {
    border: none;
    margin-top: 24px;
    p {
      color: ${theme.palette.blue.main};
    }
  }
  ${AccordionRowWrapper} {
    &:last-of-type {
      .accordion-button {
        border-bottom: 1px solid ${theme.palette.gray[10]};
      }
    }
  }
`;
