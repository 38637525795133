import * as React from "react";

import { Circle, CircleOuter, Label, RadioInput, Text } from "./styles";

import type { InputProps } from "./interface";

const noop = (): void => {
  return;
};

export const RadioButton = ({
  label = "",
  arialabel = "",
  checked = false,
  disabled = false,
  white = false,
  radioBox = false,
  value,
  onChange = noop,
  ...props
}: InputProps): JSX.Element => {
  return (
    <Label aria-label={label ? arialabel : label}>
      <RadioInput
        checked={checked}
        disabled={disabled}
        radioBox={radioBox}
        type="radio"
        value={value}
        white={white}
        onChange={onChange}
        {...props}
      />
      <CircleOuter radioBox={radioBox} white={white} />
      <Circle radioBox={radioBox} white={white} />
      {label && <Text white={white}>{label}</Text>}
    </Label>
  );
};
