/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from "styled-components";

import { gridTheme } from "@byko/lib-styles";

import type { FlattenInterpolation, ThemeProps } from "styled-components";

export const pageContainerPaddingLeft = (): FlattenInterpolation<ThemeProps<any>> => {
  return css`
    @media screen and (min-width: ${gridTheme.container.minWidth.xs}px) and (max-width: ${gridTheme.container.maxWidth
        .xs}px) {
      padding-left: 24px;
    }

    @media screen and (min-width: ${gridTheme.container.minWidth.s}px) and (max-width: ${gridTheme.container.maxWidth
        .s}px) {
      padding-left: 24px;
    }

    @media screen and (min-width: ${gridTheme.container.minWidth.sm}px) and (max-width: ${gridTheme.container.maxWidth
        .sm}px) {
      padding-left: 48px;
    }

    @media screen and (min-width: ${gridTheme.container.minWidth.m}px) and (max-width: ${gridTheme.container.maxWidth
        .m}px) {
      padding-left: 48px;
    }

    @media screen and (min-width: ${gridTheme.container.minWidth.mm}px) and (max-width: ${gridTheme.container.maxWidth
        .mm}px) {
      padding-left: 48px;
    }

    @media screen and (min-width: ${gridTheme.container.minWidth.md}px) and (max-width: ${gridTheme.container.maxWidth
        .md}px) {
      padding-left: 56px;
    }

    @media screen and (min-width: ${gridTheme.container.minWidth.lg}px) and (max-width: ${gridTheme.container.maxWidth
        .lg}px) {
      padding-left: 56px;
    }

    @media screen and (min-width: ${gridTheme.container.minWidth.xl}px) {
      padding-left: 56px;
    }
  `;
};
