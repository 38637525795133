import styled from "styled-components";
import { ifProp } from "styled-tools";

import { theme } from "@byko/lib-styles";

import type { BoxProps, WrapperProps } from "./interface";

export const FacetCheckboxWrapper = styled.div<WrapperProps>`
  padding-left: ${ifProp("padding", "16px", "0px")};
`;

export const IconContainer = styled.div`
  position: absolute;
  right: 0;
  pointer-events: none;
`;

export const Line = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const Item = styled.label`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 0;
  cursor: pointer;
  user-select: none;

  @media screen and (max-width: 895px) {
    margin-bottom: 16px;
  }
`;

export const CheckboxContainer = styled.div``;

export const Text = styled.span`
  color: ${theme.palette.blue.main};
  font-size: 1rem;
  line-height: 1.1;
`;

export const Count = styled.span`
  margin-left: 4px;
  color: ${theme.palette.gray[30]};
  font-size: 12px;
  transition: opacity 0.15s;
`;

export const Box = styled.div<BoxProps>`
  display: flex;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.palette.gray[20]};
  background-color: transparent;
  transition: background-color 0.15s, border-color 0.15s;
`;

export const Wrapper = styled.div`
  border-bottom: 1px solid ${theme.palette.gray[60]};
  transition: border-color 0.15s;
`;

export const IconWrapper = styled.div`
  transform: rotate(-90deg);
  transition: transform 0.2s ease-in-out;
`;

export const Label = styled.span`
  color: ${theme.palette.blue.main};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.1;
  text-align: left;
`;

export const FacetCount = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 16px;
  background-color: ${theme.palette.gray[10]};
  color: ${theme.palette.blue.main};
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1;
  transition: opacity 0.15s;
`;

export const Content = styled.div`
  padding: 8px 16px 24px 16px;
`;
