import { useQuery } from "@apollo/client";

import { CategoryBySlugDocument } from "../generated/graphql";
import { initializeApollo } from "../provider";

import type { CategoryBySlugQuery, CategoryBySlugQueryVariables } from "../generated/graphql";
import type { ApolloError } from "@apollo/client";

interface Data {
  loading: boolean;
  error: ApolloError | null;
  data: { slug: string; name: string; id: string } | null;
  notFound: boolean;
}

export const useCategoryBySlug = (slug: string | null | undefined): Data => {
  const { data, error, loading } = useQuery<CategoryBySlugQuery, CategoryBySlugQueryVariables>(CategoryBySlugDocument, {
    variables: { slug: slug ?? "" },
    skip: !slug,
  });

  return {
    error: error ?? null,
    loading,
    data: data?.category?.slug === slug ? data?.category ?? null : null,
    notFound: !error && !loading && !data?.category,
  };
};

export const getCategoryBySlug =
  (client = initializeApollo()) =>
  async (slug: string): Promise<Omit<Data, "refetch" | "loading">> => {
    const data = await client.query<CategoryBySlugQuery, CategoryBySlugQueryVariables>({
      query: CategoryBySlugDocument,
      variables: {
        slug,
      },
    });

    return {
      error: data.error ?? null,
      data: data?.data?.category?.slug === slug ? data?.data?.category ?? null : null,
      notFound: !data.error && !data?.data?.category,
    };
  };
