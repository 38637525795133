/**
 * Returns true if run from client.
 *
 * Avoids hydrations errors
 */

import { useEffect, useState } from "react";

export const useIsClient = (): boolean => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return isClient;
};
