import React, { useCallback } from "react";

import { theme } from "@byko/lib-styles";

import {
  BrandContainer,
  BrandName,
  FlexWrapper,
  HoverContainer,
  HoverLabel,
  HoverLabelContainer,
  ProdInfoWrapper,
  ProductName,
  ProductNumber,
  ProductNumberContainer,
  RightFloatContainer,
  ShortDecriptionText,
} from "./styles";

import type { ProductInformationProps } from "./interface";
import { EyebrowSmall } from "@byko/component-typography";

export const ProductCardInformation = ({
  brand,
  prodName,
  sku,
  shortDecription,
  addedLabel,
}: ProductInformationProps): JSX.Element => {
  const clickToCopy = useCallback(
    (e: React.MouseEvent<HTMLParagraphElement>) => {
      e.preventDefault();
      if (!sku) {
        return;
      }
      navigator.clipboard.writeText(sku);
    },
    [sku],
  );

  return (
    <ProdInfoWrapper>
      <FlexWrapper>
        {addedLabel && <EyebrowSmall>{addedLabel}</EyebrowSmall>}
        <BrandContainer>
          <BrandName textColor={theme.palette.black.main}>{brand}</BrandName>
        </BrandContainer>

        <ProductNumberContainer>
          <HoverContainer>
            <HoverLabelContainer>
              <ProductNumber textColor={theme.palette.gray[60]} onClick={clickToCopy}>
                {sku}
              </ProductNumber>
              <HoverLabel>
                <EyebrowSmall textColor={theme.palette.white.main}>Afrita vörunúmer</EyebrowSmall>
              </HoverLabel>
            </HoverLabelContainer>
          </HoverContainer>
        </ProductNumberContainer>
      </FlexWrapper>
      <ProductName>{prodName}</ProductName>
      <ShortDecriptionText>{shortDecription}</ShortDecriptionText>
    </ProdInfoWrapper>
  );
};
