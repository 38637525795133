import { useRecoilValue, useSetRecoilState } from "@byko/lib-recoil";

import { Store } from "../store/atom";

import type { StateType } from "../interfaces";
import type { SetterOrUpdater } from "@byko/lib-recoil";

export const useSetCheckoutStateValue = (): SetterOrUpdater<StateType> => useSetRecoilState(Store.state);

export const useCheckoutState = (): StateType => {
  const state = useRecoilValue(Store.state);
  return state;
};
