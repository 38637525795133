/* eslint-disable max-len */
import * as React from "react";
import { BykoReactIconComponentType } from "@byko/lib-core";

export const BaturIllustration: BykoReactIconComponentType = ({ size = 200 }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 662 611" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M495.97 225.5H493.45V262.06H495.97V225.5Z" fill="#003C71" />
      <path d="M474.28 239.21H471.76V262.06H474.28V239.21Z" fill="#003C71" />
      <path d="M122.8 212.91H120.28V257.76H122.8V212.91Z" fill="#003C71" />
      <path d="M193.55 123.08H191.03V190.09H193.55V123.08Z" fill="#003C71" />
      <path d="M202.23 145.89H199.71V212.9H202.23V145.89Z" fill="#003C71" />
      <path d="M226.98 225.5H128.4V282.95H226.98V225.5Z" fill="#A6CAE0" />
      <path d="M226.98 232.02H182.3V179.4H257.48L226.98 232.02Z" fill="#A6CAE0" />
      <path
        d="M114.21 256H213.5V275.15H434.01V256H530.32L490.1 325.5H114.21V312.03H137.62V286.49H123.43L114.21 256Z"
        fill="#408DBC"
      />
      <path d="M305.58 246.78H241.04V275.15H305.58V246.78Z" fill="#0067A5" />
      <path d="M370.12 246.78H305.58V275.15H370.12V246.78Z" fill="#82B5D3" />
      <path d="M434.66 246.78H370.12V275.15H434.66V246.78Z" fill="#0067A5" />
      <path d="M337.85 218.43H273.31V246.8H337.85V218.43Z" fill="#FF9D57" />
      <path d="M402.39 218.49H337.85V246.86H402.39V218.49Z" fill="#0067A5" />
      <path d="M370.12 190.27H305.58V218.64H370.12V190.27Z" fill="#82B5D3" />
      <path d="M197.32 185.11H187.26V195.17H197.32V185.11Z" fill="#003C71" />
      <path d="M214.28 185.11H204.22V195.17H214.28V185.11Z" fill="#003C71" />
      <path d="M197.32 200.33H187.26V210.39H197.32V200.33Z" fill="#003C71" />
      <path d="M214.28 200.33H204.22V210.39H214.28V200.33Z" fill="#003C71" />
      <path d="M231.24 185.11H221.18V195.17H231.24V185.11Z" fill="#003C71" />
      <path
        d="M248.22 189.91C246.141 189.91 244.109 189.294 242.381 188.139C240.653 186.984 239.305 185.343 238.51 183.422C237.715 181.502 237.506 179.388 237.912 177.35C238.317 175.311 239.318 173.438 240.788 171.968C242.258 170.499 244.131 169.498 246.17 169.092C248.208 168.687 250.322 168.895 252.242 169.69C254.162 170.486 255.804 171.833 256.959 173.561C258.114 175.289 258.73 177.321 258.73 179.4C258.727 182.187 257.619 184.858 255.649 186.829C253.678 188.799 251.007 189.907 248.22 189.91ZM248.22 171.41C246.638 171.41 245.091 171.879 243.775 172.758C242.46 173.637 241.434 174.887 240.829 176.349C240.223 177.81 240.065 179.419 240.374 180.971C240.682 182.523 241.444 183.948 242.563 185.067C243.682 186.186 245.107 186.948 246.659 187.256C248.211 187.565 249.82 187.407 251.281 186.801C252.743 186.196 253.993 185.17 254.872 183.855C255.751 182.539 256.22 180.992 256.22 179.41C256.22 178.36 256.013 177.319 255.611 176.349C255.209 175.378 254.62 174.496 253.877 173.753C253.134 173.01 252.252 172.421 251.281 172.019C250.311 171.617 249.271 171.41 248.22 171.41Z"
        fill="#003C71"
      />
      <path d="M182.3 211.65H114.21V214.17H182.3V211.65Z" fill="#003C71" />
      <path
        d="M435.92 276.41H212.24V257.26H164.57V254.74H214.76V273.89H433.4V254.74H530.32V257.26H435.92V276.41Z"
        fill="#003C71"
      />
      <path d="M138.88 286.49H136.36V312.02H138.88V286.49Z" fill="#003C71" />
      <path d="M153.53 286.49H151.01V312.02H153.53V286.49Z" fill="#003C71" />
      <path d="M168.19 286.49H165.67V312.02H168.19V286.49Z" fill="#003C71" />
      <path d="M182.85 286.49H180.33V312.02H182.85V286.49Z" fill="#003C71" />
      <path d="M197.51 286.49H194.99V312.02H197.51V286.49Z" fill="#003C71" />
      <path d="M212.16 286.49H209.64V312.02H212.16V286.49Z" fill="#003C71" />
      <path
        d="M451.33 282.19C450.1 282.19 448.897 281.825 447.874 281.142C446.852 280.458 446.054 279.487 445.584 278.35C445.113 277.214 444.99 275.963 445.23 274.757C445.47 273.55 446.062 272.442 446.932 271.572C447.802 270.702 448.91 270.11 450.117 269.87C451.323 269.63 452.574 269.753 453.71 270.224C454.847 270.694 455.818 271.492 456.502 272.514C457.185 273.537 457.55 274.74 457.55 275.97C457.547 277.619 456.891 279.2 455.725 280.365C454.559 281.531 452.979 282.187 451.33 282.19ZM451.33 272.27C450.599 272.272 449.884 272.491 449.277 272.899C448.67 273.306 448.197 273.885 447.919 274.561C447.64 275.237 447.569 275.981 447.712 276.698C447.856 277.415 448.209 278.073 448.727 278.59C449.245 279.106 449.904 279.458 450.622 279.6C451.339 279.742 452.083 279.668 452.758 279.388C453.434 279.107 454.011 278.633 454.417 278.025C454.823 277.416 455.04 276.701 455.04 275.97C455.039 275.484 454.942 275.003 454.755 274.555C454.567 274.107 454.294 273.699 453.949 273.357C453.605 273.014 453.196 272.743 452.747 272.558C452.297 272.373 451.816 272.279 451.33 272.28V272.27Z"
        fill="#003C71"
      />
      <path
        d="M473 282.19C471.77 282.19 470.567 281.825 469.544 281.142C468.522 280.458 467.724 279.487 467.254 278.35C466.783 277.214 466.66 275.963 466.9 274.757C467.14 273.55 467.732 272.442 468.602 271.572C469.472 270.702 470.58 270.11 471.787 269.87C472.993 269.63 474.244 269.753 475.38 270.224C476.517 270.694 477.488 271.492 478.172 272.514C478.855 273.537 479.22 274.74 479.22 275.97C479.217 277.619 478.561 279.2 477.395 280.365C476.229 281.531 474.649 282.187 473 282.19ZM473 272.27C472.268 272.27 471.553 272.487 470.944 272.894C470.336 273.3 469.862 273.878 469.582 274.554C469.302 275.23 469.228 275.974 469.371 276.692C469.514 277.41 469.866 278.069 470.384 278.586C470.901 279.104 471.56 279.456 472.278 279.599C472.996 279.742 473.74 279.669 474.416 279.388C475.092 279.108 475.67 278.634 476.076 278.026C476.483 277.417 476.7 276.702 476.7 275.97C476.697 274.991 476.306 274.052 475.613 273.36C474.919 272.669 473.98 272.28 473 272.28V272.27Z"
        fill="#003C71"
      />
      <path
        d="M494.71 282.19C493.48 282.19 492.277 281.825 491.254 281.142C490.231 280.458 489.434 279.487 488.963 278.35C488.493 277.214 488.37 275.963 488.61 274.757C488.85 273.55 489.442 272.442 490.312 271.572C491.182 270.702 492.29 270.11 493.497 269.87C494.703 269.63 495.954 269.753 497.09 270.224C498.227 270.694 499.198 271.492 499.882 272.514C500.565 273.537 500.93 274.74 500.93 275.97C500.927 277.619 500.271 279.2 499.105 280.365C497.939 281.531 496.359 282.187 494.71 282.19ZM494.71 272.27C493.978 272.27 493.263 272.487 492.654 272.894C492.046 273.3 491.572 273.878 491.292 274.554C491.012 275.23 490.938 275.974 491.081 276.692C491.224 277.41 491.576 278.069 492.094 278.586C492.611 279.104 493.27 279.456 493.988 279.599C494.706 279.742 495.45 279.669 496.126 279.388C496.802 279.108 497.38 278.634 497.786 278.026C498.193 277.417 498.41 276.702 498.41 275.97C498.407 274.991 498.016 274.052 497.323 273.36C496.629 272.669 495.69 272.28 494.71 272.28V272.27Z"
        fill="#003C71"
      />
      <path
        d="M579.88 370.05C588.21 370.05 596.2 366.741 602.09 360.85C607.981 354.96 611.29 346.97 611.29 338.64H548.46C548.463 346.971 551.774 354.961 557.666 360.851C563.558 366.741 571.549 370.05 579.88 370.05Z"
        fill="#E5F0F6"
      />
      <path
        d="M386.17 387.68C394.5 387.68 402.488 384.372 408.38 378.484C414.272 372.596 417.585 364.61 417.59 356.28H354.76C354.763 364.609 358.073 372.595 363.963 378.484C369.854 384.372 377.841 387.68 386.17 387.68Z"
        fill="#E5F0F6"
      />
      <path
        d="M485 340.53C493.33 340.53 501.32 337.221 507.21 331.33C513.101 325.44 516.41 317.451 516.41 309.12H453.54C453.541 313.249 454.356 317.337 455.938 321.15C457.521 324.964 459.839 328.428 462.76 331.345C465.682 334.262 469.15 336.575 472.966 338.151C476.782 339.727 480.871 340.535 485 340.53Z"
        fill="#E5F0F6"
      />
      <path
        d="M451 492.38C459.331 492.38 467.322 489.071 473.214 483.181C479.106 477.291 482.417 469.301 482.42 460.97H419.6C419.6 469.299 422.908 477.287 428.796 483.177C434.685 489.067 442.671 492.377 451 492.38Z"
        fill="#E5F0F6"
      />
      <path
        d="M100.22 342.79C91.3864 342.78 82.9175 339.266 76.671 333.019C70.4246 326.773 66.9107 318.304 66.9001 309.47H69.4201C69.4201 317.639 72.6651 325.473 78.4413 331.249C84.2174 337.025 92.0515 340.27 100.22 340.27C108.389 340.27 116.223 337.025 121.999 331.249C127.775 325.473 131.02 317.639 131.02 309.47H133.54C133.53 318.304 130.016 326.773 123.769 333.019C117.523 339.266 109.054 342.78 100.22 342.79Z"
        fill="#003C71"
      />
      <path
        d="M164.34 342.79C155.503 342.785 147.029 339.273 140.778 333.026C134.527 326.779 131.011 318.307 131 309.47H133.52C133.665 317.544 136.975 325.238 142.736 330.896C148.497 336.555 156.25 339.725 164.325 339.725C172.4 339.725 180.153 336.555 185.914 330.896C191.675 325.238 194.985 317.544 195.13 309.47H197.64C197.632 318.301 194.122 326.769 187.879 333.015C181.636 339.261 173.171 342.777 164.34 342.79Z"
        fill="#003C71"
      />
      <path
        d="M228.47 342.79C219.636 342.782 211.165 339.269 204.918 333.022C198.671 326.775 195.158 318.305 195.15 309.47H197.66C197.806 317.544 201.115 325.238 206.876 330.896C212.638 336.555 220.39 339.725 228.465 339.725C236.54 339.725 244.293 336.555 250.054 330.896C255.815 325.238 259.125 317.544 259.27 309.47H261.79C261.78 318.304 258.266 326.773 252.019 333.019C245.773 339.266 237.304 342.78 228.47 342.79Z"
        fill="#003C71"
      />
      <path
        d="M292.59 342.79C283.756 342.78 275.287 339.266 269.041 333.019C262.795 326.773 259.281 318.304 259.27 309.47H261.79C261.79 317.639 265.035 325.473 270.811 331.249C276.587 337.025 284.421 340.27 292.59 340.27C300.759 340.27 308.593 337.025 314.369 331.249C320.145 325.473 323.39 317.639 323.39 309.47H325.91C325.899 318.304 322.386 326.773 316.139 333.019C309.893 339.266 301.424 342.78 292.59 342.79Z"
        fill="#003C71"
      />
      <path
        d="M356.71 342.79C347.876 342.78 339.407 339.266 333.161 333.019C326.915 326.773 323.401 318.304 323.39 309.47H325.91C325.91 317.639 329.155 325.473 334.931 331.249C340.707 337.025 348.541 340.27 356.71 340.27C364.879 340.27 372.713 337.025 378.489 331.249C384.265 325.473 387.51 317.639 387.51 309.47H390C389.99 318.299 386.48 326.763 380.24 333.009C374 339.254 365.539 342.772 356.71 342.79Z"
        fill="#003C71"
      />
      <path
        d="M420.83 342.79C411.996 342.78 403.527 339.266 397.281 333.019C391.034 326.773 387.521 318.304 387.51 309.47H390C390.145 317.544 393.455 325.238 399.216 330.896C404.977 336.555 412.73 339.725 420.805 339.725C428.88 339.725 436.633 336.555 442.394 330.896C448.155 325.238 451.465 317.544 451.61 309.47H454.12C454.112 318.299 450.603 326.765 444.363 333.012C438.122 339.258 429.659 342.774 420.83 342.79Z"
        fill="#003C71"
      />
      <path
        d="M485 342.79C476.166 342.782 467.695 339.269 461.448 333.022C455.201 326.775 451.688 318.305 451.68 309.47H454.19C454.336 317.544 457.645 325.238 463.406 330.896C469.168 336.555 476.92 339.725 484.995 339.725C493.07 339.725 500.823 336.555 506.584 330.896C512.345 325.238 515.655 317.544 515.8 309.47H518.32C518.31 318.304 514.796 326.773 508.549 333.019C502.303 339.266 493.834 342.78 485 342.79Z"
        fill="#003C71"
      />
      <path
        d="M549.08 342.79C540.246 342.78 531.777 339.266 525.531 333.019C519.284 326.773 515.771 318.304 515.76 309.47H518.28C518.28 317.639 521.525 325.473 527.301 331.249C533.077 337.025 540.911 340.27 549.08 340.27C557.249 340.27 565.083 337.025 570.859 331.249C576.635 325.473 579.88 317.639 579.88 309.47H582.4C582.389 318.304 578.876 326.773 572.629 333.019C566.383 339.266 557.914 342.78 549.08 342.79Z"
        fill="#003C71"
      />
      <path
        d="M613.2 342.79C604.366 342.78 595.897 339.266 589.651 333.019C583.405 326.773 579.891 318.304 579.88 309.47H582.4C582.4 317.639 585.645 325.473 591.421 331.249C597.197 337.025 605.031 340.27 613.2 340.27C621.369 340.27 629.203 337.025 634.979 331.249C640.755 325.473 644 317.639 644 309.47H646.52C646.512 318.305 642.999 326.775 636.752 333.022C630.505 339.269 622.035 342.782 613.2 342.79Z"
        fill="#003C71"
      />
      <path
        d="M66.8901 389.59C58.058 389.579 49.5906 386.067 43.3445 379.823C37.0984 373.578 33.5833 365.112 33.5701 356.28H36.0901C36.2355 364.354 39.545 372.048 45.3062 377.706C51.0675 383.365 58.8199 386.535 66.8951 386.535C74.9703 386.535 82.7227 383.365 88.4839 377.706C94.2451 372.048 97.5546 364.354 97.7001 356.28H100.21C100.199 365.113 96.6853 373.581 90.4386 379.826C84.1919 386.07 75.7229 389.582 66.8901 389.59Z"
        fill="#003C71"
      />
      <path
        d="M131 389.59C122.171 389.577 113.707 386.063 107.464 379.819C101.222 373.574 97.7105 365.109 97.7 356.28H100.21C100.355 364.354 103.665 372.048 109.426 377.706C115.187 383.365 122.94 386.535 131.015 386.535C139.09 386.535 146.843 383.365 152.604 377.706C158.365 372.048 161.674 364.354 161.82 356.28H164.34C164.327 365.116 160.809 373.585 154.558 379.83C148.308 386.075 139.835 389.585 131 389.59Z"
        fill="#003C71"
      />
      <path
        d="M195.14 389.59C186.308 389.579 177.841 386.067 171.594 379.823C165.348 373.578 161.833 365.112 161.82 356.28H164.34C164.34 364.449 167.585 372.283 173.361 378.059C179.137 383.835 186.971 387.08 195.14 387.08C203.309 387.08 211.143 383.835 216.919 378.059C222.695 372.283 225.94 364.449 225.94 356.28H228.46C228.447 365.112 224.932 373.578 218.686 379.823C212.44 386.067 203.972 389.579 195.14 389.59Z"
        fill="#003C71"
      />
      <path
        d="M259.26 389.59C250.428 389.579 241.96 386.067 235.714 379.823C229.468 373.578 225.953 365.112 225.94 356.28H228.46C228.46 364.449 231.705 372.283 237.481 378.059C243.257 383.835 251.091 387.08 259.26 387.08C267.429 387.08 275.263 383.835 281.039 378.059C286.815 372.283 290.06 364.449 290.06 356.28H292.58C292.567 365.112 289.052 373.578 282.806 379.823C276.559 386.067 268.092 389.579 259.26 389.59Z"
        fill="#003C71"
      />
      <path
        d="M387.51 389.59C378.677 389.582 370.208 386.07 363.961 379.826C357.715 373.581 354.201 365.113 354.19 356.28H356.71C356.71 364.449 359.955 372.283 365.731 378.059C371.507 383.835 379.341 387.08 387.51 387.08C395.679 387.08 403.513 383.835 409.289 378.059C415.065 372.283 418.31 364.449 418.31 356.28H420.83C420.817 365.112 417.302 373.578 411.056 379.823C404.809 386.067 396.342 389.579 387.51 389.59Z"
        fill="#003C71"
      />
      <path
        d="M451.63 389.59C442.798 389.579 434.331 386.067 428.084 379.823C421.838 373.578 418.323 365.112 418.31 356.28H420.83C420.83 364.449 424.075 372.283 429.851 378.059C435.627 383.835 443.461 387.08 451.63 387.08C459.799 387.08 467.633 383.835 473.409 378.059C479.185 372.283 482.43 364.449 482.43 356.28H485C484.987 365.121 481.465 373.595 475.208 379.84C468.951 386.086 460.471 389.593 451.63 389.59Z"
        fill="#003C71"
      />
      <path
        d="M515.75 389.59C506.918 389.579 498.451 386.067 492.205 379.823C485.958 373.578 482.443 365.112 482.43 356.28H485C485 360.325 485.797 364.33 487.345 368.067C488.893 371.804 491.161 375.199 494.021 378.059C496.881 380.919 500.277 383.188 504.014 384.736C507.75 386.283 511.755 387.08 515.8 387.08C519.845 387.08 523.85 386.283 527.587 384.736C531.324 383.188 534.719 380.919 537.579 378.059C540.439 375.199 542.708 371.804 544.256 368.067C545.804 364.33 546.6 360.325 546.6 356.28H549.12C549.11 365.122 545.589 373.597 539.331 379.843C533.073 386.09 524.592 389.595 515.75 389.59Z"
        fill="#003C71"
      />
      <path
        d="M579.87 389.59C571.038 389.579 562.571 386.067 556.324 379.823C550.078 373.578 546.563 365.112 546.55 356.28H549.07C549.216 364.354 552.525 372.048 558.286 377.706C564.047 383.365 571.8 386.535 579.875 386.535C587.95 386.535 595.703 383.365 601.464 377.706C607.225 372.048 610.535 364.354 610.68 356.28H613.2C613.187 365.114 609.67 373.582 603.422 379.826C597.174 386.071 588.704 389.582 579.87 389.59Z"
        fill="#003C71"
      />
      <path
        d="M100.22 436.39C91.3864 436.379 82.9175 432.866 76.671 426.619C70.4246 420.373 66.9107 411.904 66.9001 403.07H69.4201C69.4201 411.239 72.6651 419.073 78.4413 424.849C84.2174 430.625 92.0515 433.87 100.22 433.87C108.389 433.87 116.223 430.625 121.999 424.849C127.775 419.073 131.02 411.239 131.02 403.07H133.54C133.53 411.904 130.016 420.373 123.769 426.619C117.523 432.866 109.054 436.379 100.22 436.39Z"
        fill="#003C71"
      />
      <path
        d="M164.34 436.39C155.503 436.385 147.029 432.873 140.778 426.626C134.527 420.379 131.011 411.907 131 403.07H133.52C133.665 411.144 136.975 418.838 142.736 424.496C148.497 430.155 156.25 433.325 164.325 433.325C172.4 433.325 180.153 430.155 185.914 424.496C191.675 418.838 194.985 411.144 195.13 403.07H197.64C197.632 411.901 194.122 420.369 187.879 426.615C181.636 432.861 173.171 436.377 164.34 436.39Z"
        fill="#003C71"
      />
      <path
        d="M292.59 436.39C283.756 436.379 275.287 432.866 269.041 426.619C262.795 420.373 259.281 411.904 259.27 403.07H261.79C261.79 411.239 265.035 419.073 270.811 424.849C276.587 430.625 284.421 433.87 292.59 433.87C300.759 433.87 308.593 430.625 314.369 424.849C320.145 419.073 323.39 411.239 323.39 403.07H325.91C325.899 411.904 322.386 420.373 316.139 426.619C309.893 432.866 301.424 436.379 292.59 436.39Z"
        fill="#003C71"
      />
      <path
        d="M356.71 436.39C347.876 436.379 339.407 432.866 333.161 426.619C326.915 420.373 323.401 411.904 323.39 403.07H325.91C325.91 411.239 329.155 419.073 334.931 424.849C340.707 430.625 348.541 433.87 356.71 433.87C364.879 433.87 372.713 430.625 378.489 424.849C384.265 419.073 387.51 411.239 387.51 403.07H390C389.99 411.899 386.48 420.363 380.24 426.609C374 432.854 365.539 436.372 356.71 436.39Z"
        fill="#003C71"
      />
      <path
        d="M420.83 436.39C411.996 436.379 403.527 432.866 397.281 426.619C391.034 420.373 387.521 411.904 387.51 403.07H390C390.145 411.144 393.455 418.838 399.216 424.496C404.977 430.155 412.73 433.325 420.805 433.325C428.88 433.325 436.633 430.155 442.394 424.496C448.155 418.838 451.465 411.144 451.61 403.07H454.12C454.112 411.899 450.603 420.365 444.363 426.612C438.122 432.858 429.659 436.374 420.83 436.39Z"
        fill="#003C71"
      />
      <path
        d="M485 436.39C476.166 436.382 467.695 432.869 461.448 426.622C455.201 420.375 451.688 411.905 451.68 403.07H454.19C454.336 411.144 457.645 418.838 463.406 424.496C469.168 430.155 476.92 433.325 484.995 433.325C493.07 433.325 500.823 430.155 506.584 424.496C512.345 418.838 515.655 411.144 515.8 403.07H518.32C518.31 411.904 514.796 420.373 508.549 426.619C502.303 432.866 493.834 436.379 485 436.39Z"
        fill="#003C71"
      />
      <path
        d="M549.08 436.39C540.246 436.379 531.777 432.866 525.531 426.619C519.284 420.373 515.771 411.904 515.76 403.07H518.28C518.28 411.239 521.525 419.073 527.301 424.849C533.077 430.625 540.911 433.87 549.08 433.87C557.249 433.87 565.083 430.625 570.859 424.849C576.635 419.073 579.88 411.239 579.88 403.07H582.4C582.389 411.904 578.876 420.373 572.629 426.619C566.383 432.866 557.914 436.379 549.08 436.39Z"
        fill="#003C71"
      />
    </svg>
  );
};
