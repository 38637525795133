import * as React from "react";

import { theme } from "@byko/lib-styles";

import { CheckboxInput, Label, Switch, Text } from "./styles";

import type { ToggleProps } from "./interface";

export const Toggle = ({
  label = "",
  labelColor = theme.palette.blue.dark,
  checked = false,
  onToggle,
  disabled = false,
  border = false,
  className = "toggle-container",
  ...props
}: ToggleProps): JSX.Element => {
  return (
    <Label className={className} disabled={disabled}>
      <CheckboxInput
        border={border}
        checked={checked}
        disabled={disabled}
        name={label}
        type="checkbox"
        onChange={onToggle}
        {...props}
      />
      <Switch border={border} checked={checked} />
      <Text labelColor={labelColor}>{label}</Text>
    </Label>
  );
};
