/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { httpLink } from "@trpc/client/links/httpLink";

import { getBykoConfig } from "@byko/lib-utils";

import { trpc } from "./base";

import type { BykoProductPage } from "./interfaces";
import type { AppRouter } from "@byko/lib-api-dato-ssr";
import type { TRPCClient } from "@trpc/react";
import type { QueryClient } from "react-query";

interface SlugProps<T> {
  slug: string;
  secret?: string | undefined;
  initialData?: T | undefined;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useProductPageBySlug = <T,>({ slug, secret, initialData }: SlugProps<T>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return trpc.useQuery(["getProduct", { slug, secret }], {
    initialData: initialData ?? undefined,
  });
};

export const getProductPageBySlug =
  (secret?: string, props: { queryClient?: QueryClient; trpcClient: TRPCClient<AppRouter> } | undefined = undefined) =>
  async (slug: string): Promise<BykoProductPage | null> => {
    const fullurl = getBykoConfig("CMS_API_URL");
    try {
      const data = await (
        props?.trpcClient ??
        trpc.createClient({
          links: [httpLink({ url: fullurl })],
        })
      ).query("getProduct", { slug, secret });
      return data ?? null;
    } catch (e) {
      return null;
    }
  };
