/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const SendMessageFilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M45.707 2.29304C45.5682 2.1542 45.3915 2.0593 45.1991 2.02017C45.0067 1.98105 44.807 1.99944 44.625 2.07304L2.62499 19.073C2.44906 19.1442 2.29701 19.264 2.18661 19.4183C2.07621 19.5727 2.01201 19.7553 2.00153 19.9448C1.99104 20.1343 2.03469 20.3229 2.12739 20.4885C2.22008 20.6541 2.35798 20.7899 2.52499 20.88L15 27.6L20.4 33L27.117 45.474C27.2028 45.6334 27.3302 45.7665 27.4857 45.8592C27.6412 45.9518 27.819 46.0005 28 46C28.0183 46.001 28.0367 46.001 28.055 46C28.2445 45.9896 28.4271 45.9255 28.5815 45.8152C28.7359 45.7049 28.8557 45.5529 28.927 45.377L45.927 3.37704C46.001 3.19475 46.0196 2.99468 45.9805 2.80186C45.9414 2.60904 45.8462 2.43205 45.707 2.29304Z"
        fill="#E6E7E8"
      />
      <path
        d="M45.707 2.29308C45.5285 2.11464 45.2891 2.01032 45.0368 2.00104C44.7846 1.99177 44.5382 2.07823 44.347 2.24308L15.347 27.2431C15.2382 27.3369 15.1508 27.4531 15.091 27.5837C15.0311 27.7144 15.0001 27.8564 15 28.0001V32.0001C15 32.2653 15.1054 32.5196 15.2929 32.7072C15.4804 32.8947 15.7348 33.0001 16 33.0001H20C20.1439 33.0002 20.2861 32.9692 20.4169 32.9093C20.5477 32.8494 20.6641 32.762 20.758 32.6531L45.758 3.65308C45.9227 3.4618 46.009 3.21531 45.9995 2.96306C45.9901 2.71081 45.8856 2.47148 45.707 2.29308Z"
        fill="#AEAEAE"
      />
    </svg>
  );
};
