import styled from "styled-components";

import { theme } from "@byko/lib-styles";
import { scrollStyle } from "@byko/lib-styles";

import type { AccordionContentProps, AccordionRowWrapperProps } from "../../interface";

export const MobileAccordionContent = styled.div<AccordionContentProps>`
  display: flex;
  max-height: ${(props): string => (props.displayContent ? "100%" : "0px")};
  flex-wrap: wrap;
  justify-content: space-between;
  padding: ${(props): string => (props.displayContent ? "10px 6px 30px 6px" : "0px 24px")};
  border-bottom: ${(props): string => (props.displayContent ? "2px" : "1px")} solid
    ${(props): string => (props.displayContent ? theme.palette.blue.main : theme.palette.gray[10])};
  opacity: ${(props): string => (props.displayContent ? "1" : "0")};
  pointer-events: ${(props): string => (props.displayContent ? "initial" : "none")};
`;

export const MobileAccordionRowOuter = styled.div``;

export const MobileAccordionRowWrapper = styled.div<AccordionRowWrapperProps>`
  width: 100%;
  button {
    padding: 10px 0;
    border: none;
    border-top: ${(props): string => (props.active ? "2px" : "1px")} solid
      ${(props): string => (props.active ? theme.palette.blue.main : theme.palette.gray[10])};
    box-shadow: none;
  }
  .accordion-button {
    p {
      color: ${(props): string => (props.active ? theme.palette.blue.main : theme.palette.blue.dark)};
    }
  }
  .accordion-button-sorting {
    justify-content: flex-start;
  }
`;

export const MobileAccordionWrapper = styled.div`
  position: fixed;
  z-index: 10;
  display: flex;
  width: 100%;
  height: 100%;
  max-height: calc(100% - 160px);
  flex-direction: column;
  padding: 0 16px;
  background-color: ${theme.palette.white.main};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  ${scrollStyle({ color: "dark-blue" })}
`;

export const MobileAccordionButtons = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;
  padding: 40px 0;
`;
