import { useCallback } from "react";

import { restApi } from "@byko/lib-api-rest";

import type { AddEmailProps } from "./interface";

export const useAddEmail = (): AddEmailProps => {
  const checkEmailAvailability = useCallback(async (email: string) => {
    try {
      const res = await restApi.usersCheckEmailAvailabilityCreate({ email });

      return res.data.available;
    } catch (err) {
      console.error(err);

      return undefined;
    }
  }, []);

  return {
    checkEmailAvailability,
  };
};
