import React from "react";

import { EyebrowLarge, PMedium } from "@byko/component-typography";
import { AfreidslaIllustration } from "@byko/lib-illustrations";
import { gridTheme, theme } from "@byko/lib-styles";

import { Container, NothingFoundContainer, ResultRows } from "./styles";

import styled from "styled-components";

const DesktopOnly = styled.div`
  display: flex;
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    display: none;
  }
`;

const MobileOnly = styled.div`
  display: none;
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    display: flex;
  }
`;

export const ResultsCountBlock = ({ count }: { count: number }): JSX.Element | null => {
  return (
    <EyebrowLarge lineHeight="100%" textColor={theme.palette.blue.dark}>
      {count < 1 ? (
        <Container>
          <ResultRows>
            <NothingFoundContainer>
              <PMedium>Ekkert fannst</PMedium>
              <DesktopOnly>
                <AfreidslaIllustration size={468} />
              </DesktopOnly>
              <MobileOnly>
                <AfreidslaIllustration size={300} />
              </MobileOnly>
            </NothingFoundContainer>
          </ResultRows>
        </Container>
      ) : (
        <>
          {count} {count === 1 ? "VARA FANNST" : "VÖRUR FUNDUST"}
        </>
      )}
    </EyebrowLarge>
  );
};
