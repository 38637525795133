import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const DocumentLineDecorative: BykoReactDecorativeIconComponentType = ({ size, color = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M38 8H6V46H38V8Z" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M10 2H44V42" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M14 18H30" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M14 26H30" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M14 34H22" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
    </svg>
  );
};
