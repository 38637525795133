import { atom } from "@byko/lib-recoil";

export enum LoginStepState {
  terms = "Skilmálar",
  email = "Netfang",
  welcome = "Velkomin",
}

export const loginState = atom<LoginStepState>({
  key: "loginState",
  default: LoginStepState.terms,
});
