import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const SettingsToolsLineDecorative: BykoReactDecorativeIconComponentType = ({ size, color = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M38.0001 29.77L41.9891 33.37C42.6029 33.9245 43.0975 34.598 43.4428 35.3496C43.7882 36.1011 43.977 36.9151 43.998 37.742C44.0189 38.5688 43.8715 39.3913 43.5647 40.1594C43.2578 40.9275 42.798 41.6252 42.2131 42.21V42.21C41.6283 42.7949 40.9306 43.2548 40.1625 43.5616C39.3944 43.8684 38.5719 44.0159 37.745 43.9949C36.9182 43.974 36.1042 43.7851 35.3526 43.4397C34.6011 43.0944 33.9276 42.5998 33.3731 41.986L27.9641 36"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path
        d="M22.225 18.69L15 11.464V9L9 3L3 9L9 15H11.464L18.51 22.046"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path
        // eslint-disable-next-line max-len
        d="M44.514 10.391L38.776 16.128L31.876 9.22803L37.613 3.49003C36.2854 3.09872 34.8992 2.94436 33.518 3.03403C30.9689 3.24496 28.5772 4.35328 26.7685 6.16185C24.9597 7.97041 23.8512 10.3619 23.64 12.911C23.5496 14.294 23.7043 15.682 24.097 17.011L3.99998 35.155C3.69571 35.4301 3.45054 35.7641 3.27935 36.1368C3.10816 36.5096 3.01452 36.9132 3.00414 37.3232C2.99376 37.7333 3.06685 38.1411 3.21895 38.522C3.37106 38.9029 3.59902 39.2489 3.88898 39.539L8.46398 44.114C8.75423 44.4033 9.10021 44.6307 9.48095 44.7822C9.86169 44.9338 10.2692 45.0065 10.6789 44.9958C11.0886 44.9852 11.4918 44.8914 11.8641 44.7202C12.2365 44.5491 12.5702 44.304 12.845 44L31 23.907C32.3291 24.2997 33.7171 24.4544 35.1 24.364C37.6491 24.1528 40.0406 23.0443 41.8492 21.2355C43.6577 19.4268 44.7661 17.0351 44.977 14.486C45.0644 13.1044 44.9077 11.7182 44.514 10.391Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
    </svg>
  );
};
