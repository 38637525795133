import * as React from "react";
import { useCallback } from "react";

import { SearchInput } from "@byko/component-inputs";
import { useAuth } from "@byko/hooks-auth-next";
import { useRecoilState, useSetRecoilState } from "@byko/lib-recoil";

import { Border } from "./picker-search-styles";
import { filteredAccountsState, inputValueState, showSuggestionsState } from "./store";
import { SuggestionsList } from "./suggestions-list";

import type { CustomerAccountMembership } from "@byko/lib-api-rest";

export const PickerSearch = (): JSX.Element => {
  const setFilteredAccounts = useSetRecoilState(filteredAccountsState);
  const [showSuggestions, setShowSuggestions] = useRecoilState(showSuggestionsState);
  const [inputValue, setInputValue] = useRecoilState(inputValueState);
  const { memberships } = useAuth();

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setInputValue(e.target.value);
      let unLinked: CustomerAccountMembership[] = [];

      if (memberships) {
        unLinked = memberships.filter(
          (membership): boolean =>
            (membership.account.name || "").toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 ||
            membership.account.ssn.indexOf(e.target.value.toLowerCase()) > -1,
        );
      }

      setFilteredAccounts(unLinked);
      setShowSuggestions(true);
    },
    [setInputValue, setFilteredAccounts, setShowSuggestions, memberships],
  );

  const handleOnClick = useCallback(() => {
    setInputValue("");
    setFilteredAccounts([]);
  }, [setInputValue, setFilteredAccounts]);

  return (
    <Border>
      <SearchInput
        isDirty={Boolean(inputValue)}
        placeholder="Leitaðu eftir nafni eða kennitölu"
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onClick={handleOnClick}
      />
      {showSuggestions && inputValue && <SuggestionsList />}
    </Border>
  );
};
