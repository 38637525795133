import { getAllPages, useGetAllPages } from "./content-queries/get-all-pages";
import { useGetFrontPageBySlug } from "./content-queries/get-front-page-by-slug";
import { useGetFrontPage } from "./content-queries/get-front-page";
import { useGetOpeningHours } from "./content-queries/get-opening-hours";
import { useGetPageByParentPageAndSlug } from "./content-queries/get-page-by-parent-page-and-slug";
import { useGetPageBySlug } from "./content-queries/get-page-by-slug";
import { useGetPagesByParent } from "./content-queries/get-pages-by-parent";
import { useGetProductBySlug } from "./content-queries/get-product-by-slug";
import { useGetStringByKey } from "./content-queries/get-string-by-key";
import { fetchAllEmployees, useAllEmployees } from "./content-queries/get-all-employees";
import { useGetTextOnPage } from "./content-queries/get-text-on-page";
import { useGetFooterText } from "./content-queries/get-text-on-footer";
import { useGetHeaderText } from "./content-queries/get-header-text";
import { useGetEnvironmentalInformation } from "./content-queries/get-environmental-information";

export const datoQueries = {
  useGetAllPages,
  getAllPages,
  useGetFrontPageBySlug,
  useGetFrontPage,
  useGetOpeningHours,
  useGetPageByParentPageAndSlug,
  useGetPageBySlug,
  useGetPagesByParent,
  useGetProductBySlug,
  useGetStringByKey,
  useAllEmployees,
  fetchAllEmployees,
  useGetTextOnPage,
  useGetFooterText,
  useGetHeaderText,
  useGetEnvironmentalInformation,
};
