import styled from "styled-components";

import {
  EyebrowLarge,
  EyebrowMedium,
  H2,
  H6,
  PSmall,
  PSmallBold,
  Subtitle,
  paragraphStyles,
} from "@byko/component-typography";
import { gridTheme, theme } from "@byko/lib-styles";

export const HiddenAttribute = styled.div`
  display: none;
`;

export const ProductDetailContainer = styled.div`
  width: 100%;
  max-width: 544px;
  background-color: ${theme.palette.white.main};
  text-align: left;
  transition: all 0.5s;
`;

export const FlexRow = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 920px) {
    flex-direction: column;
  }
`;

export const Brand = styled(EyebrowLarge)`
  font-weight: 700;
  text-transform: uppercase;
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    font-size: 14px;
  }
`;

export const SkuNumber = styled(EyebrowMedium)`
  color: ${theme.palette.blue.dark};
  text-transform: uppercase;

  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    font-size: 12px;
  }
`;

export const LoadingWrapper = styled.div``;

export const PriceContainer = styled.div`
  min-height: 98px;
`;

export const PriceText = styled(EyebrowLarge)`
  color: ${theme.palette.gray[50]};
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    font-size: 12px;
  }
`;

export const Price = styled(H6)`
  margin-bottom: 30px;
  color: ${theme.palette.blue.main};
`;

export const DiscountedPrice = styled(H6)`
  margin-top: 0px;
  color: ${theme.palette.gray[30]};
  text-decoration: line-through;
`;

export const OriginalPrice = styled(H6)`
  color: ${theme.palette.gray[50]};
  text-decoration: line-through;
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    font-size: 16px;
  }
`;

export const ProductName = styled(H2)`
  margin-top: 40px;
  font-size: 48px;
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    margin-top: 10px;
    font-size: 24px;
  }
`;

export const ProductNumber = styled(Subtitle)`
  margin-top: 10px;
  margin-bottom: 10px;
  color: ${theme.palette.gray[60]};
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    margin-top: 4px;
    font-size: 16px;
  }
`;

export const Descriptions = styled.div`
  ${paragraphStyles}
  overflow: hidden;
  max-height: 210px;
  padding: 10px 0;
  margin-bottom: 30px;
  color: ${theme.palette.blue.dark};
  font-size: 18px;
  transition: all 0.5s;
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    border-top: none;
  }

  &.showMore {
    overflow: visible;
    max-height: none;
  }
`;

export const NoticeBlock = styled.div`
  margin-bottom: 16px;
`;

export const ReadMoreContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    margin-bottom: 20px;
  }
`;

export const ReadMoreButton = styled.button`
  width: 100%;
  max-width: 544px;
  padding: 20px 10px 10px 10px;
  border-bottom: 1px solid ${theme.palette.gray[20]};
  margin-top: -70px;
  background: linear-gradient(0deg, #ffffff 43.5%, rgba(255, 255, 255, 0) 172.45%);
  color: ${theme.palette.gray[50]};
  font-size: 12px;
  text-transform: uppercase;
  svg {
    margin-left: 10px;
  }

  &.showMore {
    border-bottom: none;
    margin-top: -20px;
  }
`;

export const CrossInfoBlock = styled.div`
  &:before {
    position: absolute;
    bottom: 3px;
    left: -16px;
    color: ${theme.palette.blue.main};
    content: "=";
  }
  position: relative;
  display: flex;
  width: fit-content;
  height: 24px;

  align-items: center;
  justify-content: center;
  margin-left: 16px;
  transform: translateY(-24px);
  white-space: nowrap;
`;

export const TooltipContainer = styled.div`
  position: absolute;
  top: 4px;
  left: 64px;
  &.short {
    left: 40px;
  }
`;

export const CheckboxContainer = styled.div`
  max-height: 54px;
  margin-top: 16px;
`;

export const CalculatorRow = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: evenly;
  margin-bottom: 16px;
  gap: 16px;

  .desktop-button {
    display: flex;
    margin-bottom: 8px;
  }
  @media screen and (max-width: 1200px) {
    .desktop-button {
      display: none;
    }
  }
`;

export const ActionRow = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: evenly;
  margin-bottom: 48px;
  gap: 16px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .mobile-button {
    display: none;
  }
  @media screen and (max-width: 1200px) {
    .mobile-button {
      display: flex;
    }
  }
`;

export const ButtonFlexRow = styled.div`
  display: flex;
  gap: 16px;
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FavAndQtyButtons = styled.div`
  display: flex;
  gap: 16px;
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    gap: 8px;
  }
`;

export const VariantSelectionOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 16px;
  gap: 16px;
  &.limited-detail {
    justify-content: flex-start;
  }
  .select-container {
    max-width: calc(50% - 8px);
    border: 1px solid ${theme.palette.gray[10]};
  }
  .button-container {
    max-width: calc(50% - 8px);
  }
  @media screen and (max-width: 968px) {
    flex-direction: column;
    margin-top: 8px;
    gap: 8px;
    .select-container {
      width: 100%;
      max-width: unset;
    }
    .button-container {
      width: 100%;
      max-width: unset;
    }
  }
`;

export const AvailabilityContainer = styled.div`
  margin-top: 24px;
`;

export const AvailabilityBlock = styled.div`
  display: grid;
  padding-bottom: 16px;
  &.border-bottom {
    border-bottom: 1px solid ${theme.palette.gray[10]};
  }
  margin-bottom: 16px;
  column-gap: 16px;
  grid-template-columns: 114px 1fr;
`;

export const AvailabilityLabel = styled(PSmallBold)``;
export const SoldOutLabel = styled(PSmall)``;

export const LocationList = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 16px;
`;

export const Location = styled(PSmall)`
  position: relative;
  padding-right: 8px;
  @media screen and (min-width: 453px) {
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background-color: ${theme.palette.gray[20]};
      content: "";
    }
  }
`;

export const ButtonLoadingWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffffff;
  gap: 10px;
  text-align: left;
`;

export const EnergyWrapper = styled.div`
  margin-bottom: 32px;
`;
