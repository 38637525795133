import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const PaintRollLineDecorative: BykoReactDecorativeIconComponentType = ({ size, color = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M41 2H7V14H41V2Z" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
      <path
        d="M2 8V18C2 19.105 2.895 20 4 20H22C23.105 20 24 20.895 24 22V27"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path d="M28 27H20V46H28V27Z" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
      <path
        d="M37 32C37 29.791 41 24 41 24C41 24 45 29.791 45 32C45 34.209 43.209 36 41 36C38.791 36 37 34.209 37 32Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
    </svg>
  );
};
