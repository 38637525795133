import styled from "styled-components";

import { theme } from "@byko/lib-styles";

import type { ButtonProps } from "./interface";

export const DropdownContainer = styled.div`
  .select-container {
    width: 100%;
    min-width: 200px;
    max-width: 320px;
    outline: 1px solid ${theme.palette.gray[10]};

    p {
      color: ${theme.palette.blue.dark};
    }
  }
`;

export const MobileSortingBlockContainer = styled.div`
  position: relative;
  top: 56px;
  width: 100%;
  margin-bottom: 56px;
  @media screen and (min-width: 581px) {
    display: none;
  }
`;
export const ButtonWrapper = styled.div<ButtonProps>`
  position: relative;
  display: flex;
  .accordian-filter {
    border-bottom: 4px solid ${(props): string => (props.openAccordianFilter ? ` ${theme.palette.blue.main}` : "none")};
    :hover {
      box-shadow: ${(props): string =>
        props.openAccordianFilter ? "none" : " drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1))"};
    }
  }
  .sorting-button {
    border-bottom: 4px solid ${(props): string => (props.openSorting ? ` ${theme.palette.blue.main}` : "none")};
    :hover {
      box-shadow: ${(props): string => (props.openSorting ? "none" : " drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1))")};
    }
  }
`;
