import React, { useCallback } from "react";

import { Button } from "@byko/component-buttons";
import { AddIcons, SubtractIcons } from "@byko/lib-icons";

import { MobileAccordionContent, MobileAccordionRowOuter, MobileAccordionRowWrapper } from "./styles";

import type { AccordionRowProps } from "../../interface";

export const MobileAccordionRow = ({ configuration, active, toggleActive }: AccordionRowProps): JSX.Element => {
  const handleClick = useCallback(() => {
    if (typeof configuration.id === "undefined" || configuration.id == null) {
      return;
    }
    toggleActive(configuration.id);
  }, [configuration.id, toggleActive]);

  return (
    <MobileAccordionRowOuter>
      <MobileAccordionRowWrapper active={active}>
        <Button
          buttonColor="whiteButton"
          customClassName="accordion-button"
          icon={active ? SubtractIcons : AddIcons}
          label={configuration.label}
          stretch={true}
          onClick={handleClick}
        />
      </MobileAccordionRowWrapper>
      <MobileAccordionContent displayContent={active}>{configuration.content}</MobileAccordionContent>
    </MobileAccordionRowOuter>
  );
};
