import * as React from "react";

import { isNotNull } from "@byko/types-utils";

import type { ContentSlideProps, ImageSlideProps, SliderProps, VideoSlideProps } from "./interface";

import { ContentSlide, ImageSlide, VideoSlide } from "./index";

const SlideConvert = {
  image: (props: ImageSlideProps): JSX.Element => <ImageSlide alt={props.alt} height={props.height} src={props.src} />,
  video: (props: VideoSlideProps): JSX.Element => <VideoSlide height={props.height} src={props.src} />,
  content: (props: ContentSlideProps): JSX.Element => <ContentSlide content={props.content} />,
} as const;

export const Slides = ({ collection }: SliderProps): JSX.Element[] => {
  return collection
    .map((item) => {
      const fn = SlideConvert[item.type];
      if (!fn) {
        return null;
      }
      // We know that this is the correct type.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return fn(item as any);
    })
    .filter(isNotNull);
};
