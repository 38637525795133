/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const TreeFilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 39C16.103 39 11 34.6821 11 28C11 17.874 22.7383 3.94092 23.2378 3.35254L24 2.45557L24.7622 3.35254C25.2617 3.94092 37 17.874 37 28C37 34.6821 31.897 39 24 39Z"
        fill="#52C6A8"
      />
      <path
        d="M28.293 27.293L25 30.5859V19C25 18.4477 24.5522 18 24 18C23.4477 18 23 18.4477 23 19V25.5859L19.707 22.293C19.3164 21.9024 18.6836 21.9024 18.293 22.293C17.9023 22.6836 17.9023 23.3164 18.293 23.707L23 28.4141V46C23 46.5522 23.4477 47 24 47C24.5522 47 25 46.5522 25 46V33.4141L29.707 28.707C30.0976 28.3164 30.0976 27.6836 29.707 27.293C29.3164 26.9024 28.6836 26.9023 28.293 27.293Z"
        fill="#008F69"
      />
      <path
        d="M34 47H14C13.4477 47 13 46.5523 13 46C13 45.4477 13.4477 45 14 45H34C34.5522 45 35 45.4477 35 46C35 46.5523 34.5522 47 34 47Z"
        fill="#FFB784"
      />
    </svg>
  );
};
