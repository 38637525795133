import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const PaintBrushLineDecorative: BykoReactDecorativeIconComponentType = ({ size, color = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.7491 39.879L44.9291 25.699L40.6861 21.456L31.0001 24L33.5771 14.423L22.2631 3.10901L8.12109 17.251"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path
        // eslint-disable-next-line max-len
        d="M8.12099 17.251L6.70699 18.665C4.36399 21.008 4.36399 24.807 6.70699 27.15L10.95 31.393L3.17199 39.171C1.60999 40.733 1.60999 43.266 3.17199 44.828C4.73399 46.39 7.26699 46.39 8.82899 44.828L16.607 37.05L20.85 41.293C23.193 43.636 26.992 43.636 29.335 41.293L30.749 39.879L8.12099 17.251Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
    </svg>
  );
};
