/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const ResidenceSimpleIcons: BykoReactIconComponentType = ({ size, iconColor = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M8 4.69995V8.69995" stroke={iconColor} strokeLinecap="square" strokeWidth="1.5" />
      <path d="M6 6.69995H10" stroke={iconColor} strokeLinecap="square" strokeWidth="1.5" />
      <path
        d="M13.5 6.6C13.5 11.2667 8 15 8 15C8 15 2.5 11.2667 2.5 6.6C2.5 5.11479 3.07946 3.69041 4.11091 2.6402C5.14236 1.59 6.54131 1 8 1C9.45869 1 10.8576 1.59 11.8891 2.6402C12.9205 3.69041 13.5 5.11479 13.5 6.6V6.6Z"
        stroke={iconColor}
        strokeLinecap="square"
        strokeWidth="1.5"
      />
    </svg>
  );
};
