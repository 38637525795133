import React, { useEffect, useMemo, useState } from "react";

import { theme } from "@byko/lib-styles";
import { analytics } from "@byko/lib-analytics";

import { Brand, Descriptions, EnergyWrapper, FlexRow, ProductDetailContainer, ProductName, SkuNumber } from "./styles";

import type { ProductDetail as ProductDetailType, VariantDetail } from "@byko/lib-api-rest";
import type { Maybe } from "@byko/types-utils";

export interface ProductCustomDetailProps {
  product: ProductDetailType;
  brand?: Maybe<string>;
  activeVariant: VariantDetail;
  EnergyLabel?: React.FC | null;
}

export const ProductCustomDetail = ({
  brand,
  product,
  activeVariant,
  EnergyLabel = null,
}: ProductCustomDetailProps): JSX.Element => {
  const [firedAnalyticsImpression, setFiredAnalyticsImpression] = useState<boolean>(false);

  const descriptionHTML = useMemo(
    () => ({
      __html: product?.description?.is ?? "",
    }),
    [product?.description?.is],
  );

  useEffect(() => {
    if (!firedAnalyticsImpression) {
      setFiredAnalyticsImpression(true);
      let price = "0";
      try {
        price = activeVariant?.price?.gross?.toString() ?? "0";
      } catch (e) {
        // silent
      }
      analytics.addProductPageViewImpression(product, price);
    }
  }, [activeVariant?.price?.gross, firedAnalyticsImpression, product]);

  return (
    <ProductDetailContainer>
      <FlexRow>
        <Brand>{brand ?? ""}</Brand>
        <SkuNumber>VNR. {activeVariant?.sku}</SkuNumber>
      </FlexRow>

      <ProductName fontWeight="400" maxFontSize="48px" textColor={theme.palette.blue.dark} uppercase={true}>
        {product?.name}
      </ProductName>

      <Descriptions className="showMore" dangerouslySetInnerHTML={descriptionHTML} />
      {EnergyLabel && (
        <EnergyWrapper>
          <EnergyLabel />
        </EnergyWrapper>
      )}
    </ProductDetailContainer>
  );
};
