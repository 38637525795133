import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const SettingsGearLineDecorative: BykoReactDecorativeIconComponentType = ({ size, color = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M24 31C27.866 31 31 27.866 31 24C31 20.134 27.866 17 24 17C20.134 17 17 20.134 17 24C17 27.866 20.134 31 24 31Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path
        // eslint-disable-next-line max-len
        d="M40 24C39.998 22.8478 39.872 21.6992 39.624 20.574L45.052 16.464L41.052 9.536L34.766 12.188C33.0639 10.6271 31.0435 9.45413 28.844 8.75L28 2H20L19.156 8.75C16.9565 9.45413 14.9361 10.6271 13.234 12.188L6.948 9.536L2.948 16.464L8.376 20.574C7.87501 22.8305 7.87501 25.1695 8.376 27.426L2.948 31.536L6.948 38.464L13.234 35.812C14.9361 37.3729 16.9565 38.5459 19.156 39.25L20 46H28L28.844 39.25C31.0435 38.5459 33.0639 37.3729 34.766 35.812L41.052 38.464L45.052 31.536L39.624 27.426C39.872 26.3008 39.998 25.1522 40 24V24Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
    </svg>
  );
};
