/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import React, { useEffect, useState } from "react";

import { theme } from "@byko/lib-styles";

import { HeaderButton, HeaderRow, SubContentHeader, SubNavigationOptionsColumn } from "./styles";
import { SubNavigationOptionRow } from "./sub-navigation-option-row";

import type { SubNavigationOptionsProps } from "./interface";
import type { BykoMenu } from "@byko/lib-api-dato-client";
import { LongArrowSideIcons } from "@byko/lib-icons";

export const SubNavigationOptions = ({
  menu,
  activeSubIndex,
  setActiveSubIndex,
  hideBorder,
  startingTabIndex,
  hideHeader,
  goToChildPage,
  navigatingProducts = false,
}: SubNavigationOptionsProps): JSX.Element | null => {
  const [entered, setEntered] = useState<boolean>(false);

  useEffect(() => {
    if (menu === null || menu.children === null || menu.children === undefined || menu.children.length === 0) {
      setEntered(false);
    } else {
      setEntered(true);
    }
  }, [menu]);

  if (menu === null || menu.children === null || menu.children === undefined || menu.children.length === 0) {
    return null;
  }

  const isInitialProductCategory =
    ((navigatingProducts && menu.slug === "voruflokkar") || (navigatingProducts && menu.slug === "bykoleiga")) ?? false;

  return (
    <SubNavigationOptionsColumn
      entered={entered}
      hasSelection={typeof activeSubIndex === "number"}
      hideBorder={hideBorder ?? false}
    >
      {hideHeader !== true && (
        <HeaderRow>
          {(menu.canBeNavigatedToInMegamenu == false || isInitialProductCategory) && (
            <SubContentHeader lineHeight="120%" textAlign="left" textColor={theme.palette.blue.dark}>
              {menu.title}
            </SubContentHeader>
          )}
          {(navigatingProducts === true || menu.canBeNavigatedToInMegamenu == true) &&
            isInitialProductCategory == false && (
              <HeaderButton onClick={(): void => goToChildPage(menu.url)}>
                <SubContentHeader lineHeight="120%" textAlign="left" textColor={theme.palette.blue.dark}>
                  {menu.title}
                </SubContentHeader>
                <LongArrowSideIcons iconColor={theme.palette.blue.dark} size={16} />
              </HeaderButton>
            )}
        </HeaderRow>
      )}
      {menu?.children.map((_menuItem, index) => {
        const menuItem = _menuItem as BykoMenu;
        return (
          <SubNavigationOptionRow
            key={menuItem.slug}
            active={activeSubIndex === index}
            goToPage={(): void => goToChildPage(menuItem.url)}
            index={index}
            isLink={!menuItem.children?.length}
            label={menuItem.title}
            setActive={setActiveSubIndex}
            tabIndex={startingTabIndex + index}
          />
        );
      })}
    </SubNavigationOptionsColumn>
  );
};
