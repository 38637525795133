import React, { useCallback, useMemo, useState } from "react";

import { Card } from "@byko/component-cards";
import { Select } from "@byko/component-select";
import { theme } from "@byko/lib-styles";
import { useWindowSize } from "@byko/lib-styles";

import { Tab } from "./components";
import { Container, Content, Tabs } from "./styles";

import type { CardContentTabsProps, TabConfiguration } from "./interface";

export const CardContentTabs = ({ tabCollection, defaultIndex }: CardContentTabsProps): JSX.Element => {
  const { activeBreakPoint } = useWindowSize();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(defaultIndex ?? 0);
  const localTabCollection = useMemo((): TabConfiguration[] => {
    return tabCollection.map((tab, index) => {
      return {
        ...tab,
        index,
        active: index === activeTabIndex,
      };
    });
  }, [activeTabIndex, tabCollection]);

  const activeItem = useMemo((): TabConfiguration | undefined => {
    return localTabCollection.find((item) => {
      return item.active;
    });
  }, [localTabCollection]);

  const activeContent = useMemo((): JSX.Element | null => {
    return activeItem?.content ?? null;
  }, [activeItem]);

  const handleDropdownSelect = useCallback(
    (value: { selectedItem?: TabConfiguration | null | undefined } | null | undefined) => {
      if (!value || !value.selectedItem) {
        return;
      }
      setActiveTabIndex(value.selectedItem.index ?? 0);
    },
    [],
  );

  const handleItemToString = useCallback((option: TabConfiguration | null): string => option?.label ?? "", []);

  return (
    <Card>
      <Container>
        <Tabs>
          {["lg", "md", "sm"].includes(activeBreakPoint) &&
            localTabCollection.map((item) => {
              return (
                <Tab
                  key={item.label}
                  active={item.active ?? false}
                  highlightColor={item.highlightColor ?? theme.palette.blue.main}
                  icon={item.icon}
                  index={item.index ?? -1}
                  label={item.label}
                  setActive={setActiveTabIndex}
                />
              );
            })}
          {["s", "xs"].includes(activeBreakPoint) && (
            <Select
              align="left"
              fit={true}
              handleSelectedItemChange={handleDropdownSelect}
              items={localTabCollection}
              itemToString={handleItemToString}
              placeholder={activeItem?.label}
              selectedItem={activeItem}
            />
          )}
        </Tabs>
        <Content>{activeContent}</Content>
      </Container>
    </Card>
  );
};
