import { useCallback, useEffect, useMemo } from "react";

import { useAuth } from "@byko/hooks-auth-next";
import { useQueryWithPollAndRefetchOnUserStateChange } from "@byko/lib-apollo-helpers";

import { AnonymousCheckoutByTokenDocument, MyCheckoutDocument } from "../../../generated/graphql";
import { POLL_CHECKOUT } from "../const";
import { Store } from "../store/atom";
import { useAnonymousToken } from "./use-anonymous-token";
import { useSetCheckoutValue } from "./use-checkout";
import { useCheckoutState, useSetCheckoutStateValue } from "./use-checkout-state";
import { useRecoilValue } from "@byko/lib-recoil";
import type {
  AnonymousCheckoutByTokenQuery,
  AnonymousCheckoutByTokenQueryVariables,
  CheckoutFragment,
  CheckoutLine,
  Exact,
  MyCheckoutQuery,
  MyCheckoutQueryVariables,
} from "../../../generated/graphql";
import type { ApolloQueryResult } from "@apollo/client";
import type { Maybe } from "@byko/types-utils";

interface GetCheckout {
  checkoutReady: boolean;
  checkoutLine: CheckoutLine[];
  update: () => Promise<CheckoutFragment | null>;
  anonCheckoutQuery: AnonymousCheckoutByTokenQuery | undefined;
  myCheckoutQuery: MyCheckoutQuery | undefined;
  myCheckoutLoading: boolean;
  anonCheckoutLoading: boolean;
  refetch: () => void;
  refetchMyCheckout: (
    variables?: Partial<
      Exact<{
        [key: string]: never;
      }>
    >,
  ) => Promise<ApolloQueryResult<MyCheckoutQuery>>;
  refetchCheckout: (
    variables?: Partial<
      Exact<{
        token: Maybe<string>;
      }>
    >,
  ) => Promise<ApolloQueryResult<AnonymousCheckoutByTokenQuery>>;
}

export const useGetCheckout = ({
  shouldForceReloadOnInit = true,
}: { shouldForceReloadOnInit?: boolean } = {}): GetCheckout => {
  const { user, loading } = useAuth();
  const showLogin = !loading;
  const { token: anonymousToken } = useAnonymousToken();
  const checkout = useRecoilValue(Store.checkout);
  const setCheckoutValue = useSetCheckoutValue();
  const currentState = useCheckoutState();
  const setState = useSetCheckoutStateValue();

  const {
    data: anonCheckoutQuery,
    loading: anonCheckoutLoading,
    refetch: refetchCheckout,
  } = useQueryWithPollAndRefetchOnUserStateChange<
    AnonymousCheckoutByTokenQuery,
    AnonymousCheckoutByTokenQueryVariables
  >(
    "AnonymousCheckoutByToken",
    AnonymousCheckoutByTokenDocument,
    {
      skip: !anonymousToken,
      variables: {
        token: anonymousToken,
      },
      allowUpdate: currentState !== "LOADING",
      nextFetchPolicy: "no-cache",
      errorPolicy: "all",
      pollInterval: POLL_CHECKOUT,
    },
    [currentState, anonymousToken],
  );

  const {
    data: myCheckoutQuery,
    loading: myCheckoutLoading,
    refetch: refetchMyCheckout,
  } = useQueryWithPollAndRefetchOnUserStateChange<MyCheckoutQuery, MyCheckoutQueryVariables>(
    "MyCheckout",
    MyCheckoutDocument,
    {
      skip: !user,
      allowUpdate: currentState !== "LOADING",
      errorPolicy: "all",
      nextFetchPolicy: "no-cache",
      pollInterval: POLL_CHECKOUT,
    },
    [currentState, user],
  );

  const handleUpdate = useCallback(async (): Promise<CheckoutFragment | null> => {
    if (checkout !== null) {
      return null;
    }
    if (user) {
      const value = await refetchMyCheckout();
      const myCheckout = value.data.me?.checkout ?? null;
      setCheckoutValue(myCheckout);
      return myCheckout ?? null;
    }
    if (anonymousToken) {
      const value = await refetchCheckout();
      const anonCheckout = value.data.checkout ?? null;
      setCheckoutValue(anonCheckout);

      return anonCheckout;
    }
    setCheckoutValue(null);
    return null;
  }, [anonymousToken, refetchCheckout, refetchMyCheckout, setCheckoutValue, user, checkout]);

  useEffect(() => {
    if (currentState === "LOADING") {
      return;
    }
    handleUpdate();
  }, [myCheckoutQuery, anonCheckoutQuery, anonymousToken, user, handleUpdate, currentState]);

  const handleInitialLoad = useCallback(async () => {
    await handleUpdate();
    setState("READY");
  }, [handleUpdate, setState]);

  useEffect(() => {
    if (!shouldForceReloadOnInit) {
      return;
    }
    handleInitialLoad();
  }, [handleInitialLoad, shouldForceReloadOnInit]);
  const checkoutReady = useMemo(() => {
    if (!showLogin) {
      return false;
    }
    if (user) {
      return myCheckoutQuery?.me?.checkout != null;
    }
    return anonCheckoutQuery?.checkout != null;
  }, [anonCheckoutQuery?.checkout, myCheckoutQuery?.me?.checkout, showLogin, user]);
  const refetch = useCallback(async () => {
    if (user) {
      await refetchMyCheckout();
      return;
    }
    await refetchCheckout();
  }, [refetchCheckout, refetchMyCheckout, user]);
  const checkoutLine = useMemo(() => {
    if (showLogin) {
      return [];
    }
    if (!user) {
      return anonCheckoutQuery?.checkout?.lines ?? [];
    }
    return myCheckoutQuery?.me?.checkout?.lines ?? [];
  }, [showLogin, user, myCheckoutQuery?.me?.checkout?.lines, anonCheckoutQuery?.checkout?.lines]) as CheckoutLine[];
  return {
    checkoutReady,
    checkoutLine,
    update: handleUpdate,
    refetch,
    refetchCheckout,
    refetchMyCheckout,
    anonCheckoutQuery,
    myCheckoutQuery,
    myCheckoutLoading,
    anonCheckoutLoading,
  };
};
