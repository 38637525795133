/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React, { useCallback } from "react";

import { Checkbox } from "@byko/component-selectors";
import { ArrowDownIcons, ArrowUpIcons } from "@byko/lib-icons";
import { theme } from "@byko/lib-styles";
import { useAlgoliaInsights } from "@byko/lib-algolia";

import { FacetLayout } from "./facet-layout";
import { CheckboxContainer, Count, FacetCheckboxWrapper, IconContainer, Item, Line, Text } from "./styles";
import { flattenFacetTreeValues } from "./utils";
import { useProductSearch } from "src/features/search/context";
import { sortFacetOptions } from "src/features/search/api";

import type { FacetCheckboxItemProps, FacetCheckboxProps } from "./interface";
import type { FC } from "react";
import type { FacetTreeItem } from "src/features/search/api";
import type { ChoiceFacetProps } from "src/features/search/types";

const FacetCheckboxItem = ({ count, children, onChange, checked, value }: FacetCheckboxItemProps): JSX.Element => {
  return (
    <Item>
      <Text>
        {children}
        <Count style={{ opacity: count ? "1" : "0" }}>({count})</Count>
      </Text>
      <CheckboxContainer>
        <Checkbox //
          checked={checked ?? false}
          smallBox={true}
          value={value as string | number}
          onChange={onChange}
        />
      </CheckboxContainer>
    </Item>
  );
};

export const FacetCheckbox = ({ option, onChange, level }: FacetCheckboxProps): JSX.Element | null => {
  const { value, count, active, label, children } = option;

  if (children === undefined) {
    return null;
  }

  const childActive = flattenFacetTreeValues(children).some((child) => child.active);
  const shouldBeActive = childActive || active;
  const nextLevel = level + 1;
  return (
    <FacetCheckboxWrapper padding={level > 0}>
      <Line>
        <FacetCheckboxItem //
          key={value}
          checked={shouldBeActive}
          count={count}
          value={value}
          onChange={onChange}
        >
          {label}
        </FacetCheckboxItem>
        <IconContainer>
          {children?.length > 0 &&
            (!shouldBeActive ? (
              <ArrowDownIcons iconColor={theme.palette.blue.main} size={16} />
            ) : (
              <ArrowUpIcons iconColor={theme.palette.blue.main} size={16} />
            ))}
        </IconContainer>
      </Line>

      {!!shouldBeActive &&
        children?.map((o: FacetTreeItem) => (
          <FacetCheckbox key={o.value} level={nextLevel} option={o} onChange={onChange} />
        ))}
    </FacetCheckboxWrapper>
  );
};

export const CheckboxFacet: FC<ChoiceFacetProps> = ({ attr, facet }) => {
  const { updateChoiceFacet, facetOrderValues } = useProductSearch();
  const { sendFilterClickedEvent } = useAlgoliaInsights();
  const sortedOptions = sortFacetOptions(attr, facet.options, facetOrderValues);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateChoiceFacet(facet, attr, { [e.target.value]: e.target.checked });

      if (e.target.checked) {
        sendFilterClickedEvent({
          eventName: "Filter clicked",
          filter: `${attr}:${e.target.value}`,
        });
      }
    },
    [, facet, attr, updateChoiceFacet, sendFilterClickedEvent],
  );

  return (
    <FacetLayout label={facet.label}>
      {sortedOptions.map((o) => (
        <FacetCheckboxItem key={o.value} checked={o.active} count={o.count} value={o.value} onChange={onChange}>
          {o.label}
        </FacetCheckboxItem>
      ))}
    </FacetLayout>
  );
};
