import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const DeliveryIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 23" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M9 6.5H18L19 10.5L23 12.5V18.5H17.829" stroke={iconColor} strokeMiterlimit="10" strokeWidth="2" />
      <path d="M9 2.5H1V18.5H9V2.5Z" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path
        // eslint-disable-next-line max-len
        d="M15 22.5C16.6569 22.5 18 21.1569 18 19.5C18 17.8431 16.6569 16.5 15 16.5C13.3431 16.5 12 17.8431 12 19.5C12 21.1569 13.3431 22.5 15 22.5Z"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path d="M14 9.5V11.5H16" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
    </svg>
  );
};
