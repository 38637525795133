import { useRecoilState } from "@byko/lib-recoil";

import { errorMessageState, messagePositionState, successMessageState } from "./store";

import type { UseToastsProps } from "./interface";

export const useToasts = (): UseToastsProps => {
  const [errorMessage, setErrorMessage] = useRecoilState(errorMessageState);
  const [successMessage, setSuccessMessage] = useRecoilState(successMessageState);
  const [messagePosition, setMessagePosition] = useRecoilState(messagePositionState);

  return {
    errorMessage,
    setErrorMessage,
    successMessage,
    setSuccessMessage,
    messagePosition,
    setMessagePosition,
  };
};
