import React from "react";

import { Slider } from "@byko/component-slider";
import { Subtitle } from "@byko/component-typography";

import { VideoSlideWrapper } from "./styles";

import type { VideoSectionProps } from "./interface";

const videoSlideBreakpoints = {
  // when window width is >= 350px
  350: {
    slidesPerView: 1,
    spaceBetween: 10,
  },
  // when window width is >= 450px
  450: {
    slidesPerView: 1.5,
    spaceBetween: 10,
  },
  // when window width is >= 550px
  550: {
    slidesPerView: 2.3,
    spaceBetween: 20,
  },
  // when window width is >= 950px
  950: {
    slidesPerView: 3,
    spaceBetween: 20,
  },
  // when window width is >= 1200px
  1200: {
    slidesPerView: 4,
    spaceBetween: 20,
  },
};

export const VideoSection = ({ videoHeading, videoSlide }: VideoSectionProps): JSX.Element => {
  return (
    <VideoSlideWrapper>
      <Subtitle>{videoHeading ? videoHeading : ""}</Subtitle>
      <Slider breakpoints={videoSlideBreakpoints} slideCollection={videoSlide} />
    </VideoSlideWrapper>
  );
};
