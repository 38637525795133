import styled from "styled-components";

import { gridTheme, handleColorType, theme } from "@byko/lib-styles";

import type { ButtonContainerProps } from "./interface";

export const ButtonContainer = styled.button<ButtonContainerProps>`
  display: inline-flex;
  width: ${(props): string => (props.stretch ? "100%" : "245px")};
  height: ${(p): string => (p.small ? "40px" : p.height ? `${p.height}` : "56px")};
  box-sizing: border-box;
  flex-direction: ${(p): string => (p.iconLeftSide ? "row-reverse" : "row")};
  align-items: center;
  justify-content: ${(p): string => (p.iconLeftSide ? "flex-end" : p.hasIcon ? "space-between" : "center")};
  padding: 12px 24px;
  border: 1px solid ${theme.palette.white.main};
  gap: 10px;
  text-align: left;
  transition: color 0.5s, border-color 0.5s, background-color 0.5s, padding 0.3s;
  p {
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.5px;
    &::selection {
      background-color: unset;
    }
  }

  &:disabled {
    border: 1px solid ${theme.palette.gray[30]};
    background-color: ${theme.palette.white.main};
    pointer-events: none;
    p {
      color: ${theme.palette.gray[30]};
    }
  }

  ${(props): string => handleColorType(props.buttonColor)};

  @media screen and (min-width: ${gridTheme.container.minWidth.s}px) {
    &:hover {
      padding: ${(p): string => (p.hasIcon ? "12px 32px 12px 24px" : "12px 24px")};
    }
  }

  @media screen and (max-width: ${gridTheme.breakpoints.xs}px) {
    height: ${(p): string => (p.small ? "40px" : p.height ? `${p.height}` : "46px")};
    padding: 10px 24px;
    p {
      font-size: 14px;
    }
    &:hover {
      padding: 10px 24px;
    }
  }
`;

export const HrefContainer = styled.a<ButtonContainerProps>`
  display: inline-flex;
  width: ${(props): string => (props.stretch ? "100%" : "245px")};
  height: ${(p): string => (p.small ? "40px" : p.height ? `${p.height}` : "56px")};
  box-sizing: border-box;
  flex-direction: ${(p): string => (p.iconLeftSide ? "row-reverse" : "row")};
  align-items: center;
  justify-content: ${(p): string => (p.iconLeftSide ? "flex-end" : p.hasIcon ? "space-between" : "center")};
  padding: 12px 24px;
  border: 1px solid ${theme.palette.white.main};
  gap: 10px;
  text-align: left;
  transition: color 0.5s, border-color 0.5s, background-color 0.5s, padding 0.3s;
  a,
  p {
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.5px;
    &::selection {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  ${(props): string => handleColorType(props.buttonColor)};

  @media screen and (min-width: ${gridTheme.container.minWidth.s}px) {
    &:hover {
      padding: ${(p): string => (p.hasIcon ? "12px 32px 12px 24px" : "12px 24px")};
    }
  }

  @media screen and (max-width: ${gridTheme.breakpoints.xs}px) {
    height: ${(p): string => (p.small ? "40px" : p.height ? `${p.height}` : "46px")};
    padding: 10px 24px;
    p {
      font-size: 14px;
    }
    &:hover {
      padding: 10px 24px;
    }
  }
`;
