import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const AddIcons: BykoReactIconComponentType = ({ size, iconColor = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M18 11H13V6H11V11H6V13H11V18H13V13H18V11Z" fill={iconColor} />
    </svg>
  );
};
