/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from "react";

import { useCheckoutShippingMethodUpdateMutation } from "../../../generated/graphql";

import type { CheckoutShippingMethodUpdateMutation, MyCheckoutQuery } from "../../../generated/graphql";
import type { FetchResult } from "@apollo/client/link/core/types";
import type { Maybe } from "@byko/types-utils";

interface Props {
  refetch: () => void;
  myCheckoutQuery: Maybe<MyCheckoutQuery>;
}

export const useUpdateShippingMethod = ({
  refetch,
  myCheckoutQuery,
}: Props): ((
  id: string,
) => Promise<FetchResult<CheckoutShippingMethodUpdateMutation, Record<string, any>, Record<string, any>>>) => {
  const [checkoutShippingMethodUpdate] = useCheckoutShippingMethodUpdateMutation({
    onCompleted: () => refetch(),
  });

  return useCallback(
    (id: string) =>
      checkoutShippingMethodUpdate({
        variables: {
          token: myCheckoutQuery?.me?.checkout?.token,
          shippingMethodId: id,
        },
      }).then((res) => res),
    [checkoutShippingMethodUpdate, myCheckoutQuery?.me?.checkout?.token],
  );
};
