import Link from "next/link";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";

import { Button, IconButton } from "@byko/component-buttons";
import { Drawer, drawerState } from "@byko/component-drawer";
import { SearchInput } from "@byko/component-inputs";
import { LoginSteps } from "@byko/component-login";
import { useAuth } from "@byko/hooks-auth-next";
import { useCart, useCheckoutExtra } from "@byko/hooks-cart";
import { useMegamenu } from "@byko/hooks-megamenu";
import {
  BykoMainLogo,
  CancelLargeIcons,
  CartIcons,
  LongArrowSideIcons,
  MenuIcons,
  NotandiIcons,
} from "@byko/lib-icons";
import { analytics } from "@byko/lib-analytics";
import { useRecoilState } from "@byko/lib-recoil";
import { theme } from "@byko/lib-styles";

import { AddToCartToast, Burger, DropdownSelect, MobileSelection } from "./components";
import {
  AddToCartNotifyWrapper,
  CartCount,
  CartCounterWrapper,
  FlexRow,
  IconButtonWrapper,
  Icons,
  LoginWrapper,
  LogoWrapper,
  MegaMenuTriggerWrapper,
  MobileIconButtonWrapper,
  MobileSearchContainer,
  NavBar,
  NavBarWrapper,
  Navigation,
  PositionWrapper,
  SearchInputWrapper,
  Wrapper,
} from "./styles";

import { datoQueries } from "@byko/lib-dato";
import type { HeaderProps } from "./interface";
import type { CheckoutLine, CustomerAccountMembership } from "@byko/lib-api-rest";
import { MiniCart } from "../mini-cart";
import {
  useSearchValue,
  useSetSearchBarHasFocusStore,
  useSetSearchValue,
  useSetShowQuickSearchResultsValue,
} from "../search-provider";

const EMPTY: CustomerAccountMembership[] = [];

export const Header = ({ mockMemberships }: HeaderProps): JSX.Element => {
  const { data } = datoQueries.useGetHeaderText();
  const router = useRouter();
  const { toggleShowMinicart, cartAdditionNotifications } = useCart();
  const [blockOpen, setBlockOpen] = useState<boolean>(false);
  const currentSearchInputValue = useSearchValue();
  const setShowQuickSearch = useSetShowQuickSearchResultsValue();
  const setSearchBarHasFocus = useSetSearchBarHasFocusStore();
  const setCurrentSearchInputValue = useSetSearchValue();
  const [open, setOpen] = useRecoilState<boolean>(drawerState);
  const { setIsOpen } = useMegamenu();
  const { checkoutData, fetchCheckoutData, cartItemCount } = useCheckoutExtra();
  const { memberships, activeMembership, fetchUser, isAuthLoaded, user } = useAuth();

  useQuery([activeMembership?.id, user?.id, "checkoutData"], fetchCheckoutData, {
    enabled: isAuthLoaded,
    refetchOnWindowFocus: false,
  });

  const addToCartNotifyList = useMemo((): CheckoutLine[] => {
    const items: CheckoutLine[] = [];
    checkoutData?.lines.forEach((item) => {
      if (cartAdditionNotifications.includes(item.variant.id)) {
        analytics.addProductToCart(item, item.quantity);
        items.push(item);
      }
    });

    return items;
  }, [cartAdditionNotifications, checkoutData?.lines]);

  const closeAllMenus = useCallback(() => {
    setIsOpen(false);
    setOpen(false);
    toggleShowMinicart(false);
  }, [setIsOpen, setOpen, toggleShowMinicart]);

  useEffect(() => {
    setBlockOpen(true);
    const timeOutID = setTimeout(() => {
      setBlockOpen(false);
    }, 400);
    return () => {
      clearTimeout(timeOutID);
    };
  }, [open]);

  const doSearch = useCallback(() => {
    if (!router.pathname.startsWith("/vorur")) {
      router.push(`/leit?q=${currentSearchInputValue}`);
      setCurrentSearchInputValue("");
      return;
    }
    setCurrentSearchInputValue("");
    router.push(`/vorur?q=${currentSearchInputValue}`);
  }, [currentSearchInputValue, router, setCurrentSearchInputValue]);

  const handleSearchChange = useCallback(
    (ev: React.KeyboardEvent<HTMLInputElement>) => {
      if (ev.key === "Enter") {
        setShowQuickSearch(false);
        if (!router.pathname.startsWith("/vorur")) {
          router.push(`/leit?q=${(ev.target as HTMLInputElement).value}`);
          setCurrentSearchInputValue("");
          return;
        }
        router.push(`/vorur?q=${(ev.target as HTMLInputElement).value}`);
        setCurrentSearchInputValue("");
      }
    },
    [router, setCurrentSearchInputValue, setShowQuickSearch],
  );
  const handleSetSearchValue = useCallback(
    (value: React.ChangeEvent<HTMLInputElement>) => {
      if (value.target.value === "") {
        setShowQuickSearch(false);
      } else {
        setShowQuickSearch(true);
      }
      setCurrentSearchInputValue(value.target.value ?? "");
    },
    [setCurrentSearchInputValue, setShowQuickSearch],
  );

  const toggleMegamenu = useCallback(() => {
    setIsOpen((current) => {
      return !current;
    });
    // login menu / minicart toggle
    setOpen(false);
    toggleShowMinicart(false);
  }, [setIsOpen, setOpen, toggleShowMinicart]);

  useEffect(() => {
    fetchUser().catch((err: unknown) => console.error(err));
  }, [fetchUser]);

  const mockActiveMemberShip = useMemo(() => {
    if (mockMemberships && mockMemberships.length !== 0) {
      return mockMemberships[0];
    }

    return null;
  }, [mockMemberships]);

  const closeDrawer = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const toggleOpen = useCallback(() => {
    if (!open && !blockOpen) {
      setOpen(true);
    }
    setIsOpen(false);
    toggleShowMinicart(false);
  }, [blockOpen, open, setIsOpen, setOpen, toggleShowMinicart]);

  const handleToggleMinicart = useCallback(() => {
    toggleShowMinicart();
    setIsOpen(false);
    setOpen(false);
  }, [setIsOpen, setOpen, toggleShowMinicart]);

  const showQuickSearch = useCallback(() => {
    closeAllMenus();
    setSearchBarHasFocus(true);
    setShowQuickSearch(true);
  }, [closeAllMenus, setSearchBarHasFocus, setShowQuickSearch]);

  const onSearchInputBlur = useCallback(() => {
    setSearchBarHasFocus(false);
  }, [setSearchBarHasFocus]);

  return (
    <>
      <Wrapper>
        <NavBarWrapper>
          <NavBar id="navbar">
            <Navigation>
              <LogoWrapper>
                <Link href="/">
                  <BykoMainLogo width={100} />
                </Link>
              </LogoWrapper>
              <FlexRow>
                <MegaMenuTriggerWrapper>
                  <Button
                    customClassName="menuButton"
                    icon={MenuIcons}
                    label={data?.labelForMegamenuButton ?? "Valmynd"}
                    stretch={true}
                    onClick={toggleMegamenu}
                  />
                </MegaMenuTriggerWrapper>

                <SearchInputWrapper>
                  <SearchInput
                    border={true}
                    placeholder={data?.hintTextForSearchBarLargeScreens ?? "Leita"}
                    value={currentSearchInputValue}
                    onBlur={onSearchInputBlur}
                    onChange={handleSetSearchValue}
                    onFocus={showQuickSearch}
                    onIconClick={doSearch}
                    onKeyDown={handleSearchChange}
                  />
                </SearchInputWrapper>
              </FlexRow>
              <Icons>
                <>
                  <IconButtonWrapper>
                    <IconButton ariaLabel={"karfa"} icon={CartIcons} onClick={handleToggleMinicart} />
                    {cartItemCount >= 1 && (
                      <CartCounterWrapper>
                        <CartCount>{cartItemCount}</CartCount>
                      </CartCounterWrapper>
                    )}
                  </IconButtonWrapper>
                  <PositionWrapper>
                    <MiniCart key={cartItemCount} />
                  </PositionWrapper>
                </>
                <MobileIconButtonWrapper>
                  {activeMembership !== null ? (
                    <DropdownSelect
                      active={activeMembership}
                      fullWidth={true}
                      iconButton={true}
                      options={memberships}
                      onClick={closeDrawer}
                    />
                  ) : (
                    <IconButton
                      ariaLabel={open ? "Loka" : "Innskrá"}
                      buttonColor="whiteButton"
                      customClassName="loginButton"
                      icon={open ? CancelLargeIcons : NotandiIcons}
                      onClick={toggleOpen}
                    />
                  )}
                </MobileIconButtonWrapper>
              </Icons>
            </Navigation>

            <LoginWrapper>
              {mockActiveMemberShip && (
                <DropdownSelect active={mockActiveMemberShip} options={mockMemberships ?? EMPTY} />
              )}
              {activeMembership === null ? (
                <Button
                  buttonColor="blueButton"
                  customClassName="loginButton"
                  icon={open ? CancelLargeIcons : LongArrowSideIcons}
                  label={open ? "Loka" : data?.labelForLoginButton ?? "Innskrá"}
                  stretch={true}
                  onClick={toggleOpen}
                />
              ) : (
                <DropdownSelect active={activeMembership} options={memberships} onClick={closeDrawer} />
              )}
            </LoginWrapper>
            <Burger />
            <MobileSelection mockMemberships={mockMemberships} />
          </NavBar>
          <MobileSearchContainer>
            <SearchInput
              iconColor={theme.palette.gray[20]}
              placeholder={data?.hintTextForSearchBarSmallScreens ?? "Leita"}
              value={currentSearchInputValue}
              onBlur={onSearchInputBlur}
              onChange={handleSetSearchValue}
              onFocus={showQuickSearch}
              onIconClick={doSearch}
              onKeyDown={handleSearchChange}
            />
          </MobileSearchContainer>
        </NavBarWrapper>
      </Wrapper>
      <AddToCartNotifyWrapper>
        {addToCartNotifyList.map(({ variant, id }) => {
          return <AddToCartToast key={id} variant={variant} />;
        })}
      </AddToCartNotifyWrapper>
      <Drawer noCloseButton={true}>
        <LoginSteps />
      </Drawer>
    </>
  );
};
