import styled from "styled-components";

import { PLargeBold } from "@byko/component-typography";
import { theme } from "@byko/lib-styles";

import type {
  ClickAwayBackdropStyleProps,
  LinkIconProps,
  NavigationOptionProps,
  SubNavigationOptionsColumnProps,
} from "./interface";

export const NavigationOption = styled.button<NavigationOptionProps>`
  position: relative;
  display: flex;
  width: fit-content;
  align-items: center;
  padding-bottom: 2px;

  h6 {
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    transition: 200ms;
    @media screen and (min-width: 1625px) {
      font-size: 28px;
    }
  }
  @media screen and (max-height: 640px) and (min-width: 1438px) {
    h6 {
      font-size: 24px;
    }
  }

  &:after {
    position: absolute;
    bottom: 0;
    width: ${(props): string => (props.active ? "100%" : "32px")};
    border-bottom: 2px solid ${theme.palette.yellow.main};
    content: "";
    transition: 300ms;
    @media screen and (max-width: 599px) {
      display: none;
    }
  }
  &:focus,
  &:hover {
    h6 {
      color: ${(props): string => (props.active ? theme.palette.white.main : theme.palette.yellow.main)};
    }
    &:after {
      width: ${(props): string => (props.active ? "100%" : "64px")};
    }
  }

  @media screen and (max-width: 1200px) {
    &:after {
      bottom: -24px;
    }
  }
`;

export const SubNavigationOption = styled.button<NavigationOptionProps>`
  position: relative;
  display: flex;
  width: fit-content;
  align-items: center;
  transition: 300ms;
  p {
    text-align: left;
    @media screen and (min-width: 468px) {
      font-size: 24px !important;
    }
  }
  &:after {
    position: absolute;
    bottom: -8px;
    left: ${(props): string => (props.isLink ? "24px" : "0")};
    width: ${(props): string => (props.active ? "calc(100% + 16px)" : "0px")};
    border-bottom: 2px solid ${(props): string => (props.isLink ? theme.palette.yellow.dark : theme.palette.blue.main)};
    content: "";
    transition: 300ms;
    transition-delay: 120ms;
  }
  &:focus,
  &:hover {
    &:after {
      width: ${(props): string => (props.active || props.isLink ? "100%" : "32px")};
      transition-delay: 0ms;
    }
  }
`;

export const SubNavigationOptionsColumn = styled.div<SubNavigationOptionsColumnProps>`
  position: relative;
  z-index: 3;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 58px;
  padding-right: 64px;
  padding-bottom: 64px;
  padding-left: 48px;
  border-right: ${(props): string => (props.hideBorder ? "0px" : "2px")} solid ${theme.palette.blue.light};
  background-color: ${theme.palette.white.main};
  opacity: ${(props): string => (props.entered ? "1" : "0")};
  overflow-y: scroll;
  row-gap: 32px;
  transform: translate3d(${(props): string => (props.entered ? "0px" : "-100px")}, 0, 0);
  transition: 300ms;
  @media screen and (max-width: 1438px) {
    padding-top: 120px;
  }
  @media screen and (min-width: 1300px) {
    row-gap: 40px;
  }
  @media screen and (max-width: 1200px) {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100vw + 2px);
    padding-top: 131px;
    transform: translate3d(${(props): string => (props.hasSelection ? "-101vw" : "0")}, 0, 0);
  }
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${theme.palette.white.main};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${theme.palette.blue.main};
  }
`;

export const SubContentHeader = styled(PLargeBold)`
  margin-bottom: 12px;
`;

export const LinkIcon = styled.div<LinkIconProps>`
  opacity: ${(props): string => (props.showLinkIcon ? "1" : "0")};
  transform: translate3d(${(props): string => (props.showLinkIcon ? "16px" : "8px")}, 0, 0);
  transition: 300ms;
`;

export const HeaderButton = styled.button`
  display: flex;
  align-items: center;
`;

export const HeaderRow = styled.div`
  position: relative;
  display: grid;
  width: fit-content;
  align-items: center;
  grid-template-columns: auto auto;
  svg {
    transform: translate3d(4px, -7px, 0);
  }
  &:after {
    position: absolute;
    bottom: 6px;
    width: 0px;
    border-bottom: 2px solid ${theme.palette.yellow.dark};
    content: "";
    transition: 300ms;
  }
`;

export const ClickAwayBackdrop = styled.button<ClickAwayBackdropStyleProps>`
  position: absolute;
  z-index: 1;
  display: block;
  backdrop-filter: ${(props): string => (props.blur ? "blur(4px) grayscale(0.4)" : "blur(4px) grayscale(0.4)")};
  cursor: default;
  inset: 0;
  opacity: ${(props): string => (props.blur ? "1" : "0")};
  transition: 400ms;
  transition-delay: 100ms;
`;
