import { getBykoConfig } from "@byko/lib-utils";

export function getConfig() {
  // This is used by some crazy code that I'm not even going to touch
  // FIXME: Leaving this here for now, but slightly altered

  const values = {
    ENV: getBykoConfig("ENV"),
    SALEOR_GQL_URL: getBykoConfig("SALEOR_GQL_URL"),
    SALEOR_BASE_HOST: getBykoConfig("SALEOR_BASE_HOST"),
    CMS_API_URL: getBykoConfig("CMS_API_URL"),
    IMAGES_API_URL: getBykoConfig("IMAGES_API_URL"),
    SITEIMPROV_KEY: getBykoConfig("SITEIMPROV_KEY"),
    KLAVIYO_PUBLIC_API_KEY: getBykoConfig("KLAVIYO_PUBLIC_API_KEY"),
    BASE_URL: getBykoConfig("BASE_URL"),
  };

  const missingKeys = Object.entries(values)
    .filter(([, value]) => !value)
    .map(([key]) => key);
  if (missingKeys.length) {
    missingKeys.forEach((key) => {
      console.error(`Missing env variable: ${key}`);
    });
  }

  return values;
}
