import React, { useCallback, useEffect, useState } from "react";

import { IconButton } from "@byko/component-buttons";
import { Tooltip } from "@byko/component-tooltip";
import { PMedium } from "@byko/component-typography";
import { AttentionIcons, CancelLargeIcons, InfoIcons } from "@byko/lib-icons";
import { theme } from "@byko/lib-styles";

import { AlertContainer, AlertContentContainer, AlertTooltipContainer, CloseIconContainer } from "./styles";

import type { AlertProps } from "./interface";

export const Alert = ({
  text = "",
  alertType = "info",
  minWidth,
  stretch,
  showSuccess,
  setShowSuccess,
  top,
  bottom,
  tooltipText,
  hideClose = false,
}: AlertProps): JSX.Element => {
  const [localShow, setLocalShow] = useState<boolean | undefined>(showSuccess);

  const handleClose = useCallback(() => {
    setShowSuccess && setShowSuccess(false);
    setLocalShow(false);
  }, [setShowSuccess]);

  useEffect(() => {
    setLocalShow(showSuccess);
  }, [showSuccess]);

  return (
    <AlertContainer
      alertType={alertType}
      bottom={bottom ?? ""}
      minWidth={minWidth}
      showSuccess={!!localShow}
      stretch={!!stretch}
      text={text}
      top={top ?? ""}
    >
      {localShow && (
        <AlertContentContainer>
          {alertType === "info" ? (
            <InfoIcons size={24} />
          ) : (
            <AttentionIcons
              iconColor={
                alertType === "attention"
                  ? theme.palette.triggers.attention.main
                  : alertType === "warning"
                  ? theme.palette.triggers.warning.main
                  : theme.palette.triggers.success.main
              }
              size={24}
            />
          )}
          <PMedium textColor={theme.palette.blue.dark}>{text}</PMedium>
          {tooltipText != undefined && (
            <AlertTooltipContainer>
              <Tooltip
                arrowLocation="right"
                // eslint-disable-next-line max-len
                content={tooltipText}
              />
            </AlertTooltipContainer>
          )}
          {!hideClose && (
            <CloseIconContainer>
              <IconButton
                ariaLabel={"Loka"}
                buttonColor="whiteButton"
                icon={CancelLargeIcons}
                small={true}
                onClick={handleClose}
              />
            </CloseIconContainer>
          )}
        </AlertContentContainer>
      )}
    </AlertContainer>
  );
};
