import { gql } from "graphql-tag";
import { useQuery } from "react-query";
import { graphQLClient } from "../dato-graphql-client";
import type { FooterTextRecord } from "../generated-gql/graphql";

export function useGetFooterText() {
  const { data, isLoading, error } = useQuery(
    "get-footer-text",
    async () => {
      const request = await graphQLClient.request<{
        footerText: FooterTextRecord;
      }>(gql`
        query GetFooterText {
          footerText {
            labelForOpeningHoursAndStoresLink
            labelForTermsLink
            facebookLink
            youtubeLink
            instagramLink
            telephoneNumber
            email
          }
        }
      `);
      return request;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return { data: data?.footerText, isLoading, error };
}
