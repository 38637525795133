/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { filter, isArray } from "lodash";

/**
 * Always returns a list with `null` and `undefined` values removed.
 *
 * ```typescript
 * ensureList() // => []
 * ensureList(null) // => []
 * ensureList([null, undefined, true, false, '', 0]) // => [true, false, '', 0]
 * ```
 */
export function ensureList<T>(list?: T | Array<T> | null) {
  return filter(isArray(list) ? list : [list], (x: T) => x != null) as NonNullable<T>[];
}
