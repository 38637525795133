/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const InstagramIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M1 4C1 2.34315 2.34315 1 4 1H20C21.6569 1 23 2.34315 23 4V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4ZM17.5 12C17.5 15.0376 15.0376 17.5 12 17.5C8.96243 17.5 6.5 15.0376 6.5 12C6.5 8.96243 8.96243 6.5 12 6.5C15.0376 6.5 17.5 8.96243 17.5 12ZM19.15 6.5C20.0613 6.5 20.8 5.76127 20.8 4.85C20.8 3.93873 20.0613 3.2 19.15 3.2C18.2387 3.2 17.5 3.93873 17.5 4.85C17.5 5.76127 18.2387 6.5 19.15 6.5Z"
        fill={iconColor}
        fillRule="evenodd"
      />
    </svg>
  );
};
