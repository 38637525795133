import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const PaintLineDecorative: BykoReactDecorativeIconComponentType = ({ size, color = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M40 22H8" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
      <path
        // eslint-disable-next-line max-len
        d="M40 2V24C40 27.314 37.314 30 34 30H28V42C28 44.209 26.209 46 24 46C21.791 46 20 44.209 20 42V30H14C10.686 30 8 27.314 8 24V2H26L30 9L34 2H40Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
    </svg>
  );
};
