import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const Measure2LineDecorative: BykoReactDecorativeIconComponentType = ({ size, color = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M22 16L24 18" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
      <path d="M28 10L32 14" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
      <path d="M16 22L20 26" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
      <path d="M10 28L12 30" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
      <path
        d="M14 44L4 34L34 4L44 14L14 44Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
    </svg>
  );
};
