import * as React from "react";
import { useMemo, useRef } from "react";
import { useHover } from "usehooks-ts";

import { PSmall } from "@byko/component-typography";
import { InfoFilledIcons } from "@byko/lib-icons";
import { theme } from "@byko/lib-styles";

import { InfoIconContainer, PopoutContainer, TooltipContainer, TooltipWrapper } from "./styles";

import type { TooltipProps } from "./interface";

export const Tooltip = ({
  content,
  arrowLocation = "right",
  width = 320,
  className = "tooltip-container",
}: TooltipProps): JSX.Element => {
  const hoverRef = useRef<HTMLDivElement>(null);
  const isHover = useHover(hoverRef);

  const iconColor = useMemo(() => {
    return isHover ? theme.palette.blue.main : theme.palette.gray[20];
  }, [isHover]);

  return (
    <TooltipWrapper arrowLocation={arrowLocation} className={className}>
      <InfoIconContainer ref={hoverRef}>
        <InfoFilledIcons aria-label="upplýsingar" iconColor={iconColor} size={20} />
      </InfoIconContainer>
      <PopoutContainer showTooltip={isHover} width={width}>
        <TooltipContainer>
          <PSmall textColor={theme.palette.black.main}>{content}</PSmall>
        </TooltipContainer>
      </PopoutContainer>
    </TooltipWrapper>
  );
};
