import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const LogoutIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg
      className="byko-icon"
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 12H21" stroke={iconColor} strokeMiterlimit="10" strokeWidth="2" />
      <path d="M17 16L21 12L17 8" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M19 4V1H5V23H19V20" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
    </svg>
  );
};
