import styled from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";

export const TogglerContainer = styled.div`
  position: relative;
  display: flex;
  width: 36px;
  height: 20px;
  align-items: center;
  border: 2px solid ${theme.palette.blue.dark};
  border-radius: 16px;
  background-color: ${theme.palette.blue.dark};
`;

export const TogglerState = styled.div`
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: ${theme.palette.white.main};
  transform: translate3d(0, 0, 0);
  transition: 250ms;
  &.pro {
    left: calc(100% - 2px);
    transform: translate3d(-100%, 0, 0);
  }
  &.standard {
    left: 2px;
  }
  &.none {
    left: calc(50%);
    transform: translate3d(-50%, 0, 0);
  }
`;

export const ToggleButtonHoverLabel = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: fit-content;
  padding-top: 2px;
  border-radius: 4px;
  background-color: ${theme.palette.blue.dark};
  opacity: 0;
  pointer-events: none;
  transform: translate3d(50%, calc(100% + 16px), 0);
  transition: 250ms;
  &.common {
    width: 148px;
  }
  &.pro {
    width: 168px;
  }
`;

export const ToggleButton = styled.button`
  position: relative;
  &:hover {
    ${ToggleButtonHoverLabel} {
      opacity: 1;
    }
  }
`;

export const DesktopOnly = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  @media screen and (max-width: 1078px) {
    display: none;
  }
`;

export const MobileOnly = styled.div`
  display: none;
  @media screen and (max-width: 1078px) {
    display: block;
  }
`;

export const ContentSwitcherWrapper = styled.div``;

export const AddToCartNotifyWrapper = styled.div`
  position: fixed;
  z-index: 3000;
  top: 104px;
  right: 16px;
  height: fit-content;
  pointer-events: none;
  @media screen and (max-width: 1078px) {
    top: 132px;
  }
`;

export const MobileIconButtonWrapper = styled.div`
  position: relative;
  z-index: 300000;
  display: none;
  width: 100%;
  @media screen and (max-width: 1078px) {
    display: flex;
    overflow: visible;
    justify-content: flex-end;
  }
`;

export const PositionWrapper = styled.div`
  position: absolute;
  top: 88px;
  right: 0;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  pointer-events: none;
  @media screen and (max-width: 1078px) {
    top: 58px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  z-index: 3001;
  padding-bottom: 88px;
  margin-right: 88px;
  pointer-events: none;
`;

export const NavBarWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  pointer-events: all;
  .search-input {
    @media screen and (max-width: ${gridTheme.breakpoints.sm}px) {
      box-shadow: 0px 18px 45px rgba(0, 0, 0, 0.09);
    }
  }
`;

export const MobileSearchContainer = styled.div`
  position: relative;
  z-index: 0;
  display: none;
  @media screen and (max-width: 1078px) {
    display: block;
  }
  @media screen and (max-width: 1438px) {
    border-bottom: 1px solid ${theme.palette.gray[10]};
  }
`;

export const NavBar = styled.nav`
  position: relative;
  z-index: 1;
  display: flex;
  height: 88px;
  justify-content: space-between;
  padding-right: 25px;
  border-bottom: 2px solid ${theme.palette.yellow.main};
  background-color: ${theme.palette.white.main};
  @media screen and (max-width: 1078px) {
    height: 56px;
    padding-right: 0 !important;
  }
`;

export const Navigation = styled.div`
  display: flex;
  width: 100%;
  height: 88px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid ${theme.palette.yellow.main};
  background-color: ${theme.palette.white.main};
  @media screen and (max-width: 1078px) {
    height: 56px;
  }
`;
export const FlexRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  @media screen and (max-width: ${gridTheme.breakpoints.sm - 1}px) {
    display: none;
  }
`;

export const MegaMenuTriggerWrapper = styled.div`
  width: 162px;
  margin-right: 32px;
  margin-left: 32px;
  button {
    margin-right: 10px;
    margin-left: 10px;
    p {
      font-size: 18px;
    }
    @media screen and (max-width: ${gridTheme.breakpoints.sm}px) {
      margin-right: 20px;
      margin-left: 20px;
    }
  }
`;

export const IconButtonWrapper = styled.div`
  position: relative;
`;

export const CartCounterWrapper = styled.div`
  position: absolute;
  right: 0px;
  bottom: 0;
  display: flex;
  width: 28px;
  height: 28px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

export const CartCount = styled.p`
  padding: unset;
  margin: unset;
  background-color: ${theme.palette.white.main};
  color: ${theme.palette.blue.dark};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  transform: translateY(1px);
`;

export const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 50px;
  margin-left: 20px;
  gap: 30px;
  .icon-button {
    border: none;
  }
  @media screen and (max-width: ${gridTheme.container.maxWidth.sm}px) {
    margin-right: 0;
    margin-left: 0;
    gap: unset;
  }
`;

export const Divider = styled.div`
  @media screen and (max-width: ${gridTheme.container.maxWidth.sm}px) {
    width: 1px;
    height: 56px;
    background-color: ${theme.palette.gray[20]};
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 25px;
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
  & a {
    color: ${theme.palette.blue.main};
    font-size: 18px;
    &:focus {
      border-bottom: 1px solid ${theme.palette.gray[60]};
    }
  }
`;

export const LoginWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.palette.white.main};
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
  @media screen and (max-width: ${gridTheme.breakpoints.sm - 1}px) {
    display: none;
  }
  & a {
    color: ${theme.palette.white.main};
  }
  .loginButton {
    width: 210px;
  }

  .drop-down-active-container {
    outline: 1px solid ${theme.palette.yellow.main};
    &:hover {
      outline: none;
    }
    svg {
      path {
        stroke: ${theme.palette.yellow.main};
      }
    }
  }
`;

export const SearchInputWrapper = styled.div`
  width: 100%;
  @media screen and (max-width: 1078px) {
    display: none;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding-right: 32px;
  padding-left: 32px;
  background-color: ${theme.palette.blue.main};
  transition: 0.3s;
  a {
    display: flex;
    overflow: hidden;
    height: 100%;
    align-items: center;
  }
  * {
    transition: 0.3s;
  }
  svg {
    height: fit-content;
    box-sizing: border-box;
  }
  @media screen and (max-width: 1078px) {
    overflow: hidden;
    height: 100%;
  }
  @media screen and (max-width: 340px) {
    padding-right: 8px;
    padding-left: 8px;
  }
`;

export const SVGWrapper = styled.div`
  text-align: center;
`;
