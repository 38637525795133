import styled from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";

import type { FocusLineProps, TheSecondaryButtonProps } from "./interface";

export const SecondaryButtonWrapper = styled.button`
  width: fit-content;
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    p {
      font-size: 16px;
    }
  }
`;

export const FocusLine = styled.div<FocusLineProps>`
  z-index: 1;
  display: flex;
  width: ${(p): string => (p.smallIcon ? "22px" : p.noIcon ? "28px" : "9px")};
  height: 1px;
  margin-top: 5px;
  margin-left: ${(p): string => (p.noIcon ? "unset" : "-4px")};
  background-color: ${(p): string => (p.blueLine ? `${theme.palette.blue.main}` : `${theme.palette.yellow.main}`)};
  transition: width 0.3s ease-in-out;
`;

export const TheSecondaryButton = styled.div<TheSecondaryButtonProps>`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 20px;
  white-space: nowrap;
  svg {
    margin-bottom: 4px;
    margin-left: 4px;
  }
  &:hover + ${FocusLine} {
    width: 100%;
  }
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    gap: 10px;
  }
`;
