import styled from "styled-components";

import { gridTheme, scrollStyle, theme } from "@byko/lib-styles";

export const ProductCompareSectionBackground = styled.div`
  position: fixed;
  z-index: 300;
  bottom: 0;
  padding: 20px;
  background-color: ${theme.palette.gray[5]};
  filter: drop-shadow(14px 0px 35px rgba(13, 13, 13, 0.16));
  inset-inline: 0px;
`;

export const ProductCompareSectionContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: ${gridTheme.maxContentWidth}px;
  flex-direction: column;
  margin: 0 auto;
  overflow-x: auto;
  ${scrollStyle({ color: "dark-blue" })}
  ::-webkit-scrollbar {
    height: 2px;
  }
  ::-webkit-scrollbar-track {
    border: none;
    background: ${theme.palette.gray[5]};
  }
`;

export const ProductList = styled.div`
  display: flex;
  padding-bottom: 10px;
  gap: 20px;
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    gap: 8px;
  }
`;
