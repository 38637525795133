import { useCallback, useEffect, useState } from "react";

import { useToasts } from "@byko/hooks-toasts";
import { RoleEnum, restApi } from "@byko/lib-api-rest";
import { useRecoilState } from "@byko/lib-recoil";

import {
  customerAccountChangedRecordState,
  customerAccountInviteDetailsState,
  customerAccountMembershipManagementState,
  inviteDetailsLoadingState,
} from "./store";

import type {
  CustomerAccountChangedRecordState,
  CustomerAccountMembershipManagementExtended,
  UseCustomerAccountMembershipProps,
} from "./interface";
import type {
  CustomerAccountInvitation,
  CustomerAccountInvitationRequest,
  Login,
  PatchedCustomerAccountInvitationRequest,
  PatchedCustomerAccountMembershipManagementRequest,
} from "@byko/lib-api-rest";

const GENERIC_ERROR_MESSAGE = "Eitthvað fór úrskeiðis";

const convertInvitationToManagementItem = (
  invitation: CustomerAccountInvitation,
): CustomerAccountMembershipManagementExtended => {
  return {
    acceptedAt: invitation.acceptedAt,
    id: invitation.id,
    isInvitation: true,
    createdAt: invitation.createdAt,
    role: RoleEnum.Contractor,
    active: true,
    canPurchase: !!invitation.canPurchase,
    canViewSalesTransactions: !!invitation.canViewSalesTransactions,
    expanded: false,
    isRepresentative: !!invitation.isRepresentative,
    isUserManager: !!invitation.isUserManager,
    user: {
      id: -1,
      customerAgentId: invitation.createAsAgent ? "interim id" : "",
      avatar: {
        default: "none",
      },
      email: invitation.email ?? "",
      verified: {
        email: invitation.email ?? "",
        name: invitation.unvalidatedName ?? "",
      },
    },
  };
};

export const useCustomerAccountMembership = (): UseCustomerAccountMembershipProps => {
  customerAccountInviteDetailsState;
  const [changedRecords, setChangedRecords] = useRecoilState(customerAccountChangedRecordState);
  const [inviteDetails, setInviteDetails] = useRecoilState(customerAccountInviteDetailsState);
  const [inviteDetailsLoading, setInviteDetailsLoading] = useRecoilState(inviteDetailsLoadingState);
  const { setErrorMessage } = useToasts();
  const [customerAccountMembershipManagementList, setCustomerAccountMembershipManagementList] = useRecoilState(
    customerAccountMembershipManagementState,
  );

  const toggleExpand = useCallback(
    (id: number, expanded: boolean) => {
      const updatedCollection = customerAccountMembershipManagementList.map((item) => {
        if (item.id !== id) {
          return item;
        }

        return {
          ...item,
          expanded,
        };
      });

      setCustomerAccountMembershipManagementList(updatedCollection);
    },
    [customerAccountMembershipManagementList, setCustomerAccountMembershipManagementList],
  );

  const [loadingData, setLoadingData] = useState<boolean>(false);

  const fetchCustomerAccountMembershipManagementList = useCallback(async () => {
    setCustomerAccountMembershipManagementList([]);
    setLoadingData(true);

    const { data } = await restApi.membershipManagementList();
    const { data: invitations } = await restApi.invitationsList();

    const invitationList: CustomerAccountMembershipManagementExtended[] = [];

    invitations.forEach((invitation) => {
      // we want to hide invitations that have already been accepted
      if (invitation.acceptedAt === null) {
        invitationList.push(convertInvitationToManagementItem(invitation));
      }
    });

    setLoadingData(false);
    setCustomerAccountMembershipManagementList([...invitationList, ...data]);
  }, [setCustomerAccountMembershipManagementList]);

  useEffect(() => {
    const changeList: CustomerAccountChangedRecordState = {};

    customerAccountMembershipManagementList.forEach((item) => {
      changeList[item.id] = changeList[item.id] ?? false;
    });

    setChangedRecords(changeList);
  }, [customerAccountMembershipManagementList, setChangedRecords]);

  const setHasChanges = useCallback(
    (id: number, hasChanges: boolean) => {
      setChangedRecords((current) => {
        return {
          ...current,
          [id]: hasChanges,
        };
      });
    },
    [setChangedRecords],
  );

  const updateActiveStatus = useCallback(
    async (id: number, isActive: boolean) => {
      const { data } = await restApi.membershipManagementPartialUpdate(id, {
        active: isActive,
      });

      const updatedCollection = customerAccountMembershipManagementList.map((item) => {
        if (item.id !== id) {
          return item;
        }

        return {
          ...item,
          active: !!data.active,
        };
      });

      setCustomerAccountMembershipManagementList(updatedCollection);
    },
    [customerAccountMembershipManagementList, setCustomerAccountMembershipManagementList],
  );

  const updateCustomerAccountMembershipManagement = useCallback(
    async (id: number, payload: PatchedCustomerAccountMembershipManagementRequest) => {
      const { data } = await restApi.membershipManagementPartialUpdate(id, payload);

      const updatedCollection = customerAccountMembershipManagementList.map((item) => {
        if (item.id !== id) {
          return item;
        }

        return {
          ...data,
          expanded: true,
        };
      });

      setCustomerAccountMembershipManagementList(updatedCollection);
    },
    [customerAccountMembershipManagementList, setCustomerAccountMembershipManagementList],
  );

  const sendInvite = useCallback(
    async (invitation: CustomerAccountInvitationRequest) => {
      try {
        const { data } = await restApi.invitationsCreate(invitation);

        const updatedCollection = [convertInvitationToManagementItem(data), ...customerAccountMembershipManagementList];

        setCustomerAccountMembershipManagementList(updatedCollection);

        return true;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.status && error.status === 400 && error.error && error.error.length === 1) {
          setErrorMessage(error.error[0]);
        } else if (error.status && error.status === 409 && error.error && error.error.email) {
          setErrorMessage("Það er þegar búið að senda boð á þetta netfang");
        } else {
          setErrorMessage(GENERIC_ERROR_MESSAGE);
        }

        return false;
      }
    },
    [customerAccountMembershipManagementList, setCustomerAccountMembershipManagementList, setErrorMessage],
  );

  const updateInvite = useCallback(
    async (id: number, updatedInvitation: PatchedCustomerAccountInvitationRequest) => {
      try {
        const { data } = await restApi.invitationsPartialUpdate(id, updatedInvitation);

        const updatedCollection = customerAccountMembershipManagementList.map((item) => {
          if (item.id !== id || !item.isInvitation) {
            return item;
          }

          return {
            ...convertInvitationToManagementItem(data),
            expanded: true,
          };
        });

        setCustomerAccountMembershipManagementList(updatedCollection);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.status && error.status === 400 && error.error && error.error.length === 1) {
          setErrorMessage(error.error[0]);
        } else {
          setErrorMessage(GENERIC_ERROR_MESSAGE);
        }
      }
    },
    [customerAccountMembershipManagementList, setCustomerAccountMembershipManagementList, setErrorMessage],
  );

  const revokeInvite = useCallback(
    async (id: number) => {
      try {
        await restApi.invitationsDestroy(id);

        const updatedCollection = customerAccountMembershipManagementList.filter((item) => {
          return item.id !== id || !item.isInvitation;
        });

        setCustomerAccountMembershipManagementList(updatedCollection);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.status && error.status === 400 && error.error && error.error.length === 1) {
          setErrorMessage(error.error[0]);
        } else {
          setErrorMessage(GENERIC_ERROR_MESSAGE);
        }
      }
    },
    [customerAccountMembershipManagementList, setCustomerAccountMembershipManagementList, setErrorMessage],
  );

  const resendInvite = useCallback(
    async (id: number) => {
      try {
        await restApi.invitationsResendCreate(id);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.status && error.status === 400 && error.error && error.error.length === 1) {
          setErrorMessage(error.error[0]);
        } else {
          setErrorMessage(GENERIC_ERROR_MESSAGE);
        }
      }
    },
    [setErrorMessage],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const acceptInvite = useCallback<Login | any>(
    async (token: string) => {
      const res = await restApi
        .invitationsAcceptCreate({
          token,
        })
        .catch((error) => {
          if (error.status && error.status === 400 && error.error && error.error.length === 1) {
            setErrorMessage(error.error[0]);
          } else {
            setErrorMessage(GENERIC_ERROR_MESSAGE);
          }
          return error;
        });
      return res?.data;
    },
    [setErrorMessage],
  );

  const fetchInviteDetails = useCallback(
    async (token: string) => {
      setInviteDetailsLoading(true);

      try {
        const { data } = await restApi.accountsInvitationsDetailsRetrieve({
          token,
        });

        setInviteDetails(data);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.status && error.status === 400 && error.error && error.error.length === 1) {
          setErrorMessage(error.error[0]);
        } else if (error.status && error.status !== 404) {
          setErrorMessage(GENERIC_ERROR_MESSAGE);
        }
      }

      setInviteDetailsLoading(false);
    },
    [setErrorMessage, setInviteDetails, setInviteDetailsLoading],
  );

  const setCustomerAgentStatusToTrue = useCallback(
    (id: number) => {
      const updatedCollection = customerAccountMembershipManagementList.map((item) => {
        if (item.id !== id) {
          return item;
        }

        return {
          ...item,
          user: {
            ...item.user,
            customerAgentId: "provisional id",
          },
        };
      });

      setCustomerAccountMembershipManagementList(updatedCollection);
    },
    [customerAccountMembershipManagementList, setCustomerAccountMembershipManagementList],
  );

  return {
    customerAccountMembershipManagementList,
    fetchCustomerAccountMembershipManagementList,
    updateCustomerAccountMembershipManagement,
    changedRecords,
    setHasChanges,
    toggleExpand,
    loadingData,
    sendInvite,
    revokeInvite,
    updateActiveStatus,
    updateInvite,
    resendInvite,
    acceptInvite,
    fetchInviteDetails,
    inviteDetails,
    inviteDetailsLoading,
    setCustomerAgentStatusToTrue,
  };
};
