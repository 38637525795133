/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const GlobeLineDecorative: BykoReactDecorativeIconComponentType = ({ size, color = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M24 2V46" stroke="black" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M2 24H46" stroke="black" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M4.94397 13H43.056" stroke="black" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M4.94397 35H43.056" stroke="black" strokeMiterlimit="10" strokeWidth="2" />
      <path
        d="M24 46C30.0751 46 35 36.1503 35 24C35 11.8497 30.0751 2 24 2C17.9249 2 13 11.8497 13 24C13 36.1503 17.9249 46 24 46Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M24 46C36.1503 46 46 36.1503 46 24C46 11.8497 36.1503 2 24 2C11.8497 2 2 11.8497 2 24C2 36.1503 11.8497 46 24 46Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
};
