import { GraphQLClient } from "graphql-request";
import { getConfig } from "./get-config";

const { DATO_GRAPHQL_URL, DATO_TOKEN_CMS } = getConfig();

if (DATO_TOKEN_CMS === "") {
  console.error("missing DATO_TOKEN_CMS");
}

if (DATO_GRAPHQL_URL === "") {
  console.error("missing CMS_API_URL");
}

export const graphQLClient = new GraphQLClient(DATO_GRAPHQL_URL ?? "https://graphql.datocms.com", {
  headers: {
    Authorization: `Bearer ${DATO_TOKEN_CMS}`,
    "X-Environment": process.env["DATO_ENVIRONMENT"] ?? "main",
  },
});
