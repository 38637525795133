/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React, { useMemo } from "react";
import type { ParsedUrlQuery } from "querystring";
import type algoliasearchHelper from "algoliasearch-helper";

import {
  ProductCustomCard,
  ProductDisplayCard,
  ProductLimitedCard,
  ProductRentCard,
  ProductStandardCard,
} from "@byko/component-product-card";
import type { Prices } from "@byko/component-product-card";
import type { ProductList } from "@byko/lib-api-rest";
import type { ProductListAugmented, SalesAvailability } from "@byko/lib-api-products";
import { useAuth } from "@byko/hooks-auth-next";

import { ProductContainer } from "./styles";

export interface ProductSearchResultsProps {
  description?: string | undefined;
  attrNameMap: Record<string, string> | undefined;
  categoryId?: number | undefined;
  initialData?: algoliasearchHelper.SearchResults<ProductList> | undefined;
  query?: ParsedUrlQuery;
  pathname?: string;
  rentals: boolean;
  disabled?: boolean;
}

interface ResultsProps {
  results: algoliasearchHelper.SearchResults<ProductList> | undefined;
  products: ProductListAugmented[];
  prices: Record<string, Prices> | undefined;
  rentals: boolean;
}

export function ProductResultsList({ results, prices, rentals, products }: ResultsProps): JSX.Element {
  const { activeMembership } = useAuth();

  // rental page should only show rental products
  const ProductCardList = useMemo((): JSX.Element => {
    if (rentals) {
      return (
        <ProductContainer>
          {products.map((product, index) => {
            return (
              <ProductRentCard
                key={product.id}
                addedLabel="Leiguvara"
                algoliaInsights={{
                  eventName: "Rent Card Clicked",
                  index: results?.index || "",
                  queryID: results?.queryID || "",
                  objectIDs: [product.id.toString() || ""],
                  positions: [index + 1],
                }}
                listIndex={index}
                product={product}
              />
            );
          })}
        </ProductContainer>
      );
    }

    // mixed content
    return (
      <ProductContainer>
        {products.map((product, index) => {
          // for debug
          // const salesAvailability: SalesAvailability = "limited";

          const salesAvailability: SalesAvailability = (product.salesAvailability as SalesAvailability) ?? "standard";
          /*
            "rental": rental products, not sold on web, no price display
            "custom": custom order, not sold on the web, no price display
            "limited": limited availability, not sold on the web, show price info
            "display": display only, not sold on the web, no price display
            "standard": standard products, display as is
            "by_contract": contracted products, only users with contract see price info and can order
          */

          if (isRentalProduct(product)) {
            return (
              <ProductRentCard
                key={product.id}
                addedLabel="Leiguvara"
                algoliaInsights={{
                  eventName: "Rent Card Clicked",
                  index: results?.index || "",
                  queryID: results?.queryID || "",
                  objectIDs: [product.id.toString() || ""],
                  positions: [index + 1],
                }}
                listIndex={index}
                product={product}
              />
            );
          } else if (salesAvailability === "by_contract") {
            if (activeMembership?.account.hasContract) {
              return (
                <ProductStandardCard
                  key={product.id}
                  algoliaInsights={{
                    eventName: "Product Card Clicked",
                    index: results?.index || "",
                    queryID: results?.queryID || "",
                    objectIDs: [product.variants?.[0]?.sku || ""],
                    positions: [index + 1],
                  }}
                  analyticsSectionId="filter-results"
                  listIndex={index}
                  lowestPriceSKU={
                    prices?.[product.id] !== undefined ? Object.keys(prices?.[product.id] ?? {})[0] : undefined
                  }
                  prices={prices?.[product.id]}
                  product={product}
                />
              );
            } else {
              return (
                <ProductDisplayCard
                  key={product.id}
                  algoliaInsights={{
                    eventName: "Product Card Clicked",
                    index: results?.index || "",
                    queryID: results?.queryID || "",
                    objectIDs: [product.variants?.[0]?.sku || ""],
                    positions: [index + 1],
                  }}
                  listIndex={index}
                  product={product}
                />
              );
            }
          } else if (salesAvailability === "custom") {
            return (
              <ProductCustomCard
                key={product.id}
                addedLabel="Sérpöntun"
                algoliaInsights={{
                  eventName: "Product Card Clicked",
                  index: results?.index || "",
                  queryID: results?.queryID || "",
                  objectIDs: [product.id.toString() || ""],
                  positions: [index + 1],
                }}
                listIndex={index}
                product={product}
              />
            );
          } else if (salesAvailability === "limited") {
            return (
              <ProductLimitedCard
                key={product.id}
                algoliaInsights={{
                  eventName: "Product Card Clicked",
                  index: results?.index || "",
                  queryID: results?.queryID || "",
                  objectIDs: [product.id.toString() || ""],
                  positions: [index + 1],
                }}
                analyticsSectionId="filter-results"
                listIndex={index}
                lowestPriceSKU={
                  prices?.[product.id] !== undefined ? Object.keys(prices?.[product.id] ?? {})[0] : undefined
                }
                prices={prices?.[product.id]}
                product={product}
              />
            );
          } else if (salesAvailability === "display") {
            return (
              <ProductDisplayCard
                key={product.id}
                algoliaInsights={{
                  eventName: "Product Card Clicked",
                  index: results?.index || "",
                  queryID: results?.queryID || "",
                  objectIDs: [product.id.toString() || ""],
                  positions: [index + 1],
                }}
                listIndex={index}
                product={product}
              />
            );
            // Standard product
          } else {
            return (
              <ProductStandardCard
                key={product.id}
                algoliaInsights={{
                  eventName: "Product Card Clicked",
                  index: results?.index || "",
                  queryID: results?.queryID || "",
                  objectIDs: [product.id.toString() || ""],
                  positions: [index + 1],
                }}
                analyticsSectionId="filter-results"
                listIndex={index}
                lowestPriceSKU={
                  prices?.[product.id] !== undefined ? Object.keys(prices?.[product.id] ?? {})[0] : undefined
                }
                prices={prices?.[product.id]}
                product={product}
              />
            );
          }
        })}
      </ProductContainer>
    );
  }, [activeMembership?.account.hasContract, prices, products, rentals, results?.index, results?.queryID]);

  return ProductCardList;
}

export function isRentalProduct(product: ProductListAugmented | ProductList) {
  let isRental = false;
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    isRental = product.attributes.typeParent.includes("Leigumarkaður");
    if (!isRental) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      isRental = product.attributes.typeParent[0]["is-IS"] === "Leigumarkaður";
    }
  } catch {
    // silent
  }
  return isRental;
}
