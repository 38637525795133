import Script from "next/script";
import { useMemo } from "react";

import type { Config } from "./interface";

export const ConfigScript = ({ config }: { config: Config }): JSX.Element => {
  const script = useMemo(() => {
    return {
      __html: `window.__APP__CONFIG__ = JSON.parse(${JSON.stringify(JSON.stringify(config))})`,
    } as { __html: string };
  }, [config]);
  return <Script dangerouslySetInnerHTML={script} id="app-settings" strategy="beforeInteractive" />;
};
