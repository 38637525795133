import { css } from "styled-components";

import { theme } from "./theme";

import type { FlattenInterpolation, ThemeProps } from "styled-components";

interface Props {
  color?: string;
  outlined?: boolean;
  length: number;
  showOnHover?: boolean;
}

interface HoverProps {
  length: number;
}

export const hoverShadowStyles = <T>({ length }: HoverProps): FlattenInterpolation<ThemeProps<T>> => {
  return css`
    &:hover {
      transform: translateY(-${length}px) translateX(${length}px);

      &:before {
        transform: translateY(${length}px) translateX(-${length}px) translateZ(-1em);
      }
      &:disabled {
        transform: none;
        &:before {
          transform: none;
        }
      }
    }
  `;
};

export const clickAnimation = <T>(): FlattenInterpolation<ThemeProps<T>> => {
  return css`
    &:active&:not(:disabled) {
      font-size: 1rem;
      transform: translateY(-6px) translateX(6px);

      &::before {
        transform: translateY(6px) translateX(-6px) translateZ(-1em);
      }
    }
  `;
};

export const shadowStyles = <T>({
  color,
  outlined,
  length,
  showOnHover = false,
}: Props): FlattenInterpolation<ThemeProps<T>> => {
  return css`
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.5s;

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid ${color || theme.palette.blue.main};
      background-color: ${outlined ? theme.palette.white.main : color || theme.palette.blue.main};
      content: "";
      transform: ${!showOnHover && `translateY(${length}px) translateX(-${length}px)`} translateZ(-1em);
      transform-style: preserve-3d;
      transition: transform 0.5s;
    }

    ${showOnHover && hoverShadowStyles({ length })}

    ${showOnHover && clickAnimation()}
  `;
};
