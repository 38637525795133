/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const NewsletterFilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 37C23.813 37 23.627 36.948 23.463 36.844L1.46302 22.844C1.15902 22.651 0.983015 22.308 1.00102 21.948C1.02002 21.589 1.23002 21.266 1.55302 21.105L24 7L46.447 21.105C46.769 21.266 46.98 21.588 46.999 21.948C47.018 22.308 46.841 22.65 46.537 22.844L24.537 36.844C24.373 36.948 24.187 37 24 37Z"
        fill="#D6B200"
      />
      <path d="M39 38H9V2C9 1.448 9.448 1 10 1H38C38.552 1 39 1.448 39 2V38Z" fill="#F7F7F7" />
      <path
        d="M46 47C45.916 47 45.831 46.989 45.748 46.968L22.748 40.968L23 34L47 22V46C47 46.31 46.856 46.602 46.611 46.792C46.435 46.928 46.219 47 46 47Z"
        fill="#FFD330"
      />
      <path
        d="M46 47H2C1.447 47 1 46.552 1 46V22L46.479 45.122C46.88 45.341 47.083 45.804 46.969 46.247C46.856 46.69 46.457 47 46 47Z"
        fill="#FFE24D"
      />
      <path
        d="M32 10H16C15.448 10 15 9.553 15 9C15 8.447 15.448 8 16 8H32C32.552 8 33 8.447 33 9C33 9.553 32.552 10 32 10Z"
        fill="#959595"
      />
      <path
        d="M32 17H16C15.448 17 15 16.553 15 16C15 15.447 15.448 15 16 15H32C32.552 15 33 15.447 33 16C33 16.553 32.552 17 32 17Z"
        fill="#959595"
      />
      <path
        d="M25 24H16C15.448 24 15 23.553 15 23C15 22.447 15.448 22 16 22H25C25.552 22 26 22.447 26 23C26 23.553 25.552 24 25 24Z"
        fill="#959595"
      />
    </svg>
  );
};
