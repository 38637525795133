import * as React from "react";
import { useDeferredValue, useEffect, useMemo } from "react";

import { drawerState, drawerStateLoading } from "@byko/component-drawer";
import { blockDrawerClickOutsideState } from "@byko/component-drawer";
import { HandleInvitation } from "@byko/component-handle-invitation";
import { IconStep } from "@byko/component-icon-steps";
import { useAuth } from "@byko/hooks-auth-next";
import { CheckmarkIcons } from "@byko/lib-icons";
import { useRecoilValue, useSetRecoilState } from "@byko/lib-recoil";
import { useRecoilState } from "@byko/lib-recoil";
import { AccountPicker } from "@byko/scene-account-picker";
import { isSSR } from "@byko/types-utils";

import { Email, LoginForm, Welcome } from "./component";
import { Terms } from "./component/terms";
import { LoginStepState, loginState } from "./interface";
import { useLoginStateStore, useSetLoginStateStore } from "./login-state";
import { IconStepContent, LoginStepsContainer, StepContainer, StepWrapper } from "./styles";

export const LoginSteps = (): JSX.Element | null => {
  const setBlockClose = useSetRecoilState<boolean>(blockDrawerClickOutsideState);
  const [steps, setSteps] = useRecoilState<LoginStepState>(loginState);
  const landingPage = isSSR() ? false : window.location.pathname === "/";
  const setOpen = useSetRecoilState<boolean>(drawerState);
  const isDrawerLoading = useRecoilValue(drawerStateLoading);
  const token = isSSR() ? undefined : localStorage.getItem("token");
  const handleSetLoginState = useSetLoginStateStore();
  const currentLoginState = useLoginStateStore();

  const { user, memberships, registrationStatus, isMembershipStateSet, requestSwitchAccount } = useAuth();

  const _isOpen = useMemo(() => {
    if (!user) {
      return true;
    }
    if (
      (memberships.length === 1 && !registrationStatus.acceptedTerms) ||
      !registrationStatus.registeredEmail ||
      registrationStatus.isOnboarding
    ) {
      return true;
    }
    if (registrationStatus.hasInvite) {
      return true;
    }
    if (memberships.length > 1 && !isDrawerLoading) {
      return !isMembershipStateSet;
    }
    return false;
  }, [
    isDrawerLoading,
    isMembershipStateSet,
    memberships.length,
    registrationStatus.acceptedTerms,
    registrationStatus.hasInvite,
    registrationStatus.isOnboarding,
    registrationStatus.registeredEmail,
    user,
  ]);
  const isOpen = useDeferredValue(_isOpen);

  useEffect(() => {
    if (currentLoginState === null && !isOpen) {
      return;
    }
    handleSetLoginState(isOpen ? "in-progress" : "finished");
    if (!isOpen) {
      setOpen(false);
    }
  }, [currentLoginState, handleSetLoginState, isOpen, setOpen]);

  useEffect(() => {
    if (!registrationStatus.acceptedTerms) {
      setBlockClose(user != null);
      setSteps(LoginStepState.terms);
    } else if (!registrationStatus.registeredEmail) {
      setBlockClose(true);
      setSteps(LoginStepState.email);
    } else if (registrationStatus.isOnboarding) {
      setBlockClose(true);
      setSteps(LoginStepState.email);
    } else if (requestSwitchAccount) {
      setBlockClose(false);
      setOpen(true);
    } else if (registrationStatus.hasInvite) {
      setBlockClose(false);
      setOpen(true);
    } else if (memberships.length > 1 && !isMembershipStateSet && !isDrawerLoading) {
      setBlockClose(false);
      setOpen(true);
    } else {
      setBlockClose(false);
      setOpen(false);
    }
  }, [
    landingPage,
    isMembershipStateSet,
    memberships.length,
    registrationStatus,
    registrationStatus.acceptedTerms,
    registrationStatus.isOnboarding,
    registrationStatus.registeredEmail,
    setOpen,
    setSteps,
    token,
    user,
    isDrawerLoading,
    requestSwitchAccount,
    setBlockClose,
  ]);

  if (!user) {
    return <LoginForm />;
  }

  if (
    (memberships.length === 1 && !registrationStatus.acceptedTerms) ||
    !registrationStatus.registeredEmail ||
    registrationStatus.isOnboarding
  ) {
    return (
      <LoginStepsContainer>
        <StepContainer>
          <StepWrapper>
            <IconStep
              currentstep={steps === LoginStepState.terms}
              icon={CheckmarkIcons}
              isfulfilled={true}
              label=""
              noConnectorLine={true}
            />
            <IconStep
              currentstep={steps === LoginStepState.email}
              icon={CheckmarkIcons}
              isfulfilled={
                (steps === LoginStepState.email && registrationStatus.acceptedTerms) ||
                (steps === LoginStepState.welcome && registrationStatus.acceptedTerms)
              }
              label=""
            />
            <IconStep
              currentstep={steps === LoginStepState.welcome}
              icon={CheckmarkIcons}
              isfulfilled={steps === LoginStepState.welcome}
              label=""
            />
          </StepWrapper>
        </StepContainer>

        <IconStepContent>
          {steps === LoginStepState.terms && (
            <>
              <Terms />
            </>
          )}

          {steps === LoginStepState.email && (
            <>
              <Email />
            </>
          )}

          {steps === LoginStepState.welcome && (
            <>
              <Welcome userName={user?.firstName} />
            </>
          )}
        </IconStepContent>
      </LoginStepsContainer>
    );
  }

  if (registrationStatus.hasInvite) {
    return <HandleInvitation userName={user?.firstName} />;
  }

  if (requestSwitchAccount || (memberships.length > 1 && !isMembershipStateSet && !isDrawerLoading)) {
    return <AccountPicker />;
  }

  return null;
};
