import { useRecoilValue, useSetRecoilState } from "@byko/lib-recoil";

import { menuOpenState } from "./store";

import type { MobileBurgerProps } from "./interface";

export const useMobileBurger = (): MobileBurgerProps => {
  const setMenuOpen = useSetRecoilState(menuOpenState);
  const menuOpen = useRecoilValue(menuOpenState);

  return {
    setMenuOpen,
    menuOpen,
  };
};
