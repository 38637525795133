import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const ChevronIcon: BykoReactIconComponentType = () => {
  return (
    <svg fill="none" height="13" viewBox="0 0 9 13" width="9" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.91663 1.75L6.66663 6.5L1.91663 11.25"
        stroke="#0A3A5C"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
};
