import styled from "styled-components";

import { gridTheme } from "@byko/lib-styles";

export const StepContainer = styled.div`
  display: flex;
  width: 100%;
  max-height: 200px;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  button {
    width: 100%;
    max-width: 120px;
  }
  @media screen and (max-width: ${gridTheme.container.maxWidth.sm}px) {
    gap: 20px;
  }
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    max-height: 120px;
    flex-direction: column;
    align-items: flex-start;

    button {
      margin-bottom: 0px;
    }
  }
`;

export const StepWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const LoginStepsContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

export const IconStepContent = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;
