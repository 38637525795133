import styled from "styled-components";

import { handleColorType, theme } from "@byko/lib-styles";

import type { IconButtonContainerProps } from "./interface";

export const IconButtonContainer = styled.button<IconButtonContainerProps>`
  display: flex;
  width: ${(props): string => (props.small ? "40px" : "56px")};
  height: ${(props): string => (props.small ? "40px" : "54px")};
  align-items: center;
  justify-content: center;
  border: ${(props): string => (props.buttonColor === "whiteButton" ? `1px solid ${theme.palette.gray[30]}` : "none")};
  transition: all 0.5s;
  svg {
    * {
      transition: all 0.5s;
    }
  }

  &:disabled {
    border: 1px solid ${theme.palette.gray[10]};
    background-color: ${theme.palette.white.main};
    pointer-events: none;
    p {
      color: ${theme.palette.gray[30]};
    }
  }

  ${(props): string => (props.buttonColor ? handleColorType(props.buttonColor) : "")}
`;
