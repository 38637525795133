import styled from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";

export const Buttonflex = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const ClearAllProductsButton = styled.button`
  display: flex;
  color: ${theme.palette.gray[40]};
  text-align: right;
  @media screen and (max-width: ${gridTheme.container.maxWidth.sm}px) {
    display: none;
  }
`;

export const ClearAllProductsButtonMobile = styled.button`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  color: ${theme.palette.gray[40]};
  text-align: right;
  @media screen and (min-width: ${gridTheme.container.maxWidth.sm}px) {
    display: none;
  }
`;
