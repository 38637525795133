import React, { useCallback } from "react";

import { IconButton } from "@byko/component-buttons";
import { ArrowLeftIcons, ArrowRightIcons, DotsIcons } from "@byko/lib-icons";
import { useWindowSize } from "@byko/lib-styles";

import { PaginationButton } from "./components";
import { PaginationContainer, Wrapper } from "./styles";

import type { PaginationProps } from "./interface";

export const Pagination = ({ setPageIndex, pageIndex, totalPages, align }: PaginationProps): JSX.Element => {
  const { activeBreakPoint } = useWindowSize();
  const goToIndex = useCallback(
    (index: number) => {
      setPageIndex(index);
    },
    [setPageIndex],
  );

  const prevPage = useCallback(() => {
    setPageIndex(pageIndex - 1);
  }, [setPageIndex, pageIndex]);

  const nextPage = useCallback(() => {
    if (pageIndex + 1 < totalPages) {
      setPageIndex(pageIndex + 1);
    }
  }, [pageIndex, setPageIndex, totalPages]);

  if (totalPages <= 1) return <></>;

  return (
    <PaginationContainer>
      <Wrapper align={align ?? "center"}>
        <IconButton
          ariaLabel="Til baka"
          disabled={pageIndex === 0}
          icon={ArrowLeftIcons}
          small={["s", "xs"].includes(activeBreakPoint)}
          onClick={prevPage}
        />
        {pageIndex >= 2 && (
          <PaginationButton aria="fara á fyrstu síðu" index={0} setPage={goToIndex} variant="whiteButton" />
        )}
        {pageIndex > 2 && (
          <IconButton
            ariaLabel="skraut"
            buttonColor="blueButton"
            disabled={true}
            icon={DotsIcons}
            small={["s", "xs"].includes(activeBreakPoint)}
          />
        )}
        {[...Array(totalPages).keys()].map((pageNumber) => {
          if (
            pageNumber - pageIndex <= (["xs"].includes(activeBreakPoint) ? 0 : 1) &&
            pageNumber - pageIndex >= (["xs"].includes(activeBreakPoint) ? 0 : -1)
          ) {
            return (
              <PaginationButton
                key={pageNumber}
                aria={`fara á síðu ${pageNumber + 1}`}
                index={pageNumber}
                setPage={goToIndex}
                variant={pageIndex === pageNumber ? "blueButton" : "whiteButton"}
              />
            );
          } else {
            return null;
          }
        })}

        {pageIndex + 3 < totalPages && (
          <IconButton
            ariaLabel="skraut"
            buttonColor="blueButton"
            disabled={true}
            icon={DotsIcons}
            small={["s", "xs"].includes(activeBreakPoint)}
          />
        )}
        {pageIndex + 3 <= totalPages && (
          <PaginationButton
            aria="fara á seinustu síðu"
            index={totalPages - 1}
            setPage={goToIndex}
            variant="whiteButton"
          />
        )}
        <IconButton
          ariaLabel="Áfram"
          disabled={pageIndex === totalPages - 1}
          icon={ArrowRightIcons}
          small={["s", "xs"].includes(activeBreakPoint)}
          onClick={nextPage}
        />
      </Wrapper>
    </PaginationContainer>
  );
};
