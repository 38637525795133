import React, { useCallback } from "react";

import { NumericButton } from "@byko/component-buttons";
import { useWindowSize } from "@byko/lib-styles";

import { Container } from "./styles";

import type { PaginationButtonProps } from "./interface";

export const PaginationButton = ({ index, setPage, variant, aria }: PaginationButtonProps): JSX.Element => {
  const { activeBreakPoint } = useWindowSize();

  const handleClick = useCallback(() => {
    setPage(index);
  }, [index, setPage]);

  return (
    <Container>
      <NumericButton
        ariaLabel={aria}
        buttonColor={variant}
        number={index + 1}
        small={["s", "xs"].includes(activeBreakPoint)}
        onClick={handleClick}
      />
    </Container>
  );
};
