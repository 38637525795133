/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const NotandiIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 12C9.239 12 7 9.761 7 7V6C7 3.239 9.239 1 12 1C14.761 1 17 3.239 17 6V7C17 9.761 14.761 12 12 12Z"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M22 20.908C22 19.108 20.803 17.525 19.066 17.052C17.172 16.535 14.586 16 12 16C9.414 16 6.828 16.535 4.934 17.052C3.197 17.525 2 19.108 2 20.908V23H22V20.908Z"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
};
