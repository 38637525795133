import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const WrenchLineDecorative: BykoReactDecorativeIconComponentType = ({ size, color = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M45.5 9.59997L39.6 15.5L32.5 8.39997L38.4 2.49997C37 2.09997 35.6 1.89997 34 1.99997C28.4 2.49997 23.9 7.49997 24 13.1C24 14.2 24.2 15.3 24.5 16.3L5.30004 33.3C2.40004 35.8 2.30004 40.3 5.00004 43C7.70004 45.7 12.1 45.6 14.7 42.7L31.7 23.5C33.4 24 35.3 24.2 37.3 23.8C41.3 23 44.6 20 45.7 16.1C46.2 13.8 46.1 11.6 45.5 9.59997Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
    </svg>
  );
};
