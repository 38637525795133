import { useSelect } from "downshift";
import * as React from "react";
import { useCallback, useState } from "react";

import { SearchInput } from "@byko/component-inputs";
import { ArrowDownIcons, ArrowUpIcons, LongArrowSideIcons } from "@byko/lib-icons";

import {
  FocusLine,
  IconWrapper,
  ListItem,
  Menu,
  MenuWrapper,
  SearchWrapper,
  SelectBtn,
  SelectContainer,
} from "./styles";

import type { SelectProps } from "@byko/lib-core";
import type { ChangeEvent } from "react";

type Item = {
  value?: string;
  label?: string;
};

export const Autocomplete = ({
  items,
  align = "left",
  fit,
  withIcon = false,
  itemToString = (item): string => item as unknown as string,
}: SelectProps<Item>): JSX.Element => {
  const [inputItems, setInputItems] = useState<Item[]>(items);
  const [inputValue, setInputValue] = useState<string>("");

  const { isOpen, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps, selectedItem } = useSelect({
    items: inputItems,
  });

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setInputValue(e.target.value);
      setInputItems(items.filter((item) => (item.label || "").toLowerCase().startsWith(e.target.value.toLowerCase())));
    },
    [setInputItems, items, setInputValue],
  );

  const handleOnClick = useCallback(() => {
    setInputValue("");
    setInputItems(items);
  }, [setInputValue, setInputItems, items]);

  return (
    <SelectContainer>
      <SelectBtn aria-label="Veldu möguleika" type="button" {...getToggleButtonProps()}>
        {!selectedItem ? "Veldu möguleika" : <p>{selectedItem.label}</p>}

        <IconWrapper>{isOpen ? <ArrowUpIcons size={24} /> : <ArrowDownIcons size={24} />}</IconWrapper>
        <FocusLine isOpen={isOpen} />
      </SelectBtn>

      <MenuWrapper align={align} aria-label="veldu möguleika" fit={!!fit} role="listbox">
        <Menu {...getMenuProps({}, { suppressRefError: true })} isOpen={isOpen} role="group">
          <SearchWrapper role="none">
            <SearchInput
              isDirty={Boolean(inputValue)}
              placeholder="Hvað viltu finna ?"
              type="text"
              value={inputValue}
              onChange={handleChange}
              onClick={handleOnClick}
            />
          </SearchWrapper>
          {inputItems.map((item, index) => {
            const highlighted = highlightedIndex === index;
            const selected = selectedItem === item.label;
            const props = getItemProps({ item, index });
            const key = index;

            return (
              <ListItem key={key} {...props} highlighted={highlighted} role="button" selected={selected}>
                {itemToString(item)}
                {withIcon ? (
                  <IconWrapper>
                    <LongArrowSideIcons size={24} />
                  </IconWrapper>
                ) : (
                  <IconWrapper>{item.value}</IconWrapper>
                )}
              </ListItem>
            );
          })}
        </Menu>
      </MenuWrapper>
    </SelectContainer>
  );
};
