import type { FilterConfiguration } from "@byko/lib-algolia";
import React, { useMemo } from "react";

import { AccordionRow } from "./accordion-row";

interface Props {
  state: Record<number | string, boolean>;
  handleToggleActive: (id: number | string) => void;
  item: FilterConfiguration;
}
export const AccordionBlockItem = ({ state, handleToggleActive, item }: Props): JSX.Element => {
  const itemWithState = useMemo(() => {
    return {
      ...item,
      active: state[item.id ?? item.label] ?? false,
    };
  }, [state, item]);
  return (
    <AccordionRow
      key={item.id}
      active={state[item.id ?? item.label] ?? false}
      configuration={itemWithState}
      toggleActive={handleToggleActive}
    />
  );
};
