import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const PickaxeLineDecorative: BykoReactDecorativeIconComponentType = ({ size, color = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M14 40L8 34" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
      <path
        d="M31.9539 10.046L35.4999 6.5L41.4999 12.5L37.9469 16.053"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path
        // eslint-disable-next-line max-len
        d="M30 24L10.242 43.758C9.84823 44.1524 9.3806 44.4653 8.86583 44.6788C8.35106 44.8923 7.79924 45.0022 7.24195 45.0022C6.68467 45.0022 6.13285 44.8923 5.61808 44.6788C5.1033 44.4653 4.63568 44.1524 4.24195 43.758C3.84755 43.3643 3.53466 42.8967 3.32117 42.3819C3.10769 41.8671 2.9978 41.3153 2.9978 40.758C2.9978 40.2007 3.10769 39.6489 3.32117 39.1341C3.53466 38.6193 3.84755 38.1517 4.24195 37.758L24 18"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path
        // eslint-disable-next-line max-len
        d="M18.072 2.59998C24.3662 4.84749 30.0823 8.4656 34.8069 13.1927C39.5316 17.9198 43.1467 23.6377 45.391 29.933L42.591 31.718C36.3059 20.7736 27.2303 11.6922 16.29 5.39998L18.072 2.59998Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
    </svg>
  );
};
