import { gql } from "graphql-tag";
import { useQuery } from "react-query";
import { graphQLClient } from "../dato-graphql-client";

import type { PagesByParentQuery, PagesByParentQueryVariables } from "../generated-gql/graphql";
import { DatoPageParts } from "./fragments";

export function useGetPagesByParent({ parentpage }: PagesByParentQueryVariables) {
  const { data, isLoading, error } = useQuery(`get-pages-parent-by-${parentpage}`, async () => {
    const request = await graphQLClient.request<{
      allPages: PagesByParentQuery;
    }>(
      gql`
        query PagesByParent($parentpage: ItemId!) {
          allPages(filter: { parentPage: { eq: $parentpage } }) {
            ...DatoPageParts
          }
        }
        ${DatoPageParts}
      `,
      { parentpage },
    );
    return request;
  });

  return { data: data?.allPages, isLoading, error };
}
