import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const IllustrationContainer = styled.div`
  svg {
    width: 100%;
    min-width: 250px;
    max-width: 400px;
    height: 100%;
    max-height: 500px;
  }
`;

export const LoaderContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;
