import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const PaintBucket2FilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M42.3561 23.388L27.2701 2.81495C27.1421 2.59695 26.7861 2.15895 26.7401 2.11295C25.2981 0.669955 22.7361 0.633955 19.5331 2.00795C16.6141 3.25795 13.3971 5.55595 10.4761 8.47695C7.5551 11.398 5.2571 14.614 4.0071 17.533C2.6331 20.739 2.6701 23.299 4.1121 24.741C4.1571 24.786 4.6111 25.152 4.8191 25.274L25.3861 40.357C25.9311 40.868 26.6401 41.098 27.4601 41.098C30.1851 41.098 34.1321 38.56 37.3451 35.348C41.5261 31.167 44.5641 25.743 42.3561 23.388Z"
        fill="#0A3A5C"
      />
      <path
        // eslint-disable-next-line max-len
        d="M6.823 31.432C6.448 30.891 5.552 30.891 5.177 31.432C4.479 32.441 1 37.601 1 40C1 42.757 3.243 45 6 45C8.757 45 11 42.757 11 40C11 37.601 7.521 32.441 6.823 31.432Z"
        fill="#FFD330"
      />
      <path
        // eslint-disable-next-line max-len
        d="M32 5H28.872L30.339 7H32C35.859 7 39 10.14 39 14C39 17.86 35.859 21 32 21H24C23.447 21 23 21.448 23 22C23 22.552 23.447 23 24 23H32C36.963 23 41 18.962 41 14C41 9.038 36.963 5 32 5Z"
        fill="#C6C6C6"
      />
      <path
        // eslint-disable-next-line max-len
        d="M20.32 3.84596C17.63 4.99896 14.636 7.14596 11.89 9.89096C9.14504 12.637 6.99804 15.63 5.84504 18.32C4.84504 20.654 4.72604 22.525 5.52704 23.326C6.32604 24.125 8.19804 24.008 10.533 23.008C13.223 21.855 16.217 19.708 18.962 16.963C21.707 14.218 23.855 11.224 25.008 8.53296C26.008 6.19996 26.127 4.32796 25.326 3.52796C24.974 3.17596 24.416 3.00196 23.703 3.00196C22.792 3.00096 21.629 3.28496 20.32 3.84596Z"
        fill="#FFD330"
      />
    </svg>
  );
};
