import * as React from "react";

import { useField } from "@byko/lib-formik";

import { Input } from "./input";

import type { InputProps } from "./interface";
import type { FieldConfig } from "@byko/lib-formik";

export const FormikInput = (
  props: InputProps & React.InputHTMLAttributes<HTMLInputElement> & FieldConfig<string | number>,
): JSX.Element => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  const errorText = (Boolean(meta.touched) && meta.error) || false;

  return <Input {...field} {...props} errorText={errorText} />;
};
