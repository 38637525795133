import React, { useCallback, useMemo } from "react";
import Link from "next/link";

import { Button } from "@byko/component-buttons";
import { ArrowRightIcons } from "@byko/lib-icons";
import { useAlgoliaInsights } from "@byko/lib-algolia";

import { ProductCardContainer } from "./styles";
import { ProductCardInformation, ProductImage } from "./components";
import type { ProductDisplayCardProps } from "./interface";

export const ProductDisplayCard = ({ product, algoliaInsights }: ProductDisplayCardProps): JSX.Element => {
  const activeVariant = product.variants[0];

  const { sendClickInsights } = useAlgoliaInsights();

  const handleClick = useCallback(() => {
    !!algoliaInsights && sendClickInsights(algoliaInsights);
  }, [algoliaInsights, sendClickInsights]);

  const displayImage = useMemo((): string => {
    if (activeVariant?.firstImage?.image?.productList) {
      return activeVariant?.firstImage?.image?.productList;
    } else if (product.firstImage?.image?.productList) {
      return product.firstImage?.image?.productList;
    } else {
      return "https://www.datocms-assets.com/65892/1664464657-placeholder-do-not-remove.jpg";
    }
  }, [activeVariant?.firstImage?.image?.productList, product.firstImage?.image?.productList]);

  return (
    <ProductCardContainer className="product-card special-no-price">
      <Link href={`/vara/${product.slug}`} onClick={handleClick}>
        <ProductImage product={product} productImage={displayImage} rental={true} />
        <div className="bottom-card-content">
          <ProductCardInformation
            addedLabel={activeVariant?.sku ?? ""}
            prodName={product.name}
            shortDecription={product.description}
            sku={""}
          />
          <Button
            buttonColor="blueButton"
            customClassName="custom-button"
            icon={ArrowRightIcons}
            label="Skoða vöru"
            stretch={true}
          />
        </div>
      </Link>
    </ProductCardContainer>
  );
};
