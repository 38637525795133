/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const PdfIcons: BykoReactIconComponentType = ({ size, iconColor, ...props }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect height="10" stroke={iconColor} strokeWidth="2" width="18" x="5" y="10" />
      <path d="M20 10.4286V7.28571L14 1H2V23H20V20.9048" stroke={iconColor} strokeWidth="2" />
      <path d="M20 7H14V1" stroke={iconColor} strokeWidth="2" />
      <path
        d="M9.04861 18C9.1006 18 9.13527 17.9647 9.13527 17.9118V15.8294C9.13527 15.7941 9.1526 15.7765 9.18726 15.7765H9.47325C10.5219 15.7765 11.2065 15.2471 11.2065 13.8882C11.2065 12.5206 10.5479 12 9.47325 12H8.08666C8.03466 12 8 12.0353 8 12.0882V17.9118C8 17.9647 8.03466 18 8.08666 18H9.04861ZM9.43858 14.7618H9.18726C9.1526 14.7618 9.13527 14.7441 9.13527 14.7088V13.0676C9.13527 13.0324 9.1526 13.0147 9.18726 13.0147H9.43858C9.88056 13.0147 10.0712 13.2088 10.0712 13.8882C10.0712 14.5676 9.88056 14.7618 9.43858 14.7618Z"
        fill={iconColor}
      />
      <path
        d="M12.5714 17.9118C12.5714 17.9647 12.6061 18 12.6581 18H14.1486C14.9633 18 15.4399 17.6824 15.6566 17.0029C15.7606 16.6676 15.8125 16.2618 15.8125 15C15.8125 13.7382 15.7606 13.3324 15.6566 12.9971C15.4399 12.3176 14.9633 12 14.1486 12H12.6581C12.6061 12 12.5714 12.0353 12.5714 12.0882V17.9118ZM13.7067 16.9059V13.0941C13.7067 13.0588 13.724 13.0412 13.7587 13.0412H14.036C14.322 13.0412 14.478 13.1471 14.5646 13.4118C14.6339 13.5971 14.6599 13.8529 14.6599 15C14.6599 16.1471 14.6339 16.4029 14.5646 16.5882C14.478 16.8529 14.322 16.9588 14.036 16.9588H13.7587C13.724 16.9588 13.7067 16.9412 13.7067 16.9059Z"
        fill={iconColor}
      />
      <path
        d="M17.2528 17.9118C17.2528 17.9647 17.2875 18 17.3395 18H18.3014C18.3534 18 18.3881 17.9647 18.3881 17.9118V15.6088C18.3881 15.5735 18.4054 15.5559 18.4401 15.5559H19.714C19.766 15.5559 19.8007 15.5206 19.8007 15.4676V14.5941C19.8007 14.5412 19.766 14.5059 19.714 14.5059H18.4401C18.4054 14.5059 18.3881 14.4882 18.3881 14.4529V13.0941C18.3881 13.0588 18.4054 13.0412 18.4401 13.0412H19.9133C19.9653 13.0412 20 13.0059 20 12.9529V12.0882C20 12.0353 19.9653 12 19.9133 12H17.3395C17.2875 12 17.2528 12.0353 17.2528 12.0882V17.9118Z"
        fill={iconColor}
      />
    </svg>
  );
};

export default PdfIcons;
