import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const YoutubeIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M23.8 7.19998C23.8 7.19998 23.6 5.49998 22.8 4.79998C21.9 3.79998 20.9 3.79998 20.4 3.79998C17 3.59998 12 3.59998 12 3.59998C12 3.59998 7 3.59998 3.6 3.79998C3.1 3.89998 2.1 3.89998 1.2 4.79998C0.5 5.49998 0.2 7.19998 0.2 7.19998C0.2 7.19998 0 9.09998 0 11.1V12.9C0 14.8 0.2 16.8 0.2 16.8C0.2 16.8 0.4 18.5 1.2 19.2C2.1 20.2 3.3 20.1 3.8 20.2C5.7 20.4 12 20.4 12 20.4C12 20.4 17 20.4 20.4 20.1C20.9 20 21.9 20 22.8 19.1C23.5 18.4 23.8 16.7 23.8 16.7C23.8 16.7 24 14.8 24 12.8V11C24 9.09998 23.8 7.19998 23.8 7.19998ZM9.5 15.1V8.39998L16 11.8L9.5 15.1Z"
        fill={iconColor}
      />
    </svg>
  );
};

export default YoutubeIcons;
