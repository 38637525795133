export const Sort = [
  {
    id: 0,
    label: "Vinsælast",
    value: "Vinsælast",
  },
  {
    id: 1,
    label: "Dýrast efst",
    value: "Dýrast efst",
  },
  {
    id: 2,
    label: "Ódýrast efst",
    value: "Ódýrast efst",
  },
  {
    id: 3,
    label: "Nýjast efst",
    value: "Nýjast efst",
  },
];

export type Sort = typeof Sort[number] & { active?: boolean };

export interface SearchResult {
  handleClick: (id: number) => void;
  values: Sort[];
  active: number;
  activeItem: Sort | undefined;
}
