import React, { forwardRef, useCallback, useEffect, useMemo, useState } from "react";

import { Button, IconButton } from "@byko/component-buttons";
import { DotLoader, MainLoader } from "@byko/component-loaders";
import { QuantityInput } from "@byko/component-quantity-input";
import { Select } from "@byko/component-select";
import { EyebrowSmall, H6, PMedium } from "@byko/component-typography";
import { useAuth } from "@byko/hooks-auth-next";
import { useCart } from "@byko/hooks-cart";
import { useProductVariants } from "@byko/hooks-product-variants";
import { getPrice } from "@byko/lib-api-price";
import { productBySlug, useCartItemCount, useProductPrices } from "@byko/lib-api-products";
import { BagAddIcons, CancelLargeIcons, LongArrowRightIcons } from "@byko/lib-icons";
import { theme } from "@byko/lib-styles";
import { useAlgoliaInsights } from "@byko/lib-algolia";

import {
  ButtonContainer,
  ButtonLoadingWrapper,
  DetailButtonWrapper,
  FullDescription,
  LoadingWrapper,
  ProductDescription,
  ProductDetailContainer,
  ProductHead,
  ProductInfo,
  VariantPrice,
  VariantSelectionOptions,
} from "./styles";

import type { ProductCardOpenProps } from "./interface";
import type { ProductDetailAugmented } from "@byko/lib-api-products";

export const ProductCardOpen = forwardRef<HTMLDivElement, ProductCardOpenProps>(
  (
    {
      slug,
      show = false,
      activeVariantSKU,
      setActiveVariantSKU,
      handleClose,
      onClickProductDetailPage,
      localPrices,
      setLocalPrices,
      isLoading,
      algoliaInsights,
    },
    ref,
  ): JSX.Element | null => {
    const [product, setProduct] = useState<ProductDetailAugmented>();
    const { prices } = useProductPrices(product ? [product.id] : []);
    const { sendAddToCartInsights } = useAlgoliaInsights();

    const { user } = useAuth();

    useEffect(() => {
      if (prices && product) {
        setLocalPrices(prices);
      }
    });

    useEffect(() => {
      const fetchProduct = async (): Promise<void> => {
        if (show) {
          const data = await productBySlug(slug);
          if (data) {
            setProduct(data);
          }
        }
      };

      fetchProduct();
    }, [show, slug]);

    const {
      activeVariant,
      activeColor,
      colorSelectionList,
      sizeSelectionList,
      activeSize,
      handleColorChange,
      handleSizeChange,
      permitPurchase,
      errorMessage,
    } = useProductVariants(product, activeVariantSKU, setActiveVariantSKU, null);

    const price = useMemo(() => {
      return localPrices?.[activeVariantSKU];
    }, [activeVariantSKU, localPrices]);

    const productNotAvailableYet = useMemo(() => {
      return price?.price.gross === 0;
    }, [price]);

    const unitLabel = useMemo(() => {
      if (activeVariant?.meta.unit) {
        return activeVariant?.meta.unit;
      } else {
        const units = activeVariant?.meta.unit?.toString().split(";") ?? [];
        if (units.length === 0) {
          return "";
        }
        return units[units.length - 1];
      }
    }, [activeVariant?.meta.unit]);

    const currentCount = useCartItemCount(activeVariant?.id ?? null);
    const [count, setCount] = useState<number>(currentCount > 1 ? 1 : currentCount);

    useEffect(() => {
      if (currentCount <= 0) {
        setCount(1);
        return;
      }
      setCount(currentCount);
    }, [currentCount]);

    const handleItemToString = useCallback(
      (option: { label?: string | null } | null): string => option?.label ?? "",
      [],
    );

    const { setCartQuantity } = useCart();

    const handleAddToCart = useCallback(() => {
      if (isNaN(count)) {
        return;
      }
      setCartQuantity({ variantId: activeVariant?.id, slug: product?.slug ?? "", quantity: count });

      sendAddToCartInsights({
        eventName: "Add to Cart",
        queryID: algoliaInsights?.queryID || "",
        index: algoliaInsights?.index || "",
        objectIDs: [activeVariant?.sku || ""],
      });
    }, [
      activeVariant?.id,
      activeVariant?.sku,
      algoliaInsights?.index,
      algoliaInsights?.queryID,
      count,
      product?.slug,
      sendAddToCartInsights,
      setCartQuantity,
    ]);

    if (localPrices == undefined) {
      return null;
    }

    return (
      <ProductDetailContainer ref={ref} className="swiper-no-swiping" show={show ?? false}>
        <ProductInfo>
          <ProductDescription>
            <ProductHead>
              <H6 maxFontSize="28px" textColor={theme.palette.blue.dark}>
                {product?.name}
              </H6>
              <ButtonContainer>
                <IconButton
                  ariaLabel={"Loka"}
                  buttonColor="whiteButton"
                  icon={CancelLargeIcons}
                  small={true}
                  onClick={handleClose}
                />
              </ButtonContainer>
            </ProductHead>
            <FullDescription textColor={theme.palette.gray[60]}>{product?.description}</FullDescription>
          </ProductDescription>
          {!errorMessage && productNotAvailableYet ? (
            <VariantPrice>
              <H6 maxFontSize="18px" textColor={theme.palette.blue.main}>
                Vara væntanleg
              </H6>
            </VariantPrice>
          ) : null}
          {!errorMessage && !productNotAvailableYet ? (
            <VariantPrice>
              <EyebrowSmall textColor={theme.palette.gray[60]}>{user ? "MITT VERÐ" : "VERÐ"}</EyebrowSmall>
              {(price?.discounted?.gross || price?.price?.gross) && (
                <H6 maxFontSize="28px" textColor={theme.palette.blue.main}>
                  {price?.discounted?.gross
                    ? getPrice(price?.discounted?.gross)?.humanReadable
                    : getPrice(price?.price?.gross)?.humanReadable}
                </H6>
              )}
              {price?.discounted?.gross === undefined && price?.price?.gross === undefined && (
                <LoadingWrapper>
                  <DotLoader size={84} />
                </LoadingWrapper>
              )}
            </VariantPrice>
          ) : null}
          {errorMessage ? (
            <ProductDescription>
              <PMedium textColor={theme.palette.orange.dark}>{errorMessage}</PMedium>
            </ProductDescription>
          ) : null}
        </ProductInfo>
        <VariantSelectionOptions>
          {colorSelectionList.length > 1 ? (
            <Select
              align="left"
              className="select-container first"
              // disableInactive={true} // FIXME
              fit={true}
              handleSelectedItemChange={handleColorChange}
              hideDefaultPlaceholder={true}
              hideValue={true}
              items={colorSelectionList}
              itemToString={handleItemToString}
              placeholder={activeColor?.label ?? "Veldu lit"}
              selectedItem={activeColor}
              textColor={activeColor?.label ? theme.palette.blue.main : theme.palette.gray[40]}
            />
          ) : null}
          {sizeSelectionList.length > 1 && (
            <Select
              align="left"
              className="select-container second"
              // disableInactive={true} // FIXME
              fit={true}
              handleSelectedItemChange={handleSizeChange}
              hideDefaultPlaceholder={true}
              hideValue={true}
              items={sizeSelectionList}
              itemToString={handleItemToString}
              placeholder={activeSize?.label ?? "Veldu stærð"}
              selectedItem={activeSize}
              textColor={activeSize?.label ? theme.palette.blue.main : theme.palette.gray[40]}
            />
          )}
        </VariantSelectionOptions>
        <DetailButtonWrapper>
          <QuantityInput quantity={count} quantityUnit={unitLabel ?? ""} setQuantity={setCount} stretch={true} />
          <>
            {isLoading ? (
              <ButtonLoadingWrapper>
                <MainLoader size={54} />
              </ButtonLoadingWrapper>
            ) : (
              <Button
                buttonColor="blueButton"
                customClassName="add-to-cart-button"
                disabled={!permitPurchase || !activeVariant?.webstoreInStock || productNotAvailableYet}
                icon={BagAddIcons}
                label="Bæta í körfu"
                stretch={true}
                onClick={handleAddToCart}
              />
            )}
          </>
          <Button
            disabled={false}
            icon={LongArrowRightIcons}
            label="Skoða vöru"
            stretch={true}
            onClick={onClickProductDetailPage}
          />
        </DetailButtonWrapper>
      </ProductDetailContainer>
    );
  },
);
