import React, { useCallback, useMemo } from "react";

import { IconButton } from "@byko/component-buttons";
import { useAuth } from "@byko/hooks-auth-next";
import { useRouter } from "next/router";
import { ArrowDownIcons, CancelSmallIcons, LogoutIcons, NotandiIcons, SettingsIcons } from "@byko/lib-icons";
import { useRecoilState } from "@byko/lib-recoil";
import { theme } from "@byko/lib-styles";
import { useCheckoutExtra } from "@byko/hooks-cart";

import { collapsedState } from "./collapsed";
import { DropdownSelectList } from "./dropdown-select-list";
import { DropdownSelectSearch } from "./dropdown-select-search";
import {
  DropdownSelectActiveContainer,
  DropdownSelectActiveLabel,
  DropdownSelectContainer,
  DropdownSelectOptionRowLabel,
  DropdownSelectOptionsContainer,
  DropdownSelectOptionsInner,
  DropdownSelectOptionsWrapper,
  DropdownSelectionOption,
} from "./styles";

import type { DropdownSelectProps } from "./interface";
import type { CustomerAccountMembership } from "@byko/lib-api-rest";

export const DropdownSelect = ({
  active,
  options,
  fullWidth = false,
  onClick,
  icon: Icon = NotandiIcons,
  iconButton = false,
}: DropdownSelectProps): JSX.Element | null => {
  const router = useRouter();
  const [collapsed, setCollapsed] = useRecoilState<boolean>(collapsedState);
  const { logout, memberships } = useAuth();
  const { fetchCheckoutData } = useCheckoutExtra();
  const sortedOptions = useMemo(() => {
    const tmp: CustomerAccountMembership[] = [...options];

    return tmp.sort((a, b) => {
      if (a.account.name && b.account.name) {
        return a.account.name.localeCompare(b.account.name);
      }

      return 1;
    });
  }, [options]);

  const handleGoToSettings = useCallback(() => {
    setCollapsed(true);

    // eslint-disable-next-line no-console
    router.push("/mitt/stillingar");
  }, [router, setCollapsed]);

  const handleGoToMyByko = useCallback(() => {
    setCollapsed(true);

    // eslint-disable-next-line no-console
    router.push("/mitt/forsida");
  }, [router, setCollapsed]);

  const handleLogOut = useCallback(async () => {
    setCollapsed(true);

    logout();
    await fetchCheckoutData();
  }, [logout, setCollapsed, fetchCheckoutData]);

  const toggleCollapse = useCallback(() => {
    setCollapsed((current) => !current);
  }, [setCollapsed]);

  if (!active) {
    return null;
  }

  return (
    <DropdownSelectContainer className="drop-down" fullWidth={fullWidth} onClick={onClick}>
      {iconButton && Icon ? (
        <IconButton
          ariaLabel="valmynd"
          className="drop-down-active-container"
          icon={collapsed ? Icon : CancelSmallIcons}
          onClick={toggleCollapse}
        />
      ) : (
        <DropdownSelectActiveContainer
          className="drop-down-active-container"
          fullWidth={!!fullWidth}
          onClick={toggleCollapse}
        >
          <DropdownSelectActiveLabel className="active-name">{active?.account?.name}</DropdownSelectActiveLabel>
          <ArrowDownIcons size={32} />
        </DropdownSelectActiveContainer>
      )}
      <DropdownSelectOptionsContainer collapsed={collapsed}>
        {!collapsed ? (
          <>
            <DropdownSelectOptionsWrapper>
              <DropdownSelectOptionsInner>
                {memberships.length > 2 ? (
                  <DropdownSelectSearch options={sortedOptions} />
                ) : (
                  <DropdownSelectList options={sortedOptions} />
                )}
              </DropdownSelectOptionsInner>
            </DropdownSelectOptionsWrapper>
            <DropdownSelectionOption className="settings" onClick={handleGoToMyByko}>
              <DropdownSelectOptionRowLabel className="logout-label" color={theme.palette.blue.dark}>
                Mitt BYKO
              </DropdownSelectOptionRowLabel>
              <NotandiIcons className="logout-icon" iconColor={theme.palette.blue.dark} size={18} />
            </DropdownSelectionOption>
            <DropdownSelectionOption className="settings" onClick={handleGoToSettings}>
              <DropdownSelectOptionRowLabel className="logout-label" color={theme.palette.blue.dark}>
                Stillingar
              </DropdownSelectOptionRowLabel>
              <SettingsIcons className="logout-icon" iconColor={theme.palette.blue.dark} size={18} />
            </DropdownSelectionOption>
            <DropdownSelectionOption className="logout" onClick={handleLogOut}>
              <DropdownSelectOptionRowLabel className="logout-label" color={theme.palette.white.main}>
                Skrá út
              </DropdownSelectOptionRowLabel>
              <LogoutIcons className="logout-icon" iconColor={theme.palette.white.main} size={18} />
            </DropdownSelectionOption>
          </>
        ) : null}
      </DropdownSelectOptionsContainer>
    </DropdownSelectContainer>
  );
};
