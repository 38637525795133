import styled from "styled-components";

import type { WrapperStyleProps } from "./interface";

export const PaginationContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
`;

export const Wrapper = styled.div<WrapperStyleProps>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: ${(props): string => props.align};
  p {
    user-select: none;
  }
`;
