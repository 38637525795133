import { useMemo } from "react";

import { useAuth } from "@byko/hooks-auth-next";
import { getPrice } from "@byko/lib-api-price";

import type { MainPriceWithLabel, PreviousPriceWithLabel, Prices, useProductPricingProps } from "./interface";
import type { ProductListAugmented } from "@byko/lib-api-products";

export const useProductPricing = (
  product: ProductListAugmented,
  prices: Prices | undefined,
  activeVariantSKU: string,
): useProductPricingProps => {
  const price = prices?.[activeVariantSKU];
  const isSqmProduct = prices?.[activeVariantSKU]?.isSqmProduct ?? false;
  const { user } = useAuth();

  const lowestPrices = useMemo((): {
    discount: string | null;
    retail: string | null;
  } => {
    const discountedList: { discount: number; retail: number }[] = [];
    const retailList: number[] = [];

    product.variants.forEach(({ sku }) => {
      if (sku) {
        const variantPrice = prices?.[sku];

        const discount = variantPrice?.discounted?.gross;
        const retail = variantPrice?.price.gross;

        // we have both discount and retail price for this variant
        if (discount && retail) {
          discountedList.push({
            discount,
            retail,
          });
        }

        // we have retail price for this variant, stored seperately
        if (retail) {
          retailList.push(retail);
        }
      }
    });

    // we sort any discount prices
    const sortedDiscount = discountedList.sort((a, b) => {
      if (a.discount < b.discount) {
        return -1;
      }
      if (a.discount > b.discount) {
        return 1;
      }
      return 0;
    });

    // we stort any retail prices
    const sortedRetail = retailList.sort((a, b) => a - b);

    // we have variying discount prices
    if (sortedDiscount.length && sortedDiscount[0] !== sortedDiscount[sortedDiscount.length - 1]) {
      // we return the discounted price with the accompanying retail price
      return {
        discount: getPrice(sortedDiscount[0]?.discount ?? 0)?.humanReadable ?? "",
        retail: getPrice(sortedDiscount[0]?.retail ?? 0)?.humanReadable ?? "",
      };
    }

    // we have variying retail prices
    if (sortedRetail.length && sortedRetail[0] !== sortedRetail[sortedRetail.length - 1]) {
      return {
        discount: null,
        retail: getPrice(sortedRetail[0] ?? 0)?.humanReadable ?? "",
      };
    }

    return {
      discount: null,
      retail: null,
    };
  }, [prices, product.variants]);

  const retailPrice = useMemo((): string | null => {
    if (price?.price) {
      return getPrice(price.price.gross ?? 0)?.humanReadable ?? "";
    }
    return null;
  }, [price?.price]);

  const discountedPrice = useMemo((): string | null => {
    if (price?.discounted) {
      return getPrice(price.discounted.gross ?? 0)?.humanReadable ?? "";
    }
    return null;
  }, [price?.discounted]);

  const mainPrice = useMemo((): MainPriceWithLabel | null => {
    const firstPrice = product.variants[0]?.price.gross ?? 0;
    const usePriceFromLabel = product.variants.some((variant) => {
      if (firstPrice !== variant.price?.gross) {
        return true;
      }
      return false;
    });

    const label = usePriceFromLabel ? "VERÐ FRÁ" : user ? "MITT VERÐ" : "VERÐ";

    let localPrice;
    const activeVariantPrice = prices?.[activeVariantSKU];
    if (activeVariantPrice?.isSqmProduct) {
      if (activeVariantPrice.discounted?.perSqm) {
        localPrice = getPrice(activeVariantPrice.discounted.perSqm)?.humanReadable ?? "";
      } else {
        localPrice = getPrice(activeVariantPrice.price.perSqm ?? null)?.humanReadable ?? "";
      }
    } else if (lowestPrices?.discount && lowestPrices?.retail) {
      // Display lowest discount price out of all variants
      localPrice = lowestPrices.discount;
    } else if (!lowestPrices?.discount && lowestPrices?.retail) {
      // Display lowest retail price out of all variants
      localPrice = lowestPrices.retail;
    } else if (!lowestPrices?.discount && !lowestPrices?.retail && discountedPrice) {
      // Display standard discount price, same price applies to all variants
      localPrice = discountedPrice;
    } else if (!lowestPrices?.discount && !lowestPrices?.retail && !discountedPrice && retailPrice) {
      // Display standard retail price, same price applies to all variants
      localPrice = retailPrice;
    } else {
      // this should never happen except when loading
      return null;
    }
    return {
      label,
      price: localPrice,
    };
  }, [
    activeVariantSKU,
    discountedPrice,
    lowestPrices.discount,
    lowestPrices.retail,
    prices,
    product.variants,
    retailPrice,
    user,
  ]);

  const previousPrice = useMemo((): PreviousPriceWithLabel | null => {
    const label = "VERÐ ÁÐUR";

    let localPrice;

    const activeVariantPrice = prices?.[activeVariantSKU];
    if (activeVariantPrice?.isSqmProduct && activeVariantPrice.discounted?.perSqm) {
      localPrice = getPrice(activeVariantPrice.price.perSqm ?? null)?.humanReadable ?? "";
    } else if (lowestPrices?.discount && lowestPrices.retail) {
      localPrice = lowestPrices.retail;
    } else if (!lowestPrices?.discount && !lowestPrices?.retail && discountedPrice && retailPrice) {
      localPrice = retailPrice;
    } else {
      // this should never happen except when loading
      return null;
    }

    return {
      label,
      price: localPrice,
    };
  }, [activeVariantSKU, discountedPrice, lowestPrices?.discount, lowestPrices.retail, prices, retailPrice]);

  const loadingPrices = useMemo(() => {
    return previousPrice === null && mainPrice === null;
  }, [mainPrice, previousPrice]);

  return {
    loadingPrices,
    mainPrice,
    previousPrice,
    isSqmProduct,
  };
};
