import { css } from "styled-components";

export const Variables = css`
  :root {
    --font-main: "Nobel";
    --fz-xxs: 10px;
    --fz-xs: 12px;
    --fz-sm: 14px;
    --fz-md: 16px;
    --fz-lg: 18px;
    --fz-xl: 20px;
    --fz-xxl: 22px;
    --white: #fff;
    --black: #000;
    --gray: #7f7f7f;
    --bluelightest: "#80B3D2";
    --blue: #057dcd;
    --bluedark: #0a3a5c;
    --background: #f6f6f6;
    --disable: #c6c6c6;
    --transition: all 0.25s ease-in-out;
  }
`;
