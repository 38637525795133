import { gql } from "graphql-tag";
import { useQuery } from "react-query";
import { graphQLClient } from "../dato-graphql-client";

import type { AllPagesQueryVariables, PageRecord } from "../generated-gql/graphql";
import { DatoPageParts } from "./fragments";

export async function getAllPages({ first, id, skip }: AllPagesQueryVariables) {
  try {
    const request = await graphQLClient.request<{
      allPages: PageRecord[];
      meta: {
        count: number;
      };
    }>(
      gql`
        query AllPages($first: IntType, $skip: IntType, $id: ItemId) {
          allPages(orderBy: id_ASC, first: $first, skip: $skip, filter: { id: { eq: $id } }) {
            ...DatoPageParts
          }
          meta: _allPagesMeta {
            count
          }
        }
        ${DatoPageParts}
      `,
      {
        first,
        skip,
        id,
      },
    );
    return request;
  } catch (error) {
    console.error("Error in getAllPages:", error);
    throw error;
  }
}

export function useGetAllPages({ first, id, skip }: AllPagesQueryVariables) {
  const { data, isLoading, error } = useQuery("get-all-pages", async () => {
    const result = await getAllPages({ first, id, skip });
    return result;
  });

  return { data: data?.allPages, isLoading, error, meta: data?.meta };
}
