import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const PaintbrushIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_805_5142)">
        <path
          d="M15.021 18.879L22.092 11.808L19.971 9.68596H17.142V6.85796L12.192 1.90796L5.12097 8.97896"
          stroke={iconColor}
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          // eslint-disable-next-line max-len
          d="M4.41402 9.686C3.21202 10.888 3.21202 12.727 4.41402 13.929L5.82802 15.343L1.65602 19.515C0.949021 20.222 0.807021 21.353 1.37302 22.202C2.15102 23.263 3.56502 23.263 4.41402 22.414L8.65702 18.171L10.071 19.585C11.273 20.787 13.112 20.787 14.314 19.585L15.021 18.878L5.12102 8.979L4.41402 9.686Z"
          stroke={iconColor}
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_805_5142">
          <rect fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PaintbrushIcons;
