/* eslint-disable max-len */
import * as React from "react";
import { BykoReactIconComponentType } from "@byko/lib-core";

export const SkipuleggjaIllustration: BykoReactIconComponentType = ({ size = 200 }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 662 611" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M120.15 67.76C55.9401 82.4 30.7001 149.3 55.0201 194.2C71.6301 224.86 102.71 254.63 159.92 270.2C307.77 310.38 248.92 419.9 386.17 487.51C448.1 522.91 536.69 509.67 536.69 509.67C536.69 509.67 551.06 383.31 547.12 310.9C462.29 295.83 409.58 249.45 364.12 187.46C318.66 125.47 258.96 36.14 120.15 67.76Z"
        fill="#D9EEE8"
      />
      <path
        d="M481.02 469.38C481.02 469.38 472.25 519.49 536.69 509.65C633.28 494.92 685.9 324.73 547.12 310.88C408.34 297.03 481.02 469.38 481.02 469.38Z"
        fill="#D9EEE8"
      />
      <path
        d="M527.88 555.45C467.37 564.56 414.28 549.45 378.5 533.84C333.75 514.3 305.67 489.72 305.39 489.47L307.29 487.32C307.57 487.56 335.37 511.89 379.74 531.25C420.61 549.09 484.33 566.25 555.74 546.62L556.5 549.38C547.089 551.969 537.532 553.996 527.88 555.45Z"
        fill="#66A4C9"
      />
      <path
        d="M411.16 294.75C411.16 294.75 401.98 275.31 356.36 276.38C310.74 277.45 278.83 303.48 274.8 345.59C270.77 387.7 379.94 389.29 379.94 389.29L427.8 319.84L411.16 294.75Z"
        fill="#003C71"
      />
      <path d="M446.3 443.64L440.25 404.85H336.63V443.64H433.58H446.3Z" fill="#A1D4C7" />
      <path
        d="M444.27 443.64H509.46C515.36 433.24 519.18 418.44 515.82 400.57L466.18 384.5C466.18 384.5 411.4 384.4 432.13 425.33C435.393 431.918 439.472 438.07 444.27 443.64Z"
        fill="#A1D4C7"
      />
      <path
        d="M439.97 288.82C439.97 288.82 436.74 258.67 399.85 270.25C407.44 293.67 411.16 294.75 411.16 294.75L439.97 288.82Z"
        fill="white"
      />
      <path
        d="M407.44 349.14C406.486 346.835 404.727 344.955 402.49 343.851C400.252 342.747 397.69 342.494 395.28 343.14C386.12 345.14 379.93 342.4 377.78 337.83C375.63 333.26 377.37 322.99 387.4 321.4C397.43 319.81 418.4 325.71 418.4 325.71V343.14L407.44 349.14Z"
        fill="white"
      />
      <path
        d="M447.9 307.39C447.9 307.39 444.14 315.61 449.99 320.18C456.72 325.43 452.29 335.25 443.86 334.24C435.43 333.23 434.37 337.95 435.4 341.99C436.97 348.13 427.91 350.4 421.45 349.59C414.99 348.78 421.45 317.08 421.45 317.08L447.9 307.39Z"
        fill="white"
      />
      <path
        d="M378.6 357.48C398.66 355.17 405.01 347.39 406.41 340.28C402.275 340.041 398.257 338.817 394.69 336.71C373.08 324.29 387.22 286.94 387.22 286.94C439.72 267.82 440.53 296.89 440.53 296.89C440.53 296.89 450.22 297.17 450.22 304.71C450.22 313.86 440.53 313.59 440.53 313.59C439.263 317.886 437.148 321.885 434.31 325.35C431.716 328.939 430.294 333.242 430.24 337.67C430.47 342.75 430.24 347.17 433.6 349.99C436.74 352.63 442.68 353.71 452.91 356.13C463.14 358.55 512.38 366.9 513.46 410.79H332.51C332.51 410.79 331.92 362.87 378.6 357.48Z"
        fill="#F59795"
      />
      <path
        d="M398.51 300.99C398.51 300.99 397.11 300.49 395.68 302.83C393.68 306.12 390.62 312.62 391.68 316.56C392.62 320.19 400 321.15 404.74 317.68C407.1 315.95 406.47 312.23 402.74 312.18C399.37 312.14 400.48 301.68 398.51 300.99Z"
        fill="#FBCCCB"
      />
      <path
        d="M407.44 305.65C408.556 305.65 409.46 304.746 409.46 303.63C409.46 302.514 408.556 301.61 407.44 301.61C406.325 301.61 405.42 302.514 405.42 303.63C405.42 304.746 406.325 305.65 407.44 305.65Z"
        fill="#003C71"
      />
      <path
        d="M386.25 305.65C387.366 305.65 388.27 304.746 388.27 303.63C388.27 302.514 387.366 301.61 386.25 301.61C385.135 301.61 384.23 302.514 384.23 303.63C384.23 304.746 385.135 305.65 386.25 305.65Z"
        fill="#003C71"
      />
      <path
        d="M435.06 281.29C423.88 283.63 425.57 298.05 440.51 296.89C442.883 296.807 445.218 297.51 447.15 298.89C447.15 298.89 451.41 277.85 435.06 281.29Z"
        fill="white"
      />
      <path
        d="M419.46 280.48C411.2 280.81 407.46 284.48 400.93 289.92C393.36 296.26 383.14 299.06 376.93 294.48C370.72 289.9 373.44 280.65 384.75 280.48C371.82 280.96 372.09 262.76 394.44 265.59C416.79 268.42 419.46 280.48 419.46 280.48Z"
        fill="white"
      />
      <path
        d="M433.6 292.21V289.14H436.42V293.23C439.71 294.51 444.23 296.56 440.53 296.87C435.08 297.32 434.67 301.05 437.75 307.92C438.299 309 438.639 310.174 438.75 311.38C438.743 311.777 438.587 312.158 438.313 312.446C438.039 312.734 437.667 312.909 437.271 312.937C436.874 312.964 436.482 312.842 436.171 312.595C435.86 312.347 435.653 311.993 435.59 311.6C435.109 308.644 433.825 305.877 431.88 303.6C427.38 298.32 433.6 292.21 433.6 292.21Z"
        fill="white"
      />
      <path
        d="M380.76 356.14C386.437 355.567 392.008 354.221 397.32 352.14C397.32 352.14 413 371.57 435.92 351.52C452.461 353.754 468.614 358.263 483.92 364.92C518.25 380.14 517.08 410.85 517.08 410.85L373.72 421.28L320.14 410.79C336.52 375.77 347.81 360.01 380.76 356.14Z"
        fill="#A1D4C7"
      />
      <path
        d="M402.01 331.72V328.85C406.85 328.85 410.84 327.44 413.87 324.65L415.81 326.76C413.35 329.03 408.96 331.72 402.01 331.72Z"
        fill="#003C71"
      />
      <path d="M240.5 443.64H418.31L414.72 430.71H240.5V443.64Z" fill="#408DBC" />
      <path d="M327.82 340.79H211.96L240.5 443.64H356.36L327.82 340.79Z" fill="#408DBC" />
      <path
        d="M517.82 423.27L514.96 423.04C516.89 399.04 507.63 380.62 487.44 368.3C477.79 362.564 467.155 358.677 456.08 356.84L456.44 354C457.11 354.08 522.6 363.24 517.82 423.27Z"
        fill="#003C71"
      />
      <path
        d="M407.5 315.64C405.489 315.64 403.522 315.044 401.85 313.926C400.178 312.809 398.874 311.22 398.104 309.362C397.335 307.504 397.133 305.459 397.526 303.486C397.918 301.513 398.887 299.701 400.309 298.279C401.731 296.856 403.543 295.888 405.516 295.495C407.489 295.103 409.534 295.304 411.392 296.074C413.25 296.844 414.839 298.147 415.956 299.82C417.074 301.492 417.67 303.459 417.67 305.47C417.668 308.166 416.595 310.752 414.688 312.658C412.782 314.565 410.197 315.637 407.5 315.64ZM407.5 298.17C406.056 298.17 404.645 298.598 403.444 299.4C402.244 300.202 401.308 301.343 400.756 302.676C400.203 304.01 400.059 305.478 400.34 306.894C400.622 308.31 401.317 309.611 402.338 310.632C403.359 311.653 404.66 312.348 406.076 312.63C407.492 312.911 408.96 312.767 410.294 312.214C411.628 311.662 412.768 310.726 413.57 309.526C414.372 308.325 414.8 306.914 414.8 305.47C414.803 304.509 414.616 303.557 414.25 302.669C413.885 301.78 413.348 300.972 412.67 300.292C411.992 299.611 411.186 299.07 410.299 298.701C409.412 298.332 408.461 298.141 407.5 298.14V298.17Z"
        fill="white"
      />
      <path
        d="M384.12 315.64C382.109 315.64 380.142 315.044 378.47 313.926C376.798 312.809 375.494 311.22 374.724 309.362C373.955 307.504 373.753 305.459 374.146 303.486C374.538 301.513 375.507 299.701 376.929 298.279C378.351 296.856 380.163 295.888 382.136 295.495C384.109 295.103 386.154 295.304 388.012 296.074C389.87 296.844 391.459 298.147 392.576 299.82C393.694 301.492 394.29 303.459 394.29 305.47C394.287 308.166 393.215 310.752 391.308 312.658C389.402 314.565 386.817 315.637 384.12 315.64ZM384.12 298.17C382.676 298.17 381.265 298.598 380.064 299.4C378.864 300.202 377.928 301.343 377.376 302.676C376.823 304.01 376.679 305.478 376.96 306.894C377.242 308.31 377.937 309.611 378.958 310.632C379.979 311.653 381.28 312.348 382.696 312.63C384.112 312.911 385.58 312.767 386.914 312.214C388.248 311.662 389.388 310.726 390.19 309.526C390.992 308.325 391.42 306.914 391.42 305.47C391.423 303.53 390.656 301.669 389.287 300.295C387.918 298.92 386.06 298.145 384.12 298.14V298.17Z"
        fill="white"
      />
      <path d="M433.58 304.04H416.23V306.91H433.58V304.04Z" fill="white" />
      <path
        d="M415.35 361.44C409.49 361.44 402.73 359.49 396.35 353.1L398.35 351.1C411.66 364.34 426.61 356.34 429.46 354.6L430.24 355.8L430.96 357.05C426.227 359.845 420.847 361.358 415.35 361.44Z"
        fill="#003C71"
      />
      <path
        d="M439.08 362.83C441.378 362.83 443.24 360.968 443.24 358.67C443.24 356.373 441.378 354.51 439.08 354.51C436.783 354.51 434.92 356.373 434.92 358.67C434.92 360.968 436.783 362.83 439.08 362.83Z"
        fill="#80C5B3"
      />
      <path
        d="M430.22 369.02C432.518 369.02 434.38 367.158 434.38 364.86C434.38 362.563 432.518 360.7 430.22 360.7C427.923 360.7 426.06 362.563 426.06 364.86C426.06 367.158 427.923 369.02 430.22 369.02Z"
        fill="#80C5B3"
      />
      <path
        d="M418.57 372.11C420.868 372.11 422.73 370.248 422.73 367.95C422.73 365.653 420.868 363.79 418.57 363.79C416.273 363.79 414.41 365.653 414.41 367.95C414.41 370.248 416.273 372.11 418.57 372.11Z"
        fill="#80C5B3"
      />
      <path
        d="M406.17 371.14C408.468 371.14 410.33 369.278 410.33 366.98C410.33 364.683 408.468 362.82 406.17 362.82C403.873 362.82 402.01 364.683 402.01 366.98C402.01 369.278 403.873 371.14 406.17 371.14Z"
        fill="#80C5B3"
      />
      <path
        d="M394.67 365.89C396.968 365.89 398.83 364.028 398.83 361.73C398.83 359.433 396.968 357.57 394.67 357.57C392.373 357.57 390.51 359.433 390.51 361.73C390.51 364.028 392.373 365.89 394.67 365.89Z"
        fill="#80C5B3"
      />
      <path
        d="M389.42 357.41C391.718 357.41 393.58 355.548 393.58 353.25C393.58 350.952 391.718 349.09 389.42 349.09C387.123 349.09 385.26 350.952 385.26 353.25C385.26 355.548 387.123 357.41 389.42 357.41Z"
        fill="#80C5B3"
      />
      <path
        d="M443.49 353.91C445.788 353.91 447.65 352.048 447.65 349.75C447.65 347.452 445.788 345.59 443.49 345.59C441.193 345.59 439.33 347.452 439.33 349.75C439.33 352.048 441.193 353.91 443.49 353.91Z"
        fill="#80C5B3"
      />
      <path
        d="M283.83 196.2V160.2C283.83 159.044 283.371 157.935 282.553 157.117C281.735 156.299 280.626 155.84 279.47 155.84H184.35C183.194 155.84 182.085 156.299 181.267 157.117C180.449 157.935 179.99 159.044 179.99 160.2V188.62C179.99 189.776 180.449 190.885 181.267 191.703C182.085 192.521 183.194 192.98 184.35 192.98H266.78C267.751 192.977 268.695 193.301 269.46 193.9L276.79 199.61C277.432 200.11 278.202 200.421 279.011 200.507C279.821 200.593 280.638 200.45 281.371 200.095C282.104 199.74 282.722 199.187 283.157 198.499C283.591 197.81 283.825 197.014 283.83 196.2Z"
        fill="#0067A5"
      />
      <path d="M217.54 164.93H189.61V167.8H217.54V164.93Z" fill="white" />
      <path d="M243.52 174.61H189.61V177.48H243.52V174.61Z" fill="white" />
      <path
        d="M121.6 258.14V222.14C121.603 220.983 122.064 219.874 122.884 219.056C123.703 218.239 124.813 217.78 125.97 217.78H221.09C221.663 217.78 222.23 217.893 222.759 218.112C223.288 218.331 223.768 218.652 224.173 219.057C224.578 219.462 224.899 219.943 225.118 220.472C225.337 221.001 225.45 221.567 225.45 222.14V250.56C225.45 251.133 225.337 251.7 225.118 252.229C224.899 252.758 224.578 253.238 224.173 253.643C223.768 254.048 223.288 254.369 222.759 254.588C222.23 254.807 221.663 254.92 221.09 254.92H138.6C137.63 254.924 136.688 255.247 135.92 255.84L128.6 261.56C127.957 262.049 127.191 262.35 126.387 262.429C125.582 262.509 124.772 262.363 124.046 262.008C123.32 261.653 122.707 261.104 122.275 260.421C121.843 259.738 121.609 258.948 121.6 258.14Z"
        fill="white"
      />
      <path
        d="M125.95 263.93C124.413 263.922 122.94 263.307 121.854 262.219C120.768 261.131 120.155 259.658 120.15 258.12V222.14C120.153 220.604 120.765 219.131 121.852 218.045C122.94 216.96 124.414 216.35 125.95 216.35H221.07C222.605 216.353 224.076 216.964 225.161 218.049C226.247 219.134 226.857 220.605 226.86 222.14V250.56C226.86 252.097 226.25 253.57 225.165 254.658C224.079 255.745 222.607 256.357 221.07 256.36H138.6C137.949 256.366 137.317 256.583 136.8 256.98L129.48 262.69C128.473 263.482 127.231 263.918 125.95 263.93ZM125.95 219.23C125.175 219.23 124.431 219.537 123.882 220.085C123.333 220.632 123.023 221.375 123.02 222.15V258.15C123.02 258.699 123.175 259.236 123.465 259.701C123.756 260.166 124.172 260.54 124.664 260.781C125.157 261.022 125.708 261.119 126.253 261.062C126.799 261.006 127.317 260.797 127.75 260.46L135.07 254.75C136.089 253.961 137.341 253.532 138.63 253.53H221.07C221.845 253.527 222.588 253.218 223.135 252.668C223.683 252.119 223.99 251.375 223.99 250.6V222.14C223.99 221.366 223.682 220.623 223.135 220.075C222.587 219.528 221.845 219.22 221.07 219.22L125.95 219.23Z"
        fill="#003C71"
      />
      <path d="M215.81 226.86H187.88V229.73H215.81V226.86Z" fill="#003C71" />
      <path d="M215.81 236.54H161.9V239.41H215.81V236.54Z" fill="#003C71" />
      <path
        d="M203.07 333.51C218.385 333.51 230.8 321.095 230.8 305.78C230.8 290.465 218.385 278.05 203.07 278.05C187.755 278.05 175.34 290.465 175.34 305.78C175.34 321.095 187.755 333.51 203.07 333.51Z"
        fill="#A1D4C7"
      />
      <path
        d="M203.07 324.79C213.442 324.79 221.85 316.382 221.85 306.01C221.85 295.638 213.442 287.23 203.07 287.23C192.698 287.23 184.29 295.638 184.29 306.01C184.29 316.382 192.698 324.79 203.07 324.79Z"
        fill="white"
      />
      <path
        d="M203.07 313.93C202.83 313.929 202.593 313.868 202.382 313.753C202.17 313.638 201.991 313.472 201.86 313.27L191.65 297.39L194.06 295.84L202.92 309.61L216.92 281.91L219.48 283.2L204.35 313.14C204.236 313.367 204.064 313.559 203.851 313.698C203.639 313.837 203.394 313.917 203.14 313.93H203.07Z"
        fill="#003C71"
      />
      <path
        d="M379.94 392.96H468.35C468.35 392.96 476.69 407.2 468.35 418.32C460.01 429.44 416.93 405.64 416.93 405.64L405.19 410.57C403.429 411.313 401.456 411.377 399.65 410.75L399.2 410.6C398.045 410.199 397 409.532 396.151 408.652C395.301 407.772 394.671 406.705 394.312 405.536C393.952 404.367 393.874 403.13 394.082 401.925C394.29 400.72 394.779 399.581 395.51 398.6C395.51 398.6 383.27 400.25 379.94 392.96Z"
        fill="#FBCCCB"
      />
      <path
        d="M455.36 348.9C455.36 348.9 446.56 379.97 465.49 386.24C465.49 386.24 475.62 387.99 487.07 377.48C487.07 377.48 497.64 375.79 497.64 368.74C497.64 361.69 489.27 361.69 489.27 361.69L490.6 348.9H455.36Z"
        fill="#FBCCCB"
      />
      <path
        d="M454.75 358.23C454.75 358.23 442.24 371.71 454.08 372.23C465.92 372.75 461.97 361.71 461.97 361.71L454.75 358.23Z"
        fill="#FBCCCB"
      />
      <path
        d="M470.39 379.59L466.69 407.14L495.46 393.38C495.46 393.38 488.37 370.27 484.2 367.64C480.03 365.01 470.39 379.59 470.39 379.59Z"
        fill="#FBCCCB"
      />
      <path
        d="M448.6 338.79C448.6 338.79 451.39 352.44 466.36 352.44C481.33 352.44 489.7 355.53 489.26 361.69C496.71 365.22 494.58 370.87 489.26 372.22C489.26 372.22 484.18 383.05 493.09 386.22C493.09 386.22 498.49 375.69 504.21 372.22C509.93 368.75 517.42 345.84 497.61 337.03C475.48 317.96 448.6 338.79 448.6 338.79Z"
        fill="#003C71"
      />
      <path
        d="M493.12 386.24C493.12 386.24 519.79 407.12 519.79 443.64H448.6C456.25 428.49 440.65 416.59 440.65 416.59C440.65 416.59 458.33 414 450.41 392.96L493.12 386.24Z"
        fill="white"
      />
      <path
        d="M521.23 443.64H518.36C518.36 421.13 508.21 404.64 499.69 394.79L501.87 392.92C510.7 403.14 521.23 420.25 521.23 443.64Z"
        fill="#003C71"
      />
      <path
        d="M440.81 418.01L440.52 415.16L440.66 416.59L440.51 415.16C440.89 415.16 449.81 414.06 450.9 404.68L453.75 405.01C452.38 416.71 440.92 418 440.81 418.01Z"
        fill="#003C71"
      />
      <path
        d="M460.59 363.75C461.706 363.75 462.61 362.846 462.61 361.73C462.61 360.614 461.706 359.71 460.59 359.71C459.475 359.71 458.57 360.614 458.57 361.73C458.57 362.846 459.475 363.75 460.59 363.75Z"
        fill="#003C71"
      />
      <path
        d="M303.2 244.46C303.2 244.46 302.63 289.84 320.14 301.61C332.51 307.39 350.59 289.92 350.59 289.92L356.36 280.43C356.36 280.43 367.82 274.58 367.82 267.6C367.82 260.62 357.05 257.39 352.74 261.16L344.66 241.77L303.2 244.46Z"
        fill="#F59795"
      />
      <path
        d="M326.89 296.74V328C326.89 328 359.61 328.2 359.61 303.63V267.63L329.41 272.31L326.89 296.74Z"
        fill="#F59795"
      />
      <path
        d="M295.6 250.79C295.6 250.79 301.88 258.46 312.79 253.62C323.7 248.78 328.07 246.74 332.54 253.01C337.01 259.28 345.9 266.27 352.77 261.16C359.64 256.05 368.93 263.79 367.85 267.6C376.99 265.24 376.6 253.6 370.14 245.54C363.68 237.48 367.71 230.19 359.64 222.54C351.57 214.89 338.01 213.66 329.44 219.31C320.87 224.96 312.8 225.77 305.92 225.77C293.5 225.75 286.28 237.87 295.6 250.79Z"
        fill="#003C71"
      />
      <path
        d="M320.77 271.37C320.571 271.053 320.282 270.803 319.94 270.652C319.598 270.501 319.218 270.455 318.85 270.52C318.482 270.586 318.141 270.76 317.872 271.02C317.603 271.28 317.418 271.614 317.34 271.98C316.39 276.61 315.53 283.41 318.41 284.71C321.55 286.14 324.48 283.79 325.94 282.28C326.224 281.978 326.4 281.59 326.44 281.178C326.48 280.765 326.381 280.351 326.16 280L320.77 271.37Z"
        fill="#FBCCCB"
      />
      <path d="M328.33 317.83H325.46V335.11H328.33V317.83Z" fill="white" />
      <path d="M361.04 296.74H358.17V335.11H361.04V296.74Z" fill="white" />
      <path d="M542.7 442.2H214.59V445.07H542.7V442.2Z" fill="#003C71" />
      <path
        d="M328.91 270.49C330.026 270.49 330.93 269.586 330.93 268.47C330.93 267.354 330.026 266.45 328.91 266.45C327.795 266.45 326.89 267.354 326.89 268.47C326.89 269.586 327.795 270.49 328.91 270.49Z"
        fill="#003C71"
      />
      <path
        d="M310.81 270.49C311.926 270.49 312.83 269.586 312.83 268.47C312.83 267.354 311.926 266.45 310.81 266.45C309.695 266.45 308.79 267.354 308.79 268.47C308.79 269.586 309.695 270.49 310.81 270.49Z"
        fill="#003C71"
      />
      <path
        d="M323.81 294.01L323.48 291.16C323.55 291.16 330.48 290.27 334.31 284.37L336.71 285.94C335.228 288.097 333.318 289.927 331.098 291.315C328.879 292.703 326.399 293.621 323.81 294.01Z"
        fill="#003C71"
      />
    </svg>
  );
};
