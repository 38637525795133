import { gql } from "graphql-tag";
import type { QueryFunctionContext } from "react-query";
import { useQuery } from "react-query";
import { graphQLClient } from "../dato-graphql-client";
import { DepartmentParts, EmployeeParts, JobTitleParts, SubDepartmentParts } from "./fragments";

import type { EmployeeRecord } from "../generated-gql/graphql";

export async function fetchAllEmployees(skip = 0, first = 100, id?: string): Promise<EmployeeRecord[]> {
  try {
    // const first = 100;
    const query = gql`
      query GetAllEmployees($first: IntType, $skip: IntType, $id: ItemId) {
        allEmployees(first: $first, skip: $skip, filter: { id: { eq: $id } }) {
          ...EmployeeParts
        }
      }
      ${EmployeeParts}
      ${JobTitleParts}
      ${DepartmentParts}
      ${SubDepartmentParts}
    `;

    const variables = { first, skip, id };
    const data = await graphQLClient.request<{
      allEmployees: EmployeeRecord[];
    }>(query, variables);

    if (data.allEmployees.length === first) {
      // If the number of records returned equals 'first', there may be more records
      const moreData = await fetchAllEmployees(skip + first);
      return [...data.allEmployees, ...moreData];
    } else {
      // If fewer than 'first' records are returned, we've got all the records
      return data.allEmployees;
    }
  } catch (error) {
    console.error("Error fetching all employees:", error);
    throw error;
  }
}

export function useAllEmployees() {
  const fetcher = (_: QueryFunctionContext) => fetchAllEmployees();
  const { data, isLoading, error } = useQuery("all-employees", fetcher, {
    refetchOnWindowFocus: false,
  });
  return { data, isLoading, error };
}
