export const POSTAL_CODES: Record<string, { value: string; city: string }> = {
  "101": {
    value: "101",
    city: "Reykjavík",
  },
  "102": {
    value: "102",
    city: "Reykjavík",
  },
  "103": {
    value: "103",
    city: "Reykjavík",
  },
  "104": {
    value: "104",
    city: "Reykjavík",
  },
  "105": {
    value: "105",
    city: "Reykjavík",
  },
  "107": {
    value: "107",
    city: "Reykjavík",
  },
  "108": {
    value: "108",
    city: "Reykjavík",
  },
  "109": {
    value: "109",
    city: "Reykjavík",
  },
  "110": {
    value: "110",
    city: "Reykjavík",
  },
  "111": {
    value: "111",
    city: "Reykjavík",
  },
  "112": {
    value: "112",
    city: "Reykjavík",
  },
  "113": {
    value: "113",
    city: "Reykjavík",
  },
  "116": {
    value: "116",
    city: "Reykjavík",
  },
  "121": {
    value: "121",
    city: "Reykjavík",
  },
  "123": {
    value: "123",
    city: "Reykjavík",
  },
  "124": {
    value: "124",
    city: "Reykjavík",
  },
  "125": {
    value: "125",
    city: "Reykjavík",
  },
  "127": {
    value: "127",
    city: "Reykjavík",
  },
  "128": {
    value: "128",
    city: "Reykjavík",
  },
  "129": {
    value: "129",
    city: "Reykjavík",
  },
  "130": {
    value: "130",
    city: "Reykjavík",
  },
  "132": {
    value: "132",
    city: "Reykjavík",
  },
  "150": {
    value: "150",
    city: "(Opinberar",
  },
  "155": {
    value: "155",
    city: "Reykjavík",
  },
  "161": {
    value: "161",
    city: "Reykjavík",
  },
  "162": {
    value: "162",
    city: "Dreifbýli",
  },
  "170": {
    value: "170",
    city: "Seltjarnarnesi",
  },
  "172": {
    value: "172",
    city: "Seltjarnarnesi",
  },
  "190": {
    value: "190",
    city: "Vogum",
  },
  "191": {
    value: "191",
    city: "Vogum",
  },
  "200": {
    value: "200",
    city: "Kópavogi",
  },
  "201": {
    value: "201",
    city: "Kópavogi",
  },
  "202": {
    value: "202",
    city: "Kópavogi",
  },
  "203": {
    value: "203",
    city: "Kópavogi",
  },
  "206": {
    value: "206",
    city: "Kópavogi",
  },
  "210": {
    value: "210",
    city: "Garðabæ",
  },
  "212": {
    value: "212",
    city: "Garðabæ",
  },
  "220": {
    value: "220",
    city: "Hafnarfirði",
  },
  "221": {
    value: "221",
    city: "Hafnarfirði",
  },
  "222": {
    value: "222",
    city: "Hafnarfirði",
  },
  "225": {
    value: "225",
    city: "Garðabæ",
  },
  "230": {
    value: "230",
    city: "Reykjanesbæ",
  },
  "232": {
    value: "232",
    city: "Reykjanesbæ",
  },
  "233": {
    value: "233",
    city: "Reykjanesbæ",
  },
  "235": {
    value: "235",
    city: "Keflavíkurflugvelli",
  },
  "240": {
    value: "240",
    city: "Grindavík",
  },
  "241": {
    value: "241",
    city: "Grindavík",
  },
  "245": {
    value: "245",
    city: "Suðurnesjabæ",
  },
  "246": {
    value: "246",
    city: "Suðurnesjabæ",
  },
  "250": {
    value: "250",
    city: "Suðurnesjabæ",
  },
  "251": {
    value: "251",
    city: "Suðurnesjabæ",
  },
  "260": {
    value: "260",
    city: "Reykjanesbæ",
  },
  "262": {
    value: "262",
    city: "Reykjanesbæ",
  },
  "270": {
    value: "270",
    city: "Mosfellsbæ",
  },
  "271": {
    value: "271",
    city: "Mosfellsbæ",
  },
  "276": {
    value: "276",
    city: "Mosfellsbæ",
  },
  "300": {
    value: "300",
    city: "Akranesi",
  },
  "301": {
    value: "301",
    city: "Akranesi",
  },
  "302": {
    value: "302",
    city: "Akranesi",
  },
  "310": {
    value: "310",
    city: "Borgarnesi",
  },
  "311": {
    value: "311",
    city: "Borgarnesi",
  },
  "320": {
    value: "320",
    city: "Borgarfirði",
  },
  "340": {
    value: "340",
    city: "Stykkishólmi",
  },
  "341": {
    value: "341",
    city: "Stykkishólmi",
  },
  "342": {
    value: "342",
    city: "Stykkishólmi",
  },
  "345": {
    value: "345",
    city: "Breiðafirði",
  },
  "350": {
    value: "350",
    city: "Grundarfirði",
  },
  "351": {
    value: "351",
    city: "Grundarfirði",
  },
  "355": {
    value: "355",
    city: "Ólafsvík",
  },
  "356": {
    value: "356",
    city: "Snæfellsbæ",
  },
  "360": {
    value: "360",
    city: "Hellissandi",
  },
  "370": {
    value: "370",
    city: "Búðardal",
  },
  "371": {
    value: "371",
    city: "Búðardal",
  },
  "380": {
    value: "380",
    city: "Reykhólahreppi",
  },
  "381": {
    value: "381",
    city: "Reykhólahreppi",
  },
  "400": {
    value: "400",
    city: "Ísafirði",
  },
  "401": {
    value: "401",
    city: "Ísafirði",
  },
  "410": {
    value: "410",
    city: "Hnífsdal",
  },
  "415": {
    value: "415",
    city: "Bolungarvík",
  },
  "416": {
    value: "416",
    city: "Bolungarvík",
  },
  "420": {
    value: "420",
    city: "Súðavík",
  },
  "421": {
    value: "421",
    city: "Súðavík",
  },
  "425": {
    value: "425",
    city: "Flateyri",
  },
  "426": {
    value: "426",
    city: "Flateyri",
  },
  "430": {
    value: "430",
    city: "Suðureyri",
  },
  "431": {
    value: "431",
    city: "Suðureyri",
  },
  "450": {
    value: "450",
    city: "Patreksfirði",
  },
  "451": {
    value: "451",
    city: "Patreksfirði",
  },
  "460": {
    value: "460",
    city: "Tálknafirði",
  },
  "461": {
    value: "461",
    city: "Tálknafirði",
  },
  "465": {
    value: "465",
    city: "Bíldudal",
  },
  "466": {
    value: "466",
    city: "Bíldudal",
  },
  "470": {
    value: "470",
    city: "Þingeyri",
  },
  "471": {
    value: "471",
    city: "Þingeyri",
  },
  "500": {
    value: "500",
    city: "Stað",
  },
  "510": {
    value: "510",
    city: "Hólmavík",
  },
  "511": {
    value: "511",
    city: "Hólmavík",
  },
  "512": {
    value: "512",
    city: "Hólmavík",
  },
  "520": {
    value: "520",
    city: "Drangsnesi",
  },
  "524": {
    value: "524",
    city: "Árneshreppi",
  },
  "530": {
    value: "530",
    city: "Hvammstanga",
  },
  "531": {
    value: "531",
    city: "Hvammstanga",
  },
  "540": {
    value: "540",
    city: "Blönduósi",
  },
  "541": {
    value: "541",
    city: "Blönduósi",
  },
  "545": {
    value: "545",
    city: "Skagaströnd",
  },
  "546": {
    value: "546",
    city: "Skagaströnd",
  },
  "550": {
    value: "550",
    city: "Sauðárkróki",
  },
  "551": {
    value: "551",
    city: "Sauðárkróki",
  },
  "560": {
    value: "560",
    city: "Varmahlíð",
  },
  "561": {
    value: "561",
    city: "Varmahlíð",
  },
  "565": {
    value: "565",
    city: "Hofsósi",
  },
  "566": {
    value: "566",
    city: "Hofsósi",
  },
  "570": {
    value: "570",
    city: "Fljótum",
  },
  "580": {
    value: "580",
    city: "Siglufirði",
  },
  "581": {
    value: "581",
    city: "Siglufirði",
  },
  "600": {
    value: "600",
    city: "Akureyri",
  },
  "601": {
    value: "601",
    city: "Akureyri",
  },
  "602": {
    value: "602",
    city: "Akureyri",
  },
  "603": {
    value: "603",
    city: "Akureyri",
  },
  "604": {
    value: "604",
    city: "Akureyri",
  },
  "605": {
    value: "605",
    city: "Akureyri",
  },
  "606": {
    value: "606",
    city: "Akureyri",
  },
  "607": {
    value: "607",
    city: "Akureyri",
  },
  "610": {
    value: "610",
    city: "Grenivík",
  },
  "611": {
    value: "611",
    city: "Grímsey",
  },
  "616": {
    value: "616",
    city: "Grenivík",
  },
  "620": {
    value: "620",
    city: "Dalvík",
  },
  "621": {
    value: "621",
    city: "Dalvík",
  },
  "625": {
    value: "625",
    city: "Ólafsfirði",
  },
  "626": {
    value: "626",
    city: "Ólafsfirði",
  },
  "630": {
    value: "630",
    city: "Hrísey",
  },
  "640": {
    value: "640",
    city: "Húsavík",
  },
  "641": {
    value: "641",
    city: "Húsavík",
  },
  "645": {
    value: "645",
    city: "Fosshóli",
  },
  "650": {
    value: "650",
    city: "Laugum",
  },
  "660": {
    value: "660",
    city: "Mývatni",
  },
  "670": {
    value: "670",
    city: "Kópaskeri",
  },
  "671": {
    value: "671",
    city: "Kópaskeri",
  },
  "675": {
    value: "675",
    city: "Raufarhöfn",
  },
  "676": {
    value: "676",
    city: "Raufarhöfn",
  },
  "680": {
    value: "680",
    city: "Þórshöfn",
  },
  "681": {
    value: "681",
    city: "Þórshöfn",
  },
  "685": {
    value: "685",
    city: "Bakkafirði",
  },
  "686": {
    value: "686",
    city: "Bakkafirði",
  },
  "690": {
    value: "690",
    city: "Vopnafirði",
  },
  "691": {
    value: "691",
    city: "Vopnafirði",
  },
  "700": {
    value: "700",
    city: "Egilsstöðum",
  },
  "701": {
    value: "701",
    city: "Egilsstöðum",
  },
  "710": {
    value: "710",
    city: "Seyðisfirði",
  },
  "711": {
    value: "711",
    city: "Seyðisfirði",
  },
  "715": {
    value: "715",
    city: "Mjóafirði",
  },
  "720": {
    value: "720",
    city: "eystri",
  },
  "721": {
    value: "721",
    city: "eystri",
  },
  "730": {
    value: "730",
    city: "Reyðarfirði",
  },
  "731": {
    value: "731",
    city: "Reyðarfirði",
  },
  "735": {
    value: "735",
    city: "Eskifirði",
  },
  "736": {
    value: "736",
    city: "Eskifirði",
  },
  "740": {
    value: "740",
    city: "Neskaupstað",
  },
  "741": {
    value: "741",
    city: "Neskaupstað",
  },
  "750": {
    value: "750",
    city: "Fáskrúðsfirði",
  },
  "751": {
    value: "751",
    city: "Fáskrúðsfirði",
  },
  "755": {
    value: "755",
    city: "Stöðvarfirði",
  },
  "756": {
    value: "756",
    city: "Stöðvarfirði",
  },
  "760": {
    value: "760",
    city: "Breiðdalsvík",
  },
  "761": {
    value: "761",
    city: "Breiðdalsvík",
  },
  "765": {
    value: "765",
    city: "Djúpavogi",
  },
  "766": {
    value: "766",
    city: "Djúpavogi",
  },
  "780": {
    value: "780",
    city: "Hornafirði",
  },
  "781": {
    value: "781",
    city: "Hornafirði",
  },
  "785": {
    value: "785",
    city: "Öræfum",
  },
  "800": {
    value: "800",
    city: "Selfossi",
  },
  "801": {
    value: "801",
    city: "Selfossi",
  },
  "802": {
    value: "802",
    city: "Selfossi",
  },
  "803": {
    value: "803",
    city: "Selfossi",
  },
  "804": {
    value: "804",
    city: "Selfossi",
  },
  "805": {
    value: "805",
    city: "Selfossi",
  },
  "806": {
    value: "806",
    city: "Selfossi",
  },
  "810": {
    value: "810",
    city: "Hveragerði",
  },
  "815": {
    value: "815",
    city: "Þorlákshöfn",
  },
  "816": {
    value: "816",
    city: "Ölfus",
  },
  "820": {
    value: "820",
    city: "Eyrarbakka",
  },
  "825": {
    value: "825",
    city: "Stokkseyri",
  },
  "840": {
    value: "840",
    city: "Laugarvatni",
  },
  "845": {
    value: "845",
    city: "Flúðum",
  },
  "846": {
    value: "846",
    city: "Flúðum",
  },
  "850": {
    value: "850",
    city: "Hellu",
  },
  "851": {
    value: "851",
    city: "Hellu",
  },
  "860": {
    value: "860",
    city: "Hvolsvelli",
  },
  "861": {
    value: "861",
    city: "Hvolsvelli",
  },
  "870": {
    value: "870",
    city: "Vík",
  },
  "871": {
    value: "871",
    city: "Vík",
  },
  "880": {
    value: "880",
    city: "Kirkjubæjarklaustri",
  },
  "881": {
    value: "881",
    city: "Kirkjubæjarklaustri",
  },
  "900": {
    value: "900",
    city: "Vestmannaeyjum",
  },
  "902": {
    value: "902",
    city: "Vestmannaeyjum",
  },
};

export const VALID_POSTAL_CODE_LIST = [
  "101",
  "102",
  "103",
  "104",
  "105",
  "107",
  "108",
  "109",
  "110",
  "111",
  "112",
  "113",
  "116",
  "121",
  "123",
  "124",
  "125",
  "127",
  "128",
  "129",
  "130",
  "132",
  "150",
  "155",
  "161",
  "162",
  "170",
  "172",
  "190",
  "191",
  "200",
  "201",
  "202",
  "203",
  "206",
  "210",
  "212",
  "220",
  "221",
  "222",
  "225",
  "230",
  "232",
  "233",
  "235",
  "240",
  "241",
  "245",
  "246",
  "250",
  "251",
  "260",
  "262",
  "270",
  "271",
  "276",
  "300",
  "301",
  "302",
  "310",
  "311",
  "320",
  "340",
  "341",
  "342",
  "345",
  "350",
  "351",
  "355",
  "356",
  "360",
  "370",
  "371",
  "380",
  "381",
  "400",
  "401",
  "410",
  "415",
  "416",
  "420",
  "421",
  "425",
  "426",
  "430",
  "431",
  "450",
  "451",
  "460",
  "461",
  "465",
  "466",
  "470",
  "471",
  "500",
  "510",
  "511",
  "512",
  "520",
  "524",
  "530",
  "531",
  "540",
  "541",
  "545",
  "546",
  "550",
  "551",
  "560",
  "561",
  "565",
  "566",
  "570",
  "580",
  "581",
  "600",
  "601",
  "602",
  "603",
  "604",
  "605",
  "606",
  "607",
  "610",
  "611",
  "616",
  "620",
  "621",
  "625",
  "626",
  "630",
  "640",
  "641",
  "645",
  "650",
  "660",
  "670",
  "671",
  "675",
  "676",
  "680",
  "681",
  "685",
  "686",
  "690",
  "691",
  "700",
  "701",
  "710",
  "711",
  "715",
  "720",
  "721",
  "730",
  "731",
  "735",
  "736",
  "740",
  "741",
  "750",
  "751",
  "755",
  "756",
  "760",
  "761",
  "765",
  "766",
  "780",
  "781",
  "785",
  "800",
  "801",
  "802",
  "803",
  "804",
  "805",
  "806",
  "810",
  "815",
  "816",
  "820",
  "825",
  "840",
  "845",
  "846",
  "850",
  "851",
  "860",
  "861",
  "870",
  "871",
  "880",
  "881",
  "900",
  "902",
];
