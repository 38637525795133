import { atom } from "@byko/lib-recoil";

import type { RegistrationStatus } from "../interface";

export const registrationStatusState = atom<RegistrationStatus>({
  key: "registration-status",
  default: {
    acceptedTerms: false,
    registeredEmail: false,
    hasInvite: false,
    isOnboarding: false,
  },
});
