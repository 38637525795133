import { useMemo } from "react";

import { debounce } from "./debounce";

import type { FN } from "./interface";

export const useDebounce = <T extends (...args: Parameters<T>) => ReturnType<T>>(func: T, waitFor: number): FN<T> => {
  return useMemo(() => {
    return debounce(func, waitFor);
  }, [func, waitFor]);
};
