import styled from "styled-components";

import { theme } from "@byko/lib-styles";
import { gridTheme } from "@byko/lib-styles";

import type { BoxProps } from "./interface";

export const ResultsAndPaginationContiner = styled.div``;
export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: ${gridTheme.maxContentWidth}px;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  gap: 20px;
  @media screen and (max-width: 1438px) {
    margin-top: 56px;
  }
`;

export const TagsContainer = styled.div`
  width: 100%;
  min-height: 38px;
  margin-top: 24px;
  overflow-x: scroll;
  overflow-x: -moz-scrollbars-none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: ${gridTheme.breakpoints.sm}px) {
    margin-top: 20px;
  }
`;

export const ResultgridWrapper = styled.div`
  width: 100%;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 36px;
  gap: 40px;
  @media (max-width: ${gridTheme.breakpoints.md - 1}px) {
    gap: 20px;
  }
`;

export const SortingContainer = styled.div`
  display: flex;
  margin-bottom: 29px;
  @media screen and (min-width: ${gridTheme.breakpoints.sm}px) {
    min-height: 56px;
  }
`;

export const DesktopOnly = styled.div`
  .desktop-accordion-block {
    width: 100%;
    min-width: 260px;
    max-width: 400px;
  }
  @media (max-width: ${gridTheme.breakpoints.sm - 1}px) {
    display: none;
  }
`;

export const FacetCheckboxWrapper = styled.div``;

export const Line = styled.div`
  display: flex;
  align-items: center;
`;

export const Item = styled.label`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 0;
  cursor: pointer;
  user-select: none;
`;

export const Text = styled.span`
  margin-left: 16px;
  color: ${theme.palette.blue.main};
  font-size: 1rem;
  line-height: 1.1;
`;

export const Count = styled.span`
  margin-left: 4px;
  color: ${theme.palette.gray[30]};
  transition: opacity 0.15s;
`;

export const Box = styled.div<BoxProps>`
  display: flex;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.palette.gray[20]};
  background-color: transparent;
  transition: background-color 0.15s, border-color 0.15s;
`;

export const CheckboxInput = styled.input`
  position: absolute;
  border-radius: 0;
  opacity: 0;
  pointer-events: none;

  &:checked + ${Box} {
    border-color: ${theme.palette.blue.main};
    background-color: ${theme.palette.blue.main};
  }

  &:focus + ${Box} {
    border-color: ${theme.palette.blue.main};
  }
`;

export const Wrapper = styled.div`
  border-bottom: 1px solid ${theme.palette.gray[60]};
  margin-bottom: 16px;
  transition: border-color 0.15s;
`;

export const GroupBtn = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border: 0;
  border-radius: 0;
  border-top: 1px solid ${theme.palette.blue.dark};
  background-color: transparent;
  cursor: pointer;
  transition: border-color 0.15s, background-color 0.15s;
  &:hover {
    background-color: ${theme.palette.blue.main};
  }
`;

export const BtnTail = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
`;

export const IconWrapper = styled.div`
  transform: rotate(-90deg);
  transition: transform 0.2s ease-in-out;
`;

export const Label = styled.span`
  color: ${theme.palette.blue.main};
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.1;
  text-align: left;
`;

export const FacetCount = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 16px;
  background-color: ${theme.palette.gray[10]};
  color: ${theme.palette.blue.main};
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1;
  transition: opacity 0.15s;
`;

export const Content = styled.div`
  padding: 8px 16px 24px 16px;
`;
