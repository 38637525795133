import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const DrillFilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1144_7151)">
        <path
          // eslint-disable-next-line max-len
          d="M29 42V40.389C29.0032 39.2044 28.5844 38.0574 27.8186 37.1536C27.0528 36.2498 25.9901 35.6483 24.821 35.457L19.984 34.65C19.2139 34.5254 18.523 34.1047 18.0589 33.4776C17.5948 32.8506 17.3942 32.0669 17.5 31.294L18.988 20.132C19.0068 19.991 18.9953 19.8477 18.9543 19.7116C18.9133 19.5754 18.8437 19.4496 18.7502 19.3424C18.6567 19.2353 18.5414 19.1493 18.412 19.0903C18.2827 19.0312 18.1422 19.0005 18 19H8.00003C7.75772 19.0001 7.52368 19.0881 7.3414 19.2478C7.15912 19.4074 7.04101 19.6278 7.00903 19.868L5.31703 32.551C5.2467 33.078 5.0376 33.5768 4.71113 33.9964C4.38466 34.416 3.95255 34.7413 3.45903 34.939L3.14403 35.065C2.21395 35.4331 1.41644 36.0731 0.855586 36.9014C0.29473 37.7296 -0.0034254 38.7077 2.9692e-05 39.708V42H29Z"
          fill="#FFD330"
        />
        <path
          // eslint-disable-next-line max-len
          d="M35.6 4.2L31.6 1.2C31.4269 1.07018 31.2164 1 31 1H26V21H31C31.2164 21 31.4269 20.9298 31.6 20.8L35.6 17.8C35.7242 17.7069 35.825 17.5861 35.8944 17.4472C35.9639 17.3084 36 17.1552 36 17V5C36 4.84475 35.9639 4.69164 35.8944 4.55279C35.825 4.41393 35.7242 4.29315 35.6 4.2Z"
          fill="#C6C6C6"
        />
        <path
          // eslint-disable-next-line max-len
          d="M26 1H5C3.67441 1.00159 2.40356 1.52888 1.46622 2.46622C0.528882 3.40356 0.00158786 4.67441 0 6L0 16C0.00158786 17.3256 0.528882 18.5964 1.46622 19.5338C2.40356 20.4711 3.67441 20.9984 5 21H26V1Z"
          fill="#FFD330"
        />
        <path
          // eslint-disable-next-line max-len
          d="M0 42V46C0 46.2652 0.105357 46.5196 0.292893 46.7071C0.48043 46.8946 0.734784 47 1 47H28C28.2652 47 28.5196 46.8946 28.7071 46.7071C28.8946 46.5196 29 46.2652 29 46V42H0Z"
          fill="#0A3A5C"
        />
        <path
          // eslint-disable-next-line max-len
          d="M17.675 30H19.982C20.1525 30.0001 20.3202 29.9566 20.4691 29.8737C20.6181 29.7907 20.7433 29.6711 20.833 29.5261C20.9227 29.3811 20.9738 29.2156 20.9815 29.0453C20.9892 28.875 20.9533 28.7055 20.8771 28.553L18.5 23.8L17.675 30Z"
          fill="#FFD330"
        />
        <path
          // eslint-disable-next-line max-len
          d="M11 14H7C6.44772 14 6 14.4477 6 15C6 15.5523 6.44772 16 7 16H11C11.5523 16 12 15.5523 12 15C12 14.4477 11.5523 14 11 14Z"
          fill="#0A3A5C"
        />
        <path
          // eslint-disable-next-line max-len
          d="M11 10H7C6.44772 10 6 10.4477 6 11C6 11.5523 6.44772 12 7 12H11C11.5523 12 12 11.5523 12 11C12 10.4477 11.5523 10 11 10Z"
          fill="#0A3A5C"
        />
        <path
          // eslint-disable-next-line max-len
          d="M11 6H7C6.44772 6 6 6.44772 6 7C6 7.55228 6.44772 8 7 8H11C11.5523 8 12 7.55228 12 7C12 6.44772 11.5523 6 11 6Z"
          fill="#0A3A5C"
        />
        <path d="M28 1H26V21H28V1Z" fill="#AEAEAE" />
        <path
          // eslint-disable-next-line max-len
          d="M42 10H47C47.2652 10 47.5196 10.1054 47.7071 10.2929C47.8946 10.4804 48 10.7348 48 11C48 11.2652 47.8946 11.5196 47.7071 11.7071C47.5196 11.8946 47.2652 12 47 12H42V10Z"
          fill="#0A3A5C"
        />
        <path
          // eslint-disable-next-line max-len
          d="M42 12V8C42 7.73478 41.8946 7.48043 41.7071 7.29289C41.5196 7.10536 41.2652 7 41 7H36V15H41C41.2652 15 41.5196 14.8946 41.7071 14.7071C41.8946 14.5196 42 14.2652 42 14V12Z"
          fill="#AEAEAE"
        />
        <path d="M6.59106 23H18.6091L18.8751 21H6.85806L6.59106 23Z" fill="#FFD330" />
        <path
          // eslint-disable-next-line max-len
          d="M10 37C11.1046 37 12 36.1046 12 35C12 33.8954 11.1046 33 10 33C8.89543 33 8 33.8954 8 35C8 36.1046 8.89543 37 10 37Z"
          fill="#0A3A5C"
        />
      </g>
      <defs>
        <clipPath id="clip0_1144_7151">
          <rect fill="white" height="48" width="48" />
        </clipPath>
      </defs>
    </svg>
  );
};
