import { useCallback, useEffect } from "react";
import * as Sentry from "@sentry/nextjs";

import { drawerState } from "@byko/component-drawer";

import { PolledLoginMethodEnum } from "@byko/lib-api-rest";
import { restApi } from "@byko/lib-api-rest";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "@byko/lib-recoil";
import { isJest, isSSR } from "@byko/types-utils";

import {
  activeMembershipState,
  emailState,
  isAuthLoadedState,
  isAuthenticatedState,
  loginErrorState,
  loginLoadingState,
  membershipsState,
  requestingSwitchAccountState,
  savedEmailState,
  securityCodeState,
  showAsGuestOptionState,
  userState,
} from "./store";
import { isMembershipStateSetStore } from "./store/active-membership";
import { registrationStatusState } from "./store/registration-status";

import type { AuthProps } from "./interface";
import type { useRouter as IuseRouter } from "next/router";

const isValidJSON = (str: string) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

const fakerouter = {
  push: (_value: string): void => {
    /** Empty on purpose. */
  },
} as unknown as typeof IuseRouter;

const useRouter: typeof IuseRouter = !isJest() ? require("next/router").useRouter : fakerouter;

export const useAuth = (): AuthProps => {
  const router = useRouter();
  const [requestSwitchAccount, setRequestSwitchAccount] = useRecoilState<boolean>(requestingSwitchAccountState);
  const [showAsGuestOption, setShowAsGuestOption] = useRecoilState<boolean>(showAsGuestOptionState);
  const setOpen = useSetRecoilState<boolean>(drawerState);
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const [isAuthLoaded, setIsAuthLoaded] = useRecoilState(isAuthLoadedState);
  const [user, setUser] = useRecoilState(userState);
  const [loading, setLoading] = useRecoilState(loginLoadingState);
  const [activeMembership, setActiveMembership] = useRecoilState(activeMembershipState);
  const isMembershipStateSet = useRecoilValue(isMembershipStateSetStore);
  const [savedEmail, setSavedEmail] = useRecoilState(savedEmailState);
  const [securityCode, setSecurityCode] = useRecoilState<string | null>(securityCodeState);

  const [memberships, setMemberships] = useRecoilState(membershipsState);
  const [emailIsLoading, setEmailIsLoading] = useRecoilState(emailState);
  const [loginError, setError] = useRecoilState(loginErrorState);
  const [registrationStatus, setRegistrationStatus] = useRecoilState(registrationStatusState);

  const token = isSSR() ? undefined : localStorage.getItem("token");

  const handleSetPrefersAnonymousCheckout = useCallback(() => {
    sessionStorage.setItem("prefers-anonymous-checkout", "true");
    setShowAsGuestOption(false);
    setOpen(false);
    router.push("/kaupferli");
  }, [router, setOpen, setShowAsGuestOption]);

  const createPolledLogin = useCallback(
    async (method: PolledLoginMethodEnum, auth: string) => {
      try {
        const res = await restApi.usersPolledLoginCreate({ method, auth });
        const verificationCode = res.data.verificationCode || null;
        setSecurityCode(verificationCode);

        if (res.data.complete === true) {
          const acceptedTerms = res.data.login?.user.verified.termsAccepted !== null ? true : false;
          const registeredEmail = res.data.login?.user.verified.email !== "" ? true : false;
          setRegistrationStatus({
            acceptedTerms,
            registeredEmail,
            hasInvite: sessionStorage.getItem("invitationToken") === null ? false : true,
            isOnboarding: !acceptedTerms || !registeredEmail,
          });

          setSecurityCode(null);

          if (res.data.login !== null) {
            setUser(res.data.login.user);
            setMemberships(res.data.login.user.memberships.filter((item) => item.active));
            localStorage.setItem("token", res.data.login.saleor.accessToken);
            Sentry.setUser({ id: res.data.login.user.id.toString() });
          }
          setLoading(false);
        } else if (res.data.complete === false) {
          setTimeout(() => {
            createPolledLogin(PolledLoginMethodEnum.Poll, res.data.authRequest);
          }, 2000);
        }
      } catch (err) {
        setLoading(false);
        setSecurityCode(null);
        const error = (err as { error: { detail: string } }).error?.detail;
        const isValid = error ? isValidJSON(error) : false;
        const parsedError = isValid ? JSON.parse(error) : null;
        const message = parsedError?.responseStatus?.message || "Eitthvað fór úrskeiðis";
        setError(message);
        Sentry.setUser(null);
      }
    },
    [setSecurityCode, setRegistrationStatus, setLoading, setUser, setMemberships, setError],
  );

  const login = useCallback(
    async (auth: string, method: PolledLoginMethodEnum) => {
      setActiveMembership(null);
      setLoading(true);
      restApi.setSecurityData("");

      if (token) {
        localStorage.removeItem("token");
      }

      await createPolledLogin(method, auth);
    },
    [setActiveMembership, setLoading, token, createPolledLogin],
  );

  const logout = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("staffToken");
    setUser(null);
    Sentry.setUser(null);
    setMemberships([]);
    setActiveMembership(null);
    restApi.setSecurityData("");
  }, [setUser, setMemberships, setActiveMembership]);

  const fetchUser = useCallback(async () => {
    if (!token) {
      logout();
      setIsAuthLoaded(true);
      return;
    }

    try {
      const res = await restApi.usersMeRetrieve();
      setUser(res.data);
      Sentry.setUser({ id: res.data.id.toString() });
      const acceptedTerms = res.data.verified.termsAccepted !== null ? true : false;
      const registeredEmail = res.data.verified.email !== "" ? true : false;

      setRegistrationStatus({
        acceptedTerms,
        registeredEmail,
        hasInvite: sessionStorage.getItem("invitationToken") === null ? false : true,
        isOnboarding: registrationStatus.isOnboarding,
      });
      if (!acceptedTerms || !registeredEmail) {
        setOpen(true);
      }
      if (res.data?.memberships?.length >= 1) {
        setMemberships(res.data.memberships.filter((item) => item.active));
      }
    } catch (err) {
      logout();
    }
    setIsAuthLoaded(true);
  }, [
    token,
    setIsAuthLoaded,
    logout,
    setUser,
    setRegistrationStatus,
    registrationStatus.isOnboarding,
    setOpen,
    setMemberships,
  ]);

  useEffect(() => {
    token && restApi.setSecurityData(token);
  }, [token]);

  const switchAccount = useCallback(
    async (id: number) => {
      if (!token) {
        logout();

        return;
      }

      setLoading(true);
      restApi.setSecurityData(token);

      try {
        const res = await restApi.membershipsSwitchAccountCreate(id);

        if (res !== null) {
          const active = res.data.user.memberships.filter((item) => item.active);
          const firstActive = active.find(Boolean);
          setMemberships(active);
          setActiveMembership(firstActive ?? null);
          localStorage.setItem("token", res.data.saleor.accessToken);
          setLoading(false);
        }
      } catch (err) {
        console.error(err);
      }
    },
    [token, setLoading, logout, setMemberships, setActiveMembership],
  );

  const finalizeOnboarding = useCallback(() => {
    setRegistrationStatus({
      acceptedTerms: true,
      registeredEmail: true,
      hasInvite: registrationStatus.hasInvite,
      isOnboarding: false,
    });
  }, [registrationStatus.hasInvite, setRegistrationStatus]);

  const addEmail = useCallback(
    async (email: string) => {
      try {
        setEmailIsLoading(true);
        const res = await restApi.usersSetEmailCreate({ email });
        setSavedEmail(true);
        setRegistrationStatus({
          acceptedTerms: registrationStatus.acceptedTerms,
          registeredEmail: true,
          hasInvite: registrationStatus.hasInvite,
          isOnboarding: registrationStatus.isOnboarding,
        });
        localStorage.setItem("token", res.data.saleor.accessToken);
        setEmailIsLoading(false);
      } catch (err) {
        console.error(err);
      }
    },
    [
      registrationStatus.acceptedTerms,
      registrationStatus.hasInvite,
      registrationStatus.isOnboarding,
      setEmailIsLoading,
      setRegistrationStatus,
      setSavedEmail,
    ],
  );

  const acceptTerms = useCallback(async () => {
    try {
      const { data } = await restApi.usersAcceptTermsCreate();
      setRegistrationStatus({
        acceptedTerms: data.verified.termsAccepted !== null ? true : false,
        registeredEmail: savedEmail ? true : data.verified.email !== "" ? true : false,
        hasInvite: sessionStorage.getItem("invitationToken") === null ? false : true,
        isOnboarding: registrationStatus.isOnboarding,
      });
      setUser(data);
    } catch (err) {
      console.error(err);
    }
  }, [registrationStatus.isOnboarding, savedEmail, setRegistrationStatus, setUser]);

  return {
    login,
    logout,
    switchAccount,
    isAuthenticated,
    isAuthLoaded: isAuthLoaded && ((isAuthenticated && activeMembership != null) || !isAuthenticated),
    isMembershipStateSet,
    memberships,
    activeMembership,
    setActiveMembership,
    setError,
    loginError,
    fetchUser,
    addEmail,
    acceptTerms,
    emailIsLoading,
    loading,
    user,
    finalizeOnboarding,
    registrationStatus,
    handleSetPrefersAnonymousCheckout,
    showAsGuestOption,
    setShowAsGuestOption,
    requestSwitchAccount,
    setRequestSwitchAccount,
    securityCode,
  };
};
