import React, { useCallback } from "react";

import { useCompareProducts } from "@byko/hooks-compare-products";

import { ImageWrapper, ProdImage } from "./styles";

import type { ProductImageProps } from "./interface";

const placeholderImageHandler = (e: unknown): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const target = e.target as HTMLImageElement;
  target.src = "https://www.datocms-assets.com/65892/1664464657-placeholder-do-not-remove.jpg";
};

export const ProductImage = ({ productImage, product, rental }: ProductImageProps): JSX.Element => {
  const { addProduct, products } = useCompareProducts();
  const addToComparison = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      addProduct(product.slug);
    },
    [addProduct, product.slug],
  );

  return (
    <ImageWrapper>
      <ProdImage alt="decorative" src={productImage} onError={placeholderImageHandler} />
      {/* BYKO-121 Disable Comparison button temperorarily
      {!rental && (
        <CircleButton disabled={products.length === 4} onClick={addToComparison}>
          <BeraSamanIcons iconColor={theme.palette.blue.main} size={16} />
          <ButtonHoverLabel>
            <EyebrowSmall textColor={theme.palette.white.main}>Setja í samanburð</EyebrowSmall>
          </ButtonHoverLabel>
        </CircleButton>
      )} */}
    </ImageWrapper>
  );
};
