import * as React from "react";

import { CardContainer, CardContentContainer } from "./styles";

import type { CardProps } from "./interface";

export const Card = ({ children }: CardProps): JSX.Element => {
  return (
    <CardContainer className="card-container">
      <CardContentContainer>{children}</CardContentContainer>
    </CardContainer>
  );
};
