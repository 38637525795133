import Link from "next/link";
import styled from "styled-components";

import { EyebrowSmall } from "@byko/component-typography";
import { gridTheme, theme } from "@byko/lib-styles";

export const LoaderContainer = styled.div`
  width: 40px;
  height: 29px;
`;

export const CardContainer = styled.div<{ showAnim: boolean; isloading: boolean }>`
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 143px;
  align-items: center;
  border-top: 1px solid ${theme.palette.gray[10]};
  opacity: ${(props): string => (props.isloading ? "0.65" : props.showAnim ? "1" : "0")};
  pointer-events: auto;
  transform: translateX(${(props): string => (props.showAnim ? "0" : "8px")});
  transition: 300ms;
  button {
    width: 40px;
    height: 29px;
  }
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    height: 181px;
    align-items: flex-start;
    padding-top: 16px;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-right: 8px;
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    flex-wrap: wrap;
  }
`;

export const QuantityInputWrapper = styled.div<{ disableinput?: boolean }>`
  ${(props): string => (props.disableinput ? "pointer-events: none;" : "")}
  @media screen and (max-width: 570px) {
    padding-top: 8px;
  }
`;

export const ProdImageWrapper = styled(Link)`
  img {
    width: 80px;
    height: 80px;
    margin-right: 16px;
    object-fit: contain;
  }
  @media screen and (max-width: ${gridTheme.container.maxWidth.xs}px) {
    margin-right: 6px;
  }
`;

export const ProdImage = styled.img`
  width: 120px;
  height: 90px;
  object-fit: contain;
  object-position: center;
  @media screen and (max-width: ${gridTheme.container.maxWidth.xs}px) {
    width: 80px;
  }
`;

export const OutOfStockWrapper = styled.div`
  position: absolute;
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
  border: 2px solid ${theme.palette.triggers.warning.main};
  border-radius: 4px;
  background-color: ${theme.palette.triggers.warning.lightest};
  pointer-events: none;
  transform: translateY(24px);
`;

export const ProdInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 16px;
  ${EyebrowSmall} {
    text-decoration: line-through;
  }

  .hidden {
    width: 0;
    height: 0;
    user-select: none;
    visibility: hidden;
  }
`;

export const LoadingWrapper = styled.div``;
