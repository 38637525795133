export const gridTheme = {
  maxContentWidth: 1616,
  breakpoints: {
    lg: 1999,
    md: 1439,
    sm: 1079,
    s: 970,
    xs: 399,
  },
  container: {
    navHeight: {
      xl: 88,
      lg: 88,
      md: 56,
      sm: 56,
      s: 24,
      xs: 24,
    },
    minWidth: {
      xl: 2000,
      lg: 1440,
      md: 1080,
      mm: 950,
      m: 900,
      sm: 700,
      s: 400,
      xs: 0,
    },
    maxWidth: {
      lg: 1999,
      md: 1439,
      mm: 1300,
      m: 1079,
      sm: 899,
      s: 699,
      xs: 399,
    },
  },
};
