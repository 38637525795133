import { ensureList } from "@byko/lib-utils";

import type { VariantDetail } from "@byko/lib-api-rest";

export function prepareProductVariantSpecs(variant: VariantDetail) {
  // This is slightly refactored code that is originally written by Andri

  const specs = variant.specs.map(({ id, name, value, unit }) => {
    // FIXME: The type defs for `name` and `value` seem to be wrong on the backend
    const translatedName = name as unknown as { "is-IS": string; en: string };
    const translatedValue = value as unknown as { "is-IS": string; en: string };

    if (
      translatedValue &&
      translatedValue["is-IS"] &&
      typeof translatedValue["is-IS"] === "string" &&
      translatedValue["is-IS"].length
    ) {
      return {
        id: id,
        key: translatedName["is-IS"],
        value: `${translatedValue["is-IS"]} ${unit}`,
      };
    }

    if (value && typeof value !== "object" && value.toString().length) {
      let stringValue = value.toString();

      if (stringValue === "false") {
        stringValue = "Nei";
      } else if (stringValue === "true") {
        stringValue = "Já";
      }

      return {
        id: id,
        key: translatedName["is-IS"],
        value: `${stringValue} ${unit}`,
      };
    }

    return undefined;
  });

  return ensureList(specs);
}
