import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const LogoutReverseIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg
      className="byko-icon"
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17 12H2" stroke={iconColor} strokeMiterlimit="10" strokeWidth="2" />
      <path d="M7 17L2 12L7 7" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M10 1H22V23H10" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
    </svg>
  );
};
