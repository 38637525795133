import * as React from "react";
import { useCallback, useState } from "react";

import { Button } from "@byko/component-buttons";
import { useAuth } from "@byko/hooks-auth-next";
import { AddIcons } from "@byko/lib-icons";

import { UserItemWrapper } from "./picker-search/picker-search-styles";
import { UserItem } from "./user-item";

export const PickerRecentlyVisited = (): JSX.Element => {
  const { memberships } = useAuth();

  const [expanded, setExpanded] = useState(false);
  const itemsToShow = expanded ? memberships : memberships.slice(0, 4);
  const handleClick = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  return (
    <>
      <UserItemWrapper>
        {itemsToShow.map((item) => (
          <UserItem key={item.id} id={item.id} name={item.account.name || ""} />
        ))}
      </UserItemWrapper>
      {memberships.length > 5 && !expanded && (
        <Button icon={AddIcons} label="Sjá fleiri" stretch={true} onClick={handleClick} />
      )}
    </>
  );
};
