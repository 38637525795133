/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const ResidenceIcons: BykoReactIconComponentType = ({ size, iconColor = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 9C18 13.938 10 22 10 22C10 22 2 13.938 2 9C2 3.938 6.134 1 10 1C13.866 1 18 3.938 18 9Z"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path d="M20 17V23" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M17 20H23" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path
        d="M10 12C11.6569 12 13 10.6569 13 9C13 7.34315 11.6569 6 10 6C8.34315 6 7 7.34315 7 9C7 10.6569 8.34315 12 10 12Z"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
};
