import { theme } from "./theme";

export type ButtonColor = "whiteButton" | "blueButton" | "yellowButton" | "orangeButton" | "greenButton" | "redButton";

export const handleColorType = (buttonColor: ButtonColor | undefined | null): string => {
  switch (buttonColor) {
    case "whiteButton":
      return `p {color: ${theme.palette.blue.dark}}; 
              background-color: ${theme.palette.white.main}; 
              border-color: ${theme.palette.gray[10]};              
              :hover {
                box-shadow: 0px 18px 45px rgba(0, 0, 0, 0.09);
                border-color: ${theme.palette.transparent.main};
              };`;
    case "blueButton":
      return `p {color: ${theme.palette.white.main}}; 
              background-color: ${theme.palette.blue.main};
              :hover {background-color:  ${theme.palette.blue.dark}};
              :disabled {background-color: ${theme.palette.blue.lightest};              
              border-color: ${theme.palette.blue.lightest}};`;
    case "yellowButton":
      return `p {color: ${theme.palette.blue.dark}};
              background-color: ${theme.palette.yellow.main};
              border-color: ${theme.palette.yellow.main}};
              :hover {background-color:  ${theme.palette.yellow.dark}};`;
    case "orangeButton":
      return `p {color: ${theme.palette.white.main}};
              background-color: ${theme.palette.orange.main};
              border-color: ${theme.palette.orange.main}};
              :hover {background-color:  ${theme.palette.orange.dark}};`;
    case "greenButton":
      return `p {color: ${theme.palette.white.main}};
              background-color: ${theme.palette.green.main};
              border-color: ${theme.palette.green.main}};
              :hover {background-color:  ${theme.palette.green.dark}};`;
    case "redButton":
      return `p {color: ${theme.palette.triggers.warning.main}};
              border-color: ${theme.palette.triggers.warning.main};
              background-color: ${theme.palette.white.main};
              :hover {background-color:  ${theme.palette.triggers.warning.main};
              svg {path {stroke: ${theme.palette.white.main}}};
              p {color: ${theme.palette.white.main}}};`;
    default:
      return `p {color: ${theme.palette.blue.dark}}; 
              background-color: ${theme.palette.white.main};`;
  }
};
