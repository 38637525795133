/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const DeliverOrderIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_279_27484)">
        <path d="M6.5 3.25L18.5 9.25" stroke={iconColor} strokeMiterlimit="10" strokeWidth="2" />
        <path d="M23 7L13 12L1 6" stroke={iconColor} strokeMiterlimit="10" strokeWidth="2" />
        <path d="M13 12V23" stroke={iconColor} strokeMiterlimit="10" strokeWidth="2" />
        <path
          d="M23 7V17L13 23L1 17V6L11 1L23 7Z"
          stroke={iconColor}
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_279_27484">
          <rect fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
};
