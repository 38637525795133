/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const XlsIcons: BykoReactIconComponentType = ({ size, iconColor, ...props }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.2771 17.9226C12.3423 17.9226 12.3858 17.8883 12.364 17.8367L10.9185 14.8797L12.2445 12.1633C12.2662 12.1117 12.2227 12.0774 12.1575 12.0774H10.9402C10.875 12.0774 10.8424 12.1117 10.8207 12.1633L10.2011 13.6762H10.1794L9.55988 12.1633C9.53815 12.1117 9.50554 12.0774 9.44033 12.0774H8.21216C8.14695 12.0774 8.10347 12.1117 8.12521 12.1633L9.4512 14.8797L8.00565 17.8367C7.98392 17.8883 8.02739 17.9226 8.0926 17.9226H9.3099C9.37512 17.9226 9.41859 17.8883 9.44033 17.8367L10.1794 16.1777H10.2011L10.9293 17.8367C10.9511 17.8883 10.9837 17.9226 11.0489 17.9226H12.2771Z"
        fill={iconColor}
      />
      <path
        d="M13.3319 17.8367C13.3319 17.8883 13.3754 17.9226 13.4406 17.9226H16.0111C16.0763 17.9226 16.1198 17.8883 16.1198 17.8367V16.9943C16.1198 16.9427 16.0763 16.9083 16.0111 16.9083H14.821C14.7775 16.9083 14.7557 16.8911 14.7557 16.8567V12.1633C14.7557 12.1117 14.7123 12.0774 14.6471 12.0774H13.4406C13.3754 12.0774 13.3319 12.1117 13.3319 12.1633V17.8367Z"
        fill={iconColor}
      />
      <path
        d="M21 16.1862C21 15.1977 20.6087 14.7679 19.5979 14.4585L19.1523 14.3209C18.5763 14.1404 18.4567 14.0029 18.4567 13.5645C18.4567 13.1691 18.6415 12.9799 19.0219 12.9799C19.4132 12.9799 19.5979 13.1948 19.6088 13.616C19.6088 13.6676 19.6631 13.6934 19.7284 13.6848L20.837 13.5473C20.9022 13.5387 20.9348 13.5043 20.9348 13.4527C20.8804 12.533 20.2718 12 18.9784 12C17.6633 12 17.022 12.5673 17.022 13.6246C17.022 14.5616 17.3807 15 18.4241 15.3181L18.8697 15.4556C19.424 15.6275 19.5545 15.808 19.5545 16.3238C19.5545 16.8567 19.3697 17.0201 18.9675 17.0201C18.5437 17.0201 18.348 16.8052 18.3263 16.2636C18.3263 16.212 18.2828 16.1862 18.2067 16.1948L17.0438 16.341C16.9786 16.3496 16.9568 16.384 16.9568 16.4355C16.9786 17.4413 17.6198 18 18.9567 18C20.424 18 21 17.3811 21 16.1862Z"
        fill={iconColor}
      />
      <rect height="10" stroke={iconColor} strokeWidth="2" width="18" x="5" y="10" />
      <path d="M20 10.4286V7.28571L14 1H2V23H20V20.9048" stroke={iconColor} strokeWidth="2" />
      <path d="M20 7H14V1" stroke={iconColor} strokeWidth="2" />
    </svg>
  );
};

export default XlsIcons;
