import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const EyeClosedIcons: BykoReactIconComponentType = ({ size = 18, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_955_5309)">
        <path d="M2 22L22 2" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
        <path
          // eslint-disable-next-line max-len
          d="M1 12C2.99969 8.5 5.99985 5 12 5C18.0002 5 21.0002 8.5 23 12C21.0002 16.375 17.0001 19 12 19C6.99988 19 2.99969 16.375 1 12Z"
          stroke={iconColor}
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          // eslint-disable-next-line max-len
          d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
          stroke={iconColor}
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_955_5309">
          <rect fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
};
