/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const EcologyLineDecorative: BykoReactDecorativeIconComponentType = ({ size, color = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.39 5.97095C12.4571 6.99385 13.2934 8.2328 13.843 9.60495C14.5256 10.0868 15.0686 10.7406 15.417 11.4999C15.6274 12.3212 15.6346 13.1813 15.438 14.0059C15.438 14.0059 16.5 17.1249 16 17.9999C15.5 18.8749 13.375 19.4379 13.375 19.4379C13.0865 19.9302 12.6984 20.3569 12.2355 20.6906C11.7727 21.0243 11.2453 21.2577 10.687 21.3759C10.0255 21.4127 9.40507 21.7088 8.96045 22.2C8.51582 22.6913 8.28283 23.338 8.31202 23.9999C8.31202 25.6879 10 28.4379 10 28.4379C10 28.4379 11.75 29.2939 12 29.9189C12.1883 30.5981 12.3007 31.296 12.335 31.9999C12.335 31.9999 14 34.9379 13.062 36.6249C12.762 37.1629 11.374 37.5629 11.374 37.5629L9.42702 40.4829"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path
        d="M41.3619 10.4869C39.4737 11.2754 37.5212 11.9001 35.5259 12.3539C35.5259 12.3539 32.8129 15.3739 31.0259 15.1239C29.2389 14.8739 27.4319 12.8739 27.4319 12.8739C27.4319 12.8739 27.5619 9.18795 26.9999 8.99995C26.4379 8.81195 24.9379 9.81195 23.9999 9.06195C23.0619 8.31195 23.5999 6.19995 23.5999 6.19995C23.5999 6.19995 21.5589 5.56395 21.3089 4.87595C21.0589 4.18795 22.0519 2.65695 22.2409 2.06995"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path
        d="M44 14.8359C42.4657 11.476 40.1101 8.55648 37.1504 6.34654C34.1908 4.13661 30.7224 2.70742 27.065 2.19077C23.4077 1.67412 19.6791 2.08663 16.2231 3.39028C12.7671 4.69392 9.69503 6.84673 7.29006 9.65019C4.88509 12.4536 3.22469 15.8175 2.46195 19.4316C1.69922 23.0456 1.85872 26.7936 2.92573 30.3298C3.99275 33.866 5.93293 37.0766 8.56738 39.6656C11.2018 42.2546 14.4457 44.1386 18 45.144"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path
        d="M39.321 31.1609C28.934 34.8709 31.9 45.9999 23 45.9999"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path
        d="M30.257 20.523C29.7288 20.2046 29.167 19.9454 28.582 19.75C27.4869 19.7924 26.4049 20.0032 25.374 20.375C25.374 20.375 22.374 24 22.374 25.375C22.395 25.5701 22.4729 25.7548 22.598 25.906"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path
        d="M46 19.9729C44.6076 21.8429 42.7975 23.3617 40.7141 24.4082C38.6306 25.4546 36.3315 25.9996 34 25.9999C31.8783 25.9999 29.8434 26.8428 28.3431 28.343C26.8429 29.8433 26 31.8782 26 33.9999C26 36.1216 26.8429 38.1565 28.3431 39.6568C29.8434 41.157 31.8783 41.9999 34 41.9999C37.1826 41.9999 40.2348 40.7356 42.4853 38.4852C44.7357 36.2347 46 33.1825 46 29.9999V19.9729Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
    </svg>
  );
};
