import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const BagAddIcons: BykoReactIconComponentType = ({ size, iconColor = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M13 23H2V6H20V13" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path
        d="M7 9V5C7 2.791 8.791 1 11 1C13.209 1 15 2.791 15 5V9"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path d="M19 17V23" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M16 20H22" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
    </svg>
  );
};
