import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const Measure1FilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M31 2H17C15.895 2 15 2.895 15 4V46C15 47.105 15.895 48 17 48H31C32.105 48 33 47.105 33 46V4C33 2.895 32.105 2 31 2Z"
        fill="#FFD330"
      />
      <path d="M23 8H15V10H23V8Z" fill="#0A3A5C" />
      <path d="M20 16H15V18H20V16Z" fill="#0A3A5C" />
      <path d="M23 24H15V26H23V24Z" fill="#0A3A5C" />
      <path d="M23 40H15V42H23V40Z" fill="#0A3A5C" />
      <path d="M20 32H15V34H20V32Z" fill="#0A3A5C" />
    </svg>
  );
};
