import styled from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";

export const TagWrapper = styled.div`
  position: relative;
  display: flex;
  min-width: fit-content;
  align-items: center;
  padding: 10px 16px;
  margin-bottom: 2px;
  background-color: ${theme.palette.white.main};
  gap: 8px;
  outline: 1px solid ${theme.palette.blue.light};
  p {
    margin-top: 4px;
  }
  @media screen and (min-width: ${gridTheme.breakpoints.sm}px) {
    padding: 10px 16px;
  }
`;

export const Tags = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3px;
  margin-left: 2px;
  gap: 8px;
`;

export const ClearButton = styled.button`
  display: grid;
  outline: none;
  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    border-bottom: 2px solid ${theme.palette.blue.dark};
    content: "";
    transition: 300ms;
  }
  &:focus,
  &:hover {
    &:after {
      width: 100%;
    }
  }
`;
