import * as React from "react";

import { IFrame } from "./styles";

import type { VideoSlideProps } from "./interface";

export const VideoSlide = ({ src, height = 240, width }: Omit<VideoSlideProps, "type">): JSX.Element => {
  return (
    <IFrame
      allowFullScreen={true}
      frameBorder="0"
      height={height}
      src={`https://youtube.com/embed/${src}`}
      width={width}
    />
  );
};
