import styled from "styled-components";

import { theme } from "@byko/lib-styles";

import type { SearchContainerProps } from "./interface";

export const SearchInputField = styled.input<SearchContainerProps>`
  position: absolute;
  display: flex;
  width: 100%;
  height: 56px;
  padding-top: 16px;
  padding-left: 16px;
  border: none;
  border: ${(p): string => (p.border ? `1px solid ${theme.palette.gray[10]}` : "none")};
  background-color: ${theme.palette.white.main};
  caret-color: ${theme.palette.blue.dark};
  color: ${theme.palette.blue.main};
  font-size: 16px;
  &:disabled {
    opacity: 0.5;
  }
  &:focus {
    border: none;
  }
  &:hover {
    box-shadow: ${theme.palette.boxShadow.input};
  }
`;

export const PrefixIcon = styled.div`
  z-index: 1;
  padding-right: 8px;
  margin-bottom: -5px;
  margin-left: auto;
  transition: transform 0.15s ease-out;
  button {
    padding: 12px 12px 4px 12px;
    margin-bottom: 6px;
    transition: 300ms;
  }
`;

export const ClearBtn = styled(PrefixIcon)``;

export const FocusLine = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  display: block;
  width: 0;
  height: 1px;
  background-color: ${theme.palette.blue.main};
  transition: width 0.15s ease-out;
`;

export const Placeholder = styled.span`
  position: absolute;
  left: 16px;
  color: ${theme.palette.gray[60]};
  font-size: 1rem;
  font-weight: 400;
  pointer-events: none;
  pointer-events: none;
  transform-origin: 0 50%;
  transition: transform 0.15s ease-out, color 0.15s;
  user-select: none;
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 56px;
  align-items: center;

  .byko-icon {
    stroke: ${theme.palette.gray[40]};
    transition: transform 0.15s ease-out, fill 0.15s;
  }
  input {
    &:focus {
      outline: none;
      & ~ ${PrefixIcon} {
        .byko-icon {
          stroke: ${theme.palette.blue.main};
          transition: transform 0.15s ease-out, fill 0.15s;
        }
      }
      & ~ ${Placeholder} {
        color: ${theme.palette.gray[40]};
        font-weight: normal;
        transform: scale(0.75) translateY(-18px);
      }

      & ~ ${FocusLine} {
        width: 100%;
      }
    }

    &:not(:placeholder-shown) {
      & ~ ${Placeholder} {
        color: ${theme.palette.gray[40]};
        font-weight: normal;
        transform: scale(0.75) translateY(-18px);
      }
    }

    &::placeholder {
      opacity: 0;
    }
  }
`;
