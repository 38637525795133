import { CheckboxFacet } from "./checkbox-facet";
import { HierarchicalCheckboxFacet } from "./hierarchical-checkbox-facet";
import { RangeFacet } from "./range-facet";

const facetComponentTypeMap = {
  hierarchical: HierarchicalCheckboxFacet,
  choice: CheckboxFacet,
  range: RangeFacet,
};

export { facetComponentTypeMap };
