import * as React from "react";
import { useCallback } from "react";

import { AddIcons, SubtractIcons } from "@byko/lib-icons";
import { theme } from "@byko/lib-styles";

import { QtyButtonContainer, QtyButtonWrapper, QtyIconButton, QtyInputNumber } from "./styles";

import type { QuantityInputProps } from "./interface";

export const MiniQuantityInput = ({
  quantity,
  addClick,
  subtractClick,
  onInputChange,
  addDisabled = false,
  stretch = false,
  setQuantity,
  ...props
}: QuantityInputProps): JSX.Element => {
  const checkValue = useCallback(() => {
    if (Number.isNaN(quantity)) {
      setQuantity(1);
    }
  }, [quantity, setQuantity]);
  return (
    <QtyButtonContainer className="quantity-input" stretch={stretch}>
      <QtyButtonWrapper>
        <QtyIconButton aria-label={"Minnka magn"} disabled={quantity < 2} stretch={stretch} onClick={subtractClick}>
          <SubtractIcons iconColor={theme.palette.blue.main} size={16} />
        </QtyIconButton>

        <QtyInputNumber
          aria-label="quantity"
          name="quantity"
          stretch={stretch}
          type="number"
          value={quantity}
          onBlur={checkValue}
          onChange={onInputChange}
          {...props}
        />

        <QtyIconButton aria-label={"Bæta við magni"} disabled={addDisabled} stretch={stretch} onClick={addClick}>
          <AddIcons iconColor={theme.palette.blue.main} size={16} />
        </QtyIconButton>
      </QtyButtonWrapper>
    </QtyButtonContainer>
  );
};
