import { theme } from "@byko/lib-styles";
import styled from "styled-components";

export const TabsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid ${theme.palette.gray[10]};
  margin-bottom: 24px;
`;

export const Tab = styled.button`
  position: relative;
  width: fit-content;
  height: 56px;
  border: 0;
  border-radius: 0;
  background-color: ${theme.palette.white.main};
  color: ${theme.palette.blue.main};
  cursor: pointer;
  padding-inline: 24px;
  transition: 200ms;
  user-select: none;

  &:after {
    position: absolute;
    right: 0;
    bottom: -1px;
    left: 0;
    display: block;
    height: 2px;
    background-color: ${theme.palette.blue.main};
    content: "";
    opacity: 0;
    transition: opacity 200ms;
  }

  &:hover {
    background-color: ${theme.palette.gray[5]};
  }

  &.isActive {
    background: ${theme.palette.gray[5]};
    cursor: default;

    &:after {
      opacity: 1;
    }
  }
`;

export const TabContent = styled.div`
  display: none;

  &.isActive {
    display: block;
  }
`;
