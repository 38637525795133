import Link from "next/link";
import * as React from "react";

import { theme } from "@byko/lib-styles";

import { Connector, Label, Step } from "./styles";

import type { IconStepComponentProps } from "./interface";

export const IconStep = ({
  isfulfilled = false,
  label,
  icon: Icon,
  noConnectorLine = false,
  currentstep = false,
  url,
  square = false,
}: IconStepComponentProps): JSX.Element => {
  return (
    <>
      {!noConnectorLine ? <Connector isfulfilled={isfulfilled ? "fulfilled" : undefined} /> : null}
      <Step
        as={!!url && isfulfilled && !currentstep ? Link : "div"}
        currentstep={currentstep ? "currentstep" : undefined}
        href={!!url && isfulfilled && !currentstep ? url : undefined}
        isfulfilled={isfulfilled ? "fulfilled" : undefined}
        square={square ? "square" : undefined}
      >
        {Icon && <Icon iconColor={theme.palette.white.main} size={24} />}
        <Label
          currentstep={currentstep ? "currentstep" : undefined}
          isfulfilled={isfulfilled ? "fulfilled" : undefined}
        >
          {label}
        </Label>
      </Step>
    </>
  );
};
