import classNames from "classnames";
import { Tab, TabContent, TabsWrapper } from "./styles";

import React from "react";
import type { TabsProps } from "./interface";

export const Tabs = ({ tabs, activeTab, setActiveTab }: TabsProps): JSX.Element => {
  return (
    <>
      <TabsWrapper>
        {tabs.map(({ label, id }) => (
          <Tab
            key={id}
            className={classNames({ isActive: activeTab === id })}
            type="button"
            // eslint-disable-next-line react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop
            onClick={() => setActiveTab(id)}
          >
            {label}
          </Tab>
        ))}
      </TabsWrapper>
      {tabs.map(({ content, id }) => (
        <TabContent key={id} className={classNames({ isActive: activeTab === id })}>
          {content}
        </TabContent>
      ))}
    </>
  );
};
