import styled from "styled-components";
import { ifProp } from "styled-tools";

import { theme } from "@byko/lib-styles";

import type { LabelProps, SwitchProps, TextProps } from "./interface";

export const Label = styled.label<LabelProps>`
  position: relative;
  display: flex;
  align-items: center;
  cursor: ${ifProp({ disabled: true }, "not-allowed", "pointer")};
`;

export const Text = styled.div<TextProps>`
  margin-left: 16px;
  color: ${(props): string => props.labelColor};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
`;

export const Switch = styled.div<SwitchProps>`
  position: relative;
  width: 48px;
  height: 24px;
  border: ${ifProp({ border: true }, `1px solid ${theme.palette.white.main}`, "none")};
  border-radius: 12px;
  background-color: ${ifProp({ checked: true }, theme.palette.blue.main, theme.palette.gray[30])};
  outline: ${ifProp({ border: true }, `1px solid ${theme.palette.blue.main}`, "none")};
  transition: background-color 0.15s;
  &:after {
    position: absolute;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: ${ifProp({ checked: true }, "25px", "3px")};
    display: flex;
    width: 18px;
    height: 18px;
    border-radius: 12px;
    margin: auto 0;
    margin-left: ${ifProp({ checked: true }, "auto", "0")};
    background-color: ${theme.palette.white.main};
    content: "";
    transition: all 150ms ease-out;
  }

  &:before {
    border-radius: 10px;
  }
  &:hover {
    &:after {
      left: ${ifProp({ checked: true }, "0", "3px")};
      width: 28px;
      content: "";
    }
  }
`;

export const CheckboxInput = styled.input<SwitchProps>`
  position: absolute;
  border-radius: 0;
  visibility: hidden;

  &:disabled {
    ~ ${Switch} {
      border: ${ifProp({ border: true }, `1px solid ${theme.palette.white.main}`, "none")};
      background-color: ${theme.palette.gray[10]};
      outline: ${ifProp({ border: true }, `1px solid ${theme.palette.gray[10]}`, "none")};
      &:after {
        width: 18px;
      }
      &:hover {
        transform: none;

        &:before {
          transform: translateZ(-1em);
        }
      }
    }
  }
`;
