import styled from "styled-components";
import { ifProp } from "styled-tools";

import { scrollStyle, theme } from "@byko/lib-styles";

export const Suggestions = styled.ul`
  position: absolute;
  width: 100%;
  max-height: 280px;
  padding-left: 0;
  border: 1px solid hsla(218, 100%, 42%, 0.5);
  border-top-width: 0;
  margin-top: 0;
  background-color: ${theme.palette.white.main};
  list-style: none;
  overflow-y: auto;
`;

export const Item = styled.li`
  display: flex;
  width: 100%;
  height: 56px;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  border-top: 1px solid ${theme.palette.gray[10]};
  cursor: pointer;
  font-weight: ${ifProp({ selected: true }, "700", "400")};
  text-align: left;
  transition: border-color 0.15s, background-color 0.15s;
  &:hover {
    background-color: ${theme.palette.gray[5]};
  }
`;

export const Border = styled.div`
  border: 1px solid ${theme.palette.gray[10]};
  margin-top: 40px;
`;

export const UserItemWrapper = styled.div`
  display: flex;
  max-height: 30vh;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  ${scrollStyle({ color: "dark-blue" })}

  button {
    p {
      font-weight: normal;
    }
    &:hover {
      border: 1px solid ${theme.palette.gray[20]};
      box-shadow: none;
    }
  }
`;
