import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const WrenchFilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M46.3593 10.3012C46.2519 9.97016 45.9804 9.71918 45.6425 9.63715C45.3007 9.55414 44.9472 9.65668 44.7011 9.90277L39.4609 15.143L33.8037 9.48577L39.0439 4.24554C39.29 3.99945 39.3906 3.643 39.3095 3.30413C39.2275 2.96624 38.9755 2.69378 38.6445 2.58733C37.1064 2.08928 35.5107 1.90569 33.9091 2.04534C27.7597 2.57757 22.8427 8.03264 22.9482 14.2035C22.9648 15.1547 23.0937 16.1 23.3339 17.0219L4.5849 33.5707C2.98627 34.9828 2.07025 36.934 2.00385 39.0649C1.93745 41.1957 2.73139 43.1996 4.2392 44.7075C5.68842 46.1567 7.59662 46.9467 9.63568 46.9467C9.71771 46.9467 9.79974 46.9457 9.88177 46.9428C12.0126 46.8764 13.9638 45.9604 15.3759 44.3618L31.9247 25.6137C33.6953 26.0756 35.5156 26.1274 37.3456 25.7612C41.6962 24.895 45.2812 21.5922 46.4775 17.3481C47.1357 15.0102 47.0956 12.5737 46.3593 10.3012ZM9.94623 41.0004C8.84168 41.0004 7.94623 40.105 7.94623 39.0004C7.94623 37.8958 8.84168 37.0004 9.94623 37.0004C11.0508 37.0004 11.9462 37.8958 11.9462 39.0004C11.9462 40.105 11.0508 41.0004 9.94623 41.0004Z"
        fill="#C6C6C6"
      />
    </svg>
  );
};
