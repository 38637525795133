/* eslint-disable react-perf/jsx-no-new-object-as-prop */

import React, { createContext, useCallback, useContext, useEffect, useState } from "react";

import { v4 as uuidv4 } from "uuid";
import { useCookies } from "react-cookie";
import { useEffectOnce, useLocalStorage } from "react-use";
import { default as searchInsights } from "search-insights";

import { getBykoConfig } from "@byko/lib-utils";
import { SEARCH_INDEXES } from "./conf";

export const APP_ID = getBykoConfig("ALGOLIA_APP_ID");
export const API_KEY = getBykoConfig("ALGOLIA_API_KEY");

const DEBUGG = false;

export interface ViewEventProps {
  eventName: string;
  objectIDs: string[];
}

export interface ClickEventProps {
  eventName: string;
  index: string;
  queryID: string;
  objectIDs: string[];
  positions: number[];
}

export interface AddToCartEventProps {
  eventName: string;
  index?: string;
  queryID?: string;
  objectIDs: string[];
}

export interface FilterEventProps {
  eventName: string;
  filter: string;
}

interface AlgoliaInsightProviderProps {
  children: React.ReactNode;
}

export const AlgoliaInsightContext = createContext<Partial<AlgoliaInsightContextProps>>({});

export function AlgoliaInsightProvider({ children }: AlgoliaInsightProviderProps) {
  const [algoliaInit, setAlgoliaInit] = useState<boolean>(false);

  const [lastAlgoliaParameters, setLastAlgoliaParamters] = useLocalStorage("algoliaParameters", {
    index: "",
    queryID: "",
  });

  const [eventsToken] = useLocalStorage<string>("eventsToken", uuidv4());

  const [cookies] = useCookies(["cookiehub", "_ga"]);

  const analyticsConsent = (typeof window !== "undefined" && window.cookiehub?.hasConsented("analytics")) || DEBUGG;

  const extractGoogleAnalyticsUserIdFromCookie = () => {
    const gaCookie = cookies?._ga;

    if (gaCookie) {
      // Remove the Google Analytics tracker from the device ID.
      const userIdParts = gaCookie.split(".").slice(-2);
      if (userIdParts.length === 2) {
        return userIdParts.join("_");
      }
    }
    return undefined;
  };

  const userId = extractGoogleAnalyticsUserIdFromCookie() || eventsToken;

  const sendViewInsights = useCallback(
    ({ eventName, objectIDs }: ViewEventProps) => {
      if (algoliaInit && analyticsConsent) {
        searchInsights("viewedObjectIDs", {
          index: SEARCH_INDEXES.products,
          eventName,
          objectIDs,
        });
      }
    },
    [algoliaInit, analyticsConsent],
  );

  const sendClickInsights = useCallback(
    ({ eventName, index, queryID, objectIDs, positions }: ClickEventProps) => {
      if (algoliaInit && analyticsConsent) {
        searchInsights("clickedObjectIDsAfterSearch", {
          eventName,
          index,
          queryID,
          objectIDs,
          positions,
        });
      }
    },
    [algoliaInit, analyticsConsent],
  );

  const sendAddToCartInsights = useCallback(
    ({ eventName, index, queryID, objectIDs }: AddToCartEventProps) => {
      if (algoliaInit && analyticsConsent) {
        searchInsights("convertedObjectIDsAfterSearch", {
          eventName,
          index: index || lastAlgoliaParameters?.index || "",
          queryID: queryID || lastAlgoliaParameters?.queryID || "",
          objectIDs,
        });
      }
    },
    [algoliaInit, analyticsConsent, lastAlgoliaParameters],
  );

  const sendFilterClickedEvent = useCallback(
    ({ eventName, filter }: FilterEventProps) => {
      if (algoliaInit && analyticsConsent) {
        searchInsights("clickedFilters", {
          eventName,
          index: SEARCH_INDEXES.products,
          filters: [filter],
        });
      }
    },
    [algoliaInit, analyticsConsent],
  );

  useEffect(() => {
    searchInsights("setUserToken", userId);
  }, [userId]);

  useEffectOnce(() => {
    setAlgoliaInit(true);
    searchInsights("init", {
      appId: APP_ID,
      apiKey: API_KEY,
    });
  });

  return (
    <AlgoliaInsightContext.Provider
      value={{
        algoliaInit,
        sendViewInsights,
        sendClickInsights,
        sendAddToCartInsights,
        sendFilterClickedEvent,
        setLastAlgoliaParamters,
      }}
    >
      {children}
    </AlgoliaInsightContext.Provider>
  );
}

export interface AlgoliaInsightContextProps {
  algoliaInit: boolean;
  sendViewInsights: (props: ViewEventProps) => void;
  sendClickInsights: (props: ClickEventProps) => void;
  sendAddToCartInsights: (props: AddToCartEventProps) => void;
  sendFilterClickedEvent: (props: FilterEventProps) => void;
  setLastAlgoliaParamters: (props: { index: string; queryID: string }) => void;
}

export function useAlgoliaInsights() {
  return useContext(AlgoliaInsightContext) as AlgoliaInsightContextProps;
}
