import * as React from "react";

import { PLargeBold } from "@byko/component-typography";
import { theme } from "@byko/lib-styles";

import { CardContainer, CardContentContainer, CardHeadingContainer } from "./styles";

import type { TitleCardProps } from "./interface";

export const TitleCard = ({ title, children }: TitleCardProps): JSX.Element => {
  return (
    <CardContainer>
      <CardHeadingContainer>
        <PLargeBold textColor={theme.palette.blue.dark}>{title}</PLargeBold>
      </CardHeadingContainer>
      <CardContentContainer>{children}</CardContentContainer>
    </CardContainer>
  );
};
