import React from "react";

import { useWindowSize } from "@byko/lib-styles";

import { ProductCardGrid, ResultGrid, SortingContainer } from "./styles";

import type { ResultsBlockProps } from "./interface";
import { ResultsCountBlock } from "../results-count-block";
import { DropdownSortingBlock } from "../filter-dropdown-sorting-block";

export const ResultsBlock = ({ children, count, handleSortClick, sortingOptions }: ResultsBlockProps): JSX.Element => {
  const { width } = useWindowSize();

  if (count === 0) return <ResultsCountBlock count={count} />;

  return (
    <ResultGrid className="result-block">
      <SortingContainer>
        {typeof count !== "undefined" ? <ResultsCountBlock count={count} /> : null}
        {width > 580 && (
          <>
            <DropdownSortingBlock handleClick={handleSortClick} sortingOptions={sortingOptions} />
          </>
        )}
      </SortingContainer>
      <ProductCardGrid>{children}</ProductCardGrid>
    </ResultGrid>
  );
};
