import styled from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";
import {} from "@byko/lib-icons";

export const ButtonHoverLabel = styled.div`
  position: absolute;
  right: 0;
  bottom: 50%;
  width: fit-content;
  width: 148px;
  padding-top: 2px;
  border-radius: 4px;
  background-color: ${theme.palette.blue.dark};
  opacity: 0;
  pointer-events: none;
  transform: translate3d(-58px, 50%, 0);
  transition: 250ms;
  &::after {
    position: absolute;
    top: 50%;
    left: 100%;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid ${theme.palette.blue.dark};
    content: "";
    transform: translate3d(0, -50%, 0);
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const CircleButton = styled.button`
  position: absolute;
  right: 8px;
  bottom: 8px;
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.palette.gray[10]};
  border-radius: 50%;
  background-color: ${theme.palette.white.main};
  transition: box-shadow 200ms ease-in-out;
  &:hover {
    box-shadow: ${theme.palette.boxShadow.input};
    ${ButtonHoverLabel} {
      opacity: 1;
      transform: translate3d(-48px, 50%, 0);
    }
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  padding: 32px;
`;

export const ProdImage = styled.img`
  width: 100%;
  height: 241px;
  max-height: 241px;
  object-fit: contain;
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    height: 200px;
    max-height: 200px;
  }
`;

export const HeartWrapper = styled.button`
  position: absolute;
  right: 8px;
  bottom: 8px;
  width: 40px;
  height: 40px;
  padding: 13px;
  border: 1px solid ${theme.palette.gray[10]};
  border-radius: 50%;
  background-color: ${theme.palette.white.main};
  &:hover {
    box-shadow: ${theme.palette.boxShadow.input};
  }
`;
