import styled from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";

export const ElectronicImage = styled.img`
  object-fit: contain;
`;

export const ElectronicImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ProductWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1616px;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 50px;
  margin-right: auto;
  margin-bottom: 50px;
  margin-left: auto;
  gap: 50px;
  grid-template-columns: 0.5fr 0.5fr;
  transition: all 300ms;
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
  }
  @media screen and (max-width: ${gridTheme.container.maxWidth.xs}px) {
    padding: 0px;
  }
  @media screen and (max-width: 1078px) {
    margin-top: 16px;
  }
`;

interface ProductImageProps {
  clickable?: boolean;
}

export const ProductImage = styled.img<ProductImageProps>`
  width: 100%;
  max-width: 700px;
  height: 100%;
  max-height: 700px;
  cursor: ${(props) => (props.clickable ? "pointer" : "default")};
  object-fit: contain;
`;

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-width: 200px;
  height: 400px;
  align-items: center;
  justify-content: center;
  padding-top: 48px;
  padding-bottom: 48px;

  .image-selection-button {
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    opacity: 0;
    transform: translateY(-50%);
    &.previous-image-button {
      left: 8px;
    }
    &.next-image-button {
      right: 8px;
    }
  }
  &:hover {
    .image-selection-button {
      opacity: 1;
    }
  }
  @media screen and (max-width: 699px) {
    max-height: 20vh;
    padding-top: unset;
    padding-bottom: unset;
  }
`;

export const LeftWrapper = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  gap: 10px;
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    width: 100%;
  }
`;

export const SliderImage = styled.img<{ transformValue: number }>`
  position: relative;
  min-width: 152px;
  max-width: 152px;
  height: 106px;
  object-fit: contain;
  transform: translate3d(-${(props): string => props.transformValue.toString()}px, 0, 0);
  transition: 400ms;
`;

export const SliderItem = styled.div<{ transformValue: number }>`
  position: relative;
  overflow: hidden;
  min-width: 152px;
  max-width: 152px;
  height: 106px;
  border: 1px solid ${theme.palette.gray[10]};
  transform: translate3d(-${(props): string => props.transformValue.toString()}px, -2px, 0);
  transition: 400ms;
`;

export const PaginationContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

export const ProgressBar = styled.div<{ progress: string; sliderSize: string }>`
  position: relative;
  width: calc(100% - 8px);
  height: 1px;
  background-color: ${theme.palette.gray[30]};
  &::after {
    position: absolute;
    top: -1px;
    left: ${(props): string => props.progress};
    width: ${(props): string => props.sliderSize};
    height: 3px;
    background-color: ${theme.palette.blue.main};
    content: "";
    transition: 400ms;
  }
`;

export const ClickableDemoupSlideWrapper = styled.div<{ marginTop?: boolean }>`
  display: flex;
  align-items: center;
  margin-top: ${(props): string => (props.marginTop ? "39px" : "0")};
  &:focus {
    box-shadow: none;
    -webkit-user-drag: none;
    * {
      -webkit-user-drag: none;
    }
  }

  .demoupUI-img {
    object-fit: contain;
    svg {
      width: 48px;
    }
  }
`;

export const ClickableSliderImageWrapper = styled.button`
  &:focus {
    box-shadow: none;
    -webkit-user-drag: none;
    * {
      -webkit-user-drag: none;
    }
  }
`;

export const HiddenContainer = styled.div`
  display: none;
  overflow: hidden;
  width: 0;
  height: 0;
  pointer-events: none;
`;

export const ImageFlex = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  padding: 1rem 0;
  margin-top: auto;
  margin-bottom: 0.5rem;

  gap: 1rem;
  -ms-overflow-style: none;

  overflow-x: auto;
  scrollbar-width: none;

  /* Smooth transitions */
  transition: all 0.3s ease-in-out;
  &::-webkit-scrollbar {
    display: none;
  }

  /* Responsive design */
  @media screen and (max-width: 699px) {
    padding: 0.5rem 0;
    margin-top: auto;
    margin-bottom: 0.2rem;
    gap: 0.5rem;
  }

  @media screen and (min-width: 700px) and (max-width: 1024px) {
    padding: 0.75rem 0;
    margin-top: auto;
    margin-bottom: 0.2rem;
    gap: 0.75rem;
  }
`;

export const TabsContainer = styled.div`
  width: 100%;
  max-width: ${gridTheme.maxContentWidth}px;
  margin: auto;
  margin-top: 40px;
`;

export const VideoSlideWrapper = styled.div`
  width: 100%;
  height: fit-content;
  padding: 40px;
  margin-top: 40px;
  background-color: ${theme.palette.white.main};
`;

export const TagContainer = styled.div`
  width: 100%;
  max-width: ${gridTheme.maxContentWidth}px;
  margin-top: 24px;
  margin-right: auto;
  margin-left: auto;
`;

export const ModalContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: COLUMN;
  align-items: center;
  justify-content: center;

  .image-selection-button {
    position: absolute;
    top: 50%;
    width: 56px;
    height: 56px;
    opacity: 1;
    transform: translateY(-50%);
    &.previous-image-button {
      left: -2.4rem;
    }
    &.next-image-button {
      right: -2.4rem;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .image-selection-button {
      &.previous-image-button {
        left: -2.6rem;
      }
      &.next-image-button {
        right: -2.6rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .image-selection-button {
      width: 45px;
      height: 45px;
      &.previous-image-button {
        left: -2.9rem;
      }
      &.next-image-button {
        right: -2.9rem;
      }
    }
  }
`;
