import toPairs from "lodash/toPairs";
import { useCallback, useEffect, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

import { useUser } from "@byko/hooks-auth-next";
import { useSetRecoilState } from "@byko/lib-recoil";

import { useCheckoutCreateMutation, useCheckoutLinesUpdateMutation } from "../../../generated/graphql";
import { CHECKOUT_ERRORS, checkoutErrorCodeMap } from "../const";
import { Store } from "../store/atom";
import { getCartQuantityMap } from "../utils/get-cart-quantity-map";

import { useAnonymousToken } from "./use-anonymous-token";
import { useSetCheckoutStateValue } from "./use-checkout-state";

import type {
  AnonymousCheckoutByTokenQuery,
  CheckoutFragment,
  Exact,
  MyCheckoutQuery,
} from "../../../generated/graphql";
import type { CHECKOUT_ERROR } from "../const";
import type { ApolloQueryResult } from "@apollo/client";
import type { Maybe } from "@byko/types-utils";
import type { GraphQLError } from "graphql";

interface UpdateStore {
  update: (stateUpdate: Record<string, number>) => Promise<{ code: CHECKOUT_ERROR; message: string } | void>;
}

const typeIsError = (value: Maybe<CheckoutFragment | Readonly<GraphQLError[]>>): value is Readonly<GraphQLError[]> =>
  value != null && Array.isArray(value);

let id: string | null = null;

export const useUpdateStore = ({
  refetchMyCheckout,
  refetchCheckout,
}: {
  refetch: () => void;
  refetchMyCheckout: (
    variables?: Partial<
      Exact<{
        [key: string]: never;
      }>
    >,
  ) => Promise<ApolloQueryResult<MyCheckoutQuery>>;
  refetchCheckout: (
    variables?: Partial<
      Exact<{
        token: Maybe<string>;
      }>
    >,
  ) => Promise<ApolloQueryResult<AnonymousCheckoutByTokenQuery>>;
}): UpdateStore => {
  const user = useUser();
  const { token: anonymousToken, set: setAnonymousToken } = useAnonymousToken();
  const [checkoutLinesUpdate] = useCheckoutLinesUpdateMutation({});
  const setState = useSetCheckoutStateValue();
  const setLoading = useSetRecoilState(Store.loadingCartState);
  //const setCheckoutValue = useSetCheckoutValue();

  const [checkoutCreate] = useCheckoutCreateMutation({
    onError: (err) => {
      // eslint-disable-next-line no-console
      console.error(err);
    },
  });

  useEffect(() => {
    const fn = (): void => {
      id = null;
    };
    document.body.addEventListener("CheckoutChange", fn);
    return () => {
      document.body.removeEventListener("CheckoutChange", fn);
    };
  }, []);

  const handleCreateCheckout = useCallback(
    async (stateUpdate: Record<string, number>): Promise<Maybe<CheckoutFragment | CHECKOUT_ERROR>> => {
      let nextCheckout: Maybe<CheckoutFragment>;

      if (user) {
        // Fetching existing or 'authenticated' checkout
        const refetchRes = await refetchMyCheckout();
        if (refetchRes) {
          nextCheckout = refetchRes.data.me?.checkout ?? undefined;
        }
        // Creating new 'authenticated' checkout
        if (!nextCheckout) {
          const email = user.verified.email ?? `${user.id}@user.saleor`;
          const data = await checkoutCreate({
            variables: {
              email,
              lines: toPairs(stateUpdate).map(([variantId, quantity]) => ({
                quantity,
                variantId,
              })),
            },
          });

          if (data.data?.checkoutCreate?.errors && !data.data?.checkoutCreate?.created) {
            const errorCode = data.data.checkoutCreate.errors.find((err) =>
              CHECKOUT_ERRORS.includes(err?.code as CHECKOUT_ERROR),
            );
            if (errorCode) {
              return errorCode.code as CHECKOUT_ERROR;
            }
            return "UNKNOWN";
          }

          nextCheckout = data.data?.checkoutCreate?.checkout;
        }
      } else if (anonymousToken) {
        // Fetching existing 'anonymous' checkout
        nextCheckout = (await refetchCheckout())?.data?.checkout;
      } else {
        // Creating new 'anonymous' checkout
        const data = await checkoutCreate({
          variables: {
            email: `${uuidv4()}@anonymous.saleor`,
            lines: toPairs(stateUpdate).map(([variantId, quantity]) => ({
              quantity,
              variantId,
            })),
          },
        });
        if (data.data?.checkoutCreate?.errors && !data.data?.checkoutCreate?.created) {
          const errorCode = data.data.checkoutCreate.errors.find((err) =>
            CHECKOUT_ERRORS.includes(err?.code as CHECKOUT_ERROR),
          );
          if (errorCode) {
            return errorCode.code as CHECKOUT_ERROR;
          }
          return "UNKNOWN";
        }
        nextCheckout = data?.data?.checkoutCreate?.checkout;
        if (nextCheckout && nextCheckout.token) {
          setAnonymousToken(nextCheckout?.token);
        }
      }
      if (typeIsError(nextCheckout)) {
        return "UNKNOWN";
      }

      return nextCheckout;
    },
    [anonymousToken, checkoutCreate, refetchMyCheckout, refetchCheckout, setAnonymousToken, user],
  );

  const handleUpdateCheckout = useCallback(
    async ({ toUpdate, checkout }: { toUpdate: [string, number][]; checkout: CheckoutFragment }) => {
      const currentId = uuidv4();
      id = currentId;
      const checkoutUpdate = toUpdate.length
        ? (
            await checkoutLinesUpdate({
              variables: {
                token: checkout?.token,
                lines: toUpdate.map(([variantId, quantity]) => ({
                  quantity,
                  variantId,
                })),
              },
            })
          )?.data?.checkoutLinesUpdate
        : { errors: [] };
      //setCheckoutValue(checkout);
      const errors = checkoutUpdate?.errors;
      if (errors && errors.length > 0) {
        const errorCode = errors.find((err) => CHECKOUT_ERRORS.includes(err?.code as CHECKOUT_ERROR));
        if (errorCode) {
          return errorCode.code as CHECKOUT_ERROR;
        }
        return "UNKNOWN";
      }

      if (id === currentId) {
        setLoading({});
      }
      return;
    },
    [checkoutLinesUpdate, setLoading],
  );

  const getStore = useCallback(
    async (stateUpdate: Record<string, number>): Promise<CHECKOUT_ERROR | void> => {
      setState("LOADING");
      // TODO(Andri): refactor this
      const checkout = await handleCreateCheckout(stateUpdate);

      if (!checkout) {
        return "UNKNOWN";
      }
      if (typeof checkout == "string") {
        return checkout;
      }

      const currentState = getCartQuantityMap(checkout);
      const stateUpdatePairs = toPairs(stateUpdate);
      const toUpdate = stateUpdatePairs.filter(([vId, qty]) => currentState[vId] !== qty);
      setLoading(
        toUpdate.reduce(
          (a, b) => ({
            ...a,
            [b[0]]: b[1],
          }),
          {},
        ),
      );

      const possibleErrorValue = await handleUpdateCheckout({ toUpdate, checkout });

      setState("READY");
      if (possibleErrorValue) {
        return possibleErrorValue;
      }
      return;
    },
    [setState, handleCreateCheckout, setLoading, handleUpdateCheckout],
  );

  const handleUpdate = useCallback(
    async (stateUpdate: Record<string, number>): Promise<{ code: CHECKOUT_ERROR; message: string } | void> => {
      const value = await getStore(stateUpdate);
      if (value) {
        return {
          code: value,
          message: checkoutErrorCodeMap[value as keyof typeof checkoutErrorCodeMap] ?? checkoutErrorCodeMap.UNKNOWN,
        };
      }
    },
    [getStore],
  );

  return useMemo(
    () => ({
      update: handleUpdate,
    }),
    [handleUpdate],
  );
};
