/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloProvider } from "@apollo/client";
import * as React from "react";

import { useApollo } from "./use-apollo";
import { APOLLO_STATE_PROP_NAME } from "./use-apollo";

import type { APOLLO_STATE_TYPE } from "./use-apollo";

type Props<T> = T & { [APOLLO_STATE_PROP_NAME]?: APOLLO_STATE_TYPE };

type Fn<T> = (() => JSX.Element) | ((props: Omit<T, typeof APOLLO_STATE_PROP_NAME>) => JSX.Element);
const isServer = typeof window === "undefined";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let windowApolloState = !isServer ? (window as any)["__NEXT_DATA__"][APOLLO_STATE_PROP_NAME] : "{}";
try {
  windowApolloState = JSON.parse(windowApolloState);
} catch (e) {
  windowApolloState = {};
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const withSaleor = <T extends Record<string, string>>(Component: Fn<T>) => {
  const fn = (props: Props<T>): JSX.Element => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const client = useApollo(windowApolloState);
    return (
      <ApolloProvider client={client}>
        <Component {...props} />
      </ApolloProvider>
    );
  };
  fn.getInitialProps = (ctx: any): any => {
    if ((Component as any).getInitialProps) {
      return { ...(Component as any).getInitialProps(ctx) };
    }
    return {};
  };
  return fn;
};

export const SaleorProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const client = useApollo(windowApolloState);
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
