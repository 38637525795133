import styled from "styled-components";

import { EyebrowLarge } from "@byko/component-typography";
import { gridTheme } from "@byko/lib-styles";

export const ResultGrid = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
`;

export const SortingContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 29px;
  ${EyebrowLarge} {
    width: 100%;
  }
  @media screen and (min-width: ${gridTheme.breakpoints.sm}px) {
    min-height: 56px;
  }
`;

export const ProductCardGrid = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr);

  .product-card {
    width: unset;
    min-width: 240px;
  }
  @media (max-width: ${gridTheme.container.maxWidth.lg}px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: ${gridTheme.container.maxWidth.m}px) {
    grid-template-columns: repeat(3, 1fr);
    .product-card {
      .eye-button {
        display: none;
      }
    }
  }
  @media (max-width: ${gridTheme.container.maxWidth.sm}px) {
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 580px) {
    gap: 10px;
    grid-template-columns: repeat(1, 1fr);
    .product-card {
      max-width: unset;
    }
  }
`;
