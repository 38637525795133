import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";

import { theme } from "@byko/lib-styles";

import { Placeholder } from "./input-sleave/input-sleave-styles";

import type { ErrorProps } from "./input-sleave/input-sleave-interface";

export const InputCss = css`
  box-sizing: border-box;
  background-color: ${theme.palette.white.main};
  caret-color: ${theme.palette.yellow.main};
  font-size: 16px;
  &:hover {
    box-shadow: ${theme.palette.boxShadow.input};
  }
  &:first-letter {
    text-transform: capitalize;
  }
  &:focus {
    border: none;
  }
  &:disabled {
    background-color: ${theme.palette.gray[10]};
    color: ${theme.palette.gray[50]};
    opacity: 0.5;
    &:hover {
      box-shadow: none;
    }
  }
`;

export const InputField = styled.input<ErrorProps>`
  width: 100%;
  height: 56px;
  padding: 14px 24px 0px 24px;
  border: 1px solid ${ifProp({ error: true }, `${theme.palette.triggers.warning.main}`, (p) => p.borderColor)};
  color: ${ifProp({ error: true }, `${theme.palette.triggers.warning.main}`, `${theme.palette.blue.main}`)};
  letter-spacing: 0.5px;
  ${InputCss}
`;

export const TextAreaField = styled.textarea<ErrorProps>`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 150px;
  padding: 24px;
  border: 1px solid ${ifProp({ error: true }, `${theme.palette.triggers.warning.main}`, (p) => p.borderColor)};
  color: ${ifProp({ error: true }, `${theme.palette.triggers.warning.main}`, `${theme.palette.blue.main}`)};
  resize: none;
  &:focus {
    outline: none;
    & ~ ${Placeholder} {
      color: ${theme.palette.gray[40]};
      font-weight: normal;
      transform: scale(0.75) translateY(-18px);
    }
  }

  &:not(:placeholder-shown) {
    & ~ ${Placeholder} {
      color: ${theme.palette.gray[40]};
      font-weight: normal;
      transform: scale(0.75) translateY(-18px);
    }
  }

  &::placeholder {
    opacity: 0;
  }

  ${InputCss}
`;
