import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const SettingsToolsFilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1144_7143)">
        <path
          // eslint-disable-next-line max-len
          d="M45.989 35.373L32.167 23.437L25 30.389L37.373 43.989C37.4397 44.0657 37.5107 44.1407 37.586 44.214C38.1524 44.7805 38.8249 45.2299 39.565 45.5364C40.3051 45.843 41.0984 46.0008 41.8995 46.0008C42.7006 46.0008 43.4939 45.843 44.234 45.5364C44.9741 45.2299 45.6466 44.7805 46.213 44.214C46.286 44.141 46.357 44.066 46.426 43.99C46.9632 43.3956 47.3781 42.7011 47.6469 41.9463C47.9157 41.1915 48.0331 40.3912 47.9925 39.591C47.952 38.7908 47.7541 38.0064 47.4104 37.2827C47.0666 36.559 46.5836 35.91 45.989 35.373Z"
          fill="#FFD330"
        />
        <path
          // eslint-disable-next-line max-len
          d="M10.414 14H13L21.847 22.847L25 20L16 11V8.414C15.9999 8.1488 15.8945 7.89449 15.707 7.707L10 2L4 8L9.707 13.707C9.89449 13.8945 10.1488 13.9999 10.414 14Z"
          fill="#AEAEAE"
        />
        <path
          // eslint-disable-next-line max-len
          d="M37.6289 24.383C40.2996 24.1618 42.8052 23.0004 44.7 21.1054C46.5948 19.2104 47.756 16.7047 47.9769 14.034C48.0685 12.5863 47.9044 11.1337 47.4919 9.74298L41.4799 15.754L34.2509 8.52498L40.2619 2.51298C38.8711 2.10321 37.4189 1.94174 35.9719 2.03598C33.3011 2.25669 30.7951 3.41776 28.8999 5.31259C27.0047 7.20743 25.8432 9.71316 25.6219 12.384C25.5278 13.8327 25.69 15.2866 26.1009 16.679L5.04594 35.688C4.42228 36.2519 4.04812 37.0404 4.00574 37.8802C3.96336 38.7199 4.25623 39.5421 4.81994 40.166C4.85594 40.206 4.89194 40.244 4.92994 40.281L9.72294 45.075C10.3185 45.6684 11.1254 46.0009 11.9661 45.9994C12.8068 45.9979 13.6125 45.6625 14.2059 45.067C14.2429 45.031 14.2779 44.993 14.3129 44.955L33.3329 23.9C34.7254 24.3122 36.1797 24.4758 37.6289 24.383Z"
          fill="#C6C6C6"
        />
        <path
          // eslint-disable-next-line max-len
          d="M40.9999 40C40.7347 40 40.4804 39.8946 40.2929 39.707L33.2929 32.707C33.1108 32.5184 33.01 32.2658 33.0122 32.0036C33.0145 31.7414 33.1197 31.4906 33.3051 31.3052C33.4905 31.1198 33.7413 31.0146 34.0035 31.0124C34.2657 31.0101 34.5183 31.1109 34.7069 31.293L41.7069 38.293C41.8467 38.4329 41.9419 38.6111 41.9805 38.805C42.0191 38.999 41.9993 39.2 41.9236 39.3827C41.8479 39.5654 41.7198 39.7216 41.5554 39.8314C41.391 39.9413 41.1977 40 40.9999 40Z"
          fill="#FFD330"
        />
      </g>
      <defs>
        <clipPath id="clip0_1144_7143">
          <rect fill="white" height="48" width="48" />
        </clipPath>
      </defs>
    </svg>
  );
};
