import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const LikeThumbsUpIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M8 22H18.229C18.6568 22.0001 19.0734 21.8622 19.4175 21.6067C19.7616 21.3512 20.0152 20.9915 20.141 20.5803L22.91 11.602C23.002 11.3014 23.0225 10.9833 22.97 10.6732C22.9175 10.3632 22.7934 10.0698 22.6076 9.81678C22.4218 9.56372 22.1796 9.35799 21.9004 9.21614C21.6212 9.07428 21.3128 9.00025 21 9H14V5C14 2.61412 12.749 1.39478 11.236 1.02679C11.0884 0.991934 10.9348 0.99108 10.7868 1.02429C10.6389 1.05751 10.5002 1.12392 10.3814 1.21859C10.2625 1.31325 10.1665 1.4337 10.1004 1.57094C10.0344 1.70817 10.0001 1.85865 10 2.0111L9 7L6 12"
        stroke={iconColor}
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path d="M6 10H1V22H6V10Z" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
    </svg>
  );
};

export default LikeThumbsUpIcons;
