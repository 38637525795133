/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const ClockWhiteFilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 32 32" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30Z"
        fill="#00416A"
      />
      <path
        d="M16 31.3333C7.54494 31.3333 0.666687 24.4547 0.666687 16C0.666687 7.54521 7.54494 0.666626 16 0.666626C24.4551 0.666626 31.3334 7.54521 31.3334 16C31.3334 24.4547 24.4551 31.3333 16 31.3333ZM16 3.33329C9.01565 3.33329 3.33335 9.01559 3.33335 16C3.33335 22.9843 9.01565 28.6666 16 28.6666C22.9844 28.6666 28.6667 22.9843 28.6667 16C28.6667 9.01559 22.9844 3.33329 16 3.33329Z"
        fill="white"
      />
      <path
        d="M16 16.6666C15.6316 16.6666 15.3334 16.3681 15.3334 16V7.33329C15.3334 6.96513 15.6316 6.66663 16 6.66663C16.3685 6.66663 16.6667 6.96513 16.6667 7.33329V16C16.6667 16.3681 16.3685 16.6666 16 16.6666Z"
        fill="white"
      />
      <path
        d="M24 16.6666H16C15.6316 16.6666 15.3334 16.3681 15.3334 15.9999C15.3334 15.6318 15.6316 15.3333 16 15.3333H24C24.3685 15.3333 24.6667 15.6318 24.6667 15.9999C24.6667 16.3681 24.3685 16.6666 24 16.6666Z"
        fill="white"
      />
      <path
        d="M9.99939 24C9.85095 24 9.70186 23.9505 9.57816 23.8493C9.29301 23.6162 9.25069 23.1963 9.48376 22.9111L15.4838 15.5778C15.7175 15.2933 16.1368 15.2513 16.4219 15.484C16.7071 15.7171 16.7494 16.137 16.5163 16.4222L10.5163 23.7555C10.3842 23.9167 10.1927 24 9.99939 24Z"
        fill="#F85E00"
      />
      <path
        d="M16 18C17.1046 18 18 17.1046 18 16C18 14.8954 17.1046 14 16 14C14.8954 14 14 14.8954 14 16C14 17.1046 14.8954 18 16 18Z"
        fill="white"
      />
    </svg>
  );
};
