import { useCallback, useMemo, useState } from "react";

import { Sort } from "../interfaces";

import type { SearchResult } from "../interfaces";

export const useSort = (): SearchResult => {
  const [active, setActive] = useState<number>(0);
  const { values, activeItem } = useMemo(() => {
    const itemValues = Sort.map((value) => {
      return {
        ...value,
        active: value.id === active,
      };
    });
    const activeItemValue = itemValues.find((itemValue) => itemValue.id === active);
    return {
      values: itemValues,
      activeItem: activeItemValue,
    };
  }, [active]);

  const handleClick = useCallback((id: number) => {
    setActive(id);
  }, []);
  return {
    active,
    activeItem,
    handleClick,
    values,
  };
};
