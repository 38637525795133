/* eslint-disable @typescript-eslint/no-explicit-any */

import { getFrontpage } from "./use-frontpage";

import type { AppRouter, Maybe } from "@byko/lib-api-dato-ssr";
import type { TRPCClient } from "@trpc/client";
import type { NextPageContext } from "next/types";
import type { QueryClient } from "react-query";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const withDatoFrontpage = (component: any, forPro: Maybe<boolean>) => {
  const oldFn = component.getInitialProps ?? null;
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  component.getInitialProps = async (
    context: NextPageContext & { queryClient?: QueryClient; trpcClient: TRPCClient<AppRouter> },
  ) => {
    const value = oldFn ? await oldFn(context) : {};
    const { res } = context;
    try {
      const data = await getFrontpage("/api/cms", undefined, forPro, context)();
      if ("error" in data) {
        const { notFound, internalError } = data.error;
        if (res) {
          res.statusCode = notFound ? 404 : 500;
        }
        return {
          ...value,
          notFound,
          internalError,
        };
      }
      return {
        ...value,
        data,
        internalError: false,
        notFound: false,
      };
    } catch (_e) {
      console.error(_e);
      return {
        ...value,
        internalError: true,
        notFound: false,
      };
    }
  };
  return component;
};
