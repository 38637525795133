import styled from "styled-components";
import { ifProp } from "styled-tools";

import { gridTheme, theme } from "@byko/lib-styles";

import type { ErrorProps, StretchProps } from "./input-sleave-interface";

export const InputLabel = styled.label`
  min-height: 19px;
  margin-bottom: 16px;
  color: ${theme.palette.blue.dark};
`;

export const PrefixIcon = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 24px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  transition: transform 0.15s ease-out;
`;

export const FocusLine = styled.div<ErrorProps>`
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  width: 0;
  height: 2px;
  margin: 0 auto;
  background-color: ${ifProp({ error: true }, `${theme.palette.triggers.warning.main}`, `${theme.palette.blue.main}`)};
  transition: width 0.15s ease-out;
`;

export const Placeholder = styled.span<ErrorProps>`
  position: absolute;
  top: 20px;
  left: 24px;
  color: ${ifProp({ error: true }, `${theme.palette.triggers.warning.main}`, `${theme.palette.gray[60]}`)};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  pointer-events: none;
  transform-origin: 0 50%;
  transition: transform 0.15s ease-out, color 0.15s;
`;

export const InputContainer = styled.div<ErrorProps>`
  position: relative;
  width: 100%;
  max-width: ${ifProp({ stretch: true }, "100%", "320px")};
  height: 56px;
  .byko-icon {
    stroke: ${theme.palette.gray[60]};
    transition: transform 0.15s ease-out, fill 0.15s;
  }
  input {
    &:focus {
      outline: none;
      & ~ ${PrefixIcon} {
        .byko-icon {
          stroke: ${ifProp({ error: true }, `${theme.palette.triggers.warning.main}`, `${theme.palette.blue.main}`)};
          transition: transform 0.15s ease-out, fill 0.15s;
        }
      }
      & ~ ${Placeholder} {
        color: ${theme.palette.gray[40]};
        font-weight: normal;
        transform: scale(0.75) translateY(-18px);
      }

      & ~ ${FocusLine} {
        width: calc(100% - 2px);
      }
    }

    &:not(:placeholder-shown) {
      & ~ ${Placeholder} {
        color: ${theme.palette.gray[40]};
        font-weight: normal;
        transform: scale(0.75) translateY(-18px);
      }
    }

    &::placeholder {
      opacity: 0;
    }
  }
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    max-width: ${ifProp({ stretch: true }, "100%", "260px")};
  }
`;

export const InputWrapper = styled.div`
  padding-bottom: 8px;
`;

export const InputOuterWrapper = styled.div<StretchProps>`
  display: flex;
  width: 100%;
  max-width: ${ifProp({ stretch: true }, "100%", "320px")};
  flex-direction: column;
`;

export const ErrorText = styled.p<ErrorProps>`
  position: absolute;
  padding-left: 24px;
  margin-top: ${ifProp({ errorTextAboveInput: true }, "-30px", "64px")};
  margin-bottom: 20px;
  color: ${theme.palette.triggers.warning.main};
  font-size: 14px;
  line-height: 170%;
  text-transform: uppercase;
`;
