import React, { useCallback, useEffect } from "react";

import { useMegamenu } from "@byko/hooks-megamenu";

import { MegamenuMobile } from "./megamenu-mobile";
import { DesktopContainer, MainContainer, MobileContainer } from "./styles";

import type { MegamenuContainerProps } from "./interface";
import { Megamenu } from "./megamenu";

export const MegamenuContainer = ({ startOpen, debug }: MegamenuContainerProps): JSX.Element => {
  const { isOpen, menuContent, setIsOpen } = useMegamenu();

  useEffect(() => {
    setIsOpen(startOpen ?? false);
  }, [setIsOpen, startOpen]);

  const toggleOpen = useCallback(() => {
    setIsOpen((current) => {
      return !current;
    });
  }, [setIsOpen]);

  return (
    <>
      {Boolean(debug) === true && (
        <button type="button" onClick={toggleOpen}>
          open menu
        </button>
      )}
      <MainContainer>
        <MobileContainer>
          <MegamenuMobile configuration={menuContent} open={isOpen} toggleOpen={toggleOpen} />
        </MobileContainer>
        <DesktopContainer>
          <Megamenu configuration={menuContent} open={isOpen} toggleOpen={toggleOpen} />
        </DesktopContainer>
      </MainContainer>
    </>
  );
};
