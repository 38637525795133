/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const GlassesIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg
      className="byko-icon"
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 16H14" stroke={iconColor} strokeMiterlimit="10" strokeWidth="2" />
      <path
        d="M8 2L5.88 2.707C5.60422 2.79879 5.35177 2.94958 5.14021 3.14888C4.92865 3.34818 4.76307 3.59119 4.655 3.861L1.572 11.57C1.19395 12.5149 0.99981 13.5233 1 14.541V17C1 18.0609 1.42143 19.0783 2.17157 19.8284C2.92172 20.5786 3.93913 21 5 21H6C7.06087 21 8.07828 20.5786 8.82843 19.8284C9.57857 19.0783 10 18.0609 10 17V15C10 14.4696 9.78929 13.9609 9.41421 13.5858C9.03914 13.2107 8.53043 13 8 13H2"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M16 2L18.12 2.707C18.3958 2.79879 18.6482 2.94958 18.8598 3.14888C19.0714 3.34818 19.2369 3.59119 19.345 3.861L22.428 11.57C22.806 12.5149 23.0002 13.5233 23 14.541V17C23 18.0609 22.5786 19.0783 21.8284 19.8284C21.0783 20.5786 20.0609 21 19 21H18C16.9391 21 15.9217 20.5786 15.1716 19.8284C14.4214 19.0783 14 18.0609 14 17V15C14 14.4696 14.2107 13.9609 14.5858 13.5858C14.9609 13.2107 15.4696 13 16 13H22"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
};

export default GlassesIcons;
