import gql from "graphql-tag";

export const JobTitleParts = gql`
  fragment JobTitleParts on JobTitleRecord {
    __typename
    id
    name
  }
`;

export const DepartmentParts = gql`
  fragment DepartmentParts on DepartmentRecord {
    __typename
    id
    name
  }
`;

export const EmployeeParts = gql`
  fragment EmployeeParts on EmployeeRecord {
    __typename
    id
    name
    email
    mobilePhone
    workPhone
    jobTitle {
      ...JobTitleParts
    }
    department {
      ...DepartmentParts
    }
    subDepartment {
      ...SubDepartmentParts
    }
  }
`;

export const SubDepartmentParts = gql`
  fragment SubDepartmentParts on SubDepartmentRecord {
    __typename
    id
    name
  }
`;

export const ImageFileParts = gql`
  fragment ImageFileParts on FileField {
    __typename
    id
    jpeg: url(imgixParams: { fm: jpg, q: 100 })
    alt
    blurhash
    title
    width
    height
    focalPoint {
      x
      y
    }
  }
`;

export const ImageBlockParts = gql`
  fragment ImageBlockParts on ImageblockRecord {
    __typename
    id
    content {
      ...ImageFileParts
    }
  }
  ${ImageFileParts}
`;

export const ImageBlockPartsWithTitle = gql`
  fragment ImageBlockPartsWithTitle on ImageblockwithtitleRecord {
    __typename
    id
    content {
      ...ImageFileParts
    }
  }
  ${ImageFileParts}
`;

export const ActionButtonParts = gql`
  fragment ActionButtonParts on ImageactionbuttonRecord {
    __typename
    id
    url
    content {
      ...ImageFileParts
    }
  }
  ${ImageFileParts}
`;

export const TextblockModelContentFieldParts = gql`
  fragment TextblockModelContentFieldParts on TextblockModelContentField {
    blocks
    links
    value
  }
`;

export const ListitemblockModelTextFieldParts = gql`
  fragment ListitemblockModelTextFieldParts on ListitemblockModelTextField {
    blocks
    links
    value
  }
`;

export const ActionButtonRecordParts = gql`
  fragment ActionButtonRecordParts on ActionButtonRecord {
    __typename
    id
    link
    text
  }
`;

export const TextBlockParts = gql`
  fragment TextBlockParts on TextblockRecord {
    icon
    title
    introtext
    headingunderlinecolor
    content {
      ...TextblockModelContentFieldParts
    }
    actionbutton {
      ...ActionButtonRecordParts
    }
  }
  ${TextblockModelContentFieldParts}
  ${ActionButtonRecordParts}
`;

export const DefaultHeaderParts = gql`
  fragment DefaultHeaderParts on TopbannerdefaultblockRecord {
    __typename
    id
    mirrorxaxis
    mirroryaxis
    images {
      ...ImageFileParts
    }
    content {
      ...TextBlockParts
    }
  }
  ${ImageFileParts}
  ${TextBlockParts}
`;

export const FrontPageHeaderParts = gql`
  fragment FrontPageHeaderParts on TopBannerFrontPageRecord {
    __typename
    id
    title
    image {
      ...ImageFileParts
    }
  }
  ${ImageFileParts}
`;

export const SeoFieldParts = gql`
  fragment SeoFieldParts on SeoField {
    __typename
    title
    image {
      ...ImageFileParts
    }
    description
  }
  ${ImageFileParts}
`;

export const AuthorblockRecorddParts = gql`
  fragment AuthorblockRecorddParts on AuthorblockRecord {
    __typename
    id
    author
    date
  }
`;

export const IconbBlockRecordParts = gql`
  fragment IconbBlockRecordParts on IconblockRecord {
    __typename
    id
    icon
  }
`;

export const TopbannerdefaultblockRecordParts = gql`
  fragment TopbannerdefaultblockRecordParts on TopbannerdefaultblockRecord {
    __typename
    id
    id
    images {
      ...ImageFileParts
    }
    content {
      ...TextBlockParts
    }
    mirrorxaxis
    mirroryaxis
  }
  ${ImageFileParts}
  ${TextBlockParts}
`;

export const ListitemblockRecordParts = gql`
  fragment ListitemblockRecordParts on ListitemblockRecord {
    __typename
    id
    icon
    mirrorx
    text {
      ...ListitemblockModelTextFieldParts
    }
    title
  }
  ${ListitemblockModelTextFieldParts}
`;

export const ListcontainerblockRecordParts = gql`
  fragment ListcontainerblockRecordParts on ListcontainerblockRecord {
    __typename
    id
    content {
      ...ListitemblockRecordParts
    }
  }
  ${ListitemblockRecordParts}
`;

export const BulletContainerblockParts = gql`
  fragment BulletContainerblockParts on BulletcontainerblockRecord {
    id
    __typename

    intro {
      ...TextBlockParts
    }
    title
    content {
      text
      id
    }
  }
  ${TextBlockParts}
`;

export const Contentsection1RecordParts = gql`
  fragment Contentsection1RecordParts on Contentsection1Record {
    __typename
    id
    image {
      ...ImageBlockParts
    }
    mirrory
    text {
      ...TextBlockParts
    }
  }
  ${ImageBlockParts}
  ${TextBlockParts}
`;

export const Contentsection2RecordParts = gql`
  fragment Contentsection2RecordParts on Contentsection2Record {
    __typename
    id
    images {
      ...ImageBlockParts
    }
    primaryimage {
      ...ImageBlockParts
    }
    text {
      ...TextBlockParts
    }
  }
  ${ImageBlockParts}
  ${TextBlockParts}
  ${ImageBlockParts}
`;

export const Contentsection3RecordParts = gql`
  fragment Contentsection3RecordParts on Contentsection3Record {
    __typename
    id
    mirrory
    images {
      ...ImageBlockParts
    }

    text {
      ...TextBlockParts
    }
  }
  ${ImageBlockParts}
  ${TextBlockParts}
`;

export const Contentsection4RecordParts = gql`
  fragment Contentsection4RecordParts on Contentsection4Record {
    __typename
    id
    mirrory
    images {
      ... on ImageactionbuttonRecord {
        ...ActionButtonParts
      }
      ... on ImageblockwithtitleRecord {
        ...ImageBlockPartsWithTitle
      }
    }
    primaryimages {
      ...ImageBlockParts
    }
    bullettext {
      ...BulletContainerblockParts
    }
  }
  ${ActionButtonParts}
  ${ImageBlockPartsWithTitle}
  ${ImageBlockParts}
  ${BulletContainerblockParts}
`;

export const Contentsection5RecordParts = gql`
  fragment Contentsection5RecordParts on Contentsection5Record {
    __typename
    id
    mirrory
    primaryimage {
      ...ImageBlockParts
    }
    content {
      ...ListcontainerblockRecordParts
    }
    text {
      ...TextBlockParts
    }
  }
  ${ImageBlockParts}
  ${ListcontainerblockRecordParts}
  ${TextBlockParts}
`;

export const Contentsection6RecordParts = gql`
  fragment Contentsection6RecordParts on Contentsection6Record {
    __typename
    id
    mirrorx
    image {
      ...ImageBlockParts
    }
    text {
      ...TextBlockParts
    }
  }
  ${ImageBlockParts}
  ${TextBlockParts}
`;

export const Contentsection7RecordParts = gql`
  fragment Contentsection7RecordParts on Contentsection7Record {
    __typename
    id
    primaryimages {
      ...ImageBlockParts
    }
    images {
      ...ActionButtonParts
    }
    text {
      ...TextBlockParts
    }
    mirrory
  }
  ${ImageBlockParts}
  ${ActionButtonParts}
  ${TextBlockParts}
`;

export const Contentsection8RecordParts = gql`
  fragment Contentsection8RecordParts on Contentsection8Record {
    __typename
    id
    mirrory
    images {
      ...ImageBlockPartsWithTitle
    }
    primaryimages {
      ...ImageBlockParts
    }
    text {
      ...TextBlockParts
    }
  }
  ${ImageBlockPartsWithTitle}
  ${ImageBlockParts}
  ${TextBlockParts}
`;

export const Contentsection9RecordParts = gql`
  fragment Contentsection9RecordParts on Contentsection9Record {
    __typename
    id
    primaryimages {
      ...ImageBlockParts
    }
    images {
      ...ActionButtonParts
    }
    text {
      ...TextBlockParts
    }
    mirrory
  }
  ${ImageBlockParts}
  ${ActionButtonParts}
  ${TextBlockParts}
`;

export const ImageTilesBlockRecordParts = gql`
  fragment ImageTilesBlockRecordParts on ImageTilesBlockRecord {
    __typename
    id
    image {
      ...ImageBlockParts
    }
  }
  ${ImageBlockParts}
`;

export const PhotoGalleryBlockRecordParts = gql`
  fragment PhotoGalleryBlockRecordParts on PhotoGalleryBlockRecord {
    __typename
    id
    image {
      ...ImageBlockParts
    }
  }
  ${ImageBlockParts}
`;

export const ContentsectionFilesParts = gql`
  fragment ContentsectionFilesParts on FiledownloadsectionRecord {
    __typename
    id
    fileDownloadTab {
      __typename
      id
      title
      icon {
        ...IconbBlockRecordParts
      }
      files {
        __typename
        id
        filename
        mimeType
        title
        url
      }
    }
  }
  ${IconbBlockRecordParts}
`;

export const ContentsectionProductSliderParts = gql`
  fragment ContentsectionProductSliderParts on ProductslidersectionRecord {
    __typename
    id
    paragraph {
      blocks
      links
      value
    }
    heading
    headingUnderlineColor
    products {
      id
      saleorProducts {
        saleorProduct
        id
      }
    }
  }
`;

export const ProductParts = gql`
  fragment ProductParts on ProductPageRecord {
    __typename
    id
    slug
    content {
      ... on Contentsection1Record {
        ...Contentsection1RecordParts
      }
      ... on Contentsection2Record {
        ...Contentsection2RecordParts
      }
      ... on Contentsection3Record {
        ...Contentsection3RecordParts
      }
      ... on Contentsection4Record {
        ...Contentsection4RecordParts
      }
      ... on Contentsection5Record {
        ...Contentsection5RecordParts
      }
      ... on Contentsection6Record {
        ...Contentsection6RecordParts
      }
      ... on Contentsection7Record {
        ...Contentsection7RecordParts
      }
      ... on Contentsection8Record {
        ...Contentsection8RecordParts
      }
      ... on Contentsection9Record {
        ...Contentsection9RecordParts
      }
      ... on FiledownloadsectionRecord {
        ...ContentsectionFilesParts
      }
      ... on ProductslidersectionRecord {
        ...ContentsectionProductSliderParts
      }
      ... on ImageTilesBlockRecord {
        ...ImageTilesBlockRecordParts
      }
      ... on PhotoGalleryBlockRecord {
        ...PhotoGalleryBlockRecordParts
      }
      ... on SimplybookmeBlockRecord {
        __typename
        id
      }
    }
  }
  ${Contentsection1RecordParts}
  ${Contentsection2RecordParts}
  ${Contentsection3RecordParts}
  ${Contentsection4RecordParts}
  ${Contentsection5RecordParts}
  ${Contentsection6RecordParts}
  ${Contentsection7RecordParts}
  ${Contentsection8RecordParts}
  ${Contentsection9RecordParts}
  ${ContentsectionFilesParts}
  ${ContentsectionProductSliderParts}
  ${ImageTilesBlockRecordParts}
  ${PhotoGalleryBlockRecordParts}
`;

export const DatoPageParts = gql`
  fragment DatoPageParts on PageRecord {
    __typename
    id
    parentpage
    parentPage {
      id
    }
    title
    slug
    top {
      ... on TopbannerdefaultblockRecord {
        ...TopbannerdefaultblockRecordParts
      }
    }
    tags {
      ...IconbBlockRecordParts
    }
    seo {
      ...SeoFieldParts
    }
    author {
      ...AuthorblockRecorddParts
    }
    content {
      ... on Contentsection1Record {
        ...Contentsection1RecordParts
      }
      ... on Contentsection2Record {
        ...Contentsection2RecordParts
      }
      ... on Contentsection3Record {
        ...Contentsection3RecordParts
      }
      ... on Contentsection4Record {
        ...Contentsection4RecordParts
      }
      ... on Contentsection5Record {
        ...Contentsection5RecordParts
      }
      ... on Contentsection6Record {
        ...Contentsection6RecordParts
      }
      ... on Contentsection7Record {
        ...Contentsection7RecordParts
      }
      ... on Contentsection8Record {
        ...Contentsection8RecordParts
      }
      ... on Contentsection9Record {
        ...Contentsection9RecordParts
      }
      ... on FiledownloadsectionRecord {
        ...ContentsectionFilesParts
      }
      ... on ProductslidersectionRecord {
        ...ContentsectionProductSliderParts
      }
      ... on ImageTilesBlockRecord {
        ...ImageTilesBlockRecordParts
      }
      ... on PhotoGalleryBlockRecord {
        ...PhotoGalleryBlockRecordParts
      }
      ... on SimplybookmeBlockRecord {
        __typename
        id
      }
    }
  }
  ${TopbannerdefaultblockRecordParts}
  ${IconbBlockRecordParts}
  ${SeoFieldParts}
  ${AuthorblockRecorddParts}
  ${Contentsection1RecordParts}
  ${Contentsection2RecordParts}
  ${Contentsection3RecordParts}
  ${Contentsection4RecordParts}
  ${Contentsection5RecordParts}
  ${Contentsection6RecordParts}
  ${Contentsection7RecordParts}
  ${Contentsection8RecordParts}
  ${Contentsection9RecordParts}
  ${ContentsectionFilesParts}
  ${ContentsectionProductSliderParts}
  ${ImageTilesBlockRecordParts}
  ${PhotoGalleryBlockRecordParts}
`;

export const DatoFrontpageParts = gql`
  fragment DatoFrontpageParts on FrontpageRecord {
    __typename
    id
    top {
      ...FrontPageHeaderParts
    }
    seo {
      ...SeoFieldParts
    }
    content {
      ... on Contentsection1Record {
        ...Contentsection1RecordParts
      }
      ... on Contentsection2Record {
        ...Contentsection2RecordParts
      }
      ... on Contentsection3Record {
        ...Contentsection3RecordParts
      }
      ... on Contentsection4Record {
        ...Contentsection4RecordParts
      }
      ... on Contentsection5Record {
        ...Contentsection5RecordParts
      }
      ... on Contentsection6Record {
        ...Contentsection6RecordParts
      }
      ... on Contentsection7Record {
        ...Contentsection7RecordParts
      }
      ... on Contentsection8Record {
        ...Contentsection8RecordParts
      }
      ... on Contentsection9Record {
        ...Contentsection9RecordParts
      }
      ... on FiledownloadsectionRecord {
        ...ContentsectionFilesParts
      }
      ... on ProductslidersectionRecord {
        ...ContentsectionProductSliderParts
      }
      ... on ImageTilesBlockRecord {
        ...ImageTilesBlockRecordParts
      }
      ... on PhotoGalleryBlockRecord {
        ...PhotoGalleryBlockRecordParts
      }
      ... on SimplybookmeBlockRecord {
        __typename
        id
      }
    }
    published
  }
  ${FrontPageHeaderParts}
  ${SeoFieldParts}
  ${Contentsection1RecordParts}
  ${Contentsection2RecordParts}
  ${Contentsection3RecordParts}
  ${Contentsection4RecordParts}
  ${Contentsection5RecordParts}
  ${Contentsection6RecordParts}
  ${Contentsection7RecordParts}
  ${Contentsection8RecordParts}
  ${Contentsection9RecordParts}
  ${ContentsectionFilesParts}
  ${ContentsectionProductSliderParts}
  ${ImageTilesBlockRecordParts}
  ${PhotoGalleryBlockRecordParts}
`;

export const StringRecordFragment = gql`
  fragment StringRecordFragment on StringRecord {
    key
    string {
      alertType
      message
    }
  }
`;

export const OpeningHourFragment = gql`
  fragment OpeningHourFragment on OpeningHourRecord {
    __typename
    id
    location {
      id
      locationAddress
      locationName
      locationType
      openingHours {
        id
        hours {
          id
          dateName
          dateOpeningHours
        }
      }
    }
  }
`;
