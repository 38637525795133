import styled from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";

export const Background = styled.div`
  background-color: ${theme.palette.white.main};
`;

export const ProducSliderContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 40px;
  gap: 50px;
  @media screen and (max-width: ${gridTheme.container.maxWidth.sm}px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;
  }
`;

export const ProducSliderWrapper = styled.div`
  width: 70%;
  @media screen and (max-width: ${gridTheme.container.maxWidth.sm}px) {
    width: 100%;
  }
`;

export const TextBlockWrapper = styled.div`
  width: 30%;

  @media screen and (min-width: ${gridTheme.container.minWidth.md}px) and (max-width: ${gridTheme.container.maxWidth
      .md}px) {
    padding-right: 56px;
  }
  @media screen and (min-width: ${gridTheme.container.minWidth.lg}px) and (max-width: ${gridTheme.container.maxWidth
      .lg}px) {
    padding-right: 56px;
  }

  @media screen and (min-width: ${gridTheme.container.minWidth.xl}px) {
    padding-right: 56px;
  }
  @media screen and (max-width: ${gridTheme.container.maxWidth.sm}px) {
    width: 100%;
  }
`;

export const SectionContainer = styled.div`
  position: relative;
  display: flex;
  margin-top: 40px;
  gap: 70px;
  img {
    max-width: 800px;
    max-height: 840px;
  }
  @media screen and (max-width: ${gridTheme.container.maxWidth.sm}px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;
  }
  .text-block-variant-one {
    @media screen and (min-width: ${gridTheme.container.minWidth.md}px) and (max-width: ${gridTheme.container.maxWidth
        .md}px) {
      padding-left: 56px;
    }
    @media screen and (min-width: ${gridTheme.container.minWidth.lg}px) and (max-width: ${gridTheme.container.maxWidth
        .lg}px) {
      padding-left: 56px;
    }

    @media screen and (min-width: ${gridTheme.container.minWidth.xl}px) {
      padding-left: 56px;
    }
    @media screen and (max-width: ${gridTheme.container.maxWidth.sm}px) {
      width: 100%;
    }
  }
`;

export const BlockImageContainer = styled.div`
  display: flex;
  overflow: hidden;
  margin-top: -10rem;
  gap: 16px;
  overflow-x: auto;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media screen and (max-width: ${gridTheme.container.maxWidth.md}px) {
    display: none;
  }
`;

export const SelectionContainer = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  padding-bottom: 8px;
  padding-left: 16px;
  border-bottom: 1px solid ${theme.palette.gray[10]};
  gap: 60px;
  .select-container {
    z-index: 0;
    border: 1px solid ${theme.palette.gray[10]};
  }

  @media screen and (max-width: ${gridTheme.container.maxWidth.md}px) {
    display: flex;
  }
  @media screen and (max-width: ${gridTheme.container.maxWidth.sm}px) {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0px;
    gap: 8px;
  }
`;

export const EnvironmentContainer = styled.div`
  display: flex;
  margin-bottom: 64px;
  gap: 24px;
  @media screen and (max-width: ${gridTheme.container.maxWidth.md}px) {
    flex-direction: column;
  }
`;

export const ActiveEvironmentHeader = styled.p`
  margin-bottom: 24px;
  color: ${theme.palette.blue.main};
  font-size: 32px;
  @media screen and (max-width: ${gridTheme.container.maxWidth.md}px) {
    margin-bottom: 8px;
  }
`;

export const EnvironmentImageContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding-right: 32px;
  div {
    max-width: 300px;
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
  @media screen and (max-width: ${gridTheme.container.maxWidth.md}px) {
    display: none;
  }
`;

export const EnvironmentImageContainerMobile = styled.div`
  display: none;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-right: 32px;
  div {
    max-width: 300px;
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
  @media screen and (max-width: ${gridTheme.container.maxWidth.md}px) {
    display: flex;
  }
`;

export const ActiveEnvironmentContent = styled.div`
  max-width: 560px;
`;
