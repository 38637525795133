/* eslint-disable
  react/jsx-sort-props,
  react-perf/jsx-no-new-function-as-prop,
  react-perf/jsx-no-new-array-as-prop,
  react/jsx-no-bind,
  @typescript-eslint/ban-ts-comment,
  react-perf/jsx-no-new-object-as-prop */

import { flatMap, fromPairs, keys, mapKeys, mapValues, pickBy } from "lodash";
import { useRouter } from "next/router";
import queryString from "query-string";
import React, { createContext, useCallback, useContext, useMemo } from "react";
import { ensureList } from "@byko/lib-utils";

import { useCommonContext } from "../context/common";

import { compileActiveFilters, compileFacets } from "./api";
import { DEFAULT_PAGE_SIZE, FACET_NAME_OVERRIDES } from "./conf";

import type { ActiveFilterProps } from "./api";
import type { ChoiceFacetType, CompiledFacets, FacetOrderValues, HierarchicalFacetType, RangeFacetType } from "./types";
import type { ProductList } from "@byko/lib-api-rest";
import type algoliasearchHelper from "algoliasearch-helper";
import type { ParsedUrlQuery } from "querystring";

interface ProductSearchContextProps {
  searchResults: algoliasearchHelper.SearchResults<ProductList> | undefined;
  facets: CompiledFacets;
  activeFilters: ActiveFilterProps[];
  queryParams: Record<string, (string | number)[]>;
  page: number;
  pageSize: number;
  updateChoiceFacet: (
    facet: ChoiceFacetType | HierarchicalFacetType,
    attr: string,
    values: Record<string, boolean>,
  ) => void;
  clearFilter: (item: ActiveFilterProps) => void;
  updateRangeFacetValues: (
    facet: RangeFacetType,
    attr: string,
    {
      min,
      max,
    }: {
      min?: number | null;
      max?: number | null;
    },
  ) => void;
  clearAllFilters: () => void;
  setSorting: (sort: string) => void;
  setPageSize: (pageSize: number | string | undefined | null) => void;
  facetOrder: string[] | undefined;
  facetOrderValues: FacetOrderValues | undefined;
}

export const SearchContext = createContext<Partial<ProductSearchContextProps>>({});

export interface ProductSearchProviderProps {
  query?: ParsedUrlQuery | undefined;
  results?: algoliasearchHelper.SearchResults<ProductList> | undefined;
  attrNameMap?: Record<string, string>;
  children: React.ReactNode;
  activeCategorySlug?: string | undefined;
}

export type FilterUpdate = Record<string, false | Record<string, boolean | number | string>>;

export function ProductSearchProvider({
  query,
  results,
  attrNameMap,
  children,
  activeCategorySlug,
}: ProductSearchProviderProps) {
  const router = useRouter();
  const { categories: allCategories } = useCommonContext();

  /* prettier-ignore */
  const queryParams: Record<string, string[]> = pickBy(
    mapValues(
      router?.query ?? query,
      (v) => flatMap(ensureList(v ?? ""), (i) => i.split(",")),
    ),
  );

  const facetNames: Record<string, string> = useMemo(
    () => ({
      ...FACET_NAME_OVERRIDES,
      ...mapKeys(attrNameMap ?? {}, (_, k) => `attributes.${k}`),
    }),
    [attrNameMap],
  );

  const facets = compileFacets(results, allCategories, queryParams, facetNames, activeCategorySlug);

  const facetOrder = results?.renderingContent?.facetOrdering?.facets?.order;
  const facetOrderValues = results?.renderingContent?.facetOrdering?.values;

  if (facets.categories?.options.length < 2) {
    // @ts-ignore
    delete facets.categories;
  }

  const activeFilters = compileActiveFilters(facets);

  const page = parseInt(queryParams["page"]?.[0] || "1", 10);
  const pageSize = parseInt(queryParams["pageSize"]?.[0] || `${DEFAULT_PAGE_SIZE}`, 10);

  const commitFilterQueryParams = useCallback(
    (filterParams: Record<string, string[]>) => {
      const nextQueryParams = {
        ...queryParams,
        ...filterParams,
      };
      delete nextQueryParams["page"];

      const q = pickBy(mapValues(nextQueryParams, (v) => v.join(",")));

      if (activeCategorySlug) {
        q["slug"] = activeCategorySlug;
      }
      router.replace({ query: q }, undefined, {
        shallow: true,
      });
    },
    [activeCategorySlug, queryParams, router],
  );

  const updateChoiceFacet = useCallback(
    (
      //
      facet: ChoiceFacetType | HierarchicalFacetType,
      attr: string,
      selected: Record<string | number, boolean>,
    ) => {
      const optionValues: Record<string, boolean> = {};

      // Populate with current values:
      facet.options.forEach((opt) => {
        optionValues[`${opt.value}`] = opt.active;
      });

      // Override with new values:
      Object.assign(
        optionValues,
        mapKeys(selected, (_, k) => `${k}`),
      );

      // Only select keys where value is true:
      commitFilterQueryParams({ [attr]: keys(pickBy(optionValues)) });
    },
    [commitFilterQueryParams],
  );

  const clearFilter = useCallback(
    (activeFilter: ActiveFilterProps) => {
      const optionValues: Record<string, boolean> = {};

      // Set the value we're looking for to false:
      activeFilters.map((filter) => {
        if (filter.attr === activeFilter.attr) {
          optionValues[filter.value] = activeFilter.value !== filter.value;
        }
      });

      // Only select keys where value is true:
      commitFilterQueryParams({ [activeFilter.attr]: keys(pickBy(optionValues)) });
    },
    [activeFilters, commitFilterQueryParams],
  );

  const updateRangeFacetValues = useCallback(
    (
      facet: RangeFacetType,
      attr: string,
      {
        min,
        max,
      }: {
        min?: number | null;
        max?: number | null;
      },
    ) => {
      const minV = min === null || (min ?? 0) <= facet.min ? "" : `${min === undefined ? facet.minValue : min}`;
      const maxV = max === null || facet.max <= (max ?? Infinity) ? "" : `${max === undefined ? facet.maxValue : max}`;

      commitFilterQueryParams({
        [attr]: minV || maxV ? [`${minV}...${maxV}`] : [],
      });
    },
    [commitFilterQueryParams],
  );

  const updateQueryParams = useCallback(
    (params: Record<string, string | null | undefined>) => {
      if (activeCategorySlug) {
        params["slug"] = activeCategorySlug;
      }

      const search = queryString.stringify(params, { arrayFormat: "comma" });

      router.replace({ search }, undefined, {
        shallow: true,
      });
    },
    [router, activeCategorySlug],
  );

  const setSorting = useCallback(
    (sort: string) => {
      updateQueryParams({ ...queryParams, sort });
    },
    [updateQueryParams, queryParams],
  );

  const setPageSize = useCallback(
    (v: number | string | undefined | null) => {
      delete queryParams["page"];
      updateQueryParams({ ...queryParams, pageSize: v ? `${v}` : null });
    },
    [updateQueryParams, queryParams],
  );

  const clearAllFilters = useCallback(() => {
    commitFilterQueryParams(fromPairs(activeFilters.map((f) => [f.attr, []])));
  }, [commitFilterQueryParams, activeFilters]);

  return (
    <SearchContext.Provider
      value={{
        searchResults: results,
        facets,
        activeFilters,
        queryParams,
        page,
        pageSize,
        updateChoiceFacet,
        updateRangeFacetValues,
        clearFilter,
        clearAllFilters,
        setSorting,
        setPageSize,
        facetOrder,
        facetOrderValues,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

export function useProductSearch() {
  return useContext(SearchContext) as ProductSearchContextProps;
}
