import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const CalculatorIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M22 1H3V23H22V1Z" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M18 5H7V10H18V5Z" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M7 14H8" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M12 14H13" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M7 18H8" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M12 18H13" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M17 14H18" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M17 18H18" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
    </svg>
  );
};

export default CalculatorIcons;
