import React, { useCallback } from "react";

import { EyebrowSmall } from "@byko/component-typography";
import { CancelLargeIcons } from "@byko/lib-icons";
import { theme } from "@byko/lib-styles";

import { ClearButton, TagWrapper } from "./styles";

import type { TagProps } from "./interface";

export const TagItem = ({ tag, clearTag }: TagProps): JSX.Element => {
  const handleClearTag = useCallback(() => {
    clearTag(tag);
  }, [clearTag, tag]);

  return (
    <TagWrapper>
      <EyebrowSmall lineHeight="100%" textColor={theme.palette.blue.dark}>
        {tag.label}
      </EyebrowSmall>
      <ClearButton type="button" onClick={handleClearTag}>
        <CancelLargeIcons iconColor={theme.palette.blue.dark} size={14} />
      </ClearButton>
    </TagWrapper>
  );
};
