/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const DrillIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg
      className="byko-icon"
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20 5H23" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path
        d="M16 9H3C2.46957 9 1.96086 8.78929 1.58579 8.41421C1.21071 8.03914 1 7.53043 1 7V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H16L20 2V8L16 9Z"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M14.283 18.755L11.138 18.305C10.8653 18.266 10.6037 18.171 10.3694 18.0261C10.1351 17.8812 9.93334 17.6895 9.77665 17.4629C9.61995 17.2364 9.51174 16.9799 9.45881 16.7096C9.40587 16.4393 9.40934 16.1609 9.469 15.892L11 9H5L3.207 17.071C3.14248 17.3613 3.01404 17.6336 2.83101 17.868C2.64799 18.1024 2.415 18.293 2.149 18.426L2.106 18.447C1.77369 18.6131 1.49419 18.8684 1.29884 19.1844C1.10349 19.5004 1.00001 19.8645 1 20.236V23H16V20.735C16 20.2536 15.8264 19.7883 15.511 19.4246C15.1956 19.0609 14.7596 18.8231 14.283 18.755V18.755Z"
        stroke={iconColor}
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path d="M16 1V9" stroke={iconColor} strokeMiterlimit="10" strokeWidth="2" />
      <path d="M5 5H7" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
    </svg>
  );
};

export default DrillIcons;
