import * as React from "react";
import { useCallback } from "react";

import { useMegamenu } from "@byko/hooks-megamenu";

import {
  BurgerContainer,
  BurgerCrossBottom,
  BurgerCrossTop,
  BurgerInner,
  BurgerLineLong,
  BurgerLineShort,
} from "./styles";

export const Burger = (): JSX.Element => {
  const { setIsOpen, isOpen } = useMegamenu();
  const toggleActie = useCallback(() => {
    setIsOpen((current) => {
      return !current;
    });
  }, [setIsOpen]);

  return (
    <BurgerContainer onClick={toggleActie}>
      <BurgerInner>
        <BurgerCrossTop hide={!isOpen} />
        <BurgerCrossBottom hide={!isOpen} />
        <BurgerLineLong hide={isOpen} />
        <BurgerLineLong hide={isOpen} rotateLine={true} />
        <BurgerLineShort hide={isOpen} />
      </BurgerInner>
    </BurgerContainer>
  );
};
