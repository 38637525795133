import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";

import { Button, IconButton } from "@byko/component-buttons";
import { H6, PLarge, PMedium } from "@byko/component-typography";
import { AttentionIcons, CancelLargeIcons, LongArrowSideIcons } from "@byko/lib-icons";
import { theme } from "@byko/lib-styles";
import { useWindowSize } from "@byko/lib-styles";

import {
  Actions,
  CloseContainer,
  IconContainer,
  InfoContainer,
  ModalBackdrop,
  ModalContainer,
  ViewContainer,
} from "./styles";

import type { ModalProps } from "@byko/types-modal";

export const Modal = ({
  cancelLabel,
  confirmLabel,
  cancelIcon = LongArrowSideIcons,
  confirmIcon = LongArrowSideIcons,
  modalBackgroundColor = theme.palette.blue.main,
  paragraph,
  info,
  title,
  confirmCallback,
  cancelCallback,
}: ModalProps): JSX.Element => {
  const modalRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = useCallback(() => {
    if (cancelCallback !== undefined) {
      cancelCallback();
    }
  }, [cancelCallback]);

  useOnClickOutside(modalRef, handleClickOutside);

  const { activeBreakPoint } = useWindowSize();
  const [active, setActive] = useState<boolean>(false);

  useEffect(() => {
    setActive(true);
  }, []);

  return (
    <ViewContainer active={active}>
      <ModalBackdrop backgroundColor={modalBackgroundColor} />
      <ModalContainer ref={modalRef} active={active}>
        <H6 bottom={16} textColor={theme.palette.blue.dark} top={40}>
          {title}
        </H6>
        {["xl", "lg", "md"].includes(activeBreakPoint) && (
          <>
            <PLarge textColor={theme.palette.blue.dark}>{paragraph}</PLarge>
            {info !== undefined && (
              <InfoContainer>
                <IconContainer>
                  <AttentionIcons iconColor={theme.palette.triggers.attention.main} size={24} />
                </IconContainer>
                <PLarge textColor={theme.palette.blue.dark}>{info}</PLarge>
              </InfoContainer>
            )}
          </>
        )}
        {["xs", "sm", "s"].includes(activeBreakPoint) && (
          <>
            <PMedium textColor={theme.palette.blue.dark}>{paragraph}</PMedium>
            {info !== undefined && (
              <InfoContainer>
                <IconContainer>
                  <AttentionIcons iconColor={theme.palette.triggers.attention.main} size={24} />
                </IconContainer>
                <PMedium textColor={theme.palette.blue.dark}>{info}</PMedium>
              </InfoContainer>
            )}
          </>
        )}

        <Actions>
          <Button
            buttonColor="blueButton"
            icon={confirmIcon}
            label={confirmLabel}
            small={["xs", "sm", "s"].includes(activeBreakPoint)}
            stretch={["xs", "sm", "s"].includes(activeBreakPoint)}
            onClick={confirmCallback}
          />
          <Button
            buttonColor="orangeButton"
            icon={cancelIcon}
            label={cancelLabel}
            small={["xs", "sm", "s"].includes(activeBreakPoint)}
            stretch={["xs", "sm", "s"].includes(activeBreakPoint)}
            onClick={cancelCallback}
          />
        </Actions>

        <CloseContainer>
          <IconButton
            ariaLabel={"loka glugga"}
            buttonColor="blueButton"
            icon={CancelLargeIcons}
            small={true}
            onClick={cancelCallback}
          />
        </CloseContainer>
      </ModalContainer>
    </ViewContainer>
  );
};
