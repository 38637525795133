import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const PaintFilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M7 22C7 26.418 10.582 30 15 30H20V43C20 45.209 21.791 47 24 47C26.209 47 28 45.209 28 43V30H33C37.418 30 41 26.418 41 22H7Z"
        fill="#0A3A5C"
      />
      <path d="M41 6H7V22H41V6Z" fill="#E6E7E8" />
      <path
        // eslint-disable-next-line max-len
        d="M41 3C41 1.895 40.105 1 39 1H9C7.895 1 7 1.895 7 3V8H30C30.552 8 31 8.448 31 9V14.893C31 15.889 31.681 16.813 32.664 16.973C33.917 17.176 35 16.215 35 15V9C35 8.448 35.448 8 36 8H41V3Z"
        fill="#80B3D2"
      />
      <path d="M41 20H7V22H41V20Z" fill="#AEAEAE" />
    </svg>
  );
};
