/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const MobileContact1FilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M14 47C11.791 47 10 45.1276 10 42.8182V5C10 2.79086 11.7909 1 14 1H34C36.209 1 38 2.87241 38 5.18182V42.8182C38 45.1276 36.209 47 34 47H14Z"
        fill="#0A3A5C"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M22 43C22 41.895 22.896 41 24 41C25.104 41 26 41.895 26 43C26 44.105 25.104 45 24 45C22.896 45 22 44.105 22 43Z"
        fill="#F7F7F7"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12 5.99914C12 5.44733 12.4475 5 13.0007 5H34.9993C35.552 5 36 5.45492 36 5.99914V38.0009C36 38.5527 35.5525 39 34.9993 39H13.0007C12.448 39 12 38.5451 12 38.0009V5.99914Z"
        fill="#80B3D2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M26 24H22C19.2386 24 17 26.2386 17 29C17 29.5523 17.4499 30 18.0068 30H29.9932C30.5492 30 31 29.5468 31 29C31 26.2386 28.7614 24 26 24Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M24 22C26.2091 22 28 20.2091 28 18C28 15.7909 26.2091 14 24 14C21.7909 14 20 15.7909 20 18C20 20.2091 21.7909 22 24 22Z"
        fill="white"
      />
    </svg>
  );
};
