import { useCallback, useEffect, useMemo, useState } from "react";

import type { SortingOption, UseProductVariantsReturn } from "./interface";
import type { ProductList, VariantList } from "@byko/lib-api-rest";

const clothSizeWeights: { [key: string]: number } = {
  "3xs": 0,
  xxxs: 1,
  "2xs": 2,
  xxs: 3,
  xs: 4,
  s: 5,
  m: 6,
  l: 7,
  xl: 8,
  xxl: 9,
  "2xl": 10,
  xxxl: 11,
  "3xl": 12,
};

export const useProductVariants = (
  product: ProductList | undefined,
  activeVariantSKU: string,
  setActiveVariantSKU: (value: string) => void,
  lowestPriceSku: string | undefined | null,
): UseProductVariantsReturn => {
  const [activeSizeId, setActiveSizeId] = useState<number>(-1);
  const [activeColorId, setActiveColorId] = useState<number>(-1);
  const [activeSize, setActiveSize] = useState<SortingOption | undefined>();
  const [activeColor, setActiveColor] = useState<SortingOption | undefined>();
  const [availableSizes, setAvailableSizes] = useState<string[]>([]);
  const [availableColors, setAvailableColors] = useState<string[]>([]);

  useEffect(() => {
    // this is for debugging purposes, ment to be deployed temporarily
    // FIXME: This still needed?
    product?.variants.forEach(({ meta, sku, name }) => {
      if (
        meta &&
        meta?.color === "" &&
        meta?.size === "" &&
        meta?.style === "" &&
        meta?.unit === "" &&
        product.variants.length > 1
      ) {
        // eslint-disable-next-line no-console
        console.log("Meta data missing for product variant with sku: ", sku, " - ", name);
      }
    });
  }, [product, product?.variants]);

  const activeVariant = useMemo((): VariantList | undefined => {
    return product?.variants.find((variant) => {
      return variant.sku === activeVariantSKU;
    });
  }, [activeVariantSKU, product?.variants]);

  const availableSizesForActiveVariant = useMemo(() => {
    const available: string[] = [];

    if (activeColorId !== -1) {
      product?.variants.forEach((variant) => {
        if (variant.meta) {
          if (activeColor?.value === variant.meta?.color) {
            if (!available.includes(variant.meta?.size)) {
              available.push(variant.meta?.size);
            }
          }
        }
      });
    }

    return available;
  }, [activeColor?.value, activeColorId, product?.variants]);

  const availableColorsForActiveVariant = useMemo(() => {
    const available: string[] = [];

    if (activeSizeId !== -1) {
      product?.variants.forEach((variant) => {
        if (variant.meta) {
          if (activeSize?.value === variant.meta?.size) {
            if (!available.includes(variant.meta?.color)) {
              available.push(variant.meta?.color);
            }
          }
        }
      });
    }

    return available;
  }, [activeSize?.value, activeSizeId, product?.variants]);

  useEffect(() => {
    const values: string[] = [];

    product?.variants.forEach((variant) => {
      if (variant.meta) {
        const value = variant.meta?.size;
        if (value && value.length && !values.includes(value)) {
          values.push(value);
        }
      }
    });

    if (
      values.some((value) =>
        ["3xs", "xxxs", "2xs", "xxs", "xs", "s", "m", "l", "xl", "xxl", "2xl", "xxxl", "3xl"].includes(
          value.toLowerCase(),
        ),
      )
    ) {
      setAvailableSizes(
        values.sort((a, b) => {
          const aWeight = clothSizeWeights[a.toLowerCase()];
          const bWeight = clothSizeWeights[b.toLowerCase()];

          if (aWeight && bWeight) {
            return aWeight - bWeight;
          }

          // this will sort unknown sizes at the end of the list
          return 1;
        }),
      );
    } else if (values.every((value) => !isNaN(parseFloat(value)))) {
      setAvailableSizes(values.sort((a, b) => parseFloat(a) - parseFloat(b)));
    } else {
      setAvailableSizes(values.sort((a, b) => a.localeCompare(b)));
    }
  }, [product?.variants]);

  useEffect(() => {
    const values: string[] = [];

    product?.variants.forEach((variant) => {
      if (variant.meta) {
        const value = variant.meta?.color;
        if (value && value.length && !values.includes(value)) {
          values.push(value);
        }
      }
    });

    if (values.length > 1) {
      setAvailableColors(values);
    }
  }, [product?.variants]);

  const sizeSelectionList = useMemo((): SortingOption[] => {
    const list: SortingOption[] = [];

    availableSizes.forEach((item, index) => {
      list.push({
        id: index,
        value: item,
        label: item,
        active: availableSizesForActiveVariant.length === 0 || availableSizesForActiveVariant.includes(item),
      });
    });

    const topElement = {
      id: -1,
      label: "Veldu stærð",
      value: "Veldu stærð",
    };

    return [topElement, ...list];
  }, [availableSizes, availableSizesForActiveVariant]);

  const colorSelectionList = useMemo((): SortingOption[] => {
    const list: SortingOption[] = [];

    availableColors.forEach((item, index) => {
      list.push({
        id: index,
        value: item,
        label: item,
        active: availableColorsForActiveVariant.length === 0 || availableColorsForActiveVariant.includes(item),
      });
    });

    const topElement = {
      id: -1,
      label: "Veldu lit",
      value: "Veldu lit",
    };

    return [topElement, ...list];
  }, [availableColors, availableColorsForActiveVariant]);

  useEffect(() => {
    const value = sizeSelectionList.find((size) => {
      return size.id === activeSizeId;
    });
    setActiveSize(value);
  }, [activeSizeId, sizeSelectionList]);

  useEffect(() => {
    const value = colorSelectionList.find((color) => {
      return color.id === activeColorId;
    });

    setActiveColor(value);
  }, [activeColorId, colorSelectionList]);

  useEffect(() => {
    if (availableColors.length && availableSizes.length) {
      if (activeColorId >= 0 && activeSizeId >= 0) {
        const updatedVariantSelection = product?.variants.find((variant) => {
          return variant.meta?.color === activeColor?.value && variant.meta?.size === activeSize?.value;
        });
        if (updatedVariantSelection && updatedVariantSelection.sku) {
          setActiveVariantSKU(lowestPriceSku ?? updatedVariantSelection.sku);
        }
      }
    } else if (availableColors.length) {
      if (activeColorId >= 0) {
        const updatedVariantSelection = product?.variants.find((variant) => {
          return variant.meta?.color === activeColor?.value;
        });
        if (updatedVariantSelection && updatedVariantSelection.sku) {
          setActiveVariantSKU(lowestPriceSku ?? updatedVariantSelection.sku);
        }
      }
    } else if (availableSizes.length) {
      if (activeSizeId >= 0) {
        const updatedVariantSelection = product?.variants.find((variant) => {
          return variant.meta?.size === activeSize?.value;
        });
        if (updatedVariantSelection && updatedVariantSelection.sku) {
          setActiveVariantSKU(lowestPriceSku ?? updatedVariantSelection.sku);
        }
      }
    }
  }, [
    activeColor,
    activeColorId,
    activeSize,
    activeSizeId,
    availableColors.length,
    availableSizes.length,
    lowestPriceSku,
    product?.variants,
    setActiveVariantSKU,
  ]);

  const handleSizeChange = useCallback((value: { selectedItem?: SortingOption | null } | null) => {
    if (!value?.selectedItem || value.selectedItem.id === -1) {
      setActiveSizeId(-1);
      return;
    }
    setActiveSizeId(value.selectedItem.id ?? 0);
  }, []);

  const handleColorChange = useCallback(
    (value: { selectedItem?: SortingOption | null } | null) => {
      if (!value?.selectedItem || value.selectedItem.id === -1) {
        setActiveColorId(-1);
        return;
      }
      setActiveColorId(value.selectedItem.id ?? 0);
    },
    [setActiveColorId],
  );

  const errorMessage = useMemo((): string | null => {
    if (activeColor?.active === false || activeSize?.active === false) {
      return "Þessi stærð er ekki í boði fyrir þennan lit, vinsamlegast uppfærðu valið.";
    }
    return null;
  }, [activeColor?.active, activeSize?.active]);

  const permitPurchase = useMemo(() => {
    if (availableColors.length > 1 && activeColorId === -1) {
      return false;
    }
    if (availableSizes.length > 1 && activeSizeId === -1) {
      return false;
    }
    if (errorMessage) {
      return false;
    }

    return true;
  }, [activeColorId, activeSizeId, availableColors.length, availableSizes.length, errorMessage]);

  useEffect(() => {
    // find and set selection values for first available (in stock) variant:
    if (activeColorId === -1 && activeSizeId === -1) {
      const validVariant = product?.variants.find((variant) => {
        return variant.webstoreInStock;
      });

      const meta = validVariant?.meta ?? product?.variants[0]?.meta;
      if (meta) {
        const sizeSelectionItem = sizeSelectionList.find((item) => {
          return item.value === meta?.size;
        });
        const colorSelectionItem = colorSelectionList.find((item) => {
          return item.value === meta?.color;
        });
        if (meta?.color.length) {
          handleColorChange({ selectedItem: colorSelectionItem ?? null });
        }
        if (meta?.size.length) {
          handleSizeChange({ selectedItem: sizeSelectionItem ?? null });
        }
      }
    }
  }, [
    activeColorId,
    activeSizeId,
    colorSelectionList,
    handleColorChange,
    handleSizeChange,
    product?.variants,
    sizeSelectionList,
  ]);

  return {
    activeVariant,
    sizeSelectionList,
    colorSelectionList,
    activeSize,
    activeColor,
    handleSizeChange,
    handleColorChange,
    permitPurchase,
    errorMessage,
  };
};
