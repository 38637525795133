import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const WrenchSmallIcons: BykoReactIconComponentType = ({ iconColor, size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 16 16" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M14.664 3.20267L12.6667 5.2L10.8 3.33333L12.7973 1.336C12.3297 1.12413 11.8136 1 11.2667 1C9.20493 1 7.53333 2.6716 7.53333 4.73333C7.53333 5.24853 7.63787 5.73947 7.8264 6.18653L1 11.2667V15H4.73333L9.81347 8.1736C10.2605 8.36213 10.7515 8.46667 11.2667 8.46667C13.3284 8.46667 15 6.79507 15 4.73333C15 4.1864 14.8759 3.67027 14.664 3.20267Z"
        stroke={iconColor}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
    </svg>
  );
};
