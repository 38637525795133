import { useCallback, useEffect } from "react";

import { useAuth } from "@byko/hooks-auth-next";
import { restApi } from "@byko/lib-api-rest";
import { useRecoilState } from "@byko/lib-recoil";
import { isSSR } from "@byko/types-utils";
import { cartItemCountState, checkoutDataState } from "./store";
import { useSetRecoilState } from "@byko/lib-recoil";
import { _checkout, useAnonymousToken } from "@byko/lib-api-products";
import type { UseCartNewProps } from "./interface";
import type { Checkout, PatchedCheckoutRequest } from "@byko/lib-api-rest";

export const useCheckoutExtra = (): UseCartNewProps => {
  const { user, isMembershipStateSet } = useAuth();
  const [checkoutData, setCheckoutData] = useRecoilState<Checkout | null>(checkoutDataState);
  const [cartItemCount, setCartItemCount] = useRecoilState<number>(cartItemCountState);
  const { set: setAnonymousToken } = useAnonymousToken();
  const setCheckoutItems = useSetRecoilState(_checkout);
  let checkoutId: string | null = null;
  if (!isSSR()) {
    checkoutId = localStorage.getItem("myanonymousCartToken");
  }

  useEffect(() => {
    const localcheckoutId = localStorage.getItem("myanonymousCartToken");
    if (user === null && localcheckoutId === null) {
      setCheckoutData(null);
      setCheckoutItems(null);
    }
  }, [setCheckoutData, user, setCheckoutItems]);

  const mergeAnonymousCartWithUserCart = useCallback(
    async (anonymousToken: string) => {
      try {
        const result = await restApi.checkoutsMergeUpdate("current", {
          token: anonymousToken,
        });

        localStorage.removeItem("myanonymousCartToken");

        setCheckoutData(result.data);
        setCheckoutItems(result.data);
        setCartItemCount(result.data.quantity);
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const status = error.status;

        if (status === 403 || status === 404) {
          localStorage.removeItem("myanonymousCartToken");
        }
        console.error(error);
      }
    },
    [setCartItemCount, setCheckoutData, setCheckoutItems],
  );

  const updateCheckoutData = useCallback(
    async (options: PatchedCheckoutRequest) => {
      try {
        if (!checkoutData) {
          return;
        }

        if (user && isMembershipStateSet) {
          const result = await restApi.checkoutsPartialUpdate("current", options);
          setCheckoutData(result.data);
          setCheckoutItems(result.data);
          setCartItemCount(result.data.quantity);
        } else {
          if (checkoutId) {
            const result = await restApi.checkoutsPartialUpdate(checkoutId, options);
            setCheckoutData(result.data);
            setCheckoutItems(result.data);
            setCartItemCount(result.data.quantity);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    [checkoutData, isMembershipStateSet, setCheckoutData, user, checkoutId, setCartItemCount, setCheckoutItems],
  );

  const fetchCheckoutData = useCallback(async () => {
    const localcheckoutId = localStorage.getItem("myanonymousCartToken");
    try {
      if (user) {
        if (localcheckoutId) {
          await mergeAnonymousCartWithUserCart(localcheckoutId);
        } else {
          const result = await restApi.checkoutsRetrieve("current");
          setCheckoutData(result.data);
          setCheckoutItems(result.data);
          setCartItemCount(result.data.quantity);
        }
      } else {
        if (localcheckoutId) {
          const result = await restApi.checkoutsRetrieve(localcheckoutId);
          setCheckoutData(result.data);
          setCheckoutItems(result.data);
          setCartItemCount(result.data.quantity);
          if (
            result.data?.quantity &&
            typeof result.data?.quantity === "number" &&
            !Number.isNaN(result.data?.quantity)
          ) {
            setCartItemCount(result.data.quantity);
          }
        } else {
          const result = await restApi.checkoutsRetrieve("current");
          setAnonymousToken(result.data.token);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [user, mergeAnonymousCartWithUserCart, setCheckoutData, setCheckoutItems, setCartItemCount, setAnonymousToken]);

  return {
    fetchCheckoutData,
    updateCheckoutData,
    checkoutData,
    cartItemCount,
  };
};
