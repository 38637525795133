import { useRouter } from "next/router";
import React from "react";

import { useCompareProducts } from "@byko/hooks-compare-products";
import { isSSR } from "@byko/types-utils";

import { CompareSectionButtons } from "./compare-section-buttons";
import { CompareSectionCard } from "./compare-section-card";
import { ProductCompareSectionBackground, ProductCompareSectionContainer, ProductList } from "./styles";

export const ProductCompareSection = (): JSX.Element | null => {
  const { products, clearAllProducts } = useCompareProducts();

  const router = useRouter();

  if (isSSR()) {
    return null;
  }

  // no need to show this section on compare page
  if (products.length === 0 || router.pathname === "/samanburdur") {
    return null;
  }

  return (
    <ProductCompareSectionBackground>
      <ProductCompareSectionContainer>
        <CompareSectionButtons prodLenght={products.length} onClickClearAll={clearAllProducts} />

        <ProductList>
          {products.map((product) => {
            return (
              <CompareSectionCard
                key={product.id}
                prodId={product.id}
                prodImageAlt={product.thumbnail.alt}
                prodName={product.name}
                prodSlug={product.slug}
                prodUrl={product.thumbnail.url}
              />
            );
          })}
        </ProductList>
      </ProductCompareSectionContainer>
    </ProductCompareSectionBackground>
  );
};
