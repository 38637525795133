import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const PaintBucket1LineDecorative: BykoReactDecorativeIconComponentType = ({ size, color = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 8V40C6 43.314 14.059 46 24 46C33.941 46 42 43.314 42 40V8"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path
        // eslint-disable-next-line max-len
        d="M24 2C14.059 2 6 4.686 6 8C6 11.314 14.059 14 24 14C24.688 14 25.333 13.949 26 13.923V25C26 26.657 27.343 28 29 28C30.657 28 32 26.657 32 25V13.369C37.922 12.386 42 10.354 42 8C42 4.686 33.941 2 24 2Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
    </svg>
  );
};
