import styled from "styled-components";

import { theme } from "@byko/lib-styles";

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;
  gap: 8px;
  .hideButton {
    display: none;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 2px;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: ${theme.palette.yellow.main};
`;

export const BottomLine = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 24px;
  background-color: ${theme.palette.gray[10]};
`;
