import React from "react";

import { PMedium } from "@byko/component-typography";
import { theme } from "@byko/lib-styles";
import { useCommonContext } from "src/features/context";
import { useProductInventory } from "@byko/hooks-product-variants";

import type { StockStatusProps } from "./interface";
import {
  AvailabilityBlock,
  AvailabilityContainer,
  AvailabilityLabel,
  Location,
  LocationList,
  SoldOutLabel,
} from "./styles";

export const StockStatus = ({ activeVariantSKU, errorMessage, productSlug }: StockStatusProps) => {
  const { warehouses } = useCommonContext();
  const { availability } = useProductInventory({
    productSlug,
    activeVariantSKU,
    warehouses,
  });

  return (
    <AvailabilityContainer>
      {errorMessage && errorMessage.length && <PMedium textColor={theme.palette.orange.dark}>{errorMessage}</PMedium>}
      {!errorMessage && availability.available.length > 0 && (
        <AvailabilityBlock className="border-bottom">
          <AvailabilityLabel textColor={theme.palette.blue.main}>Til á lager:</AvailabilityLabel>
          <LocationList>
            {availability.available.map(({ name, slug }) => {
              return (
                <Location key={slug} textColor={theme.palette.gray[80]}>
                  {name}
                </Location>
              );
            })}
          </LocationList>
        </AvailabilityBlock>
      )}

      {!errorMessage && availability.lowStock.length > 0 && (
        <AvailabilityBlock className="border-bottom">
          <AvailabilityLabel textColor={theme.palette.blue.main}>Fá eintök:</AvailabilityLabel>
          <LocationList>
            {availability.lowStock.map(({ name, slug }) => {
              return (
                <Location key={slug} textColor={theme.palette.gray[80]}>
                  {name}
                </Location>
              );
            })}
          </LocationList>
        </AvailabilityBlock>
      )}

      {!errorMessage && availability.outOfStock.length > 0 && (
        <AvailabilityBlock>
          <SoldOutLabel textColor={theme.palette.blue.main}>Uppselt:</SoldOutLabel>
          <LocationList>
            {availability.outOfStock.map(({ name, slug }) => {
              return (
                <Location key={slug} textColor={theme.palette.gray[30]}>
                  {name}
                </Location>
              );
            })}
          </LocationList>
        </AvailabilityBlock>
      )}
    </AvailabilityContainer>
  );
};
