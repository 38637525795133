import { useEffect, useState } from "react";

import type loaderConfigType from "@byko/component-loaders-next-assets";
import type lottieType from "react-lottie-player/dist/LottiePlayerLight";

export const getAssets = async (): Promise<typeof loaderConfigType> => {
  if (typeof window === "undefined") {
    return null;
  }
  const assets = (await import("@byko/component-loaders-next-assets")) as typeof loaderConfigType;
  return assets;
};

export const getLottie = async (): Promise<typeof lottieType | null> => {
  if (typeof window === "undefined") {
    return null;
  }
  const assets = (await import("react-lottie-player/dist/LottiePlayerLight")).default as typeof lottieType;
  return assets;
};

export const useLoadAsset = <T>(fn: () => Promise<T>): T | null => {
  const [value, setValue] = useState<T | null>(null);
  useEffect(() => {
    fn().then(setValue);
  }, [fn]);
  return value;
};

export const useLottie = (): typeof lottieType | null => {
  return useLoadAsset(getLottie);
};

export const useAssets = (): typeof loaderConfigType | null => {
  return useLoadAsset(getAssets);
};

export const preloadLoader = (): void => {
  if (typeof window === "undefined") {
    return;
  }
  const fn = (): void => {
    void getLottie();
    void getAssets();
  };
  requestIdleCallback(fn);
};
