import { gql } from "graphql-tag";
import { useQuery } from "react-query";
import { graphQLClient } from "../dato-graphql-client";

import type { GetFrontPageBySlugQuery, GetFrontPageBySlugQueryVariables } from "../generated-gql/graphql";
import { DatoFrontpageParts } from "./fragments";

export function useGetFrontPageBySlug({ slug }: GetFrontPageBySlugQueryVariables) {
  const { data, isLoading, error } = useQuery(`get-slug-front-page-by-${slug}`, async () => {
    const request = await graphQLClient.request<{
      allFrontpages: GetFrontPageBySlugQuery;
    }>(
      gql`
        query GetFrontPageBySlug($slug: String!) {
          allFrontpages(filter: { slug: { eq: $slug } }, first: 1, orderBy: [published_DESC]) {
            ...DatoFrontpageParts
          }
        }
        ${DatoFrontpageParts}
      `,
      {
        slug,
      },
    );
    return request;
  });

  return { data: data?.allFrontpages, isLoading, error };
}
