import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const LawnmowerIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1H5L7.939 9.603" stroke={iconColor} strokeMiterlimit="10" strokeWidth="2" />
      <path d="M8.89099 20H17" stroke={iconColor} strokeMiterlimit="10" strokeWidth="2" />
      <path
        // eslint-disable-next-line max-len
        d="M20 14V13.709C20.0001 13.2326 19.8301 12.7717 19.5207 12.4095C19.2112 12.0472 18.7826 11.8073 18.312 11.733L7.939 9.60002C7.58309 9.52697 7.21538 9.53409 6.86256 9.62087C6.50974 9.70765 6.18068 9.8719 5.89926 10.1017C5.61784 10.3315 5.39112 10.6211 5.23558 10.9495C5.08003 11.2778 4.99956 11.6367 5 12V12"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        // eslint-disable-next-line max-len
        d="M5 23C7.20914 23 9 21.2091 9 19C9 16.7909 7.20914 15 5 15C2.79086 15 1 16.7909 1 19C1 21.2091 2.79086 23 5 23Z"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        // eslint-disable-next-line max-len
        d="M20 23C21.6569 23 23 21.6569 23 20C23 18.3431 21.6569 17 20 17C18.3431 17 17 18.3431 17 20C17 21.6569 18.3431 23 20 23Z"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
};
