/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const Clock1FilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 45C35.598 45 45 35.598 45 24C45 12.402 35.598 3 24 3C12.402 3 3 12.402 3 24C3 35.598 12.402 45 24 45Z"
        fill="white"
      />
      <path
        d="M24 47C11.3174 47 1 36.6821 1 24C1 11.3179 11.3174 1 24 1C36.6826 1 47 11.3179 47 24C47 36.6821 36.6826 47 24 47ZM24 5C13.5234 5 5 13.5234 5 24C5 34.4766 13.5234 43 24 43C34.4766 43 43 34.4766 43 24C43 13.5234 34.4766 5 24 5Z"
        fill="#00416A"
      />
      <path
        d="M24 25C23.4473 25 23 24.5522 23 24V11C23 10.4477 23.4473 10 24 10C24.5527 10 25 10.4477 25 11V24C25 24.5522 24.5527 25 24 25Z"
        fill="#0A3A5C"
      />
      <path
        d="M36 25H24C23.4473 25 23 24.5523 23 24C23 23.4477 23.4473 23 24 23H36C36.5527 23 37 23.4477 37 24C37 24.5523 36.5527 25 36 25Z"
        fill="#0A3A5C"
      />
      <path
        d="M14.9991 36.0001C14.7764 36.0001 14.5528 35.9258 14.3672 35.774C13.9395 35.4244 13.876 34.7945 14.2256 34.3668L23.2256 23.3668C23.5762 22.94 24.2051 22.877 24.6329 23.2261C25.0606 23.5757 25.1241 24.2056 24.7745 24.6334L15.7745 35.6334C15.5762 35.8751 15.2891 36.0001 14.9991 36.0001Z"
        fill="#F85E00"
      />
      <path
        d="M24 27C25.6569 27 27 25.6569 27 24C27 22.3431 25.6569 21 24 21C22.3431 21 21 22.3431 21 24C21 25.6569 22.3431 27 24 27Z"
        fill="#0A3A5C"
      />
    </svg>
  );
};
