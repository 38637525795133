import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const ShovelFilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M24 47C24 47 13 45.0833 13 38V25C13 24.4477 13.4477 24 14 24H34C34.5523 24 35 24.4477 35 25V38C35 45 24 47 24 47Z"
        fill="#E6E7E8"
      />
      <path
        // eslint-disable-next-line max-len
        d="M29 1H19C18.4477 1 18 1.44775 18 2V3C18 5.96722 20.1669 8.43134 23 8.90967V31C23 31.5522 23.4478 32 24 32C24.5522 32 25 31.5522 25 31V8.90967C27.8331 8.43134 30 5.96722 30 3V2C30 1.44775 29.5522 1 29 1ZM24 7C21.7944 7 20 5.20557 20 3H28C28 5.20557 26.2056 7 24 7Z"
        fill="#00416A"
      />
    </svg>
  );
};
