import * as React from "react";
import { useMemo } from "react";

import { DropdownSelectOptionsItem } from "./dropdown-select-list-item";

import type { DropdownOptionsProps } from "./interface";

export const DropdownSelectList = ({ options }: DropdownOptionsProps): JSX.Element => {
  const handleOptionsItems = useMemo(() => {
    return (
      <>
        {options.map((option) => {
          return (
            <DropdownSelectOptionsItem
              key={option.id}
              id={option.id}
              name={option.account.name}
              ssn={option.account.ssn}
            />
          );
        })}
      </>
    );
  }, [options]);

  return <>{handleOptionsItems}</>;
};
