import styled from "styled-components";

import { EyebrowSmall, PLargeBold, PMedium } from "@byko/component-typography";
import { gridTheme, theme } from "@byko/lib-styles";

export const ProdInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    padding: 12px 16px;
  }
`;

export const RightFloatContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const FlexWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const BrandContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 50%;
  max-width: 45%;
`;

export const ProductNumberContainer = styled.div`
  width: 45%;
`;

export const BrandName = styled(EyebrowSmall)`
  overflow: hidden;
  flex: 1;
  color: ${theme.palette.gray[60]};
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ProductNumber = styled(EyebrowSmall)`
  overflow: hidden;
  height: 20px;
  flex: 1;
  text-align: right;
  text-overflow: ellipsis;
  text-transform: uppercase;
  user-select: all;
  white-space: nowrap;
`;

export const ProductName = styled(PLargeBold)`
  overflow: hidden;
  color: ${theme.palette.blue.dark};
  font-weight: 400;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    padding: 0;
    font-size: 16px;
  }
`;

export const HoverLabelContainer = styled.div`
  position: relative;
  display: inline-block;
  max-width: 100%;
`;

export const ShortDecriptionText = styled(PMedium)`
  overflow: hidden;
  color: ${theme.palette.gray[60]};
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    font-size: 14px;
  }
`;

export const EnvironmentalWrapper = styled.div`
  height: 25px;
  margin-top: 8px;
`;

export const EnvironmentalInner = styled.div`
  display: flex;
  width: fit-content;
  height: 100%;
  align-items: center;
  background-color: ${theme.palette.green.dark};
  padding-inline: 16px;
`;

export const HoverLabel = styled.div`
  position: absolute;
  top: 50%;
  right: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px 1px 4px;
  border-radius: 4px;
  margin-right: 10px;
  background-color: ${theme.palette.blue.dark};
  opacity: 0;
  pointer-events: none;
  transform: translateY(-50%); /* adjust to align perfectly */
  transition: opacity 0.3s ease, visibility 0.3s ease;
  visibility: hidden;
  white-space: nowrap;

  &::after {
    position: absolute;
    top: 50%;
    left: 100%;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid ${theme.palette.blue.dark};
    content: "";
    transform: translateY(-50%);
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const HoverContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &:hover ${HoverLabel} {
    opacity: 1;
    visibility: visible;
  }
`;
