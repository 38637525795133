import styled from "styled-components";

import { FormikProvider } from "@byko/lib-formik";

export const FormikProviderStyle = styled(FormikProvider)``;

export const EmailFormWrapper = styled.form`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 40px;
`;

export const TextAndInput = styled.div`
  margin-top: 40px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  row-gap: 8px;
`;
