import Link from "next/link";
import styled from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";

// -------- Compare section card
export const ProductCompareSectionCard = styled(Link)`
  display: flex;
  width: 100%;
  max-width: 400px;
  height: 60px;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  background-color: ${theme.palette.white.main};
  gap: 16px;
  &:hover {
    box-shadow: 0px 18px 45px rgba(0, 0, 0, 0.09);
  }
  p {
    overflow: hidden;
    max-width: 260px;
    color: ${theme.palette.blue.dark};
    font-weight: 400;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media screen and (max-width: ${gridTheme.container.maxWidth.sm}px) {
      max-width: 160px;
    }
  }
`;

export const ProdImage = styled.img`
  width: 60px;
  height: 40px;
  object-fit: contain;
  @media screen and (max-width: ${gridTheme.container.maxWidth.sm}px) {
    display: none;
  }
`;

export const ProductCardDeletButton = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    border-radius: 100%;
    margin-bottom: -20px;
    &:hover {
      width: 40px;
      height: 40px;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    }
  }
`;
