/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const EcologyFilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 47C36.7025 47 47 36.7025 47 24C47 11.2975 36.7025 1 24 1C11.2975 1 1 11.2975 1 24C1 36.7025 11.2975 47 24 47Z"
        fill="#E6E7E8"
      />
      <path
        d="M1.00001 24C0.997059 27.2585 1.68831 30.4801 3.02773 33.4506C4.36715 36.421 6.32401 39.072 8.76801 41.227L10.8 38.18C10.8 38.18 12.252 37.761 12.565 37.199C13.545 35.435 11.805 32.364 11.805 32.364C11.7691 31.628 11.6517 30.8982 11.455 30.188C11.193 29.535 9.36401 28.64 9.36401 28.64C9.36401 28.64 7.60001 25.765 7.60001 24C7.5692 23.308 7.8127 22.6317 8.27761 22.1181C8.74252 21.6045 9.39129 21.2951 10.083 21.257C10.6665 21.1333 11.2179 20.8893 11.7017 20.5404C12.1856 20.1916 12.5913 19.7456 12.893 19.231C12.893 19.231 15.115 18.642 15.637 17.731C16.159 16.82 15.05 13.556 15.05 13.556C15.2554 12.6939 15.2479 11.7946 15.028 10.936C14.6636 10.1421 14.0957 9.45873 13.382 8.95503C12.8084 7.52083 11.9357 6.22538 10.822 5.15503C7.78794 7.27271 5.31042 10.0921 3.6003 13.3732C1.89019 16.6543 0.99812 20.3 1.00001 24Z"
        fill="#04B485"
      />
      <path
        d="M24 1.00001C23.38 1.00001 22.768 1.03201 22.161 1.08001C21.961 1.69901 20.93 3.28801 21.19 4.00501C21.45 4.72201 23.585 5.38901 23.585 5.38901C23.585 5.38901 23.019 7.60001 24 8.38301C24.981 9.16601 26.549 8.12201 27.136 8.31801C27.723 8.51401 27.593 12.368 27.593 12.368C27.593 12.368 29.488 14.459 31.351 14.721C33.214 14.983 36.051 11.821 36.051 11.821C38.1363 11.3467 40.1771 10.6943 42.151 9.87101C40.0026 7.10582 37.25 4.86881 34.1039 3.33122C30.9578 1.79363 27.5017 0.996215 24 1.00001Z"
        fill="#04B485"
      />
      <path
        d="M38.8581 25.5C39.3811 26.742 37.8761 29.912 36.6361 30.531C35.3667 31.1072 34.2603 31.9896 33.416 33.099C32.958 33.818 32.357 36.783 30.855 37.371C29.353 37.959 25.7901 41.313 23.8951 40.79C22.0001 40.267 22 35.761 23.307 33.735C24.087 32.526 23.176 29.88 23.107 28.966C23.038 28.052 20.3 26.352 20.3 25.438C20.3 24 23.4361 20.21 23.4361 20.21C23.4361 20.21 26.0061 19.361 26.7901 19.557C27.5827 19.8452 28.3424 20.2174 29.056 20.667C30.4379 20.7701 31.8001 21.0556 33.107 21.516L34.607 22.888C34.607 22.888 38.3351 24.261 38.8581 25.5Z"
        fill="#04B485"
      />
      <path
        d="M47.316 19.0231C47.1166 18.9577 46.9017 18.9563 46.7014 19.0191C46.5012 19.0818 46.3255 19.2056 46.199 19.3731C44.9042 21.1233 43.2159 22.5445 41.2705 23.522C39.3251 24.4995 37.1771 25.0058 35 25.0001C33.0217 24.9985 31.0981 25.6493 29.5272 26.8518C27.9563 28.0543 26.8258 29.7412 26.3108 31.6513C25.7958 33.5615 25.9251 35.5881 26.6787 37.4172C27.4323 39.2464 28.768 40.776 30.479 41.7691C29.9798 42.7828 29.1953 43.6286 28.2219 44.2026C27.2486 44.7766 26.1287 45.0538 25 45.0001C24.7348 45.0001 24.4804 45.1054 24.2929 45.293C24.1054 45.4805 24 45.7348 24 46.0001C24 46.2653 24.1054 46.5196 24.2929 46.7072C24.4804 46.8947 24.7348 47.0001 25 47.0001C28.919 47.0001 30.86 45.0001 32.315 42.5901C33.184 42.8622 34.0894 43.0005 35 43.0001C38.4466 42.9961 41.7509 41.6252 44.188 39.1881C46.6251 36.7509 47.996 33.4467 48 30.0001V19.9731C48.0003 19.7629 47.9343 19.558 47.8115 19.3875C47.6888 19.217 47.5154 19.0895 47.316 19.0231Z"
        fill="#008F69"
      />
      <path
        d="M40.313 28.3371C34.062 30.5671 31.713 35.1421 29.995 38.4831C29.58 39.2901 29.224 39.9771 28.877 40.5601C29.3634 41.0216 29.9001 41.4272 30.477 41.7691C30.915 41.0601 31.327 40.2601 31.771 39.3951C33.423 36.1831 35.477 32.1851 40.982 30.2191C41.1088 30.1775 41.2261 30.1109 41.3268 30.0233C41.4276 29.9357 41.5098 29.8288 41.5685 29.7089C41.6273 29.589 41.6614 29.4585 41.669 29.3252C41.6765 29.1919 41.6572 29.0584 41.6124 28.9327C41.5675 28.8069 41.4979 28.6914 41.4077 28.593C41.3174 28.4946 41.2084 28.4153 41.087 28.3596C40.9656 28.304 40.8343 28.2733 40.7009 28.2693C40.5674 28.2652 40.4345 28.2879 40.31 28.3361L40.313 28.3371Z"
        fill="#52C6A8"
      />
    </svg>
  );
};
