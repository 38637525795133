import React, { useCallback } from "react";

import { Tags } from "./styles";
import { TagItem } from "./tag-item";

import type { TagsBlockProps } from "./interface";
import type { Tag } from "@byko/lib-algolia";

export const TagsBlock = ({ tags, setTag }: TagsBlockProps): JSX.Element => {
  const clearTag = useCallback(
    (tag: Tag) => {
      if (!setTag) {
        return;
      }

      setTag(tag.id, false);
    },
    [setTag],
  );
  return (
    <Tags>
      {tags.map((tag) => {
        return <TagItem key={tag.id} clearTag={clearTag} tag={tag} />;
      })}
    </Tags>
  );
};
