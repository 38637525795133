/**
 * Returns true if condition is met.
 *
 * Avoids hydrations errors
 */

import { useEffect, useRef, useState } from "react";

export const useHasCondition = <T extends unknown[]>(fn: () => boolean | Promise<boolean>, deps: T): boolean => {
  const [hasCondition, setHasCondition] = useState(false);
  const currentRun = useRef(0);
  useEffect(() => {
    const myRun = currentRun.current + 1;
    currentRun.current = myRun;
    const promiseFn = async (): Promise<void> => {
      if (currentRun.current !== myRun) {
        return;
      }
      const newCondition = await fn();
      setHasCondition(newCondition);
    };

    promiseFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return hasCondition;
};
