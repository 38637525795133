import styled from "styled-components";

import { PMediumBold } from "@byko/component-typography";
import { gridTheme, scrollStyle, theme } from "@byko/lib-styles";

export const MiniCartContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  width: 696px;
  height: 100%;
  min-height: 100px;
  max-height: 65vh;
  flex-direction: column;
  padding: 0 50px;
  background-color: ${theme.palette.white.main};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  pointer-events: auto;
  transition: all 0.5s;
  @media screen and (max-width: 400px) {
    min-width: 100%;
  }

  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    max-height: 90%;
    padding: 0 16px;
  }
  @media screen and (max-width: 699px) {
    width: 100%;
  }
`;

export const SpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0;
  p {
    text-transform: uppercase;
  }

  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    padding: 16px 0;
    ${PMediumBold} {
      font-size: 14px;
    }
  }
`;

export const CartCardWrapper = styled.div`
  max-height: 564px;
  border-bottom: 2px solid ${theme.palette.yellow.main};
  overflow-y: scroll;
  ${scrollStyle({ color: "light-blue" })}
  @media screen and (min-width: ${gridTheme.container.maxWidth.s}px) {
    padding-right: 8px;
  }
  @media screen and (max-width: 699px) {
    max-height: 240px;
  }
`;

export const ButtonSpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  gap: 16px;
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    flex-direction: column;
    padding-bottom: 16px;
  }
`;
