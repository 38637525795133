import * as React from "react";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

import { useToasts } from "@byko/hooks-toasts";

export const ErrorToast = (): JSX.Element => {
  const { errorMessage, setErrorMessage, messagePosition } = useToasts();
  useEffect(() => {
    if (errorMessage.length) {
      toast.error(errorMessage);
      setErrorMessage("");
    }

    let interval: number;

    return (): void => {
      clearInterval(interval);
    };
  }, [errorMessage, setErrorMessage]);

  return (
    <ToastContainer
      autoClose={5000}
      closeOnClick={true}
      draggable={true}
      hideProgressBar={false}
      newestOnTop={false}
      pauseOnFocusLoss={false}
      pauseOnHover={true}
      position={messagePosition}
      rtl={false}
    />
  );
};
