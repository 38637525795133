import React, { useEffect, useState } from "react";

import { PMedium, PSmall } from "@byko/component-typography";

import { NotifyContainer, NotifyInner, ProductImage } from "./styles";

import type { CheckoutLineVariant } from "@byko/lib-api-rest";

const placeholderImageHandler = (e: unknown): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const target = e.target as HTMLImageElement;
  target.src = "https://www.datocms-assets.com/65892/1664464657-placeholder-do-not-remove.jpg";
};

export const AddToCartToast = ({ variant }: { variant: CheckoutLineVariant }): JSX.Element => {
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    setShow(true);
    const timeOutID = setTimeout(() => {
      setShow(false);
    }, 3000);
    return () => {
      clearTimeout(timeOutID);
    };
  }, []);
  return (
    <NotifyContainer className={show ? "show" : ""}>
      <PMedium>Bætt við körfu</PMedium>
      <NotifyInner>
        <ProductImage
          alt={variant.name}
          src={
            variant.firstImage?.image.productList ??
            variant.product.firstImage?.image.productList ??
            "https://www.datocms-assets.com/65892/1664464657-placeholder-do-not-remove.jpg"
          }
          onError={placeholderImageHandler}
        />
        <PSmall>{variant.product.name}</PSmall>
      </NotifyInner>
    </NotifyContainer>
  );
};
