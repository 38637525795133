import { useEffect, useState } from "react";

import { gridTheme } from "@byko/lib-styles";

import type { BreakPoints, WindowSizeProps } from "./interface";

export const useWindowSize = (): WindowSizeProps => {
  const [windowSize, setWindowSize] = useState<WindowSizeProps>({
    width: 0,
    height: 0,
    activeBreakPoint: "lg",
  });

  useEffect(() => {
    const handleResize = (): void => {
      if (typeof window !== "undefined") {
        let activeBreakpoint: BreakPoints = "lg";

        if (window.innerWidth <= gridTheme.breakpoints.md) {
          activeBreakpoint = "md";
        }

        if (window.innerWidth <= gridTheme.breakpoints.sm) {
          activeBreakpoint = "sm";
        }

        if (window.innerWidth <= gridTheme.breakpoints.s) {
          activeBreakpoint = "s";
        }

        if (window.innerWidth <= gridTheme.breakpoints.xs) {
          activeBreakpoint = "xs";
        }

        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
          activeBreakPoint: activeBreakpoint,
        });
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return (): void => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};
