import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const DataIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M19.2 4H21V23H3V4H4.8" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M7 10H17" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M7 14.5555H17" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M7 19H11" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <rect height="4" stroke={iconColor} strokeWidth="2" width="8" x="8" y="1" />
    </svg>
  );
};
