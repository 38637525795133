import React, { useCallback, useState } from "react";

import { PMedium } from "@byko/component-typography";
import { ArrowRightIcons, LongArrowSideIcons } from "@byko/lib-icons";
import { theme } from "@byko/lib-styles";

import { LinkIcon, SubNavigationOption } from "./styles";

import type { NavigationOptionRowProps } from "./interface";

export const SubNavigationOptionRow = ({
  active,
  index,
  setActive,
  label,
  isLink,
  goToPage,
}: NavigationOptionRowProps): JSX.Element => {
  const [showLinkIcon, setShowLinkIcon] = useState<boolean>(false);

  const handleHoverEnter = useCallback(() => {
    setShowLinkIcon(true);
  }, []);

  const handleHoverExit = useCallback(() => {
    setShowLinkIcon(false);
  }, []);

  const setLocalActive = useCallback(() => {
    setActive(index);
  }, [index, setActive]);

  return (
    <SubNavigationOption
      active={active}
      isLink={isLink}
      onClick={isLink ? goToPage : setLocalActive}
      onMouseOut={handleHoverExit}
      onMouseOver={handleHoverEnter}
    >
      {isLink === false && <ArrowRightIcons iconColor={theme.palette.blue.dark} size={24} />}
      <PMedium left={isLink ? 30 : 6} lineHeight="100%" textColor={theme.palette.blue.dark}>
        {label}
      </PMedium>
      {isLink === true && (
        <LinkIcon showLinkIcon={showLinkIcon}>
          <LongArrowSideIcons iconColor={theme.palette.blue.dark} size={24} />
        </LinkIcon>
      )}
    </SubNavigationOption>
  );
};
