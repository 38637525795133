import { sortBy, toPairs } from "lodash";
import React from "react";
import { useProductSearch } from "src/features/search/context";
import styled from "styled-components";

import { Button } from "@byko/component-buttons";
import { theme } from "@byko/lib-styles";

import { facetComponentTypeMap } from "./facets";

import type { FacetTypeRecord } from "src/features/search/types";

const Wrapper = styled.div`
  width: 382px;
  max-width: 100%;
  padding: 32px;
  border: 1px solid ${theme.palette.gray[10]};
  background-color: ${theme.palette.white.main};
  @media screen and (max-width: 895px) {
    width: 100%;
  }
`;

const FacetsBarHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  gap: 80px;
  button {
    p {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 895px) {
    margin-bottom: 8px;
    gap: 16px;
    button {
      p {
        font-size: 14px;
      }
    }
  }
`;

const FacetsBarHeadInner = styled.div`
  display: flex;
  align-items: center;
  .remixicon-icon {
    margin-right: 12px;
  }
`;

const Title = styled.span`
  color: ${theme.palette.blue.main};
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1;
  @media screen and (max-width: 895px) {
    font-size: 16px;
  }
`;

const TitleCount = styled.span`
  margin-left: 4px;
  font-size: 14px;
`;

export interface FiltersProps {
  facets: FacetTypeRecord;
}

const FacetsBar = (): JSX.Element | null => {
  const { facets, activeFilters, clearAllFilters: clearFilters, facetOrder } = useProductSearch();

  if (!facetOrder) {
    return null; // TODO: loader?
  }

  const sortedFacets = sortBy(toPairs(facets), ([key, _]) => facetOrder.indexOf(key));

  return (
    <Wrapper>
      <FacetsBarHead>
        <FacetsBarHeadInner>
          <Title>
            Síur
            {!!activeFilters?.length && <TitleCount>({activeFilters?.length})</TitleCount>}
          </Title>
        </FacetsBarHeadInner>

        {!!activeFilters?.length && (
          <Button buttonColor="whiteButton" label="Hreinsa síu" small={true} stretch={true} onClick={clearFilters} />
        )}
      </FacetsBarHead>

      {sortedFacets.map(([k, facet]) => {
        const FacetComponent = facetComponentTypeMap[facet.type];

        return (
          <FacetComponent
            key={k}
            attr={k}
            // It's impossible to transform intersection to union:
            facet={facet as unknown as never}
          />
        );
      })}
    </Wrapper>
  );
};

export default FacetsBar;
