/* eslint-disable max-len */
import * as React from "react";

import { PMediumBold, PSmall } from "@byko/component-typography";
import { theme } from "@byko/lib-styles";

import { TermsWrapper } from "./styles";

export const TermsText = (): JSX.Element => {
  return (
    <TermsWrapper>
      <PMediumBold textColor={theme.palette.blue.dark}>Viðskiptaskilmálar BYKO</PMediumBold>
      <PMediumBold>Almennir ábyrgðarskilmálar</PMediumBold>
      <PSmall textColor={theme.palette.blue.dark}>
        Allar vörur eru seldar með fullri ábyrgð samkvæmt lögum um neytendakaup nr. 48/2003. Ábyrgð miðast við
        dagsetningu kaupnótu. Kaupnóta er nauðsynleg þegar staðfesta þarf ábyrgð. Ábyrgð nær ekki til eðlilegs slits.
        Ábyrgðin fellur úr gildi ef rekja má bilun til illrar eða rangrar meðferðar.
      </PSmall>
      <PMediumBold>Viðskiptaskilmálar</PMediumBold>
      <PSmall textColor={theme.palette.blue.dark}>
        Varan er eign BYKO þar til kaupverð hefur verið að fullu greitt. Raðgreiðslusamningar, reikningsviðskipti eða
        önnur lánaform afnema ekki eignarétt BYKO fyrr en fullnaðargreiðsla hefur verið innt af hendi. Gjalddagi
        reiknings vegna reikningsviðskipta er 1. dag mánaðar. Eindagi reiknings er 20. dag mánaðar. Dráttarvextir
        reiknast eftir eindaga frá og með gjalddaga reiknings.
      </PSmall>
      <PMediumBold>Skilaréttur </PMediumBold>
      <PSmall textColor={theme.palette.blue.dark}>
        Skilaréttur vöru er allt að 30 dagar gegn framvísun reiknings. Athugið að útsöluvörum er ekki hægt að skila eða
        skipta. Vara er eign BYKO uns hún hefur verið að fullu greidd. BYKO áskilur sér rétt til að taka 20% afföll af
        upphaflegu verði þegar vörum er skilað. Við vöruskil er útgefin inneignarnóta sem gildir í öllum verslunum BYKO
        í 1 ár frá útgáfudegi. Ekki er hægt að skila ljósaperum eða niðurmældri vöru. Ekki er hægt að skila
        sérpöntunarvöru.
      </PSmall>
      <PMediumBold>Lagning gólfefna </PMediumBold>
      <PMediumBold>Nokkur atriði sem þarf að hafa í huga </PMediumBold>
      <PSmall textColor={theme.palette.blue.dark}>
        Að uppgötva galla eftir að gólfefnið hefur verið lagt á gólf getur reynst kaupanda vörunnar dýrkeypt ef hann
        hefur ekki framfylgt faglegu eftirlitshlutverki við lögnina. Kaupandi vörunnar má búast við því að þurfa að bera
        tjónið sjálfur.
      </PSmall>
      <PMediumBold>Parket og þiljur, sjáanlegir gallar</PMediumBold>
      <PSmall textColor={theme.palette.blue.dark}>
        Fylgjast þarf vel með að borð falli vel saman og ekki sé hæðarmismunur milli borða. Skoða þarf hvert borð
        vandlega áður en það er lagt og leggja það til hliðar ef eitthvað er athugavert. Mikilvægt er að lýsing sé góð
        þannig að almennir gallar eða glansmismunur milli einstakra borða fari ekki framhjá lagningarmanni. Ef hafna
        þarf parketborði skal það gert áður en það er lagt. Ganga þarf úr skugga um að rétt vörutegund hafi verið afhent
        áður en lagning hefst.
      </PSmall>
      <PMediumBold>Flísar, stærðir og litir</PMediumBold>
      <PSmall textColor={theme.palette.blue.dark}>
        Flísar eru almennt framleiddar í lotum. Lita- og stærðarmunur er á milli framleiðslulota. Framleiðendur stimpla
        litanúmer á pakkana í samræmi við framleiðslulotu. Mikilvægt er að lýsing sé góð þannig að almennir gallar eins
        og litamunur, stærðarmunur eða aðrir sjáanlegir gallar fari ekki framhjá lagningarmanni. Kaupandi þarf að bera
        saman litanúmerið á kössunum því það er mjög áríðandi að allar keyptar flísar séu úr sömu framleiðslulotu. Ef
        hafna þarf flísum sökum litamunar, stærðar eða annarra athugasemda skal það gert áður en þær eru lagðar. Ganga
        þarf úr skugga um að rétt vörutegund hafi verið afhent áður en lagning hefst. Ef söluhlutur er gallaður ber
        kaupanda að tilkynna BYKO um að hann muni bera gallann fyrir sig án ástæðulauss dráttar frá því að hann varð
        galla var eða mátti verða hans var.
      </PSmall>
      <PMediumBold>Geymslugjöld sérpantana</PMediumBold>
      <PSmall textColor={theme.palette.blue.dark}>
        Geymslugjald reiknast 7 dögum eftir tilkynningu um komu vöru. Geymslugjald á einu EURO bretti: 500 kr á dag
        (Vnr. 095).
      </PSmall>
      <PMediumBold>Gámaleiga</PMediumBold>
      <PSmall textColor={theme.palette.blue.dark}>
        Gámaleiga reiknast 14 dögum eftir tilkynningu um komu gáms. Gámaleiga á 40 feta gámi: 6.130 kr á dag (Vnr. 028).
        Gámaleiga á 20 feta gámi: 4.595 kr á dag (Vnr. 027).
      </PSmall>
      <PMediumBold>Viðskiptaskilmálar vefverslunar BYKO</PMediumBold>
      <PSmall textColor={theme.palette.blue.dark}>
        Þessi skilmáli gildir um sölu á vöru og þjónustu BYKO til neytenda. Skilmálinn, sem staðfestur er með
        staðfestingu á kaupum, er grunnurinn að viðskiptunum. Skilmálinn og aðrar upplýsingar á BYKO.is eru einungis
        fáanlegar á íslensku. Um neytendakaup þessi er fjallað í{" "}
        <a
          href="https://www.althingi.is/dba-bin/unds.pl?txti=/wwwtext/html/lagasofn/131b/2003048.html&leito=neytanda#word1"
          rel="noreferrer"
          target="_blank"
        >
          lögum um neytendakaup
        </a>
        ,{" "}
        <a
          href="https://www.althingi.is/dba-bin/unds.pl?txti=/wwwtext/html/lagasofn/136b/1936007.html&leito=samningsgert%5C0samningsger%25F0%5C0samningsger%25F0a%5C0samningsger%25F0an%5C0samningsger%25F0anna%5C0samningsger%25F0ar%5C0samningsger%25F0ara%5C0samningsger%25F0ari%5C0samningsger%25F0arinnar%5C0samningsger%25F0ast%5C0samningsger%25F0asta%5C0samningsger%25F0astan%5C0samningsger%25F0astar%5C0samningsger%25F0asti%5C0samningsger%25F0astir%5C0samningsger%25F0astra%5C0samningsger%25F0astrar%5C0samningsger%25F0astri%5C0samningsger%25F0asts%5C0samningsger%25F0astur%5C0samningsger%25F0i%5C0samningsger%25F0in%5C0samningsger%25F0ina%5C0samningsger%25F0inni%5C0samningsger%25F0ir%5C0samningsger%25F0irnar%5C0samningsger%25F0ra%5C0samningsger%25F0rar%5C0samningsger%25F0ri%5C0samningsger%25F0s%5C0samningsger%25F0u%5C0samningsger%25F0um%5C0samningsger%25F0unum%5C0samningsger%25F0ur%5C0samningsger%25F0ust%5C0samningsger%25F0ustu%5C0samningsger%25F0ustum#word1"
          rel="noreferrer"
          target="_blank"
        >
          lögum um samningsgerð
        </a>
        ,{" "}
        <a
          href="https://www.althingi.is/dba-bin/unds.pl?txti=/wwwtext/html/lagasofn/131b/2002030.html&leito=vi%25F0skipti#word1"
          rel="noreferrer"
          target="_blank"
        >
          lögum um rafræn viðskipti og aðra rafræna þjónustu
        </a>
        ,{" "}
        <a
          href="https://www.althingi.is/dba-bin/unds.pl?txti=/wwwtext/html/lagasofn/132a/1997071.html&leito=fj%25E1rr%25E1tt%255C0fj%25E1rr%25E1%25F0%255C0fj%25E1rr%25E1%25F0a%255C0fj%25E1rr%25E1%25F0an%255C0fj%25E1rr%25E1%25F0anna%255C0fj%25E1rr%25E1%25F0ar%255C0fj%25E1rr%25E1%25F0ara%255C0fj%25E1rr%25E1%25F0ari%255C0fj%25E1rr%25E1%25F0ast%255C0fj%2525E"
          rel="noreferrer"
          target="_blank"
        >
          lögræðislög
        </a>{" "}
        og{" "}
        <a
          href="https://www.althingi.is/dba-bin/unds.pl?txti=/wwwtext/html/lagasofn/131b/2000077.html&leito=vernd#word1"
          rel="noreferrer"
          target="_blank"
        >
          lögum um persónuvernd og meðferð persónuupplýsinga.
        </a>
        Seljandi er BYKO , Skemmuvegi 2, 201 Kópavogi, kennitala: 460169-3219, virðisaukaskattsnúmer 3394. Byko ehf. er
        skráð í Firmaskrá Íslands. Kaupandi er sá aðili sem er skráður kaupandi á reikning. Kaupandi verður að vera
        orðinn að minnsta kosti 16 ára til að versla á www.BYKO.is Pöntun er bindandi þegar hún er skráð á netþjón
        seljanda. Það gerist þegar kaupandi hefur staðfest pöntun í skrefi. 3. Seljandi er einnig bundinn til að
        afgreiða pöntun kaupanda svo lengi sem hún er í samræmi við vöruúrval og verðlagningu. Allar pantanir þar sem
        grunur um að brögð séu í tafli og/eða þar sem hugbúnaðargalli hefur áhrif eru afturkallaðar. Kaupandi hefur rétt
        á að rifta kaupum samkvæmt lögum um neytendakaup, sjá nánar í grein 11. Seljandi sendir kaupanda staðfestingu
        þegar pöntun er skráð, þó aðeins ef kaupandi hefur skráð netfang sitt við kaupin. Kaupanda er bent á að kynna
        sér gaumgæfilega pöntunarstaðfestingu þegar hún berst. Einnig ætti að ganga úr skugga um að hún sé í samræmi við
        fyrirhugaða pöntun. Frávik frá pöntun og pöntunarstaðfestingu skal túlkað sem nýtt tilboð frá seljanda sem að
        hægt er að afþakka eða samþykkja. Kaupandi hefur einnig rétt til að láta upprunalega pöntun gilda svo lengi sem
        hún er í samræmi við það sem seljandi bauð upp á. Seljandi veitir upplýsingar um vörur eftir bestu vitund hverju
        sinni. Seljandi birtir allar upplýsingar með fyrirvara um bilanir, vírusa, prent-, birtingar- og
        innsláttarvillur í texta, verðum og myndum. Vöruúrval getur verið mismunandi milli vefverslunar og verslana.
        Ennfremur áskilur seljandi sér rétt til að aflýsa í heild eða að hluta til pöntun kaupanda ef varan er uppseld.
        Undir þeim kringumstæðum fær kaupandi tilkynningu ásamt upplýsingum um hvað gæti mögulega hentað í staðinn.
        Kaupandi fær þá möguleika á að samþykkja þá tillögu eða aflýsa pöntun í heild sinni. Verð eru stöðugt að
        breytast hjá seljanda vegna samkeppni og verðbreytinga birgja. Verðhækkanir sem verða eftir pöntun
        viðskiptavinar eru ekki afturkræfar. Það verð gildir sem var í gildi þegar pöntun var gerð og kemur fram á
        pöntunarstaðfestingu. Heildarkostnaður er tekinn fram áður en kaupandi staðfestir pöntun endanlega(skref 3 í
        vefverslun). Þar er tekinn fram allur kostnaður við pöntun s.s. þjónustu, sendingu o.s.frv. Aðeins sértilfelli
        geta haft með sér aukakostnað eftir pöntunarstaðfestingu. Sem dæmi má nefna eru bilanir, vírusar, prent-,
        birtingar- og innsláttarvillur í texta, verðum og myndum. Hægt er að inna greiðslu af hendi með bankamillifærslu
        eða greiðslukorti. Ef greitt er með greiðslukorti er upphæðin skuldfærð við afgreiðslu pöntunarinnar af lager.
        Ef greiðsla berst ekki, áskilur seljandi sér rétt til að aflýsa pöntuninni. Ef greitt er með bankamillifærslu
        eru vörur fráteknar á lager þar til greiðsla berst. Vörur eru fráteknar í 1 dag með tilliti til frídaga, ef
        millifærsla berst ekki innan þess tíma er pöntun aflýst. Afhending telst vera innan eðlilegra tímamarka frá
        útgáfudegi reiknings. Tekið er fram í kaupferlinu hverju má búast við miðað við valda sendingarleið. Seljandi
        afhendir vörur einungis innan Íslands. Ef afhendingu seinkar mun seljandi tilkynna það til kaupanda ásamt
        upplýsingum um hvenær pöntunin verður tilbúin til afhendingar eða bjóða staðgengilsvörur ef varan er uppseld.
        Allar pantanir yfir kr. 20.000 krefst undirskriftar reikningseiganda gegn framvísun persónuskilríkja þ.e. passa
        eða ökuskírteinis. Pantanir undir kr. 20.000 eru afhentar aðila á afhendingarstað. Ef að enginn er við er pöntun
        stungið inn um bréfalúgu ef kostur er. Allar skemmdir á vörum af hendi flutningsaðila eru á þeirra ábyrgð og að
        fullu bætt gagnvart viðskiptavini. Skemmdir eftir að vara hefur verið afhent er á ábyrgð kaupanda. Ef að
        afhending vöru í heimakstri reynist árangurslaus er hægt að óska eftir nýjum afhendingartíma. Ef að afhending
        reynist árangurslaus í annað skiptið er vara tekin í geymslu þar til hún er sótt. Geymslutími miðast við 2 vikur
        og eftir það áskilur BYKO sér rétt til að rifta kaupum. Ef aðstæður krefjast þess að varan sé geymd er krafist
        mánaðarlegs geymslugjalds sem nemur um 5% af upphæð vörunnar. Eftir að kaupandi hefur móttekið pöntunina þarf
        hann að kanna hvort hún sé í samræmi við pöntunarstaðfestinguna, hvort eitthvað hafi skemmst í flutningi og að
        allar vörur séu samkvæmt vörulýsingu og ógallaðar. Skilmálar þessir gera ráð fyrir að kaupandi hafi lesið og
        kynnt sér leiðbeiningar og/eða handbók sem fylgja keyptri vöru við afhendingu. Eðlilegur athugunartími
        viðskiptavinar telst vera innan 30 daga. Eftir 30 daga áskilur seljandi sér rétt til að sannreyna staðhæfingu
        kaupanda innan eðlilegra tímamarka áður en leyst er úr umkvörtunarefni kaupanda. Í flestum tilfellum felur það í
        sér athugun hjá viðurkenndum þjónustuaðila. Ef að varan er gölluð eða það vantar eitthvað í vöruna er seljanda
        skylt að bjóða kaupanda viðgerð, nýja vöru, afslátt eða afturköllun kaupa. Það fer eftir atvikum hvaða leið er
        valin hverju sinni. Tilkynning á galla verður að berast munnlega eða skriflega. Mælt er með að tilkynningin
        berist innan 30 daga frá því galli uppgötvast. Vegna skráningargildis er mælt með að kaupandi sendi tölvupóst um
        gallann og geymi afrit. Seljandi sendir staðfestingu til baka um móttöku tölvupóstsins. Réttur til að fá galla
        bættan er í 2 ár. Réttur til að kvarta er 5 ár, þó aðeins á vörum sem eru með viðurkenndan meðallíftíma umfram 2
        ár. Seljandi áskilur sér rétt til að sannreyna að varan sé gölluð, þó innan eðlilegra tímamarka.
      </PSmall>
    </TermsWrapper>
  );
};
