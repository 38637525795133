import styled from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";

export const BottomFooter = styled.div`
  display: flex;
  width: 100%;
  max-width: ${gridTheme.container.minWidth.lg}px;
  height: 80px;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  color: ${theme.palette.blue.main};
  gap: 100px;
  @media screen and (max-width: ${gridTheme.container.maxWidth.md}px) {
    gap: 50px;
  }
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    height: 120px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  min-width: 400px;
  justify-content: space-between;
  @media screen and (max-width: ${gridTheme.container.maxWidth.sm}px) {
    width: fit-content;
    min-width: unset;
  }
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    display: none;
  }
`;

export const InfoText = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 18px;
  @media screen and (max-width: ${gridTheme.container.maxWidth.sm}px) {
    font-size: 14px;
  }
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    flex-direction: column;
    font-size: 16px;
    gap: 20px;
    text-align: left;
  }
`;

export const InfoParagraph = styled.p`
  &::selection {
    background-color: ${theme.palette.yellow.main};
  }
`;

export const Location = styled.a`
  display: flex;
  width: 100%;
  justify-content: space-around;
  color: ${theme.palette.blue.main};
`;

export const CompanyDetail = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;

export const Divider = styled.div`
  display: flex;
  width: 1px;
  height: 20px;
  justify-content: center;
  background-color: ${theme.palette.gray[30]};
  @media screen and (min-width: ${gridTheme.container.maxWidth.s}px) {
    display: none;
  }
`;
