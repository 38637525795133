import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const MushroomIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M6.89722 13.5135L5.95685 18.2154C5.46179 20.6906 7.35498 22.9999 9.87915 22.9999H14.1207C16.6449 22.9999 18.5381 20.6906 18.043 18.2155L17.105 13.5252"
        stroke={iconColor}
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        // eslint-disable-next-line max-len
        d="M13.3743 1.11389C13.1364 1.69659 13 2.33179 13 3C13 5.76141 15.2386 8 18 8C19.405 8 20.6702 7.41644 21.5786 6.48334"
        stroke={iconColor}
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        // eslint-disable-next-line max-len
        d="M8.5 11C9.88071 11 11 9.88071 11 8.5C11 7.11929 9.88071 6 8.5 6C7.11929 6 6 7.11929 6 8.5C6 9.88071 7.11929 11 8.5 11Z"
        stroke={iconColor}
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        // eslint-disable-next-line max-len
        d="M1 10C1 7.0625 5.92487 1 12 1C18.0751 1 23 7.0625 23 10C23 12.125 18.0751 14 12 14C5.92487 14 1 12.0625 1 10Z"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
};

export default MushroomIcons;
