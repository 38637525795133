/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const PresentIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 25" width={size} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_337_11512)">
        <path
          d="M4 4.00031C4.02271 3.31531 4.31631 2.66732 4.81634 2.1986C5.31637 1.72988 5.98197 1.47874 6.667 1.50031C10.611 1.50031 12 6.50031 12 6.50031H6.667C5.98197 6.52188 5.31637 6.27073 4.81634 5.80201C4.31631 5.33329 4.02271 4.6853 4 4.00031V4.00031Z"
          stroke={iconColor}
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M17.333 6.50031H12C12 6.50031 13.389 1.50031 17.333 1.50031C18.018 1.47874 18.6836 1.72988 19.1837 2.1986C19.6837 2.66732 19.9773 3.31531 20 4.00031C19.9773 4.6853 19.6837 5.33329 19.1837 5.80201C18.6836 6.27073 18.018 6.52188 17.333 6.50031Z"
          stroke={iconColor}
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M21 13.5V21.5C21 22.0304 20.7893 22.5391 20.4142 22.9142C20.0391 23.2893 19.5304 23.5 19 23.5H5C4.46957 23.5 3.96086 23.2893 3.58579 22.9142C3.21071 22.5391 3 22.0304 3 21.5V13.5"
          stroke={iconColor}
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M23 6.5H1V10.5H23V6.5Z"
          stroke={iconColor}
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path d="M12 6.5V23.5" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      </g>
      <defs>
        <clipPath id="clip0_337_11512">
          <rect fill="white" height={size} transform="translate(0 0.5)" width={size} />
        </clipPath>
      </defs>
    </svg>
  );
};
