import type { FacetTreeItem } from "src/features/search/api";

export const flattenFacetTreeValues = (facetTreeValues: FacetTreeItem[]): FacetTreeItem[] => {
  return facetTreeValues
    ? facetTreeValues.reduce(
        (result: FacetTreeItem[], item: FacetTreeItem) => [
          ...result,
          item,
          ...flattenFacetTreeValues(item?.children ?? []),
        ],
        [],
      )
    : [];
};
