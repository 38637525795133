import fromPairs from "lodash/fromPairs";

import { ensureList } from "./ensure-list";

import type { CheckoutFragment } from "../../../generated/graphql";
import type { Maybe } from "@byko/types-utils";
import type { Dictionary } from "lodash";

type Tuple = [string, number];

export const getCartQuantityMap = (cart?: Maybe<CheckoutFragment>, variantIds?: string[]): Dictionary<number> => {
  let pairs = ensureList(cart?.lines).map<Tuple>((line) => [line.variant.id, line.quantity]);

  if (variantIds != null) {
    pairs = pairs.filter(([id]) => variantIds.includes(id));
  }

  return fromPairs(pairs);
};
