/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const DeactivateIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M19.8 4.19995L4.20001 19.8" stroke={iconColor} strokeMiterlimit="10" strokeWidth="2" />
      <path
        d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
};
