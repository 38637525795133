export const CHECKOUT_ERRORS = ["NOT_FOUND", "QUANTITY_GREATER_THAN_LIMIT", "INSUFFICIENT_STOCK", "UNKNOWN"] as const;
export type CHECKOUT_ERROR = typeof CHECKOUT_ERRORS[number];

export const checkoutErrorCodeMap: Record<CHECKOUT_ERROR, string> = {
  NOT_FOUND: "Fann ekki vöru",
  QUANTITY_GREATER_THAN_LIMIT: "Ekki nægilegt magn af þessari vöru til á lager",
  INSUFFICIENT_STOCK: "Ekki nægilegt magn af þessari vöru til á lager" as const,
  UNKNOWN: "Óþekkt villa" as const,
} as const;

export const POLL_CHECKOUT = 1000 * 60 * 15;

export const LOCAL_KEY = "myanonymousCartToken";
