import styled from "styled-components";

import { H6 } from "@byko/component-typography";
import { gridTheme, theme } from "@byko/lib-styles";

export const FooterContainer = styled.div`
  display: grid;
  width: 100%;
  max-width: ${gridTheme.container.minWidth.lg}px;
  height: 352px;
  justify-content: space-between;
  padding: 80px 0;
  margin: 0 auto;
  gap: 20px;
  grid-template-areas:
    "logo openinghours social happyscale"
    "logo openinghours links happyscale";
  transition: all 0.5s;
  p {
    color: ${theme.palette.white.main};
  }
  @media screen and (max-width: ${gridTheme.container.maxWidth.sm}px) {
    height: unset;
    align-items: flex-start;
    justify-content: center;
    padding: 48px 5%;
    grid-template-areas:
      "logo"
      "social"
      "openinghours"
      "links"
      "happyscale";
    row-gap: 57px;
  }
`;

export const LogoWrapper = styled.a`
  height: fit-content;
  margin: 0 auto;
  grid-area: logo;
`;

export const LargeLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  grid-area: openinghours;
  ${H6} {
    color: ${theme.palette.white.main};
  }
`;

export const LargeLinks = styled.a`
  display: flex;
  align-items: center;
  gap: 27px;
  h6 {
    font-weight: 400;
  }
  @media screen and (max-width: ${gridTheme.container.maxWidth.xs}px) {
    h6 {
      font-size: 16px;
    }
  }
  svg {
    margin-bottom: 8px;
  }
`;

export const SocialMediaIconWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
  grid-area: social;
`;

export const SocialMediaIcon = styled.a``;

export const FooterLink = styled.span`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  color: inherit;
  gap: 20px;
  grid-area: links;
`;

export const HappyScaleWrapper = styled.div`
  margin: auto;
  grid-area: happyscale;
`;
