import * as React from "react";

import { SecondaryButton } from "@byko/component-buttons";
import { PageContainer } from "@byko/component-page-container";
import { H6 } from "@byko/component-typography";
import {
  BykoMainLogo,
  ClockWhiteFilledDecorative,
  FacebookSimpleIcons,
  InstagramLineIcons,
  MobileWhiteFilledDecorative,
  SendMessagesBlueFilledDecorative,
  YoutubeIcons,
} from "@byko/lib-icons";
import { HappyScale } from "@byko/lib-images";
import { gridTheme, theme } from "@byko/lib-styles";
import { useWindowSize } from "@byko/lib-styles";
import { datoQueries } from "@byko/lib-dato";

import {
  FooterContainer,
  FooterLink,
  HappyScaleWrapper,
  LargeLinkWrapper,
  LargeLinks,
  LogoWrapper,
  SocialMediaIcon,
  SocialMediaIconWrapper,
} from "./styles";
import { FooterBottom } from "./footer-bottom";

export const Footer = (): JSX.Element => {
  const { width } = useWindowSize();
  const { data } = datoQueries.useGetFooterText();

  // format phone number from 1234567 to 123 4567
  const formattedPhoneNumber = data?.telephoneNumber?.replace(/(\d{3})(\d{4})/, "$1 $2");
  return (
    <>
      <PageContainer backgroundColor={theme.palette.blue.main} flexDirection="row" offsetNav={false}>
        <FooterContainer>
          <LogoWrapper href="https://byko.is/" rel="noreferrer">
            <BykoMainLogo width={width > gridTheme.breakpoints.sm ? 136 : 220} />
          </LogoWrapper>

          <LargeLinkWrapper>
            <LargeLinks href="/thjonusta/um-byko/verslanir">
              <ClockWhiteFilledDecorative size={width > gridTheme.breakpoints.s ? 46 : 30} />
              <H6>{data?.labelForOpeningHoursAndStoresLink}</H6>
            </LargeLinks>
            <LargeLinks href={`mailto:${data?.email ?? "byko@byko.is"}`} target="_blank">
              <SendMessagesBlueFilledDecorative size={width > gridTheme.breakpoints.s ? 46 : 30} />
              <H6>{data?.email ?? "byko@byko.is"}</H6>
            </LargeLinks>
            <LargeLinks href={`tel:+354${data?.telephoneNumber ?? "5154000"}`} target="_blank">
              <MobileWhiteFilledDecorative size={width > gridTheme.breakpoints.s ? 46 : 30} />
              <H6>{formattedPhoneNumber}</H6>
            </LargeLinks>
          </LargeLinkWrapper>

          <>
            <SocialMediaIconWrapper>
              <SocialMediaIcon href={data?.instagramLink ?? ""} target="_blank">
                <InstagramLineIcons iconColor={theme.palette.white.main} size={24} />
              </SocialMediaIcon>
              <SocialMediaIcon href={data?.facebookLink ?? ""} target="_blank">
                <FacebookSimpleIcons iconColor={theme.palette.white.main} size={24} />
              </SocialMediaIcon>
              <SocialMediaIcon href={data?.youtubeLink ?? ""} target="_blank">
                <YoutubeIcons iconColor={theme.palette.white.main} size={24} />
              </SocialMediaIcon>
            </SocialMediaIconWrapper>
            <FooterLink>
              <a href="https://byko.is/skilmalar">
                <SecondaryButton label={data?.labelForTermsLink ?? "Skilmálar"} />
              </a>
            </FooterLink>
          </>
          <HappyScaleWrapper>
            <HappyScale />
          </HappyScaleWrapper>
        </FooterContainer>
      </PageContainer>

      <FooterBottom />
    </>
  );
};
