import { useRecoilValue, useSetRecoilState } from "@byko/lib-recoil";

import { Store } from "../store/atom";

import type { CheckoutFragment } from "../../../generated/graphql";
import type { Line, LinesNonNull } from "../store/checkout-lines";
import type { SetterOrUpdater } from "@byko/lib-recoil";
import type { Maybe } from "@byko/types-utils";
import type { Checkout } from "@byko/lib-api-rest";
/** TODO: Fix GQL id to number id */

export const useSetCheckoutValue = (): SetterOrUpdater<Maybe<CheckoutFragment | Checkout>> =>
  useSetRecoilState(Store.checkout);

export const useCheckoutLines = (): LinesNonNull => {
  return useRecoilValue(Store.checkoutLines);
};

export const useTotalCartCount = (): number => useRecoilValue(Store.totalCartCount);

export const useCartItemCount = (value: number | string | null): number =>
  useRecoilValue(Store.cartItemCount(`${value ?? 0}`));

export const useCartItem = (value: number | null | string): Line | null =>
  useRecoilValue(Store.cartItem(`${value ?? 0}`));
