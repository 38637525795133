import styled from "styled-components";

import { PSmall } from "@byko/component-typography";
import { theme } from "@byko/lib-styles";
import { scrollStyle } from "@byko/lib-styles";

import type { ProductDetailContainerStyleProps } from "./interface";

export const ProductDetailContainer = styled.div<ProductDetailContainerStyleProps>`
  position: absolute;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${theme.palette.gray[10]};
  background-color: ${theme.palette.white.main};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  clip-path: inset(-10px -20px -20px 0px);
  inset: 8px 8px 8px 8px;
  opacity: ${(props): string => (props.show ? "1" : "0")};
  pointer-events: ${(props): string => (props.show ? "initial" : "none")};
  transform: ${(props): string => (props.show ? "translateY(0px)" : "translateY(16px)")};
  transition: 200ms;
  transition-timing-function: ease-out;
`;

export const VariantSelectionOptions = styled.div`
  width: 100%;
  .select-container {
    display: flex;
    flex-direction: column-reverse;
    border: 1px solid ${theme.palette.gray[10]};
    &.first {
      z-index: 102;
    }
    &.second {
      z-index: 103;
    }
    .menu-wrapper {
      width: 95%;
      margin-right: auto;
      margin-bottom: 50px;
      margin-left: auto;
      &.open {
        ul {
          border: 1px solid ${theme.palette.gray[10]};
          box-shadow: none;
        }
      }
    }
  }
`;

export const DetailButtonWrapper = styled.div`
  .button-container {
    border-right: 1px solid ${theme.palette.white.main};
    border-left: 1px solid ${theme.palette.white.main};
  }
  .quantity-input {
    border: none;
    border-top: 1px solid ${theme.palette.gray[10]};
  }
  .add-to-cart-button {
    border: none;
  }
`;

export const ProductInfo = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  transform: translate3d(8px, -8px, 0px);
`;

export const ProductHead = styled.div`
  display: grid;
  grid-template-columns: auto 40px;
`;

export const ProductDescription = styled.div`
  padding-top: 24px;
  padding-bottom: 16px;
  padding-inline: 24px;
`;

export const LoadingWrapper = styled.div`
  position: relative;
  width: 64px;
  height: 33px;
  pointer-events: none;
  transform: translate3d(-16px, -24px, 0px);
`;

export const VariantPrice = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 16px;
  padding-inline: 24px;
`;

export const FullDescription = styled(PSmall)`
  max-height: 120px;
  padding-top: 20px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${theme.palette.gray[10]};
  overflow-y: auto;
`;
export const DetailList = styled(PSmall)`
  display: flex;
  padding-top: 16px;
  gap: 8px;

  li {
    max-height: 100px;
    list-style: square;
    overflow-y: auto;
    ${scrollStyle({ color: "dark-blue" })}
  }
`;

export const ButtonLoadingWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffffff;
  background-color: ${theme.palette.blue.main};
  gap: 10px;
  text-align: left;
`;
