/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { httpLink } from "@trpc/client/links/httpLink";

import { getBykoConfig } from "@byko/lib-utils";

import { trpc } from "./base";

import type { AppRouter } from "@byko/lib-api-dato-ssr";
import type { TRPCClient } from "@trpc/react";
import type { QueryClient } from "react-query";

interface SlugProps<T> {
  slug: string;
  secret?: string | undefined;
  initialData?: T | undefined;
  parentPage?: undefined | boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const usePageBySlug = <T>({ slug, secret, initialData, parentPage = true }: SlugProps<T>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return trpc.useQuery(["getPageBySlug", { slug, secret, parentPage }], {
    initialData: initialData ?? undefined,
  });
};

export const getPageBySlug =
  (
    _url: string,
    secret?: string,
    parentPage = true,
    props: { queryClient?: QueryClient; trpcClient: TRPCClient<AppRouter> } | undefined = undefined,
  ) =>
  (slug: string) => {
    const fullurl = getBykoConfig("CMS_API_URL");

    return (
      props?.trpcClient ??
      trpc.createClient({
        links: [httpLink({ url: fullurl })],
      })
    ).query("getPageBySlug", { slug, secret, parentPage });
  };
