import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const LongArrowSideIcons: BykoReactIconComponentType = ({ size, iconColor = "#00416A", ...props }) => {
  return (
    <svg
      className="byko-icon"
      fill="none"
      height={size}
      stroke={iconColor}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g strokeMiterlimit={10} strokeWidth={2}>
        <path d="M5 18.5L18.5 5" />
        <path d="M8.5 5h10v10" strokeLinecap="square" />
      </g>
    </svg>
  );
};
