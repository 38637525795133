import React, { useCallback, useEffect, useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";

import { IconButton } from "@byko/component-buttons";
import { CancelLargeIcons } from "@byko/lib-icons";
import { useRecoilState, useRecoilValue } from "@byko/lib-recoil";

import { backdropBlurState, blockDrawerClickOutsideState, drawerState } from "./store";
import { ChildrenContent, CloseButton, DrawerContainer, MainContent, Overlay } from "./styles";

import type { DrawerProps } from "./interface";

export const Drawer = ({ children, noCloseButton = false }: DrawerProps): JSX.Element => {
  const drawerRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useRecoilState<boolean>(drawerState);
  const blockClosing = useRecoilValue<boolean>(blockDrawerClickOutsideState);
  const showBackdrop = useRecoilValue<boolean>(backdropBlurState);

  useEffect(() => {
    document.body.classList.toggle("no-scroll", open);
  }, [open]);

  const toggleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    if (blockClosing) {
      return;
    }
    setOpen(false);
  }, [blockClosing, setOpen]);

  useOnClickOutside(drawerRef, handleClose);

  return (
    <>
      {showBackdrop && <Overlay active={open || blockClosing} />}
      <DrawerContainer ref={drawerRef} open={open || blockClosing}>
        <MainContent>
          {!noCloseButton && (
            <CloseButton>
              <IconButton ariaLabel="Loka" icon={CancelLargeIcons} small={true} onClick={toggleOpen} />
            </CloseButton>
          )}
          <ChildrenContent>{children}</ChildrenContent>
        </MainContent>
      </DrawerContainer>
    </>
  );
};
