/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const FacebookSimpleIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 25 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.53153 22.9998L9.5 12.9998H5.5V8.99976H9.5V6.49976C9.5 2.78836 11.7983 0.999756 15.1091 0.999756C16.6951 0.999756 18.0581 1.11783 18.4553 1.17061V5.04924L16.1591 5.05028C14.3584 5.05028 14.0098 5.9059 14.0098 7.16147V8.99976H19.25L17.25 12.9998H14.0098V22.9998H9.53153Z"
        fill={iconColor}
      />
    </svg>
  );
};
