import { isSSR } from "@byko/types-utils";

export interface Config {
  ENV: string;
  SALEOR_GQL_URL: string;
  SALEOR_BASE_HOST: string;
  CMS_API_URL: string;
  DATO_GRAPHQL_URL: string;
  IMAGES_API_URL: string;
  ALGOLIA_APP_ID: string;
  ALGOLIA_API_KEY: string;
  ALGOLIA_ENV_PREFIX: string;
  DATO_TOKEN_CMS: string;
  SITEIMPROV_KEY: string;
  KLAVIYO_PUBLIC_API_KEY: string;
  BASE_URL: string;
  SALT_PAY_TOKEN: string;
  SALT_PAY_URL: string;
  NEXT_PUBLIC_SENTRY_DSN: string;
  NEXT_PUBLIC_SENTRY_ENV: string;
}
declare global {
  interface Window {
    __APP__CONFIG__: Config;
  }
}

export const getBykoConfig = (key: keyof Config): string => {
  if (isSSR()) {
    return process.env[key] as string;
  }
  return window.__APP__CONFIG__[key];
};
