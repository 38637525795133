/* eslint-disable max-len */
import * as React from "react";
import { BykoReactIconComponentType } from "@byko/lib-core";

export const ByggingakraniIllustration: BykoReactIconComponentType = ({ size = 200 }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 662 611" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M469.85 100.77H441.29V143.83H469.85V100.77Z" fill="#0067A5" />
      <path
        d="M116.03 221.51H83.5901C80.7679 221.51 78.4801 223.798 78.4801 226.62V239.66C78.4801 242.482 80.7679 244.77 83.5901 244.77H116.03C118.852 244.77 121.14 242.482 121.14 239.66V226.62C121.14 223.798 118.852 221.51 116.03 221.51Z"
        fill="#D9D9D9"
      />
      <path
        d="M160.51 221.51H128.07C125.248 221.51 122.96 223.798 122.96 226.62V239.66C122.96 242.482 125.248 244.77 128.07 244.77H160.51C163.332 244.77 165.62 242.482 165.62 239.66V226.62C165.62 223.798 163.332 221.51 160.51 221.51Z"
        fill="#D9D9D9"
      />
      <path
        d="M204.99 221.51H172.55C169.728 221.51 167.44 223.798 167.44 226.62V239.66C167.44 242.482 169.728 244.77 172.55 244.77H204.99C207.812 244.77 210.1 242.482 210.1 239.66V226.62C210.1 223.798 207.812 221.51 204.99 221.51Z"
        fill="#D9D9D9"
      />
      <path
        d="M249.47 221.51H217.03C214.208 221.51 211.92 223.798 211.92 226.62V239.66C211.92 242.482 214.208 244.77 217.03 244.77H249.47C252.292 244.77 254.58 242.482 254.58 239.66V226.62C254.58 223.798 252.292 221.51 249.47 221.51Z"
        fill="#D9D9D9"
      />
      <path
        d="M138.27 246.57H105.83C103.008 246.57 100.72 248.858 100.72 251.68V264.72C100.72 267.542 103.008 269.83 105.83 269.83H138.27C141.092 269.83 143.38 267.542 143.38 264.72V251.68C143.38 248.858 141.092 246.57 138.27 246.57Z"
        fill="#D9D9D9"
      />
      <path
        d="M182.75 246.57H150.31C147.488 246.57 145.2 248.858 145.2 251.68V264.72C145.2 267.542 147.488 269.83 150.31 269.83H182.75C185.572 269.83 187.86 267.542 187.86 264.72V251.68C187.86 248.858 185.572 246.57 182.75 246.57Z"
        fill="#D9D9D9"
      />
      <path
        d="M227.23 246.57H194.79C191.968 246.57 189.68 248.858 189.68 251.68V264.72C189.68 267.542 191.968 269.83 194.79 269.83H227.23C230.052 269.83 232.34 267.542 232.34 264.72V251.68C232.34 248.858 230.052 246.57 227.23 246.57Z"
        fill="#D9D9D9"
      />
      <path
        d="M271.71 246.57H239.27C236.448 246.57 234.16 248.858 234.16 251.68V264.72C234.16 267.542 236.448 269.83 239.27 269.83H271.71C274.532 269.83 276.82 267.542 276.82 264.72V251.68C276.82 248.858 274.532 246.57 271.71 246.57Z"
        fill="#D9D9D9"
      />
      <path
        d="M116.03 271.62H83.5901C80.7679 271.62 78.4801 273.908 78.4801 276.73V289.77C78.4801 292.592 80.7679 294.88 83.5901 294.88H116.03C118.852 294.88 121.14 292.592 121.14 289.77V276.73C121.14 273.908 118.852 271.62 116.03 271.62Z"
        fill="#D9D9D9"
      />
      <path
        d="M160.51 271.62H128.07C125.248 271.62 122.96 273.908 122.96 276.73V289.77C122.96 292.592 125.248 294.88 128.07 294.88H160.51C163.332 294.88 165.62 292.592 165.62 289.77V276.73C165.62 273.908 163.332 271.62 160.51 271.62Z"
        fill="#D9D9D9"
      />
      <path
        d="M204.99 271.62H172.55C169.728 271.62 167.44 273.908 167.44 276.73V289.77C167.44 292.592 169.728 294.88 172.55 294.88H204.99C207.812 294.88 210.1 292.592 210.1 289.77V276.73C210.1 273.908 207.812 271.62 204.99 271.62Z"
        fill="#D9D9D9"
      />
      <path
        d="M249.47 271.62H217.03C214.208 271.62 211.92 273.908 211.92 276.73V289.77C211.92 292.592 214.208 294.88 217.03 294.88H249.47C252.292 294.88 254.58 292.592 254.58 289.77V276.73C254.58 273.908 252.292 271.62 249.47 271.62Z"
        fill="#D9D9D9"
      />
      <path
        d="M138.27 296.68H105.83C103.008 296.68 100.72 298.968 100.72 301.79V314.83C100.72 317.652 103.008 319.94 105.83 319.94H138.27C141.092 319.94 143.38 317.652 143.38 314.83V301.79C143.38 298.968 141.092 296.68 138.27 296.68Z"
        fill="#D9D9D9"
      />
      <path
        d="M182.75 296.68H150.31C147.488 296.68 145.2 298.968 145.2 301.79V314.83C145.2 317.652 147.488 319.94 150.31 319.94H182.75C185.572 319.94 187.86 317.652 187.86 314.83V301.79C187.86 298.968 185.572 296.68 182.75 296.68Z"
        fill="#D9D9D9"
      />
      <path
        d="M116.03 321.77H83.5901C80.7679 321.77 78.4801 324.058 78.4801 326.88V339.92C78.4801 342.742 80.7679 345.03 83.5901 345.03H116.03C118.852 345.03 121.14 342.742 121.14 339.92V326.88C121.14 324.058 118.852 321.77 116.03 321.77Z"
        fill="#D9D9D9"
      />
      <path
        d="M160.51 321.77H128.07C125.248 321.77 122.96 324.058 122.96 326.88V339.92C122.96 342.742 125.248 345.03 128.07 345.03H160.51C163.332 345.03 165.62 342.742 165.62 339.92V326.88C165.62 324.058 163.332 321.77 160.51 321.77Z"
        fill="#D9D9D9"
      />
      <path
        d="M204.99 321.77H172.55C169.728 321.77 167.44 324.058 167.44 326.88V339.92C167.44 342.742 169.728 345.03 172.55 345.03H204.99C207.812 345.03 210.1 342.742 210.1 339.92V326.88C210.1 324.058 207.812 321.77 204.99 321.77Z"
        fill="#D9D9D9"
      />
      <path
        d="M138.27 346.82H105.83C103.008 346.82 100.72 349.108 100.72 351.93V364.97C100.72 367.792 103.008 370.08 105.83 370.08H138.27C141.092 370.08 143.38 367.792 143.38 364.97V351.93C143.38 349.108 141.092 346.82 138.27 346.82Z"
        fill="#D9D9D9"
      />
      <path
        d="M182.75 346.82H150.31C147.488 346.82 145.2 349.108 145.2 351.93V364.97C145.2 367.792 147.488 370.08 150.31 370.08H182.75C185.572 370.08 187.86 367.792 187.86 364.97V351.93C187.86 349.108 185.572 346.82 182.75 346.82Z"
        fill="#D9D9D9"
      />
      <path
        d="M116.03 371.88H83.5901C80.7679 371.88 78.4801 374.168 78.4801 376.99V390.03C78.4801 392.852 80.7679 395.14 83.5901 395.14H116.03C118.852 395.14 121.14 392.852 121.14 390.03V376.99C121.14 374.168 118.852 371.88 116.03 371.88Z"
        fill="#D9D9D9"
      />
      <path
        d="M204.99 371.88H172.55C169.728 371.88 167.44 374.168 167.44 376.99V390.03C167.44 392.852 169.728 395.14 172.55 395.14H204.99C207.812 395.14 210.1 392.852 210.1 390.03V376.99C210.1 374.168 207.812 371.88 204.99 371.88Z"
        fill="#D9D9D9"
      />
      <path
        d="M158.59 374.63C159.725 374.63 160.814 375.081 161.617 375.884C162.419 376.686 162.87 377.775 162.87 378.91V388.1C162.87 389.236 162.42 390.326 161.617 391.13C160.815 391.934 159.726 392.387 158.59 392.39H130C128.863 392.387 127.774 391.935 126.97 391.131C126.166 390.327 125.713 389.237 125.71 388.1V378.91C125.713 377.774 126.166 376.685 126.97 375.883C127.774 375.081 128.864 374.63 130 374.63H158.59ZM158.59 371.88H130C128.144 371.88 126.363 372.618 125.05 373.93C123.738 375.243 123 377.023 123 378.88V388.07C123 389.927 123.738 391.707 125.05 393.02C126.363 394.333 128.144 395.07 130 395.07H158.59C160.447 395.07 162.227 394.333 163.54 393.02C164.853 391.707 165.59 389.927 165.59 388.07V378.88C165.59 377.023 164.853 375.243 163.54 373.93C162.227 372.618 160.447 371.88 158.59 371.88Z"
        fill="#003C71"
      />
      <path
        d="M138.27 396.93H105.83C103.008 396.93 100.72 399.218 100.72 402.04V415.08C100.72 417.902 103.008 420.19 105.83 420.19H138.27C141.092 420.19 143.38 417.902 143.38 415.08V402.04C143.38 399.218 141.092 396.93 138.27 396.93Z"
        fill="#D9D9D9"
      />
      <path
        d="M182.75 396.93H150.31C147.488 396.93 145.2 399.218 145.2 402.04V415.08C145.2 417.902 147.488 420.19 150.31 420.19H182.75C185.572 420.19 187.86 417.902 187.86 415.08V402.04C187.86 399.218 185.572 396.93 182.75 396.93Z"
        fill="#D9D9D9"
      />
      <path
        d="M259.94 418.57C250.068 417.099 240.437 414.318 231.3 410.3C244.3 402.19 251.72 392.3 248.3 374.77C242.89 347.22 282.01 368.69 282.01 368.69C282.01 368.69 277.01 400.45 302.14 413.91L302.86 414.29C293.41 418 278.67 421.57 259.94 418.57Z"
        fill="#FDC498"
      />
      <path
        d="M291.72 366C291.72 366 290.66 395.47 266.17 395C239.39 394.48 239.17 365.55 239.17 365.55L291.72 366Z"
        fill="#003C71"
      />
      <path
        d="M290.13 347C290.13 347 302.42 345 302.82 353.74C303.06 359.13 295.24 362.39 290.61 360.9C285.98 359.41 290.13 347 290.13 347Z"
        fill="#FDC498"
      />
      <path
        d="M239.65 346C239.65 346 230.14 342.54 228.51 349.79C227.32 355.05 234.14 359.79 238.98 359.29C243.82 358.79 239.65 346 239.65 346Z"
        fill="#FDC498"
      />
      <path
        d="M295.62 336.17C295.62 336.17 299.4 385.3 268.14 387.29C236.88 389.28 234.76 348.13 235.03 337.13L295.62 336.17Z"
        fill="#FDC498"
      />
      <path
        d="M253.8 352.64C254.651 352.64 255.34 351.951 255.34 351.1C255.34 350.249 254.651 349.56 253.8 349.56C252.95 349.56 252.26 350.249 252.26 351.1C252.26 351.951 252.95 352.64 253.8 352.64Z"
        fill="#003C71"
      />
      <path
        d="M275.98 353.76C276.831 353.76 277.52 353.071 277.52 352.22C277.52 351.369 276.831 350.68 275.98 350.68C275.13 350.68 274.44 351.369 274.44 352.22C274.44 353.071 275.13 353.76 275.98 353.76Z"
        fill="#003C71"
      />
      <path
        d="M239.21 365.58C239.21 365.58 250.83 365.34 263.95 365.58C277.07 365.82 292.19 365.98 292.19 365.98C292.19 365.98 289.24 395.65 267.51 394.98C248.88 394.48 239.44 374.89 239.21 365.58Z"
        fill="#003C71"
      />
      <path
        d="M265.97 348.62C262.24 348.05 262.56 353.52 260.84 356.89C259.12 360.26 256.51 359.77 255.43 361.89C254.35 364.01 256.96 365.89 258.62 366.17C260.28 366.45 261.75 369.53 266.15 367.17C270.15 365.04 272.15 365.93 272.64 363.47C273.41 359.31 268.64 360.29 268.35 355.6C268.1 352.12 268.37 349 265.97 348.62Z"
        fill="#FFEEE0"
      />
      <path
        d="M266.17 306.09C262.012 306.09 257.896 306.909 254.054 308.5C250.213 310.091 246.723 312.423 243.783 315.363C240.843 318.303 238.511 321.793 236.92 325.634C235.329 329.475 234.51 333.592 234.51 337.75H297.82C297.82 329.355 294.486 321.304 288.551 315.367C282.615 309.429 274.565 306.093 266.17 306.09Z"
        fill="#FFD400"
      />
      <path d="M300.64 336.73H231.94V342.39H300.64V336.73Z" fill="#FFE770" />
      <path
        d="M231.94 342.39C231.94 342.39 242.2 346.8 266.17 346.8C290.14 346.8 300.64 342.39 300.64 342.39H231.94Z"
        fill="#FF7D20"
      />
      <path d="M274.44 301.67H258.05V323.26H274.44V301.67Z" fill="#FFD400" />
      <path d="M249.98 308.85C249.91 308.85 258.05 321.56 258.05 321.56V305.45L249.98 308.85Z" fill="#FF7D20" />
      <path d="M282.51 308.85C282.58 308.85 274.44 321.56 274.44 321.56V305.45L282.51 308.85Z" fill="#FF7D20" />
      <path
        d="M184.47 483.74C184.47 483.74 189.47 417.31 231.27 410.3C273.07 403.29 302.81 414.3 302.81 414.3C302.81 414.3 338.65 427.67 340.35 483.75L184.47 483.74Z"
        fill="#003C71"
      />
      <path d="M235.41 407.97H221.83V483.74H235.41V407.97Z" fill="#FFD400" />
      <path d="M309.79 407.97H296.21V483.74H309.79V407.97Z" fill="#FFD400" />
      <path d="M300.64 433.18H228.37V483.74H300.64V433.18Z" fill="#FFD400" />
      <path d="M364.84 483.15H165.42V485.9H364.84V483.15Z" fill="#003C71" />
      <path
        d="M284.08 447.81H251.45C247.255 447.805 243.233 446.139 240.262 443.176C237.292 440.213 235.616 436.195 235.6 432V408H238.35V432C238.353 435.474 239.734 438.804 242.19 441.26C244.646 443.716 247.977 445.097 251.45 445.1H284.08V447.81Z"
        fill="#003C71"
      />
      <path
        d="M437.24 548.29C488.52 548.29 530.09 506.72 530.09 455.44C530.09 404.16 488.52 362.59 437.24 362.59C385.961 362.59 344.39 404.16 344.39 455.44C344.39 506.72 385.961 548.29 437.24 548.29Z"
        fill="#E5E5E5"
      />
      <path
        d="M486.75 401.75C485.197 401.752 483.678 401.293 482.385 400.431C481.093 399.569 480.085 398.343 479.49 396.909C478.894 395.474 478.738 393.895 479.04 392.371C479.343 390.847 480.091 389.448 481.189 388.349C482.288 387.251 483.687 386.503 485.211 386.2C486.735 385.898 488.314 386.054 489.749 386.649C491.184 387.245 492.41 388.253 493.271 389.545C494.133 390.837 494.592 392.357 494.59 393.91C494.587 395.988 493.761 397.981 492.291 399.451C490.821 400.921 488.829 401.747 486.75 401.75ZM486.75 388.81C485.741 388.808 484.754 389.105 483.914 389.665C483.074 390.224 482.419 391.02 482.031 391.951C481.644 392.883 481.541 393.909 481.737 394.899C481.933 395.889 482.418 396.798 483.13 397.513C483.843 398.227 484.752 398.714 485.741 398.911C486.731 399.109 487.757 399.008 488.69 398.623C489.622 398.237 490.419 397.583 490.98 396.744C491.541 395.906 491.84 394.919 491.84 393.91C491.838 392.56 491.301 391.266 490.347 390.31C489.393 389.355 488.1 388.815 486.75 388.81Z"
        fill="#989898"
      />
      <path
        d="M426.04 373.32C424.487 373.322 422.968 372.863 421.676 372.002C420.384 371.141 419.376 369.915 418.781 368.481C418.185 367.047 418.028 365.468 418.33 363.945C418.632 362.421 419.378 361.022 420.476 359.923C421.573 358.824 422.972 358.075 424.495 357.772C426.018 357.468 427.597 357.623 429.032 358.217C430.467 358.81 431.694 359.817 432.557 361.108C433.42 362.399 433.88 363.917 433.88 365.47C433.878 367.549 433.051 369.543 431.582 371.014C430.112 372.486 428.12 373.315 426.04 373.32ZM426.04 360.38C425.031 360.378 424.043 360.676 423.203 361.235C422.363 361.795 421.708 362.591 421.32 363.524C420.933 364.456 420.831 365.482 421.028 366.472C421.224 367.463 421.71 368.372 422.424 369.086C423.138 369.8 424.047 370.286 425.038 370.482C426.028 370.679 427.054 370.577 427.986 370.19C428.919 369.803 429.715 369.147 430.275 368.307C430.834 367.467 431.132 366.48 431.13 365.47C431.128 364.121 430.59 362.828 429.636 361.874C428.682 360.92 427.389 360.383 426.04 360.38Z"
        fill="#989898"
      />
      <path
        d="M423.2 413.52C422.1 413.52 421.025 413.194 420.11 412.582C419.196 411.971 418.483 411.102 418.062 410.085C417.642 409.069 417.532 407.95 417.748 406.872C417.963 405.793 418.494 404.802 419.272 404.025C420.051 403.248 421.042 402.719 422.122 402.506C423.201 402.292 424.319 402.404 425.335 402.826C426.351 403.248 427.218 403.963 427.828 404.878C428.438 405.794 428.762 406.87 428.76 407.97C428.757 409.443 428.171 410.855 427.128 411.895C426.086 412.936 424.673 413.52 423.2 413.52ZM423.2 405.16C422.644 405.16 422.101 405.325 421.639 405.634C421.177 405.942 420.817 406.381 420.604 406.895C420.391 407.408 420.336 407.973 420.444 408.518C420.553 409.063 420.82 409.564 421.213 409.957C421.606 410.35 422.107 410.618 422.652 410.726C423.197 410.834 423.762 410.779 424.275 410.566C424.789 410.353 425.228 409.993 425.537 409.531C425.845 409.069 426.01 408.526 426.01 407.97C426.01 407.225 425.714 406.51 425.187 405.983C424.66 405.456 423.945 405.16 423.2 405.16Z"
        fill="#989898"
      />
      <path
        d="M355.91 416.31C354.812 416.31 353.739 415.984 352.827 415.375C351.914 414.765 351.203 413.898 350.783 412.884C350.363 411.87 350.253 410.754 350.467 409.677C350.681 408.601 351.209 407.612 351.986 406.836C352.762 406.059 353.751 405.531 354.827 405.317C355.904 405.102 357.02 405.212 358.034 405.632C359.048 406.053 359.915 406.764 360.525 407.677C361.135 408.589 361.46 409.662 361.46 410.76C361.455 412.23 360.868 413.639 359.829 414.679C358.789 415.718 357.38 416.305 355.91 416.31ZM355.91 407.96C355.356 407.96 354.815 408.124 354.355 408.432C353.894 408.74 353.535 409.177 353.323 409.688C353.111 410.2 353.056 410.763 353.164 411.306C353.272 411.849 353.539 412.348 353.93 412.74C354.322 413.131 354.821 413.398 355.364 413.506C355.907 413.614 356.47 413.559 356.982 413.347C357.493 413.135 357.931 412.776 358.238 412.316C358.546 411.855 358.71 411.314 358.71 410.76C358.7 410.024 358.4 409.322 357.876 408.806C357.352 408.289 356.646 408 355.91 408V407.96Z"
        fill="#989898"
      />
      <path
        d="M411.29 385.23C413.599 385.23 415.47 383.359 415.47 381.05C415.47 378.741 413.599 376.87 411.29 376.87C408.982 376.87 407.11 378.741 407.11 381.05C407.11 383.359 408.982 385.23 411.29 385.23Z"
        fill="white"
      />
      <path
        d="M375.01 399.49C378.219 399.49 380.82 396.889 380.82 393.68C380.82 390.471 378.219 387.87 375.01 387.87C371.801 387.87 369.2 390.471 369.2 393.68C369.2 396.889 371.801 399.49 375.01 399.49Z"
        fill="white"
      />
      <path
        d="M448.51 410.76C450.819 410.76 452.69 408.889 452.69 406.58C452.69 404.271 450.819 402.4 448.51 402.4C446.202 402.4 444.33 404.271 444.33 406.58C444.33 408.889 446.202 410.76 448.51 410.76Z"
        fill="white"
      />
      <path
        d="M399.1 410.55C400.707 410.55 402.01 409.247 402.01 407.64C402.01 406.033 400.707 404.73 399.1 404.73C397.493 404.73 396.19 406.033 396.19 407.64C396.19 409.247 397.493 410.55 399.1 410.55Z"
        fill="white"
      />
      <path
        d="M493.29 430.13C494.897 430.13 496.2 428.827 496.2 427.22C496.2 425.613 494.897 424.31 493.29 424.31C491.683 424.31 490.38 425.613 490.38 427.22C490.38 428.827 491.683 430.13 493.29 430.13Z"
        fill="white"
      />
      <path
        d="M347.87 393.21C354.995 393.21 360.77 387.434 360.77 380.31C360.77 373.186 354.995 367.41 347.87 367.41C340.746 367.41 334.97 373.186 334.97 380.31C334.97 387.434 340.746 393.21 347.87 393.21Z"
        fill="#E5E5E5"
      />
      <path
        d="M393.93 513.62C409.996 513.62 423.02 500.596 423.02 484.53C423.02 468.464 409.996 455.44 393.93 455.44C377.864 455.44 364.84 468.464 364.84 484.53C364.84 500.596 377.864 513.62 393.93 513.62Z"
        fill="white"
      />
      <path
        d="M369.67 446.63C371.841 446.63 373.6 444.87 373.6 442.7C373.6 440.53 371.841 438.77 369.67 438.77C367.5 438.77 365.74 440.53 365.74 442.7C365.74 444.87 367.5 446.63 369.67 446.63Z"
        fill="white"
      />
      <path
        d="M437.63 438.86C439.42 438.86 440.87 437.409 440.87 435.62C440.87 433.831 439.42 432.38 437.63 432.38C435.841 432.38 434.39 433.831 434.39 435.62C434.39 437.409 435.841 438.86 437.63 438.86Z"
        fill="white"
      />
      <path
        d="M449.75 454.73C451.54 454.73 452.99 453.279 452.99 451.49C452.99 449.701 451.54 448.25 449.75 448.25C447.961 448.25 446.51 449.701 446.51 451.49C446.51 453.279 447.961 454.73 449.75 454.73Z"
        fill="white"
      />
      <path
        d="M414.97 453.99C417.002 453.99 418.65 452.342 418.65 450.31C418.65 448.278 417.002 446.63 414.97 446.63C412.938 446.63 411.29 448.278 411.29 450.31C411.29 452.342 412.938 453.99 414.97 453.99Z"
        fill="white"
      />
      <path
        d="M351.55 450.32C353.583 450.32 355.23 448.672 355.23 446.64C355.23 444.608 353.583 442.96 351.55 442.96C349.518 442.96 347.87 444.608 347.87 446.64C347.87 448.672 349.518 450.32 351.55 450.32Z"
        fill="white"
      />
      <path
        d="M401.2 473.74C402.36 473.74 403.3 472.8 403.3 471.64C403.3 470.48 402.36 469.54 401.2 469.54C400.04 469.54 399.1 470.48 399.1 471.64C399.1 472.8 400.04 473.74 401.2 473.74Z"
        fill="white"
      />
      <path
        d="M473.77 453.99C476.647 453.99 478.98 451.657 478.98 448.78C478.98 445.903 476.647 443.57 473.77 443.57C470.893 443.57 468.56 445.903 468.56 448.78C468.56 451.657 470.893 453.99 473.77 453.99Z"
        fill="white"
      />
      <path
        d="M375.01 481.84C378.18 481.84 380.75 479.27 380.75 476.1C380.75 472.93 378.18 470.36 375.01 470.36C371.84 470.36 369.27 472.93 369.27 476.1C369.27 479.27 371.84 481.84 375.01 481.84Z"
        fill="white"
      />
      <path
        d="M462.01 424.26C461.098 424.26 460.207 423.99 459.449 423.483C458.691 422.977 458.1 422.257 457.751 421.414C457.402 420.572 457.311 419.645 457.489 418.751C457.667 417.856 458.106 417.035 458.75 416.39C459.395 415.746 460.217 415.306 461.111 415.129C462.005 414.951 462.932 415.042 463.774 415.391C464.617 415.74 465.337 416.331 465.843 417.089C466.35 417.847 466.62 418.738 466.62 419.65C466.62 420.255 466.501 420.855 466.269 421.414C466.038 421.974 465.698 422.482 465.27 422.91C464.842 423.338 464.334 423.677 463.774 423.909C463.215 424.141 462.616 424.26 462.01 424.26ZM462.01 417.79C461.642 417.79 461.283 417.899 460.977 418.103C460.671 418.308 460.433 418.598 460.292 418.938C460.151 419.278 460.114 419.652 460.186 420.013C460.258 420.374 460.435 420.705 460.695 420.965C460.955 421.225 461.286 421.403 461.647 421.474C462.008 421.546 462.382 421.509 462.722 421.368C463.062 421.228 463.352 420.989 463.557 420.683C463.761 420.377 463.87 420.018 463.87 419.65C463.87 419.157 463.674 418.684 463.325 418.335C462.977 417.986 462.503 417.79 462.01 417.79Z"
        fill="#989898"
      />
      <path
        d="M405.66 436.74C404.505 436.74 403.375 436.397 402.414 435.755C401.454 435.113 400.705 434.2 400.264 433.133C399.822 432.065 399.707 430.89 399.933 429.757C400.159 428.624 400.716 427.583 401.534 426.767C402.352 425.951 403.393 425.395 404.527 425.171C405.66 424.947 406.835 425.064 407.902 425.507C408.969 425.951 409.88 426.701 410.521 427.663C411.161 428.624 411.502 429.755 411.5 430.91C411.497 432.457 410.881 433.94 409.786 435.033C408.691 436.126 407.207 436.74 405.66 436.74ZM405.66 427.82C405.049 427.82 404.451 428.001 403.943 428.341C403.435 428.68 403.039 429.163 402.805 429.728C402.571 430.292 402.51 430.913 402.629 431.513C402.749 432.112 403.043 432.663 403.475 433.095C403.907 433.527 404.458 433.821 405.057 433.941C405.657 434.06 406.278 433.999 406.843 433.765C407.407 433.531 407.89 433.135 408.229 432.627C408.569 432.119 408.75 431.521 408.75 430.91C408.75 430.09 408.424 429.305 407.845 428.725C407.266 428.146 406.48 427.82 405.66 427.82Z"
        fill="#989898"
      />
      <path
        d="M388.64 373.94C387.819 373.94 387.016 373.696 386.333 373.24C385.651 372.783 385.119 372.135 384.805 371.376C384.491 370.617 384.41 369.782 384.571 368.977C384.732 368.171 385.128 367.432 385.709 366.852C386.291 366.272 387.031 365.878 387.837 365.719C388.642 365.56 389.477 365.643 390.235 365.959C390.993 366.274 391.641 366.808 392.096 367.492C392.55 368.175 392.792 368.979 392.79 369.8C392.787 370.899 392.349 371.952 391.571 372.728C390.793 373.504 389.739 373.94 388.64 373.94ZM388.64 368.4C388.363 368.4 388.093 368.482 387.862 368.636C387.632 368.79 387.453 369.008 387.347 369.264C387.241 369.52 387.213 369.802 387.267 370.073C387.321 370.345 387.454 370.594 387.65 370.79C387.846 370.986 388.095 371.119 388.367 371.173C388.639 371.227 388.92 371.199 389.176 371.093C389.432 370.987 389.65 370.808 389.804 370.578C389.958 370.348 390.04 370.077 390.04 369.8C390.04 369.429 389.893 369.073 389.63 368.81C389.368 368.548 389.011 368.4 388.64 368.4Z"
        fill="#989898"
      />
      <path d="M210.11 295.3H107.76V298.05H210.11V295.3Z" fill="#003C71" />
      <path
        d="M246.37 247.94H166.53V245.19H246.37C248.342 245.19 250.233 244.408 251.628 243.014C253.022 241.621 253.808 239.731 253.81 237.76V230.32C253.808 228.349 253.022 226.459 251.628 225.066C250.233 223.672 248.342 222.89 246.37 222.89H235.01V220.14H246.37C249.07 220.143 251.659 221.216 253.569 223.124C255.479 225.032 256.555 227.62 256.56 230.32V237.76C256.555 240.46 255.479 243.048 253.569 244.956C251.659 246.864 249.07 247.937 246.37 247.94Z"
        fill="#003C71"
      />
      <path d="M212.39 228.55H209.64V237.75H212.39V228.55Z" fill="#003C71" />
      <path d="M168.22 228.55H165.47V237.75H168.22V228.55Z" fill="#003C71" />
      <path d="M122.91 278.65H120.16V287.85H122.91V278.65Z" fill="#003C71" />
      <path d="M167.91 278.65H165.16V287.85H167.91V278.65Z" fill="#003C71" />
      <path d="M123.7 228.55H120.95V237.75H123.7V228.55Z" fill="#003C71" />
      <path d="M145.21 344.05H99.4501V346.8H145.21V344.05Z" fill="#003C71" />
      <path
        d="M397.23 503.75H394.48V470.84C394.48 469.221 393.838 467.669 392.694 466.523C391.55 465.378 389.999 464.733 388.38 464.73C386.034 464.727 383.784 463.794 382.125 462.135C380.466 460.476 379.533 458.226 379.53 455.88V439.24H382.3V455.88C382.303 457.497 382.946 459.047 384.09 460.19C385.233 461.334 386.783 461.977 388.4 461.98C390.747 461.985 392.997 462.921 394.656 464.581C396.315 466.242 397.248 468.493 397.25 470.84L397.23 503.75Z"
        fill="#0067A5"
      />
      <path
        d="M402.47 440.62L386.47 408.62C385.955 407.59 385.164 406.724 384.184 406.119C383.205 405.514 382.076 405.194 380.925 405.194C379.774 405.194 378.645 405.514 377.666 406.119C376.687 406.724 375.895 407.59 375.38 408.62L359.38 440.62C358.901 441.567 358.673 442.62 358.717 443.68C358.762 444.74 359.078 445.771 359.635 446.674C360.191 447.577 360.971 448.322 361.898 448.838C362.825 449.354 363.869 449.623 364.93 449.62H396.93C397.99 449.621 399.033 449.351 399.959 448.835C400.885 448.318 401.663 447.573 402.218 446.67C402.774 445.767 403.089 444.737 403.133 443.678C403.177 442.619 402.949 441.566 402.47 440.62Z"
        fill="#408DBC"
      />
      <path
        d="M397.23 474.91H394.21C391.979 474.91 390.17 476.719 390.17 478.95V499.7C390.17 501.931 391.979 503.74 394.21 503.74H397.23C399.461 503.74 401.27 501.931 401.27 499.7V478.95C401.27 476.719 399.461 474.91 397.23 474.91Z"
        fill="#003C71"
      />
      <path d="M445.93 162.42H443.18V394.02H445.93V162.42Z" fill="#003C71" />
      <path d="M471.23 162.42H468.48V394.02H471.23V162.42Z" fill="#003C71" />
      <path
        d="M541.3 164.9H317.98V150L346.3 136.85H541.3V164.9ZM320.73 162.15H538.55V139.6H346.93L320.73 151.75V162.15Z"
        fill="#003C71"
      />
      <path d="M362.85 164.81H360.1V227.52H362.85V164.81Z" fill="#003C71" />
      <path
        d="M350.98 241.15L348.89 239.37L361.67 224.29L374.08 239.31L371.96 241.06L361.65 228.58L350.98 241.15Z"
        fill="#003C71"
      />
      <path d="M420.49 238.84H303.93V253.31H420.49V238.84Z" fill="#63A2C8" />
      <path d="M420.49 256H303.93V270.47H420.49V256Z" fill="#63A2C8" />
      <path d="M354.988 137.198L353.182 139.271L382.22 164.566L384.026 162.493L354.988 137.198Z" fill="#003C71" />
      <path d="M392.711 137.188L390.905 139.262L419.942 164.557L421.749 162.484L392.711 137.188Z" fill="#003C71" />
      <path d="M430.438 137.187L428.631 139.26L457.669 164.555L459.475 162.482L430.438 137.187Z" fill="#003C71" />
      <path d="M444.958 180.415L443.151 182.488L468.939 204.953L470.745 202.879L444.958 180.415Z" fill="#003C71" />
      <path d="M444.963 212.1L443.156 214.174L468.944 236.638L470.75 234.565L444.963 212.1Z" fill="#003C71" />
      <path d="M444.968 243.786L443.161 245.859L468.949 268.324L470.755 266.25L444.968 243.786Z" fill="#003C71" />
      <path d="M444.965 275.465L443.159 277.538L468.946 300.003L470.753 297.929L444.965 275.465Z" fill="#003C71" />
      <path d="M444.96 307.16L443.154 309.234L468.941 331.698L470.748 329.625L444.96 307.16Z" fill="#003C71" />
      <path d="M444.966 338.483L443.16 340.556L468.947 363.021L470.754 360.947L444.966 338.483Z" fill="#003C71" />
      <path d="M444.962 369.795L443.156 371.869L468.943 394.333L470.75 392.26L444.962 369.795Z" fill="#003C71" />
      <path d="M468.171 137.197L466.364 139.271L495.402 164.566L497.208 162.493L468.171 137.197Z" fill="#003C71" />
      <path d="M505.897 137.196L504.091 139.269L533.128 164.564L534.934 162.491L505.897 137.196Z" fill="#003C71" />
      <path d="M469.85 138.23H420.85V162.43H469.85V138.23Z" fill="#0067A5" />
      <path d="M471.23 163.8H419.48V136.8H471.23V163.8ZM422.23 161.05H468.48V139.6H422.23V161.05Z" fill="#003C71" />
      <path d="M433.35 269.09H291.6V271.84H433.35V269.09Z" fill="#003C71" />
      <path d="M317.13 238.84H314.38V268.84H317.13V238.84Z" fill="#003C71" />
      <path d="M362.85 238.84H360.1V268.84H362.85V238.84Z" fill="#003C71" />
      <path d="M408.57 238.84H405.82V268.84H408.57V238.84Z" fill="#003C71" />
      <path d="M602.17 363.94H485.61V378.41H602.17V363.94Z" fill="#63A2C8" />
      <path d="M602.17 381.1H485.61V395.57H602.17V381.1Z" fill="#63A2C8" />
      <path d="M498.81 363.94H496.06V393.94H498.81V363.94Z" fill="#003C71" />
      <path d="M544.53 363.94H541.78V393.94H544.53V363.94Z" fill="#003C71" />
      <path d="M590.24 363.94H587.49V393.94H590.24V363.94Z" fill="#003C71" />
      <path d="M602.17 329.61H485.61V344.08H602.17V329.61Z" fill="#63A2C8" />
      <path d="M602.17 346.78H485.61V361.25H602.17V346.78Z" fill="#63A2C8" />
      <path d="M498.81 329.61H496.06V359.61H498.81V329.61Z" fill="#003C71" />
      <path d="M544.53 329.61H541.78V359.61H544.53V329.61Z" fill="#003C71" />
      <path d="M590.24 329.61H587.49V359.61H590.24V329.61Z" fill="#003C71" />
      <path d="M481.5 393.4H417.02V396.15H481.5V393.4Z" fill="#003C71" />
      <path d="M441.29 100.77H469.85V138.23" fill="#0067A5" />
      <path d="M471.23 138.23H468.48V102.14H441.29V99.39H471.23V138.23Z" fill="#003C71" />
      <path d="M451.05 83.73H448.3V118.06H451.05V83.73Z" fill="#003C71" />
    </svg>
  );
};
