/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */

// So we can skip types/node
declare const process: {
  env: {
    NODE_ENV?: string;
    SALEOR_BASE_HOST?: string;
  };
};

let URL =
  typeof window === "undefined"
    ? (process.env.SALEOR_BASE_HOST as string)
    : (window as unknown as { __APP__CONFIG__: { SALEOR_BASE_HOST: string } }).__APP__CONFIG__.SALEOR_BASE_HOST;

const securityWorker = (token: string | null | undefined): any => {
  const conf = {
    headers: {
      Authorization: "",
    },
  };
  if (token && token !== "" && typeof window !== "undefined") {
    Object.assign(conf.headers, {
      Authorization: `JWT ${token}`,
    });
  }

  return Promise.resolve(conf);
};

export const restApiConf = {
  baseUrl: (URL ? URL.replace(/\/$/, "") : null) as unknown as string,
  securityWorker,
};
