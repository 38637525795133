import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const SwissKnifeFilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M32 39V15C32 7.268 38.268 1 46 1V39H32Z" fill="#C6C6C6" />
      <path
        // eslint-disable-next-line max-len
        d="M2 40V11C2 10.448 2.448 10 3 10H13C13.552 10 14 10.448 14 11V18C11.791 18 10 19.791 10 22C10 24.209 11.791 26 14 26V40H2Z"
        fill="#C6C6C6"
      />
      <path
        d="M40 47H8C4.141 47 1 43.86 1 40C1 36.14 4.141 33 8 33H40C43.859 33 47 36.14 47 40C47 43.86 43.859 47 40 47Z"
        fill="#00416A"
      />
      <path
        // eslint-disable-next-line max-len
        d="M41 29C40.447 29 40 28.552 40 28V11C40 10.448 40.447 10 41 10C41.553 10 42 10.448 42 11V28C42 28.552 41.553 29 41 29Z"
        fill="#E6E7E8"
      />
      <path
        // eslint-disable-next-line max-len
        d="M8 43C9.65685 43 11 41.6569 11 40C11 38.3431 9.65685 37 8 37C6.34315 37 5 38.3431 5 40C5 41.6569 6.34315 43 8 43Z"
        fill="#00416A"
      />
      <path
        // eslint-disable-next-line max-len
        d="M40 43C41.6569 43 43 41.6569 43 40C43 38.3431 41.6569 37 40 37C38.3431 37 37 38.3431 37 40C37 41.6569 38.3431 43 40 43Z"
        fill="#00416A"
      />
    </svg>
  );
};
