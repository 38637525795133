import { useCallback, useEffect } from "react";

import { useToasts } from "@byko/hooks-toasts";
import {
  useCartHelper,
  useCheckout,
  useCheckoutLines,
  useCheckoutState,
  useProductCartVariantHelper,
  useTotalCartCount,
} from "@byko/lib-api-products";
import { analytics } from "@byko/lib-analytics";
import { useRecoilState } from "@byko/lib-recoil";

import {
  cartAdditionNotificaktionsState,
  loadingVariantIdCollectionState,
  setDeferMinicartToggleState,
  showMinicartState,
} from "./store";
import { useCheckoutExtra } from "./use-checkout-extra";
import type { UseCartProps } from "./interface";
import type { CHECKOUT_ERROR } from "@byko/lib-api-products/src/provider/checkout-provider/const";
import type { CheckoutLine } from "@byko/lib-api-rest";

export const useCart = (): UseCartProps => {
  const { setErrorMessage, setMessagePosition } = useToasts();
  const { cartUpdate, clearCart: handleClearCart } = useCheckout();
  const cartState = useCheckoutState();
  const isLoading = cartState === "LOADING" || cartState === "INITIAL_LOAD";
  const hasError = cartState === "ERROR";
  const isReady = cartState === "READY";
  const cartItemList = useCheckoutLines();
  const [cartAdditionNotifications, setCartAdditionNotifications] = useRecoilState(cartAdditionNotificaktionsState);
  const [loadingVariantIdCollection, setLoadingVariantIdCollection] = useRecoilState(loadingVariantIdCollectionState);
  const [showMinicart, setShowMinicart] = useRecoilState(showMinicartState);
  const [deferMinicartToggle, setDeferMinicartToggle] = useRecoilState(setDeferMinicartToggleState);
  const totalProducts = useTotalCartCount();
  const { fetchCheckoutData } = useCheckoutExtra();

  const handleUpdateCartError = useCallback(
    (
      updateResults: void | {
        code: "INSUFFICIENT_STOCK" | "QUANTITY_GREATER_THAN_LIMIT" | "NOT_FOUND" | "UNKNOWN";
        message: string;
      },
    ): boolean => {
      if (updateResults?.code === "INSUFFICIENT_STOCK") {
        setMessagePosition("top-right");
        setErrorMessage(updateResults.message);
      } else if (updateResults?.code === "QUANTITY_GREATER_THAN_LIMIT") {
        setMessagePosition("top-right");
        setErrorMessage(updateResults.message);
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      else if (updateResults?.code === "SHIPPING_ADDRESS_NOT_SET") {
        // Do Nothing, harmless error
      } else if (updateResults?.code === "NOT_FOUND" || updateResults?.code === "UNKNOWN") {
        setMessagePosition("top-right");
        setErrorMessage("Villa kom upp við að bæta vöru í körfu");
      }

      if (updateResults?.code) {
        return true;
      }

      return false;
    },
    [setErrorMessage, setMessagePosition],
  );

  useEffect(() => {
    if (showMinicart === false) {
      setDeferMinicartToggle(true);
    }
  }, [setDeferMinicartToggle, showMinicart]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDeferMinicartToggle(false);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [deferMinicartToggle, setDeferMinicartToggle]);

  const addToLoading = useCallback(
    (variantId: number) => {
      setLoadingVariantIdCollection((prev): number[] => {
        if (prev.includes(variantId)) {
          return prev;
        }
        return [...prev, variantId];
      });
    },
    [setLoadingVariantIdCollection],
  );

  const removeFromLoading = useCallback(
    (variantId: number) => {
      setLoadingVariantIdCollection((prev): number[] => {
        return prev.filter((id) => id !== variantId);
      });
    },
    [setLoadingVariantIdCollection],
  );

  const toggleShowMinicart = useCallback(
    (open?: boolean) => {
      if (typeof open === "boolean") {
        setShowMinicart(open);
      } else {
        setShowMinicart((current) => {
          if (current === false && deferMinicartToggle === false) {
            return true;
          } else {
            return false;
          }
        });
      }
    },
    [deferMinicartToggle, setShowMinicart],
  );

  const addToCart = useProductCartVariantHelper(
    async ({ variantIdGQL, itemCount, variantId }): Promise<{ code: CHECKOUT_ERROR; message: string } | void> => {
      const newCount = itemCount + 1;
      addToLoading(variantId);
      setCartAdditionNotifications((prev) => {
        if (prev.includes(variantId)) {
          return prev;
        }

        return [...prev, variantId];
      });
      setTimeout(() => {
        setCartAdditionNotifications((prev) => {
          return prev.filter((id) => id !== variantId);
        });
      }, 10000);
      const updateResults = await cartUpdate({ [variantIdGQL]: newCount });
      handleUpdateCartError(updateResults);
      await fetchCheckoutData();
      removeFromLoading(variantId);
    },
    [cartUpdate],
  );

  const setCartQuantity = useProductCartVariantHelper<{ quantity: number }>(
    async ({ variantIdGQL, quantity, variantId }) => {
      addToLoading(variantId);
      setCartAdditionNotifications((prev) => {
        if (prev.includes(variantId)) {
          return prev;
        }
        return [...prev, variantId];
      });
      setTimeout(() => {
        setCartAdditionNotifications((prev) => {
          return prev.filter((id) => id !== variantId);
        });
      }, 10000);
      const updateResults = await cartUpdate({ [variantIdGQL]: quantity });
      handleUpdateCartError(updateResults);
      await fetchCheckoutData();
      removeFromLoading(variantId);
    },
    [cartUpdate],
  );

  const removeFromCart = useProductCartVariantHelper(
    async ({ variantIdGQL, variantId }) => {
      const cartItem = cartItemList.find((item) => item.variant.id === variantId);
      if (cartItem) {
        try {
          analytics.removeProductFromCart(cartItem as CheckoutLine);
        } catch (error) {
          console.error(error);
        }
      }
      addToLoading(variantId);
      await cartUpdate({ [variantIdGQL]: 0 });
      await fetchCheckoutData();
      removeFromLoading(variantId);
    },
    [cartUpdate],
  );

  const clearCart = useCartHelper(() => {
    handleClearCart();
  }, [handleClearCart]);

  return {
    isLoading,
    isReady,
    hasError,
    showMinicart,
    toggleShowMinicart,
    addToCart,
    removeFromCart,
    cartAdditionNotifications,
    loadingVariantIdCollection,
    clearCart,
    totalProducts,
    setCartQuantity,
    cartItemList,
  };
};
