/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const DocumentFilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42 41H12C10.895 41 10 40.105 10 39V3C10 1.895 10.895 1 12 1H42C43.105 1 44 1.895 44 3V39C44 40.105 43.105 41 42 41Z"
        fill="#AEAEAE"
      />
      <path
        d="M36 47H6C4.895 47 4 46.105 4 45V9C4 7.895 4.895 7 6 7H36C37.105 7 38 7.895 38 9V45C38 46.105 37.105 47 36 47Z"
        fill="#E6E7E8"
      />
      <path
        d="M29 19H13C12.448 19 12 18.552 12 18C12 17.448 12.448 17 13 17H29C29.552 17 30 17.448 30 18C30 18.552 29.552 19 29 19Z"
        fill="#AEAEAE"
      />
      <path
        d="M29 27H13C12.448 27 12 26.552 12 26C12 25.448 12.448 25 13 25H29C29.552 25 30 25.448 30 26C30 26.552 29.552 27 29 27Z"
        fill="#AEAEAE"
      />
      <path
        d="M21 35H13C12.448 35 12 34.552 12 34C12 33.448 12.448 33 13 33H21C21.552 33 22 33.448 22 34C22 34.552 21.552 35 21 35Z"
        fill="#AEAEAE"
      />
    </svg>
  );
};
