import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const SwissKnifeIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M16 17V8C16 4.1 19.1 1 23 1V20" stroke={iconColor} strokeMiterlimit="10" strokeWidth="2" />
      <path
        d="M1 20V5H7V9C5.9 9 5 9.9 5 11C5 12.1 5.9 13 7 13V17"
        stroke={iconColor}
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M1 20C1 18.3 2.3 17 4 17H20C21.7 17 23 18.3 23 20C23 21.7 21.7 23 20 23H4C2.3 23 1 21.7 1 20Z"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
};
