import styled from "styled-components";

import { HeadingsStyles } from "@byko/component-typography";
import { theme } from "@byko/lib-styles";

import type { ContainerProps, HeadingContainerProps, SBBackdropProps } from "./interface";

export const MainParagraphContainer = styled.div<{ textColor: string }>`
  position: relative;
  margin-bottom: 60px;
  color: ${(props): string => props.textColor};
  font-size: 20px;
  line-height: 150%;

  p {
    margin: 0;
    margin-bottom: 16px;
    color: ${(props): string => props.textColor};
    line-height: 170%;
    overflow-wrap: break-word;
    text-align: "inherit";
    strong {
      margin-top: 16px;
      font-weight: bold;
    }
  }

  a {
    color: ${theme.palette.blue.main};
    font-weight: bold;
  }

  h3,
  h4,
  h5,
  h6 {
    ${HeadingsStyles};
    margin-top: 16px;
    margin-bottom: 16px;
    color: ${({ textColor }): string => (textColor ? textColor : theme.palette.blue.dark)};
    font-weight: normal;
  }

  h3 {
    font-size: 18px;
    @media screen and (max-width: 375px) {
      font-size: 18px;
    }
  }

  h4 {
    font-size: 16px;
    font-weight: normal;
    @media screen and (max-width: 375px) {
      font-size: 16px;
    }
  }

  h5 {
    font-size: 16px;
    font-weight: normal;
    @media screen and (max-width: 375px) {
      font-size: 16px;
    }
  }

  h6 {
    font-size: 14px;
    font-weight: normal;
    @media screen and (max-width: 375px) {
      font-size: 14px;
    }
  }
  h1,
  h2 {
    display: none;
  }
`;

export const IntroParagraphContainer = styled.div`
  margin-bottom: 16px;
`;

export const Container = styled.div<ContainerProps>`
  position: relative;
  ${MainParagraphContainer} {
    p {
      column-count: ${(props): string => props.columns?.toString() ?? "1"};
      column-gap: 16px;
      column-width: ${(props): string => (props.columns === 3 ? "430px" : "544px")};
      line-height: 170%;
      white-space: pre-line;
      &::selection {
        background-color: ${theme.palette.yellow.main};
      }
    }
  }
  h4 {
    padding: 0;
    margin: 0;
    line-height: 120%;
    transform: translate3d(0, 18px, 0);
  }
`;

export const BlockLine = styled.div<{ color: string }>`
  width: 96px;
  height: 2px;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: ${(props): string => props.color};
`;

export const HeadingContainer = styled.div<HeadingContainerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${(props): string => (props.showUnderline ? "0" : "24px")};
  svg {
    margin-right: 32px;
    margin-left: 24px;
  }
`;

export const SBBackdrop = styled.div<SBBackdropProps>`
  padding: 48px;
  background-color: ${(props): string => (props.darkMode ? theme.palette.blue.dark : theme.palette.white.main)};
`;
