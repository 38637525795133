import styled from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";

import type { TagStyleProps } from "./interface";

export const TagsWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  text-transform: uppercase;
  @media screen and (max-width: ${gridTheme.container.maxWidth.sm}px) {
    flex-wrap: wrap;
  }
`;

export const Tag = styled.div<TagStyleProps>`
  position: relative;
  display: flex;
  overflow: hidden;
  width: fit-content;
  align-items: center;
  padding: 16px 36px 16px 20px;
  margin-right: 8px;
  margin-bottom: 8px;
  background-color: ${(props): string =>
    props.color === "blue"
      ? theme.palette.blue.main
      : props.color === "green"
      ? theme.palette.green.dark
      : theme.palette.yellow.main};
  color: ${(props): string => (props.color === "yellow" ? theme.palette.blue.dark : theme.palette.yellow.main)};
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  font-style: italic;
  font-weight: 900;
  &:before {
    position: absolute;
    top: 0;
    right: 0;
    width: 26px;
    height: 100%;
    background-color: white;
    content: "";
    transform: rotate(45deg);
    transform-origin: bottom left;
  }
  &:after {
    position: absolute;
    top: 0;
    right: 0;
    width: 26px;
    height: 100%;
    background-color: white;
    content: "";
    transform: rotate(-45deg);
    transform-origin: top left;
  }
  @media screen and (max-width: ${gridTheme.container.maxWidth.sm}px) {
    padding: 8px 36px 8px 8px;
    margin-top: 10px;
    margin-bottom: 1px;
    font-size: 12px;
  }
`;
