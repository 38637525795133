import { productBySlug } from "@byko/lib-api-products";
import { atom, selector, selectorFamily } from "@byko/lib-recoil";
import { isNotNull, isSSR } from "@byko/types-utils";

import type { ProductDetailAugmented } from "@byko/lib-api-products";

export const COMPARE_PRODUCT_LOCAL_STORAGE_KEY = "comparison-product";
export const MAX_PRODUCTS_TO_COMPARE = 4;

export const productSlugState = atom<string[]>({
  key: "product-slug-state",
  default: [],
});

export const productForComparisonState = selectorFamily<ProductDetailAugmented | null, string>({
  key: "product-for-comparison-state",
  get: (slug) => async (): Promise<ProductDetailAugmented | null> => {
    const value = await productBySlug(slug);
    return value;
  },
});

export const productHasBeenInitialzed = atom<boolean>({
  key: "product-has-been-initialized",
  default: false,
});

export const productsForComparisonState = selector<ProductDetailAugmented[]>({
  key: "products-for-comparison-state",
  get: async ({ get }) => {
    const slugState = get(productSlugState);
    const hasBeenInitialized = get(productHasBeenInitialzed);
    if (hasBeenInitialized && !isSSR()) {
      if (slugState.length === 0) {
        localStorage.removeItem(COMPARE_PRODUCT_LOCAL_STORAGE_KEY);
        return [];
      }
      const jsonValues = JSON.stringify(slugState);
      localStorage.setItem(COMPARE_PRODUCT_LOCAL_STORAGE_KEY, jsonValues);
    }
    const value = await Promise.all(
      slugState.map((slug) => {
        const product = get(productForComparisonState(slug));
        return product;
      }),
    );
    return value.filter(isNotNull);
  },
});
