import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const SwissKnifeLineDecorative: BykoReactDecorativeIconComponentType = ({ size, color = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M32 34V16C32 8.3 38.3 2 46 2V40" stroke={color} strokeMiterlimit="10" strokeWidth="2.5" />
      <path
        d="M2 40V10H14V18C11.8 18 10 19.8 10 22C10 24.2 11.8 26 14 26V34"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path
        d="M2 40C2 36.7 4.7 34 8 34H40C43.3 34 46 36.7 46 40C46 43.3 43.3 46 40 46H8C4.7 46 2 43.3 2 40Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path d="M40 28V13" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
    </svg>
  );
};
