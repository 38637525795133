import styled, { css } from "styled-components";

import { theme } from "@byko/lib-styles";

import type { AccordionItemContainerProps, AccordionWrapperProps } from "./interface";
import type { FlattenSimpleInterpolation } from "styled-components";

export const AccordionItemWrapper = styled.div`
  width: 100%;
  margin-bottom: 8px;
`;

export const SecondLabelContainer = styled.div`
  position: absolute;
  top: 0;
  right: 80px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 375px) {
    right: 40px;
    p {
      font-size: 14px !important;
    }
  }
`;

export const AccordionItemContainer = styled.div<AccordionItemContainerProps>`
  position: relative;
  width: 100%;
  .accordion-button {
    padding: 0;
    border: none;
    p {
      color: ${theme.palette.blue.main};
      font-size: 16px;
    }
    :hover {
      box-shadow: none;
    }
  }
`;

export const AccordionChildren = styled.div`
  background-color: ${theme.palette.white.main};
  @media screen and (max-width: 895px) {
    min-width: unset;
    padding: 12px 16px;
    margin-bottom: 16px;
  }
`;

const expandedCSS = css``;

export const AccordionWrapper = styled.div<AccordionWrapperProps>`
  display: flex;
  width: 100%;
  flex-direction: column;

  ${AccordionChildren} {
    &:last-of-type {
      .accordion-button {
        border-bottom: 1px solid ${theme.palette.gray[10]};
      }
    }
  }
  ${(props): false | FlattenSimpleInterpolation | undefined => props.isOpen && expandedCSS}
`;
