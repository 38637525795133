import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  opacity: 0;
  transform: translateY(80px);
  transition: opacity 0.6s, transform 0.6s;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
`;

interface MotionWrapperProps {
  children: React.ReactNode;
}

const MotionWrapper: React.FC<MotionWrapperProps> = ({ children }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const domRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (typeof window === "undefined" || !("IntersectionObserver" in window)) {
      // If we're server-side rendering or the browser doesn't support IntersectionObserver,
      // skip setting up the observer and default to showing the component
      setIsVisible(true);
      return;
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          if (domRef.current) {
            observer.unobserve(domRef.current);
          }
        }
      });
    });

    const node = domRef.current;
    if (node) {
      observer.observe(node);
    }

    return () => {
      if (node) {
        observer.unobserve(node);
      }
    };
  }, []);

  return (
    <Wrapper ref={domRef} className={isVisible ? "visible" : ""}>
      {children}
    </Wrapper>
  );
};

export { MotionWrapper };
