import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const PickaxeFilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M14.3218 3.11803L15.3598 1.04203C15.4678 0.826689 15.6498 0.657546 15.8725 0.565687C16.0951 0.473828 16.3435 0.465426 16.5718 0.542025C23.7494 2.96689 30.2706 7.01526 35.6279 12.3721C40.9853 17.7289 45.0343 24.2497 47.4598 31.427C47.5364 31.6554 47.528 31.9037 47.4362 32.1264C47.3443 32.3491 47.1752 32.5311 46.9598 32.639L44.8998 33.669C44.7819 33.7279 44.6535 33.7629 44.522 33.772C44.3905 33.7811 44.2585 33.7641 44.1336 33.722C44.0087 33.6799 43.8934 33.6135 43.7942 33.5267C43.6951 33.4399 43.614 33.3343 43.5558 33.216C37.377 20.7333 27.2635 10.6297 14.7748 4.46303C14.6564 4.40482 14.5508 4.32376 14.4638 4.22453C14.3769 4.12529 14.3105 4.00984 14.2684 3.88482C14.2263 3.7598 14.2093 3.62768 14.2185 3.49608C14.2277 3.36448 14.2628 3.23599 14.3218 3.11803V3.11803Z"
        fill="#C6C6C6"
      />
      <path
        // eslint-disable-next-line max-len
        d="M39.751 17.071L35.719 21.1C33.0703 17.8787 30.1122 14.9249 26.887 12.281L30.925 8.24304C34.2274 10.8018 37.1929 13.768 39.751 17.071V17.071Z"
        fill="#AEAEAE"
      />
      <path
        // eslint-disable-next-line max-len
        d="M27.2928 14.707L33.7068 8.29303C33.8944 8.10556 34.1487 8.00024 34.4138 8.00024C34.679 8.00024 34.9333 8.10556 35.1208 8.29303L39.7058 12.873C39.7988 12.9659 39.8726 13.0762 39.9229 13.1976C39.9732 13.319 39.9991 13.4491 39.9991 13.5805C39.9991 13.7119 39.9732 13.8421 39.9229 13.9635C39.8726 14.0849 39.7988 14.1952 39.7058 14.288L33.2858 20.708C33.193 20.801 33.0827 20.8748 32.9613 20.9251C32.8399 20.9754 32.7097 21.0013 32.5783 21.0013C32.4469 21.0013 32.3168 20.9754 32.1954 20.9251C32.074 20.8748 31.9637 20.801 31.8708 20.708L27.2908 16.123C27.1978 16.03 27.1241 15.9196 27.0739 15.798C27.0236 15.6764 26.9979 15.5462 26.998 15.4146C26.9982 15.2831 27.0244 15.1529 27.075 15.0315C27.1255 14.91 27.1996 14.7998 27.2928 14.707Z"
        fill="#C6C6C6"
      />
      <path
        // eslint-disable-next-line max-len
        d="M32 20L9.24195 44.758C8.84823 45.1524 8.38061 45.4653 7.86583 45.6788C7.35106 45.8923 6.79924 46.0022 6.24195 46.0022C5.68467 46.0022 5.13285 45.8923 4.61808 45.6788C4.1033 45.4653 3.63568 45.1524 3.24195 44.758V44.758C2.84755 44.3643 2.53466 43.8967 2.32117 43.3819C2.10769 42.8671 1.9978 42.3153 1.9978 41.758C1.9978 41.2007 2.10769 40.6489 2.32117 40.1341C2.53466 39.6193 2.84755 39.1517 3.24195 38.758L28 16L32 20Z"
        fill="#FFD330"
      />
      <path
        // eslint-disable-next-line max-len
        d="M3.24195 38.7581L7.89995 34.4811L13.519 40.1001L9.24195 44.7581C8.84823 45.1525 8.38061 45.4654 7.86583 45.6789C7.35106 45.8923 6.79924 46.0022 6.24195 46.0022C5.68467 46.0022 5.13285 45.8923 4.61808 45.6789C4.1033 45.4654 3.63568 45.1525 3.24195 44.7581V44.7581C2.84755 44.3644 2.53466 43.8967 2.32117 43.382C2.10769 42.8672 1.9978 42.3154 1.9978 41.7581C1.9978 41.2008 2.10769 40.649 2.32117 40.1342C2.53466 39.6194 2.84755 39.1518 3.24195 38.7581V38.7581Z"
        fill="#0A3A5C"
      />
    </svg>
  );
};
