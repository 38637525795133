import { DefaultValue, atom, selector } from "@byko/lib-recoil";
import { isSSR } from "@byko/types-utils";

import { LOCAL_KEY } from "../const";

import { Key } from "./const";

const _token = atom<string | null>({
  key: Key("AnonymousToken-hack"),
  default: isSSR() ? undefined : localStorage.getItem(LOCAL_KEY) ?? undefined,
});

export const anonymousTokenStore = selector<string | null | undefined>({
  key: Key("anonymous-token-store"),
  get: ({ get }) => {
    get(_token);
    const value = isSSR() ? undefined : localStorage.getItem(LOCAL_KEY) ?? undefined;
    return value;
  },
  set: (props, newValue) => {
    if (newValue instanceof DefaultValue) {
      return;
    }
    if (newValue == null) {
      props.set(_token, null);
      localStorage.removeItem(LOCAL_KEY);
      return;
    }
    props.set(_token, newValue);
    localStorage.setItem(LOCAL_KEY, newValue);
  },
});
