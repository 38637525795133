import * as React from "react";

import { MainLoader } from "./main-loader";
import { LoadingComponentWrapper } from "./styles";

export const LargeMainLoader = (): JSX.Element => {
  return (
    <LoadingComponentWrapper>
      <MainLoader size={96} />
    </LoadingComponentWrapper>
  );
};
