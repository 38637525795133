import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const PaintBucket1FilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 1C14.789 1 5 3.453 5 8V40C5 44.547 14.789 47 24 47C33.211 47 43 44.547 43 40V8C43 3.453 33.211 1 24 1Z"
        fill="#0A3A5C"
      />
      <path
        // eslint-disable-next-line max-len
        d="M41 8C41 5.912 34.532 3 24 3C13.468 3 7 5.912 7 8C7 10.088 13.468 13 24 13C24.676 13 25.331 12.985 25.969 12.96C26.534 12.938 27 13.394 27 13.959V25.83C27 27.454 28.216 28.911 29.839 28.995C31.569 29.086 33 27.71 33 26V12.988C33 12.516 33.334 12.115 33.794 12.011C38.387 10.977 41 9.324 41 8Z"
        fill="#FFD330"
      />
    </svg>
  );
};
