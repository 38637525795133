import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const TreeIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 14C6 9.0625 12 2 12 2C12 2 18 9.0625 18 14C18 17.3137 15.3137 19 12 19C8.68629 19 6 17.3137 6 14Z"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path d="M12 13V23" stroke={iconColor} strokeMiterlimit="10" strokeWidth="2" />
      <path d="M7 23H17" stroke={iconColor} strokeMiterlimit="10" strokeWidth="2" />
    </svg>
  );
};
