import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const SaveIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M6 22V14H18V22" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path
        d="M16.9999 1.00005H2V23H21.9999V7L16.9999 1.00005Z"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <rect height="5" stroke={iconColor} strokeWidth="2" width="10" x="6" y="6" />
    </svg>
  );
};
