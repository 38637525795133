import { atom } from "@byko/lib-recoil";

export const savedEmailState = atom<boolean>({
  key: "savedEmail",
  default: false,
});

export const emailState = atom<boolean>({
  key: "email",
  default: false,
});
