import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const LongArrowDownIcons: BykoReactIconComponentType = ({ size, iconColor, ...props }) => {
  return (
    <svg
      className="byko-icon"
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12 2V22" stroke={iconColor} strokeMiterlimit="10" strokeWidth="2" />
      <path d="M19 15L12 22L5 15" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
    </svg>
  );
};
