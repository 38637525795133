import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";

import { Alert } from "@byko/component-alert";
import { Button } from "@byko/component-buttons";
import { FormikInput, showFormikError } from "@byko/component-inputs";
import { H6, PSmall } from "@byko/component-typography";
import { useAddEmail } from "@byko/hooks-add-email";
import { useAuth } from "@byko/hooks-auth-next";
import { useModal } from "@byko/hooks-modal";
import { FormikProvider, useFormik } from "@byko/lib-formik";
import { CancelLargeIcons, LongArrowSideIcons } from "@byko/lib-icons";
import { useRecoilState } from "@byko/lib-recoil";
import { emailValidation } from "@byko/lib-utils";

import { LoginStepState, loginState } from "../../interface";
import { Loading } from "../loading";

import { emailStepText } from "./steps";
import { ButtonWrapper, EmailFormWrapper, TextAndInput } from "./styles";

import type { FormValues } from "./interface";

export const Email = (): JSX.Element => {
  const { addEmail, emailIsLoading, logout } = useAuth();
  const { show, hide } = useModal();
  const { checkEmailAvailability } = useAddEmail();
  const [errorMessagesState, setErrorMessagesState] = useState<string>("Nauðsynlegt");
  const [steps, setSteps] = useRecoilState<LoginStepState>(loginState);

  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showLoadingSplash, setShowLoadingSplash] = useState<boolean>(false);

  useEffect(() => {
    if (emailIsLoading) {
      setShowLoadingSplash(true);
    }
  }, [emailIsLoading]);

  useEffect(() => {
    if (showLoadingSplash) {
      setTimeout(() => {
        setShowLoadingSplash(false);
        setSteps(LoginStepState.welcome);
      }, 3000);
    }
  }, [setSteps, showLoadingSplash, steps]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    }
  }, [showAlert, steps]);

  const required = "Nauðsynlegt";
  const emailInUse = "Netfang er nú þegar í notkun";
  const emailNotValid = "Netfang ekki gilt";
  const somethingWentWrong = "Eitthvað fór úrskeiðis";

  const initialValues: FormValues = {
    email: "",
    addPersonalExperience: false,
    addToPostList: false,
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnMount: true,
    validationSchema: Yup.object({
      email: Yup.string().email(emailNotValid),
      addPersonalExperience: Yup.boolean().required(required),
      addToPostList: Yup.boolean().required(required),
    }),
    onSubmit: async (values) => {
      await addEmail(values.email);
    },
  });

  const handleEmailClick = useCallback(async () => {
    const res = await checkEmailAvailability(formik.values.email);

    if (res === false) {
      setShowAlert(true);
      setErrorMessagesState(emailInUse);
    } else if (formik.values.email.match(emailValidation) === null) {
      setErrorMessagesState(emailNotValid);
    } else {
      setErrorMessagesState(somethingWentWrong);
    }
  }, [checkEmailAvailability, formik]);

  const handleLogout = useCallback(() => {
    hide();
    logout();
  }, [hide, logout]);

  const handleLogoutPrompt = useCallback(() => {
    show({
      title: "Skrá út",
      paragraph: "Ertu viss um að þú viljir hætta við innskráningu?",
      cancelLabel: "Hætta við",
      confirmCallback: handleLogout,
      cancelIcon: CancelLargeIcons,
      confirmLabel: "Staðfesta",
    });
  }, [handleLogout, show]);

  return (
    <>
      {showLoadingSplash ? (
        <Loading />
      ) : (
        <FormikProvider value={formik}>
          <EmailFormWrapper onSubmit={formik.handleSubmit}>
            <TextAndInput>
              <H6 bottom={16}>{emailStepText.head}</H6>
              <PSmall bottom={32}>{emailStepText.main}</PSmall>
              <FormikInput
                error={showFormikError(formik, "email")}
                errorText={errorMessagesState}
                id="email"
                name="email"
                placeholder="Sláðu inn netfang"
                stretch={true}
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />

              <Alert alertType="warning" bottom="10" showSuccess={showAlert} stretch={true} text={errorMessagesState} />
            </TextAndInput>
            <ButtonWrapper>
              <Button
                buttonColor="blueButton"
                disabled={formik.isSubmitting || !formik.dirty}
                icon={LongArrowSideIcons}
                label="Áfram"
                stretch={true}
                type="submit"
                onClick={handleEmailClick}
              />
              <Button
                buttonColor="redButton"
                icon={CancelLargeIcons}
                label="Hætta við"
                stretch={true}
                type="button"
                onClick={handleLogoutPrompt}
              />
            </ButtonWrapper>
          </EmailFormWrapper>
        </FormikProvider>
      )}
    </>
  );
};
