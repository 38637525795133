import { gql } from "graphql-tag";
import { useQuery } from "react-query";
import { graphQLClient } from "../dato-graphql-client";

import type { EnvironmentalInformationQuery } from "../generated-gql/graphql";

export function useGetEnvironmentalInformation() {
  const { data, isLoading, error } = useQuery(`get-environmental-information`, async () => {
    const request = await graphQLClient.request<EnvironmentalInformationQuery>(
      gql`
        query EnvironmentalInformation {
          allEnvironmentalInformations {
            id
            pimKey
            description
            logo {
              __typename
              id
              alt
              author
              basename
              blurUpThumb
              blurhash
              colors {
                __typename
                alpha
                blue
                cssRgb
                green
                hex
                red
              }
              filename
              focalPoint {
                __typename
                x
                y
              }
              format
              height
              md5
              mimeType
              responsiveImage {
                __typename
                alt
                aspectRatio
                base64
                bgColor
                height
                sizes
                src
                srcSet
                title
                webpSrcSet
                width
              }
              size
              smartTags
              tags
              thumbhash
              title
              url
              width
            }
          }
        }
      `,
    );
    return request;
  });

  return { data, isLoading, error };
}
