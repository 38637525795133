/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const SendMessagesBlueFilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 32 32" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.4713 1.52865C30.3787 1.43609 30.261 1.37282 30.1327 1.34674C30.0044 1.32066 29.8713 1.33292 29.75 1.38199L1.74997 12.7153C1.63268 12.7628 1.53132 12.8426 1.45772 12.9455C1.38412 13.0484 1.34132 13.1702 1.33433 13.2965C1.32734 13.4228 1.35644 13.5485 1.41824 13.6589C1.48003 13.7693 1.57197 13.8599 1.68331 13.92L9.99997 18.4L13.6 22L18.078 30.316C18.1352 30.4222 18.2201 30.511 18.3238 30.5727C18.4275 30.6345 18.546 30.667 18.6666 30.6667C18.6789 30.6673 18.6911 30.6673 18.7033 30.6667C18.8296 30.6597 18.9514 30.617 19.0543 30.5434C19.1572 30.4699 19.2371 30.3686 19.2846 30.2513L30.618 2.25132C30.6673 2.1298 30.6797 1.99641 30.6537 1.86786C30.6276 1.73932 30.5641 1.62132 30.4713 1.52865Z"
        fill="white"
      />
      <path
        d="M30.4713 1.52864C30.3523 1.40968 30.1927 1.34013 30.0245 1.33395C29.8564 1.32776 29.6921 1.3854 29.5647 1.4953L10.2313 18.162C10.1588 18.2245 10.1006 18.302 10.0606 18.3891C10.0207 18.4762 10 18.5708 10 18.6666V21.3333C10 21.5101 10.0702 21.6797 10.1953 21.8047C10.3203 21.9297 10.4899 22 10.6667 22H13.3333C13.4292 22 13.524 21.9794 13.6113 21.9395C13.6985 21.8996 13.776 21.8413 13.8387 21.7686L30.5053 2.4353C30.6151 2.30779 30.6727 2.14346 30.6664 1.97529C30.6601 1.80713 30.5904 1.64757 30.4713 1.52864Z"
        fill="#80B3D2"
      />
    </svg>
  );
};
