import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const SettingsCogwheelLineDecorative: BykoReactDecorativeIconComponentType = ({ size, color = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M24 31C27.866 31 31 27.866 31 24C31 20.134 27.866 17 24 17C20.134 17 17 20.134 17 24C17 27.866 20.134 31 24 31Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path
        // eslint-disable-next-line max-len
        d="M46 27V21L39.6 20.466C39.1994 18.6962 38.4986 17.0082 37.528 15.475L41.683 10.565L37.435 6.322L32.525 10.477C30.9923 9.50443 29.3042 8.80191 27.534 8.4L27 2H21L20.466 8.4C18.6962 8.80063 17.0082 9.50143 15.475 10.472L10.565 6.317L6.322 10.565L10.477 15.475C9.50443 17.0077 8.80191 18.6958 8.4 20.466L2 21V27L8.4 27.534C8.80063 29.3038 9.50143 30.9918 10.472 32.525L6.317 37.435L10.56 41.678L15.47 37.523C17.0042 38.4961 18.6941 39.1986 20.466 39.6L21 46H27L27.534 39.595C29.3038 39.1944 30.9918 38.4936 32.525 37.523L37.435 41.678L41.678 37.435L37.523 32.525C38.4956 30.9923 39.1981 29.3042 39.6 27.534L46 27Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
    </svg>
  );
};
