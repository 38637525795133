import React, { useCallback, useMemo } from "react";
import Link from "next/link";

import { Button } from "@byko/component-buttons";
import { ArrowRightIcons } from "@byko/lib-icons";
import { useAlgoliaInsights } from "@byko/lib-algolia";

import { ProductCardContainer } from "./styles";
import { ProductCardInformation, ProductImage } from "./components";
import type { ProductRentCardProps } from "./interface";

export const ProductRentCard = ({ product, addedLabel, algoliaInsights }: ProductRentCardProps): JSX.Element => {
  const activeVariant = product.variants[0];

  const { sendClickInsights } = useAlgoliaInsights();

  const handleClick = useCallback(() => {
    !!algoliaInsights && sendClickInsights(algoliaInsights);
  }, [algoliaInsights, sendClickInsights]);

  const displayImage = useMemo((): string => {
    if (activeVariant?.firstImage?.image?.productList) {
      return activeVariant?.firstImage?.image?.productList;
    } else if (product.firstImage?.image?.productList) {
      return product.firstImage?.image?.productList;
    } else {
      return "https://www.datocms-assets.com/65892/1664464657-placeholder-do-not-remove.jpg";
    }
  }, [activeVariant?.firstImage?.image?.productList, product.firstImage?.image?.productList]);

  return (
    <ProductCardContainer className="product-card rent">
      <Link href={`/leiguvara/${product.slug}`} onClick={handleClick}>
        <ProductImage product={product} productImage={displayImage} rental={true} />
        <div className="bottom-card-content">
          <ProductCardInformation
            addedLabel={addedLabel}
            prodName={product.name}
            shortDecription={product.description}
            sku={product.variants[0]?.sku || ""}
          />
          <Button buttonColor="yellowButton" icon={ArrowRightIcons} label="Skoða vöru" stretch={true} />
        </div>
      </Link>
    </ProductCardContainer>
  );
};
