/* eslint-disable max-len */
import * as React from "react";
import { BykoReactIconComponentType } from "@byko/lib-core";

export const AfreidslaIllustration: BykoReactIconComponentType = ({ size = 200 }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 662 611" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M269.98 184.47C291.98 191.47 261.26 261.47 227.13 266.47C236.48 219.36 245.2 176.59 269.98 184.47Z"
        fill="#D9EEE8"
      />
      <path
        d="M195.03 161.88C178.67 167.08 201.52 219.1 226.88 222.79C219.93 187.8 213.45 156.02 195.03 161.88Z"
        fill="#D9EEE8"
      />
      <path
        d="M253.69 141.14C267.34 145.48 248.29 188.88 227.13 191.95C232.92 162.8 238.3 136.28 253.69 141.14Z"
        fill="#D9EEE8"
      />
      <path d="M228.3 143.36H225.96V329.41H228.3V143.36Z" fill="#003C71" />
      <path
        d="M80.3001 434.01C85.0608 434.01 88.9201 430.151 88.9201 425.39C88.9201 420.629 85.0608 416.77 80.3001 416.77C75.5394 416.77 71.6801 420.629 71.6801 425.39C71.6801 430.151 75.5394 434.01 80.3001 434.01Z"
        fill="#003C71"
      />
      <path
        d="M261.37 434.01C266.131 434.01 269.99 430.151 269.99 425.39C269.99 420.629 266.131 416.77 261.37 416.77C256.609 416.77 252.75 420.629 252.75 425.39C252.75 430.151 256.609 434.01 261.37 434.01Z"
        fill="#003C71"
      />
      <path d="M194.34 258.57H157.92V345.38H194.34V258.57Z" fill="#0067A5" />
      <path d="M191.392 201.503L176.618 192.973L93.6627 336.656L108.437 345.186L191.392 201.503Z" fill="#408DBC" />
      <path d="M267.24 380.6H65.0601V416.27H267.24V380.6Z" fill="#0067A5" />
      <path d="M252.75 345.06H88.92V380.73H252.75V345.06Z" fill="#FF7D20" />
      <path
        d="M227.13 345.06C232.989 345.06 238.607 342.733 242.75 338.59C246.893 334.447 249.22 328.829 249.22 322.97H205.04C205.04 328.829 207.367 334.447 211.51 338.59C215.653 342.733 221.271 345.06 227.13 345.06Z"
        fill="#428FBD"
      />
      <path d="M455.51 240.34H402.51V265.92H455.51V240.34Z" fill="#0067A5" />
      <path
        d="M504.3 231.51L487.05 224.67C479.12 219.74 455.12 204.5 449.9 197.54C449.822 195.533 449.245 193.577 448.22 191.85C447.195 190.123 445.755 188.679 444.03 187.65C443.861 187.553 443.667 187.508 443.472 187.519C443.277 187.531 443.09 187.6 442.933 187.717C442.777 187.834 442.659 187.994 442.592 188.178C442.526 188.361 442.515 188.56 442.56 188.75C443.095 190.58 443.324 192.485 443.24 194.39V194.85C438.66 192.85 432.7 189.85 428.47 186.29C428.266 186.112 428.017 185.995 427.75 185.952C427.483 185.908 427.209 185.94 426.959 186.044C426.71 186.148 426.494 186.32 426.337 186.54C426.18 186.76 426.087 187.02 426.07 187.29C425.26 197.1 436.82 205.01 446.24 207.63C464.24 254.53 505.56 264.07 505.56 264.07L504.3 231.51Z"
        fill="#0067A5"
      />
      <path
        d="M532.53 153.38C523.78 151.29 511.81 157.56 509.91 158.89C508.01 160.22 509.91 162.89 512.57 163.26C509.14 166.26 508.18 170.49 509.14 172.77C510.1 175.05 514.66 175.05 517.32 173.91C517.32 173.91 518.65 181.13 515.61 185.13C512.57 189.13 501.73 191.02 501.73 191.02C501.73 191.02 506.11 199.38 519.79 199.38C533.47 199.38 536.52 190.64 536.52 190.64L526.78 174.14L532.53 153.38Z"
        fill="#EE9697"
      />
      <path
        d="M555.72 207.94C555.72 207.94 563.3 192.14 567.13 183.22C570.96 174.3 564.85 167.45 553.06 169.92C541.27 172.39 538.61 160.41 536.52 156.61C534.43 152.81 529.52 150.33 522.45 153.76V162.69C522.45 162.69 531.77 164.4 523.21 171.25C523.21 171.25 521.02 191.41 535.14 193.02C549.26 194.63 555.53 203.76 555.72 207.94Z"
        fill="#003C71"
      />
      <path
        d="M564.66 268.14C565.47 250.82 565.25 189.64 535.14 193.05C535.14 193.05 515.99 205.69 501.73 191.05C501.73 191.05 481.2 191.43 483.67 234.39C483.67 234.39 481.39 247.7 470.56 242.95C470.56 242.95 493.94 260.25 509.15 263.1C524.36 265.95 542.22 276.21 555.53 271.1L555.73 271.03C560.78 269.2 564.4 273.46 564.66 268.14Z"
        fill="#0067A5"
      />
      <path
        d="M501.73 231.74C501.73 231.74 490.14 267.44 501.73 310.21H551.54C551.54 310.21 535 271.43 532.72 249.76C530.44 228.09 501.73 231.74 501.73 231.74Z"
        fill="#0067A5"
      />
      <path
        d="M546.17 257.82C554.85 256.82 558.78 246.09 552.37 240.16C548.09 236.16 540.8 234.96 534.37 234.16C517.23 232.16 486.6 243.33 486.6 243.33C482.184 239.887 477.348 237.017 472.21 234.79C466.67 233.09 475.76 243.34 475.76 243.34C471.488 244.309 467.015 243.849 463.03 242.03C462.797 241.933 462.542 241.902 462.292 241.941C462.043 241.98 461.809 242.087 461.616 242.251C461.424 242.414 461.28 242.628 461.202 242.867C461.123 243.107 461.112 243.364 461.17 243.61C462.52 249.87 470.54 254.81 486.17 251.78C508.58 255.5 530.09 259.68 546.17 257.82Z"
        fill="#EE9697"
      />
      <path
        d="M470.56 407.46C526.87 398.55 540.4 376.82 556.38 337.8C564.44 318.11 577.75 303.58 588.96 294.02V270.54H364.18V433.95H406.91C419.77 422.67 439.52 412.37 470.56 407.46Z"
        fill="#66A4C9"
      />
      <path d="M588.96 294.02V270.54H364.18V301.85H580.55C583.41 298.95 586.3 296.34 588.96 294.02Z" fill="#408DBC" />
      <path d="M366.74 288.9H364.4V434.05H366.74V288.9Z" fill="#408DBC" />
      <path d="M377.35 288.9H375.01V434.05H377.35V288.9Z" fill="#408DBC" />
      <path d="M387.95 288.9H385.61V434.05H387.95V288.9Z" fill="#408DBC" />
      <path d="M398.56 288.9H396.22V434.05H398.56V288.9Z" fill="#408DBC" />
      <path d="M596.38 265.79H360.01V275.29H596.38V265.79Z" fill="#8CBBD7" />
      <path d="M428.08 202.93H362.42V254.85H428.08V202.93Z" fill="#8FBCD8" />
      <path d="M402.51 245.3H387.62V265.79H402.51V245.3Z" fill="#8FBCD8" />
      <path d="M448.2 233.77H435.06V246.91H448.2V233.77Z" fill="#8FBCD8" />
      <path d="M448.19 248.08H433.88V233.77H436.22V245.74H448.19V248.08Z" fill="#003C71" />
      <path d="M428.08 253.68H362.42V256.02H428.08V253.68Z" fill="#003C71" />
      <path
        d="M435.48 258.81C436.485 258.81 437.3 257.995 437.3 256.99C437.3 255.985 436.485 255.17 435.48 255.17C434.475 255.17 433.66 255.985 433.66 256.99C433.66 257.995 434.475 258.81 435.48 258.81Z"
        fill="#003C71"
      />
      <path
        d="M441.62 258.81C442.625 258.81 443.44 257.995 443.44 256.99C443.44 255.985 442.625 255.17 441.62 255.17C440.615 255.17 439.8 255.985 439.8 256.99C439.8 257.995 440.615 258.81 441.62 258.81Z"
        fill="#003C71"
      />
      <path
        d="M447.77 258.81C448.775 258.81 449.59 257.995 449.59 256.99C449.59 255.985 448.775 255.17 447.77 255.17C446.765 255.17 445.95 255.985 445.95 256.99C445.95 257.995 446.765 258.81 447.77 258.81Z"
        fill="#003C71"
      />
      <path
        d="M517.99 161.7C518.553 161.7 519.01 161.243 519.01 160.68C519.01 160.117 518.553 159.66 517.99 159.66C517.427 159.66 516.97 160.117 516.97 160.68C516.97 161.243 517.427 161.7 517.99 161.7Z"
        fill="#003C71"
      />
      <path d="M329.37 164.64C329.37 164.64 314.66 165.3 314.66 179.35V193.14H329.37V164.64Z" fill="#003C71" />
      <path
        d="M329.37 164.64C329.37 164.64 341.67 164.42 344.52 166.64C347.37 168.86 343.42 172.5 343.42 172.5C343.42 172.5 351.55 181.21 339.91 184.79V202.41C339.91 202.41 333.14 204.41 325.66 198.26V183.66H323.37C322.9 183.66 322.434 183.567 322 183.388C321.566 183.208 321.171 182.944 320.839 182.611C320.506 182.279 320.242 181.884 320.063 181.45C319.883 181.016 319.79 180.55 319.79 180.08C319.79 179.13 320.167 178.218 320.838 177.545C321.509 176.872 322.42 176.493 323.37 176.49H325.66L329.37 164.64Z"
        fill="#EE9697"
      />
      <path
        d="M305.77 264.81C305.77 264.81 277.38 204.49 325.66 198.23H350.56V280.68H314L314.33 264.81H305.77Z"
        fill="#008B66"
      />
      <path
        d="M312.58 217.99C314.458 217.99 315.98 216.468 315.98 214.59C315.98 212.712 314.458 211.19 312.58 211.19C310.702 211.19 309.18 212.712 309.18 214.59C309.18 216.468 310.702 217.99 312.58 217.99Z"
        fill="#40A88D"
      />
      <path
        d="M326.58 229.25C329.468 229.25 331.81 226.908 331.81 224.02C331.81 221.132 329.468 218.79 326.58 218.79C323.692 218.79 321.35 221.132 321.35 224.02C321.35 226.908 323.692 229.25 326.58 229.25Z"
        fill="#40A88D"
      />
      <path
        d="M341.64 255.54C344.528 255.54 346.87 253.198 346.87 250.31C346.87 247.422 344.528 245.08 341.64 245.08C338.752 245.08 336.41 247.422 336.41 250.31C336.41 253.198 338.752 255.54 341.64 255.54Z"
        fill="#40A88D"
      />
      <path
        d="M309.17 253.71C311.048 253.71 312.57 252.188 312.57 250.31C312.57 248.432 311.048 246.91 309.17 246.91C307.292 246.91 305.77 248.432 305.77 250.31C305.77 252.188 307.292 253.71 309.17 253.71Z"
        fill="#40A88D"
      />
      <path
        d="M304.17 225.72C305.109 225.72 305.87 224.959 305.87 224.02C305.87 223.081 305.109 222.32 304.17 222.32C303.231 222.32 302.47 223.081 302.47 224.02C302.47 224.959 303.231 225.72 304.17 225.72Z"
        fill="#40A88D"
      />
      <path
        d="M334.33 213.88C336.014 213.88 337.38 212.515 337.38 210.83C337.38 209.146 336.014 207.78 334.33 207.78C332.646 207.78 331.28 209.146 331.28 210.83C331.28 212.515 332.646 213.88 334.33 213.88Z"
        fill="#40A88D"
      />
      <path
        d="M325.41 206.09C326.349 206.09 327.11 205.329 327.11 204.39C327.11 203.451 326.349 202.69 325.41 202.69C324.471 202.69 323.71 203.451 323.71 204.39C323.71 205.329 324.471 206.09 325.41 206.09Z"
        fill="#40A88D"
      />
      <path
        d="M312.58 238.08C313.519 238.08 314.28 237.319 314.28 236.38C314.28 235.441 313.519 234.68 312.58 234.68C311.641 234.68 310.88 235.441 310.88 236.38C310.88 237.319 311.641 238.08 312.58 238.08Z"
        fill="#40A88D"
      />
      <path
        d="M341.29 269.81C342.229 269.81 342.99 269.049 342.99 268.11C342.99 267.171 342.229 266.41 341.29 266.41C340.351 266.41 339.59 267.171 339.59 268.11C339.59 269.049 340.351 269.81 341.29 269.81Z"
        fill="#40A88D"
      />
      <path
        d="M342.99 206.09C343.929 206.09 344.69 205.329 344.69 204.39C344.69 203.451 343.929 202.69 342.99 202.69C342.051 202.69 341.29 203.451 341.29 204.39C341.29 205.329 342.051 206.09 342.99 206.09Z"
        fill="#40A88D"
      />
      <path
        d="M329.37 275.01C330.309 275.01 331.07 274.249 331.07 273.31C331.07 272.371 330.309 271.61 329.37 271.61C328.431 271.61 327.67 272.371 327.67 273.31C327.67 274.249 328.431 275.01 329.37 275.01Z"
        fill="#40A88D"
      />
      <path
        d="M324.86 244.47C326.622 244.47 328.05 243.042 328.05 241.28C328.05 239.518 326.622 238.09 324.86 238.09C323.098 238.09 321.67 239.518 321.67 241.28C321.67 243.042 323.098 244.47 324.86 244.47Z"
        fill="#40A88D"
      />
      <path
        d="M342.99 228.17C344.752 228.17 346.18 226.742 346.18 224.98C346.18 223.218 344.752 221.79 342.99 221.79C341.228 221.79 339.8 223.218 339.8 224.98C339.8 226.742 341.228 228.17 342.99 228.17Z"
        fill="#40A88D"
      />
      <path d="M350.56 280.68H314V435.09H350.56V280.68Z" fill="#003C71" />
      <path
        d="M335.08 156.07C335.08 156.07 337.31 171 325.66 176.49C319.05 172.47 316.86 160.25 335.08 156.07Z"
        fill="#003C71"
      />
      <path
        d="M336.38 171.17C337.004 171.17 337.51 170.664 337.51 170.04C337.51 169.416 337.004 168.91 336.38 168.91C335.756 168.91 335.25 169.416 335.25 170.04C335.25 170.664 335.756 171.17 336.38 171.17Z"
        fill="#003C71"
      />
      <path
        d="M309.17 259.48C309.17 259.48 311.59 278.15 328.71 288.03C334.42 291.32 331.35 300.1 328.71 302.96C328.49 307.35 337.5 309.32 340.13 307.57C343.42 298.57 342.13 284.62 328.27 264.81C325.983 262.115 322.941 260.167 319.537 259.217C316.133 258.267 312.522 258.359 309.17 259.48Z"
        fill="#EE9697"
      />
      <path d="M330.25 256.61H305.77V264.81H330.25V256.61Z" fill="#008B66" />
      <path d="M329.01 221.72H326.67V264.81H329.01V221.72Z" fill="#003C71" />
      <path d="M282.9 416.27H280.56V297.02H300.83V299.36H282.9V416.27Z" fill="#82B5D3" />
      <path d="M307.95 293.99H291.61V301.97H307.95V293.99Z" fill="#82B5D3" />
      <path
        d="M416.15 465.14L414.08 464.06C414.28 463.67 419.08 454.64 431.66 445.06C443.19 436.29 463.71 425.47 495.96 423.71C527.42 421.99 545.14 406.3 554.46 393.43C560.552 385.066 564.932 375.581 567.35 365.52L569.65 365.96C567.216 376.343 562.713 386.128 556.41 394.73C546.79 408.03 528.51 424.27 496.09 426.04C464.51 427.77 444.45 438.3 433.19 446.83C421 456.14 416.2 465.04 416.15 465.14Z"
        fill="#66A4C9"
      />
      <path d="M386.78 433.92H36.9V436.26H386.78V433.92Z" fill="#003C71" />
      <path d="M282.82 405.29H56.8V416.77H282.82V405.29Z" fill="#82B5D3" />
      <path d="M117.48 380.73H115.14V408.34H117.48V380.73Z" fill="#82B5D3" />
      <path d="M217.16 380.73H214.82V408.34H217.16V380.73Z" fill="#82B5D3" />
      <path
        d="M88.92 357.23H136.83C138.342 357.23 139.792 357.831 140.861 358.899C141.93 359.968 142.53 361.418 142.53 362.93V364.86C142.53 366.372 141.93 367.822 140.861 368.891C139.792 369.959 138.342 370.56 136.83 370.56H88.92V357.23Z"
        fill="#FFCAA3"
      />
      <path
        d="M154.34 370.41C157.98 370.41 160.93 367.46 160.93 363.82C160.93 360.18 157.98 357.23 154.34 357.23C150.7 357.23 147.75 360.18 147.75 363.82C147.75 367.46 150.7 370.41 154.34 370.41Z"
        fill="#FFCAA3"
      />
      <path d="M267.24 379.43H65.0601V381.77H267.24V379.43Z" fill="#003C71" />
      <path
        d="M108.87 346.78L93.0801 337.66L94.2501 335.64L108.01 343.58L190.38 200.92L192.41 202.08L108.87 346.78Z"
        fill="#003C71"
      />
      <path d="M338.97 425.39C338.97 425.39 361.58 428.91 363.97 435.09H334.97L338.97 425.39Z" fill="#003C71" />
      <path d="M527.45 218.79H525.11V233.77H527.45V218.79Z" fill="#003C71" />
      <path
        d="M554.41 247.26C547.86 226.48 504.22 239.08 503.78 239.21L503.12 236.96C510.861 234.825 518.804 233.507 526.82 233.03C543.3 232.28 553.61 236.95 556.65 246.56L554.41 247.26Z"
        fill="#003C71"
      />
      <path
        d="M482.38 231.61C481.96 225.148 482.343 218.658 483.52 212.29C486.09 199.06 492.33 191.29 501.52 189.86L501.88 192.18C481.93 195.3 484.65 231.05 484.68 231.41L482.38 231.61Z"
        fill="#003C71"
      />
      <path d="M504.62 398.65V288.9H502.3V399.56C503.07 399.26 503.85 398.96 504.62 398.65Z" fill="#408DBC" />
      <path d="M494.01 402.32V288.9H491.67V402.99L494.01 402.32Z" fill="#408DBC" />
      <path d="M483.4 405.04V288.9H481.07V405.55L483.4 405.04Z" fill="#408DBC" />
      <path d="M470.56 407.46L472.8 407.09V288.9H470.46V407.48L470.56 407.46Z" fill="#408DBC" />
      <path d="M462.19 408.98V288.9H459.85V409.46L462.19 408.98Z" fill="#408DBC" />
      <path d="M451.59 411.47V288.9H449.3V412.14C450.02 411.9 450.79 411.68 451.59 411.47Z" fill="#408DBC" />
      <path d="M440.98 414.75V288.9H438.64V415.58C439.4 415.3 440.2 415.03 440.98 414.75Z" fill="#408DBC" />
      <path d="M430.38 419.02V288.9H428.04V420.14C428.8 419.74 429.59 419.38 430.38 419.02Z" fill="#408DBC" />
      <path d="M419.77 424.6V288.9H417.43V426.06C418.19 425.57 418.97 425.08 419.77 424.6Z" fill="#408DBC" />
      <path d="M409.16 432.04V288.9H406.83V434.02C407.58 433.35 408.36 432.69 409.16 432.04Z" fill="#408DBC" />
    </svg>
  );
};
