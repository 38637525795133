/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable max-len */
import type { Checkout, CheckoutLine, ProductDetail, ProductList } from "@byko/lib-api-rest";
import type { AnalyticsProductListSection, PaymentType, ShippingTiers } from "./utils";
import { buildAnalyticsProduct } from "./utils";

// Enabling this will log the events to the console instead of sending them to Google Analytics as well as bypassing the cookie consent check
const DEBUG = false;

/**
 * Sends a Google Analytics view_item_list event when a product is viewed in a list
 * See https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#view_item_list
 *
 * @param {ProductList[]} products - the products to add impression for
 * @param {Record<string, string>} prices - a mapping of product ID to price
 * @param {AnalyticsProductListSection} analyticsSectionId - the section ID for the product list
 */
export const addProductListImpression = (
  products: ProductList[],
  prices: Record<string, string>,
  analyticsSectionId: AnalyticsProductListSection,
) => {
  if (typeof window === "undefined") return;
  if (window.cookiehub?.hasConsented("analytics") || DEBUG) {
    const analyticsProducts = products.map((product, index) => {
      const price = prices[product.id];
      return buildAnalyticsProduct(product, price ?? "0", product.name ?? "", index, analyticsSectionId);
    });

    // @ts-ignore
    if (DEBUG === false) {
      window.dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          items: analyticsProducts,
        },
      });
    } else {
      console.log({
        event: "view_item_list",
        ecommerce: {
          items: analyticsProducts,
        },
      });
    }
  }
};

/**
 * Sends a Google Analytics select_item event when a product is clicked on in a list
 * See https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#select_item
 *
 * @param {ProductList} product The product that was clicked.
 * @param {string} price The price of the product that was clicked.
 * @param {number} listIndex The index of the product in the list.
 * @param {AnalyticsProductListSection} analyticsSectionId The ID of the section in which the product list
 * appears.
 */
export const addProductListClick = (
  product: ProductList,
  price: string,
  listIndex: number,
  analyticsSectionId: AnalyticsProductListSection,
) => {
  if (typeof window === "undefined") return;
  if (window.cookiehub?.hasConsented("analytics") || DEBUG) {
    const analyticsProduct = buildAnalyticsProduct(product, price, product.name, listIndex, analyticsSectionId);

    // @ts-ignore
    if (DEBUG === false) {
      window.dataLayer.push({
        event: "select_item",
        items: [analyticsProduct],
      });
    } else {
      console.log({
        event: "select_item",
        items: [analyticsProduct],
      });
    }
  }
};

/**
 * Sends a Google Analytics view_item event when a product is viewed in a list
 * See https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#view_item
 *
 * @param {ProductDetail} product - the product to add impression for
 * @param {string} price The price of the product that was clicked.
 */
export const addProductPageViewImpression = (product: ProductDetail, price: string) => {
  if (typeof window === "undefined") return;
  if (window.cookiehub?.hasConsented("analytics") || DEBUG) {
    const analyticsProduct = buildAnalyticsProduct(product, price, product.name);

    // @ts-ignore
    if (DEBUG === false) {
      window.dataLayer.push({
        event: "view_item",
        ecommerce: {
          currency: "ISK",
          value: analyticsProduct.price,
          items: [analyticsProduct],
        },
      });
    } else {
      console.log({
        event: "view_item",
        ecommerce: {
          currency: "ISK",
          value: analyticsProduct.price,
          items: [analyticsProduct],
        },
      });
    }
  }
};

/**
 * Sends a Google Analytics add_to_cart event when a product is added to the cart
 *  See https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#add_to_cart
 * @param {CheckoutLine} product The product being added to the cart.
 * @param {number} quantity The quantity of the product being added to the cart.
 * @param {number} listIndex The index of the product in the list, if any.
 * @param {AnalyticsProductListSection} analyticsSectionId The section of the product list, if any.
 */
export const addProductToCart = (
  product: CheckoutLine,
  quantity: number,
  listIndex?: number,
  analyticsSectionId?: AnalyticsProductListSection,
) => {
  if (typeof window === "undefined") return;
  if (window.cookiehub?.hasConsented("analytics") || DEBUG) {
    const analyticsProduct = buildAnalyticsProduct(
      product.variant,
      product.totalPrice.amount.gross.toString(),
      product.variant.name ?? "",
      listIndex,
      analyticsSectionId,
      quantity,
    );

    // @ts-ignore
    if (DEBUG === false) {
      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          currency: "ISK",
          value: analyticsProduct.price,
          items: [analyticsProduct],
        },
      });
    } else {
      console.log({
        event: "add_to_cart",
        ecommerce: {
          currency: "ISK",
          value: analyticsProduct.price,
          items: [analyticsProduct],
        },
      });
    }
  }
};

/**
 * Sends a Google Analytics remove_from_cart event when a product is removed from the cart
 * See https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#remove_from_cart
 *
 * @param {CheckoutLine} product - product to remove from cart
 */
export const removeProductFromCart = (product: CheckoutLine) => {
  if (typeof window === "undefined") return;
  if (window.cookiehub?.hasConsented("analytics") || DEBUG) {
    const analyticsProduct = buildAnalyticsProduct(
      product.variant,
      "0",
      product.variant.name ?? "",
      undefined,
      undefined,
      product.quantity,
    );

    // @ts-ignore
    if (DEBUG === false) {
      window.dataLayer.push({
        event: "remove_from_cart",
        ecommerce: {
          currency: "ISK",
          value: analyticsProduct.price,
          items: [analyticsProduct],
        },
      });
    } else {
      console.log({
        event: "remove_from_cart",
        ecommerce: {
          currency: "ISK",
          value: analyticsProduct.price,
          items: [analyticsProduct],
        },
      });
    }
  }
};

/**
 * Sends a Google Analytics view_cart event when the cart page is viewed
 * See https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#view_cart
 *
 * @param {Checkout} checkout - checkout object
 */
export const viewCartPage = (checkout: Checkout) => {
  if (typeof window === "undefined") return;
  if (window.cookiehub?.hasConsented("analytics") || DEBUG) {
    const analyticsProducts = checkout.lines.map((product) => {
      return buildAnalyticsProduct(
        product.variant,
        product.totalPrice.amount.gross.toString(),
        product.variant.name ?? "",
        undefined,
        undefined,
        product.quantity,
      );
    });

    const totalPrice = analyticsProducts.reduce((acc, product) => {
      return acc + parseInt(product.price, 10);
    }, 0);

    // @ts-ignore
    if (DEBUG === false) {
      window.dataLayer.push({
        event: "view_cart",
        ecommerce: {
          currency: "ISK",
          value: totalPrice,
          items: analyticsProducts,
        },
      });
    } else {
      console.log({
        event: "view_cart",
        ecommerce: {
          currency: "ISK",
          value: totalPrice,
          items: analyticsProducts,
        },
      });
    }
  }
};

/**
 * Sends a Google Analytics begin_checkout event when the checkout process is started
 * See https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#begin_checkout
 *
 * @param {Checkout} checkout - checkout object
 */
export const checkoutStarted = (checkout: Checkout) => {
  if (typeof window === "undefined") return;
  if (window.cookiehub?.hasConsented("analytics") || DEBUG) {
    const analyticsProducts = checkout.lines.map((product) => {
      return buildAnalyticsProduct(
        product.variant,
        product.totalPrice.amount.gross.toString(),
        product.variant.name ?? "",
        undefined,
        undefined,
        product.quantity,
      );
    });

    const totalPrice = analyticsProducts.reduce((acc, product) => {
      return acc + parseInt(product.price, 10);
    }, 0);

    // @ts-ignore
    if (DEBUG === false) {
      window.dataLayer.push({
        event: "begin_checkout",
        ecommerce: {
          currency: "ISK",
          value: totalPrice,
          items: analyticsProducts,
        },
      });
    } else {
      console.log({
        event: "begin_checkout",
        ecommerce: {
          currency: "ISK",
          value: totalPrice,
          items: analyticsProducts,
        },
      });
    }
  }
};

/**
 * Sends a Google Analytics add_shipping_info event when the shipping info is added to the checkout
 * See https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#add_shipping_info
 *
 * @param {Checkout} checkout - checkout object
 * @param {string} shippingTier - delivery method
 */
export const addShippingInfo = (checkout: Checkout, shippingTier: ShippingTiers) => {
  if (typeof window === "undefined") return;
  if (window.cookiehub?.hasConsented("analytics") || DEBUG) {
    const analyticsProducts = checkout.lines.map((product) => {
      return buildAnalyticsProduct(
        product.variant,
        product.totalPrice.amount.gross.toString(),
        product.variant.name ?? "",
        undefined,
        undefined,
        product.quantity,
      );
    });

    const totalPrice = analyticsProducts.reduce((acc, product) => {
      return acc + parseInt(product.price, 10);
    }, 0);

    // @ts-ignore
    if (DEBUG === false) {
      window.dataLayer.push({
        event: "add_shipping_info",
        ecommerce: {
          shipping_tier: shippingTier,
          currency: "ISK",
          value: totalPrice,
          items: analyticsProducts,
        },
      });
    } else {
      console.log({
        event: "add_shipping_info",
        ecommerce: {
          shipping_tier: shippingTier,
          currency: "ISK",
          value: totalPrice,
          items: analyticsProducts,
        },
      });
    }
  }
};

/**
 * Sends a Google Analytics add_payment_info event when the payment info is added to the checkout
 * See https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#add_payment_info
 *
 * @param {Checkout} checkout - checkout object
 * @param {PaymentType} paymentType - payment method
 */
export const addPaymentInfo = (checkout: Checkout, paymentType: PaymentType) => {
  if (typeof window === "undefined") return;
  if (window.cookiehub?.hasConsented("analytics") || DEBUG) {
    const analyticsProducts = checkout.lines.map((product) => {
      return buildAnalyticsProduct(
        product.variant,
        product.totalPrice.amount.gross.toString(),
        product.variant.name ?? "",
        undefined,
        undefined,
        product.quantity,
      );
    });

    const totalPrice = analyticsProducts.reduce((acc, product) => {
      return acc + parseInt(product.price, 10);
    }, 0);

    // @ts-ignore
    if (DEBUG === false) {
      window.dataLayer.push({
        event: "add_payment_info",
        ecommerce: {
          currency: "ISK",
          value: totalPrice,
          payment_type: paymentType,
          items: analyticsProducts,
        },
      });
    } else {
      console.log({
        event: "add_payment_info",
        ecommerce: {
          currency: "ISK",
          value: totalPrice,
          payment_type: paymentType,
          items: analyticsProducts,
        },
      });
    }
  }
};

/**
 * Sends a Google Analytics purchase event when the checkout is completed
 * See https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#purchase
 *
 * @param {Checkout} checkout - checkout object
 * @param {string} shippingCost - shipping cost
 * @param {string} totalTax - total tax
 * @param {string} transactionId - transaction id
 */
export const checkoutCompleted = (
  checkout: Checkout,
  shippingCost: string,
  totalTax: string,
  transactionId: string,
) => {
  if (typeof window === "undefined") return;
  if (window.cookiehub?.hasConsented("analytics") || DEBUG) {
    const analyticsProducts = checkout.lines.map((product) => {
      return buildAnalyticsProduct(
        product.variant,
        product.totalPrice.amount.gross.toString(),
        product.variant.name ?? "",
        undefined,
        undefined,
        product.quantity,
      );
    });

    const totalPrice = analyticsProducts.reduce((acc, product) => {
      return acc + parseInt(product.price, 10);
    }, 0);

    // @ts-ignore
    if (DEBUG === false) {
      window.dataLayer.push({
        event: "purchase",
        ecommerce: {
          currency: "ISK",
          value: totalPrice, // total price
          tax: totalTax, // total tax
          transaction_id: transactionId,
          affiliation: "BYKO webstore",
          shipping: shippingCost,
          items: analyticsProducts,
        },
      });
    } else {
      console.log({
        event: "purchase",
        ecommerce: {
          currency: "ISK",
          value: totalPrice, // total price
          tax: totalTax, // total tax
          transaction_id: transactionId,
          affiliation: "BYKO webstore",
          shipping: shippingCost,
          items: analyticsProducts,
        },
      });
    }
  }
};
