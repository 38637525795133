import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const ReceiptIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 16 16" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M13.0769 1H2.92308C2.41354 1 2 1.38827 2 1.86667V14L4.30769 12.2667L6.15385 14L8 12.2667L9.84615 14L11.6923 12.2667L14 14V1.86667C14 1.38827 13.5865 1 13.0769 1Z"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <path d="M5 5L11 5" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="1.5" />
      <path d="M5 8H11" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="1.5" />
    </svg>
  );
};
