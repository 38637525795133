import * as React from "react";
import { useCallback } from "react";

import { drawerState } from "@byko/component-drawer";
import { PMedium } from "@byko/component-typography";
import { useAuth } from "@byko/hooks-auth-next";
import { useSetRecoilState } from "@byko/lib-recoil";

import { Item } from "./picker-search-styles";
import { filteredAccountsState, inputValueState, showSuggestionsState } from "./store";

import type { SuggestedItemProps } from "./interface";

export const SuggestedItem = ({ id, name, ssn }: SuggestedItemProps): JSX.Element => {
  const setOpen = useSetRecoilState<boolean>(drawerState);
  const setInputValue = useSetRecoilState(inputValueState);
  const setFilteredAccounts = useSetRecoilState(filteredAccountsState);
  const setShowSuggestions = useSetRecoilState(showSuggestionsState);
  const { switchAccount, setRequestSwitchAccount } = useAuth();

  const handleItemClick = useCallback(async () => {
    setInputValue(name);
    await switchAccount(id);
    setRequestSwitchAccount(false);
    setFilteredAccounts([]);
    setShowSuggestions(false);
    setOpen(false);
  }, [
    setInputValue,
    name,
    switchAccount,
    id,
    setRequestSwitchAccount,
    setFilteredAccounts,
    setShowSuggestions,
    setOpen,
  ]);

  return (
    <Item onClick={handleItemClick}>
      <PMedium>{name}</PMedium>
      <PMedium>{ssn}</PMedium>
    </Item>
  );
};
