import { ApolloProvider } from "@apollo/client";
import dynamic from "next/dynamic";
import React, { useEffect, useMemo, useRef } from "react";

import LightboxRoot from "../lightbox";
import { PageContent } from "@byko/component-page-container";
import { useMegaMenuData, withDato } from "@byko/lib-api-dato-client";
import { CheckoutProvider, useApollo } from "@byko/lib-api-products";
import { CategoriesProvider, WarehousesProvider } from "@byko/hooks-warehouses";
import { restApi } from "@byko/lib-api-rest";
import { RecoilRoot } from "@byko/lib-recoil";
import { GlobalStyles } from "@byko/lib-styles";
import { AlgoliaInsightProvider } from "@byko/lib-algolia";
import { isSSR } from "@byko/types-utils";
import { BetaBanner, CommonContext, QuickSearchResults } from "../features";
import { ConfigScript } from "../config-script";
import { SEARCH_ROUTES } from "../const";
import { Siteimprov } from "../siteimprov";
import { MainLoadingContainer } from "../style";
import { ClearSearchOnPageLoad, useLoader } from "../use-loader";

import type { NextPageContext } from "next";
import type { AppContext, AppProps, AppType } from "next/app";
import type { ComponentType } from "react";
import type { LargeMainLoader as ILargeMainLoader } from "@byko/component-loaders";
import type { Category, FieldType, Warehouse } from "@byko/lib-api-rest";

import type { Config } from "../interface";
import { Footer } from "src/features/footer";
import { Header } from "src/features/header";
import { MegamenuContainer } from "src/features/megamenu-container";
import { ModalHandler } from "src/features/modal";
import { ProductCompareSection } from "src/features/product-compare-section";
import { ErrorToast, SuccessToast } from "@byko/component-toasts";
import { Klaviyo } from "../klaviyo";
import "react-toastify/dist/ReactToastify.css";

const LargeMainLoader = dynamic<typeof ILargeMainLoader>(() =>
  import("@byko/component-loaders").then((value) => value.LargeMainLoader),
) as typeof ILargeMainLoader;

const GenesysChatWidget = dynamic(() => import("src/genesys-chat-widget"), { ssr: false });
const windowApolloState = {};

interface Props extends AppProps {
  config: Config;
  warehouses: Warehouse[];
  categories: Category[];
  fieldTypes: FieldType[];
  categoriesRental: Category[];
}

type AugmentedComponent = AppProps["Component"] & {
  getLayout?: ComponentType | undefined;
  skipHeader?: boolean | undefined;
};

const MyApp = (props: Props) => {
  const {
    //
    pageProps,
    config,
    warehouses,
    categories,
    fieldTypes,
    categoriesRental,
  } = props;
  const Component = props.Component as unknown as AugmentedComponent;
  const ref = useRef<HTMLDivElement>(null);
  useLoader(ref);
  const client = useApollo(windowApolloState);

  useMegaMenuData();
  const Layout = useMemo(() => {
    return Component.getLayout ?? (({ children }: { children: JSX.Element }) => <>{children}</>);
  }, [Component.getLayout]);

  const skipHeader = useMemo(() => {
    return Component?.skipHeader ?? false;
  }, [Component?.skipHeader]);

  const commonContext = useMemo(
    () => ({ categories, warehouses, fieldTypes, config, categoriesRental }),
    [categories, warehouses, fieldTypes, config, categoriesRental],
  );

  useEffect(() => {
    if (typeof window !== "undefined") {
      // TODO (Andri): Remove this after a few months
      localStorage.removeItem("preference");
    }
  }, []);

  const cmp = (
    <WarehousesProvider warehouses={warehouses}>
      <CategoriesProvider categories={categories}>
        <AlgoliaInsightProvider>
          <RecoilRoot>
            <Siteimprov />
            <Klaviyo />
            <ClearSearchOnPageLoad searchRoutes={SEARCH_ROUTES} />
            <ApolloProvider client={client}>
              <CheckoutProvider>
                <ConfigScript config={config} />
                <GlobalStyles />
                <ModalHandler />
                <>
                  {!skipHeader ? <Header /> : null}
                  <QuickSearchResults />
                  {!skipHeader ? (
                    <PageContent>
                      <BetaBanner />
                      <MainLoadingContainer ref={ref} active={false}>
                        <LargeMainLoader />
                      </MainLoadingContainer>
                      <Layout>
                        <div>
                          <Component {...pageProps} />
                        </div>
                      </Layout>
                      <MegamenuContainer />
                      <ProductCompareSection />
                    </PageContent>
                  ) : (
                    <Layout>
                      <Component {...pageProps} />
                    </Layout>
                  )}
                </>
                {!skipHeader ? <Footer /> : null}
                <div>
                  <ErrorToast />
                </div>
                <div>
                  <SuccessToast />
                </div>
                <LightboxRoot />
                <div id="portal"></div>
              </CheckoutProvider>
            </ApolloProvider>
          </RecoilRoot>
        </AlgoliaInsightProvider>
      </CategoriesProvider>
    </WarehousesProvider>
  );

  return (
    <CommonContext.Provider value={commonContext}>
      {!skipHeader && <GenesysChatWidget />} {cmp}
    </CommonContext.Provider>
  );
};

const MyApp2 = withDato()(MyApp) as AppType;

export interface NextPageContextExtra {
  categories: Category[];
  warehouses: Warehouse[];
  fieldTypes: FieldType[];
  config: Config;
  categoriesRental: Category[];
}

MyApp2.getInitialProps = async (appContext: AppContext) => {
  const { Component } = appContext;

  // We're stuck with this unless we make big alternations in architecture and deployment:
  const config = isSSR()
    ? {
        ENV: process.env["ENV"],
        SALEOR_GQL_URL: process.env["SALEOR_GQL_URL"],
        SALEOR_BASE_HOST: process.env["SALEOR_BASE_HOST"],
        CMS_API_URL: process.env["CMS_API_URL"],
        DATO_TOKEN_CMS: process.env["DATO_TOKEN_CMS"],
        IMAGES_API_URL: process.env["IMAGES_API_URL"],
        DATO_GRAPHQL_URL: process.env["DATO_GRAPHQL_URL"],
        ALGOLIA_APP_ID: process.env["ALGOLIA_APP_ID"],
        ALGOLIA_API_KEY: process.env["ALGOLIA_API_KEY"],
        ALGOLIA_ENV_PREFIX: process.env["ALGOLIA_ENV_PREFIX"],
        SITEIMPROV_KEY: process.env["SITEIMPROV_KEY"],
        BASE_URL: process.env["BASE_URL"],
        KLAVIYO_PUBLIC_API_KEY: process.env["KLAVIYO_PUBLIC_API_KEY"],
        SALT_PAY_TOKEN: process.env["SALT_PAY_TOKEN"],
        SALT_PAY_URL: process.env["SALT_PAY_URL"],
        NEXT_PUBLIC_SENTRY_DSN: process.env["NEXT_PUBLIC_SENTRY_DSN"],
        NEXT_PUBLIC_SENTRY_ENV: process.env["NEXT_PUBLIC_SENTRY_ENV"],
      }
    : window.__APP__CONFIG__;

  const [
    //
    { data: categories },
    { data: warehouses },
    { data: fieldTypes },
    { data: categoriesRental },
  ] = await Promise.all([
    //
    restApi.categoriesList(),
    restApi.warehousesList(),
    restApi.fieldTypesList(),
    restApi.categoriesRentalList(),
  ]);

  // FIXME: What is the point of setting these globals?
  //        We have context...
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _global = (isSSR() ? global : window) as any;
  _global.__BYKO_CATEGORIES__ = categories;
  _global.__BYKO_WAREHOUSES__ = warehouses;

  const ctx = appContext.ctx as NextPageContext & NextPageContextExtra;
  ctx.categories = categories;
  ctx.warehouses = warehouses;
  ctx.fieldTypes = fieldTypes;
  ctx.config = config;
  ctx.categoriesRental = categoriesRental;

  let pageProps = {};
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(appContext.ctx);
  }

  return {
    //
    pageProps,
    config,
    warehouses,
    categories,
    fieldTypes,
    categoriesRental,
  } as Props;
};

export default MyApp2;
