/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { httpLink } from "@trpc/client/links/httpLink";

import { getBykoConfig } from "@byko/lib-utils";

import { trpc } from "./base";

import type { AppRouter } from "@byko/lib-api-dato-ssr";
import type { TRPCClient } from "@trpc/react";
import type { QueryClient } from "react-query";

interface OpeningHoursProps<T> {
  secret?: string | undefined;
  initialData?: T | undefined;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useOpeningHours = <T,>({ initialData }: OpeningHoursProps<T>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return trpc.useQuery(["getOpeningHours"], {
    initialData: initialData ?? undefined,
    refetchOnWindowFocus: false,
  });
};

export const getOpeningHours =
  (props: { queryClient?: QueryClient; trpcClient: TRPCClient<AppRouter> } | undefined = undefined) =>
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async () => {
    const fullurl = getBykoConfig("CMS_API_URL");
    try {
      const data = await (
        props?.trpcClient ??
        trpc.createClient({
          links: [httpLink({ url: fullurl })],
        })
      ).query("getOpeningHours");
      return data ?? null;
    } catch (e) {
      return null;
    }
  };
