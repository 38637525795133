import React, { useCallback } from "react";

import { IconButton } from "@byko/component-buttons";
import { PSmall } from "@byko/component-typography";
import { useCompareProducts } from "@byko/hooks-compare-products";
import { CancelLargeIcons } from "@byko/lib-icons";

import { ProdImage, ProductCardDeletButton, ProductCompareSectionCard } from "./styles";

import type { SectionCardProps } from "./interface";

export const CompareSectionCard = ({
  prodId,
  prodSlug,
  prodImageAlt,
  prodUrl,
  prodName,
}: SectionCardProps): JSX.Element | null => {
  const { removeProduct } = useCompareProducts();

  const onClickRemove = useCallback(() => {
    removeProduct(prodSlug);
  }, [prodSlug, removeProduct]);

  return (
    <div key={prodId}>
      <ProductCardDeletButton>
        <IconButton ariaLabel="Eyða vöru úr samanburði" icon={CancelLargeIcons} small={true} onClick={onClickRemove} />
      </ProductCardDeletButton>
      <ProductCompareSectionCard href={`/vara/${prodSlug}`}>
        <ProdImage alt={prodImageAlt} src={prodUrl} />
        <PSmall>{prodName}</PSmall>
      </ProductCompareSectionCard>
    </div>
  );
};
