import styled from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";

import type { ModalBackdropProps } from "./interface";

export const ModalContainer = styled.div<{ active: boolean }>`
  position: fixed;
  width: 656px;
  padding: 16px 80px 56px 112px;
  background-color: ${theme.palette.white.main};
  box-shadow: 0px 2px 20px ${theme.palette.shadow.main};
  opacity: ${(props): string => (props.active ? "1" : "0")};
  transform: translate3d(0, ${(props): string => (props.active ? "0" : "-40px")}, 0);
  transition: all 300ms;
  &:before {
    position: absolute;
    width: 8px;
    background-color: ${theme.palette.blue.main};
    content: "";
    inset: 0 auto 0 0;
  }

  @media screen and (max-width: ${gridTheme.breakpoints.sm}px) {
    width: auto;
    width: 380px;
    padding: 16px 80px 56px 64px;
  }

  @media screen and (max-width: ${gridTheme.breakpoints.xs}px) {
    width: auto;
    max-height: 400px;
    padding: 40px 24px 32px 24px;
    margin-top: auto;
    margin-bottom: auto;
    inset: 0;
    &:before {
      background-color: ${theme.palette.transparent.main};
      content: "";
    }
  }
`;

export const CloseContainer = styled.div`
  position: absolute;
  inset: 16px 16px auto auto;
`;

export const Actions = styled.div`
  display: grid;
  margin-top: 32px;
  grid-row-gap: 8px;
  grid-template-columns: 1fr;
`;

export const ModalBackdrop = styled.div<ModalBackdropProps>`
  position: fixed;
  background-color: ${(props): string => props.backgroundColor};
  inset: 0;
  opacity: 0.5;
`;

export const ViewContainer = styled.div<{ active: boolean }>`
  position: fixed;
  z-index: 599;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
  opacity: ${(props): string => (props.active ? "1" : "0")};
  transition: opacity 300ms;
`;

export const InfoContainer = styled.div`
  display: grid;
  margin-top: 16px;
  grid-column-gap: 16px;
  grid-template-columns: 24px auto;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;
