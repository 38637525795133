import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const Measure1LineDecorative: BykoReactDecorativeIconComponentType = ({ size, color = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M16 12H22" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
      <path d="M16 20H20" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
      <path d="M16 28H22" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
      <path d="M16 36H20" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
      <path d="M32 2H16V46H32V2Z" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
    </svg>
  );
};
