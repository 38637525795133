/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const LeafBulbFilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41 18C41 7.96838 32.3111 -0.0338086 22.0485 1.10889C14.451 1.9549 8.23519 7.97095 7.17293 15.5412C6.00667 23.853 10.8634 31.193 18 33.8899V39H30V33.8899C36.4235 31.4625 41 25.2735 41 18Z"
        fill="#E6E7E8"
      />
      <path d="M18 41C18 44.3137 20.6863 47 24 47C27.3137 47 30 44.3137 30 41V39H18V41Z" fill="#AEAEAE" />
      <path d="M33 12H32C27.5817 12 24 15.5817 24 20V22H25C29.4183 22 33 18.4183 33 14V12Z" fill="#52C6A8" />
      <path d="M15 19C15 23.4183 18.5817 27 23 27H24V25C24 20.5817 20.4183 17 16 17H15V19Z" fill="#52C6A8" />
      <path
        d="M24 34C23.4473 34 23 33.5522 23 33V9C23 8.44775 23.4473 8 24 8C24.5527 8 25 8.44775 25 9V33C25 33.5522 24.5527 34 24 34Z"
        fill="#008F69"
      />
      <path d="M30 38H18V40H30V38Z" fill="#AEAEAE" />
    </svg>
  );
};
