import styled from "styled-components";

import { IconButton } from "@byko/component-buttons";
import { gridTheme, theme } from "@byko/lib-styles";

export const BurgerContainer = styled.button`
  display: flex;
  width: 56px;
  height: 56px;
  align-items: center;
  justify-content: center;
  background-color: ${theme.palette.yellow.main};
  @media screen and (min-width: ${gridTheme.breakpoints.sm}px) {
    display: none;
  }
`;

export const BurgerInner = styled.div`
  display: flex;
  width: 22px;
  height: 16px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;

export const BurgerLineLong = styled.div<{ hide?: boolean; rotateLine?: boolean }>`
  width: 22px;
  border: 1px solid ${theme.palette.blue.main};
  opacity: ${(p): string => (p.hide ? "0" : "1")};
  transform: rotate(-${(p): string => (p.hide && p.rotateLine ? "45" : "0")}deg);
  transform-origin: center;
  transition: 0.3s;
`;

export const BurgerLineShort = styled.div<{ hide?: boolean }>`
  width: 11px;
  border: 1px solid ${theme.palette.blue.main};
  opacity: ${(p): string => (p.hide ? "0" : "1")};
  transition: 0.3s;
`;

export const BurgerCrossTop = styled.div<{ hide?: boolean }>`
  position: absolute;
  top: 50%;
  width: 22px;
  border: 1px solid ${theme.palette.blue.main};
  background-color: ${theme.palette.blue.main};
  opacity: ${(p): string => (p.hide ? "0" : "1")};
  transform: rotate(-${(p): string => (p.hide ? "0" : "45")}deg);
  transform-origin: center;
  transition: 0.3s;
`;

export const BurgerCrossBottom = styled.div<{ hide?: boolean }>`
  position: absolute;
  top: 50%;
  width: 22px;
  border: 1px solid ${theme.palette.blue.main};
  background-color: ${theme.palette.blue.main};
  opacity: ${(p): string => (p.hide ? "0" : "1")};
  transform: rotate(${(p): string => (p.hide ? "0" : "45")}deg);
  transform-origin: center;
  transition: 0.3s;
`;

export const MobileSelectionContainer = styled.div<{ active: boolean }>`
  position: fixed;
  z-index: 20;
  top: 0px;
  right: 0px;
  width: 100%;
  max-width: 460px;
  height: calc(100vh - 56px);
  background-color: ${theme.palette.blue.main};
  transform: translate3d(${(p): string => (p.active ? "0%" : "100%")}, 56px, 0px);
  transition: 0.3s;
  @media screen and (min-width: ${gridTheme.breakpoints.sm}px) {
    display: none;
  }
`;

export const MobileIconButton = styled(IconButton)`
  @media screen and (min-width: ${gridTheme.breakpoints.sm}px) {
    display: none;
  }
`;

export const MobileSelectionContentBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 24px 16px;
`;

export const LinkText = styled.p`
  color: ${theme.palette.white.main};
  font-size: 16px;
  font-weight: bold;
  transition: 0.3s;
`;

export const LinkTag = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: 16px;
  background-color: ${theme.palette.white.main};
  transform: translate3d(0px, -1px, 0px);
  transition: 0.3s;
`;

export const MobileNavLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover ${LinkTag} {
    background-color: ${theme.palette.yellow.main};
  }
  &:hover ${LinkText} {
    color: ${theme.palette.yellow.main};
  }
`;
