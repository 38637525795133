import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const TrashCanIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 9L19 23H5.00002L4 9"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path d="M2 5L22 5" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M8 5V1H16V5" stroke={iconColor} strokeMiterlimit="10" strokeWidth="2" />
    </svg>
  );
};
