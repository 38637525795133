import styled from "styled-components";

import { theme } from "@byko/lib-styles";

import type { Maybe } from "@byko/types-utils";

export const DropdownSelectContainer = styled.div<{ fullWidth: Maybe<boolean> }>`
  position: relative;
  z-index: 30;
  display: flex;
  width: ${(p): string => (p.fullWidth ? "100%" : "320px")};
  flex-direction: column;
  align-items: flex-end;
`;

export const DropdownSelectActiveContainer = styled.button<{ fullWidth: boolean }>`
  display: flex;
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
  padding-left: 24px;
  background-color: ${theme.palette.white.main};
  cursor: pointer;
  outline: 1px solid ${theme.palette.gray[10]};
  transition: 0.3s;
  .active-name {
    transition: 0.3s;
  }
  &:hover {
    background-color: ${theme.palette.blue.main};
    .active-name {
      color: ${theme.palette.white.main};
    }
  }
  &:focus {
    outline: 1px solid ${theme.palette.gray[10]};
  }
`;

export const DropdownSelectActiveLabel = styled.p`
  color: ${theme.palette.blue.dark};
  font-size: 16;
  font-weight: 400;
`;

export const DropdownSelectOptionsInner = styled.div`
  display: grid;
  width: 100%;
  flex-direction: column;
`;

export const DropdownSelectOptionsWrapper = styled.div`
  display: grid;
  width: 100%;
  max-height: 254px;
  flex-direction: column;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${theme.palette.white.main};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${theme.palette.blue.main};
  }
`;

export const DropdownSelectionOption = styled.button`
  display: flex;
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
  padding-left: 24px;
  &.logout {
    border: 1px solid ${theme.palette.blue.main};
    background-color: ${theme.palette.blue.main};
    &:hover {
      .logout-label {
        color: ${theme.palette.yellow.main};
      }
    }
  }
  &.settings {
    border: 1px solid ${theme.palette.gray[40]};
    background-color: ${theme.palette.white.main};
    &:hover {
      .logout-label {
        color: ${theme.palette.blue.main};
      }
    }
  }
  cursor: pointer;
  transition: 0.3s;
  .logout-icon,
  .logout-label {
    transition: 0.3s;
  }
  &:hover {
    .logout-icon {
      transform: translate3d(-8px, 0px, 0px);
    }
  }
  &:focus {
    outline: 1px solid ${theme.palette.gray[60]};
  }
`;

export const DropdownSelectOptionsContainer = styled.div<{ collapsed: boolean }>`
  position: absolute;
  top: 56px;
  right: 0px;
  width: 100%;
  min-width: 320px;
  border: 1px solid ${theme.palette.gray[10]};
  box-shadow: 0px 18px 45px rgba(0, 0, 0, 0.09);
  opacity: ${(props): string => (props.collapsed ? "0" : "1")};
  pointer-events: ${(props): string => (props.collapsed ? "none" : "initial")};
  transform: translate3d(0px, ${(props): string => (props.collapsed ? "16px" : "0px")}, 0px);
  transition: 0.2s;
  @media screen and (max-width: 398px) {
    width: 100vw;
    transform: translateX(48px);
  }
`;

export const DropdownSelectOptionRow = styled.button`
  display: flex;
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
  padding-left: 16px;
  border-top: 1px solid ${theme.palette.transparent.main};
  border-bottom: 1px solid ${theme.palette.gray[10]};
  border-left: 1px solid ${theme.palette.transparent.main};
  background-color: ${theme.palette.white.main};
  transition: 0.3s;
  .option-label {
    transition: 0.3s;
  }
  &:hover {
    background-color: ${theme.palette.blue.main};
    .option-label {
      color: ${theme.palette.white.main};
    }
  }
  &:focus {
    border: 1px solid ${theme.palette.gray[60]};
  }
  p {
    text-align: left;
  }
`;

export const DropdownSelectOptionRowLabel = styled.p<{ color: string; small?: boolean }>`
  color: ${(props): string => props.color};
  font-size: ${(props): string => (props.small ? "14px" : "16px")};
  font-weight: 400;
`;
