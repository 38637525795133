/* eslint-disable max-len */
import * as React from "react";
import { BykoReactIconComponentType } from "@byko/lib-core";

export const PipulagnirIllustration: BykoReactIconComponentType = ({ size = 200 }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 662 611" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M365.43 331.01L358.78 325.4C358.78 325.4 354.21 331.84 356.91 337.45C359.61 343.06 370.66 353.45 387.37 350.76C404.08 348.07 391.61 341.19 391.61 341.19L381.01 332.43L365.43 331.01Z"
        fill="#F4B587"
      />
      <path
        d="M387.37 335.28C387.37 335.28 424.24 345.6 467.05 314.84L483.34 347.72C483.34 347.72 445.21 379.54 387.34 350.72L387.37 335.28Z"
        fill="#D9E8F2"
      />
      <path
        d="M437.87 363.78C410.87 363.78 387.04 352.24 386.72 352.08L385.82 351.64L386.9 338.18L389.84 338.42L388.92 349.85C395.31 352.76 434.34 369.28 466.74 355.29L467.91 358C458.397 361.958 448.173 363.926 437.87 363.78Z"
        fill="#003C71"
      />
      <path
        d="M345.1 360.42H247.35C237.039 360.409 227.153 356.309 219.862 349.018C212.571 341.727 208.471 331.841 208.46 321.53V271.63H227.65V321.53C227.658 326.752 229.736 331.758 233.429 335.451C237.122 339.144 242.128 341.222 247.35 341.23H345.1C350.321 341.222 355.327 339.144 359.018 335.451C362.709 331.758 364.785 326.751 364.79 321.53V224.89C364.803 215.301 368.618 206.109 375.399 199.329C382.179 192.548 391.371 188.733 400.96 188.72H445.6C450.109 188.72 454.433 186.929 457.621 183.741C460.809 180.553 462.6 176.229 462.6 171.72V104.72H481.79V171.72C481.787 181.317 477.974 190.521 471.187 197.307C464.401 204.094 455.197 207.907 445.6 207.91H401C396.491 207.91 392.167 209.701 388.979 212.889C385.791 216.077 384 220.401 384 224.91V321.55C383.987 331.86 379.883 341.744 372.59 349.031C365.297 356.319 355.41 360.415 345.1 360.42Z"
        fill="#E5E5E5"
      />
      <path
        d="M468.2 272.08C468.2 272.08 459.15 279.86 458.72 291.4C458.63 295.5 464.14 293.68 465.21 293C465.64 297.58 468.97 302.33 475.11 302.36C481.25 302.39 494.11 291.49 495.37 284.66C496.63 277.83 484.09 260.28 468.2 272.08Z"
        fill="#F4B587"
      />
      <path
        d="M468.71 285.94C469.472 285.94 470.09 285.322 470.09 284.56C470.09 283.798 469.472 283.18 468.71 283.18C467.948 283.18 467.33 283.798 467.33 284.56C467.33 285.322 467.948 285.94 468.71 285.94Z"
        fill="#003C71"
      />
      <path
        d="M479.14 292.79C479.14 292.79 481.96 305.67 479.05 314.01C476.14 322.35 506.45 314.85 506.45 314.85L506.03 308.99C506.03 308.99 498.6 274.1 484.51 269.28C470.42 264.46 479.14 292.79 479.14 292.79Z"
        fill="#F4B587"
      />
      <path
        d="M480.12 272.71C480.12 272.71 482.77 277.83 484.48 280.86C484.48 280.86 489.59 279.93 490.35 284.56C490.365 285.382 490.115 286.187 489.638 286.857C489.161 287.526 488.482 288.025 487.7 288.28C487.7 288.28 492.04 292.72 501.43 293.34H505.5C505.5 293.34 502.57 286.12 499.35 278.95C498.087 276.068 496.01 273.617 493.374 271.899C490.738 270.181 487.657 269.271 484.51 269.28C481.83 272.14 480.12 272.71 480.12 272.71Z"
        fill="#003C71"
      />
      <path
        d="M465.23 385.04H516.23C536.72 404.18 519.1 488.85 406.8 403.8L440.8 483.93H411.29L375.52 397.19C374.42 394.224 374.121 391.022 374.652 387.904C375.184 384.786 376.528 381.863 378.548 379.429C380.569 376.996 383.195 375.138 386.162 374.043C389.13 372.947 392.333 372.653 395.45 373.19L465.23 385.04Z"
        fill="#FF7D20"
      />
      <path
        d="M461.6 428.43V463.51C461.599 466.191 462.126 468.846 463.151 471.324C464.176 473.801 465.679 476.052 467.575 477.948C469.47 479.845 471.72 481.349 474.197 482.375C476.674 483.402 479.329 483.93 482.01 483.93H560.5V459.99H492.43L499.06 421.57L461.6 428.43Z"
        fill="#FF7D20"
      />
      <path
        d="M501.78 310.63C497.15 312.3 492.34 315.21 488.46 314.85H467.05V385.04H519.14V331.96C519.14 319.46 513.93 309.38 501.78 310.63Z"
        fill="#D9E8F2"
      />
      <path d="M479.14 296.45H479.05V296.5L479.14 296.45Z" fill="#82B5D3" />
      <path
        d="M506.03 308.99C505.895 308.973 505.761 308.946 505.63 308.91C495.1 314.59 481.4 312.34 476.49 310.52C471.58 310.66 470.97 311.91 464.88 316.37L498.2 318.47C494.28 342.12 491.3 385.04 491.3 385.04C491.3 385.04 521.39 401.61 545.37 385.04C562.9 372.93 564.04 318.8 506.03 308.99Z"
        fill="#D9E8F2"
      />
      <path
        d="M477.09 278.92H499.35C499.35 278.92 493.48 262.71 477.09 262.71C460.7 262.71 462.65 278.92 462.65 278.92H477.09Z"
        fill="#408DBC"
      />
      <path
        d="M462.65 278.92C462.65 278.92 441.65 273.07 446.21 264.53C450.77 255.99 483.34 278.92 483.34 278.92H462.65Z"
        fill="#82B5D3"
      />
      <path d="M477.21 307.9H467.05V385.04H477.21V307.9Z" fill="#FF7D20" />
      <path d="M509.83 307.9H499.67V385.04H509.83V307.9Z" fill="#FF7D20" />
      <path d="M509.82 329.16H472.1V402.09H509.82V329.16Z" fill="#FF7D20" />
      <path
        d="M504.28 347.78C504.28 347.78 532.13 348.04 528.8 365.63C526.53 377.63 510.45 376.54 504.28 372.93C498.11 369.32 504.28 347.78 504.28 347.78Z"
        fill="#FF7D20"
      />
      <path
        d="M491.88 442.75C443.64 442.75 399.78 399.04 399.32 398.59L401.42 396.51C401.88 396.97 447.71 442.71 496.51 439.66L496.69 442.6C495.09 442.7 493.48 442.75 491.88 442.75Z"
        fill="#003C71"
      />
      <path
        d="M387.37 335.28C387.37 335.28 386.84 322.81 370.37 322.81H361.56C361.56 322.81 362.71 329.25 370.37 329.15C374.21 329.15 375.63 332.32 376.09 335.56C376.317 337.338 377.181 338.973 378.522 340.162C379.862 341.352 381.588 342.015 383.38 342.03H387.38L387.37 335.28Z"
        fill="#F4B587"
      />
      <path
        d="M294.51 486.76H275.32V176.18C275.32 169.815 277.849 163.71 282.35 159.209C286.85 154.709 292.955 152.18 299.32 152.18H322.12V171.37H299.3C298.031 171.373 296.814 171.878 295.916 172.776C295.018 173.674 294.513 174.89 294.51 176.16V486.76Z"
        fill="#CBCBCB"
      />
      <path
        d="M415.02 181.52H412.07C410.358 181.52 408.97 182.908 408.97 184.62V208.64C408.97 210.352 410.358 211.74 412.07 211.74H415.02C416.732 211.74 418.12 210.352 418.12 208.64V184.62C418.12 182.908 416.732 181.52 415.02 181.52Z"
        fill="#989898"
      />
      <path
        d="M300.03 233.46V230.51C300.03 228.798 298.642 227.41 296.93 227.41H272.91C271.198 227.41 269.81 228.798 269.81 230.51V233.46C269.81 235.172 271.198 236.56 272.91 236.56H296.93C298.642 236.56 300.03 235.172 300.03 233.46Z"
        fill="#989898"
      />
      <path
        d="M389.56 255.42V252.47C389.56 250.758 388.172 249.37 386.46 249.37H362.44C360.728 249.37 359.34 250.758 359.34 252.47V255.42C359.34 257.132 360.728 258.52 362.44 258.52H386.46C388.172 258.52 389.56 257.132 389.56 255.42Z"
        fill="#989898"
      />
      <path
        d="M233.17 271.65V268.7C233.17 266.988 231.782 265.6 230.07 265.6H206.05C204.338 265.6 202.95 266.988 202.95 268.7V271.65C202.95 273.362 204.338 274.75 206.05 274.75H230.07C231.782 274.75 233.17 273.362 233.17 271.65Z"
        fill="#989898"
      />
      <path
        d="M300.03 317.38V314.43C300.03 312.718 298.642 311.33 296.93 311.33H272.91C271.198 311.33 269.81 312.718 269.81 314.43V317.38C269.81 319.092 271.198 320.48 272.91 320.48H296.93C298.642 320.48 300.03 319.092 300.03 317.38Z"
        fill="#989898"
      />
      <path
        d="M300.02 386.52V383.57C300.02 381.858 298.632 380.47 296.92 380.47H272.9C271.188 380.47 269.8 381.858 269.8 383.57V386.52C269.8 388.232 271.188 389.62 272.9 389.62H296.92C298.632 389.62 300.02 388.232 300.02 386.52Z"
        fill="#989898"
      />
      <path d="M319.45 360.52H249.02L249.09 341.19H319.52L319.45 360.52Z" fill="#CBCBCB" />
      <path
        d="M251.82 335.37H248.87C247.158 335.37 245.77 336.758 245.77 338.47V362.49C245.77 364.202 247.158 365.59 248.87 365.59H251.82C253.532 365.59 254.92 364.202 254.92 362.49V338.47C254.92 336.758 253.532 335.37 251.82 335.37Z"
        fill="#989898"
      />
      <path
        d="M320.96 335.37H318.01C316.298 335.37 314.91 336.758 314.91 338.47V362.49C314.91 364.202 316.298 365.59 318.01 365.59H320.96C322.672 365.59 324.06 364.202 324.06 362.49V338.47C324.06 336.758 322.672 335.37 320.96 335.37Z"
        fill="#989898"
      />
      <path d="M511.3 307.9H508.35V354.73H511.3V307.9Z" fill="#003C71" />
      <path d="M395.686 371.714L395.15 374.615L476.905 389.723L477.441 386.823L395.686 371.714Z" fill="#003C71" />
      <path d="M387.37 332.204L385.891 350.625L388.832 350.861L390.311 332.44L387.37 332.204Z" fill="#003C71" />
      <path
        d="M509.83 391.35V380.75C509.83 380.75 510.96 371.32 501.54 369.66C492.12 368 484.92 367.44 484.92 367.44C484.92 367.44 484.36 373.63 495.17 375.39C496.32 378.61 484.47 378.5 479.53 372.68C479.215 372.328 478.806 372.075 478.351 371.95C477.895 371.825 477.414 371.835 476.964 371.977C476.514 372.12 476.114 372.39 475.814 372.754C475.513 373.118 475.325 373.561 475.27 374.03C474.29 382.33 479.44 393.28 509.83 391.35Z"
        fill="#F4B587"
      />
      <path d="M512.5 393.86H509.54V373.03H536.32V375.98H512.5V393.86Z" fill="#003C71" />
      <path
        d="M440.66 483.85V505.53H381.71C381.71 505.53 385.45 493.23 407.47 493.95C416.62 494.69 411.15 483.85 411.15 483.85H440.66Z"
        fill="#003C71"
      />
      <path
        d="M570.46 494.95C570.308 498.496 570.58 502.048 571.27 505.53H582.04V459.91H560.36V483.85C560.36 483.85 571.2 485.8 570.46 494.95Z"
        fill="#003C71"
      />
      <path d="M560.36 505.53C560.36 505.53 561.36 496.34 574.03 496.34L582.03 505.53H560.36Z" fill="#003C71" />
      <path d="M612.77 505.14H363.64V508.09H612.77V505.14Z" fill="#003C71" />
      <path d="M226.08 104.74H223.13V255.53H226.08V104.74Z" fill="#E5F0F6" />
      <path d="M219.43 104.74H216.48V255.53H219.43V104.74Z" fill="#E5F0F6" />
      <path d="M212.79 104.74H209.84V255.53H212.79V104.74Z" fill="#E5F0F6" />
      <path d="M354.02 166.97H329.01V169.92H354.02V166.97Z" fill="#E5F0F6" />
      <path d="M354.02 160.32H329.01V163.27H354.02V160.32Z" fill="#E5F0F6" />
      <path d="M354.02 153.68H329.01V156.63H354.02V153.68Z" fill="#E5F0F6" />
      <path
        d="M143.15 501.75C187.968 501.75 224.3 465.418 224.3 420.6C224.3 375.782 187.968 339.45 143.15 339.45C98.3322 339.45 62.0001 375.782 62.0001 420.6C62.0001 465.418 98.3322 501.75 143.15 501.75Z"
        fill="#FFD400"
      />
      <path d="M178.14 381.27H166.8V439.14H178.14V381.27Z" fill="#408DBC" />
      <path
        d="M178.41 361.21V374.51H166.53V361.21C163.872 362.547 161.743 364.742 160.486 367.439C159.23 370.136 158.92 373.178 159.607 376.073C160.294 378.968 161.938 381.547 164.272 383.392C166.606 385.237 169.495 386.24 172.47 386.24C175.446 386.24 178.334 385.237 180.668 383.392C183.002 381.547 184.646 378.968 185.333 376.073C186.02 373.178 185.71 370.136 184.454 367.439C183.198 364.742 181.068 362.547 178.41 361.21Z"
        fill="#408DBC"
      />
      <path
        d="M173.17 387.83H171.97C170.545 387.83 169.39 388.985 169.39 390.41V430.39C169.39 431.815 170.545 432.97 171.97 432.97H173.17C174.595 432.97 175.75 431.815 175.75 430.39V390.41C175.75 388.985 174.595 387.83 173.17 387.83Z"
        fill="#003C71"
      />
      <path
        d="M138.37 380.39L143.8 375.72L136.6 367.28L138.78 365.51L149.38 370.67C150.232 371.082 150.981 371.68 151.571 372.419C152.161 373.159 152.578 374.021 152.791 374.943C153.004 375.865 153.008 376.823 152.801 377.747C152.595 378.67 152.185 379.536 151.6 380.28L146.14 387.28L149.57 435.02C149.655 436.216 149.493 437.417 149.094 438.548C148.695 439.679 148.067 440.716 147.25 441.594C146.433 442.472 145.444 443.172 144.345 443.652C143.246 444.131 142.059 444.379 140.86 444.38C139.644 444.38 138.442 444.126 137.33 443.634C136.218 443.143 135.22 442.425 134.402 441.526C133.583 440.627 132.96 439.567 132.575 438.414C132.189 437.261 132.047 436.041 132.16 434.83L136.23 391.55L133.02 388.35C131.769 387.101 130.968 385.473 130.74 383.72L129.36 373.23L131.05 371.86L138.37 380.39Z"
        fill="#408DBC"
      />
      <path
        d="M135.83 395.46H146.7L149.54 434.99C149.635 436.2 149.476 437.415 149.075 438.56C148.674 439.705 148.039 440.754 147.21 441.64C146.382 442.526 145.378 443.23 144.262 443.707C143.147 444.184 141.944 444.423 140.731 444.41C139.518 444.397 138.321 444.131 137.216 443.63C136.112 443.129 135.123 442.403 134.314 441.499C133.505 440.595 132.893 439.533 132.517 438.38C132.141 437.226 132.009 436.007 132.13 434.8L135.83 395.46Z"
        fill="#003C71"
      />
      <path d="M143.139 374.991L134.764 382.96L136.116 384.38L144.49 376.41L143.139 374.991Z" fill="#003C71" />
      <path d="M189.56 393.45H96.7401V395.41H189.56V393.45Z" fill="#003C71" />
      <path d="M193.21 434.75H92.7701V456.89H193.21V434.75Z" fill="#FFF6CC" />
      <path d="M193.21 407.42H92.7701V429.56H193.21V407.42Z" fill="#FFF6CC" />
      <path d="M100.71 385.87H92.7701V456.9H100.71V385.87Z" fill="#FFF6CC" />
      <path d="M193.53 385.87H185.59V456.9H193.53V385.87Z" fill="#FFF6CC" />
      <path d="M193.53 455.91H92.7701V457.87H193.53V455.91Z" fill="#003C71" />
      <path d="M128.08 433.77H92.7701V435.73H128.08V433.77Z" fill="#003C71" />
      <path d="M178.13 428.58H92.7701V430.54H178.13V428.58Z" fill="#003C71" />
      <path d="M153.2 406.43H92.7701V408.39H153.2V406.43Z" fill="#003C71" />
      <path
        d="M62.9901 420.6H60.9901C60.9978 402.044 67.2869 384.037 78.8335 369.51C90.38 354.984 106.504 344.794 124.58 340.6L125.02 342.52C107.371 346.612 91.628 356.561 80.3565 370.745C69.0851 384.929 62.9497 402.513 62.9501 420.63L62.9901 420.6Z"
        fill="#003C71"
      />
      <path
        d="M143.15 502.73V500.73C160.321 500.734 177.039 495.223 190.843 485.011C204.646 474.798 214.806 460.423 219.825 444.002C224.845 427.581 224.458 409.983 218.724 393.798C212.989 377.613 202.208 363.697 187.97 354.1L189.07 352.47C203.657 362.304 214.7 376.561 220.575 393.143C226.45 409.725 226.845 427.755 221.703 444.578C216.561 461.401 206.152 476.129 192.011 486.592C177.869 497.056 160.742 502.702 143.15 502.7V502.73Z"
        fill="#003C71"
      />
      <path
        d="M143.6 520.76C129.109 520.817 114.781 517.697 101.626 511.619C88.4715 505.541 76.809 496.652 67.4601 485.58L68.9601 484.31C78.1252 495.163 89.5582 503.875 102.454 509.833C115.35 515.79 129.395 518.847 143.6 518.79C157.555 518.77 171.352 515.838 184.109 510.181C196.866 504.525 208.302 496.268 217.686 485.939C227.07 475.61 234.195 463.437 238.606 450.197C243.016 436.957 244.615 422.943 243.3 409.05L245.3 408.86C246.64 423.029 245.008 437.322 240.508 450.825C236.008 464.327 228.74 476.742 219.167 487.274C209.595 497.807 197.929 506.225 184.917 511.991C171.905 517.757 157.833 520.744 143.6 520.76Z"
        fill="#003C71"
      />
      <path
        d="M116.27 383.08H117.6C118.353 383.08 119.076 383.379 119.608 383.912C120.141 384.444 120.44 385.167 120.44 385.92V453.11H113.44V385.92C113.44 385.169 113.738 384.448 114.268 383.915C114.799 383.383 115.519 383.083 116.27 383.08Z"
        fill="#408DBC"
      />
      <path
        d="M115.6 451.28H118.26C119.764 451.28 121.206 451.877 122.269 452.941C123.333 454.004 123.93 455.446 123.93 456.95V459.13H109.93V456.95C109.93 455.446 110.527 454.004 111.591 452.941C112.654 451.877 114.096 451.28 115.6 451.28Z"
        fill="#FF7D20"
      />
      <path
        d="M116.94 457.45C112.113 457.45 107.484 459.368 104.071 462.781C100.658 466.194 98.7401 470.823 98.7401 475.65H135.14C135.14 470.823 133.223 466.194 129.809 462.781C126.396 459.368 121.767 457.45 116.94 457.45Z"
        fill="#FF9B53"
      />
      <path
        d="M134.84 475.65H99.0401C97.5821 475.65 96.4001 476.832 96.4001 478.29V479.52C96.4001 480.978 97.5821 482.16 99.0401 482.16H134.84C136.298 482.16 137.48 480.978 137.48 479.52V478.29C137.48 476.832 136.298 475.65 134.84 475.65Z"
        fill="#FF7D20"
      />
      <path
        d="M99.7201 475.65H97.7201C97.7213 472.17 98.669 468.755 100.462 465.772C102.255 462.788 104.825 460.349 107.898 458.714C110.971 457.08 114.43 456.312 117.906 456.493C121.382 456.673 124.743 457.796 127.63 459.74L126.6 461.37C124.009 459.626 120.992 458.619 117.872 458.458C114.752 458.296 111.647 458.985 108.889 460.452C106.132 461.919 103.824 464.108 102.214 466.785C100.604 469.462 99.7527 472.526 99.7501 475.65H99.7201Z"
        fill="#003C71"
      />
      <path d="M137.48 474.67H95.7201V476.63H137.48V474.67Z" fill="#003C71" />
      <path d="M137.48 481.34H95.7201V483.3H137.48V481.34Z" fill="#003C71" />
      <path d="M117.92 390.78H115.96V444.35H117.92V390.78Z" fill="#003C71" />
    </svg>
  );
};
