import styled from "styled-components";

import { theme } from "@byko/lib-styles";

import type { PopoutContainerProps, TooltipContainerProps } from "./interface";

export const InfoIconClickable = styled.button``;

export const PopoutContainer = styled.div<PopoutContainerProps>`
  position: absolute;
  z-index: 3000;
  top: 0px;
  display: unset;
  width: ${(props): string => props.width.toString()}px;
  opacity: ${(props): string => (props.showTooltip ? "1" : "0")};
  pointer-events: none;
  transform: translate3d(0, ${(props): string => (props.showTooltip ? "0" : "16px")}, 0);
  transition: 150ms;
`;

export const InfoIconContainer = styled.div`
  position: relative;
  left: 0px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 16px;
  transform: translate3d(0, -6px, 0);
  svg {
    cursor: pointer;
  }
`;

export const TooltipContainer = styled.div`
  position: relative;
  top: 32px;
  box-sizing: border-box;
  padding: 16px;
  border: 2px solid ${theme.palette.blue.main};
  background-color: ${theme.palette.white.main};
  transform: translate3d(0, -6px, 0);
  &::before {
    position: absolute;
    top: 0px;
    width: 16px;
    height: 16px;
    background-color: ${theme.palette.blue.main};
    content: "";
  }
  &::after {
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    height: 12px;
    background-color: ${theme.palette.white.main};
    content: "";
  }
`;

export const TooltipWrapper = styled.div<TooltipContainerProps>`
  position: relative;
  max-width: 320px;
  ${PopoutContainer} {
    ${(props): string => (props.arrowLocation === "right" ? "transform: translate3d(calc(-100% + 52px), 0, 0)" : "")}
    ${(props): string => (props.arrowLocation === "center" ? "transform: translate3d(calc(-50% + 18px), 0, 0)" : "")}
  }
  ${TooltipContainer} {
    &::before {
      ${(props): string => (props.arrowLocation === "left" ? "left: 16px;" : "")}
      ${(props): string => (props.arrowLocation === "right" ? "right: 16px;" : "")}
      ${(props): string => (props.arrowLocation === "center" ? "left: 50%;" : "")}
      transform: translate3d(${(props): string => (props.arrowLocation === "center" ? "-50%" : "0")}, -50%, 0)
        rotate(-45deg);
    }
  }
`;
