import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const ShovelLineDecorative: BykoReactDecorativeIconComponentType = ({ size, color = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1144_7130)">
        <path
          d="M24 10C20.686 10 18 7.314 18 4V2H30V4C30 7.314 27.314 10 24 10Z"
          stroke={color}
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2.5"
        />
        <path
          // eslint-disable-next-line max-len
          d="M24 47C24 47 13 45.0833 13 38V25C13 24.4477 13.4477 24 14 24H34C34.5523 24 35 24.4477 35 25V38C35 45 24 47 24 47Z"
          stroke={color}
          strokeLinecap="square"
          strokeWidth="2.5"
        />
        <path d="M24 10V34" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
      </g>
      <defs>
        <clipPath id="clip0_1144_7130">
          <rect fill="white" height="48" width="48" />
        </clipPath>
      </defs>
    </svg>
  );
};
