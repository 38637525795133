import styled from "styled-components";

export const FlexRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 32px auto;
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  .input-error-text {
    transform: translateY(32px);
  }
`;

export const Inputs = styled.div`
  display: flex;
  height: fit-content;
  height: 100%;
  flex-direction: column;
  justify-content: center;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Form = styled.form`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SecurityCodeSectionWrapper = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  transition: 200ms ease-in-out;

  &.show {
    grid-template-rows: 1fr;
  }
`;

export const SecurityCodeSectionContent = styled.div`
  overflow: hidden;
`;
