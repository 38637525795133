import styled from "styled-components";
import { ifProp } from "styled-tools";

import { gridTheme, scrollStyle, theme } from "@byko/lib-styles";

import type { FocusLineProps, ListItemProps, MenuProps, MenuWrapperProps, SelectContainerProps } from "@byko/lib-core";
import type { Maybe } from "@byko/types-utils";

export const SelectContainer = styled.div<SelectContainerProps>`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 56px;
  border-top: 1px solid ${(props): string => (props.isOpen ? theme.palette.blue.main : "transparent")};
  border-right: 1px solid ${(props): string => (props.isOpen ? theme.palette.blue.main : "transparent")};
  border-left: 1px solid ${(props): string => (props.isOpen ? theme.palette.blue.main : "transparent")};
  transition: box-shadow 0.15s;
  transition: 0.3s;
`;

export const IconWrapper = styled.div`
  height: 24px;
  padding-right: 24px;
  padding-left: 24px;
  margin-left: auto;
  .byko-icon {
    stroke: ${theme.palette.yellow.main};
    transition: transform 0.15s ease-out, fill 0.15s;
  }
`;

export const FocusLine = styled.div<FocusLineProps>`
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  display: block;
  width: ${ifProp({ isOpen: true }, "100%", "0")};
  height: 1px;
  background-color: ${theme.palette.blue.main};
  transition: width 0.15s ease-out;
`;

export const SelectBtn = styled.button`
  position: relative;
  z-index: 0;
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  padding-left: 16px;
  border: 0;
  background-color: ${theme.palette.white.main};
  color: ${theme.palette.blue.main};
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
  transition: border-color 0.15s;

  .byko-icon {
    stroke: ${theme.palette.blue.main};
    transition: transform 0.15s ease-out, fill 0.15s;
  }

  &:hover {
    box-shadow: 0px 18px 45px rgba(0, 0, 0, 0.09);
    outline: none;
    transition: background-color 200ms ease, box-shadow 300ms ease, border 200ms ease;
  }
  &:focus {
    outline: none;
  }
`;

export const MenuWrapper = styled.div<MenuWrapperProps>`
  position: absolute;
  right: ${ifProp({ align: "right" }, "0", "inherit")};
  left: ${ifProp({ align: "left" }, "-5px", "inherit")};
  min-width: ${ifProp({ fit: true }, "calc(100% + 6px)", "300px")};
  padding-bottom: 4px;
  padding-left: 4px;
  pointer-events: ${ifProp({ isOpen: true }, "unset", "none")};
  transition: opacity 0.15s;
`;

export const Menu = styled.ul<MenuProps>`
  z-index: 2;
  max-height: 255px;
  border-right: 1px solid ${(props): string => (props.isOpen ? theme.palette.blue.main : "transparent")};
  border-bottom: 1px solid ${(props): string => (props.isOpen ? theme.palette.blue.main : "transparent")};
  border-left: 1px solid ${(props): string => (props.isOpen ? theme.palette.blue.main : "transparent")};
  background-color: ${theme.palette.white.main};
  box-shadow: ${(props): string => (props.isOpen ? " 0px 18px 45px rgba(0, 0, 0, 0.09);" : "none")};
  list-style: none;
  opacity: ${ifProp({ isOpen: true }, "1", "0")};
  overflow-y: scroll;
  pointer-events: ${ifProp({ isOpen: true }, "unset", "none")};
  transition: 0.3s;
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${theme.palette.white.main};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${theme.palette.blue.main};
  }
`;

export const MenuInner = styled.div`
  max-height: 400px;
  ${scrollStyle({ color: "dark-blue" })}
`;

export const ListItem = styled.button<ListItemProps>`
  display: flex;
  width: 100%;
  height: 56px;
  align-items: center;
  padding: 0 16px;
  border-top: 1px solid ${theme.palette.gray[10]};
  background-color: ${(props): string =>
    props.highlighted ? theme.palette.blue.lightest : props.disabledLine ? "transparent" : "transparent"};
  color: ${(props): string => (props.disabledLine ? theme.palette.gray[20] : theme.palette.blue.dark)};
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  font-weight: ${ifProp({ selected: true }, "700", "400")};
  text-align: left;
  transition: border-color 0.15s, background-color 0.15s;
  &:hover {
    background-color: ${(props): string => (props.disabledLine ? theme.palette.gray[5] : theme.palette.blue.lightest)};
  }
`;
export const SearchWrapper = styled.li`
  width: 100%;
  height: 56px;
`;

export const Checkbox = styled.div`
  display: flex;
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.palette.black.main};
  margin-right: 8px;
  transition: background-color 0.15s, border-color 0.15s;
`;

export const PrefixIcon = styled.div`
  position: absolute;
  z-index: 1;
  top: calc(50% - 8px);
  right: 17px;
  transition: transform 0.15s ease-out;
`;

export const PlaceholderText = styled.p<{ textColor?: Maybe<string> }>`
  color: ${(props): string => (props.textColor ? props.textColor : theme.palette.blue.main)};
  font-weight: normal;
  @media screen and (max-width: ${gridTheme.breakpoints.xs}px) {
    font-size: 16px;
  }
`;
