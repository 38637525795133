/* eslint-disable max-len */
import * as React from "react";
import { BykoReactIconComponentType } from "@byko/lib-core";

export const StafraentIllustration: BykoReactIconComponentType = ({ size = 200 }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 662 611" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M436.38 184.01C477.437 184.01 510.72 150.727 510.72 109.67C510.72 68.6132 477.437 35.33 436.38 35.33C395.323 35.33 362.04 68.6132 362.04 109.67C362.04 150.727 395.323 184.01 436.38 184.01Z"
        fill="#E5E5E5"
      />
      <path
        d="M400.88 322.06H218.31C208.629 322.06 200.78 329.908 200.78 339.59V463.35C200.78 473.032 208.629 480.88 218.31 480.88H400.88C410.562 480.88 418.41 473.032 418.41 463.35V339.59C418.41 329.908 410.562 322.06 400.88 322.06Z"
        fill="#82B5D3"
      />
      <path
        d="M395.37 331.45H224.5C222.719 331.449 220.956 331.798 219.31 332.478C217.664 333.159 216.169 334.156 214.909 335.415C213.649 336.673 212.649 338.167 211.966 339.812C211.283 341.456 210.931 343.219 210.93 345V455.17C210.93 459.414 212.616 463.483 215.616 466.484C218.617 469.484 222.687 471.17 226.93 471.17H393.27C397.514 471.17 401.583 469.484 404.584 466.484C407.584 463.483 409.27 459.414 409.27 455.17V345.32C409.27 343.496 408.91 341.69 408.211 340.005C407.513 338.321 406.488 336.79 405.197 335.502C403.906 334.213 402.373 333.192 400.687 332.497C399.001 331.802 397.194 331.446 395.37 331.45Z"
        fill="white"
      />
      <path
        d="M395.37 331.45H341.01L251.34 471.15H393.27C397.514 471.15 401.583 469.464 404.584 466.464C407.584 463.463 409.27 459.393 409.27 455.15V345.32C409.27 343.496 408.91 341.69 408.211 340.005C407.513 338.321 406.488 336.79 405.197 335.502C403.906 334.213 402.373 333.192 400.687 332.497C399.001 331.802 397.194 331.446 395.37 331.45Z"
        fill="#E5F0F6"
      />
      <path
        d="M373.3 350.1H336.35C333.423 350.1 331.05 352.473 331.05 355.4V384.59C331.05 387.517 333.423 389.89 336.35 389.89H373.3C376.227 389.89 378.6 387.517 378.6 384.59V355.4C378.6 352.473 376.227 350.1 373.3 350.1Z"
        fill="#408DBC"
      />
      <path
        d="M385.21 209H300.28C297.047 209 293.947 210.284 291.66 212.57C289.374 214.856 288.09 217.957 288.09 221.19V288.28C288.09 291.513 289.374 294.614 291.66 296.9C293.947 299.186 297.047 300.47 300.28 300.47H385.21C386.811 300.47 388.396 300.155 389.875 299.542C391.354 298.929 392.698 298.032 393.83 296.9C394.962 295.768 395.86 294.424 396.472 292.945C397.085 291.466 397.4 289.881 397.4 288.28V221.18C397.397 217.949 396.112 214.851 393.826 212.567C391.54 210.283 388.441 209 385.21 209Z"
        fill="#FFD400"
      />
      <path
        d="M211.03 382.34C223.19 382.92 223.2 401.88 218.03 430.86C226.35 467.93 216.75 492.5 198.81 503.38L186 564.63L131.6 542.45C131.6 542.45 176.45 370 188.6 335.8C188.6 335.8 205.25 327.26 198.84 368.25C192.43 409.24 198.84 414.36 198.84 414.36C198.84 414.36 197.6 381.7 211.03 382.34Z"
        fill="#F59795"
      />
      <path
        d="M204.31 395.47L216.8 397.39C216.8 397.39 216.52 386.58 210.6 386C204.68 385.42 204.31 395.47 204.31 395.47Z"
        fill="white"
      />
      <path
        d="M516.22 532C501.97 504 469.17 469.46 469.17 469.46C469.17 469.46 463.06 415.66 441.64 403.86C424.22 394.25 402.22 394.95 389.41 401.36C389.41 401.36 374.47 375.74 368.06 371.47C361.65 367.2 347.14 370.28 369.35 409.9C377.46 423.62 379.59 430.39 379.59 430.39C379.59 430.39 366.04 411.5 354.83 426.23C354.83 426.23 371.34 475.47 416.43 499.85C426.19 520.31 431.43 537.01 444.28 562.39L516.22 532Z"
        fill="#F59795"
      />
      <path
        d="M372.47 381.35L362.6 386.48C362.6 386.48 356.9 376.96 363.25 374.23C367.82 372.28 372.47 381.35 372.47 381.35Z"
        fill="white"
      />
      <path
        d="M285.28 436.58H255.65C251.634 436.581 247.77 435.046 244.85 432.288C241.931 429.53 240.177 425.76 239.95 421.75L238.48 399.93H302.48L301 421.75C300.773 425.763 299.016 429.536 296.093 432.295C293.169 435.053 289.3 436.586 285.28 436.58Z"
        fill="#FFD400"
      />
      <path
        d="M292.68 399.93H287.68V388.41C287.68 387.63 287.37 386.883 286.819 386.331C286.268 385.78 285.52 385.47 284.74 385.47H256.6C255.82 385.47 255.073 385.78 254.521 386.331C253.97 386.883 253.66 387.63 253.66 388.41V399.93H248.66V388.41C248.676 386.309 249.517 384.299 251.003 382.813C252.489 381.327 254.499 380.486 256.6 380.47H284.79C286.891 380.486 288.902 381.327 290.387 382.813C291.873 384.299 292.714 386.309 292.73 388.41L292.68 399.93Z"
        fill="#003C71"
      />
      <path
        d="M268.31 437.58H255.65C251.38 437.577 247.273 435.943 244.169 433.011C241.064 430.08 239.198 426.073 238.95 421.81L237.41 398.93H302.41V400.93H239.6L241 421.68C241.212 425.436 242.853 428.968 245.586 431.553C248.32 434.137 251.938 435.578 255.7 435.58H268.36L268.31 437.58Z"
        fill="#003C71"
      />
      <path d="M268.192 399.221L252.007 415.407L253.421 416.821L269.606 400.635L268.192 399.221Z" fill="#003C71" />
      <path d="M283.786 399.227L267.607 415.406L269.022 416.82L285.2 400.641L283.786 399.227Z" fill="#003C71" />
      <path d="M299.401 399.212L283.215 415.398L284.63 416.812L300.815 400.627L299.401 399.212Z" fill="#003C71" />
      <path
        d="M271.6 370.38L269.73 369.67C273.52 359.67 279.63 352.92 287.87 349.57C303.47 343.25 321.01 351.31 321.75 351.65L320.9 353.46C320.73 353.38 303.39 345.46 288.61 351.46C280.89 354.56 275.17 360.93 271.6 370.38Z"
        fill="#003C71"
      />
      <path
        d="M268.12 365.72L268.94 370.66C268.98 370.908 269.08 371.143 269.231 371.344C269.382 371.545 269.581 371.706 269.809 371.813C270.037 371.919 270.287 371.968 270.538 371.956C270.79 371.943 271.034 371.869 271.25 371.74L275.53 369.21C275.771 369.067 275.969 368.861 276.102 368.614C276.236 368.368 276.3 368.089 276.287 367.809C276.275 367.529 276.187 367.257 276.033 367.023C275.878 366.789 275.663 366.601 275.41 366.48L270.31 364.07C270.055 363.95 269.772 363.902 269.491 363.931C269.21 363.96 268.943 364.065 268.718 364.235C268.492 364.404 268.317 364.632 268.212 364.894C268.107 365.156 268.075 365.442 268.12 365.72Z"
        fill="#003C71"
      />
      <path
        d="M199.6 442.56C199.35 440.7 193.67 396.83 204.31 384.56C205.127 383.552 206.157 382.737 207.325 382.173C208.494 381.61 209.773 381.311 211.07 381.3V383.3C210.057 383.31 209.059 383.545 208.149 383.99C207.239 384.435 206.44 385.077 205.81 385.87C195.76 397.42 201.51 441.81 201.57 442.25L199.6 442.56Z"
        fill="#003C71"
      />
      <path
        d="M186.42 505.72L186.23 503.72C197.14 502.65 205.54 498.1 211.17 490.19C226.05 469.28 217.17 431.46 217.1 431.08L219.05 430.62C220.621 437.886 221.531 445.28 221.77 452.71C222.14 464.07 220.87 480.01 212.77 491.35C206.81 499.76 197.94 504.59 186.42 505.72Z"
        fill="#003C71"
      />
      <path d="M181.276 355.674L130.662 542.22L132.592 542.743L183.206 356.198L181.276 355.674Z" fill="#003C71" />
      <path
        d="M385.23 441.43C385.1 441.18 371.91 416.43 361.37 396.24C356.72 387.36 355.22 380.16 356.91 374.84C357.505 372.639 358.904 370.74 360.83 369.52L361.75 371.3C361.67 371.35 353.15 376.2 363.14 395.3C373.67 415.45 386.86 440.23 386.99 440.48L385.23 441.43Z"
        fill="#003C71"
      />
      <path
        d="M415.96 500.73C377.7 480.04 361.32 443.19 361.16 442.83L362.99 442.03C363.15 442.39 379.28 478.62 416.91 498.97L415.96 500.73Z"
        fill="#003C71"
      />
      <path d="M385.602 393.149L383.908 394.213L399.328 418.785L401.022 417.722L385.602 393.149Z" fill="#003C71" />
      <path d="M410.057 396.838L408.427 397.997L417.721 411.07L419.351 409.911L410.057 396.838Z" fill="#003C71" />
      <path
        d="M413.89 385.55H389.26V383.55H413.89C423.322 383.55 432.661 381.692 441.374 378.083C450.088 374.474 458.005 369.184 464.675 362.514C471.344 355.845 476.634 347.928 480.243 339.214C483.852 330.501 485.71 321.162 485.71 311.73C485.71 302.299 483.852 292.959 480.243 284.246C476.634 275.532 471.344 267.615 464.675 260.946C458.005 254.277 450.088 248.986 441.374 245.377C432.661 241.768 423.322 239.91 413.89 239.91V237.91C433.468 237.91 452.245 245.687 466.089 259.531C479.933 273.375 487.71 292.152 487.71 311.73C487.71 331.308 479.933 350.085 466.089 363.929C452.245 377.773 433.468 385.55 413.89 385.55Z"
        fill="#003C71"
      />
      <path
        d="M412.68 243.07C414.978 243.07 416.84 241.208 416.84 238.91C416.84 236.612 414.978 234.75 412.68 234.75C410.383 234.75 408.52 236.612 408.52 238.91C408.52 241.208 410.383 243.07 412.68 243.07Z"
        fill="#003C71"
      />
      <path
        d="M365.49 90.4C367.788 90.4 369.65 88.5375 369.65 86.24C369.65 83.9425 367.788 82.08 365.49 82.08C363.193 82.08 361.33 83.9425 361.33 86.24C361.33 88.5375 363.193 90.4 365.49 90.4Z"
        fill="#003C71"
      />
      <path
        d="M486.79 314.18C489.088 314.18 490.95 312.317 490.95 310.02C490.95 307.722 489.088 305.86 486.79 305.86C484.493 305.86 482.63 307.722 482.63 310.02C482.63 312.317 484.493 314.18 486.79 314.18Z"
        fill="#003C71"
      />
      <path
        d="M432.66 157.53H424.13V115.84L448.6 81.71V141.57C448.59 145.796 446.907 149.847 443.921 152.837C440.935 155.827 436.886 157.514 432.66 157.53Z"
        fill="#FF7D20"
      />
      <path
        d="M411.71 138.85L406.71 135.53L461.32 53.27L483.39 45.55C483.914 47.0512 483.821 48.699 483.131 50.1315C482.441 51.564 481.211 52.6641 479.71 53.19L465.21 58.27L411.71 138.85Z"
        fill="#003C71"
      />
      <path
        d="M350.6 157.53H424.09V128.84H407.24L398.67 132.84C398.67 132.84 361.9 134.16 350.6 157.53Z"
        fill="#3D8CBB"
      />
      <path d="M363.85 143.19H398.23V132.8C398.23 132.8 382.12 131.94 363.85 143.19Z" fill="#003C71" />
      <path
        d="M443.39 128.84H429.36C428.62 128.84 428.02 129.44 428.02 130.18V130.19C428.02 130.93 428.62 131.53 429.36 131.53H443.39C444.13 131.53 444.73 130.93 444.73 130.19V130.18C444.73 129.44 444.13 128.84 443.39 128.84Z"
        fill="#003C71"
      />
      <path
        d="M443.39 134.42H429.36C428.62 134.42 428.02 135.02 428.02 135.76V135.77C428.02 136.51 428.62 137.11 429.36 137.11H443.39C444.13 137.11 444.73 136.51 444.73 135.77V135.76C444.73 135.02 444.13 134.42 443.39 134.42Z"
        fill="#003C71"
      />
      <path
        d="M443.39 140H429.36C428.62 140 428.02 140.6 428.02 141.34V141.35C428.02 142.09 428.62 142.69 429.36 142.69H443.39C444.13 142.69 444.73 142.09 444.73 141.35V141.34C444.73 140.6 444.13 140 443.39 140Z"
        fill="#003C71"
      />
      <path d="M428.31 113.89H417.09V119.62H428.31V113.89Z" fill="#3D8CBB" />
      <path d="M444.73 89.33H433.51V95.06H444.73V89.33Z" fill="#3D8CBB" />
      <path d="M461.16 64.78H449.94V70.51H461.16V64.78Z" fill="#3D8CBB" />
      <path
        d="M415.12 166.26C421.808 166.26 427.23 160.838 427.23 154.15C427.23 147.462 421.808 142.04 415.12 142.04C408.432 142.04 403.01 147.462 403.01 154.15C403.01 160.838 408.432 166.26 415.12 166.26Z"
        fill="#003C71"
      />
      <path
        d="M415.12 157.3C416.705 157.3 417.99 156.015 417.99 154.43C417.99 152.845 416.705 151.56 415.12 151.56C413.535 151.56 412.25 152.845 412.25 154.43C412.25 156.015 413.535 157.3 415.12 157.3Z"
        fill="#3D8CBB"
      />
      <path
        d="M369.79 166.26C374.739 166.26 378.75 162.249 378.75 157.3C378.75 152.352 374.739 148.34 369.79 148.34C364.842 148.34 360.83 152.352 360.83 157.3C360.83 162.249 364.842 166.26 369.79 166.26Z"
        fill="#003C71"
      />
      <path
        d="M369.79 160.17C371.375 160.17 372.66 158.885 372.66 157.3C372.66 155.715 371.375 154.43 369.79 154.43C368.205 154.43 366.92 155.715 366.92 157.3C366.92 158.885 368.205 160.17 369.79 160.17Z"
        fill="#3D8CBB"
      />
      <path d="M325.36 139.11H323.36V193.51H325.36V139.11Z" fill="#59B49C" />
      <path d="M331.69 139.11H329.69V193.51H331.69V139.11Z" fill="#59B49C" />
      <path d="M338.02 139.11H336.02V193.51H338.02V139.11Z" fill="#59B49C" />
      <path d="M344.34 139.11H342.34V193.51H344.34V139.11Z" fill="#59B49C" />
      <path d="M350.67 139.11H348.67V193.51H350.67V139.11Z" fill="#59B49C" />
      <path d="M357 162.17H355V168.26H357V162.17Z" fill="#59B49C" />
      <path d="M363.32 162.17H361.32V168.26H363.32V162.17Z" fill="#59B49C" />
      <path d="M369.65 162.17H367.65V168.26H369.65V162.17Z" fill="#59B49C" />
      <path d="M375.98 162.17H373.98V168.26H375.98V162.17Z" fill="#59B49C" />
      <path d="M382.3 162.17H380.3V168.26H382.3V162.17Z" fill="#59B49C" />
      <path d="M388.63 162.17H386.63V168.26H388.63V162.17Z" fill="#59B49C" />
      <path d="M394.95 162.17H392.95V168.26H394.95V162.17Z" fill="#59B49C" />
      <path d="M401.28 162.17H399.28V168.26H401.28V162.17Z" fill="#59B49C" />
      <path d="M407.61 162.17H405.61V168.26H407.61V162.17Z" fill="#59B49C" />
      <path d="M413.93 162.17H411.93V168.26H413.93V162.17Z" fill="#59B49C" />
      <path d="M420.26 162.17H418.26V168.26H420.26V162.17Z" fill="#59B49C" />
      <path d="M426.59 162.17H424.59V168.26H426.59V162.17Z" fill="#59B49C" />
      <path d="M432.91 162.17H430.91V168.26H432.91V162.17Z" fill="#59B49C" />
      <path d="M439.24 162.17H437.24V168.26H439.24V162.17Z" fill="#59B49C" />
      <path d="M445.57 162.17H443.57V168.26H445.57V162.17Z" fill="#59B49C" />
      <path d="M451.89 162.17H449.89V168.26H451.89V162.17Z" fill="#59B49C" />
      <path d="M458.22 162.17H456.22V168.26H458.22V162.17Z" fill="#59B49C" />
      <path d="M464.55 162.17H462.55V168.26H464.55V162.17Z" fill="#59B49C" />
      <path d="M360.16 170.8H358.16V176.89H360.16V170.8Z" fill="#59B49C" />
      <path d="M366.49 170.8H364.49V176.89H366.49V170.8Z" fill="#59B49C" />
      <path d="M372.81 170.8H370.81V176.89H372.81V170.8Z" fill="#59B49C" />
      <path d="M379.14 170.8H377.14V176.89H379.14V170.8Z" fill="#59B49C" />
      <path d="M385.47 170.8H383.47V176.89H385.47V170.8Z" fill="#59B49C" />
      <path d="M391.79 170.8H389.79V176.89H391.79V170.8Z" fill="#59B49C" />
      <path d="M398.12 170.8H396.12V176.89H398.12V170.8Z" fill="#59B49C" />
      <path d="M404.45 170.8H402.45V176.89H404.45V170.8Z" fill="#59B49C" />
      <path d="M410.77 170.8H408.77V176.89H410.77V170.8Z" fill="#59B49C" />
      <path d="M417.1 170.8H415.1V176.89H417.1V170.8Z" fill="#59B49C" />
      <path d="M423.42 170.8H421.42V176.89H423.42V170.8Z" fill="#59B49C" />
      <path d="M429.75 170.8H427.75V176.89H429.75V170.8Z" fill="#59B49C" />
      <path d="M436.08 170.8H434.08V176.89H436.08V170.8Z" fill="#59B49C" />
      <path d="M442.4 170.8H440.4V176.89H442.4V170.8Z" fill="#59B49C" />
      <path d="M448.73 170.8H446.73V176.89H448.73V170.8Z" fill="#59B49C" />
      <path d="M455.06 170.8H453.06V176.89H455.06V170.8Z" fill="#59B49C" />
      <path d="M461.38 170.8H459.38V176.89H461.38V170.8Z" fill="#59B49C" />
      <path d="M467.71 170.8H465.71V176.89H467.71V170.8Z" fill="#59B49C" />
      <path d="M357 178.79H355V184.88H357V178.79Z" fill="#59B49C" />
      <path d="M363.32 178.79H361.32V184.88H363.32V178.79Z" fill="#59B49C" />
      <path d="M369.65 178.79H367.65V184.88H369.65V178.79Z" fill="#59B49C" />
      <path d="M375.98 178.79H373.98V184.88H375.98V178.79Z" fill="#59B49C" />
      <path d="M382.3 178.79H380.3V184.88H382.3V178.79Z" fill="#59B49C" />
      <path d="M388.63 178.79H386.63V184.88H388.63V178.79Z" fill="#59B49C" />
      <path d="M394.95 178.79H392.95V184.88H394.95V178.79Z" fill="#59B49C" />
      <path d="M401.28 178.79H399.28V184.88H401.28V178.79Z" fill="#59B49C" />
      <path d="M407.61 178.79H405.61V184.88H407.61V178.79Z" fill="#59B49C" />
      <path d="M413.93 178.79H411.93V184.88H413.93V178.79Z" fill="#59B49C" />
      <path d="M420.26 178.79H418.26V184.88H420.26V178.79Z" fill="#59B49C" />
      <path d="M426.59 178.79H424.59V184.88H426.59V178.79Z" fill="#59B49C" />
      <path d="M432.91 178.79H430.91V184.88H432.91V178.79Z" fill="#59B49C" />
      <path d="M439.24 178.79H437.24V184.88H439.24V178.79Z" fill="#59B49C" />
      <path d="M445.57 178.79H443.57V184.88H445.57V178.79Z" fill="#59B49C" />
      <path d="M451.89 178.79H449.89V184.88H451.89V178.79Z" fill="#59B49C" />
      <path d="M458.22 178.79H456.22V184.88H458.22V178.79Z" fill="#59B49C" />
      <path d="M464.55 178.79H462.55V184.88H464.55V178.79Z" fill="#59B49C" />
      <path d="M360.16 187.43H358.16V193.52H360.16V187.43Z" fill="#59B49C" />
      <path d="M366.49 187.43H364.49V193.52H366.49V187.43Z" fill="#59B49C" />
      <path d="M372.81 187.43H370.81V193.52H372.81V187.43Z" fill="#59B49C" />
      <path d="M379.14 187.43H377.14V193.52H379.14V187.43Z" fill="#59B49C" />
      <path d="M385.47 187.43H383.47V193.52H385.47V187.43Z" fill="#59B49C" />
      <path d="M391.79 187.43H389.79V193.52H391.79V187.43Z" fill="#59B49C" />
      <path d="M398.12 187.43H396.12V193.52H398.12V187.43Z" fill="#59B49C" />
      <path d="M404.45 187.43H402.45V193.52H404.45V187.43Z" fill="#59B49C" />
      <path d="M410.77 187.43H408.77V193.52H410.77V187.43Z" fill="#59B49C" />
      <path d="M417.1 187.43H415.1V193.52H417.1V187.43Z" fill="#59B49C" />
      <path d="M423.42 187.43H421.42V193.52H423.42V187.43Z" fill="#59B49C" />
      <path d="M429.75 187.43H427.75V193.52H429.75V187.43Z" fill="#59B49C" />
      <path d="M436.08 187.43H434.08V193.52H436.08V187.43Z" fill="#59B49C" />
      <path d="M442.4 187.43H440.4V193.52H442.4V187.43Z" fill="#59B49C" />
      <path d="M448.73 187.43H446.73V193.52H448.73V187.43Z" fill="#59B49C" />
      <path d="M455.06 187.43H453.06V193.52H455.06V187.43Z" fill="#59B49C" />
      <path d="M461.38 187.43H459.38V193.52H461.38V187.43Z" fill="#59B49C" />
      <path d="M467.71 187.43H465.71V193.52H467.71V187.43Z" fill="#59B49C" />
      <path
        d="M345.34 264.57C336.99 261.5 333.78 256.71 332.55 253.24C331.68 250.804 331.476 248.181 331.96 245.64L333.96 245.98V246.06C333.55 247.94 332.23 257.6 346.07 262.69L345.34 264.57Z"
        fill="#003C71"
      />
      <path
        d="M348.27 260.23C348.27 260.23 323.12 275 327.36 288.66C327.755 289.722 328.394 290.675 329.226 291.443C330.059 292.211 331.06 292.772 332.15 293.08C333.95 293.58 335.86 293.14 336.54 291.39C336.79 290.789 336.844 290.125 336.695 289.491C336.546 288.857 336.201 288.287 335.71 287.86C334.05 286.48 332.37 283.78 336.31 279.64C342.37 273.27 352.31 265.92 352.31 265.92L348.27 260.23Z"
        fill="#003C71"
      />
      <path
        d="M350.72 240C350.72 240 340.35 231 328.87 240.95C317.39 250.9 308.09 249.81 303.37 250.14C300.17 250.36 297.84 252.88 297.27 256.59C297.167 257.317 297.229 258.057 297.451 258.756C297.673 259.456 298.05 260.096 298.553 260.63C299.056 261.164 299.673 261.578 300.358 261.841C301.043 262.104 301.779 262.21 302.51 262.15C303.435 262.068 304.319 261.729 305.063 261.173C305.806 260.617 306.38 259.864 306.72 259C307.43 257.28 309.12 255.54 313.13 256.12C320.5 257.21 328.13 254.8 332.55 249.6C336.97 244.4 339.81 243.71 342.2 244.78C344.59 245.85 350.72 240 350.72 240Z"
        fill="#003C71"
      />
      <path
        d="M336.51 261.04C338.603 261.04 340.3 259.343 340.3 257.25C340.3 255.157 338.603 253.46 336.51 253.46C334.417 253.46 332.72 255.157 332.72 257.25C332.72 259.343 334.417 261.04 336.51 261.04Z"
        fill="#0067A5"
      />
      <path
        d="M379.28 236.44C379.28 236.44 372.92 252.2 357.82 247.44C355.62 247.27 351.51 249.61 351.43 250.08C351.35 250.55 357.36 256.13 357.36 256.13C357.36 256.13 379.53 255 379.28 236.44Z"
        fill="#0067A5"
      />
      <path
        d="M373.37 218.18L342.2 244.78C342.2 244.78 352.35 255.06 348.27 260.23L352.27 265.92C352.27 265.92 359.68 260.92 356.57 250.77C356.6 250.77 374.6 241.44 373.37 218.18Z"
        fill="#0067A5"
      />
      <path
        d="M351.51 248.6C352.559 248.6 353.41 247.749 353.41 246.7C353.41 245.651 352.559 244.8 351.51 244.8C350.461 244.8 349.61 245.651 349.61 246.7C349.61 247.749 350.461 248.6 351.51 248.6Z"
        fill="#003C71"
      />
      <path
        d="M398.4 226.46H396.4V220.46C396.397 217.493 395.218 214.648 393.12 212.55C391.022 210.452 388.177 209.273 385.21 209.27H311.6L311.3 208.95C301.626 198.38 295.235 185.223 292.908 171.084C290.581 156.945 292.417 142.435 298.193 129.321C303.969 116.208 313.435 105.058 325.438 97.231C337.441 89.4039 351.461 85.2376 365.79 85.24V87.24C351.89 87.2495 338.291 91.2897 326.64 98.8712C314.989 106.453 305.787 117.25 300.148 129.955C294.509 142.661 292.676 156.728 294.87 170.454C297.064 184.18 303.192 196.976 312.51 207.29H385.21C388.707 207.295 392.059 208.687 394.531 211.159C397.003 213.632 398.395 216.983 398.4 220.48V226.46Z"
        fill="#003C71"
      />
      <path
        d="M385.21 300.77H300.28C296.782 300.765 293.428 299.372 290.956 296.897C288.483 294.423 287.093 291.068 287.09 287.57V225H289.09V287.62C289.093 290.588 290.272 293.434 292.37 295.533C294.468 297.633 297.312 298.815 300.28 298.82H385.21C388.178 298.815 391.023 297.633 393.12 295.533C395.218 293.434 396.397 290.588 396.4 287.62V253.46H398.4V287.57C398.397 291.068 397.007 294.423 394.535 296.897C392.062 299.372 388.708 300.765 385.21 300.77Z"
        fill="#003C71"
      />
      <path
        d="M414.23 355.64C437.421 355.64 456.22 336.84 456.22 313.65C456.22 290.46 437.421 271.66 414.23 271.66C391.04 271.66 372.24 290.46 372.24 313.65C372.24 336.84 391.04 355.64 414.23 355.64Z"
        fill="#0067A5"
      />
      <path d="M415.23 310.02H413.23V348.18H415.23V310.02Z" fill="#8FBCD8" />
      <path
        d="M415.86 298.92C415.895 299.828 415.746 300.733 415.423 301.582C415.1 302.431 414.609 303.206 413.979 303.86C413.349 304.514 412.594 305.035 411.758 305.391C410.922 305.746 410.023 305.93 409.115 305.93C408.207 305.93 407.308 305.746 406.472 305.391C405.636 305.035 404.881 304.514 404.251 303.86C403.621 303.206 403.13 302.431 402.807 301.582C402.484 300.733 402.335 299.828 402.37 298.92C402.37 295.2 409.12 281.56 409.12 281.56C409.12 281.56 415.86 295.2 415.86 298.92Z"
        fill="#FF7D20"
      />
      <path
        d="M426.17 298.92C426.205 299.828 426.056 300.733 425.733 301.582C425.41 302.431 424.919 303.206 424.289 303.86C423.659 304.514 422.904 305.035 422.068 305.391C421.232 305.746 420.333 305.93 419.425 305.93C418.517 305.93 417.618 305.746 416.782 305.391C415.946 305.035 415.191 304.514 414.561 303.86C413.931 303.206 413.44 302.431 413.117 301.582C412.794 300.733 412.645 299.828 412.68 298.92C412.68 295.2 419.43 281.56 419.43 281.56C419.43 281.56 426.17 295.2 426.17 298.92Z"
        fill="#FF7D20"
      />
      <path
        d="M423.46 302.72C423.46 305.168 422.488 307.516 420.757 309.247C419.026 310.978 416.678 311.95 414.23 311.95C411.782 311.95 409.434 310.978 407.704 309.247C405.973 307.516 405 305.168 405 302.72C405 297.62 414.23 278.95 414.23 278.95C414.23 278.95 423.46 297.62 423.46 302.72Z"
        fill="#FFD400"
      />
      <path
        d="M420.3 339.75H420.25C419.029 339.738 417.839 339.365 416.83 338.678C415.821 337.991 415.038 337.02 414.58 335.888C414.123 334.756 414.01 333.514 414.258 332.318C414.505 331.123 415.101 330.027 415.97 329.17C418.11 327.06 428.33 323.66 429.49 323.28L431.41 322.65L430.75 324.56C430.35 325.71 426.75 335.87 424.63 337.98L423.93 337.27L424.63 337.98C423.476 339.117 421.92 339.753 420.3 339.75ZM428.16 325.84C423.99 327.3 418.54 329.45 417.37 330.6C416.583 331.377 416.137 332.434 416.13 333.54C416.125 334.088 416.228 334.633 416.433 335.141C416.638 335.65 416.942 336.113 417.327 336.504C417.711 336.895 418.169 337.206 418.674 337.42C419.179 337.634 419.722 337.746 420.27 337.75C421.389 337.762 422.468 337.33 423.27 336.55C424.4 335.4 426.6 330 428.16 325.84Z"
        fill="#8FBCD8"
      />
      <path
        d="M409.11 329.36C408.385 329.36 407.668 329.206 407.006 328.91C406.345 328.614 405.753 328.181 405.27 327.64C403.67 325.84 401.47 318.05 401.04 316.5L400.5 314.55L402.38 315.31C403.86 315.91 411.38 318.99 412.95 320.79C413.613 321.53 414.048 322.446 414.201 323.428C414.354 324.41 414.219 325.415 413.813 326.322C413.406 327.229 412.746 327.998 411.911 328.537C411.077 329.077 410.104 329.362 409.11 329.36ZM403.54 318C404.54 321.32 405.94 325.42 406.76 326.35C407.324 326.942 408.096 327.291 408.912 327.324C409.729 327.357 410.527 327.072 411.137 326.528C411.747 325.984 412.121 325.225 412.182 324.409C412.242 323.594 411.983 322.788 411.46 322.16C410.6 321.2 406.73 319.33 403.54 318Z"
        fill="#8FBCD8"
      />
      <path d="M388.83 309.3H386.83V324.73H388.83V309.3Z" fill="#8FBCD8" />
      <path d="M441.01 313.15H439.01V334.01H441.01V313.15Z" fill="#8FBCD8" />
      <path
        d="M388.01 306.55C390.992 306.55 393.41 304.132 393.41 301.15C393.41 298.168 390.992 295.75 388.01 295.75C385.028 295.75 382.61 298.168 382.61 301.15C382.61 304.132 385.028 306.55 388.01 306.55Z"
        fill="#FFD400"
      />
      <path
        d="M440.01 309.42C444.053 309.42 447.33 306.143 447.33 302.1C447.33 298.057 444.053 294.78 440.01 294.78C435.967 294.78 432.69 298.057 432.69 302.1C432.69 306.143 435.967 309.42 440.01 309.42Z"
        fill="#FFD400"
      />
      <path
        d="M388.01 303.71C389.424 303.71 390.57 302.564 390.57 301.15C390.57 299.736 389.424 298.59 388.01 298.59C386.596 298.59 385.45 299.736 385.45 301.15C385.45 302.564 386.596 303.71 388.01 303.71Z"
        fill="#0067A5"
      />
      <path
        d="M440.01 305.57C441.927 305.57 443.48 304.016 443.48 302.1C443.48 300.184 441.927 298.63 440.01 298.63C438.094 298.63 436.54 300.184 436.54 302.1C436.54 304.016 438.094 305.57 440.01 305.57Z"
        fill="#0067A5"
      />
      <path d="M393.41 323.73H381.82V325.73H393.41V323.73Z" fill="#8FBCD8" />
      <path
        d="M357.14 251.62L356.09 249.92C374.21 238.68 372.39 218.48 372.37 218.28L374.37 218.07C374.38 218.29 376.35 239.71 357.14 251.62Z"
        fill="#8FBCD8"
      />
      <path
        d="M425.99 355L425.44 353.08C432.777 350.997 439.387 346.908 444.527 341.274C449.668 335.641 453.135 328.685 454.539 321.189C455.943 313.692 455.229 305.953 452.476 298.841C449.723 291.728 445.041 285.524 438.956 280.927C432.871 276.329 425.625 273.52 418.031 272.815C410.437 272.11 402.797 273.537 395.969 276.936C389.142 280.334 383.398 285.57 379.382 292.054C375.367 298.538 373.24 306.013 373.24 313.64H371.24C371.239 305.641 373.47 297.801 377.68 291.001C381.891 284.2 387.915 278.709 395.075 275.144C402.235 271.579 410.248 270.082 418.212 270.82C426.177 271.559 433.777 274.505 440.159 279.326C446.541 284.148 451.452 290.653 454.34 298.113C457.228 305.572 457.978 313.688 456.506 321.55C455.034 329.412 451.398 336.708 446.007 342.617C440.616 348.526 433.684 352.814 425.99 355Z"
        fill="#003C71"
      />
      <path
        d="M345.19 382.67C341.06 382.67 336.9 380.46 336.9 374.86H342.6C342.567 375.208 342.611 375.558 342.727 375.887C342.843 376.216 343.029 376.516 343.273 376.766C343.516 377.016 343.811 377.211 344.137 377.336C344.463 377.461 344.812 377.513 345.16 377.49C345.511 377.516 345.864 377.464 346.192 377.338C346.521 377.212 346.818 377.015 347.061 376.76C347.305 376.506 347.49 376.202 347.602 375.868C347.714 375.534 347.751 375.18 347.71 374.83C347.718 374.48 347.655 374.132 347.524 373.807C347.393 373.482 347.197 373.187 346.948 372.941C346.699 372.695 346.402 372.502 346.075 372.374C345.749 372.247 345.4 372.188 345.05 372.2H344.25V367.2H345.05C345.525 367.24 346.001 367.136 346.416 366.902C346.83 366.668 347.165 366.314 347.376 365.886C347.586 365.459 347.663 364.978 347.597 364.506C347.53 364.034 347.322 363.594 347.001 363.242C346.68 362.89 346.261 362.642 345.797 362.532C345.333 362.423 344.847 362.455 344.402 362.625C343.957 362.796 343.574 363.096 343.303 363.488C343.031 363.879 342.884 364.344 342.88 364.82H337.14C337.14 360.13 340.71 357.29 345.14 357.29C349.76 357.29 353.14 360.37 353.14 364.68C353.195 365.65 352.983 366.616 352.526 367.473C352.069 368.33 351.386 369.045 350.55 369.54C351.464 370.11 352.21 370.912 352.714 371.864C353.218 372.816 353.462 373.884 353.42 374.96C353.48 380.22 349.6 382.67 345.19 382.67Z"
        fill="#003C71"
      />
      <path
        d="M506.08 499C494.82 500 488.36 506.2 488.77 517.6C489.18 529 480.77 533.49 469.47 531.81C458.17 530.13 451.47 533.97 449.2 540.81C446.93 547.65 442.69 550.15 431.87 549.45L442.07 571.27L509.75 555.85L526.12 520.85L506.08 499Z"
        fill="white"
      />
      <path
        d="M435.09 550.48C434.09 550.48 432.96 550.48 431.8 550.36L431.93 548.36C443.18 549.1 446.36 546.13 448.25 540.36C450.83 532.59 458.62 529.08 469.62 530.72C475.8 531.64 480.94 530.6 484.09 527.79C486.69 525.46 487.93 522.01 487.77 517.54C487.35 505.98 493.82 499.01 505.99 497.9L506.17 499.9C495.06 500.9 489.39 506.98 489.77 517.46C489.95 522.46 488.45 526.58 485.42 529.28C481.82 532.5 476.11 533.71 469.33 532.7C459.23 531.19 452.42 534.14 450.15 540.99C447.88 547.84 443.73 550.48 435.09 550.48Z"
        fill="#003C71"
      />
      <path
        d="M122.22 509.6C122.22 509.6 142.39 504.29 156.93 529.03C165.65 543.86 167.83 554.03 194.86 555.77C221.89 557.51 210.43 596.33 176.46 587.04C142.49 577.75 110.68 549.04 110.68 549.04L122.22 509.6Z"
        fill="white"
      />
      <path
        d="M194.8 556.77C170.42 555.22 165.6 546.56 158.92 534.57C158.02 532.95 157.09 531.28 156.07 529.57C142.07 505.82 123.27 510.39 122.47 510.57L121.97 508.64C122.18 508.58 143.04 503.43 157.8 528.53C158.8 530.29 159.8 531.98 160.67 533.6C167.27 545.47 171.67 553.31 194.93 554.78L194.8 556.77Z"
        fill="#003C71"
      />
    </svg>
  );
};
