import { userState } from "@byko/hooks-auth-next";
import { selectorFamily } from "@byko/lib-recoil";

import { priceApiBatchGet } from "./batch-fetch";

export interface VariantPrice {
  original: number;
  humanReadable: string;
}

export interface ProductPricing {
  price: {
    vat: VariantPrice | null | undefined;
    net: VariantPrice | null | undefined;
    gross: VariantPrice | null | undefined;
    perSqm: VariantPrice | null | undefined;
  };
  discounted?: {
    net: VariantPrice | null | undefined;
    gross: VariantPrice | null | undefined;
    perSqm: VariantPrice | null | undefined;
    name: string;
    percentage: number;
  };
  isSqmProduct: boolean;
  packagingQuantity: number | null | undefined;
  sqm: number | null | undefined;
}

export const getPrice = (value: number | null): VariantPrice | null => {
  if (value === null) {
    return null;
  }

  return {
    original: value,
    humanReadable: `${new Intl.NumberFormat("is-IS", { style: "currency", currency: "ISK" })
      .format(Math.ceil(parseFloat(value.toString())))
      .replace("ISK", "")
      .split("")
      .filter((x) => x.trim() !== "")
      .join("")
      .replaceAll(/,/g, ".")} kr.`
      .replace("&nbsp;", "")
      .replace(/kr\. kr\./g, " kr."),
  };
};

/***
 *  export const variantPricing = selectorFamily<ProductPricing | null, string>;
 *  export const pricing = selectorFamily<Record<string, ProductPricing> | null, string>({
 */

export const pricing = selectorFamily<ProductPricing | null, string>({
  key: "pricing-pricing",
  get:
    (sku) =>
    async ({ get }): Promise<ProductPricing | null> => {
      /** Get user state. Solely so Recoil knows that this should be recomputed if user state changes. */
      get(userState);

      /** This should not be fetched on SSR. */
      if (typeof window === "undefined") {
        return null;
      }

      if (!sku) {
        return null;
      }

      try {
        const data = await priceApiBatchGet({ sku });
        if (!data) {
          return null;
        }

        const prices: ProductPricing = {
          isSqmProduct: data.isSqmProduct ?? false,
          packagingQuantity: data.packagingQuantity,
          sqm: data.sqm,
          price: {
            gross: getPrice(data.price.gross),
            net: getPrice(data.price.net),
            perSqm: getPrice(data.price.perSqm ?? null),
            vat: getPrice(data.price.vat),
          },
        };

        if (data.discounted) {
          prices.discounted = {
            gross: getPrice(data.discounted.gross),
            net: getPrice(data.discounted.net),
            perSqm: getPrice(data.discounted.perSqm ?? null),
            name: data.discounted.name,
            percentage: data.discounted.percentage,
          };
        }

        return prices;
      } catch (_e) {
        return null;
      }
    },
});
