// Validate that an email format is being used something@something.something
export const emailValidation = /^([\wÀ-Úá-úþÞÝý+-.]+@[\wÀ-Úá-úþÞÝý+-]+\.[\wÀ-Úá-úþÞÝý+-.]+)$/;

// Validate that only letters are being used
export const stringValidation = /^([\sA-Za-zÁ-Úá-úþÞÝý]*)$/;

// Validate that only letters are being used
export const licencePlateValidation = /^([\sA-Za-zÁ-Úá-úþÞÝý0-9]*)$/;

// Ensure the mask in formik is not being confused with user input
export const maskValidation = /^([^_]*)$/;

// Validate a number between 01-12 can be used for months
export const monthValidation = /^([0-9]|0[1-9]|1[012])$/;

export const numberOverZero = /^[1-9]\d*$/;

// Removes more than one spaces in a string
export const passwordValidation = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

// Removes more than one spaces in a string
export const removeExtraSpaces = (param: string): string => param.replace(/\s+/g, " ").trim();
