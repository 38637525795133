import styled from "styled-components";

export const IFrameWrapper = styled.div``;

export const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
`;

export const VideoBlockContainer = styled.div``;
