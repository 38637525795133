/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const PlanetIcons: BykoReactIconComponentType = ({ size = "24", iconColor = "#008F69" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2242_4156)">
        <path
          d="M7.11 3L8.722 4.325L10.367 6.081L9.963 8.821L7.369 10.133L6.667 12.439L7.974 13.839L8.68 16.312L6.717 17.959L6 20"
          stroke={iconColor}
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M23 8C22.3038 8.93503 21.3987 9.69446 20.357 10.2177C19.3153 10.741 18.1658 11.0137 17 11.014C15.9391 11.014 14.9217 11.4354 14.1716 12.1856C13.4214 12.9357 13 13.9531 13 15.014C13 16.0749 13.4214 17.0923 14.1716 17.8424C14.9217 18.5926 15.9391 19.014 17 19.014C18.5913 19.014 20.1174 18.3819 21.2426 17.2566C22.3679 16.1314 23 14.6053 23 13.014V8Z"
          stroke={iconColor}
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M16.757 15.771C16.1992 16.3277 15.7569 16.9891 15.4553 17.7173C15.1538 18.4454 14.9991 19.2259 15 20.014C15 20.8096 14.6839 21.5727 14.1213 22.1353C13.5587 22.6979 12.7956 23.014 12 23.014"
          stroke={iconColor}
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M8 22.242C6.46131 21.6443 5.07699 20.7076 3.94976 19.5017C2.82252 18.2958 1.98132 16.8515 1.48856 15.2761C0.995801 13.7006 0.864142 12.0344 1.10335 10.4011C1.34256 8.76778 1.9465 7.20931 2.87035 5.84131C3.7942 4.47332 5.01425 3.33093 6.43996 2.49891C7.86566 1.66688 9.46043 1.16659 11.1059 1.03515C12.7514 0.903714 14.4054 1.14451 15.9451 1.73966C17.4848 2.33481 18.8707 3.26905 20 4.47304C20.1 4.58404 20.207 4.69704 20.306 4.81304"
          stroke={iconColor}
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M12.5 1.5L12.939 2.34904L13.559 4.16204L15.843 6.00004L19 4"
          stroke={iconColor}
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_2242_4156">
          <rect fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
};
