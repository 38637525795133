/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const LawnmowerLineDecorative: BykoReactDecorativeIconComponentType = ({ size, color = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M16 38H36" stroke={color} strokeMiterlimit="10" strokeWidth="2" />
      <path d="M21 18V16L35 17V20" stroke="black" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path
        d="M13.41 22.754L10.43 5.49C10.3098 4.79296 9.94708 4.16083 9.40603 3.70523C8.86497 3.24962 8.18033 2.99984 7.473 3H3"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M41 33V29.557C41.0001 28.577 40.6404 27.631 39.9891 26.8986C39.3378 26.1662 38.4404 25.6984 37.467 25.584L11.467 22.526C10.906 22.4601 10.3375 22.5137 9.79875 22.6833C9.26 22.8529 8.76329 23.1347 8.3413 23.5101C7.91931 23.8855 7.58161 24.3461 7.35042 24.8614C7.11924 25.3767 6.99981 25.9352 7 26.5V29"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M41 43C43.7614 43 46 40.7614 46 38C46 35.2386 43.7614 33 41 33C38.2386 33 36 35.2386 36 38C36 40.7614 38.2386 43 41 43Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M9 43C12.866 43 16 39.866 16 36C16 32.134 12.866 29 9 29C5.13401 29 2 32.134 2 36C2 39.866 5.13401 43 9 43Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
};
