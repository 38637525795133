import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const TreeLineDecorative: BykoReactDecorativeIconComponentType = ({ size, color = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 28C12 18.125 24 4 24 4C24 4 36 18.125 36 28C36 34.6274 30.6274 38 24 38C17.3726 38 12 34.6274 12 28Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path d="M24 19V46" stroke={color} strokeMiterlimit="10" strokeWidth="2.5" />
      <path d="M14 46H34" stroke={color} strokeMiterlimit="10" strokeWidth="2.5" />
      <path d="M24 33L29 28" stroke={color} strokeMiterlimit="10" strokeWidth="2.5" />
      <path d="M19 23L24 28" stroke={color} strokeMiterlimit="10" strokeWidth="2.5" />
    </svg>
  );
};
