import { useCallback, useEffect, useState } from "react";

export const useKeyPress = (targetKey: string, log = false): boolean => {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);
  // If pressed key is our target key then set to true
  const downHandler = useCallback(
    ({ key }: { key: string }): void => {
      if (log) {
        // eslint-disable-next-line no-console
        console.log({ target: targetKey, youPressed: key });
      }
      if (key === targetKey) {
        setKeyPressed(true);
      }
    },
    [log, targetKey],
  );

  // If released key is our target key then set to false
  const upHandler = useCallback(
    ({ key }: { key: string }): void => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    },
    [targetKey],
  );

  // Add event listeners
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("keydown", downHandler);
      window.addEventListener("keyup", upHandler);
      // Remove event listeners on cleanup
    }
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("keydown", downHandler);
        window.removeEventListener("keyup", upHandler);
      }
    };
  }, [downHandler, upHandler]);
  return keyPressed;
};
