import { gql } from "graphql-tag";
import { useQuery } from "react-query";
import { graphQLClient } from "../dato-graphql-client";

import type { PageBySlugQuery, PageBySlugQueryVariables } from "../generated-gql/graphql";
import { DatoPageParts } from "./fragments";

export function useGetPageBySlug({ slug }: PageBySlugQueryVariables) {
  const { data, isLoading, error } = useQuery(`get-page-slug-by-${slug}`, async () => {
    const request = await graphQLClient.request<{
      allPages: PageBySlugQuery;
    }>(
      gql`
        query PageBySlug($slug: String!) {
          allPages(filter: { slug: { eq: $slug } }) {
            ...DatoPageParts
          }
        }
        ${DatoPageParts}
      `,
      { slug },
    );
    return request;
  });

  return { data: data?.allPages, isLoading, error };
}
