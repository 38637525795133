import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const AdviceIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 23" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 1H23V16H13L6 21V16H1V1Z"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
};

export default AdviceIcons;
