import Link from "next/link";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";

import { analytics } from "@byko/lib-analytics";
import { useOnScreen } from "@byko/lib-utils";
import { useProductVariants } from "@byko/hooks-product-variants";
import { useAlgoliaInsights } from "@byko/lib-algolia";

import { PriceWrapper, ProductCardInformation, ProductImage } from "./components";
import { IconBackdrop, IconContainer, ProductCardContainer, TagBlock, TagContainer, TagText } from "./styles";
import type { ProductLimitedCardProps, Tag } from "./interface";
import { LongArrowRightIcons, PlanetIcons } from "@byko/lib-icons";
import { theme } from "@byko/lib-styles";
import { Button } from "@byko/component-buttons";

interface EnvironmentalInfo {
  en: string;
  "is-IS": string;
}

export const ProductLimitedCard = ({
  product,
  smallMobileCard,
  prices,
  lowestPriceSKU,
  listIndex,
  analyticsSectionId,
  algoliaInsights,
}: ProductLimitedCardProps): JSX.Element => {
  const environmentalAttributes = product?.attributes?.["environmentalInfoType"] as EnvironmentalInfo[] | null;
  const hasEnvironmentalAttributes = environmentalAttributes && environmentalAttributes.length > 0;

  const [localPrices, setLocalPrices] = useState(prices);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const isVisible = useOnScreen(containerRef);
  const { sendClickInsights } = useAlgoliaInsights();
  const [hasBeenVisible, setHasBeenVisible] = useState<boolean>(false);
  const [activeVariantSKU, setActiveVariantSKU] = useState<string>(Object.keys(prices ?? {})[0] ?? "");
  const { activeVariant } = useProductVariants(product, activeVariantSKU, setActiveVariantSKU, lowestPriceSKU);
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const router = useRouter();
  const collapseRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (lowestPriceSKU) {
      setActiveVariantSKU(lowestPriceSKU);
    }
  }, [lowestPriceSKU]);

  const price = useMemo(() => {
    return localPrices?.[activeVariantSKU];
  }, [activeVariantSKU, localPrices]);

  useEffect(() => {
    if (isVisible && !hasBeenVisible) {
      setHasBeenVisible(true);
      analytics.addProductListImpression(
        [product],
        { [product.id]: price?.price.gross.toString() ?? "" },
        analyticsSectionId,
      );
    }
    // we only want to fire this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, hasBeenVisible]);

  useEffect(() => {
    if (prices) {
      setLocalPrices(prices);
    }
  }, [prices]);

  const tags: Tag[] = useMemo(() => {
    const list: Tag[] = [];
    if (price?.discounted?.name === "RETAIL") {
      if (price?.discounted?.percentage) {
        return [
          {
            label: `-${price?.discounted?.percentage}%`,
            color: "yellow",
          },
        ];
      } else {
        return [
          {
            label: `Tilboð`,
            color: "yellow",
          },
        ];
      }
    }
    return list;
  }, [price]);

  const handleCollapseDetails = useCallback(() => {
    setShowDetail(false);
  }, []);

  useOnClickOutside(collapseRef, handleCollapseDetails);

  const addProductListClick = useCallback(() => {
    analytics.addProductListClick(product, price?.price.gross.toString() ?? "", listIndex, analyticsSectionId);

    !!algoliaInsights && sendClickInsights(algoliaInsights);
  }, [algoliaInsights, analyticsSectionId, listIndex, price?.price.gross, product, sendClickInsights]);

  const onClickProductDetailPage = useCallback(() => {
    addProductListClick();
    router.push(`/vara/${product.slug}`);
  }, [addProductListClick, product.slug, router]);

  const displayImage = useMemo((): string => {
    if (activeVariant?.firstImage?.image?.productList) {
      return activeVariant?.firstImage?.image?.productList;
    } else if (product.firstImage?.image?.productList) {
      return product.firstImage?.image?.productList;
    } else {
      return "https://www.datocms-assets.com/65892/1664464657-placeholder-do-not-remove.jpg";
    }
  }, [activeVariant?.firstImage?.image?.productList, product.firstImage?.image?.productList]);

  return (
    <ProductCardContainer
      ref={containerRef}
      className="product-card"
      showDetail={showDetail}
      smallMobileCard={smallMobileCard ?? false}
    >
      <TagContainer>
        {tags.map(({ label, color }): JSX.Element => {
          return (
            <TagBlock key={label} color={color}>
              <TagText>{label}</TagText>
            </TagBlock>
          );
        })}
      </TagContainer>
      {hasEnvironmentalAttributes && (
        <IconContainer href={`/vara/${product.slug}`} onClick={addProductListClick}>
          <IconBackdrop title="Umhverfisvæn vara">
            <PlanetIcons iconColor={theme.palette.white.main} size={24} />
          </IconBackdrop>
        </IconContainer>
      )}
      <Link href={`/vara/${product.slug}`} onClick={addProductListClick}>
        <ProductImage product={product} productImage={displayImage} />
        <ProductCardInformation
          prodName={product.name}
          shortDecription={product.description}
          sku={activeVariant?.sku ?? ""}
        />
        <PriceWrapper activeVariantSKU={activeVariantSKU} prices={localPrices} product={product} />
      </Link>

      <Button
        buttonColor="whiteButton"
        icon={LongArrowRightIcons}
        label="Skoða vöru"
        stretch={true}
        onClick={onClickProductDetailPage}
      />
    </ProductCardContainer>
  );
};
