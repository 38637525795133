import * as React from "react";

import { theme } from "@byko/lib-styles";

import type { BykoReactIconComponentType } from "../../icon-types";

export const FilterIcons: BykoReactIconComponentType = ({ size, iconColor = theme.palette.blue.main }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6667 2.66675L9.33337 8.00008V13.3334L6.66671 14.6667V8.00008L1.33337 2.66675V0.666748H14.6667V2.66675Z"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="1.33333"
      />
    </svg>
  );
};
