import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const MeasureBigFilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M42.707 43.293L2.707 3.29304C2.42 3.00604 1.99 2.92104 1.617 3.07604C1.244 3.23104 1 3.59604 1 4.00004V44C1 44.552 1.448 45 2 45H42C42.404 45 42.769 44.756 42.924 44.383C43.079 44.009 42.993 43.579 42.707 43.293ZM22.924 36.383C22.769 36.756 22.404 37 22 37H12C11.448 37 11 36.552 11 36V26C11 25.596 11.244 25.231 11.617 25.076C11.991 24.92 12.42 25.006 12.707 25.293L22.707 35.293C22.993 35.579 23.079 36.009 22.924 36.383Z"
        fill="#FFD330"
      />
      <path d="M5 20H1V22H5V20Z" fill="#0A3A5C" />
      <path d="M5 14H1V16H5V14Z" fill="#0A3A5C" />
      <path d="M5 26H1V28H5V26Z" fill="#0A3A5C" />
      <path d="M5 32H1V34H5V32Z" fill="#0A3A5C" />
      <path d="M5 38H1V40H5V38Z" fill="#0A3A5C" />
    </svg>
  );
};
