import React, { useCallback, useMemo } from "react";

import { Select } from "@byko/component-select";

import { DropdownContainer } from "./styles";

import type { ResultsSortingBlockProps } from "./interface";
import type { SortingOption } from "@byko/lib-algolia";

export const DropdownSortingBlock = ({ sortingOptions, handleClick }: ResultsSortingBlockProps): JSX.Element | null => {
  const activeItem = useMemo((): SortingOption | undefined => {
    return sortingOptions.find((item) => {
      return item.active;
    });
  }, [sortingOptions]);

  const handleDropdownSelect = useCallback(
    (value: { selectedItem?: SortingOption | null } | null) => {
      if (!value?.selectedItem) {
        return;
      }
      if (handleClick) {
        handleClick(value.selectedItem.id);
      }
    },
    [handleClick],
  );

  const handleItemToString = useCallback((option: SortingOption | null): string => option?.label ?? "", []);

  return (
    <DropdownContainer>
      <Select
        align="left"
        fit={true}
        handleSelectedItemChange={handleDropdownSelect}
        hideValue={true}
        items={sortingOptions}
        itemToString={handleItemToString}
        placeholder={activeItem?.label}
        selectedItem={activeItem}
      />
    </DropdownContainer>
  );
};
