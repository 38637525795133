import { useRouter } from "next/router";
import React, { useCallback, useMemo, useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { useQuery } from "react-query";

import { Button, IconButton } from "@byko/component-buttons";
import { drawerState } from "@byko/component-drawer";
import { H6, PMediumBold, PSmallBold } from "@byko/component-typography";
import { useAuth } from "@byko/hooks-auth-next";
import { useCart, useCheckoutExtra } from "@byko/hooks-cart";
import { getPrice } from "@byko/lib-api-price";
import { ArrowUpIcons, CartIcons, LongArrowSideIcons } from "@byko/lib-icons";
import { useRecoilState } from "@byko/lib-recoil";
import { gridTheme, theme, useWindowSize } from "@byko/lib-styles";

import { ButtonSpaceBetween, CartCardWrapper, MiniCartContainer, SpaceBetween } from "./styles";
import { MiniCartCard } from "./components";

export const MiniCart = (): JSX.Element | null => {
  const miniCartRef = useRef<HTMLDivElement>(null);
  const { user, setShowAsGuestOption, activeMembership } = useAuth();
  const [_open, setOpen] = useRecoilState<boolean>(drawerState);
  const { width } = useWindowSize();
  const router = useRouter();
  const { toggleShowMinicart, showMinicart } = useCart();
  const { checkoutData, fetchCheckoutData } = useCheckoutExtra();

  useQuery([activeMembership?.id, user?.id, "checkoutData"], fetchCheckoutData, {
    enabled: !!showMinicart,
    refetchOnWindowFocus: false,
  });

  const goToCartPage = useCallback(() => {
    toggleShowMinicart();
    router.push("/karfa");
  }, [toggleShowMinicart, router]);

  const goToCheckoutPage = useCallback(() => {
    const prefersAnonymousCheckout = sessionStorage.getItem("prefers-anonymous-checkout");
    if (!user && !prefersAnonymousCheckout) {
      setShowAsGuestOption(false); // TODO: should be true - Andri
      setOpen(true);
      toggleShowMinicart();
    } else {
      toggleShowMinicart();
      router.push("/kaupferli");
    }
  }, [toggleShowMinicart, user, setShowAsGuestOption, setOpen, router]);

  const handleToggleMinicart = useCallback(() => {
    toggleShowMinicart();
  }, [toggleShowMinicart]);

  const closeMinicart = useCallback(() => {
    toggleShowMinicart(false);
  }, [toggleShowMinicart]);

  useOnClickOutside(miniCartRef, closeMinicart);

  const hasSoldOutProduct = useMemo(() => {
    const soldOutProduct = checkoutData?.lines.find((line) => line.variant.webstoreInStock === false);
    return soldOutProduct != null;
  }, [checkoutData?.lines]);

  if (!showMinicart) {
    return null;
  }

  if (checkoutData?.quantity === 0 || checkoutData?.quantity == null) {
    return (
      <MiniCartContainer ref={miniCartRef}>
        <SpaceBetween>
          <PMediumBold textColor={theme.palette.gray[90]}>Karfan þín er tóm</PMediumBold>
          <IconButton ariaLabel="" icon={ArrowUpIcons} small={true} onClick={handleToggleMinicart} />
        </SpaceBetween>
      </MiniCartContainer>
    );
  }

  return (
    <MiniCartContainer ref={miniCartRef}>
      <SpaceBetween>
        <PMediumBold textColor={theme.palette.gray[90]}>
          {checkoutData?.quantity ?? 0} {checkoutData?.quantity === 1 ? "Vara í körfu" : "Vörur í körfu"}
        </PMediumBold>
        {width > gridTheme.container.maxWidth.s && (
          <IconButton ariaLabel="" icon={ArrowUpIcons} small={true} onClick={handleToggleMinicart} />
        )}
      </SpaceBetween>

      <CartCardWrapper>
        {checkoutData?.lines.map((cartItem) => (
          <MiniCartCard key={cartItem.variant.id} cartItem={cartItem} />
        ))}
      </CartCardWrapper>

      <SpaceBetween>
        <PSmallBold textColor={theme.palette.gray[90]}>Verð samtals</PSmallBold>
        <H6 maxFontSize="28px" textColor={theme.palette.blue.main}>
          {getPrice(checkoutData?.subTotalPrice.gross ?? 0)?.humanReadable}
        </H6>
      </SpaceBetween>

      <ButtonSpaceBetween>
        <Button icon={CartIcons} label="Skoða körfu" stretch={true} onClick={goToCartPage} />
        <Button
          buttonColor="blueButton"
          disabled={hasSoldOutProduct}
          icon={LongArrowSideIcons}
          label="Ganga frá kaupum"
          stretch={true}
          onClick={goToCheckoutPage}
        />
      </ButtonSpaceBetween>
    </MiniCartContainer>
  );
};
