/* eslint-disable max-len */
/* stylelint-disable */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */

import React from "react";
import { createGlobalStyle } from "styled-components";

import NobelBlack from "./fonts/Nobel-Black.woff2";
import NobelBlackItalic from "./fonts/Nobel-Black_Italic.woff2";
import NobelBook from "./fonts/Nobel-Book.woff2";
import { Variables } from "./variables";

const woffFonts = [NobelBlack, NobelBook, NobelBlackItalic];
const html = {
  __html: `
  @font-face {
    ascent-override: 75%;
    descent-override: 17%;
    font-family: 'FallbackFont';
    line-gap-override: 37%;
    size-adjust: 95%;
    src: local(Arial);
  }
  @font-face {
      font-family: "Nobel";
      font-style: normal;
      font-weight: 400;
      src: url(${`/${NobelBook}`.replace(/\/{2,}/g, "/")}) format('woff2');
  }
  @font-face {
      font-family: "Nobel";
      font-style: normal;
      font-weight: 500;
      src: url(${`/${NobelBlack}`.replace(/\/{2,}/g, "/")}) format('woff2');
  }
    @font-face {
      font-family: "Nobel";
      font-stretch: condensed;
      font-style: normal;
      font-weight: 500;
      src: url(${`/${NobelBlack}`.replace(/\/{2,}/g, "/")}) format('woff2');
  }
  @font-face {
      font-family: "Nobel";
      font-style: normal;
      font-weight: 300;
      src: url(${`/${NobelBook}`.replace(/\/{2,}/g, "/")}) format('woff2');
  }`,
};

export const PreloadWoffFont = ({ font, url }: { font: string; url: string }): JSX.Element => {
  const fontUrl = `${url}${font.replace("//", "/")}`;
  return <link as="font" crossOrigin="" href={fontUrl} rel="preload" type="font/woff2" />;
};

export const PreloadFonts = ({
  url = typeof window === "undefined"
    ? (process.env["BASE_URL"] as string)
    : (window as unknown as { __APP__CONFIG__: { BASE_URL: string } }).__APP__CONFIG__.BASE_URL,
}: {
  url?: string | undefined;
}): JSX.Element => {
  return (
    <>
      <style dangerouslySetInnerHTML={html} type="text/css" />
      {woffFonts.map((font, index) => (
        <PreloadWoffFont key={index} font={font} url={url} />
      ))}
    </>
  );
};

export const ResetStyle = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    padding: 0;
    border: 0;
    margin: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
  }

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  html {
    height: fill-available;
  }

  body {
    min-height: fill-available;
    line-height: 1;
  }

  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

`;

export const GlobalStyles = createGlobalStyle`
${Variables};
*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
* {
font-family: var(--font-main);
}
*:focus-visible {
  outline: none;
}

.ch2 {
  position: relative;
  z-index: 1;
}

html {
  width: 100%;
  max-width: 100%;
  box-sizing: inherit;
  overflow-x: hidden;
  /* Scrollbar styles */
  scroll-behavior: smooth;
  scrollbar-color: var(--bluedark);
  scrollbar-width: thin;
}
body::-webkit-scrollbar {
  width: 6px;
}
body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--bluedark);
}

body {
  max-width: var(--max-width);
  min-height: 100vh;
  padding: 0 !important;
  margin: 0 auto;
  background-color: var(--background);
  color: var(--black);
  font-family: var(--font-main);
}
input, textarea {
    font-family: var(--font-main);
}
ul, li, ol {
  list-style: none;
}
a {
  color: var(--black);
  text-decoration: none;
  transition: var(--transition);
  :hover {
    color: var(--blue)
  }
}

button {
  padding: 0;
  border: none;
  background: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  outline: inherit;
}

.rdt_TableHead {
  z-index: 0 !important;
}

.rdt_TableRow.disabled {
  color: var(--disable);
}

.rdt_Pagination {
  font-size: 16px;
  span {
    margin: 5px 10px 0 10px;
  }

}

/* stylelint-disable-next-line, no-duplicate-selectors */
* {
  transition-timing-function: ease-out;

  /* for debug purposes */
  /* outline: 1px solid firebrick !important; */
}

.ch2-learn-more:focus-visible {
  outline: none;
}

input[type='number'] {
  appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    appearance: none;
}
.no-scroll {
  overflow: hidden;
  height: 100%;
  margin: 0;
}
input {
  border-radius: 0;
}
input[type="search"] {
  appearance: none;
}
img {
  user-select: none;
}
.cx-widget.cx-theme-byko {
  background-color: #fdfdfd;
  color: #444a52;
  scrollbar-face-color: #ccc
}

body .cx-widget.cx-theme-byko [aria-hidden="true"] {
    display: inline;
}


.cx-widget.cx-theme-byko .cx-theme {
    border-color: #dae1e8 !important;
    background-color: #fdfdfd;
    color: #444a52
}

.cx-widget.cx-theme-byko .cx-overlay {
    background-color: #fdfdfd
}

.cx-widget.cx-theme-byko .cx-svg-icon-tone1 {
    fill: #00416A
}

.cx-widget.cx-theme-byko .cx-svg-icon-tone2 {
    fill: #971f82
}

.cx-widget.cx-theme-byko .cx-svg-icon-shadow1 {
    fill: #000;
    opacity: 0.2
}

.cx-widget.cx-theme-byko .cx-svg-icon-shadow2 {
    fill: #000;
    opacity: 0.1
}

.cx-widget.cx-theme-byko * {
    border-color: #dae1e8
}

.cx-widget.cx-theme-byko .cx-titlebar .cx-title {
    color: #444a52
}

.cx-widget.cx-theme-byko .cx-buttons-window-control .cx-svg-icon-tone1 {
    fill: rgb(255, 217, 0)
}

.cx-widget.cx-theme-byko .cx-input-icon-overlay .cx-svg-icon-tone1 {
    fill: #f5a623
}

.cx-widget.cx-theme-byko .cx-input-group-btn .cx-svg-icon-tone1 {
    fill: #fdfdfd
}

.cx-widget.cx-theme-byko label {
    color: #444a52
}

.cx-widget.cx-theme-byko a {
    color: #000
}

    .cx-widget.cx-theme-byko a:hover {
        color: #000
    }

.cx-widget.cx-theme-byko .cx-icon-alert-circle {
    color: #f53131
}

.cx-widget.cx-theme-byko .cx-footer, .cx-widget.cx-theme-byko .cx-branding {
    color: #66759b
}

    .cx-widget.cx-theme-byko .cx-footer *, .cx-widget.cx-theme-byko .cx-branding * {
        fill: #66759b
    }

.cx-widget.cx-theme-byko .cx-form-control.cx-error {
    border-color: #f53131
}

.cx-widget.cx-theme-byko .cx-form-control::placeholder {
    color: #6f7491
}

.cx-widget.cx-theme-byko .cx-form-control:-moz-placeholder {
    color: #6f7491
}

.cx-widget.cx-theme-byko .cx-form-control::-moz-placeholder {
    color: #6f7491
}

.cx-widget.cx-theme-byko .cx-form-control:-ms-input-placeholder {
    color: #6f7491
}

.cx-widget.cx-theme-byko .cx-form-control::-webkit-input-placeholder {
    color: #6f7491
}

.cx-widget.cx-theme-byko input:focus, .cx-widget.cx-theme-byko textarea:focus, .cx-widget.cx-theme-byko .cx-btn:focus, .cx-widget.cx-theme-byko .cx-button-group button:focus, .cx-widget.cx-theme-byko .cx-form-control:not(.cx-error):focus {
    border-color: #5791fd !important
}

.cx-widget.cx-theme-byko input[type="text"], .cx-widget.cx-theme-byko input[type="email"], .cx-widget.cx-theme-byko input[type="tel"], .cx-widget.cx-theme-byko select, .cx-widget.cx-theme-byko textarea {
    border-color: #94948a;
    background-color: #fdfdfd;
    color: #444a52
}

.cx-widget.cx-theme-byko .cx-btn-default {
    border-color: #dae1e8 !important;
    background: none;
    background-color: rgb(239, 239, 239);
    color: #000;
}

.cx-widget.cx-theme-byko .cx-btn.cx-disabled {
    background: #ccc
}

.cx-widget.cx-theme-byko .cx-btn-primary {
    border-color: #2e69db !important;
    background: #00416A;
    background: linear-gradient(to bottom,#00416A 0%,#00416A 100%);
    background-color: #00416A;
    color: #fdfdfd;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@bg_color_4',endColorstr='@bg_color_5',GradientType=0)
}

    .cx-widget.cx-theme-byko .cx-button-group .cx-btn-primary:focus, .cx-widget.cx-theme-byko .cx-btn-primary:focus, .cx-widget.cx-theme-byko .cx-button-group .cx-btn-default:focus, .cx-widget.cx-theme-byko .cx-btn-default:focus {
        border-color: #000 !important
    }

.cx-widget.cx-theme-byko .cx-ac-dropdown-menu {
    border-color: #dae1e8 !important;
    background: #fdfdfd
}

.cx-widget.cx-theme-byko .cx-ac-suggestion:hover, .cx-widget.cx-theme-byko .cx-ac-suggestion.cx-active {
    background-color: #5791fd;
    color: #fdfdfd
}

.cx-widget.cx-theme-byko ::-webkit-scrollbar-thumb {
    background: #939399
}

.cx-widget.cx-theme-byko .cx-smokescreen {
    background-color: #fdfdfd;
    opacity: 0.7
}

.cx-widget.cx-theme-byko .cx-dialog-container .cx-dialog {
    border-color: #dae1e8 !important;
    background-color: #fdfdfd
}

.cx-widget.cx-theme-byko .cx-theme-background {
    background-color: #fdfdfd
}

.cx-widget.cx-theme-byko .cx-theme-border {
    border: 1px solid #dae1e8 !important
}

.cx-widget.cx-theme-byko .cx-theme-text {
    color: #444a52
}

.cx-widget.cx-theme-byko .cx-theme-icon-interactive1 svg .cx-svg-icon-tone1 {
    fill: #5081e1
}

.cx-widget.cx-theme-byko .cx-theme-icon-positive svg .cx-svg-icon-tone1 {
    fill: #34a84f
}

.cx-widget.cx-theme-byko .cx-theme-icon-negative svg .cx-svg-icon-tone1 {
    fill: #f53131
}

.cx-widget.cx-theme-byko .cx-theme-icon-warning svg .cx-svg-icon-tone1 {
    fill: #d47f2f
}

.cx-widget.cx-theme-byko .cx-warning {
    color: #f53131 !important
}

    .cx-widget.cx-theme-byko .cx-warning .cx-icon svg * {
        fill: #f53131 !important
    }

.cx-widget.cx-theme-byko .cx-countdown {
    color: #444a52
}

    .cx-widget.cx-theme-byko .cx-countdown .cx-icon svg * {
        fill: #444a52
    }

.cx-widget.cx-theme-byko .cx-loading-screen .cx-loading-icon svg g g, .cx-widget.cx-theme-byko .cx-loading-container .cx-loading-icon svg g g {
    stroke: #2b74ff
}

.cx-widget.cx-theme-byko .cx-loading-screen .cx-loading-icon svg g:first-child g, .cx-widget.cx-theme-byko .cx-loading-container .cx-loading-icon svg g:first-child g {
    stroke: #72799d
}

.cx-webchat.cx-theme-byko .cx-transcript .cx-message.cx-them .cx-bubble {
    background-color: #e4bada
}

.cx-webchat.cx-theme-byko .cx-transcript .cx-message.cx-you .cx-bubble {
    background-color: #a0ddea
}
#chat-button {
    position: fixed;
    z-index: 99999;
    right: 32px;
    bottom: 32px;
    display: flex;
    width: 64px;
    height: 64px;
    align-items: center;
    justify-content: center;
    border-width: 3px;
    border-style: solid;
    border-color: #fff;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 2px 8px rgba(0,0,0,.5);
    cursor: pointer;
}
`;
