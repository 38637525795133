/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const ContextProUserIcons: BykoReactIconComponentType = ({ iconColor, size }) => {
  return (
    <svg height={size} viewBox="0 0 32.736 44.195" width={size} xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(1799.885 1087.396)">
        <path
          d="M-1783.516-934.867a16.387,16.387,0,0,0-16.369,16.369,1.819,1.819,0,0,0,1.819,1.819,1.846,1.846,0,0,0,.218-.014h28.664a1.846,1.846,0,0,0,.218.014,1.819,1.819,0,0,0,1.819-1.819A16.387,16.387,0,0,0-1783.516-934.867Zm0,3.638a12.73,12.73,0,0,1,12.63,11.152h-25.26A12.73,12.73,0,0,1-1783.516-931.229Z"
          fill={iconColor}
          transform="translate(0 -126.522)"
        />
        <path
          d="M-1781.846-1070.436v.02h1.81a10.924,10.924,0,0,0,10.281,7.265,10.914,10.914,0,0,0,10.3-7.3h1.414c.05,0,.1.008.152.008s.111,0,.165-.008h.068v-.008a1.681,1.681,0,0,0,1.446-1.663,1.681,1.681,0,0,0-1.446-1.663v-.007h-.053a1.722,1.722,0,0,0-.342,0h-.925v-.033c0-.171,0-.321,0-.47a10.766,10.766,0,0,0-5.724-9.607.969.969,0,0,1-.352-.4,4.933,4.933,0,0,0-4.694-3.1h-.012a4.945,4.945,0,0,0-4.7,3.115.966.966,0,0,1-.355.4,10.672,10.672,0,0,0-5.655,8.588c-.04.391-.054.775-.069,1.182,0,.112-.008.225-.013.339h-1.07l-.222,0v.019a1.68,1.68,0,0,0-1.449,1.661A1.679,1.679,0,0,0-1781.846-1070.436Zm12.091,3.648a7.271,7.271,0,0,1-6.284-3.609l.587,0h11.445l.538-.01A7.27,7.27,0,0,1-1769.755-1066.788Zm-5.07-12.983v3.507l.006.182v.06h.019a1.693,1.693,0,0,0,1.674,1.464,1.694,1.694,0,0,0,1.676-1.464h.01v-.089c0-.046.006-.092.006-.138s0-.077-.006-.115v-2.045q0-1.979,0-3.959a1.67,1.67,0,0,1,1.666-1.684,1.683,1.683,0,0,1,1.207.481,1.687,1.687,0,0,1,.5,1.209q0,1.968,0,3.935v1.626l0,.781h.021a1.675,1.675,0,0,0,1.652,1.424,1.675,1.675,0,0,0,1.649-1.41l.022,0v-.016h0v-.483h0v-3.26a7.305,7.305,0,0,1,2.351,5.945h-14.771A7.5,7.5,0,0,1-1774.825-1079.771Z"
          fill={iconColor}
          transform="translate(-13.761)"
        />
      </g>
    </svg>
  );
};
