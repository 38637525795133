/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const PlantGroundFilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M34 18C30.0334 18 26.6165 20.3154 25 23.663V2C25 1.44775 24.5527 1 24 1C23.4473 1 23 1.44775 23 2V13.663C21.3835 10.3154 17.9665 8 14 8H12V12C12 17.5228 16.4772 22 22 22H23V38.2529C23 38.8052 23.4473 39.2529 24 39.2529C24.5527 39.2529 25 38.8052 25 38.2529V32H26C31.5228 32 36 27.5228 36 22V18H34Z"
        fill="#52C6A8"
      />
      <path
        d="M46 47H2C1.44727 47 1 46.5522 1 46C1 45.4478 1.44727 45 2 45H11.1006C11.5654 42.7207 13.585 41 16 41C16.7637 41 17.5156 41.1816 18.1992 41.5229C19.7881 38.7412 22.7363 37 26 37C30.625 37 34.4453 40.5064 34.9443 45H46C46.5527 45 47 45.4478 47 46C47 46.5522 46.5527 47 46 47Z"
        fill="#FFB784"
      />
    </svg>
  );
};
