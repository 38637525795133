import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const Measure2FilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M45.213 12.686L35.314 2.78698C34.533 2.00598 33.267 2.00598 32.486 2.78698L2.78704 32.485C2.00604 33.266 2.00604 34.532 2.78704 35.313L12.686 45.212C13.467 45.993 14.733 45.993 15.514 45.212L45.212 15.514C45.994 14.734 45.994 13.467 45.213 12.686Z"
        fill="#FFD330"
      />
      <path d="M29.6566 5.61571L28.2424 7.02991L33.8992 12.6867L35.3134 11.2725L29.6566 5.61571Z" fill="#0A3A5C" />
      <path d="M24 11.272L22.5858 12.6862L26.1213 16.2217L27.5355 14.8075L24 11.272Z" fill="#0A3A5C" />
      <path d="M18.3422 16.929L16.928 18.3432L22.5848 24L23.999 22.5858L18.3422 16.929Z" fill="#0A3A5C" />
      <path d="M7.02919 28.2422L5.61499 29.6564L11.2718 35.3132L12.686 33.899L7.02919 28.2422Z" fill="#0A3A5C" />
      <path d="M12.6863 22.586L11.2721 24.0002L14.8076 27.5357L16.2218 26.1215L12.6863 22.586Z" fill="#0A3A5C" />
    </svg>
  );
};
