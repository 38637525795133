/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const ProductIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg
      className="byko-icon"
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 11.5V22.5" stroke={iconColor} strokeMiterlimit="10" strokeWidth="2" />
      <path d="M3 6.5L12 11.5L21 6.5" stroke={iconColor} strokeMiterlimit="10" strokeWidth="2" />
      <path
        d="M21 17.5V6.5L12 1.5L3 6.5V17.5L12 22.5L21 17.5Z"
        stroke={iconColor}
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
};

export default ProductIcons;
