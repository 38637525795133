import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const ArrowUpIcons: BykoReactIconComponentType = ({ size, iconColor = "#00416A", ...props }) => {
  return (
    <svg
      className="byko-icon"
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6 14L12 8L18 14" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
    </svg>
  );
};
