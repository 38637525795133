/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const RecycleLineDecorative: BykoReactDecorativeIconComponentType = ({ size, color = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42.068 2.26001L43.526 13.854L32.142 10.682"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path
        d="M43.526 13.854C41.2555 9.48002 37.5821 5.99503 33.0947 3.95773C28.6073 1.92044 23.5658 1.44884 18.7784 2.61854C13.991 3.78824 9.73495 6.53149 6.69259 10.4085C3.65022 14.2856 1.99773 19.0718 2 24"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path
        d="M5.932 45.74L4.474 34.146L15.858 37.318"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path
        d="M4.474 34.146C6.74318 38.5227 10.4166 42.0104 14.9051 44.0496C19.3936 46.0888 24.4369 46.5613 29.2261 45.3913C34.0152 44.2213 38.2726 41.4767 41.3152 37.5976C44.3579 33.7186 46.0094 28.93 46.005 24"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
    </svg>
  );
};
