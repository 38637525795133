import Script from "next/script";

import { getBykoConfig } from "@byko/lib-utils";

export const Siteimprov = (): JSX.Element | null => {
  const isProd = getBykoConfig("ENV") === "prod";
  const siteimprovKey = getBykoConfig("SITEIMPROV_KEY");
  if (!isProd || !siteimprovKey || siteimprovKey.length === 0) {
    return null;
  }
  return <Script async={true} src={`https://siteimproveanalytics.com/js/siteanalyze_${siteimprovKey}.js`} />;
};
