/* eslint-disable @next/next/link-passhref */
import Link from "next/link";
import React, { useEffect, useMemo, useState } from "react";
import { useCallback } from "react";

import { IconButton } from "@byko/component-buttons";
import { MainLoader } from "@byko/component-loaders";
import { QuantityInput } from "@byko/component-quantity-input";
import { PSmall, PSmallBold, SubtitleBold } from "@byko/component-typography";
import { useCart } from "@byko/hooks-cart";
import { getPrice } from "@byko/lib-api-price";
import { useCartItemCount } from "@byko/lib-api-products";
import { CancelSmallIcons } from "@byko/lib-icons";
import { gridTheme, theme } from "@byko/lib-styles";
import { useWindowSize } from "@byko/lib-styles";
import { useDebouncedCallback } from "use-debounce";

import {
  CardContainer,
  FlexWrapper,
  LoaderContainer,
  OutOfStockWrapper,
  ProdImage,
  ProdImageWrapper,
  ProdInfoWrapper,
  QuantityInputWrapper,
} from "./styles";

import type { CardProps } from "./interface";

const placeholderImageHandler = (e: unknown): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const target = e.target as HTMLImageElement;
  target.src = "https://www.datocms-assets.com/65892/1664464657-placeholder-do-not-remove.jpg";
};

export const MiniCartCard = ({ cartItem }: CardProps): JSX.Element | null => {
  const variantId = cartItem.variant.id;
  const [showAnim, setShowAnim] = useState<boolean>(false);
  const count = useCartItemCount(variantId);
  const totalPrice = getPrice(cartItem?.totalPrice?.discountedAmount.gross ?? cartItem?.totalPrice?.amount.gross ?? 0);
  const { setCartQuantity, removeFromCart, loadingVariantIdCollection } = useCart();
  const { width } = useWindowSize();
  const displayName = useMemo((): string => {
    if (cartItem.variant.name && cartItem.variant.name.length) {
      return cartItem.variant.name;
    } else if (cartItem.variant.product.name && cartItem.variant.product.name.length) {
      return cartItem.variant.product.name;
    }

    // this should never happen
    return "";
  }, [cartItem.variant.name, cartItem.variant.product.name]);

  const isloading = loadingVariantIdCollection.includes(variantId);

  useEffect(() => {
    setShowAnim(true);
  }, []);

  const handleRemove = useCallback(() => {
    removeFromCart({
      variantId,
    });
  }, [removeFromCart, variantId]);

  const setQuantity = useDebouncedCallback((quantity: number) => {
    if (isNaN(quantity)) {
      return;
    }
    setCartQuantity({ variantId, quantity: quantity });
  }, 0);

  return (
    <CardContainer isloading={isloading} showAnim={showAnim}>
      {isloading ? (
        <LoaderContainer>
          <MainLoader size={24} />
        </LoaderContainer>
      ) : (
        <IconButton
          ariaLabel="Eyða úr körfu"
          disabled={loadingVariantIdCollection.length > 0}
          icon={CancelSmallIcons}
          small={true}
          title="Eyða úr körfu"
          onClick={handleRemove}
        />
      )}
      <ProdImageWrapper href={`/vara/${cartItem.variant.product.slug}`}>
        {cartItem.variant.webstoreInStock === false && (
          <OutOfStockWrapper>
            <PSmallBold textColor={theme.palette.triggers.warning.main}>Uppselt</PSmallBold>
          </OutOfStockWrapper>
        )}
        <ProdImage
          alt={cartItem.variant.name}
          src={
            cartItem.variant.firstImage?.image.productList ??
            cartItem.variant.product.firstImage?.image.productList ??
            "https://www.datocms-assets.com/65892/1664464657-placeholder-do-not-remove.jpg"
          }
          onError={placeholderImageHandler}
        />
      </ProdImageWrapper>
      <FlexWrapper>
        <ProdInfoWrapper>
          <Link href={`/vara/${cartItem.variant.product.slug}`}>
            <PSmall textColor={theme.palette.blue.dark}>{displayName}</PSmall>
          </Link>
          <SubtitleBold className="minicart-price-item">{totalPrice?.humanReadable}</SubtitleBold>
          <SubtitleBold className="hidden minicart-price-item-quantity">{count}</SubtitleBold>
        </ProdInfoWrapper>
        <QuantityInputWrapper>
          <QuantityInput
            miniVersion={width < gridTheme.container.maxWidth.s ? true : false}
            quantity={count}
            setQuantity={setQuantity}
            stretch={false}
          />
        </QuantityInputWrapper>
      </FlexWrapper>
    </CardContainer>
  );
};
