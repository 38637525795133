import { useRouter } from "next/router";
import React, { useCallback, useMemo } from "react";

import { Button } from "@byko/component-buttons";
import { PageContainer } from "@byko/component-page-container";
import { Slider } from "@byko/component-slider";
import { SubtitleBold } from "@byko/component-typography";
import { LongArrowSideIcons } from "@byko/lib-icons";
import { theme } from "@byko/lib-styles";
import { useWindowSize } from "@byko/lib-styles";

import {
  ResultsRowHeader,
  ResultsRowHeaderSeeAll,
  ResultsRowHeaderTitle,
  ResultsRowSwiper,
  ResultsRowWrapper,
} from "./styles";

import type { ResultsRowProps } from "./interface";

const VARIANT_WIDTH = {
  products: 264,
  pages: 317,
  articles: 429,
  brands: 330,
};

export const ResultsRow = ({
  query,
  title,
  icon: Icon,
  sliderCollection,
  variant,
  hideShowAll = false,
}: ResultsRowProps): JSX.Element | null => {
  const router = useRouter();
  const { activeBreakPoint, width } = useWindowSize();

  const breakpoints = useMemo(() => {
    const sliderPerView = width / VARIANT_WIDTH[variant];
    return {
      [width]: {
        slidesPerView: sliderPerView,
        spaceBetween: 10,
      },
    };
  }, [variant, width]);

  const handleClick = useCallback(() => {
    if (variant === "products") {
      router.push(`/vorur?q=${query}`);
    }
  }, [router, query, variant]);

  const getSlidesPerView = useCallback((): number => {
    let perView = 1;
    for (let i = Object.keys(breakpoints).length - 1; i > 0; i--) {
      if (window.innerWidth >= Number(Object.keys(breakpoints)[i])) {
        perView = Number(breakpoints[Number(Object.keys(breakpoints)[i])]?.slidesPerView);
        break;
      }
    }
    return perView;
  }, [breakpoints]);

  const showSeeAll = useMemo((): boolean => {
    if (hideShowAll) {
      return false;
    }

    const slidesPerView = getSlidesPerView();
    return slidesPerView <= sliderCollection.length;
  }, [getSlidesPerView, hideShowAll, sliderCollection.length]);

  if (sliderCollection.length === 0 || width === 0) {
    return null;
  }

  return (
    <ResultsRowWrapper>
      <PageContainer flexDirection="column" offsetNav={false}>
        <ResultsRowHeader>
          <ResultsRowHeaderTitle>
            <Icon iconColor={theme.palette.blue.main} size={22} />
            <SubtitleBold textColor={theme.palette.blue.main}>{title}</SubtitleBold>
          </ResultsRowHeaderTitle>
          <ResultsRowHeaderSeeAll>
            {showSeeAll && (
              <>
                {["sm", "md", "lg", "xl"].includes(activeBreakPoint) && (
                  <Button
                    buttonColor="blueButton"
                    icon={LongArrowSideIcons}
                    label="Sýna allar niðurstöður"
                    stretch={true}
                    onClick={handleClick}
                  />
                )}
                {["s", "xs"].includes(activeBreakPoint) && (
                  <Button
                    buttonColor="blueButton"
                    icon={LongArrowSideIcons}
                    label="Sjá allt"
                    stretch={false}
                    onClick={handleClick}
                  />
                )}
              </>
            )}
          </ResultsRowHeaderSeeAll>
        </ResultsRowHeader>
      </PageContainer>
      <PageContainer flexDirection="column" noGutter={true} offsetNav={false}>
        <ResultsRowSwiper className={title === "Síður" ? "pages" : ""}>
          <Slider
            autoPad={true}
            paginationGutter={true}
            resetCondition={query}
            slideCollection={sliderCollection}
            slideWidth={VARIANT_WIDTH[variant]}
          />
        </ResultsRowSwiper>
      </PageContainer>
    </ResultsRowWrapper>
  );
};
