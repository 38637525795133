/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from "react-query";

import { restApi } from "@byko/lib-api-rest";

import type { Api } from "@byko/lib-api-rest/src/ApiRoute";
import { useAuth } from "@byko/hooks-auth-next";

export type ProductVariantPrices = Api.ProductsPricesList.ResponseBody["results"];
export type ProductsLowestPricesList = Api.ProductsLowestPricesList.ResponseBody["results"];

export interface ProductVariantPricesQueryResult {
  prices: ProductVariantPrices | undefined;
  loadingPrices: boolean;
}
export interface ProductsLowestPricesQueryResult {
  prices: ProductsLowestPricesList | undefined;
  loadingPrices: boolean;
}

export function useProductLowestPrices(ids?: number[] | string[] | null): ProductsLowestPricesQueryResult {
  const { activeMembership, isAuthLoaded } = useAuth();
  const { data: prices, isLoading } = useQuery({
    queryKey: ["prices", ids?.sort(), activeMembership?.id],
    queryFn: async ({ queryKey }) => {
      const [_, _ids] = queryKey;
      const { data: priceData } = await restApi.productsLowestPricesList({
        // @ts-ignore
        id: _ids.join(","),
      });
      return priceData.results;
    },
    enabled: !!ids?.length && isAuthLoaded,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  return { prices, loadingPrices: isLoading };
}

export function useProductPrices(ids?: number[] | string[] | null): ProductVariantPricesQueryResult {
  const { activeMembership, isAuthLoaded } = useAuth();
  const { data: prices, isLoading } = useQuery({
    queryKey: ["prices", ids?.sort(), activeMembership?.id],
    queryFn: async ({ queryKey }) => {
      const [_, _ids] = queryKey;
      const { data: priceData } = await restApi.productsPricesList({
        // @ts-ignore
        id: _ids.join(","),
      });
      return priceData.results;
    },
    enabled: !!ids?.length && isAuthLoaded,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  return { prices, loadingPrices: isLoading };
}
