import { atom } from "@byko/lib-recoil";

import type { MenuLevel } from "../interface";

export const menuContentState = atom<MenuLevel[]>({
  key: "megamenu-content-state",
  // TODO: this hould be an empty array once we connect things
  // only used for demo purposes
  default: [],
});
