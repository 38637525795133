/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useCallback, useContext, useMemo } from "react";

import { useAuth } from "@byko/hooks-auth-next";

import {
  useCheckoutAddressesUpdateMutation,
  useCheckoutCompleteMutation,
  useCheckoutEmailUpdateMutation,
  useCheckoutPaymentCreateMutation,
} from "../../generated/graphql";

import {
  handleCheckoutError,
  useClearCheckout,
  useGetCheckout,
  useUpdateShippingMethod,
  useUpdateStore,
} from "./hooks";

import type {
  AddressInput,
  CheckoutAddressesUpdateMutation,
  CheckoutComplete,
  CheckoutFragment,
  CheckoutLine,
  CheckoutPaymentCreate,
  CheckoutShippingMethodUpdateMutation,
  PaymentInput,
} from "../../generated/graphql";
import type { CHECKOUT_ERROR } from "./const";
import type { FetchResult } from "@apollo/client/link/core/types";
import type { Maybe } from "@byko/types-utils";

export const CheckoutContext = createContext<CheckoutContextProps | null>(null);

interface CheckoutProviderProps {
  children: React.ReactNode;
}

export const CheckoutProvider = ({ children }: CheckoutProviderProps): JSX.Element => {
  const {
    checkoutReady,
    checkoutLine,
    anonCheckoutQuery,
    myCheckoutQuery,
    myCheckoutLoading,
    anonCheckoutLoading,
    refetchCheckout,
    refetchMyCheckout,
    refetch,
  } = useGetCheckout();

  const { update } = useUpdateStore({ refetch, refetchCheckout, refetchMyCheckout });
  const { user, loading } = useAuth();
  const showLogin = !loading;

  const handleUpdateShippingMethod = useUpdateShippingMethod({
    refetch,
    myCheckoutQuery,
  });

  const [checkoutAddressesUpdateMutation] = useCheckoutAddressesUpdateMutation({
    onCompleted: () => refetch(),
  });

  const [checkoutEmailUpdate] = useCheckoutEmailUpdateMutation();
  const [checkoutPaymentCreate] = useCheckoutPaymentCreateMutation();
  const [checkoutComplete] = useCheckoutCompleteMutation();

  const checkoutToken = myCheckoutQuery?.me?.checkout?.token ?? anonCheckoutQuery?.checkout?.token ?? undefined;
  const updateCheckoutAddresses = useCallback(
    (shippingAddress: AddressInput, billingAddress: AddressInput) =>
      checkoutAddressesUpdateMutation({
        variables: {
          token: checkoutToken,
          shippingAddress,
          billingAddress,
        },
      }).then((res) => res),
    [checkoutAddressesUpdateMutation, checkoutToken],
  );

  const updateCheckoutEmail = useCallback(
    (email: string) =>
      checkoutEmailUpdate({
        variables: {
          email,
          token: checkoutToken,
        },
      }).then((res) => {
        handleCheckoutError(res?.data?.checkoutEmailUpdate?.errors);
      }),

    [checkoutEmailUpdate, checkoutToken],
  );

  const createPayment = useCallback(
    (input: PaymentInput) =>
      checkoutPaymentCreate({
        variables: {
          token: checkoutToken,
          input,
        },
      }).then((res) => res?.data?.checkoutPaymentCreate as CheckoutPaymentCreate),
    [checkoutPaymentCreate, checkoutToken],
  );

  const completeCheckout = useCallback(
    async (paymentData?: string) => {
      const result = await checkoutComplete({
        variables: {
          token: checkoutToken,
          paymentData: paymentData ?? null,
        },
      });

      return result?.data?.checkoutComplete as CheckoutComplete;
    },
    [checkoutComplete, checkoutToken],
  );

  const getVariant = useCallback(
    (variantId: string): CheckoutLine | null => {
      const value = checkoutLine.find((item) => item.variant.id === variantId);
      return value ?? null;
    },
    [checkoutLine],
  );
  const isCheckoutLoading = useMemo(() => {
    if (!showLogin) {
      return true;
    }
    if (user) {
      return myCheckoutLoading;
    }
    return anonCheckoutLoading;
  }, [anonCheckoutLoading, myCheckoutLoading, showLogin, user]);

  const checkout = useMemo(() => {
    if (showLogin && user) {
      return myCheckoutQuery?.me?.checkout ?? undefined;
    }
    return anonCheckoutQuery?.checkout ?? undefined;
  }, [anonCheckoutQuery?.checkout, myCheckoutQuery?.me?.checkout, showLogin, user]);

  const token = useMemo(() => {
    if (showLogin && user) {
      return myCheckoutQuery?.me?.checkout?.token ?? undefined;
    }
    return anonCheckoutQuery?.checkout?.token ?? undefined;
  }, [anonCheckoutQuery?.checkout, myCheckoutQuery?.me?.checkout, showLogin, user]);

  const handleClearCart = useClearCheckout({
    token,
    checkout,
  });

  const value = useMemo(
    () => ({
      checkoutReady,
      getVariant,
      checkoutLine,
      cartUpdate: update,
      clearCart: handleClearCart,
      updateShippingMethod: handleUpdateShippingMethod,
      updateCheckoutAddresses,
      updateCheckoutEmail,
      createPayment,
      completeCheckout,
      checkout,
      checkoutLoading: isCheckoutLoading,
    }),
    [
      checkoutReady,
      getVariant,
      checkoutLine,
      update,
      handleClearCart,
      handleUpdateShippingMethod,
      updateCheckoutAddresses,
      updateCheckoutEmail,
      createPayment,
      completeCheckout,
      checkout,
      isCheckoutLoading,
    ],
  );

  return <CheckoutContext.Provider value={value}>{children}</CheckoutContext.Provider>;
};

interface CheckoutContextProps {
  checkoutReady: boolean;
  getVariant: (id: string) => CheckoutLine | null;
  checkoutLine: CheckoutLine[];
  cartUpdate: (state: Record<string, number>) => Promise<{ code: CHECKOUT_ERROR; message: string } | void>;
  clearCart: () => void;
  updateShippingMethod: (id: string) => Promise<
    FetchResult<
      CheckoutShippingMethodUpdateMutation,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Record<string, any>,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Record<string, any>
    >
  >;
  updateCheckoutAddresses: (
    shippingAddress: AddressInput,
    billingAddress: AddressInput,
  ) => Promise<
    FetchResult<
      CheckoutAddressesUpdateMutation,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Record<string, any>,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Record<string, any>
    >
  >;
  updateCheckoutEmail: (email: string) => Promise<void>;
  createPayment: (input: PaymentInput) => Promise<CheckoutPaymentCreate>;
  completeCheckout: (paymentData?: string) => Promise<CheckoutComplete>;
  checkout: Maybe<CheckoutFragment>;
  checkoutLoading: boolean;
}

export const useCheckout = (): CheckoutContextProps => {
  const value = useContext(CheckoutContext);
  if (value == null) {
    throw new Error("Should be used inside provider");
  }
  return value;
};
