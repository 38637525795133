import React, { useCallback, useReducer } from "react";

import { Button } from "@byko/component-buttons";
import { SearchIcons } from "@byko/lib-icons";

import { reducer } from "../../reducer";

import { MobileAccordionRow } from "./mobile-accordion-row";
import { MobileAccordionButtons, MobileAccordionWrapper } from "./styles";

import type { AccordionBlockProps } from "../../interface";

export const MobileAccordionBlock = ({
  configuration,
  handleReset,
  handleClose,
  handleSubmit,
}: AccordionBlockProps): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, {});
  const handleClick = useCallback(() => {
    if (!handleReset) {
      return;
    }
    handleReset();
  }, [handleReset]);
  const handleToggleActive = useCallback((id: number | string) => {
    dispatch({ type: "toggle", id });
  }, []);

  const handleSubmitClick = useCallback(() => {
    handleSubmit?.();
    handleClose?.();
  }, [handleSubmit, handleClose]);

  return (
    <MobileAccordionWrapper>
      {configuration.map((item) => {
        const active = state[item.id as number] ?? false;
        return (
          <>
            <MobileAccordionRow key={item.id} active={active} configuration={item} toggleActive={handleToggleActive} />
          </>
        );
      })}
      <MobileAccordionButtons>
        <Button buttonColor="whiteButton" label="Endurstilla síur" stretch={true} onClick={handleClick} />
        <Button buttonColor="blueButton" icon={SearchIcons} label="Leita" stretch={true} onClick={handleSubmitClick} />
      </MobileAccordionButtons>
    </MobileAccordionWrapper>
  );
};
