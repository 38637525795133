import Link from "next/link";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";

import { analytics } from "@byko/lib-analytics";
import { useOnScreen } from "@byko/lib-utils";
import { useCart } from "@byko/hooks-cart";
import { productBySlug, ProductListAugmented } from "@byko/lib-api-products";
import { useProductVariants } from "@byko/hooks-product-variants";
import type { ProductDetailAugmented } from "@byko/lib-api-products";
import { useAlgoliaInsights } from "@byko/lib-algolia";

import { PriceWrapper, ProductCardButtons, ProductCardInformation, ProductCardOpen, ProductImage } from "./components";
import { IconBackdrop, IconContainer, ProductCardContainer, TagBlock, TagContainer, TagText } from "./styles";
import type { ProductStandardCardProps, Tag } from "./interface";
import { PlanetIcons } from "@byko/lib-icons";
import { theme } from "@byko/lib-styles";

interface EnvironmentalInfo {
  en: string;
  "is-IS": string;
}

export const ProductStandardCard = ({
  product,
  smallMobileCard,
  prices,
  lowestPriceSKU,
  listIndex,
  analyticsSectionId,
  algoliaInsights,
}: ProductStandardCardProps): JSX.Element => {
  const environmentalAttributes = product?.attributes?.["environmentalInfoType"] as EnvironmentalInfo[] | null;
  const hasEnvironmentalAttributes = environmentalAttributes && environmentalAttributes.length > 0;

  const [localPrices, setLocalPrices] = useState(prices);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const isVisible = useOnScreen(containerRef);
  const { sendClickInsights } = useAlgoliaInsights();
  const [hasBeenVisible, setHasBeenVisible] = useState<boolean>(false);
  const [localProduct, setLocalProduct] = useState<ProductDetailAugmented>();
  const [activeVariantSKU, setActiveVariantSKU] = useState<string>(Object.keys(prices ?? {})[0] ?? "");
  const { activeVariant } = useProductVariants(
    localProduct ?? product,
    activeVariantSKU,
    setActiveVariantSKU,
    lowestPriceSKU,
  );
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const router = useRouter();
  const collapseRef = useRef<HTMLDivElement>(null);
  const { addToCart, isReady, isLoading } = useCart();

  useEffect(() => {
    if (lowestPriceSKU) {
      setActiveVariantSKU(lowestPriceSKU);
    }
  }, [lowestPriceSKU]);

  const price = useMemo(() => {
    return localPrices?.[activeVariantSKU];
  }, [activeVariantSKU, localPrices]);

  useEffect(() => {
    if (isVisible && !hasBeenVisible) {
      setHasBeenVisible(true);
      analytics.addProductListImpression(
        [product],
        { [product.id]: price?.price.gross.toString() ?? "" },
        analyticsSectionId,
      );
    }
    // we only want to fire this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, hasBeenVisible]);

  const fetchProduct = useCallback(async (): Promise<ProductDetailAugmented | null> => {
    const data = await productBySlug(product.slug);
    if (!data) {
      return null;
    }

    return data;
  }, [product.slug]);

  useEffect(() => {
    if (prices) {
      setLocalPrices(prices);
    }
  }, [prices]);

  const tags: Tag[] = useMemo(() => {
    const list: Tag[] = [];
    if (price?.discounted?.name === "RETAIL") {
      if (price?.discounted?.percentage) {
        return [
          {
            label: `-${price?.discounted?.percentage}%`,
            color: "yellow",
          },
        ];
      } else {
        return [
          {
            label: `Tilboð`,
            color: "yellow",
          },
        ];
      }
    }
    return list;
  }, [price]);

  const handleCollapseDetails = useCallback(() => {
    setShowDetail(false);
  }, []);

  useOnClickOutside(collapseRef, handleCollapseDetails);

  const addProductListClick = useCallback(() => {
    analytics.addProductListClick(product, price?.price.gross.toString() ?? "", listIndex, analyticsSectionId);

    !!algoliaInsights && sendClickInsights(algoliaInsights);
  }, [algoliaInsights, analyticsSectionId, listIndex, price?.price.gross, product, sendClickInsights]);

  const onClickProductDetailPage = useCallback(() => {
    addProductListClick();
    router.push(`/vara/${product.slug}`);
  }, [addProductListClick, product.slug, router]);

  const handleAddToCart = useCallback(
    (apiProduct: ProductDetailAugmented) => {
      const availableColors: string[] = [];
      const availableSizes: string[] = [];

      apiProduct.variants.forEach((variant) => {
        if (variant.meta.color && !availableColors.includes(variant.meta.color)) {
          availableColors.push(variant.meta.color);
        }
        if (variant.meta.size && !availableColors.includes(variant.meta.size)) {
          availableSizes.push(variant.meta.size);
        }
      });

      if (availableColors.length > 1 || availableSizes.length > 1) {
        setShowDetail(true);
      } else {
        if (activeVariant?.id) {
          addToCart({ slug: product.slug });
          return;
        }
        addToCart({ variantId: activeVariant?.id });
      }
    },
    [activeVariant?.id, addToCart, product.slug],
  );

  const handleButtonClick = useCallback(async (): Promise<void> => {
    let data: ProductDetailAugmented | null = localProduct ?? null;
    if (data === null) {
      data = await fetchProduct();

      if (data) {
        setLocalProduct(data);
        handleAddToCart(data);
      }
    } else {
      handleAddToCart(data);
    }
  }, [fetchProduct, handleAddToCart, localProduct]);

  const availability = useMemo((): boolean => {
    if (showDetail) {
      return activeVariant?.webstoreInStock ?? false;
    }

    return product?.webstoreInStock ?? false;
  }, [activeVariant?.webstoreInStock, product?.webstoreInStock, showDetail]);

  const handleClose = useCallback((): void => {
    setShowDetail(false);
  }, []);

  const getBrand = (product: ProductListAugmented): string | undefined => {
    const brandAttribute = product.attributes?.["brand"];
    return brandAttribute?.[0] !== "no brand" ? brandAttribute[0] : undefined;
  };

  const displayImage = useMemo((): string => {
    if (product.firstImage?.image?.productList) {
      return product.firstImage?.image?.productList;
    } else if (activeVariant?.firstImage?.image?.productList) {
      return activeVariant?.firstImage?.image?.productList;
    } else {
      return "https://www.datocms-assets.com/65892/1664464657-placeholder-do-not-remove.jpg";
    }
  }, [activeVariant?.firstImage?.image?.productList, product.firstImage?.image?.productList]);

  return (
    <ProductCardContainer
      ref={containerRef}
      className="product-card"
      showDetail={showDetail}
      smallMobileCard={smallMobileCard ?? false}
    >
      <TagContainer>
        {tags.map(({ label, color }): JSX.Element => {
          return (
            <TagBlock key={label} color={color}>
              <TagText>{label}</TagText>
            </TagBlock>
          );
        })}
      </TagContainer>
      {hasEnvironmentalAttributes && (
        <IconContainer href={`/vara/${product.slug}`} onClick={addProductListClick}>
          <IconBackdrop title="Umhverfisvæn vara">
            <PlanetIcons iconColor={theme.palette.white.main} size={24} />
          </IconBackdrop>
        </IconContainer>
      )}
      <Link href={`/vara/${product.slug}`} onClick={addProductListClick}>
        <ProductImage product={product} productImage={displayImage} />
        <ProductCardInformation
          brand={getBrand(product)}
          prodName={product.name}
          shortDecription={product.description}
          sku={activeVariant?.sku ?? ""}
        />
        <PriceWrapper activeVariantSKU={activeVariantSKU} prices={localPrices} product={localProduct ?? product} />
      </Link>

      <ProductCardButtons
        addToCartClick={handleButtonClick}
        disabledAddToCart={!isReady || showDetail}
        outOfStock={availability === false}
        showDetail={showDetail}
        onClickProductDetailPage={onClickProductDetailPage}
      />

      {showDetail && (
        <ProductCardOpen
          ref={collapseRef}
          activeVariantSKU={activeVariantSKU}
          algoliaInsights={algoliaInsights}
          handleClose={handleClose}
          isLoading={isLoading}
          localPrices={localPrices}
          setActiveVariantSKU={setActiveVariantSKU}
          setLocalPrices={setLocalPrices}
          show={showDetail}
          slug={product.slug}
          onClickProductDetailPage={onClickProductDetailPage}
        />
      )}
    </ProductCardContainer>
  );
};
