import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const DownloadIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg
      className="byko-icon"
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 1V17" stroke={iconColor} strokeMiterlimit="10" strokeWidth="2" />
      <path d="M18 11L12 17L6 11" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M22 22H2" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
    </svg>
  );
};
