import { httpLink } from "@trpc/client/links/httpLink";

import { getBykoConfig } from "@byko/lib-utils";

import { withTRPC } from "./with-trpc";

import type { AppRouter } from "@byko/lib-api-dato-ssr";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const withDato = () => {
  return withTRPC<AppRouter>({
    config: () => {
      const fullUrl = getBykoConfig("CMS_API_URL");

      return {
        links: [
          httpLink({
            url: fullUrl,
          }),
        ],
      };
    },
    /**
     * @link https://trpc.io/docs/ssr
     */
    ssr: true,
    responseMeta: ({ clientErrors }) => {
      return {
        status: clientErrors.length ? 404 : 200,
      };
    },
  });
};
