import { gql } from "graphql-tag";
import { useQuery } from "react-query";
import { graphQLClient } from "../dato-graphql-client";
import { StringRecordFragment } from "./fragments";

import type { GetStringByKeyQuery, GetStringByKeyQueryVariables } from "../generated-gql/graphql";

export function useGetStringByKey({ key }: GetStringByKeyQueryVariables) {
  const { data, isLoading, error } = useQuery(`get-string-by-${key}`, async () => {
    const request = await graphQLClient.request<{
      allStrings: GetStringByKeyQuery;
    }>(
      gql`
        query GetStringByKey($key: String!) {
          allStrings(filter: { key: { eq: $key } }) {
            ...StringRecordFragment
          }
        }
        ${StringRecordFragment}
      `,
      { key },
    );
    return request;
  });

  return { data: data?.allStrings, isLoading, error };
}
