import Script from "next/script";
import { createContext, useContext, useMemo } from "react";
import React from "react";

import type { Category } from "@byko/lib-api-rest";

type CategoryContext = Category[] | null;
const CategoryContext = createContext<CategoryContext>(null);

export const useCategories = (): Category[] => {
  const data = useContext(CategoryContext);
  if (!data) {
    throw new Error("Invalid usage of context");
  }
  return data;
};

export const CategoriesProvider = ({
  categories,
  children,
}: {
  categories: Category[];
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  const script = useMemo(() => {
    return {
      __html: `window.__BYKO_CATEGORIES__ = JSON.parse(${JSON.stringify(JSON.stringify(categories))})`,
    } as { __html: string };
  }, [categories]);

  return (
    <>
      <Script dangerouslySetInnerHTML={script} id="byko-categories" strategy="beforeInteractive" />
      <CategoryContext.Provider value={categories}>{children}</CategoryContext.Provider>
    </>
  );
};
