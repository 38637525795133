import { atom, selector } from "@byko/lib-recoil";
import { isNotNull } from "@byko/types-utils";

import { Key } from "./const";

import type { CheckoutFragment } from "../../../generated/graphql";
import type { Maybe } from "@byko/types-utils";
import type { Checkout } from "@byko/lib-api-rest";

export const _checkout = atom<Maybe<CheckoutFragment | Checkout>>({
  key: Key("Checkout"),
  default: null,
});

export type CheckourVariantProductParsed = NonNullable<
  NonNullable<NonNullable<CheckoutFragment | Checkout>["lines"]>[number]
>["variant"]["product"] & {
  parsedID: number;
};

export type CheckourVariantParsed = NonNullable<
  NonNullable<NonNullable<CheckoutFragment | Checkout>["lines"]>[number]
>["variant"] & {
  parsedID: number;
  product: CheckourVariantProductParsed;
};
export type CheckoutLinesParsed = NonNullable<
  NonNullable<NonNullable<CheckoutFragment | Checkout>["lines"]>[number]
> & {
  variant: CheckourVariantParsed;
};

export type CheckoutParsed = Maybe<CheckoutFragment | Checkout> &
  Maybe<{
    lines: CheckoutLinesParsed[];
  }>;

export const checkout = selector<CheckoutParsed>({
  key: Key("Checkout-Parsed"),
  get: ({ get }) => {
    const value = get(_checkout);
    // TODO: Add id to checkout fragment
    if (!value || !value.lines || value.lines.length === 0) {
      return value as CheckoutParsed;
    }

    const lines = (value?.lines ?? [])
      .map((e) => {
        if (e?.variant?.id) {
          return {
            ...e,
            variant: {
              ...e.variant,
              product: e.variant.product
                ? {
                    ...e.variant.product,
                    parsedID: e.variant.product.id ?? null,
                  }
                : null,
              parsedID: e.variant.id ?? null,
            },
          };
        }
        return e;
      })
      .filter(isNotNull);
    return {
      ...value,
      lines: lines ?? [],
    } as CheckoutParsed;
  },
});
