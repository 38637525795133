import * as React from "react";

import { PMedium } from "@byko/component-typography";
import { useRecoilValue } from "@byko/lib-recoil";

import { Item, Suggestions } from "./picker-search-styles";
import { filteredAccountsState } from "./store";
import { SuggestedItem } from "./suggested-item";

export const SuggestionsList = (): JSX.Element => {
  const filteredAccounts = useRecoilValue(filteredAccountsState);

  return filteredAccounts.length ? (
    <Suggestions>
      {filteredAccounts.map((item) => (
        <SuggestedItem key={item.id} id={item.id} name={item.account.name || ""} ssn={item.account.ssn} />
      ))}
    </Suggestions>
  ) : (
    <Suggestions>
      <Item>
        <PMedium>Ekkert fannst</PMedium>
      </Item>
    </Suggestions>
  );
};
