/* eslint-disable @next/next/no-img-element */
import React, { useCallback, useEffect, useState } from "react";
import { useMemo } from "react";

import styled from "styled-components";

import { KeyValueFields } from "@byko/card-content-tabs";
import { PageContainer } from "@byko/component-page-container";
import { ProductSliderSection } from "@byko/component-product-slider-section";
import { DrillIcons, EyeOpenIcons, PdfIcons, PlanetIcons } from "@byko/lib-icons";
import { MotionWrapper, theme } from "@byko/lib-styles";
import { useAlgoliaInsights } from "@byko/lib-algolia";

import { prepareProductVariantSpecs } from "../../utils";

import { ProductImageAndDetail, TabsSection, VideoSection } from "./component";

import type { ProductStandardDetailProps } from "./product-detail";
import type { ProductDetail, VariantDetail } from "@byko/lib-api-rest";
import type { Maybe } from "@byko/types-utils";
import { Button } from "@byko/component-buttons";
import {
  ActiveEnvironmentContent,
  ActiveEvironmentHeader,
  ButtonContainer,
  EnvironmentContainer,
  EnvironmentImageContainer,
  EnvironmentImageContainerMobile,
  SelectionContainer,
} from "./styles";
import { EyebrowMedium, PSmall } from "@byko/component-typography";
import { datoQueries } from "@byko/lib-dato";
import type { EnvironmentalInformationQuery } from "@byko/lib-dato/src/generated-gql/graphql";
import { Select } from "@byko/component-select";
import type { TagItem } from "src/features/tags/interface";

interface PlaceholderDetails {
  brand: string;
  tags: TagItem[];
}

export interface VideoData {
  videoSlide: JSX.Element[];
  videoHeading: string;
}

export interface AdditionalProducts {
  productSliderSectionHeading: string | undefined;
  productSliderSectionCollection: JSX.Element[];
  productSliderSectionParagraph: JSX.Element;
}

export interface ProductPageProps {
  videoData?: Maybe<VideoData>;
  productImageSrc?: string;
  producDetails?: ProductStandardDetailProps;
  additionalProducts?: Maybe<AdditionalProducts>;
  product: ProductDetail;
  placeholderDetails?: Maybe<PlaceholderDetails>;
  defaultVariant: VariantDetail;
  rental: boolean;
}

interface EnvironmentalInfo {
  en: string;
  "is-IS": string;
}
const ProductSliderSectionWrapper = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;
`;

export const ProductPageContent = ({
  additionalProducts,
  videoData,
  product,
  placeholderDetails,
  defaultVariant,
  rental,
}: ProductPageProps): JSX.Element => {
  const { data: environmentData } = datoQueries.useGetEnvironmentalInformation();

  const { algoliaInit, sendViewInsights } = useAlgoliaInsights();
  // FIXME: SKU should be required on the variant on the backend
  const [activeVariantSKU, setActiveVariantSKU] = useState<string>(defaultVariant.sku ?? "");

  useEffect(() => {
    setActiveVariantSKU(defaultVariant.sku ?? "");
  }, [defaultVariant.sku]);

  const activeVariant = useMemo(() => {
    return product.variants.find((v) => v.sku === activeVariantSKU) as VariantDetail;
  }, [activeVariantSKU, product.variants]);

  useEffect(() => {
    setActiveVariantSKU(defaultVariant.sku ?? "");
  }, [defaultVariant.sku]);

  useEffect(() => {
    sendViewInsights({
      eventName: "View Product Detail",
      objectIDs: [product.id.toString()],
    });
  }, [algoliaInit, product.id, sendViewInsights]);

  const specs = useMemo(() => {
    if (!activeVariant) return [];
    return prepareProductVariantSpecs(activeVariant);
  }, [activeVariant]);

  const tabCollection = useMemo(() => {
    const environmentalAttributes = product.attributes["environmentalInfoType"] as EnvironmentalInfo[];

    const tabs = [];
    if (specs.length) {
      tabs.push({
        icon: DrillIcons,
        label: "Tæknilegar upplýsingar",
        highlightColor: theme.palette.blue.main,
        content: <KeyValueFields collection={specs} />,
      });
    }

    if (environmentalAttributes.length) {
      tabs.push({
        icon: PlanetIcons,
        label: "Umhverfisvottun á vöru",
        highlightColor: theme.palette.green.main,
        content: (
          <EnvironmentalInfo
            additionalInformation={environmentData}
            environmentalInformation={environmentalAttributes}
          />
        ),
      });
    }

    if (product.resources.length) {
      tabs.push({
        icon: PdfIcons,
        label: "Fylgiskjöl",
        highlightColor: theme.palette.blue.main,
        content: <KeyValueFields collection={product.resources} />,
      });
    }

    return tabs.length ? tabs : null;
  }, [environmentData, product.attributes, product.resources, specs]);

  return (
    <MotionWrapper>
      <ProductImageAndDetail
        activeVariant={activeVariant}
        brand={placeholderDetails?.brand}
        product={product}
        rental={rental}
        setActiveVariantSKU={setActiveVariantSKU}
        tags={placeholderDetails?.tags}
      />

      <PageContainer
        backgroundColor={theme.palette.gray[5]}
        flexDirection="column"
        justifyContent="center"
        offsetNav={false}
      >
        {tabCollection ? <TabsSection tabCollection={tabCollection} /> : null}
        {videoData ? <VideoSection videoHeading={videoData.videoHeading} videoSlide={videoData.videoSlide} /> : null}
      </PageContainer>

      {additionalProducts ? (
        <ProductSliderSectionWrapper>
          <ProductSliderSection
            collection={additionalProducts.productSliderSectionCollection}
            heading={additionalProducts.productSliderSectionHeading}
            paragraph={additionalProducts.productSliderSectionParagraph}
          />
        </ProductSliderSectionWrapper>
      ) : null}
    </MotionWrapper>
  );
};

export interface SortingOption {
  label: string;
  value: string;
  active?: boolean;
  id: number;
}

const handleItemToString = (option: { label?: string | null } | null): string => option?.label ?? "";

function EnvironmentalInfo({
  environmentalInformation,
  additionalInformation,
}: {
  environmentalInformation: EnvironmentalInfo[];
  additionalInformation?: EnvironmentalInformationQuery | undefined;
}) {
  const [active, setActive] = useState<string>(environmentalInformation[0]?.["is-IS"] ?? "");
  const activeDescription =
    additionalInformation?.allEnvironmentalInformations?.find((item) => item.pimKey === active)?.description ?? "";

  const activeLogo = additionalInformation?.allEnvironmentalInformations?.find((item) => item.pimKey === active)?.logo;

  let imageUrl = "";

  if (activeLogo && activeLogo.responsiveImage) {
    imageUrl = activeLogo.responsiveImage.src;
  } else {
    imageUrl = activeLogo?.url ?? "";
  }

  const handleChange = useCallback((value: { selectedItem?: SortingOption | null } | null) => {
    if (!value || !value.selectedItem) {
      return;
    }
    const newItem = value.selectedItem;
    setActive(newItem.value);
  }, []);

  const availableItems = useMemo(() => {
    return environmentalInformation.map((item, index) => {
      return {
        label: item["is-IS"],
        value: item["is-IS"],
        id: index,
      };
    });
  }, [environmentalInformation]);

  const selectedItem = useMemo(() => {
    return availableItems.find((item) => item.value === active);
  }, [active, availableItems]);

  return (
    <EnvironmentContainer>
      <SelectionContainer>
        <EyebrowMedium>Merki</EyebrowMedium>
        <Select
          align="left"
          fit={true}
          handleSelectedItemChange={handleChange}
          hideDefaultPlaceholder={true}
          hideValue={true}
          items={availableItems}
          itemToString={handleItemToString}
          placeholder={selectedItem?.value}
          selectedItem={selectedItem}
          textColor={theme.palette.blue.dark}
        />
      </SelectionContainer>
      <ButtonContainer>
        {environmentalInformation.map((info) => {
          return (
            <div key={info["is-IS"]}>
              <EnvironmentButton
                active={active === info["is-IS"]}
                environmentalInformation={info}
                setActive={setActive}
              />
            </div>
          );
        })}
      </ButtonContainer>
      <EnvironmentImageContainerMobile>
        {activeLogo != undefined && (
          <div>
            <img alt={active} src={imageUrl} />
          </div>
        )}
      </EnvironmentImageContainerMobile>
      <ActiveEnvironmentContent>
        <ActiveEvironmentHeader>{active}</ActiveEvironmentHeader>
        <PSmall textColor="black">{activeDescription}</PSmall>
      </ActiveEnvironmentContent>
      <EnvironmentImageContainer>
        {activeLogo != undefined && (
          <div>
            <img alt={active} src={imageUrl} />
          </div>
        )}
      </EnvironmentImageContainer>
    </EnvironmentContainer>
  );
}

function EnvironmentButton({
  environmentalInformation,
  active,
  setActive,
}: {
  environmentalInformation: EnvironmentalInfo;
  active: boolean;
  setActive: (value: string) => void;
}) {
  const handleSetActive = useCallback(() => {
    setActive(environmentalInformation["is-IS"]);
  }, [environmentalInformation, setActive]);

  return (
    <Button
      buttonColor={active ? "blueButton" : "whiteButton"}
      icon={EyeOpenIcons}
      label={environmentalInformation["is-IS"]}
      onClick={handleSetActive}
    />
  );
}
