import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const RulerPencilFilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 1H5C4.448 1 4 1.448 4 2V46C4 46.552 4.448 47 5 47H21C21.552 47 22 46.552 22 46V2C22 1.448 21.552 1 21 1Z"
        fill="#FFD330"
      />
      <path d="M12 7H4V9H12V7Z" fill="#0A3A5C" />
      <path d="M9 15H4V17H9V15Z" fill="#0A3A5C" />
      <path d="M12 23H4V25H12V23Z" fill="#0A3A5C" />
      <path d="M12 39H4V41H12V39Z" fill="#0A3A5C" />
      <path d="M9 31H4V33H9V31Z" fill="#0A3A5C" />
      <path d="M44 9V2C44 1.448 43.553 1 43 1H31C30.447 1 30 1.448 30 2V9H44Z" fill="#0A3A5C" />
      <path d="M44 8H30V10H44V8Z" fill="#E6E7E8" />
      <path d="M37 10H30V37H37V10Z" fill="#00416A" />
      <path d="M44 10H37V37H44V10Z" fill="#00416A" />
      <path d="M30 37L37 47L44 37H30Z" fill="#FFEC8E" />
      <path d="M39.7999 43H34.2L37 47L39.7999 43Z" fill="#0A3A5C" />
    </svg>
  );
};
