import React from "react";

import { PMedium } from "@byko/component-typography";
import { PagesIcons } from "@byko/lib-icons";
import { AfreidslaIllustration } from "@byko/lib-illustrations";

import { ResultsRow } from "./results-row";
import { Container, DesktopOnly, MobileOnly, NothingFoundContainer, ResultRows } from "./styles";

import type { SearchSceneProps } from "./interface";

export default function SearchOverview({ query, pages, isLoading }: SearchSceneProps) {
  const notFound = !pages.length;

  if (isLoading) {
    return <></>;
  }

  if (notFound) {
    return (
      <Container>
        <ResultRows>
          <NothingFoundContainer>
            <PMedium>Ekkert fannst</PMedium>
            <DesktopOnly>
              <AfreidslaIllustration size={468} />
            </DesktopOnly>
            <MobileOnly>
              <AfreidslaIllustration size={300} />
            </MobileOnly>
          </NothingFoundContainer>
        </ResultRows>
      </Container>
    );
  }
  return (
    <Container>
      <ResultRows>
        <ResultsRow
          hideShowAll={true}
          icon={PagesIcons}
          query={query}
          sliderCollection={pages}
          title="Síður"
          variant="pages"
        />
      </ResultRows>
    </Container>
  );
}
