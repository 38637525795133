/* eslint-disable
  react/jsx-sort-props,
  react-perf/jsx-no-new-function-as-prop,
  react-perf/jsx-no-new-array-as-prop,
  react/jsx-no-bind,
  no-empty,
  @typescript-eslint/ban-ts-comment */

import algoliasearchHelper from "algoliasearch-helper";
import algoliasearch from "algoliasearch";
import { addSeconds } from "date-fns";
import { fromPairs, mapValues, omit, pick, pickBy, toPairs } from "lodash";
import { useQuery } from "react-query";
import { ensureList } from "@byko/lib-utils";

import {
  //
  API_KEY,
  APP_ID,
  DEFAULT_PAGE_SIZE,
  FACET_UNIT_OVERRIDES,
  PRICE_PATH,
  PRODUCT_SORT_OPTIONS,
  RANGE_FACETS,
  SEARCH_INDEXES,
} from "./conf";

import type { CompiledFacets, FacetOrderValues, FacetTypeRecord, HierarchicalFacetOption } from "./types";
import type { ChoiceFacetOption } from "./components";
import type { SearchOptions } from "@algolia/client-search";
import type { Category, ProductList } from "@byko/lib-api-rest";
import type { PlainSearchParameters, SearchResults } from "algoliasearch-helper";
import type { QueryFunctionContext } from "react-query";
import type { AlgoliaEmployeeHit, AlgoliaPageHit } from "../dato-algolia-sync/processors";

type BaseSearchParameters = Omit<PlainSearchParameters & SearchOptions, "query">;

interface SearchParameters extends BaseSearchParameters {
  query?: string | undefined | null;
}

export const searchClient = algoliasearch(APP_ID, API_KEY);
export const productSearchClient = algoliasearchHelper(searchClient, SEARCH_INDEXES.products);
export const contentSearchClient = algoliasearchHelper(searchClient, SEARCH_INDEXES.content);
export const productSortOptionsMap = fromPairs(PRODUCT_SORT_OPTIONS.map((o) => [o.value, o]));

export function algoliaMultiQueryFetcher(query: string) {
  return searchClient.multipleQueries<ProductList | AlgoliaPageHit | AlgoliaEmployeeHit>(
    [
      {
        indexName: `${SEARCH_INDEXES.products}_popular`,
        query,

        params: {
          hitsPerPage: 5,
          facets: [PRICE_PATH, "categories", "attributes.*"],
          facetFilters: [PRICE_PATH, "categories", "attributes.*"],
          maxFacetHits: 100,
          facetingAfterDistinct: true,
        },
      },
      {
        indexName: SEARCH_INDEXES.content,
        query,
        params: {
          facets: [PRICE_PATH, "categories", "attributes.*"],
          facetFilters: [PRICE_PATH, "categories", "attributes.*"],
          maxFacetHits: 10,
          facetingAfterDistinct: true,
        },
      },
      {
        indexName: SEARCH_INDEXES.employees,
        query,
        params: {
          facets: ["attributes.*"], // TODO: Is this correct? Can we add more facets?
          facetFilters: ["attributes.*"], // TODO: Is this correct? Can we add more facet filters?
          maxFacetHits: 100,
          facetingAfterDistinct: true,
        },
      },
    ],
    {
      strategy: "none",
    },
  );
}

export function useAlgoliaMultiQuery(props: FetchProductsProps) {
  return useQuery([props.query, "algolia"], () => algoliaMultiQueryFetcher(props.query || ""), {
    enabled: !!props.query,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
}

// TODO: Create simple polymorghic localStorage/memory cache in utils
export const storage =
  typeof sessionStorage == "undefined"
    ? {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        obj: {} as Record<string, any>,
        get<T>(key: string): T | null {
          return this.obj[key] ?? null;
        },
        set<T>(key: string, data: T): void {
          this.obj[key] = data;
        },
      }
    : {
        get: <T>(key: string): T | null => {
          try {
            return JSON.parse(window.sessionStorage.getItem(key) ?? "") as T;
          } catch (e) {
            return null;
          }
        },
        set: <T>(key: string, data: T): void => {
          sessionStorage.setItem(key, JSON.stringify(data));
        },
      };

async function getAvailableFacetKeys(indexName: string) {
  let availableFacets: {
    keys: undefined | string[];
    exp: Date;
  } | null = storage.get("availableFacets");

  const now = new Date();

  if (!availableFacets?.keys?.length || now > new Date(availableFacets?.exp)) {
    const { results } = await searchClient.search<ProductList>([
      {
        indexName,
        params: {
          hitsPerPage: 1,
          analytics: false,
          analyticsTags: [],
          facets: [PRICE_PATH, "categories", "attributes.*"],
          clickAnalytics: true,
        },
      },
    ]);
    const facets = results?.[0]?.facets;

    availableFacets = {
      keys: facets && Object.keys(facets),
      exp: addSeconds(now, 60),
    };
    storage.set("availableFacets", availableFacets);
  }

  return availableFacets.keys;
}

type Filters = Omit<SearchParameters, "query" | "index" | "sortBy" | "page" | "pageSize">;

export interface FetchProductsProps {
  productIds?: string[] | undefined | null;
  categoryId?: number | undefined | null;
  sortBy?: string | undefined | null;
  query?: string | undefined | null;
  page?: number;
  pageSize?: number;
  filters?: Filters;
}

export async function fetchAlgoliaProducts({
  productIds,
  categoryId,
  sortBy,
  query,
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  filters,
}: FetchProductsProps) {
  const indexName = `${SEARCH_INDEXES.products}_${sortBy || "popular"}`;

  const strFilters = [];

  if (categoryId) {
    strFilters.push(`categories:${categoryId}`);
  }

  if (productIds && productIds.length > 0) {
    strFilters.push(`objectID:${productIds.join(" OR objectID:")}`);
  }

  // Do the actual search:
  const params: SearchParameters = {
    query,
    index: indexName,
    disjunctiveFacets: (await getAvailableFacetKeys(indexName)) ?? [],
    ...filters,
    filters: strFilters.join(" AND "),
    hitsPerPage: pageSize,
    page: page - 1,
    clickAnalytics: true,
  };
  const res = await productSearchClient.searchOnce(params);

  const result = {
    ...res.content,
  } as algoliasearchHelper.SearchResults<ProductList>;
  // @ts-ignore
  delete result._state;
  // @ts-ignore
  delete result._rawResults;

  return result;
}

export function algoliaProductsQueryFetcher(context: QueryFunctionContext<[string, string, FetchProductsProps]>) {
  return fetchAlgoliaProducts(context.queryKey[2]);
}

export function useAlgoliaProductQuery(props: FetchProductsProps, initialData?: SearchResults<ProductList>) {
  return useQuery(["algolia", SEARCH_INDEXES.products, props], algoliaProductsQueryFetcher, {
    initialData,
    enabled: !!props.query || !!props.categoryId,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
}

interface FetchContentProps {
  query?: string | undefined | null;
  page?: number;
  pageSize?: number;
}

export async function fetchAlgoliaContent({
  //
  query,
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE,
}: FetchContentProps) {
  const params: SearchParameters = {
    query,
    index: SEARCH_INDEXES.content,
    hitsPerPage: pageSize,
    page: page - 1,
    clickAnalytics: true,
  };
  const res = await contentSearchClient.searchOnce(params);

  const result = {
    ...res.content,
  } as algoliasearchHelper.SearchResults<AlgoliaPageHit>;
  // @ts-ignore
  delete result._state;
  // @ts-ignore
  delete result._rawResults;

  return result;
}

export function algoliaContentQueryFetcher(context: QueryFunctionContext<[string, string, FetchContentProps]>) {
  return fetchAlgoliaContent(context.queryKey[2]);
}

export function useAlgoliaContentQuery(props: FetchContentProps) {
  return useQuery(["algolia", SEARCH_INDEXES.content, props], algoliaContentQueryFetcher, {
    enabled: !!props.query,
    keepPreviousData: true,
  });
}

export function compileAlgoliaProductFilters(q: Record<string, string | undefined>): Filters {
  const query = omit(q, ["q", "slug", "sort", "page"]);

  // Only use filtering params
  const filteredQuery = pickBy(
    query,
    (v, k) => (v && k.startsWith("specs.")) || k.startsWith("attributes.") || k === "categories" || k === PRICE_PATH,
  ) as Record<string, string>;

  const numericRefinements = mapValues(
    //
    pick(filteredQuery, RANGE_FACETS),
    (v) => {
      const [minV, maxV] = v.split("...");
      return {
        ">=": minV ? [parseInt(minV, 10)] : [],
        "<=": maxV ? [parseInt(maxV, 10)] : [],
      };
    },
  );

  const cleanQuery = mapValues(filteredQuery, (v) =>
    ensureList(v?.split(",")).map((item: string) => item.replace("_", ",")),
  );

  const disjunctiveFacetsRefinements = omit(cleanQuery, RANGE_FACETS);

  return {
    disjunctiveFacetsRefinements,
    numericRefinements,
  };
}

export function sortFacetOptions(
  key: string,
  options: ChoiceFacetOption[],
  facetOrderValues: FacetOrderValues | undefined,
): ChoiceFacetOption[] {
  if (!facetOrderValues) {
    return options;
  }
  const order = facetOrderValues[key]?.order;
  const remainingOrder = facetOrderValues[key]?.sortRemainingBy;

  const sortOptionsMap = fromPairs(options.map((o) => [o.label, o]));

  const orderedOptions = ensureList(order?.map((o) => sortOptionsMap[o]));
  const remainingOptions = options?.filter((f) => !order?.includes(f.label));

  if (remainingOptions?.length && remainingOrder === "alpha") {
    remainingOptions.sort((a, b) => a.label.localeCompare(b.label));
  } else if (remainingOptions?.length && remainingOrder === "count") {
    remainingOptions.sort((a, b) => b.count - a.count);
  }

  return [...orderedOptions, ...remainingOptions];
}

export interface FacetTreeItem extends HierarchicalFacetOption {
  children?: FacetTreeItem[];
  parents?: FacetTreeItem[];
}

export function compileFacets(
  results: algoliasearchHelper.SearchResults<ProductList> | undefined,
  allCategories: Category[],
  queryParams: Record<string, string[]>,
  attrNameMap: Record<string, string>,
  activeCategorySlug?: string | undefined,
): CompiledFacets {
  const disjunctiveFacets = fromPairs(results?.disjunctiveFacets?.map((f) => [f.name, f]) ?? []);
  const catFacet = disjunctiveFacets["categories"];
  delete disjunctiveFacets["categories"];

  const categoryMap = fromPairs(allCategories.map((c) => [c.id, c]));

  const categories = ensureList(
    toPairs(catFacet?.data).map(([idStr, count]) => {
      const id = parseInt(idStr, 10);
      const cat = categoryMap[id];
      if (cat && cat.slug !== activeCategorySlug) {
        return {
          value: id,
          label: cat?.name,
          count,
          active: queryParams["categories"]?.includes(idStr) ?? false,
          parent: cat.parent,
        } as HierarchicalFacetOption;
      }
      return null;
    }),
  );

  const specs = fromPairs(
    toPairs(disjunctiveFacets)
      .filter(([k]) => k.indexOf("specs.") === 0 || k.indexOf("attributes.") === 0 || k === PRICE_PATH)
      .map(([attr, { data: valueCounts }]) => {
        const isSpec = attr.indexOf("specs.") === 0;
        const unit = FACET_UNIT_OVERRIDES[attr] ?? (isSpec ? attr.split("|")[1] : undefined);
        const facetLabel = attrNameMap[attr] ?? attr.replace("specs.", "").replace("attributes.", "").split("|")[0];

        if (RANGE_FACETS.includes(attr)) {
          const _facet = disjunctiveFacets[attr];
          const stats = _facet?.stats;
          const data = _facet?.data;
          const val: string[] | undefined = queryParams[attr];
          const [minValue, maxValue] = (val?.[0] || "").split("...");

          return [
            attr,
            {
              type: "range",
              label: facetLabel,
              unit,
              min: stats?.min,
              max: stats?.max,
              minValue: parseInt(minValue || "0", 10) || undefined,
              maxValue: parseInt(maxValue || "0", 10) || undefined,
              data, // What's this for?
            },
          ];
        }

        const options = toPairs(valueCounts).map(([value, count]) => {
          const sanitizedValue = value.replace(",", "_");
          const label: string = unit ? `${value} ${unit}` : value;

          return {
            value: sanitizedValue,
            label,
            count,
            active: queryParams[attr]?.includes(sanitizedValue) ?? false,
          };
        });

        return [
          attr,
          {
            type: "choice",
            label: facetLabel,
            options,
          },
        ];
      }),
  );

  return {
    categories: {
      type: "hierarchical",
      label: "Vöruflokkar",
      options: categories,
    },
    ...specs,
  };
}

export interface ActiveFilterProps {
  attr: string;
  value: string | number;
  label: string;
  min?: string;
  max?: string;
}

export function compileActiveFilters(facets: FacetTypeRecord): ActiveFilterProps[] {
  return toPairs(facets).reduce((acc: ActiveFilterProps[], [attr, facet]) => {
    return [
      ...acc,

      ...(facet.type === "choice" || facet.type === "hierarchical"
        ? facet.options
            .filter((opt) => opt.active)
            .map(({ value, label }) => ({
              attr,
              value,
              label,
            }))
        : []),

      ...(facet.type === "range" && (!!facet.minValue || !!facet.maxValue)
        ? [
            {
              attr,
              value: facet.label,
              label: facet.label,
            },
          ]
        : []),
    ];
  }, []);
}
