import { useCallback } from "react";

import { atom, useRecoilValue, useSetRecoilState } from "@byko/lib-recoil";

const key = <T extends string>(value: T): `byko-search${T}` => `byko-search${value}` as const;

const searchStore = atom<string>({
  key: key("value"),
  default: "",
});

const showQuickSearchResultsStore = atom<boolean>({
  key: "showQuickSearchResults",
  default: false,
});

const searchBarHasFocusStore = atom<boolean>({
  key: "searchBarHasFocus",
  default: false,
});

export const useShowQuickSearchResultsValue = (): boolean => useRecoilValue(showQuickSearchResultsStore);
export const useSearchBarHasFocusStore = (): boolean => useRecoilValue(searchBarHasFocusStore);
export const useSearchValue = (): string => useRecoilValue(searchStore);

export const useSetShowQuickSearchResultsValue: () => (value: boolean) => void = () => {
  const setValue = useSetRecoilState(showQuickSearchResultsStore);
  return useCallback(
    (value: boolean) => {
      setValue(value);
    },
    [setValue],
  );
};

export const useSetSearchBarHasFocusStore: () => (value: boolean) => void = () => {
  const setValue = useSetRecoilState(searchBarHasFocusStore);
  return useCallback(
    (value: boolean) => {
      setValue(value);
    },
    [setValue],
  );
};

export const useSetSearchValue: () => (value: string) => void = () => {
  const setValue = useSetRecoilState(searchStore);
  return useCallback(
    (value: string) => {
      setValue(value);
    },
    [setValue],
  );
};
