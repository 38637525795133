// Helper function to handle the common formatting logic
// This was taken from packages/lib-api-price/src/store.ts
const formatPrice = (amount: number): string => {
  return `${new Intl.NumberFormat("is-IS", { style: "currency", currency: "ISK" })
    .format(Math.ceil(amount))
    .replace("ISK", "")
    .split("")
    .filter((x) => x.trim() !== "")
    .join("")
    .replaceAll(/,/g, ".")} kr.`
    .replace("&nbsp;", "")
    .replace(/kr\. kr\./g, " kr.");
};

export const formatPriceString = (price: string): string => {
  return formatPrice(parseFloat(price));
};

export const formatPriceNumber = (price: number): string => {
  return formatPrice(price);
};
