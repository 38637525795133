import { FacetAccordionBlock } from "../facet-accordion";

import { Content, Wrapper } from "./styles";

import type { FacetLayoutProps } from "./interface";
import type { FC } from "react";

export const FacetLayout: FC<FacetLayoutProps> = ({ label, children, initiallyOpen = false }) => {
  return (
    <Wrapper>
      <FacetAccordionBlock defaultOpen={initiallyOpen} label={label}>
        <Content>{children}</Content>
      </FacetAccordionBlock>
    </Wrapper>
  );
};
