import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const BarbecueToolsLineDecorative: BykoReactDecorativeIconComponentType = ({ size, color = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40 30H32V42C32 44.2 33.8 46 36 46C38.2 46 40 44.2 40 42V30Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path d="M36 30V2" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
      <path d="M44 2H28V18H44V2Z" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
      <path
        d="M16 30H8V42C8 44.2 9.8 46 12 46C14.2 46 16 44.2 16 42V30Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path d="M12 30V18" stroke={color} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
      <path
        d="M18 2V12C18 15.3 15.3 18 12 18C8.7 18 6 15.3 6 12V2"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
    </svg>
  );
};
