import { useCallback } from "react";

import { useRecoilState } from "@byko/lib-recoil";

import { anonymousTokenStore } from "../store/anonymous-token";

interface AnonymousToken {
  set: (value: string) => void;
  remove: () => void;
  token: string | undefined;
}

/**
 * Helper for anonymous token
 * @returns
 */
export const useAnonymousToken = (): AnonymousToken => {
  const [token, set] = useRecoilState(anonymousTokenStore);
  const remove = useCallback(() => {
    set(null);
  }, [set]);
  return {
    set,
    remove,
    token: token ?? undefined,
  };
};
