/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const HeartFilledIcons: BykoReactIconComponentType = ({ size, iconColor = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.4545 1C15.1636 1 13.2 2.20476 12 3.95714C10.8 2.20476 8.83636 1 6.54545 1C2.94545 1 0 3.95714 0 7.57143C0 14.1429 12 24 12 24C12 24 24 14.1429 24 7.57143C24 3.95714 21.0545 1 17.4545 1Z"
        fill={iconColor}
      />
    </svg>
  );
};
