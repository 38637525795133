import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const DesignFilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M39.4719 43.839L40.6549 40.655L43.8389 39.472L44.9299 44.93L39.4719 43.839Z" fill="#0A3A5C" />
      <path
        d="M33.1619 42.577L35.6549 35.364L42.5769 33.162L43.8389 39.472L39.4719 43.839L33.1619 42.577Z"
        fill="#FFEC8E"
      />
      <path
        // eslint-disable-next-line max-len
        d="M13.0698 3.65496L18.3628 8.94796L14.6558 14.655L8.94884 18.362L3.65484 13.07C2.87384 12.289 2.87384 11.023 3.65484 10.242L10.2408 3.65596C11.0218 2.87396 12.2888 2.87396 13.0698 3.65496Z"
        fill="#0A3A5C"
      />
      <path d="M18.362 8.94912L12.655 14.6561L36.869 38.8701L42.576 33.1631L18.362 8.94912Z" fill="#00416A" />
      <path d="M13.6549 13.6556L8.94775 18.3628L33.1617 42.5767L37.8688 37.8696L13.6549 13.6556Z" fill="#00416A" />
      <path d="M17.6553 8.2404L8.24097 17.6547L9.65517 19.0689L19.0695 9.6546L17.6553 8.2404Z" fill="#E6E7E8" />
      <path
        // eslint-disable-next-line max-len
        d="M45.2129 12.686L35.3139 2.78698C34.5329 2.00598 33.2669 2.00598 32.4859 2.78698L2.78692 32.485C2.00592 33.266 2.00592 34.532 2.78692 35.313L12.6859 45.212C13.4669 45.993 14.7329 45.993 15.5139 45.212L45.2119 15.514C45.9939 14.734 45.9939 13.467 45.2129 12.686Z"
        fill="#FFD330"
      />
      <path d="M29.6566 5.61571L28.2424 7.02991L33.8992 12.6867L35.3134 11.2725L29.6566 5.61571Z" fill="#0A3A5C" />
      <path d="M23.9999 11.272L22.5857 12.6862L26.1212 16.2217L27.5354 14.8075L23.9999 11.272Z" fill="#0A3A5C" />
      <path d="M18.3422 16.929L16.928 18.3432L22.5848 24L23.999 22.5858L18.3422 16.929Z" fill="#0A3A5C" />
      <path d="M7.02919 28.2422L5.61499 29.6564L11.2718 35.3132L12.686 33.899L7.02919 28.2422Z" fill="#0A3A5C" />
      <path d="M12.6862 22.586L11.272 24.0002L14.8075 27.5357L16.2217 26.1215L12.6862 22.586Z" fill="#0A3A5C" />
    </svg>
  );
};
