import styled from "styled-components";

import { gridTheme } from "@byko/lib-styles";

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: ${gridTheme.maxContentWidth}px;
  margin-right: auto;
  margin-left: auto;
`;

export const TextContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1000px;
  flex-direction: column;
  gap: 50px;
  @media screen and (max-width: ${gridTheme.container.maxWidth.sm}px) {
    gap: 30px;
  }
`;

export const ProductSliderWrapper = styled.div`
  width: 100%;
  overflow-x: visible;
  @media screen and (max-width: ${gridTheme.container.maxWidth.sm}px) {
    width: 100%;
  }
  .swiper-slide {
    width: 325px !important;
  }
  .product-card {
    width: 100% !important;
  }

  @media screen and (max-width: 375px) {
    .swiper-slide {
      width: calc(100% - 48px) !important;
    }
  }
`;

export const TextBlockWrapper = styled.div`
  width: 100%;

  @media screen and (min-width: ${gridTheme.container.minWidth.md}px) and (max-width: ${gridTheme.container.maxWidth
      .md}px) {
    padding-right: 56px;
  }
  @media screen and (min-width: ${gridTheme.container.minWidth.lg}px) and (max-width: ${gridTheme.container.maxWidth
      .lg}px) {
    padding-right: 56px;
  }

  @media screen and (min-width: ${gridTheme.container.minWidth.xl}px) {
    padding-right: 56px;
  }
  @media screen and (max-width: ${gridTheme.container.maxWidth.sm}px) {
    width: 100%;
  }
`;
