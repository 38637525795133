import React from "react";

import { Button } from "@byko/component-buttons";
import { PMediumBold } from "@byko/component-typography";

import { AccordionItemContainer, AccordionItemWrapper, SecondLabelContainer } from "./styles";

import type { AccordionItemProps } from "./interface";

export const FacetAccordionItem = ({
  label,
  secondLabel,
  children,
  icon,
  onClickOpen,
  underline,
}: AccordionItemProps): JSX.Element => {
  return (
    <AccordionItemWrapper>
      <AccordionItemContainer underline={underline}>
        <Button
          buttonColor="whiteButton"
          customClassName="accordion-button"
          icon={icon}
          label={label}
          small={true}
          stretch={true}
          onClick={onClickOpen}
        />
        <SecondLabelContainer>
          <PMediumBold lineHeight="100%">{secondLabel}</PMediumBold>
        </SecondLabelContainer>
      </AccordionItemContainer>
      <>{children}</>
    </AccordionItemWrapper>
  );
};
