import styled from "styled-components";

import { theme } from "@byko/lib-styles";

export const NotifyInner = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

export const ProductImage = styled.img`
  width: 64px;
  height: 64px;
  object-fit: contain;
`;

export const NotifyContainer = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  flex-direction: column;
  padding: 8px 16px;
  border: 2px solid ${theme.palette.yellow.main};
  background-color: ${theme.palette.white.main};
  gap: 16px;
  transform: translateX(160%);
  transition: 600ms;
  transition-timing-function: ease-in-out;
  &.show {
    transform: translateX(0);
  }
`;
