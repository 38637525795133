import React, { useEffect, useState } from "react";

import { ClickAwayBackdrop } from "./styles";

import type { ToggleOpenProps } from "./interface";

export const ClickAway = ({ toggleOpen }: ToggleOpenProps): JSX.Element => {
  const [blur, setBlur] = useState<boolean>(false);

  useEffect(() => {
    setBlur(true);
  }, []);

  return <ClickAwayBackdrop blur={blur} onClick={toggleOpen} />;
};
