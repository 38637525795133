import { selector } from "@byko/lib-recoil";

import { isAuthLoadedState } from "./is-auth-loaded";
import { userState } from "./user";

export const isAuthenticatedState = selector<boolean>({
  key: "auth-is-authenticated",
  get: ({ get }) => {
    const user = get(userState);
    const isLoaded = get(isAuthLoadedState);
    return !!(isLoaded && user != null);
  },
});
