import * as React from "react";
import { useMemo } from "react";

import { useAssets, useLottie } from "./utils";

export const MainLoader = ({ size = 64 }: { size: number }): JSX.Element | null => {
  const LOADER_STYLE = useMemo(() => {
    return { width: size, height: size };
  }, [size]);
  const animationData = useAssets();
  const LottieComponent = useLottie();
  if (!animationData || !LottieComponent) {
    return null;
  }
  return <LottieComponent animationData={animationData} loop={true} play={true} style={LOADER_STYLE} />;
};
