import * as React from "react";

import { theme } from "@byko/lib-styles";

import { LabelText, SimpleRadioButtonLabel, SimpleRadioButtonWrapper, StyledSimpleRadioButton } from "./styles";

import type { InputProps } from "./interface";

export const SimpleRadioButton = ({
  id,
  name,
  disabled,
  hasLabel,
  backgroundColor,
  ...props
}: InputProps): JSX.Element => {
  return (
    <SimpleRadioButtonWrapper
      backgroundColor={disabled ? theme.palette.gray[20] : backgroundColor || ""}
      hasLabel={hasLabel}
    >
      <StyledSimpleRadioButton
        backgroundColor={disabled ? theme.palette.gray[20] : backgroundColor || ""}
        disabled={disabled}
        hasLabel={hasLabel}
        id={id || "radio-1"}
        name={name || "radio"}
        type="radio"
        value={id || "radio-1"}
      />
      <SimpleRadioButtonLabel htmlFor={id || "radio-1"}>
        <LabelText
          backgroundColor={disabled ? theme.palette.gray[20] : backgroundColor || ""}
          hasLabel={hasLabel}
          isSimple={true}
        >
          {props.label}
        </LabelText>
      </SimpleRadioButtonLabel>
    </SimpleRadioButtonWrapper>
  );
};
