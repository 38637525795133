import { useRouter } from "next/router";
import React, { useCallback } from "react";

import { SecondaryButton } from "@byko/component-buttons";
import { ArrowRightIcons } from "@byko/lib-icons";

import { Buttonflex, ClearAllProductsButton, ClearAllProductsButtonMobile } from "./styles";

import type { SectionButtonProps } from "./interface";

export const CompareSectionButtons = ({ onClickClearAll, prodLenght }: SectionButtonProps): JSX.Element | null => {
  const router = useRouter();
  const icelandic = prodLenght <= 1 ? "vara" : "vörur";

  const handleClick = useCallback(() => {
    router.push("/samanburdur");
  }, [router]);

  return (
    <Buttonflex>
      <SecondaryButton
        icon={ArrowRightIcons}
        label={`Skoða samanburð - ${prodLenght} ${icelandic} af 4`}
        onClick={handleClick}
      />
      <ClearAllProductsButton onClick={onClickClearAll}>Hreinsa allar vörur úr samanburði</ClearAllProductsButton>
      <ClearAllProductsButtonMobile onClick={onClickClearAll}>Hreinsa</ClearAllProductsButtonMobile>
    </Buttonflex>
  );
};
