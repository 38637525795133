import { gql } from "graphql-tag";
import { useQuery } from "react-query";
import { graphQLClient } from "../dato-graphql-client";
import type { TextOnPageRecord } from "../generated-gql/graphql";

// fetches text on page from dato
export function useGetTextOnPage() {
  const { data, isLoading, error } = useQuery(
    "get-text-on-page",
    async () => {
      const request = await graphQLClient.request<{
        textOnPage: TextOnPageRecord;
      }>(gql`
        query GetTextOnPage {
          textOnPage {
            hasPostPaymentDiscountInvoiceExplanation
            globalBannerText
            netgiroPaymentHeading
            netgiroPaymentHint
            netgiroPaymentDescriptionFirst
            netgiroPaymentDescriptionSecond
            mittBykoOnboardingTitleNotAuthenticated
            mittBykoOnboardingSubtitle
            mittBykoOnboardingDescription
          }
        }
      `);
      return request;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return { data: data?.textOnPage, isLoading, error };
}
