/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloError } from "@apollo/client";
import { useEffect } from "react";
import { useQuery } from "react-query";

import { restApi } from "@byko/lib-api-rest";
import { getCategoriesAndBreadcrumb } from "@byko/lib-category-helper";
import { atomFamily, useRecoilCallback, useRecoilState } from "@byko/lib-recoil";

import { selectorCartItem, selectorCartItemCount, state } from "../provider";

import { ProductFailImages } from "./const";
import { toGlobalId } from "./to-global-id";

import type { Attributes, PrimitiveValue, ProductDetailAugmented, ProductListAugmented } from "../interfaces";
import type { Line } from "../provider";
import type { Hit } from "@algolia/client-search";
import type { ProductList } from "@byko/lib-api-rest";

interface Data {
  loading: boolean;
  error: ApolloError | null;
  data: ProductListAugmented | null;
  notFound: boolean;
}

export const algoliaHitToProduct = (hitValue: Hit<ProductList> | null | undefined): ProductListAugmented | null => {
  // FIXME: This method should not exist. Frontend should use ProductList and not augment it
  if (!hitValue) {
    return null;
  }

  const image = hitValue.firstImage?.image?.productGallery2x
    ? {
        alt: hitValue.firstImage.alt ?? "",
        url: hitValue.firstImage?.image?.productGallery2x,
      }
    : (ProductFailImages[Math.floor(Math.random() * ProductFailImages.length)] as {
        url: string;
        alt: string;
      });

  return {
    ...hitValue,
    description: hitValue?.shortDescription?.is ?? "",
    media: [image],
    thumbnail: image,
  };
};

export const productBySlug = async (slug: string | null | undefined): Promise<ProductDetailAugmented | null> => {
  // FIXME: This method should not exist. Frontend should use ProductDetail and not augment it
  if (slug == null) {
    return null;
  }

  const { data, error } = await restApi.productsBySlugRetrieve(slug);
  // TODO:

  if (error) {
    throw new Error(error);
  }
  if (!data) {
    return null;
  }
  const breadcrumbs = getCategoriesAndBreadcrumb(data.categories, {
    title: data.name,
    slug: data.slug,
  });
  const image = data.firstImage?.image?.productGallery2x
    ? {
        alt: data.firstImage.alt ?? "",
        url: data.firstImage?.image?.productGallery2x,
      }
    : (ProductFailImages[Math.floor(Math.random() * ProductFailImages.length)] as {
        url: string;
        alt: string;
      });
  // TODO: Add video
  const media =
    data.media
      ?.filter((e) => "image" in e)
      .filter((e) => e.image?.productGallery2x)
      .map((e) => ({
        id: e.id,
        url: e.image?.productGallery2x ?? "",
        alt: e.alt ?? "",
      })) ?? [];
  const variants = data.variants.map((variant) => {
    Object.keys(variant.attributes).forEach((e) => {
      const item = variant.attributes[e];
      if (typeof item === "object" && "is-IS" in item && "en" in item) {
        variant.attributes[e] = item["is-IS"];
      }
    });
    return variant;
  });
  const attributes = variants.reduce((a, b) => {
    Object.keys(b.attributes).forEach((e) => {
      let item = b.attributes[e];
      const compareItem = a[e];
      if (typeof item === "object" && "is-IS" in item && "en" in item) {
        item = item["is-IS"];
      }
      if (!a[e]) {
        a[e] = item;
        return;
      }
      if (!compareItem) {
        // Only for typing.
        return;
      }

      if (!Array.isArray(compareItem)) {
        if (typeof item === "object" && e !== null) {
          const firstItemKey = Object.keys(item)[0];
          if (!firstItemKey) {
            return;
          }
          const firstItem = item[firstItemKey];
          const compareItemChild = compareItem[firstItemKey as keyof typeof compareItem] as PrimitiveValue;
          if (firstItem === compareItemChild) {
            // Atttribute already exists.
            return;
          }
          (a as any)[e] = [compareItem, item];
          return;
        }
        if (item === compareItem) {
          // Atttribute already exists.
          return;
        }
        (a as any)[e] = [compareItem, item];
        return;
      }
      if (typeof item === "object" && e !== null) {
        const firstItemKey = Object.keys(item)[0];
        const hasItem = !!(compareItem as any).find((compareItemItem: any) => {
          return (
            compareItemItem[firstItemKey as keyof typeof compareItemItem] ===
            item[firstItemKey as keyof typeof compareItem]
          );
        });
        if (hasItem) {
          return;
        }
        (a as any)[e] = [...compareItem, item];
      }
      if (compareItem.includes(item)) {
        return;
      }
      a[e] = [...compareItem, item];
    });
    return a as Attributes;
  }, {} as Attributes);
  return {
    ...data,
    breadcrumbs,
    description: data?.shortDescription?.is ?? "",
    media: media,
    thumbnail: image,
    attributes,
  };
};

export const useProductBySlug = (slug: string | null | undefined, enabled = true): Data => {
  const { data, error, isLoading } = useQuery(["productBySlug", slug], () => productBySlug(slug), {
    enabled: enabled && slug != null,
    refetchOnWindowFocus: false,
  });
  const [product, setProduct] = useRecoilState(productStore(slug ?? null));
  useEffect(() => {
    setProduct(product);
  }, [product, setProduct]);
  return {
    error: error ? new ApolloError({ errorMessage: "something went wrong" }) : null,
    loading: isLoading,
    data: typeof data === "object" ? data : product ?? null,
    notFound: data === null,
  };
};

export interface ProductCartVariantHelperProps {
  variantId?: string | number | undefined | null;
  slug?: string | number | undefined | null;
  options?:
    | {
        setOnLoad?: boolean;
      }
    | null
    | undefined;
}

export interface ProductCartVariantHelperFnProps {
  variantId: number;
  variantIdGQL: string;
  itemCount: number;
  item: Line | null;
}

type CartFn<T, X> = (props: T & ProductCartVariantHelperFnProps) => Promise<Awaited<X>>;

export const productStore = atomFamily<ProductDetailAugmented | null, string | null>({
  key: "productStore",
  default: null,
});

export const useProductCartVariantHelper = <
  X extends Record<string, any> = Record<string, never>,
  Fn extends CartFn<X, any> = CartFn<X, unknown>,
  T extends Awaited<ReturnType<Fn>> = Awaited<ReturnType<Fn>>,
>(
  fn: Fn,
  deps: any[],
): ((
  props: X extends Record<string, never> ? ProductCartVariantHelperProps : X & ProductCartVariantHelperProps,
) => Promise<T | undefined>) => {
  return useRecoilCallback(
    ({ snapshot, set }) =>
      async ({
        variantId: _variantId,
        slug,
        options,
        ...props
      }: X extends Record<string, never> ? ProductCartVariantHelperProps : X & ProductCartVariantHelperProps): Promise<
        T | undefined
      > => {
        const { setOnLoad } = {
          setOnLoad: true,
          ...(options ?? {}),
        };

        if (setOnLoad) {
          const currentState = await snapshot.getPromise(state);
          if (currentState !== "READY") {
            return;
          }
          set(state, "LOADING");
        }

        const [variantId, _product] = await (async (): Promise<[number | null, ProductDetailAugmented | null]> => {
          if (_variantId) {
            const x = typeof _variantId === "string" ? parseInt(_variantId, 10) : _variantId;
            if (!x || Number.isNaN(x)) {
              return [null, null];
            }
            return [x, null];
          }
          if (!slug) {
            return [null, null];
          }
          // Check if object is in cache
          const currentProductItem = await snapshot.getPromise(productStore(`${slug}`));
          if (currentProductItem) {
            if (currentProductItem.variants[0]?.id) {
              return [currentProductItem.variants[0]?.id as number, currentProductItem];
            }
            return [null, currentProductItem];
          }
          // Fetch data
          const product = await productBySlug(`${slug}`);
          return [product?.variants[0]?.id ?? null, product];
        })();

        if (!variantId) {
          return;
        }

        if (_product?.slug) {
          set(productStore(`${slug}`), _product);
        }

        const itemCount = await snapshot.getPromise(selectorCartItemCount(`${variantId}`));
        const item = await snapshot.getPromise(selectorCartItem(`${variantId}`));
        const gqlVariantId = toGlobalId("ProductVariant", variantId);
        const fnProps = {
          variantId: variantId,
          variantIdGQL: gqlVariantId,
          itemCount,
          item,
          ...props,
        };
        const value = await fn(fnProps as any);
        if (setOnLoad) {
          set(state, "READY");
        }
        return value;
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deps],
  );
};

export const useCartHelper = <T = void>(
  fn: () => Promise<Awaited<T> | undefined> | T | undefined,
  deps: any[],
): ((value?: Pick<ProductCartVariantHelperProps, "options"> | undefined) => Promise<Awaited<T> | undefined>) => {
  return useRecoilCallback(
    ({ snapshot, set }) =>
      async ({ options }: Pick<ProductCartVariantHelperProps, "options"> = {}): Promise<Awaited<T> | undefined> => {
        const { setOnLoad } = {
          setOnLoad: true,
          ...(options ?? {}),
        };
        if (setOnLoad) {
          const currentState = await snapshot.getPromise(state);
          if (currentState !== "READY") {
            return;
          }
          set(state, "LOADING");
        }

        const value = await fn();
        if (setOnLoad) {
          set(state, "READY");
        }
        return value;
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deps],
  );
};
