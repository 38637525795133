import styled from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  transform: translateX(-50%);
  .desktop-accordion-block {
    min-width: 320px;
    max-width: unset;
  }
  p {
    text-transform: none;
  }
  @media screen and (max-width: ${gridTheme.breakpoints.xs}px) {
    background-color: ${theme.palette.white.main};
  }
`;

export const ResultRows = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  gap: 80px;
`;

export const NothingFoundContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
