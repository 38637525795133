/* eslint-disable react-perf/jsx-no-new-array-as-prop */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-perf/jsx-no-new-function-as-prop */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import { groupBy, mapValues, range, sumBy, toPairs } from "lodash";
import React, { useCallback, useState } from "react";
import ReactSlider from "react-slider";
import { useProductSearch } from "src/features/search/context";
import { ensureList } from "@byko/lib-utils";
import styled from "styled-components";
import { useDebouncedCallback } from "use-debounce";

import { theme } from "@byko/lib-styles";

import { FacetLayout } from "./facet-layout";

import type { FC } from "react";
import type { RangeFacetProps, RangeFacetType } from "src/features/search/types";

const formatNumber = (num: number | string): string => {
  if (typeof num === "string") {
    num = parseFloat(num);
  }

  num = Math.floor(num);
  return num.toLocaleString("de-DE");
};

interface Props {
  facet: RangeFacetType;
  maxValue: number | undefined;
  minValue: number | undefined;
  onChange?: ((value: number | readonly number[], index: number) => void) | undefined;
}

const Wrapper = styled.div`
  position: relative;
  padding-top: 10px;
`;

const Slider = styled(ReactSlider)`
  width: 100%;
  height: 6px;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
  .track-1 {
    background-color: ${theme.palette.gray[80]};
    opacity: 0.1;
  }
`;

const SliderTrack = styled.div`
  height: 100%;
  border-radius: 0;
  background: ${theme.palette.blue.lightest};
  &.track-1 {
    height: 100%;
    border-color: 1px solid ${theme.palette.blue.main};
    background-color: ${theme.palette.blue.main};
    opacity: 1;
  }
`;

const SliderStick = styled.div`
  position: absolute;
  top: -3px;
  left: -6px;
  width: 12px;
  height: 12px;
  border: 0;
  border-radius: 10px;
  background-color: ${theme.palette.blue.main};
  cursor: grap;
`;

const SliderStickWrapper = styled.div`
  position: relative;
  height: 100%;
  &:focus {
    outline: none;
  }
`;

const RangeWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 4px;
`;

const RangeLabel = styled.span`
  color: ${theme.palette.blue.main};
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.2;
`;

const PriceData = styled.div`
  height: 50px;
`;

const PriceDataElement = styled.span`
  display: inline-block;
  justify-content: center;
`;

const PriceRange = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const PriceDelimiter = styled.span`
  font-weight: bold;
  margin-inline: 8px;
`;

const Price = styled.span`
  color: ${theme.palette.blue.main};
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 1;
  text-align: left;
`;

const RangeSlider = ({ facet, maxValue, minValue, onChange }: Props): JSX.Element => {
  const usedMin = facet.min || 0;
  const usedMax = facet.max || 260000;

  const steps = 100;
  const calcDist = (usedMax - usedMin) / steps;
  const priceArray: [string, string][] = toPairs(facet.data);
  const priceSteps: { step: number; count: number }[] = priceArray.map(([step, count]) => {
    const stepPoint = (parseInt(step, 10) - usedMin) * (steps / (usedMax - usedMin));
    return {
      step: Math.min(Math.floor(stepPoint), steps - 1),
      count: parseInt(count, 10),
    };
  });
  const groupedByStep = groupBy(priceSteps, "step");
  const itemsPerStep = mapValues(groupedByStep, (g) => sumBy(g, "count"));
  const maxCount = Math.max(...Object.values(itemsPerStep));
  const priceCountArray = range(steps).map((_, index) => itemsPerStep[index] || 0);

  return (
    <Wrapper>
      <PriceRange>
        <Price>
          {formatNumber(minValue ?? facet.min)} {facet.unit}
        </Price>
        <PriceDelimiter>-</PriceDelimiter>
        <Price>
          {formatNumber(maxValue ?? facet.max)} {facet.unit}
        </Price>
      </PriceRange>

      <PriceData>
        {priceCountArray.map((count, index) => (
          <PriceDataElement
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            style={{
              height: `${(count / maxCount) * 100}%`,
              width: `${100 / steps}%`,
              backgroundColor: theme.palette.blue.light,
            }}
          ></PriceDataElement>
        ))}
      </PriceData>

      <Slider
        max={usedMax}
        min={usedMin}
        minDistance={calcDist * 5}
        pearling={true}
        renderThumb={(props: any) => (
          <SliderStickWrapper {...props}>
            <SliderStick />
          </SliderStickWrapper>
        )}
        renderTrack={(props: any) => <SliderTrack {...props} />}
        step={calcDist}
        value={[minValue ?? facet.min, maxValue ?? facet.max]}
        onChange={onChange}
      />

      <RangeWrapper>
        <RangeLabel>
          {formatNumber(usedMin)} {facet.unit}
        </RangeLabel>
        <RangeLabel>
          {formatNumber(usedMax)} {facet.unit}
        </RangeLabel>
      </RangeWrapper>
    </Wrapper>
  );
};

export default RangeSlider;

export const RangeFacet: FC<RangeFacetProps> = ({ attr, facet }) => {
  const { updateRangeFacetValues } = useProductSearch();

  const [minValue, setMinValue] = useState<number | undefined>(facet.minValue);
  const [maxValue, setMaxValue] = useState<number | undefined>(facet.maxValue);

  const update = useDebouncedCallback(() => {
    updateRangeFacetValues(facet, attr, {
      min: minValue || null,
      max: maxValue || null,
    });
  }, 200);

  const handleChange = useCallback(
    (value: number | readonly number[]) => {
      const [from, to] = ensureList(value as number | number[]);
      setMinValue(from);
      setMaxValue(to);
      update();
    },
    [update],
  );

  if (facet.min === facet.max) {
    return null;
  }

  return (
    <FacetLayout label={facet.label}>
      <RangeSlider
        facet={facet}
        maxValue={maxValue ?? facet.maxValue}
        minValue={minValue ?? facet.minValue}
        onChange={handleChange}
      />
    </FacetLayout>
  );
};
