import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const WalletIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 12V5H3C1.895 5 1 4.105 1 3V20C1 21.657 2.343 23 4 23H21V16"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M17 2V1H3C1.895 1 1 1.895 1 3C1 4.105 1.895 5 3 5"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M23 16H18C16.895 16 16 15.105 16 14C16 12.895 16.895 12 18 12H23V16Z"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
};
