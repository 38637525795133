import React, { useCallback, useState } from "react";

import { ArrowDownIcons, ArrowUpIcons } from "@byko/lib-icons";

import { FacetAccordionItem } from "./facet-accordion-item";
import { AccordionChildren, AccordionWrapper } from "./styles";

import type { AccordionBlockProps } from "./interface";

export const FacetAccordionBlock = ({
  label,
  children,
  defaultOpen = false,
  openIcon = ArrowDownIcons,
  closeIcon = ArrowUpIcons,
  secondLabel,
  onClose,
}: AccordionBlockProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);

  const handleOpen = useCallback(() => {
    if (isOpen && onClose) onClose();
    setIsOpen(!isOpen);
  }, [isOpen, onClose]);

  return (
    <AccordionWrapper className="accordion-block" defaultOpen={defaultOpen} isOpen={isOpen}>
      <FacetAccordionItem
        icon={isOpen ? closeIcon : openIcon}
        label={label}
        secondLabel={secondLabel ?? ""}
        underline={isOpen ? true : false}
        onClickOpen={handleOpen}
      >
        {isOpen && <AccordionChildren>{children}</AccordionChildren>}
      </FacetAccordionItem>
    </AccordionWrapper>
  );
};
