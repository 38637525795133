import * as React from "react";

import { H6 } from "@byko/component-typography";

import { PickerRecentlyVisited, PickerSearch } from "./components";
import { BottomLine, Container, Line } from "./styles";

export const AccountPicker = (): JSX.Element => {
  return (
    <Container>
      <H6>Veldu aðgang</H6>
      <PickerSearch />
      <Line />
      <PickerRecentlyVisited />
      <BottomLine />
    </Container>
  );
};
