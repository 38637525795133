import { atom, useRecoilValue } from "@byko/lib-recoil";

import type { User } from "@byko/lib-api-rest";

export const userState = atom<User | null>({
  key: "user",
  default: null,
});

export const useUser = (): User | null => useRecoilValue(userState);
