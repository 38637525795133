import * as React from "react";
import { useCallback } from "react";

import { useModal } from "@byko/hooks-modal";

import { Modal } from "./modal";

export const ModalHandler = (): JSX.Element | null => {
  const { hide, modalProps } = useModal();
  const cancel = useCallback(() => {
    hide();
  }, [hide]);

  if (modalProps === undefined) {
    return null;
  }

  return <Modal {...modalProps} cancelCallback={modalProps.cancelCallback ?? cancel} />;
};
