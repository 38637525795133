import styled from "styled-components";

import { gridTheme } from "@byko/lib-styles";

export const PageContent = styled.div`
  position: relative;
  min-height: calc(100vh - 522px);
  @media screen and (max-width: 1078px) {
    margin-top: 24px;
  }
`;

export const PageContainer = styled.div<{
  offsetNav: boolean;
  flexDirection: "row" | "column";
  justifyContent?:
    | "end"
    | "left"
    | "right"
    | "start"
    | "center"
    | "flex-end"
    | "flex-start"
    | "space-around"
    | "space-evenly"
    | "space-between";
  backgroundColor?: string;
  noGutter?: boolean;
  noGutterLeft?: boolean;
  noGutterRight?: boolean;
}>`
  display: flex;
  width: 100%;
  min-width: 0;
  flex-direction: ${(props): string => (props.flexDirection === "column" ? "column" : "row")};
  justify-content: ${(props): string => props?.justifyContent || "space-between"};
  background-color: ${(props): string => (props.backgroundColor ? props.backgroundColor : "transparent")};

  @media screen and (min-width: ${gridTheme.container.minWidth.xs}px) and (max-width: ${gridTheme.container.maxWidth
      .xs}px) {
    padding-top: ${(props): string => (props.offsetNav ? `${gridTheme.container.navHeight.xs}px` : "initial")};
    padding-right: ${(props): string => (props.noGutter || props.noGutterRight ? "0" : "24px")};
    padding-left: ${(props): string => (props.noGutter || props.noGutterLeft ? "0" : "24px")};
  }

  @media screen and (min-width: ${gridTheme.container.minWidth.s}px) and (max-width: ${gridTheme.container.maxWidth
      .s}px) {
    padding-top: ${(props): string => (props.offsetNav ? `${gridTheme.container.navHeight.xs}px` : "initial")};
    padding-right: ${(props): string => (props.noGutter || props.noGutterRight ? "0" : "24px")};
    padding-left: ${(props): string => (props.noGutter || props.noGutterLeft ? "0" : "24px")};
  }

  @media screen and (min-width: ${gridTheme.container.minWidth.sm}px) and (max-width: ${gridTheme.container.maxWidth
      .sm}px) {
    padding-top: ${(props): string => (props.offsetNav ? `${gridTheme.container.navHeight.sm}px` : "initial")};
    padding-right: ${(props): string => (props.noGutter || props.noGutterRight ? "0" : "48px")};
    padding-left: ${(props): string => (props.noGutter || props.noGutterLeft ? "0" : "48px")};
  }

  @media screen and (min-width: ${gridTheme.container.minWidth.m}px) and (max-width: ${gridTheme.container.maxWidth
      .m}px) {
    padding-top: ${(props): string => (props.offsetNav ? `${gridTheme.container.navHeight.md}px` : "initial")};
    padding-right: ${(props): string => (props.noGutter || props.noGutterRight ? "0" : "48px")};
    padding-left: ${(props): string => (props.noGutter || props.noGutterLeft ? "0" : "48px")};
  }

  @media screen and (min-width: ${gridTheme.container.minWidth.mm}px) and (max-width: ${gridTheme.container.maxWidth
      .mm}px) {
    padding-top: ${(props): string => (props.offsetNav ? `${gridTheme.container.navHeight.md}px` : "initial")};
    padding-right: ${(props): string => (props.noGutter || props.noGutterRight ? "0" : "48px")};
    padding-left: ${(props): string => (props.noGutter || props.noGutterLeft ? "0" : "48px")};
  }

  @media screen and (min-width: ${gridTheme.container.minWidth.md}px) and (max-width: ${gridTheme.container.maxWidth
      .md}px) {
    padding-top: ${(props): string => (props.offsetNav ? `${gridTheme.container.navHeight.md}px` : "initial")};
    padding-right: ${(props): string => (props.noGutter || props.noGutterRight ? "0" : "56px")};
    padding-left: ${(props): string => (props.noGutter || props.noGutterLeft ? "0" : "56px")};
  }

  @media screen and (min-width: ${gridTheme.container.minWidth.lg}px) and (max-width: ${gridTheme.container.maxWidth
      .lg}px) {
    padding-top: ${(props): string => (props.offsetNav ? `${gridTheme.container.navHeight.lg}px` : "initial")};
    padding-right: ${(props): string => (props.noGutter || props.noGutterRight ? "0" : "calc(56px + 5vw)")};
    padding-left: ${(props): string => (props.noGutter || props.noGutterLeft ? "0" : "calc(56px + 5vw)")};
  }

  @media screen and (min-width: ${gridTheme.container.minWidth.xl}px) {
    padding-top: ${(props): string => (props.offsetNav ? `${gridTheme.container.navHeight.xl}px` : "initial")};
    padding-right: ${(props): string => (props.noGutter || props.noGutterRight ? "0" : "calc(56px + 5vw)")};
    padding-left: ${(props): string => (props.noGutter || props.noGutterLeft ? "0" : "calc(56px + 5vw)")};
  }
`;
