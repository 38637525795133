import * as React from "react";

import { theme } from "@byko/lib-styles";

import type { BykoReactIconComponentType } from "../../icon-types";

export const SearchIcons: BykoReactIconComponentType = ({ size, iconColor = theme.palette.blue.main }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 23 23" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M21 21L14.656 14.656" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path
        d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
};
