/* eslint-disable max-len */
import * as React from "react";
import { BykoReactIconComponentType } from "@byko/lib-core";

export const KaupaIllustration: BykoReactIconComponentType = ({ size = 200 }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 663 611" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M532.9 443.31C532.9 443.31 496.09 398.15 423.44 408.31C350.79 418.47 311.81 373.48 281.49 318.39C251.17 263.3 154.13 362.5 154.13 362.5C154.13 362.5 168.46 413.36 112.44 453.04C56.4201 492.72 82.7701 528.61 82.7701 528.61H473.9L532.9 443.31Z"
        fill="#D9EEE8"
      />
      <path
        d="M154.13 362.5C154.13 362.5 147.6 331.5 117.71 343.34C87.8201 355.18 55.5301 317.77 79.2901 290.51C103.05 263.25 134.4 275.2 149.5 281.77C164.6 288.34 180.01 262.51 152.61 227.52C125.21 192.53 177.81 135.71 229.28 197.16C280.75 258.61 281.44 318.35 281.44 318.35L154.13 362.5Z"
        fill="#D9EEE8"
      />
      <path
        d="M349.37 550.14C313.15 526.59 283.04 490.42 262.31 445.55C245.7 409.6 235.64 369 234.71 334.18C233.23 278.43 208.21 242.18 187.47 221.6C165.01 199.29 142.67 190.06 142.47 189.97L143.36 187.79C143.59 187.88 166.36 197.29 189.16 219.93C210.16 240.82 235.59 277.59 237.1 334.12C238.02 368.63 248 408.89 264.48 444.56C285.02 489.03 314.83 524.86 350.68 548.16L349.37 550.14Z"
        fill="white"
      />
      <path
        d="M135.17 538.71L133.65 536.91C224.85 459.73 213.08 382.36 194.12 352.24C172.46 317.84 137.06 310.31 111.18 310.04C93.6174 309.968 76.1485 312.605 59.3901 317.86L58.6201 315.63C75.6183 310.33 93.325 307.653 111.13 307.69C137.63 307.95 173.87 315.69 196.13 350.99C215.45 381.71 227.61 460.48 135.17 538.71Z"
        fill="white"
      />
      <path d="M105.998 264.576L103.769 265.354L118.459 307.465L120.687 306.688L105.998 264.576Z" fill="white" />
      <path
        d="M190.08 220.34L187.83 219.65C196.46 191.49 187.56 164.82 187.46 164.55L189.69 163.79C189.79 164.07 198.9 191.46 190.08 220.34Z"
        fill="white"
      />
      <path d="M134.497 395.218L133.071 397.086L200.266 448.387L201.692 446.519L134.497 395.218Z" fill="white" />
      <path d="M175.565 428.134L142.188 452.082L143.564 454L176.941 430.051L175.565 428.134Z" fill="white" />
      <path
        d="M408.77 545.7L407.16 544C467.16 488.32 479.5 401.5 479.62 400.63L481.95 400.95C481.83 401.8 469.39 489.43 408.77 545.7Z"
        fill="white"
      />
      <path d="M430.643 395.516L428.588 396.678L463.69 458.796L465.745 457.635L430.643 395.516Z" fill="white" />
      <path
        d="M537.44 299.68C574.206 299.68 604.01 269.876 604.01 233.11C604.01 196.344 574.206 166.54 537.44 166.54C500.675 166.54 470.87 196.344 470.87 233.11C470.87 269.876 500.675 299.68 537.44 299.68Z"
        fill="#D9EEE8"
      />
      <path d="M548.96 223.95L541.57 225.83L540.79 218.24L554.32 198.88L562.49 204.58L548.96 223.95Z" fill="#408DBC" />
      <path
        d="M568.06 209.21C569.64 206.555 570.158 203.4 569.508 200.38C568.859 197.359 567.092 194.695 564.561 192.923C562.03 191.151 558.922 190.402 555.861 190.827C552.801 191.251 550.014 192.817 548.06 195.21L568.06 209.21Z"
        fill="#408DBC"
      />
      <path d="M563.371 192.486L560.291 196.896L562.218 198.242L565.298 193.831L563.371 192.486Z" fill="#003C71" />
      <path d="M550.225 199.839L548.879 201.766L561.527 210.603L562.873 208.676L550.225 199.839Z" fill="#408DBC" />
      <path d="M547.559 203.53L546.214 205.457L558.864 214.292L560.209 212.365L547.559 203.53Z" fill="#408DBC" />
      <path d="M544.982 207.34L543.636 209.266L556.285 218.103L557.63 216.177L544.982 207.34Z" fill="#408DBC" />
      <path d="M542.328 211.031L540.982 212.957L553.632 221.792L554.978 219.866L542.328 211.031Z" fill="#408DBC" />
      <path d="M559.51 266.37L552.08 268.07L551.48 260.47L567.87 238.61L575.9 244.51L559.51 266.37Z" fill="#408DBC" />
      <path
        d="M581.36 249.28C583.001 246.662 583.592 243.521 583.013 240.485C582.434 237.45 580.729 234.747 578.239 232.917C575.75 231.086 572.661 230.266 569.591 230.619C566.522 230.972 563.699 232.473 561.69 234.82L581.36 249.28Z"
        fill="#408DBC"
      />
      <path d="M576.885 229.638L573.711 233.996L575.614 235.383L578.789 231.025L576.885 229.638Z" fill="#003C71" />
      <path d="M564.949 238.321L562.836 239.373L570.817 255.407L572.929 254.355L564.949 238.321Z" fill="#D9EEE8" />
      <path d="M561.293 242.216L559.18 243.267L567.16 259.301L569.273 258.25L561.293 242.216Z" fill="#D9EEE8" />
      <path d="M557.651 246.127L555.538 247.179L563.518 263.212L565.631 262.161L557.651 246.127Z" fill="#D9EEE8" />
      <path d="M554.004 250.112L551.891 251.163L559.871 267.197L561.984 266.146L554.004 250.112Z" fill="#D9EEE8" />
      <path d="M500.83 242.36H492.54L488.39 249.55L492.54 256.73H500.83L504.98 249.55L500.83 242.36Z" fill="#408DBC" />
      <path
        d="M496.69 253.43C498.833 253.43 500.57 251.693 500.57 249.55C500.57 247.407 498.833 245.67 496.69 245.67C494.547 245.67 492.81 247.407 492.81 249.55C492.81 251.693 494.547 253.43 496.69 253.43Z"
        fill="#D9EEE8"
      />
      <path d="M534.33 249.31H524.23L519.19 258.05L524.23 266.79H534.33L539.37 258.05L534.33 249.31Z" fill="#408DBC" />
      <path
        d="M529.28 262.77C531.887 262.77 534 260.657 534 258.05C534 255.443 531.887 253.33 529.28 253.33C526.673 253.33 524.56 255.443 524.56 258.05C524.56 260.657 526.673 262.77 529.28 262.77Z"
        fill="#D9EEE8"
      />
      <path d="M524.95 220.51H513.64L507.99 230.3L513.64 240.09H524.95L530.6 230.3L524.95 220.51Z" fill="#408DBC" />
      <path
        d="M519.3 235.58C522.216 235.58 524.58 233.216 524.58 230.3C524.58 227.384 522.216 225.02 519.3 225.02C516.384 225.02 514.02 227.384 514.02 230.3C514.02 233.216 516.384 235.58 519.3 235.58Z"
        fill="#D9EEE8"
      />
      <path
        d="M441.04 76.2H368.97C364.342 76.2 360.59 79.9519 360.59 84.58V87.55C360.59 92.1782 364.342 95.93 368.97 95.93H441.04C445.668 95.93 449.42 92.1782 449.42 87.55V84.58C449.42 79.9519 445.668 76.2 441.04 76.2Z"
        fill="#FF9D57"
      />
      <path
        d="M461.78 90.24C460.431 90.242 459.111 89.8437 457.988 89.0955C456.866 88.3473 455.99 87.2829 455.472 86.0369C454.954 84.7908 454.818 83.4193 455.08 82.0957C455.342 80.772 455.991 79.5559 456.944 78.6011C457.898 77.6463 459.113 76.9958 460.436 76.7318C461.759 76.4679 463.131 76.6023 464.378 77.1182C465.625 77.6341 466.69 78.5082 467.44 79.63C468.19 80.7518 468.59 82.0707 468.59 83.42C468.588 85.2263 467.869 86.9579 466.593 88.2361C465.317 89.5142 463.586 90.2347 461.78 90.24ZM461.78 78.96C460.898 78.96 460.036 79.2216 459.302 79.7117C458.569 80.2017 457.997 80.8983 457.66 81.7133C457.322 82.5282 457.234 83.425 457.406 84.2901C457.578 85.1553 458.003 85.95 458.626 86.5737C459.25 87.1975 460.045 87.6222 460.91 87.7943C461.775 87.9664 462.672 87.8781 463.487 87.5405C464.302 87.203 464.998 86.6313 465.488 85.8979C465.979 85.1644 466.24 84.3021 466.24 83.42C466.23 82.2441 465.755 81.1199 464.92 80.2922C464.084 79.4644 462.956 79 461.78 79V78.96Z"
        fill="#003C71"
      />
      <path d="M466.782 86.1179L465.315 87.9667L473.18 94.2071L474.647 92.3584L466.782 86.1179Z" fill="#003C71" />
      <path
        d="M376.01 88.82C377.65 88.82 378.98 87.4903 378.98 85.85C378.98 84.2097 377.65 82.88 376.01 82.88C374.37 82.88 373.04 84.2097 373.04 85.85C373.04 87.4903 374.37 88.82 376.01 88.82Z"
        fill="#FFD400"
      />
      <path
        d="M386.32 88.82C387.96 88.82 389.29 87.4903 389.29 85.85C389.29 84.2097 387.96 82.88 386.32 82.88C384.68 82.88 383.35 84.2097 383.35 85.85C383.35 87.4903 384.68 88.82 386.32 88.82Z"
        fill="#FFD400"
      />
      <path
        d="M396.64 88.82C398.28 88.82 399.61 87.4903 399.61 85.85C399.61 84.2097 398.28 82.88 396.64 82.88C395 82.88 393.67 84.2097 393.67 85.85C393.67 87.4903 395 88.82 396.64 88.82Z"
        fill="#FFD400"
      />
      <path
        d="M282.96 208.82C294.09 206.16 309.35 214.15 311.77 215.82C314.19 217.49 311.77 220.91 308.38 221.39C312.75 225.27 313.97 230.6 312.75 233.5C311.53 236.4 305.75 236.41 302.33 234.95C302.33 234.95 300.63 244.16 304.51 249.24C308.39 254.32 322.18 256.75 322.18 256.75C322.18 256.75 316.61 267.4 299.18 267.4C281.75 267.4 277.9 256.28 277.9 256.28L294.34 235L282.96 208.82Z"
        fill="#FDC498"
      />
      <path
        d="M401.9 200.6C401.9 200.6 397.62 210.23 400.14 218.36C402.66 226.49 407.96 218.36 407.96 218.36L401.9 200.6Z"
        fill="#FDC498"
      />
      <path
        d="M492.5 127.87H414.98C408.148 127.87 402.61 133.408 402.61 140.24V204.81C402.61 211.642 408.148 217.18 414.98 217.18H492.5C499.332 217.18 504.87 211.642 504.87 204.81V140.24C504.87 133.408 499.332 127.87 492.5 127.87Z"
        fill="#408DBC"
      />
      <path
        d="M274.65 299.72L282.17 252.06C282.605 249.322 284.002 246.828 286.109 245.027C288.217 243.225 290.898 242.234 293.67 242.23H358.99L400.15 218.36L411.5 202.48C411.5 202.48 420.58 208.69 415.95 216.54C408.47 229.19 383.57 256.61 358.23 261.54C332.89 266.47 313.6 278.18 309.44 299.74L274.65 299.72Z"
        fill="#FDC498"
      />
      <path d="M434 151.91H412.78V154.27H434V151.91Z" fill="white" />
      <path
        d="M436.65 146.46H442.74V159.06H436.65C435.231 159.06 433.87 158.496 432.867 157.493C431.864 156.49 431.3 155.129 431.3 153.71V151.81C431.3 150.391 431.864 149.03 432.867 148.027C433.87 147.024 435.231 146.46 436.65 146.46Z"
        fill="#A8CBE1"
      />
      <path
        d="M470.65 189.88H479.05V194C479.05 194.947 478.674 195.855 478.005 196.524C477.335 197.194 476.427 197.57 475.48 197.57H474.21C473.263 197.57 472.355 197.194 471.686 196.524C471.016 195.855 470.64 194.947 470.64 194V189.91L470.65 189.88Z"
        fill="#003C71"
      />
      <path
        d="M461.97 175.82H460.18C458.854 175.82 457.582 175.293 456.645 174.356C455.707 173.418 455.18 172.146 455.18 170.82V159.06H457.53V170.8C457.533 171.507 457.815 172.185 458.315 172.685C458.815 173.185 459.493 173.467 460.2 173.47H461.99L461.97 175.82Z"
        fill="#003C71"
      />
      <path
        d="M487.94 142.81H443.95C441.907 142.81 440.25 144.467 440.25 146.51V159.67C440.25 161.713 441.907 163.37 443.95 163.37H487.94C489.984 163.37 491.64 161.713 491.64 159.67V146.51C491.64 144.467 489.984 142.81 487.94 142.81Z"
        fill="#FFD400"
      />
      <path
        d="M465.12 159.06H484.19V188.69C484.19 190.066 483.643 191.387 482.67 192.36C481.697 193.333 480.377 193.88 479 193.88H470.31C468.934 193.88 467.614 193.333 466.64 192.36C465.667 191.387 465.12 190.066 465.12 188.69V159.06Z"
        fill="#FFD400"
      />
      <path d="M457.52 147.51H455.16V153.09H457.52V147.51Z" fill="#003C71" />
      <path d="M463.15 147.51H460.79V153.09H463.15V147.51Z" fill="#003C71" />
      <path d="M468.79 147.51H466.43V153.09H468.79V147.51Z" fill="#003C71" />
      <path
        d="M441.43 152.76H439.07V147.44C439.073 145.9 439.686 144.424 440.775 143.335C441.864 142.246 443.34 141.633 444.88 141.63H456.33V144H444.9C443.986 144.003 443.11 144.367 442.464 145.013C441.817 145.66 441.453 146.536 441.45 147.45L441.43 152.76Z"
        fill="#003C71"
      />
      <path
        d="M464.77 206.45C462.05 206.382 459.382 205.695 456.967 204.442C454.551 203.19 452.453 201.404 450.83 199.22C446.07 193.22 440.03 190.49 434.28 191.82C428.53 193.15 424.07 198.34 422.48 205.65L420.18 205.15C421.97 196.92 427.04 191.08 433.75 189.53C440.46 187.98 447.33 190.98 452.68 197.76C457.31 203.62 463.68 204.94 467.95 203.65C469.54 203.214 470.952 202.287 471.985 201.001C473.017 199.716 473.618 198.137 473.7 196.49L476.05 196.68C475.926 198.798 475.144 200.824 473.814 202.476C472.484 204.129 470.672 205.326 468.63 205.9C467.378 206.274 466.077 206.459 464.77 206.45Z"
        fill="#003C71"
      />
      <path
        d="M289.72 243.46C295.96 242.3 314.81 239.69 358.99 242.23L376.91 254.23C376.91 254.23 369.71 261.52 346.91 265.62C324.11 269.72 309.6 290.54 309.4 299.76H274.65L279.5 262.47L289.72 243.46Z"
        fill="#40A88D"
      />
      <path
        d="M235.64 487.58L268 499.92L266.13 504.41C264.57 508.17 266.13 514.08 269 516.82L269.46 517.26C271.48 519.164 273.94 520.538 276.62 521.26C285.07 523.62 285.14 528.65 285.14 528.65H265.61L226.36 500.6L235.64 487.58Z"
        fill="#003C71"
      />
      <path
        d="M406.42 528.61C406.42 528.61 399.12 520.98 385 516.82C370.88 512.66 342.51 512.66 342.51 512.66V528.66L406.42 528.61Z"
        fill="#003C71"
      />
      <path
        d="M309.44 299.72L385 516.82H322.18C322.18 516.82 335.56 466.41 301.12 408.27C303.73 436.79 293.56 473.7 272.38 506.98L227.75 491.1C227.75 491.1 222.28 473.71 246.66 457.85C269.96 442.69 266.98 421.23 264.06 408.27C250.74 349.16 262.55 327.71 274.65 299.72H309.44Z"
        fill="#FFD400"
      />
      <path d="M272.34 255.96L266.5 318.92L280.3 319.3L287.57 243.73L272.34 255.96Z" fill="#FFD400" />
      <path d="M277.87 267.42H310.71L306.11 307.06H274.65L277.87 267.42Z" fill="#FFD400" />
      <path
        d="M294.34 235C294.34 235 274.23 230.32 276.2 214.81C277.42 205.22 293.67 208.88 293.67 208.88V217.32C288.29 215.15 285.97 222.62 293.67 225.55L294.34 235Z"
        fill="#003C71"
      />
      <path
        d="M360.32 518H320.77L321.03 516.6C328.1 478.54 320.68 440.3 300.14 408.92C271.07 368.57 274.57 342.25 274.73 341.15L277.06 341.49C277.06 341.76 273.6 368.06 302.06 407.58C322.71 439.14 330.32 477.44 323.57 515.64H360.3L360.32 518Z"
        fill="#003C71"
      />
      <path d="M454.84 526.2H180.2V528.56H454.84V526.2Z" fill="#003C71" />
      <path
        d="M300.09 218.44C300.935 218.44 301.62 217.755 301.62 216.91C301.62 216.065 300.935 215.38 300.09 215.38C299.245 215.38 298.56 216.065 298.56 216.91C298.56 217.755 299.245 218.44 300.09 218.44Z"
        fill="#003C71"
      />
      <path
        d="M293.64 229.9C293.64 229.9 282.73 224.69 275.94 230.25C269.15 235.81 279.38 248.93 260.29 254.6C241.2 260.27 240.73 284.24 240.73 284.24L284.58 283.15C284.58 283.15 276.67 275.01 288.18 268.28C299.69 261.55 297.56 248.37 293.82 244.34C290.08 240.31 294.3 235.55 294.3 235.55L293.64 229.9Z"
        fill="#003C71"
      />
      <path
        d="M253.03 262.47L252.17 260.27C261.71 256.57 262.04 251.12 262.41 244.82C262.72 239.68 263.07 233.82 269.55 229.67C282.49 221.33 290.61 232.15 290.69 232.26L288.79 233.65C288.51 233.28 281.87 224.52 270.79 231.65C265.31 235.18 265.02 239.93 264.72 244.96C264.39 251.18 263.97 258.22 253.03 262.47Z"
        fill="#003C71"
      />
      <path
        d="M290.52 281.8C290.33 281.8 285.65 280.69 283.86 276.47C282.42 273.08 283.3 268.78 286.46 263.67L288.46 264.91C285.73 269.32 284.91 272.91 286.02 275.53C287.32 278.63 291.02 279.53 291.02 279.53L290.52 281.8Z"
        fill="#003C71"
      />
      <path
        d="M276.4 233.84L302.64 200.6C301.438 199.873 300.009 199.622 298.631 199.893C297.253 200.165 296.026 200.941 295.19 202.07L275.83 226.35L276.4 233.84Z"
        fill="#FFD400"
      />
      <path
        d="M276.4 233.84C276.4 233.84 262.46 221.84 270.46 208.17C277.87 195.46 293.23 200.71 297.32 204.17L280.38 229.25L276.4 233.84Z"
        fill="#FFD400"
      />
      <path
        d="M322.18 241.16C322.18 241.16 315.23 295.32 314.78 349.47C285.24 339.97 301.62 311.47 301.62 311.47C302.22 288.022 304.702 264.661 309.04 241.61L322.18 241.16Z"
        fill="#FFD400"
      />
      <path d="M319.91 330.01H258.43L261.17 318.92H319.91V330.01Z" fill="#40A88D" />
      <path d="M260.94 324.75L258.65 324.18L260.25 317.74H319.91V320.1H262.09L260.94 324.75Z" fill="#003C71" />
      <path d="M319.92 328.83H258.43V331.19H319.92V328.83Z" fill="#003C71" />
      <path d="M292.7 360.43H295.68V340.68H292.7V360.43Z" fill="white" />
      <path d="M291.48 368.06H296.9V357.95H291.48V368.06Z" fill="white" />
      <path
        d="M307.58 347.36V353.88H306.35L304.42 351.7H302.32C301.949 351.7 301.593 351.848 301.33 352.11C301.068 352.373 300.92 352.729 300.92 353.1V358.27C300.92 358.641 301.068 358.997 301.33 359.26C301.593 359.523 301.949 359.67 302.32 359.67H304.25L306.18 357.8H307.58V359.67C307.58 359.67 318.21 360.83 320.47 353.35C320.513 353.204 320.508 353.049 320.458 352.905C320.408 352.762 320.314 352.638 320.19 352.55C320.059 352.444 319.894 352.386 319.725 352.386C319.556 352.386 319.392 352.444 319.26 352.55C318.07 353.6 314.7 356.23 312.91 353.9C311.82 352.48 312 347.33 312 347.33L307.58 347.36Z"
        fill="white"
      />
      <path d="M312.03 339.45H307.58V348.62H312.03V339.45Z" fill="white" />
      <path
        d="M314.51 340.69H305.09V310.69C305.09 309.629 305.512 308.612 306.262 307.862C307.012 307.111 308.029 306.69 309.09 306.69H310.51C311.571 306.69 312.588 307.111 313.339 307.862C314.089 308.612 314.51 309.629 314.51 310.69V340.69Z"
        fill="#003C71"
      />
      <path
        d="M298.9 340.69H289.48V310.69C289.48 309.629 289.902 308.612 290.652 307.862C291.402 307.111 292.419 306.69 293.48 306.69H294.9C295.961 306.69 296.978 307.111 297.729 307.862C298.479 308.612 298.9 309.629 298.9 310.69V340.69Z"
        fill="#003C71"
      />
      <path d="M295.37 364.1H293.01V368.06H295.37V364.1Z" fill="#003C71" />
      <path d="M228.154 489.973L227.362 492.197L271.99 508.079L272.782 505.856L228.154 489.973Z" fill="#003C71" />
      <path
        d="M383.27 228.15L390.73 244.53C390.73 244.53 378.09 260.09 342.37 266.65C337.83 255.14 358.99 242.23 358.99 242.23L383.27 228.15Z"
        fill="#40A88D"
      />
      <path d="M295.97 201.92L290.5 198.52L287.01 204.24L292.24 205.98L295.97 201.92Z" fill="#FFD400" />
      <path
        d="M273.9 226.75C273.13 219.75 274.46 214 277.85 209.75C280.826 206.181 285.006 203.826 289.6 203.13L289.85 205.48L289.73 204.31L289.85 205.48C289.21 205.55 274.16 207.48 276.24 226.48L273.9 226.75Z"
        fill="#FF7D20"
      />
      <path
        d="M490.3 218.36H428.9V216H490.3C493.852 215.997 497.257 214.586 499.769 212.076C502.281 209.566 503.695 206.161 503.7 202.61V142.44C503.695 138.889 502.281 135.484 499.769 132.974C497.257 130.464 493.852 129.053 490.3 129.05H417.18C413.631 129.055 410.228 130.468 407.718 132.978C405.208 135.488 403.795 138.89 403.79 142.44V178.71H401.44V142.44C401.445 138.267 403.105 134.267 406.056 131.316C409.007 128.365 413.007 126.705 417.18 126.7H490.3C494.475 126.703 498.478 128.362 501.431 131.312C504.383 134.263 506.045 138.265 506.05 142.44V202.61C506.045 206.786 504.384 210.789 501.431 213.741C498.479 216.694 494.476 218.355 490.3 218.36Z"
        fill="#003C71"
      />
      <path
        d="M527.98 279.13H413.51C407.523 279.13 402.67 283.983 402.67 289.97V349.64C402.67 355.627 407.523 360.48 413.51 360.48H527.98C533.967 360.48 538.82 355.627 538.82 349.64V289.97C538.82 283.983 533.967 279.13 527.98 279.13Z"
        fill="#F09D61"
      />
      <path
        d="M527.97 279.13H514.64L452.59 360.48H527.97C530.845 360.48 533.602 359.338 535.635 357.305C537.668 355.272 538.81 352.515 538.81 349.64V290C538.814 288.574 538.537 287.161 537.994 285.843C537.451 284.524 536.653 283.325 535.646 282.316C534.639 281.306 533.443 280.505 532.125 279.958C530.808 279.411 529.396 279.13 527.97 279.13Z"
        fill="#EB7C2D"
      />
      <path
        d="M516.19 312.09C522.425 312.09 527.48 307.035 527.48 300.8C527.48 294.565 522.425 289.51 516.19 289.51C509.955 289.51 504.9 294.565 504.9 300.8C504.9 307.035 509.955 312.09 516.19 312.09Z"
        fill="#F6D330"
      />
      <path d="M444.21 341.62H415.03V349.11H444.21V341.62Z" fill="#00416A" />
      <path d="M425.89 331.03H415.03V338.52H425.89V331.03Z" fill="#00416A" />
      <path d="M451.61 331.03H433.34V338.52H451.61V331.03Z" fill="#00416A" />
      <path d="M478.26 331.03H459.99V338.52H478.26V331.03Z" fill="#00416A" />
      <path d="M491.86 331.03H486.63V338.52H491.86V331.03Z" fill="#00416A" />
      <path d="M480.79 341.62H451.61V349.11H480.79V341.62Z" fill="#00416A" />
      <path
        d="M434.2 322.63H422.14C419.942 322.627 417.835 321.753 416.281 320.199C414.727 318.645 413.853 316.538 413.85 314.34V313.05C413.853 310.852 414.727 308.745 416.281 307.191C417.835 305.637 419.942 304.763 422.14 304.76H434.2C436.398 304.763 438.505 305.637 440.059 307.191C441.613 308.745 442.487 310.852 442.49 313.05V314.34C442.487 316.538 441.613 318.645 440.059 320.199C438.505 321.753 436.398 322.627 434.2 322.63ZM422.14 307.12C420.566 307.12 419.057 307.744 417.943 308.856C416.83 309.968 416.203 311.476 416.2 313.05V314.34C416.203 315.914 416.83 317.422 417.943 318.534C419.057 319.646 420.566 320.27 422.14 320.27H434.2C435.769 320.254 437.269 319.625 438.38 318.517C439.49 317.408 440.122 315.909 440.14 314.34V313.05C440.122 311.481 439.49 309.982 438.38 308.874C437.269 307.765 435.769 307.136 434.2 307.12H422.14Z"
        fill="#00416A"
      />
      <path
        d="M346.76 198.57C368.581 198.57 386.27 180.881 386.27 159.06C386.27 137.239 368.581 119.55 346.76 119.55C324.939 119.55 307.25 137.239 307.25 159.06C307.25 180.881 324.939 198.57 346.76 198.57Z"
        fill="#D9EEE8"
      />
      <path d="M283.43 175.74H281.07V167.31H362.44V169.66H283.43V175.74Z" fill="#003C71" />
      <path
        d="M364.61 172.11H326.26V153.68C326.26 149.93 327.75 146.333 330.402 143.682C333.053 141.03 336.65 139.54 340.4 139.54H350.47C354.22 139.54 357.817 141.03 360.469 143.682C363.12 146.333 364.61 149.93 364.61 153.68V172.11Z"
        fill="#003C71"
      />
      <path
        d="M345.66 165.28C350.327 165.28 354.11 161.497 354.11 156.83C354.11 152.163 350.327 148.38 345.66 148.38C340.993 148.38 337.21 152.163 337.21 156.83C337.21 161.497 340.993 165.28 345.66 165.28Z"
        fill="#408DBC"
      />
    </svg>
  );
};
