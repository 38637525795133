import styled from "styled-components";

import { H6, Subtitle } from "@byko/component-typography";
import { gridTheme, theme } from "@byko/lib-styles";

export const PriceContainer = styled.div`
  display: flex;
  min-height: 85px;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 16px;
  gap: 6px;
  ${Subtitle} {
    position: relative;
    transform: translateY(-5px);
    &:after {
      position: absolute;
      top: 16px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: ${theme.palette.gray[30]};
      content: "";
    }
    @media screen and (max-width: 1300px) {
      &:after {
        top: 12px;
      }
      font-size: 16px;
    }
  }
  ${H6} {
    height: 38px;
    @media screen and (max-width: 1300px) {
      font-size: 18px;
    }
  }
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    padding: 4px 16px;
    gap: 0;
  }
`;

export const LoadingWrapper = styled.div`
  height: 38px;
  transform: translate3d(-16px, -60%, 0px);
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SqmSpan = styled.span`
  font-size: 16px;
`;
