import * as React from "react";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

import { useToasts } from "@byko/hooks-toasts";

export const SuccessToast = (): JSX.Element => {
  const { successMessage, setSuccessMessage, messagePosition } = useToasts();

  useEffect(() => {
    if (successMessage.length) {
      toast.success(successMessage);
      setSuccessMessage("");
    }

    let interval: number;

    return (): void => {
      clearInterval(interval);
    };
  }, [successMessage, setSuccessMessage]);

  return (
    <ToastContainer
      autoClose={5000}
      closeOnClick={true}
      containerId="success-toast"
      draggable={true}
      hideProgressBar={false}
      newestOnTop={false}
      pauseOnFocusLoss={false}
      pauseOnHover={true}
      position={messagePosition}
      rtl={false}
    />
  );
};
