import { atom } from "@byko/lib-recoil";

import { Key } from "./const";

import type { StateType } from "../interfaces";

export const state = atom<StateType>({
  key: Key("State"),
  default: "INITIAL_LOAD",
});
