import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const SettingsGearFilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M46 19H41.8896C40.9307 19 40.0718 18.4199 39.6836 17.4775C39.311 16.5811 39.5078 15.5635 40.1851 14.8857L43.0918 11.9795C43.2793 11.792 43.3848 11.5381 43.3848 11.2725C43.3848 11.0068 43.2793 10.7529 43.0918 10.5654L37.4351 4.9082C37.0444 4.51758 36.4116 4.51758 36.021 4.9082L33.1143 7.81445C32.436 8.49218 31.4185 8.69043 30.478 8.29785C29.5801 7.92773 29 7.06934 29 6.11035V2C29 1.44727 28.5522 1 28 1H20C19.4478 1 19 1.44727 19 2V6.11035C19 7.06933 18.4199 7.92773 17.4785 8.3164C16.5811 8.68847 15.5635 8.49218 14.8857 7.81445L11.979 4.9082C11.5884 4.51758 10.9556 4.51758 10.5649 4.9082L4.9082 10.5654C4.7207 10.7529 4.61523 11.0068 4.61523 11.2725C4.61523 11.5381 4.7207 11.792 4.9082 11.9795L7.81445 14.8857C8.49218 15.5635 8.68896 16.5811 8.29785 17.5225C7.92822 18.4199 7.06934 19 6.11035 19H2C1.44775 19 1 19.4473 1 20V28C1 28.5527 1.44775 29 2 29H6.11035C7.06933 29 7.92822 29.5801 8.3164 30.5225C8.68896 31.4189 8.49218 32.4365 7.81494 33.1143L4.9082 36.0205C4.7207 36.208 4.61523 36.4619 4.61523 36.7275C4.61523 36.9932 4.7207 37.2471 4.9082 37.4346L10.5649 43.0918C10.9556 43.4824 11.5884 43.4824 11.979 43.0918L14.8857 40.1856C15.564 39.5078 16.5815 39.3096 17.522 39.7021C18.4199 40.0723 19 40.9307 19 41.8896V46C19 46.5527 19.4478 47 20 47H28C28.5522 47 29 46.5527 29 46V41.8896C29 40.9307 29.5801 40.0723 30.5215 39.6836C31.4189 39.3115 32.4365 39.5068 33.1143 40.1856L36.021 43.0918C36.4116 43.4824 37.0444 43.4824 37.4351 43.0918L43.0918 37.4346C43.2793 37.2471 43.3848 36.9932 43.3848 36.7275C43.3848 36.4619 43.2793 36.208 43.0918 36.0205L40.1856 33.1143C39.5078 32.4365 39.311 31.4189 39.7021 30.4775C40.0718 29.5801 40.9307 29 41.8896 29H46C46.5522 29 47 28.5527 47 28V20C47 19.4473 46.5522 19 46 19ZM24 31C20.134 31 17 27.866 17 24C17 20.134 20.134 17 24 17C27.866 17 31 20.134 31 24C31 27.866 27.866 31 24 31Z"
        fill="#C6C6C6"
      />
      <path
        // eslint-disable-next-line max-len
        d="M24 13C17.9249 13 13 17.9249 13 24C13 30.0751 17.9249 35 24 35C30.0751 35 35 30.0751 35 24C35 17.9249 30.0751 13 24 13ZM24 31C20.134 31 17 27.866 17 24C17 20.134 20.134 17 24 17C27.866 17 31 20.134 31 24C31 27.866 27.866 31 24 31Z"
        fill="#959595"
      />
    </svg>
  );
};
