import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const DiscountIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 12C20 10.3 21.3 9 23 9V4H1V9C2.7 9 4 10.3 4 12C4 13.7 2.7 15 1 15V20H23V15C21.3 15 20 13.7 20 12Z"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path d="M15 9L9 15" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path
        // eslint-disable-next-line max-len
        d="M9.5 10C10.3284 10 11 9.32843 11 8.5C11 7.67157 10.3284 7 9.5 7C8.67157 7 8 7.67157 8 8.5C8 9.32843 8.67157 10 9.5 10Z"
        fill={iconColor}
      />
      <path
        // eslint-disable-next-line max-len
        d="M14.5 17C15.3284 17 16 16.3284 16 15.5C16 14.6716 15.3284 14 14.5 14C13.6716 14 13 14.6716 13 15.5C13 16.3284 13.6716 17 14.5 17Z"
        fill={iconColor}
      />
    </svg>
  );
};
