import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const KeyIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 16 16" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M5.20005 12.2C5.97325 12.2 6.60005 11.5732 6.60005 10.8C6.60005 10.0268 5.97325 9.40002 5.20005 9.40002C4.42685 9.40002 3.80005 10.0268 3.80005 10.8C3.80005 11.5732 4.42685 12.2 5.20005 12.2Z"
        stroke={iconColor}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <path
        // eslint-disable-next-line max-len
        d="M15 1H12.2L6.404 6.796C6.0204 6.6812 5.62093 6.6 5.2 6.6C2.88067 6.6 1 8.48067 1 10.8C1 13.1193 2.88067 15 5.2 15C7.51933 15 9.4 13.1193 9.4 10.8C9.4 10.3791 9.3188 9.9796 9.204 9.596L11.2667 7.53333V4.73333H14.0667L15 3.8V1Z"
        stroke={iconColor}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
    </svg>
  );
};
