import styled from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";

import type { ContainerProps } from "./interface";

export const Overlay = styled.div<{ active: boolean }>`
  position: fixed;
  z-index: 19;
  backdrop-filter: ${(props): string => (props.active ? "blur(4px) grayscale(0.4)" : "blur(0) grayscale(0)")};
  inset: 0;
  pointer-events: ${(props): string => (props.active ? "initial" : "none")};
  transition: 200ms;
`;

export const DrawerContainer = styled.div<ContainerProps>`
  position: fixed;
  z-index: 3001;
  right: 0;
  bottom: 0;
  width: 100vw;
  max-width: 496px;
  height: calc(100% - 88px);
  padding: 24px 48px;
  background-color: ${theme.palette.white.main};
  box-shadow: ${theme.palette.boxShadow.input};
  overflow-y: auto;
  transform: translate3d(${(props): string => (props.open ? "0" : "100%")}, 0, 0);
  transition: ${(props): string => (props.open ? "300ms" : "150ms")};
  transition-delay: ${(props): string => (props.open ? "0" : "100ms")};
  transition-timing-function: ease-in;
  @media screen and (max-width: ${gridTheme.breakpoints.sm}px) {
    height: calc(100% - 56px);
  }
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    max-width: 100%;
  }
`;

export const MainContent = styled.div`
  position: relative;
  z-index: 3;
  height: 100%;
  transition: 300ms;
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    padding-top: 0px;
    margin: 0 auto;
    transition: 300ms;
    transition-delay: 0ms;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 40px;
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: flex-end;
  padding-top: 12px;
`;

export const ChildrenContent = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
  ::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    display: none;
  }
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    padding-right: 16px;
    padding-left: 16px;
    margin: 0 auto;
  }
`;
