export const ProductFailImages: { url: string; alt: string }[] = [
  {
    url: "https://www.datocms-assets.com/65892/1664464657-placeholder-do-not-remove.jpg",
    alt: "",
  },
  {
    url: "https://www.datocms-assets.com/65892/1664464657-placeholder-do-not-remove.jpg",
    alt: "",
  },
];

export const FailBackImages: { url: string; alt: string } = {
  url: "https://www.datocms-assets.com/65892/1664464657-placeholder-do-not-remove.jpg",
  alt: "",
};
