import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const WalletSmallIcons: BykoReactIconComponentType = ({ iconColor, size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 16 16" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 11C10.1046 11 11 10.1046 11 9C11 7.89543 10.1046 7 9 7C7.89543 7 7 7.89543 7 9C7 10.1046 7.89543 11 9 11Z"
        fill={iconColor}
      />
      <path
        // eslint-disable-next-line max-len
        d="M2 2.73333V12.2667C2 12.7264 2.16857 13.1673 2.46863 13.4923C2.76869 13.8174 3.17565 14 3.6 14H14V4.46667H3.6C3.17565 4.46667 2.76869 4.28405 2.46863 3.95899C2.16857 3.63392 2 3.19304 2 2.73333ZM2 2.73333C2 2.27362 2.16857 1.83274 2.46863 1.50768C2.76869 1.18262 3.17565 1 3.6 1H10.8V2.73333"
        stroke={iconColor}
        strokeLinecap="square"
        strokeWidth="1.5"
      />
    </svg>
  );
};
