import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const PaintBrushFilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M30.749 41.293L6.70703 17.251L21.556 2.40203C22.337 1.62103 23.603 1.62103 24.384 2.40203L35.913 13.93C36.209 14.226 36.289 14.675 36.114 15.055L32.84 22.172L40.018 18.948C40.397 18.778 40.841 18.86 41.135 19.153L45.597 23.615C46.378 24.396 46.378 25.662 45.597 26.443L30.749 41.293Z"
        fill="#E6E7E8"
      />
      <path d="M32.1627 39.8782L8.12134 15.8368L6.70714 17.251L30.7485 41.2924L32.1627 39.8782Z" fill="#C6C6C6" />
      <path
        // eslint-disable-next-line max-len
        d="M6.70692 17.251L5.99992 17.958C3.27092 20.687 3.27092 25.129 5.99992 27.857L9.53592 31.393L2.46492 38.464C0.515924 40.413 0.515924 43.586 2.46492 45.535C4.41392 47.484 7.58692 47.484 9.53592 45.535L16.6069 38.464L20.1419 42C22.8709 44.729 27.3119 44.729 30.0409 42L30.7479 41.293L6.70692 17.251ZM4.58592 43.414C3.80492 42.633 3.80492 41.367 4.58592 40.586C5.36692 39.805 6.63292 39.805 7.41392 40.586C8.19492 41.367 8.19492 42.633 7.41392 43.414C6.63292 44.195 5.36692 44.195 4.58592 43.414Z"
        fill="#0A3A5C"
      />
      <path
        // eslint-disable-next-line max-len
        d="M4.58599 43.414C3.80499 42.633 3.80499 41.367 4.58599 40.586C5.36699 39.805 6.63299 39.805 7.41399 40.586C8.19499 41.367 8.19499 42.633 7.41399 43.414C6.63299 44.195 5.36699 44.195 4.58599 43.414Z"
        fill="#E6E7E8"
      />
    </svg>
  );
};
