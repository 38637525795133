import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const CreditCardNormalIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M1 7H23" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M1 11H23" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path
        // eslint-disable-next-line max-len
        d="M21 21H3C1.895 21 1 20.105 1 19V5C1 3.895 1.895 3 3 3H16H21C22.105 3 23 3.895 23 5V19C23 20.105 22.105 21 21 21Z"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path d="M5 16H10" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M18 16H19" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
    </svg>
  );
};
