/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const LeafBulbLineDecorative: BykoReactDecorativeIconComponentType = ({ size, color = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M24 40V9" stroke="black" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2.5" />
      <path d="M18 40H30" stroke="black" strokeMiterlimit="10" strokeWidth="2.5" />
      <path
        d="M33 12H32C27.5817 12 24 15.5817 24 20V22H25C29.4183 22 33 18.4183 33 14V12Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path
        d="M15 21C15 25.4183 18.5817 29 23 29H24V27C24 22.5817 20.4183 19 16 19H15V21Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path
        d="M41 19C41 8.96835 32.311 0.966167 22.0485 2.1089C14.451 2.95488 8.23515 8.97092 7.17293 16.5412C6.00667 24.853 10.8634 32.193 18 34.8899V40C18 43.3137 20.6863 46 24 46H24C27.3137 46 30 43.3137 30 40V34.8899C36.4235 32.4624 41 26.2735 41 19Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
    </svg>
  );
};
