import { DefaultValue, selector } from "@byko/lib-recoil";

import { membershipsState } from "./memberships";
import { userState } from "./user";

import type { CustomerAccountMembership, User } from "@byko/lib-api-rest";

const TOKEN = "active-membership-token";

const clearToken = (): void => {
  if (typeof window === "undefined") {
    return;
  }
  window.localStorage.removeItem(TOKEN);
};

const setToken = (value: number, user: User): void => {
  if (typeof window === "undefined") {
    return;
  }
  if (user == null || value == null) {
    clearToken();
    return;
  }
  const newToken = Buffer.from(`${user.id}:${value}`, "utf-8").toString("base64");
  window.localStorage.setItem(TOKEN, newToken);
};

export const getMembership = (
  user: User | null,
  memberships: CustomerAccountMembership[],
): CustomerAccountMembership | null => {
  if (typeof window === "undefined") {
    return null;
  }

  const token = window.localStorage.getItem(TOKEN);
  if (!token || !user) {
    return null;
  }
  try {
    const parsed = Buffer.from(token, "base64").toString("utf-8");
    const [tokenUser, id] = parsed.split(":");
    if (!tokenUser || !id) {
      return null;
    }
    if (parseInt(tokenUser, 10) != user.id) {
      return null;
    }
    const currentToken = parseInt(id, 10);
    if (Number.isNaN(currentToken)) {
      return null;
    }
    const findItem = memberships.find((e) => e.id === currentToken);
    if (!findItem) {
      return null;
    }
    return findItem;
  } catch (_e) {
    return null;
  }
};

export const activeMembershipState = selector<CustomerAccountMembership | null>({
  key: "next-login-active-membership",
  set: ({ get }, value: CustomerAccountMembership | null | DefaultValue): void => {
    const user = get(userState);
    const memberships = get(membershipsState);
    if (value instanceof DefaultValue) {
      return;
    }
    if (value == null || user == null) {
      clearToken();
      return;
    }
    const isInMemberships = memberships.find((e) => e.id === value?.id);
    if (!isInMemberships) {
      clearToken();
      return;
    }
    setToken(value.id, user);
    return;
  },
  get: ({ get }) => {
    const user = get(userState);
    const memberships = get(membershipsState);
    if (user == null || typeof window === "undefined" || !memberships) {
      return null;
    }
    const membership = getMembership(user, memberships);

    if (!membership) {
      // Select the first membership
      return memberships.find(Boolean) ?? null;
    }
    return membership;
  },
});

export const isMembershipStateSetStore = selector<boolean>({
  key: "next-login-is-membership-state-set",
  get: ({ get }) => {
    const user = get(userState);
    const memberships = get(membershipsState);
    const activeMembership = get(activeMembershipState);
    if (memberships.length === 1 && activeMembership) {
      return true;
    }
    const membership = getMembership(user, memberships);
    return membership?.id === activeMembership?.id;
  },
});
