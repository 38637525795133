import { atom } from "@byko/lib-recoil";

export const showMinicartState = atom<boolean>({
  key: "show-minicart-state",
  default: false,
});

export const setDeferMinicartToggleState = atom<boolean>({
  key: "show-minicart-state-defer-state",
  default: false,
});
