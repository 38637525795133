import styled from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";

export const CardContainer = styled.div`
  display: flex;
  min-width: 420px;
  flex: calc(33.33% - 20px);
  padding: 20px;
  background-color: ${theme.palette.gray[5]};
  gap: 20px;
  :last-child {
    max-width: calc(33.33% - 10px);
  }
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    width: 100%;
    min-width: unset;
    max-width: unset;
    flex: calc(50% - 20px);
    :last-child {
      max-width: unset;
    }
  }
  @media screen and (max-width: 1066px) {
    flex-direction: column;
  }
`;

export const StoreInfoWrapper = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  flex-direction: column;
  @media screen and (max-width: 1066px) {
    width: 100%;
  }
`;

export const StoreName = styled.div`
  display: flex;
  flex-direction: column;
  h6 {
    color: ${theme.palette.blue.dark};
  }
  p {
    color: ${theme.palette.blue.dark};
  }
`;

export const Location = styled.a`
  display: flex;
  width: fit-content;
  align-items: baseline;
  gap: 10px;
  p {
    color: ${theme.palette.blue.dark};
  }
  svg {
    transform: translateY(4px);
    path {
      stroke: ${theme.palette.blue.dark};
    }
  }
`;

export const StoreOpeningInfo = styled.div`
  display: flex;
  width: 40%;
  flex-direction: column;
  @media screen and (max-width: 1066px) {
    width: 100%;
  }
`;

export const OpeningDetail = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 8px;
  p {
    color: ${theme.palette.gray[90]};
    white-space: nowrap;
  }
`;
