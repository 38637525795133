import styled from "styled-components";
import { ifProp } from "styled-tools";

import { PSmall } from "@byko/component-typography";
import { theme } from "@byko/lib-styles";

import type { RadioButtonProps } from "./interface";

export const Label = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

export const CircleOuter = styled.div<RadioButtonProps>`
  position: absolute;
  width: 24px;
  height: 24px;
  border: 3px solid ${ifProp({ white: true }, `${theme.palette.white.main}`, `${theme.palette.blue.main}`)};
  border-color: ${ifProp({ white: true }, `${theme.palette.white.main}`, `${theme.palette.blue.light}`)};
  border-radius: ${ifProp({ radioBox: true }, "0", "50%")};
  background-color: ${ifProp({ white: true }, `${theme.palette.gray[5]}`, `${theme.palette.white.main}`)};
`;

export const Circle = styled.div<RadioButtonProps>`
  position: absolute;
  left: 5px;
  width: 14px;
  height: 14px;
  align-self: center;
  border-radius: ${ifProp({ radioBox: true }, "0", "50%")};
  opacity: 0;
  pointer-events: auto;
  transition: background-color 0.2s ease-out, border 0.2s ease-out;
`;

export const Text = styled(PSmall)<RadioButtonProps>`
  margin-left: 26px;
  color: ${ifProp({ white: true }, `${theme.palette.white.main}`, `${theme.palette.blue.main}`)};
  cursor: pointer;
`;

export const RadioInput = styled.input<RadioButtonProps>`
  border-radius: 0;
  cursor: pointer;
  visibility: hidden;

  &:hover ~ ${CircleOuter} {
    border-color: ${ifProp({ white: true }, `${theme.palette.white.main}`, `${theme.palette.blue.main}`)};
  }
  &:focus ~ ${CircleOuter} {
    border: 1px solid ${ifProp({ white: true }, `${theme.palette.white.main}`, `${theme.palette.blue.main}`)};
    outline: 2px double ${ifProp({ white: true }, `${theme.palette.white.main}`, `${theme.palette.blue.main}`)};
  }

  &:checked ~ ${Circle} {
    border: 1px solid ${ifProp({ white: true }, `${theme.palette.white.main}`, `${theme.palette.blue.main}`)};
    background: ${ifProp({ white: true }, `${theme.palette.white.main}`, `${theme.palette.blue.main}`)};
    opacity: 1;

    &::after {
      display: block;
      width: 12px;
      height: 12px;
      margin: 4px;
      color: ${theme.palette.white.main};
    }
  }
  &:checked ~ ${CircleOuter} {
    border: 3px solid ${ifProp({ white: true }, `${theme.palette.white.main}`, `${theme.palette.blue.main}`)};
  }

  &:disabled {
    ~ ${Text} {
      color: ${ifProp({ white: true }, `${theme.palette.white.main}`, `${theme.palette.gray[20]}`)};
      cursor: not-allowed;
    }
    ~ ${CircleOuter} {
      border: 3px solid ${ifProp({ white: true }, `${theme.palette.white.main}`, `${theme.palette.gray[20]}`)};
      cursor: not-allowed;
    }
  }
`;
