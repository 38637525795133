/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { useMemo } from "react";

import { getBykoConfig } from "@byko/lib-utils";
import { isSSR } from "@byko/types-utils";

import type { NormalizedCacheObject, StoreObject } from "@apollo/client";
import type { Maybe } from "@byko/types-utils";

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";
export type APOLLO_STATE_TYPE = Record<string, StoreObject | undefined>;

export const httpLink = new BatchHttpLink({
  uri: getBykoConfig("SALEOR_GQL_URL"),
  batchMax: 20,
  batchInterval: 20,
});

export const authLink = setContext((_, context) => {
  if (!isSSR()) {
    const token = window.localStorage.getItem("token");
    if (token) {
      return {
        ...context,
        headers: {
          ...context.headers,
          Authorization: `JWT ${token}`,
        },
      };
    }
  }
  return context;
});

let client: ApolloClient<any> | null = null;

export const initializeApollo = (initialState: Maybe<NormalizedCacheObject> = undefined): ApolloClient<any> => {
  if (client) {
    return client;
  }

  //
  // Notice:
  // We are not using initialState and will probably not since
  // since the server is not using Apollo at all.
  //

  const cache = new InMemoryCache().restore(initialState ?? {});
  client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
  });
  return client;
};

export const useApollo = (cache: any | null | undefined = {}): ApolloClient<any> => {
  const { client: _client } = useMemo(() => {
    const currentInitState = cache ?? undefined;
    const currentClient = initializeApollo(currentInitState);
    return {
      client: currentClient,
    };
    // Only run this on inital load.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return _client;
};
