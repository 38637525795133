import { useEffect, useRef } from "react";

export const usePrevious = <T,>(value: T | null | undefined = null): T | null => {
  const ref = useRef<T | null>(null);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current ?? null;
};
