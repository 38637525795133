import type { FN } from "./interface";

export const debounce = <T extends (...args: Parameters<T>) => ReturnType<T>>(func: T, waitFor: number): FN<T> => {
  let timeout: number;

  const debounced = (...args: Parameters<T>): void => {
    window.clearTimeout(timeout);
    timeout = window.setTimeout(() => func(...args), waitFor);
  };

  debounced.cancel = (): void => {
    window.clearTimeout(timeout);
  };

  return debounced;
};
