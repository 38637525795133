import { anonymousTokenStore } from "./anonymous-token";
import { _checkout } from "./checkout";
import { cartObject, checkoutLines, selectorCartItem, selectorCartItemCount, totalCartCount } from "./checkout-lines";
import { loadingCartState } from "./loading-cart-state";
import { state } from "./state";

export const Store = {
  anonymousTokenStore,
  loadingCartState,
  _checkout: _checkout,
  checkout: _checkout,
  checkoutLines,
  cartObject,
  state,
  totalCartCount,
  cartItem: selectorCartItem,
  cartItemCount: selectorCartItemCount,
};
