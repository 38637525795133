import styled from "styled-components";

export const LoadingContainer = styled.div<{ active: boolean }>`
  position: absolute;
  z-index: 2;
  display: grid;
  inset: 0;
  opacity: ${({ active }): string => (active ? "1" : "0")};
  place-items: center;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
`;

export const MainLoadingContainer = styled.div<{ active: boolean }>`
  position: fixed;
  z-index: 100;
  display: grid;
  background-color: rgba(128, 179, 210, 0.4);
  inset: 0;
  opacity: ${({ active }): string => (active ? "1" : "0")};
  place-items: center;
  pointer-events: none;
  transition: 400ms;
  transition-delay: ${(props): string => (props.active ? "400ms" : "0")};
`;
