/* eslint-disable react-perf/jsx-no-new-object-as-prop */
/* eslint-disable react-perf/jsx-no-new-function-as-prop */
/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { Select } from "@byko/component-select";
import { EyebrowLarge, EyebrowMedium } from "@byko/component-typography";
import { ArrowDownIcons, CancelSmallIcons } from "@byko/lib-icons";
import { theme } from "@byko/lib-styles";

import { PRODUCT_SORT_OPTIONS, PRODUCT_SORT_OPTIONS_MAP } from "../conf";
import { useProductSearch } from "../context";

import FacetsBar from "./filters/facets-bar";
import { FilterChip } from "./filters/filter-chip";

import type { UseSelectStateChange } from "downshift";
import { productHitCountState } from "./product-search-results";
import { useRecoilValue } from "recoil";

const ShowInDesktop = styled.div`
  position: relative;
  display: none;
  @media screen and (min-width: 767px) {
    display: block;
  }
`;

const SubRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  grid-area: filter-header;
  .select-container {
    width: 328px;
    & > div {
      width: 332px;
    }
  }
  @media screen and (max-width: 1179px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media screen and (max-width: 895px) {
    display: none;
  }
`;

export const CountContainerMobile = styled.div`
  width: 100%;
`;

const ShowInMobile = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  pointer-events: initial;
  @media screen and (min-width: 895px) {
    display: none;
  }
`;

const OuterWrapper = styled.div`
  z-index: 30;
  inset: 0;
  pointer-events: none;
`;

const MainWrapper = styled.div`
  margin-bottom: 32px;
`;

const FilterChips = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
  gap: 8px;
  grid-area: chip-row;
  @media screen and (max-width: 895px) {
    display: none;
  }
`;

const FilterActionsBar = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid ${theme.palette.gray[10]};
  border-right: 1px solid ${theme.palette.gray[10]};
  border-left: 1px solid ${theme.palette.gray[10]};
`;

const SortContainer = styled.div`
  position: relative;
  z-index: 2;
  width: 50%;
  button {
    outline: 1px solid ${theme.palette.gray[10]};
  }
  @media screen and (max-width: 895px) {
    button {
      border-bottom: 1px solid ${theme.palette.gray[10]};
      outline: unset;
    }
  }
`;

const FilterBtn = styled.button`
  position: relative;
  display: flex;
  width: 50%;
  align-items: center;
  padding-left: 16px;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid ${theme.palette.gray[10]};
  border-left: 1px solid ${theme.palette.gray[10]};
  background-color: ${theme.palette.white.main};
  color: ${theme.palette.blue.main} !important;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 800 !important;
  text-align: left;
  transition: border-color 0.15s;
  & > svg {
    display: block;
    margin-right: 8px;
  }
`;

const FilterCount = styled.div`
  position: absolute;
  top: calc(50% - 12px);
  right: 16px;
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 16px;
  background-color: ${theme.palette.gray[10]};
  color: ${theme.palette.blue.main};
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1;
  transition: opacity 0.15s;
`;

const FilterBtnClose = styled.div`
  position: absolute;
  top: calc(50% - 12px);
  right: 16px;
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 16px;
  background-color: ${theme.palette.gray[10]};
  transition: opacity 0.15s;
`;

const MobileFilterContainer = styled.div`
  position: absolute;
  z-index: 5;
  top: 82px;
  left: 0;
  width: 100%;
  padding: 2px;
  border: none;
  background-color: ${theme.palette.white.main};
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.15s;

  &.isOpen {
    opacity: 1;
    pointer-events: unset;
  }
`;

const ProductCount = styled.div``;

interface Option {
  value: string;
  label: string;
}

export const FilterHeader = (): JSX.Element => {
  const productCount = useRecoilValue<number>(productHitCountState);
  const { setSorting, activeFilters, queryParams, clearFilter } = useProductSearch();

  const activeFilterCount = activeFilters?.length;
  const [showMobileFilters, setShowMobileFilters] = useState<boolean>(false);

  const handleSortChange = useCallback(
    (changes: UseSelectStateChange<Option>) => {
      if (changes.selectedItem?.value) {
        setSorting(changes.selectedItem?.value);
      }
    },
    [setSorting],
  );

  const sortInput = (
    <SortContainer>
      <Select
        handleSelectedItemChange={handleSortChange}
        hideValue={true}
        icon={ArrowDownIcons}
        items={PRODUCT_SORT_OPTIONS}
        itemToString={(item): string => item?.label || ""}
        placeholder={PRODUCT_SORT_OPTIONS_MAP[queryParams["sort"]?.[0] || ""]?.label}
        selectedItem={PRODUCT_SORT_OPTIONS_MAP[queryParams["sort"]?.[0] || ""]}
      />
    </SortContainer>
  );

  return (
    <>
      <OuterWrapper>
        <MainWrapper>
          <ShowInMobile>
            <CountContainerMobile>
              <EyebrowMedium>
                {productCount} {productCount === 1 ? "vara fannst" : "vörur fundust"}
              </EyebrowMedium>
            </CountContainerMobile>
            <FilterActionsBar>
              {sortInput}

              <FilterBtn
                style={{
                  fontWeight: activeFilterCount ? 500 : 400,
                  color: activeFilterCount ? theme.palette.blue.main : theme.palette.gray[50],
                }}
                onClick={() => setShowMobileFilters(!showMobileFilters)}
              >
                Síur
                <FilterCount style={{ opacity: activeFilterCount ? "1" : "0" }}>{activeFilterCount}</FilterCount>
                <FilterBtnClose style={{ opacity: showMobileFilters ? "1" : "0" }}>
                  <CancelSmallIcons iconColor={theme.palette.blue.main} size={12} />
                </FilterBtnClose>
              </FilterBtn>
            </FilterActionsBar>

            <MobileFilterContainer className={showMobileFilters ? "isOpen" : ""}>
              <FacetsBar />
            </MobileFilterContainer>
          </ShowInMobile>
        </MainWrapper>
      </OuterWrapper>

      <FilterChips>
        {activeFilters?.map((item) => (
          <FilterChip key={item.value} onClick={() => clearFilter(item)}>
            {item.label}
          </FilterChip>
        ))}
      </FilterChips>

      <SubRow>
        <ProductCount>
          <EyebrowLarge>
            {productCount} {productCount === 1 ? "vara fannst" : "vörur fundust"}
          </EyebrowLarge>
        </ProductCount>
        <ShowInDesktop>{sortInput}</ShowInDesktop>
      </SubRow>
    </>
  );
};
