import * as React from "react";
import { useCallback, useMemo, useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";

import { useAuth } from "@byko/hooks-auth-next";
import { useMobileBurger } from "@byko/hooks-mobile-burger";

import { DropdownSelect } from "./dropdown-select";
import { LinkTag, LinkText, MobileNavLink, MobileSelectionContainer, MobileSelectionContentBlock } from "./styles";

import type { CustomerAccountMembership } from "@byko/lib-api-rest";

export const MobileSelection = ({
  mockMemberships,
}: {
  mockMemberships: CustomerAccountMembership[] | undefined;
}): JSX.Element => {
  const collapseRef = useRef<HTMLDivElement>(null);
  const { menuOpen, setMenuOpen } = useMobileBurger();
  const { activeMembership, memberships } = useAuth();

  const collapseMenu = useCallback(() => {
    setTimeout(() => {
      if (menuOpen) {
        setMenuOpen(false);
      }
    }, 150);
  }, [setMenuOpen, menuOpen]);

  useOnClickOutside(collapseRef, collapseMenu);

  const mockActiveMemberShip = useMemo(() => {
    if (mockMemberships && mockMemberships.length !== 0) {
      return mockMemberships[0];
    }

    return null;
  }, [mockMemberships]);

  return (
    <MobileSelectionContainer ref={collapseRef} active={menuOpen}>
      <MobileSelectionContentBlock>
        {mockActiveMemberShip && (
          <DropdownSelect active={mockActiveMemberShip} fullWidth={true} options={mockMemberships || memberships} />
        )}
        {activeMembership !== null && (
          <DropdownSelect active={activeMembership} fullWidth={true} options={mockMemberships || memberships} />
        )}
      </MobileSelectionContentBlock>
      <MobileSelectionContentBlock>
        <MobileNavLink href="/mitt">
          <LinkText>Mitt BYKO</LinkText>
          <LinkTag />
        </MobileNavLink>
      </MobileSelectionContentBlock>
      <MobileSelectionContentBlock>
        <MobileNavLink
          href="https://byko.is/thjonusta/vidskiptakjor/reikningsvidskipti"
          rel="noreferrer"
          target="_blank"
        >
          <LinkText>Reikningsviðskipti</LinkText>
          <LinkTag />
        </MobileNavLink>
      </MobileSelectionContentBlock>
      <MobileSelectionContentBlock>
        <MobileNavLink href="https://byko.is/vidskiptaradgjof#panta" rel="noreferrer" target="_blank">
          <LinkText>Bóka ráðgjöf</LinkText>
          <LinkTag />
        </MobileNavLink>
      </MobileSelectionContentBlock>
    </MobileSelectionContainer>
  );
};
