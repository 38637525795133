import React from "react";

import { H6, PMedium, Subtitle } from "@byko/component-typography";
import { LocationIcons } from "@byko/lib-icons";

import { CardContainer, Location, OpeningDetail, StoreInfoWrapper, StoreName, StoreOpeningInfo } from "./styles";

import type { OpeningHourProps } from "./interface";

export const OpeningHourCard = ({
  id,
  openingTime,
  storeLocation,
  storeName,
  storeType,
}: OpeningHourProps): JSX.Element => {
  return (
    <CardContainer key={id} className="opening-hours-card">
      <StoreInfoWrapper>
        <StoreName>
          <H6 maxFontSize="24px" uppercase={true}>
            {storeName}
          </H6>
          <Subtitle>{storeType}</Subtitle>
        </StoreName>
        <Location href={`http://maps.google.com/?q=BYKO ${storeLocation}`} rel="noreferrer" target="_blank">
          <LocationIcons size={20} />
          <Subtitle>{storeLocation}</Subtitle>
        </Location>
      </StoreInfoWrapper>

      <StoreOpeningInfo>
        {openingTime.map((item) => (
          <OpeningDetail key={item.id}>
            <PMedium>{item.dateName}</PMedium>
            <PMedium>{item.dateOpeningHours}</PMedium>
          </OpeningDetail>
        ))}
      </StoreOpeningInfo>
    </CardContainer>
  );
};
