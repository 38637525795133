import { useCallback } from "react";

import { useRecoilState } from "@byko/lib-recoil";

import { modalPropsState } from "./store";

import type { UseModalProps } from "./interface";
import type { ModalProps } from "@byko/types-modal";

export const useModal = (): UseModalProps => {
  const [modalProps, setModalProps] = useRecoilState(modalPropsState);

  const show = useCallback(
    (config: ModalProps) => {
      setModalProps(config);
    },
    [setModalProps],
  );

  const hide = useCallback(() => {
    setModalProps(undefined);
  }, [setModalProps]);

  return {
    show,
    hide,
    modalProps,
  };
};
