import * as React from "react";
import { useCallback, useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";

import { useAuth } from "@byko/hooks-auth-next";
import { useSetRecoilState } from "@byko/lib-recoil";
import { theme } from "@byko/lib-styles";

import { collapsedState } from "./collapsed";
import { DropdownSelectOptionRow, DropdownSelectOptionRowLabel } from "./styles";

import type { DropdownOtionsItemProps } from "./interface";

export const DropdownSelectOptionsItem = ({ ssn, name, id }: DropdownOtionsItemProps): JSX.Element => {
  const collapseRef = useRef<HTMLDivElement>(null);
  const { switchAccount } = useAuth();
  const setCollapsed = useSetRecoilState<boolean>(collapsedState);

  const collapseMenu = useCallback(() => {
    setCollapsed(true);
  }, [setCollapsed]);

  useOnClickOutside(collapseRef, collapseMenu);

  const handleItemClick = useCallback(async () => {
    await switchAccount(id);
    collapseMenu();
  }, [collapseMenu, switchAccount, id]);

  return (
    <DropdownSelectOptionRow key={ssn} onClick={handleItemClick}>
      <DropdownSelectOptionRowLabel className="option-label" color={theme.palette.blue.dark}>
        {name}
      </DropdownSelectOptionRowLabel>
      <DropdownSelectOptionRowLabel className="option-label" color={theme.palette.gray[40]} small={true}>
        {ssn}
      </DropdownSelectOptionRowLabel>
    </DropdownSelectOptionRow>
  );
};
