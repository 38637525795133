import React, { useCallback } from "react";
import { useProductSearch } from "src/features/search/context";
import { sortFacetOptions } from "src/features/search/api";
import { FacetCheckbox } from "./checkbox-facet";
import { FacetLayout } from "./facet-layout";
import { flattenFacetTreeValues } from "./utils";

import type { FC } from "react";
import type { FacetTreeItem } from "src/features/search/api";
import type { HierarchicalFacetOption, HierarchicalFacetProps } from "src/features/search/types";

const convertToFacetTree = (options: HierarchicalFacetOption[]): Map<string | number, FacetTreeItem> => {
  const facetPairs = options.map<[string | number, FacetTreeItem]>((f) => [
    f.value,
    { ...f, children: [], parents: [] },
  ]);

  const tree = new Map(facetPairs);

  tree.forEach((ref: FacetTreeItem) => {
    tree.get(ref.parent)?.children?.push(ref);
  });

  tree.forEach((ref: FacetTreeItem) => {
    ref.children?.forEach((child) => {
      const parent = tree.get(child.parent);
      if (parent) {
        child?.parents?.push(parent);
      }
    });
  });

  return tree;
};

export const HierarchicalCheckboxFacet: FC<HierarchicalFacetProps> = ({ attr, facet }) => {
  const { updateChoiceFacet, facetOrderValues } = useProductSearch();
  const sortedOptions = sortFacetOptions(attr, facet.options, facetOrderValues);
  const facetTree = convertToFacetTree(sortedOptions as HierarchicalFacetOption[]);
  const facetTreeValues = Array.from(facetTree.values()).filter((p) => !p.parent);
  const facetTreeValuesFlat: FacetTreeItem[] = flattenFacetTreeValues(facetTreeValues);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const checked = e.target.checked;

      const filterUpdate = { [value]: checked };
      const selectedFacet = facetTreeValuesFlat.find(
        (localFacet: FacetTreeItem) => localFacet.value.toString() === value,
      );

      // if un-checking
      if (!checked && selectedFacet) {
        // check if has children and uncheck them
        if (selectedFacet?.children?.length ?? 0 > 0) {
          const selectedFacetChildrenFlat = flattenFacetTreeValues(selectedFacet?.children ?? []);
          selectedFacetChildrenFlat.forEach((localFacet: FacetTreeItem) => {
            filterUpdate[localFacet.value] = checked;
          });
        }

        // check if all siblings are also un-checked
        const parent = facetTree.get(selectedFacet.parent);
        const noActiveChildren = !parent?.children?.some((child) => child !== selectedFacet && child.active);
        if (parent && noActiveChildren) {
          // if all parent children are un-checked, add parent facet
          filterUpdate[parent.value] = true;
        }
      }

      // if checking, check if has parent and remove parent facet
      if (checked && selectedFacet?.parents) {
        selectedFacet.parents.forEach((parent) => {
          filterUpdate[parent.value] = false;
        });
      }

      updateChoiceFacet(facet, attr, filterUpdate);
    },
    [updateChoiceFacet, facet, attr, facetTreeValuesFlat, facetTree],
  );

  return (
    <FacetLayout label={facet.label}>
      {facetTreeValues.map((o: FacetTreeItem) => (
        <FacetCheckbox key={o.value} level={0} option={o} onChange={onChange} />
      ))}
    </FacetLayout>
  );
};
