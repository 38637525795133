import React, { useMemo } from "react";
import { createContext, useContext } from "react";

type Platform = "mobile" | "desktop";

interface ComponentSettings {
  platform: Platform;
}

const defaultProps: ComponentSettings = {
  platform: "desktop",
};

const Context = createContext<ComponentSettings>(defaultProps);

export const useComponentSettingsContext = (): ComponentSettings => useContext(Context);

export const ComponentSettingsProvider = ({
  children,
  ...props
}: Partial<ComponentSettings> & { children: JSX.Element }): JSX.Element => {
  const value = useMemo(() => {
    return {
      ...defaultProps,
      ...props,
    };
  }, [props]);
  return <Context.Provider value={value}>{children}</Context.Provider>;
};
