import { useMemo } from "react";
import { useQuery } from "react-query";

import { restApi } from "@byko/lib-api-rest";
import type { Warehouse } from "@byko/lib-api-rest";
import type { AvailabilityList, ProductInventoryProps } from "./interface";

export const useProductInventory = ({ productSlug, activeVariantSKU, warehouses }: ProductInventoryProps) => {
  const inventoryQuery = useQuery(
    ["product-inventory", productSlug],
    () => restApi.productsInventoryRetrieve(productSlug),
    {
      enabled: !!productSlug,
      refetchOnWindowFocus: false,
    },
  );
  const inventory = inventoryQuery.data?.data;

  const availability = useMemo((): AvailabilityList => {
    const availabilityStatus: AvailabilityList = {
      soldOutInWebstore: true,
      available: [],
      lowStock: [],
      outOfStock: [],
    };

    const status = inventory?.variants.find((variant) => {
      return variant.sku === activeVariantSKU;
    }, []);

    const WAREHOUSE_SLUGS = ["LAG390", "LAG330", "LAG339", "LAG305", "LAG380"];

    let availableInWarehouses = false;
    if (status && status.inventoryStatus) {
      warehouses.forEach((warehouse: Warehouse) => {
        if (status.inventoryStatus[warehouse.slug]) {
          if (status.inventoryStatus[warehouse.slug] === "sold_out") {
            if (!WAREHOUSE_SLUGS.includes(warehouse.slug)) {
              availabilityStatus.outOfStock.push({
                slug: warehouse.slug,
                name: warehouse.name,
              });
            }
          } else if (status.inventoryStatus[warehouse.slug] === "low") {
            if (WAREHOUSE_SLUGS.includes(warehouse.slug)) {
              availableInWarehouses = true;
            } else {
              availabilityStatus.lowStock.push({
                slug: warehouse.slug,
                name: warehouse.name,
              });
            }
          } else {
            if (WAREHOUSE_SLUGS.includes(warehouse.slug)) {
              availableInWarehouses = true;
            } else {
              availabilityStatus.available.push({
                slug: warehouse.slug,
                name: warehouse.name,
              });
            }
          }
        }
      }, []);
    }

    let soldOutInWebstore = true;

    if (status?.webstoreInStock) {
      soldOutInWebstore = false;
    }

    if (availableInWarehouses) {
      availabilityStatus.available.push({
        slug: "VORUHUS",
        name: "Vöruhús",
      });
    } else {
      availabilityStatus.outOfStock.push({
        slug: "VORUHUS",
        name: "Vöruhús",
      });
    }

    return {
      ...availabilityStatus,
      soldOutInWebstore,
    };
  }, [inventory, activeVariantSKU, warehouses]);

  return {
    inventory,
    availability,
  };
};
