import {
  addPaymentInfo,
  addProductListClick,
  addProductListImpression,
  addProductPageViewImpression,
  addProductToCart,
  addShippingInfo,
  checkoutCompleted,
  checkoutStarted,
  removeProductFromCart,
  viewCartPage,
} from "./products";

export type { AnalyticsProductListSection } from "./utils";

export const analytics = {
  addPaymentInfo,
  addProductListClick,
  addProductListImpression,
  addProductPageViewImpression,
  addProductToCart,
  addShippingInfo,
  checkoutCompleted,
  checkoutStarted,
  removeProductFromCart,
  viewCartPage,
};
