/* eslint-disable max-len */
import * as React from "react";
import { BykoReactIconComponentType } from "@byko/lib-core";

export const Error400: BykoReactIconComponentType = ({ size = 200 }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 539 620" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M408.659 103.222c-164.959 0-224.359 298.467-300.544 298.467-49.54 0-89.711 41.637-89.711 93.011 0 49.378 37.074 89.711 83.966 92.807 1.874.408 3.83.489 5.745.204 19.922 0 38.296-6.722 53.166-18.089 110.897-58.952 174.371-228.637 247.378-228.637 63.311 0 114.645-53.207 114.645-118.881S471.97 103.222 408.659 103.222Z"
        fill="#8FDBC7"
      />
      <path
        d="M215.996 232.778c12.623 0 22.856-10.598 22.856-23.671 0-13.072-10.233-23.67-22.856-23.67-12.622 0-22.855 10.598-22.855 23.67 0 13.073 10.233 23.671 22.855 23.671Z"
        fill="#00416A"
      />
      <path
        d="M215.548 251.804c23.063 0 41.759-19.39 41.759-43.308s-18.696-43.307-41.759-43.307-41.759 19.389-41.759 43.307c0 23.918 18.696 43.308 41.759 43.308ZM144.415 208.496c0-35.648 24.363-65.348 56.792-72.233a69.333 69.333 0 0 1 14.341-1.507"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M108.237 605.596c-59.074 0-106.985-49.663-106.985-110.896 0-20.656 5.46-40.007 14.952-56.589M519.23 154.393c11.326 19.84 17.844 42.981 17.844 67.67 0 47.83-24.322 89.752-60.867 113.178"
        stroke="#0A3A5C"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M134.963 276.737c3.219 5.907 9.207 11.285 15.563 13.893 1.711 5.296-4.645 4.522-8.596 3.666-3.545-.733-12.223-11-9.493-17.477.489-1.06 1.956-1.1 2.526-.082Z"
        fill="#FFB784"
      />
      <path
        d="M141.93 294.296c-3.545-.733-12.223-11-9.493-17.478"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.052 296.211c3.381-1.426 1.059-2.404 8.474-5.581 1.589 3.748 9.33 5.174 11.815 6.966 3.503 6.315 0 14.993-3.585 16.5-6.641 2.486-13.282.693-18.945.489-7.333-.285-8.596-13.729 2.241-18.374Z"
        fill="#FFB784"
      />
      <path
        d="M157.533 314.585c-5.907 2.485-12.059.245-17.722 0"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m141.604 296.252-16.011-1.263.488-6.274 16.012 1.263c1.751.122 3.014 1.629 2.892 3.381-.122 1.711-1.629 3.015-3.381 2.893Z"
        fill="#FFB784"
      />
      <path
        d="m129.341 295.193 13.77 1.059c1.752.122 3.015 1.629 2.893 3.381-.123 1.752-1.63 3.015-3.382 2.893l-11.285-.896a3.006 3.006 0 0 1-2.77-3.219l.203-2.77c.082-.245.326-.448.571-.448Z"
        fill="#FFB784"
      />
      <path
        d="m142.133 308.637-8.27-.652c-1.752-.122-3.015-1.629-2.893-3.381.123-1.752 1.63-3.015 3.382-2.893l8.27.652c1.752.122 3.015 1.63 2.893 3.381a3.172 3.172 0 0 1-3.382 2.893Z"
        fill="#FFB784"
      />
      <path
        d="m143.111 314.83-7.659-.612c-1.752-.122-3.015-1.629-2.893-3.381.122-1.752 1.63-3.015 3.382-2.893l7.659.611c1.752.123 3.015 1.63 2.893 3.382-.123 1.711-1.671 3.015-3.382 2.893Z"
        fill="#FFB784"
      />
      <path
        d="M146.493 311.937c-.123 1.752-1.63 3.015-3.382 2.893l-7.659-.612c-1.752-.122-3.015-1.629-2.893-3.381M144.985 293.359c-.122 1.752-1.629 3.015-3.381 2.893l-16.011-1.263M95.811 502.237c21.756 37.767 23.182 90.607 48.237 116.885"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M415.544 200.43c47.004 0 85.108-38.104 85.108-85.108 0-47.003-38.104-85.107-85.108-85.107-47.003 0-85.107 38.104-85.107 85.107 0 47.004 38.104 85.108 85.107 85.108Z"
        fill="#FFD330"
      />
      <path
        d="M470.422 218.356c15.616 0 28.274-12.659 28.274-28.275 0-15.615-12.658-28.274-28.274-28.274-15.615 0-28.274 12.659-28.274 28.274 0 15.616 12.659 28.275 28.274 28.275Z"
        fill="#fff"
      />
      <path
        d="M470.422 214.118c13.276 0 24.037-10.761 24.037-24.037 0-13.275-10.761-24.037-24.037-24.037-13.275 0-24.037 10.762-24.037 24.037 0 13.276 10.762 24.037 24.037 24.037Z"
        fill="#FFD330"
      />
      <path
        d="M442.678 370.685c.407-1.629-1.222-3.015-2.771-2.363-6.07 2.608-18.333 8.637-25.503 17.071-6.722 7.944-9.819 15.4-8.148 22.244.366 1.467 1.629 2.567 3.137 2.689 7.048.529 13.892-3.708 20.614-11.652 7.171-8.474 11.082-21.552 12.671-27.989Z"
        fill="#04B485"
      />
      <path d="M408.211 383.559v204.885" stroke="#008F69" strokeWidth={1.6} strokeMiterlimit={10} />
      <path
        d="M229.563 619.163h77.896v-14.707h-55.04c-8.149 0-22.856 3.707-22.856 14.707ZM273.278 219.822h-6.234c-4.481 0-17.477 16.541-18.863 26.441-5.622 2.322-22.937 34.752-23.181 45.874-13.322-5.459-39.641 5.459-49.093 5.459-9.451 0-12.751-1.018-17.396-2.363 1.467 6.03.204 17.03-3.341 21.797h73.578c8.922 0 11.896-4.36 14.748-10.226 2.852-5.867 34.711-64.248 34.711-64.248l-4.929-22.734Z"
        fill="#0A3A5C"
      />
      <path
        d="M155.211 317.07h73.578c8.922 0 11.896-4.359 14.748-10.226 2.852-5.866 34.711-64.248 34.711-64.248"
        stroke="#5995BE"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M275.681 219.822c-4.644 5.134-15.562 29.863-15.562 29.863l34.059 5.704s12.792-25.87 19.474-35.567h-37.971Z"
        fill="#0A3A5C"
      />
      <path
        d="M303.711 154.311c-1.833-9.126-11.855-16.093-23.996-16.093-11.489 0-16.989 3.178-19.759 7.986-2.852 3.137-4.523 6.926-4.523 11.081 0 1.996.408 3.911 1.141 5.745-1.018 6.314-2.933 10.714-1.141 17.07 1.386 4.848 4.645 15.93 1.752 24.974 21.43 12.63 47.708 1.793 62.456-8.352-8.922-12.874-13.689-34.263-15.93-42.411Z"
        fill="#00416A"
      />
      <path
        d="M294.178 141.926c-4.034-2.363-9.045-3.748-14.422-3.748M311.004 178.959c-3.585-9.859-5.908-19.678-7.293-24.648M314.467 199.981a96.83 96.83 0 0 0 5.214-3.3c-1.629-2.322-3.096-4.929-4.44-7.659M266.311 140.541c-3.015 1.385-4.97 3.34-6.315 5.663-2.852 3.137-4.522 6.926-4.522 11.081 0 1.996.407 3.911 1.141 5.745-1.019 6.314-2.934 10.714-1.141 17.07 1.385 4.848 4.645 15.93 1.752 24.974 16.581 9.778 36.055 5.5 50.844-1.63"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M308.111 219.374c-6.478-7.415-6.478-25.381-6.478-28.885 0-8.108-20.655.122-20.655 6.6 0 6.355 1.018 13.892 1.629 22.285h25.504Z"
        fill="#FFB784"
      />
      <path
        d="M280.978 197.089c0 6.355 1.018 13.892 1.629 22.285"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M327.259 219.822h-21.022c-.041.082-.122.122-.163.204-6.6 8.352-9.167 12.344-11.367 24.241h-27.663c2.241-11.897 4.767-15.889 11.367-24.241.041-.082.122-.122.163-.204h-5.296c-13.159 10.267-22.734 32.185-22.734 44.856 0 26.603 7.008 24.852 7.008 40.211v34.955h75.329c-2.933-10.551-5.988-21.837-5.988-29.537 0-11.855 4.481-34.955 6.6-45.059 2.118-10.063 17.477-45.426-6.234-45.426Z"
        fill="#FFE24D"
      />
      <path d="m222.357 198.725-11.036 2.545 1.409 6.113 11.036-2.545-1.409-6.113Z" fill="#E5F0F6" />
      <path d="m215.477 200.293-4.169.961 1.41 6.114 4.168-.962-1.409-6.113Z" fill="#7C7C7C" />
      <path d="m214.556 200.523-3.255.751 1.409 6.114 3.256-.751-1.41-6.114Z" fill="#7C7C7C" />
      <path d="m216.001 206.678 5.042-1.163-1.41-6.113-5.042 1.163 1.41 6.113Z" fill="#C6C6C6" />
      <path d="m225 223.774-.448.489-4.033.937-.652-.244-4.197-18.252 5.134-1.182L225 223.774Z" fill="#E6E7E8" />
      <path
        d="m225.163 223.122-1.059 1.263-1.345.285 2.2-2.566.204 1.018ZM224.674 220.963l-3.463 4.074-.692.163-.408-.163 4.319-5.093.244 1.019ZM224.185 218.804l-4.726 5.581-.244-1.018 4.726-5.582.244 1.019ZM223.696 216.604l-4.766 5.622-.204-1.019 4.726-5.622.244 1.019ZM223.167 214.444l-4.726 5.582-.204-1.019 4.726-5.581.204 1.018ZM222.678 212.285l-4.726 5.582-.245-1.019 4.726-5.581.245 1.018ZM222.189 210.126l-4.726 5.581-.244-1.018 4.725-5.582.245 1.019ZM221.7 207.967l-4.726 5.581-.244-1.018 4.726-5.582.244 1.019ZM221.17 205.807l-4.726 5.582-.203-1.019 3.952-4.685.896-.244.081.366ZM218.644 206.011l-2.688 3.219-.245-1.019 1.589-1.874 1.344-.326Z"
        fill="#7C7C7C"
      />
      <path d="m475.459 172.248-15.403 3.552 1.959 8.495 15.403-3.552-1.959-8.495Z" fill="#E6E7E8" />
      <path d="m465.888 174.452-5.836 1.346 1.959 8.495 5.836-1.346-1.959-8.495Z" fill="#7C7C7C" />
      <path d="m464.605 174.766-4.565 1.052 1.959 8.496 4.565-1.053-1.959-8.495Z" fill="#7C7C7C" />
      <path d="m466.578 183.322 7.027-1.621-1.959-8.495-7.027 1.62 1.959 8.496Z" fill="#C6C6C6" />
      <path d="m479.1 207.152-.611.692-5.582 1.263-.937-.285-5.866-25.422 7.129-1.67 5.867 25.422Z" fill="#E6E7E8" />
      <path
        d="m479.344 206.256-1.466 1.711-1.874.448 3.014-3.585.326 1.426ZM478.652 203.2l-4.808 5.704-.937.203-.611-.163 6.03-7.129.326 1.385ZM477.959 200.226l-6.559 7.781-.326-1.426 6.559-7.781.326 1.426ZM477.267 197.17l-6.6 7.782-.286-1.385 6.56-7.782.326 1.385ZM476.574 194.156l-6.6 7.822-.285-1.426 6.559-7.782.326 1.386ZM475.881 191.141l-6.6 7.781-.325-1.385 6.6-7.781.325 1.385ZM475.189 188.126l-6.6 7.781-.326-1.385 6.6-7.781.326 1.385ZM474.496 185.111l-6.6 7.782-.326-1.386 6.6-7.781.326 1.385ZM473.804 182.096l-6.6 7.782-.326-1.385 5.541-6.56 1.262-.285.123.448ZM470.259 182.422l-3.748 4.441-.326-1.385 2.2-2.608 1.874-.448Z"
        fill="#7C7C7C"
      />
      <path
        d="M228.789 209.678c.652 6.681 4.033 14.015 8.841 18.903-.489 5.541-6.03 2.323-9.33 0-2.933-2.077-6.885-14.911-1.833-19.8.814-.814 2.2-.285 2.322.897Z"
        fill="#FFB784"
      />
      <path
        d="M228.3 228.541c-2.933-2.078-6.885-14.911-1.833-19.8"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m234.696 234.122-18.903-9.9c-1.549-.815-2.119-2.689-1.345-4.237.815-1.548 2.689-2.118 4.237-1.344l18.904 9.9-2.893 5.581Z"
        fill="#FFB784"
      />
      <path
        d="m234.696 241.17-18.903-9.9c-1.549-.814-2.119-2.689-1.345-4.237.815-1.548 2.689-2.118 4.237-1.344l18.904 9.9-2.893 5.581Z"
        fill="#FFB784"
      />
      <path
        d="m234.696 248.178-18.903-9.9c-1.549-.815-2.119-2.689-1.345-4.237.815-1.548 2.689-2.119 4.237-1.345l18.904 9.9-2.893 5.582Z"
        fill="#FFB784"
      />
      <path
        d="m234.696 255.185-16.663-8.718c-1.548-.815-2.118-2.689-1.344-4.237.815-1.549 2.689-2.119 4.237-1.345l16.663 8.719-2.893 5.581Z"
        fill="#FFB784"
      />
      <path
        d="M227.648 230.333c3.667 0 1.915-1.792 9.982-1.792 0 4.074 7.333 23.507 8.922 26.115h-15.319c-7.211-7.171-24.2-24.323-3.585-24.323Z"
        fill="#FFB784"
      />
      <path d="M310.352 339.885h-51.863v264.571h51.863V339.885Z" fill="#FFD330" />
      <path
        d="M258.489 604.456V339.885"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m124.577 296.574 4.415 2.098 6.295-13.247-4.415-2.098-6.295 13.247Z"
        fill="#80B3D2"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
      />
      <path
        d="m130.821 283.338-22.887-10.876-6.295 13.247 22.887 10.876 6.295-13.247Z"
        fill="#E5F0F6"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
      />
      <path
        d="m138.701 287.082-3.459-1.644-1.644 3.459 3.459 1.643 1.644-3.458Z"
        fill="#80B3D2"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
      />
      <path d="m138.711 287.044-30.759-14.585" stroke="#0A3A5C" strokeWidth={1.6} strokeMiterlimit={10} />
      <path d="m112.51 280.601-2.091 4.056 12.929 6.663 2.09-4.056-12.928-6.663Z" fill="#C6C6C6" />
      <path
        d="m104.693 279.263-3.056 6.437 22.896 10.878 3.341-6.967"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
      />
      <path d="M110.396 319.596h-25.34v14.667h25.34v-14.667Z" fill="#E5F0F6" />
      <path
        d="M110.415 246.242h4.889v-14.667h-4.889v14.667ZM110.415 260.909h4.889v-14.667h-4.889v14.667ZM110.415 275.575h4.889v-14.666h-4.889v14.666Z"
        fill="#80B3D2"
      />
      <path
        d="M110.396 260.93h-25.34v14.666h25.34V260.93ZM110.396 246.263h-25.34v14.667h25.34v-14.667ZM110.396 231.596h-25.34v14.667h25.34v-14.667ZM110.396 290.263h-25.34v14.667h25.34v-14.667ZM110.396 304.93h-25.34v14.666h25.34V304.93ZM110.396 334.263h-25.34v14.667h25.34v-14.667ZM110.396 348.93h-25.34v14.666h25.34V348.93Z"
        fill="#E5F0F6"
      />
      <path
        d="M110.415 304.909h4.889v-14.667h-4.889v14.667ZM110.415 319.575h4.889v-14.666h-4.889v14.666ZM110.415 334.242h4.889v-14.667h-4.889v14.667ZM110.415 348.909h4.889v-14.667h-4.889v14.667ZM110.415 363.575h4.889v-14.666h-4.889v14.666ZM119.115 231.596h-3.83v3.83h3.83v-3.83ZM119.115 246.263h-3.83v3.83h3.83v-3.83ZM119.115 260.93h-3.83v3.829h3.83v-3.829Z"
        fill="#80B3D2"
      />
      <path
        d="M119.115 290.263h-3.83v3.83h3.83v-3.83ZM119.115 304.93h-3.83v3.829h3.83v-3.829ZM119.115 319.596h-3.83v3.83h3.83v-3.83ZM119.115 334.263h-3.83v3.83h3.83v-3.83ZM119.115 348.93h-3.83v3.829h3.83v-3.829Z"
        fill="#80B3D2"
      />
      <path
        d="m102.37 352.759-3.503 8.148M89.13 353.004l4.563 7.618M104.041 356.263l1.711 4.644M97.726 353.167l-4.033 2.852"
        stroke="#636363"
        strokeWidth={1.6}
        strokeMiterlimit={10}
      />
      <path
        d="M100.456 346.444a1.955 1.955 0 1 0 0-3.91 1.955 1.955 0 0 0 0 3.91ZM93.937 346.444a1.955 1.955 0 1 0 .001-3.91 1.955 1.955 0 0 0-.001 3.91ZM96.993 341.148a1.955 1.955 0 1 0 0-3.91 1.955 1.955 0 0 0 0 3.91ZM104.326 341.148a1.955 1.955 0 1 0 0-3.91 1.955 1.955 0 0 0 0 3.91ZM90.067 341.148a1.955 1.955 0 1 0 0-3.91 1.955 1.955 0 0 0 0 3.91Z"
        fill="#C6C6C6"
        stroke="#636363"
        strokeWidth={1.6}
        strokeMiterlimit={10}
      />
      <path
        d="M88.519 322.856h17.64M89.089 325.585h17.844M87.826 328.274h17.641M89.781 330.963h17.886"
        stroke="#00416A"
        strokeWidth={1.6}
        strokeMiterlimit={10}
      />
      <path d="m101.651 309.163-1.68.325 1.262 6.52 1.68-.325-1.262-6.52Z" fill="#AEAEAE" />
      <path d="m103.753 315.559-3.36.651.379 1.96 3.36-.651-.379-1.96Z" fill="#7C7C7C" />
      <path d="m96.525 316.153 1.707-.123-.478-6.623-1.706.123.477 6.623Z" fill="#AEAEAE" />
      <path d="m95.209 309.582 3.413-.246-.144-1.991-3.413.246.144 1.991Z" fill="#7C7C7C" />
      <path d="m92.5 309.402-1.657.43 1.668 6.427 1.657-.43-1.669-6.427Z" fill="#AEAEAE" />
      <path d="m94.973 315.637-3.313.86.502 1.932 3.312-.859-.501-1.933Z" fill="#7C7C7C" />
      <path d="m88.152 315.478 1.707-.123-.477-6.623-1.707.123.477 6.623Z" fill="#AEAEAE" />
      <path d="m86.795 308.91 3.413-.246-.143-1.992-3.413.246.143 1.992Z" fill="#7C7C7C" />
      <path d="m105.593 316.214 1.707-.123-.478-6.623-1.706.123.477 6.623Z" fill="#AEAEAE" />
      <path
        d="m104.277 309.643 3.413-.246-.144-1.991-3.413.246.144 1.991ZM107.83 292.667h-1.589v9.533h1.589v-9.533Z"
        fill="#7C7C7C"
      />
      <path d="M104.122 292.667h-1.589v9.533h1.589v-9.533Z" fill="#C6C6C6" />
      <path d="M100.415 292.667h-1.59v9.533h1.59v-9.533Z" fill="#7C7C7C" />
      <path d="M96.707 292.667H95.12v9.533h1.588v-9.533Z" fill="#C6C6C6" />
      <path d="M93 292.667h-1.589v9.533H93v-9.533Z" fill="#7C7C7C" />
      <path d="M89.293 292.667h-1.59v9.533h1.59v-9.533Z" fill="#C6C6C6" />
      <path
        d="M91.86 264.148h-2.242l-1.14 1.956 1.14 1.915h2.241l1.1-1.915-1.1-1.956Z"
        fill="#5995BE"
        stroke="#4A4A4A"
        strokeWidth={1.6}
        strokeMiterlimit={10}
      />
      <path
        d="M90.678 269.648h-2.241l-1.14 1.915 1.14 1.956h2.24l1.1-1.956-1.1-1.915Z"
        fill="#7C7C7C"
        stroke="#4A4A4A"
        strokeWidth={1.6}
        strokeMiterlimit={10}
      />
      <path
        d="M97.644 263.089h-2.24l-1.1 1.955 1.1 1.915h2.24l1.141-1.915-1.14-1.955Z"
        fill="#5995BE"
        stroke="#4A4A4A"
        strokeWidth={1.6}
        strokeMiterlimit={10}
      />
      <path
        d="M101.596 267h-2.24l-1.141 1.956 1.14 1.914h2.241l1.1-1.914-1.1-1.956Z"
        fill="#7C7C7C"
        stroke="#4A4A4A"
        strokeWidth={1.6}
        strokeMiterlimit={10}
      />
      <path
        d="M106.281 270.137h-2.24l-1.141 1.956 1.141 1.914h2.24l1.1-1.914-1.1-1.956Z"
        fill="#5995BE"
        stroke="#4A4A4A"
        strokeWidth={1.6}
        strokeMiterlimit={10}
      />
      <path
        d="M105.507 263.089h-2.24l-1.141 1.955 1.141 1.915h2.24l1.1-1.915-1.1-1.955Z"
        fill="#7C7C7C"
        stroke="#4A4A4A"
        strokeWidth={1.6}
        strokeMiterlimit={10}
      />
      <path
        d="M96.504 268.548h-2.241l-1.14 1.956 1.14 1.955h2.24l1.1-1.955-1.1-1.956Z"
        fill="#5995BE"
        stroke="#4A4A4A"
        strokeWidth={1.6}
        strokeMiterlimit={10}
      />
      <path
        d="m90.515 253.841 1.1 1.915 1.14 1.955h-4.48l1.1-1.955 1.14-1.915Z"
        fill="#959595"
        stroke="#4A4A4A"
        strokeWidth={1.6}
        strokeMiterlimit={10}
      />
      <path
        d="m93.733 253.963-1.14-1.956-1.1-1.914h4.481l-1.14 1.914-1.1 1.956Z"
        fill="#80B3D2"
        stroke="#4A4A4A"
        strokeWidth={1.6}
        strokeMiterlimit={10}
      />
      <path
        d="m98.052 253.841 1.1 1.915 1.141 1.955h-4.481l1.1-1.955 1.14-1.915ZM101.27 253.963l-1.14-1.956-1.1-1.914h4.481l-1.141 1.914-1.1 1.956Z"
        fill="#959595"
        stroke="#4A4A4A"
        strokeWidth={1.6}
        strokeMiterlimit={10}
      />
      <path
        d="m105.589 253.841 1.141 1.915 1.1 1.955h-4.482l1.141-1.955 1.1-1.915Z"
        fill="#80B3D2"
        stroke="#4A4A4A"
        strokeWidth={1.6}
        strokeMiterlimit={10}
      />
      <path
        d="M91.493 242.881a3.748 3.748 0 1 0 0-7.496 3.748 3.748 0 0 0 0 7.496Z"
        fill="#fff"
        stroke="#636363"
        strokeWidth={1.6}
        strokeMiterlimit={10}
      />
      <path
        d="M95.811 242.881a3.748 3.748 0 1 0 0-7.496 3.748 3.748 0 0 0 0 7.496Z"
        fill="#80B3D2"
        stroke="#636363"
        strokeWidth={1.6}
        strokeMiterlimit={10}
      />
      <path
        d="M100.17 242.881a3.749 3.749 0 1 0 0-7.498 3.749 3.749 0 0 0 0 7.498Z"
        fill="#fff"
        stroke="#636363"
        strokeWidth={1.6}
        strokeMiterlimit={10}
      />
      <path
        d="M104.489 242.881a3.748 3.748 0 1 0 0-7.496 3.748 3.748 0 0 0 0 7.496Z"
        fill="#AEAEAE"
        stroke="#636363"
        strokeWidth={1.6}
        strokeMiterlimit={10}
      />
      <path
        d="M110.396 238.93v7.333M110.396 253.596v7.334M110.396 268.263v7.333h-25.34v-7.333M110.396 297.596v7.334M110.396 312.263v7.333M110.396 326.93v7.333M110.396 341.596v7.334M119.115 290.263h-34.06v-7.333M119.115 260.93h-34.06v-7.334M119.115 231.596h-34.06M85.056 356.263v7.333h25.34v-7.333M119.115 246.263h-34.06v-7.333M119.115 304.93h-34.06v-7.334M119.115 319.596h-34.06v-7.333"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
      />
      <path d="M119.115 334.263h-34.06v-7.333" stroke="#00416A" strokeWidth={1.6} strokeMiterlimit={10} />
      <path d="M119.115 348.93h-34.06v-7.334" stroke="#0A3A5C" strokeWidth={1.6} strokeMiterlimit={10} />
      <path d="m109.163 276.501-4.031 7.823 4.309 2.221 4.032-7.823-4.31-2.221Z" fill="#7C7C7C" />
      <path
        d="M162.952 362.863H141.4a.818.818 0 0 1-.815-.815v-23.181c0-.449.367-.815.815-.815h21.552c.448 0 .815.366.815.815v23.181a.818.818 0 0 1-.815.815Z"
        fill="#3FA9F5"
      />
      <path
        d="M146.656 362.863h-5.215a.818.818 0 0 1-.815-.815v-23.141c0-.448.367-.814.815-.814h5.255v24.77h-.04Z"
        fill="#00416A"
      />
      <path
        d="M162.952 362.863H141.4a.818.818 0 0 1-.815-.815v-.367h23.182v.367a.818.818 0 0 1-.815.815Z"
        fill="#0A3A5C"
      />
      <path
        d="M141.4 338.093h21.552c.448 0 .815.366.815.814v.367h-23.182v-.367c0-.448.367-.814.815-.814ZM169.959 341.189h-6.192v3.055h4.563a3.139 3.139 0 0 1 3.137 3.137v6.193a3.138 3.138 0 0 1-3.137 3.137h-4.563v3.056h6.192a4.555 4.555 0 0 0 4.563-4.563v-9.452c0-2.526-2.078-4.563-4.563-4.563Z"
        fill="#FFD330"
      />
      <path
        d="M169.959 359.4h-6.192v.367h6.192a4.555 4.555 0 0 0 4.563-4.563v-.367c0 2.526-2.078 4.563-4.563 4.563ZM171.467 347.381v-.366a3.138 3.138 0 0 0-3.137-3.137h-4.563v.366h4.563c1.711-.04 3.137 1.386 3.137 3.137Z"
        fill="#D6B200"
      />
      <path
        d="M143.03 347.87c0-.04.04-.081.081-.081h2.078c1.141 0 1.67.53 1.67 1.344 0 .53-.244.897-.611 1.1.367.123.693.571.693 1.141 0 .978-.693 1.467-1.752 1.467h-2.037a.088.088 0 0 1-.082-.082v-4.889h-.04Zm1.914 1.956c.408 0 .612-.163.612-.53 0-.366-.204-.529-.612-.529h-.529c-.041 0-.041 0-.041.04v.937c0 .041 0 .041.041.041h.529v.041Zm-.529 2.037h.611c.407 0 .611-.204.611-.57 0-.367-.204-.571-.611-.571h-.611c-.041 0-.041 0-.041.041v1.059c0 .041 0 .041.041.041ZM148.652 352.881c-.041 0-.082-.04-.082-.081v-1.874l-1.548-3.015c0-.041 0-.081.041-.081h1.181c.041 0 .082.04.123.081l.855 1.711h.041l.856-1.711c.04-.041.081-.081.122-.081h1.14c.041 0 .082.04.041.081l-1.548 3.015v1.874c0 .041-.041.081-.081.081h-1.141ZM151.87 347.87c0-.04.041-.081.082-.081h1.181c.041 0 .082.041.082.081v1.997l1.466-1.997c.041-.04.082-.081.123-.081h1.303c.041 0 .082.041.041.081l-1.507 1.956 1.589 2.933c.04.041 0 .082-.041.082h-1.345c-.081 0-.081-.041-.122-.082l-.978-1.915-.529.734v1.181a.088.088 0 0 1-.082.082h-1.181a.088.088 0 0 1-.082-.082v-4.889ZM156.515 351.7c-.122-.407-.163-.693-.163-1.385 0-.693.041-.978.163-1.385.285-.815 1.018-1.263 1.915-1.263.896 0 1.629.407 1.914 1.263.123.407.163.692.163 1.385 0 .692-.04.978-.163 1.385-.285.815-1.018 1.263-1.914 1.263-.897 0-1.63-.407-1.915-1.263Zm2.566-.367c.041-.163.082-.407.082-1.018s0-.856-.082-1.019c-.081-.285-.325-.448-.651-.448s-.571.163-.652.448c-.041.163-.082.408-.082 1.019s0 .855.082 1.018c.081.286.326.448.652.448s.57-.162.651-.448Z"
        fill="#0A3A5C"
      />
      <path
        d="M143.437 347.463c0-.041.041-.082.082-.082h2.077c1.141 0 1.671.53 1.671 1.345 0 .53-.245.896-.611 1.1.366.122.692.57.692 1.141 0 .977-.692 1.466-1.752 1.466h-2.037c-.04 0-.081-.04-.081-.081v-4.889h-.041Zm1.915 1.955c.407 0 .611-.162.611-.529s-.204-.53-.611-.53h-.53c-.041 0-.041 0-.041.041v.937c0 .041 0 .041.041.041h.53v.04Zm-.53 2.038h.611c.408 0 .611-.204.611-.571s-.203-.57-.611-.57h-.611c-.041 0-.041 0-.041.041v1.059c0 .041 0 .041.041.041ZM149.059 352.474c-.04 0-.081-.041-.081-.081v-1.874l-1.548-3.015c0-.041 0-.082.04-.082h1.182c.041 0 .081.041.122.082l.856 1.711h.04l.856-1.711c.041-.041.081-.082.122-.082h1.141c.041 0 .081.041.041.082l-1.549 3.015v1.874c0 .04-.04.081-.081.081h-1.141ZM152.278 347.463c0-.041.041-.082.081-.082h1.182c.04 0 .081.041.081.082v1.996l1.467-1.996c.041-.041.081-.082.122-.082h1.304c.041 0 .081.041.041.082l-1.508 1.955 1.589 2.934c.041.041 0 .081-.041.081h-1.344c-.082 0-.082-.04-.122-.081l-.978-1.915-.53.733v1.182c0 .041-.041.081-.081.081h-1.182c-.04 0-.081-.04-.081-.081v-4.889ZM156.922 351.293c-.122-.408-.163-.693-.163-1.386 0-.692.041-.977.163-1.385.285-.815 1.019-1.263 1.915-1.263.896 0 1.63.408 1.915 1.263.122.408.163.693.163 1.385 0 .693-.041.978-.163 1.386-.285.814-1.019 1.263-1.915 1.263-.896 0-1.63-.408-1.915-1.263Zm2.567-.367c.041-.163.081-.407.081-1.019 0-.611 0-.855-.081-1.018-.082-.285-.326-.448-.652-.448s-.57.163-.652.448c-.041.163-.081.407-.081 1.018 0 .612 0 .856.081 1.019.082.285.326.448.652.448s.57-.163.652-.448Z"
        fill="#FFD330"
      />
      <path
        d="M332.841 339.885h-56.263v264.571h51.863V410.489c0-15.156 10.022-33.326 10.022-46.363-.041-4.808-2.811-14.178-5.622-24.241Z"
        fill="#FFE24D"
      />
      <path
        d="m227.078 230.048-11.326-5.826c-1.548-.815-2.119-2.689-1.345-4.237M226.467 236.526l-10.674-5.296c-1.549-.815-2.119-2.689-1.345-4.237M226.874 244.226l-11.081-5.989c-1.549-.815-2.119-2.689-1.345-4.237M229.563 252.496l-11.489-6.029c-1.548-.815-2.118-2.689-1.344-4.237M220.111 219.374l15.074 7.904"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M308.315 189.022c-4.971 9.493-26.93 18.945-36.422 13.974-9.493-4.97-14.015-33.529-9.045-43.022 4.971-9.493 18.822 2.363 28.274 7.333 9.493 4.971 22.163 12.223 17.193 21.715Z"
        fill="#FFB784"
      />
      <path
        d="M308.315 189.022c-4.971 9.493-26.93 18.945-36.422 13.974-9.493-4.97-14.015-33.529-9.045-43.022 4.971-9.493 18.822 2.363 28.274 7.333 9.493 4.971 22.163 12.223 17.193 21.715Z"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M269.285 179.896v11.448c0 .408.245.734.611.897l.611.244a.895.895 0 0 0 .652 0l3.504-1.304a.985.985 0 0 0 .57-1.222l-4.114-10.389a1.01 1.01 0 0 0-.652-.57.929.929 0 0 0-1.182.896ZM277.23 181.037a1.63 1.63 0 1 0 0-3.259 1.63 1.63 0 0 0 0 3.259ZM265.496 182.626a1.63 1.63 0 1 0 0-3.26 1.63 1.63 0 0 0 0 3.26Z"
        fill="#0A3A5C"
      />
      <path
        d="M276.904 195.174c.855-.53 3.666-1.833 5.011-1.956 1.344-.162 2.118.123 2.118.123"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M282.159 167.593c1.548 5.459 7.578 20.614 12.793 25.259 3.178-.571 8.23-2.2 11.407-4.074 3.178-1.874-.57-19.434-2.648-21.837-3.463-3.993-23.141-4.93-21.552.652Z"
        fill="#00416A"
      />
      <path
        d="M293.974 180.793c1.304 4.318 3.748 13.688 8.148 17.355 2.689-.448 6.967-1.752 9.656-3.218 2.689-1.467-.489-15.36-2.241-17.234-2.893-3.137-16.907-1.303-15.563 3.097ZM281.304 145.022c5.255 3.097 7.74 12.915 7.211 19.352-5.419 8.596-28.967 14.993-38.704 8.759-3.911-6.518-4.196-13.933-3.3-20.207 6.437-11.856 27.704-12.1 34.793-7.904Z"
        fill="#00416A"
      />
      <path
        d="M286.315 151.378c1.792 4.115 2.526 9.166 2.241 12.996-5.419 8.596-28.967 14.993-38.704 8.759-3.911-6.518-4.196-13.933-3.3-20.207 5.459-10.063 21.552-11.774 30.678-9.493"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M302.244 170.159c-1.507 0-2.851.897-3.788 2.363.04 3.708.896 7.782 3.34 11.285.163 0 .285.041.448.041 2.771 0 5.011-3.055 5.011-6.844s-2.24-6.845-5.011-6.845Z"
        fill="#FFB784"
      />
      <path
        d="M298.578 169.507c-.448 4.849.244 11.082 4.44 15.889M282.893 169.507c2.729 8.678 6.6 18.456 12.059 23.345 2.73-.489 6.315-1.467 9.859-2.73"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M312.267 212.407h-38.745v7.415h38.745v-7.415Z" fill="#5995BE" />
      <path d="M314.833 212.407h-41.311v7.415h41.311v-7.415Z" fill="#5995BE" />
      <path d="M314.833 212.407h-41.311v7.415h41.311v-7.415Z" fill="#FFD330" />
      <path
        d="M273.522 212.163v8.393"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M281.711 251.478c0-.041.041-.082.082-.082h2.077c1.141 0 1.671.53 1.671 1.345 0 .529-.245.896-.611 1.1.366.122.692.57.692 1.14 0 .978-.692 1.467-1.752 1.467h-2.037c-.04 0-.081-.041-.081-.081v-4.889h-.041Zm1.874 1.955c.408 0 .611-.163.611-.529 0-.367-.203-.53-.611-.53h-.529c-.041 0-.041 0-.041.041v.937c0 .041 0 .041.041.041h.529v.04Zm-.529 2.078h.611c.407 0 .611-.204.611-.57 0-.367-.204-.571-.611-.571h-.611c-.041 0-.041 0-.041.041v1.059l.041.041ZM287.293 256.489a.088.088 0 0 1-.082-.082v-1.874l-1.548-3.014c0-.041 0-.082.041-.082h1.181c.041 0 .082.041.122.082l.856 1.711h.041l.855-1.711c.041-.041.082-.082.122-.082h1.141c.041 0 .082.041.041.082l-1.548 3.014v1.874a.088.088 0 0 1-.082.082h-1.14ZM290.552 251.478c0-.041.041-.082.081-.082h1.182c.041 0 .081.041.081.082v1.996l1.467-1.996c.041-.041.081-.082.122-.082h1.304c.041 0 .081.041.041.082l-1.508 1.955 1.589 2.934c.041.04 0 .081-.041.081h-1.344c-.082 0-.082-.041-.122-.081l-.978-1.915-.53.733v1.182c0 .04-.04.081-.081.081h-1.182c-.04 0-.081-.041-.081-.081v-4.889ZM295.196 255.348c-.122-.407-.163-.692-.163-1.385 0-.693.041-.978.163-1.385.285-.815 1.019-1.263 1.915-1.263.896 0 1.63.407 1.915 1.263.122.407.163.692.163 1.385 0 .693-.041.978-.163 1.385-.285.815-1.019 1.263-1.915 1.263-.937-.041-1.67-.448-1.915-1.263Zm2.526-.407c.041-.163.082-.408.082-1.019s0-.855-.082-1.018c-.081-.285-.326-.448-.652-.448s-.57.163-.651.448c-.041.163-.082.407-.082 1.018s0 .856.082 1.019c.081.285.325.448.651.448.367 0 .571-.163.652-.448Z"
        fill="#0A3A5C"
      />
      <path
        d="M280.896 251.478c0-.041.041-.082.082-.082h2.078c1.14 0 1.67.53 1.67 1.345 0 .529-.245.896-.611 1.1.366.122.692.57.692 1.14 0 .978-.692 1.467-1.751 1.467h-2.038c-.04 0-.081-.041-.081-.081v-4.889h-.041Zm1.874 1.955c.408 0 .611-.163.611-.529 0-.367-.203-.53-.611-.53h-.529c-.041 0-.041 0-.041.041v.937c0 .041 0 .041.041.041h.529v.04Zm-.529 2.078h.611c.407 0 .611-.204.611-.57 0-.367-.204-.571-.611-.571h-.611c-.041 0-.041 0-.041.041v1.059l.041.041ZM286.478 256.489a.088.088 0 0 1-.082-.082v-1.874l-1.548-3.014c0-.041 0-.082.041-.082h1.181c.041 0 .082.041.123.082l.855 1.711h.041l.855-1.711c.041-.041.082-.082.123-.082h1.14c.041 0 .082.041.041.082l-1.548 3.014v1.874c0 .041-.041.082-.081.082h-1.141ZM289.737 251.478c0-.041.041-.082.082-.082H291c.041 0 .081.041.081.082v1.996l1.467-1.996c.041-.041.082-.082.122-.082h1.304c.041 0 .082.041.041.082l-1.508 1.955 1.589 2.934c.041.04 0 .081-.04.081h-1.345c-.081 0-.081-.041-.122-.081l-.978-1.915-.53.733v1.182c0 .04-.04.081-.081.081h-1.181c-.041 0-.082-.041-.082-.081v-4.889ZM294.381 255.348c-.122-.407-.162-.692-.162-1.385 0-.693.04-.978.162-1.385.286-.815 1.019-1.263 1.915-1.263.897 0 1.63.407 1.915 1.263.122.407.163.692.163 1.385 0 .693-.041.978-.163 1.385-.285.815-1.018 1.263-1.915 1.263-.937-.041-1.67-.448-1.915-1.263Zm2.526-.407c.041-.163.082-.408.082-1.019s0-.855-.082-1.018c-.081-.285-.326-.448-.651-.448-.326 0-.571.163-.652.448-.041.163-.082.407-.082 1.018s0 .856.082 1.019c.081.285.326.448.652.448.366 0 .57-.163.651-.448Z"
        fill="#00416A"
      />
      <path
        d="M329.093 401.77a3.253 3.253 0 0 1-2.404 1.1h-3.422c-.897 0-1.793-.366-2.404-1.059v-.041c-.57-.611-.856-1.426-.856-2.24l1.182-60.419h7.537l1.181 60.419c.041.855-.285 1.629-.814 2.24Z"
        fill="#0A3A5C"
      />
      <path
        d="M332.963 323.344h-10.674a1.27 1.27 0 0 0-1.263 1.263h-.082a4.419 4.419 0 0 1-8.637-.774.57.57 0 0 0-.57-.489h-5.011a.557.557 0 0 0-.57.571v12.1c0 .326.244.57.57.57h5.011a.57.57 0 0 0 .57-.489c.245-2.2 2.119-3.952 4.4-3.952a4.411 4.411 0 0 1 4.319 3.545v3.463h7.863l1.222-4.808a7.783 7.783 0 0 1 7.252-4.929c4.196 0 7.578 3.3 7.781 7.415l1.589-.693c-.489-7.17-6.477-12.793-13.77-12.793Z"
        fill="#00416A"
      />
      <path d="M304.2 327.663Z" stroke="#0A3A5C" strokeWidth={1.6} strokeMiterlimit={10} />
      <path d="M250.544 619.163h77.897v-14.707H273.4c-8.148 0-22.856 3.707-22.856 14.707Z" fill="#00416A" />
      <path
        d="M273.074 268.956c.407-9.412 3.137-19.108 5.296-24.689h-11.326c2.241-11.897 4.767-15.889 11.367-24.241.041-.082.122-.122.163-.204h-5.296c-13.159 10.267-22.734 32.185-22.734 44.856 0 26.603 7.008 24.852 7.008 40.211v34.955h19.026c0-11.081-4.685-44.977-3.504-70.888Z"
        fill="#FFD330"
      />
      <path
        d="M273.278 219.822c-13.16 10.267-22.734 32.185-22.734 44.856 0 26.603 7.008 24.852 7.008 40.211v34.955"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M205.241 619.163V363.596H85.056v-144.67H33.64v93.948c0 69.952 19.474 111.182 67.629 159.296 48.156 48.197 26.604 146.993 103.971 146.993Z"
        fill="#00416A"
      />
      <path
        d="M205.241 619.163V363.596H85.056V218.763"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M235.226 363.596H85.056v8.637h150.17v-8.637Z"
        fill="#0A3A5C"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M297.437 339.681c4.93-1.303 8.556-5.785 8.556-11.122 0-2.281-.652-4.4-1.793-6.192a1.236 1.236 0 0 0-.204-.286l-4.766 6.763c-.611.897-1.752 1.304-2.771.978-1.955-.529-3.666-1.711-4.603-3.544-.449-.815-.326-1.834.203-2.608l4.563-6.477c-.611-.123-1.222-.163-1.833-.204h-.245c-6.355 0-11.488 5.174-11.488 11.489 0 4.929 3.096 9.126 7.455 10.755v42.615c-3.585 1.059-6.192 4.4-6.192 8.352 0 1.67.488 3.219 1.303 4.522l3.952-5.622a2.13 2.13 0 0 1 2.322-.815c1.589.448 3.056 1.426 3.83 2.933.367.693.285 1.508-.163 2.16l-3.83 5.418c.408.082.856.122 1.304.122 4.807 0 8.719-3.911 8.719-8.718 0-3.137-1.671-5.907-4.197-7.456l-.122-43.063Z"
        fill="#00416A"
      />
      <path
        d="M291.815 388.367c1.589.448 3.055 1.426 3.829 2.933.367.693.286 1.507-.163 2.159l-3.829 5.419c.407.081.855.122 1.304.122 4.807 0 8.718-3.911 8.718-8.719 0-3.137-1.67-5.907-4.196-7.455v-43.145"
        stroke="#80B3D2"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M290.43 339.315v42.615c-3.586 1.059-6.193 4.4-6.193 8.351a8.51 8.51 0 0 0 1.304 4.523"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="m337.268 334.07-5.416 1.509 8.289 29.748 5.415-1.509-8.288-29.748Z" fill="#5995BE" />
      <path
        d="M297.967 192.159c1.14 2.404 2.526 4.604 4.196 5.989 2.078-.367 5.133-1.222 7.659-2.281M278.9 212.163v8.393M284.278 212.163v8.393M289.656 216.115v4.441M295.074 216.115v4.441M300.452 216.115v4.441M305.83 216.115v4.441M311.248 216.115v4.441M273.522 219.822h41.311"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M328.441 219.822c-8.923 0-24.078 19.637-25.952 39.234-8.433 4.074-23.426 27.459-23.426 38.54 0-11.692-25.096-30.433-32.552-44h-16.989c13.811 24.893 26.034 79.282 44.082 79.282 13.403 0 19.27-15.156 27.785-24.363 8.922-9.656 35.241-42.574 41.759-49.093 6.559-6.478 8.8-39.6-14.707-39.6Z"
        fill="#0A3A5C"
      />
      <path
        d="M229.563 253.596c13.811 24.893 26.033 79.282 44.081 79.282 13.404 0 19.271-15.156 27.786-24.363 8.922-9.656 35.24-42.574 41.759-49.093"
        stroke="#5995BE"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M387.637 332.307c-1.181-1.14-3.218-.448-3.422 1.182-1.059 7.904-2.771 26.522 2.2 40.659 4.441 12.671 10.715 20.941 19.433 23.548 1.874.571 3.911-.122 5.011-1.752 5.174-7.455 4.93-17.844.489-30.514-5.011-14.137-17.967-27.623-23.711-33.123Z"
        fill="#008F69"
      />
      <path
        d="M361.889 390.933c-1.548-.611-3.137.774-2.73 2.404 1.997 7.741 7.415 25.626 17.356 36.87 8.881 10.063 17.803 15.36 26.848 14.504a4.555 4.555 0 0 0 3.993-3.504c1.955-8.881-2.16-18.374-11.041-28.437-9.941-11.285-27.011-18.903-34.426-21.837Z"
        fill="#8FDBC7"
      />
      <path
        d="M369.548 520.856c-1.548-.612-3.178.774-2.729 2.403 1.792 6.804 6.355 21.185 14.422 30.311 7.455 8.434 14.952 12.915 22.57 12.182 1.63-.163 3.015-1.345 3.341-2.974 1.67-7.456-1.833-15.441-9.289-23.915-8.107-9.126-21.796-15.4-28.315-18.007Z"
        fill="#008F69"
      />
      <path
        d="M369.385 470.541c-1.548-.611-3.178.774-2.729 2.403 1.792 6.804 6.355 21.267 14.463 30.475 7.496 8.474 15.033 12.955 22.651 12.222 1.63-.163 3.015-1.345 3.382-2.974 1.67-7.497-1.833-15.523-9.33-23.997-8.107-9.207-21.918-15.522-28.437-18.129Z"
        fill="#04B485"
      />
      <path
        d="M453.474 433.915c.367-1.63-1.263-2.974-2.77-2.322-7.334 3.096-24.241 11.122-33.897 22.57-8.637 10.267-12.548 19.881-10.389 28.681.489 1.915 2.119 3.3 4.075 3.423 9.044.652 17.885-4.848 26.522-15.115 9.615-11.489 14.626-29.496 16.459-37.237Z"
        fill="#008F69"
      />
      <path
        d="M450.459 505.659c.652-1.548-.733-3.177-2.363-2.77-6.477 1.63-19.678 5.704-28.192 13.037-7.986 6.844-12.223 13.811-11.611 20.9a3.598 3.598 0 0 0 2.729 3.178c6.926 1.629 14.422-1.508 22.408-8.393 8.433-7.333 14.422-19.8 17.029-25.952Z"
        fill="#8FDBC7"
      />
      <path
        d="M454.248 578.096c.734 3.3 1.1 6.763 1.1 10.308 0 6.355-1.222 12.466-3.504 18.007M362.093 604.456c-1.793-5.012-2.73-10.43-2.73-16.052 0-13.037 5.174-24.852 13.607-33.489"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M439.989 97.722c0-17.11-13.852-30.963-30.963-30.963s-30.963 13.852-30.963 30.963h15.644c0-8.433 6.845-15.318 15.319-15.318s15.318 6.844 15.318 15.318c0 4.237-1.711 8.067-4.522 10.837-7.415 7.415-18.904 7.13-18.904 22.163v11.367h17.438v-5.541c0-10.674 3.096-7.496 12.222-16.622 5.785-5.622 9.411-13.485 9.411-22.204ZM418.315 149.83h-17.396v14.055h17.396V149.83Z"
        fill="#00416A"
      />
      <path
        d="M448.137 172.726c5.174-6.641 13.241-10.878 22.285-10.878 8.882 0 16.826 4.115 22 10.552M498.696 190.081c0 15.604-12.67 28.275-28.274 28.275-15.603 0-28.274-12.671-28.274-28.275"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M473.804 64.111c15.638 0 28.315-12.677 28.315-28.315 0-15.637-12.677-28.315-28.315-28.315-15.638 0-28.315 12.678-28.315 28.315 0 15.638 12.677 28.315 28.315 28.315Z"
        fill="#fff"
      />
      <path
        d="M473.804 59.507c13.095 0 23.711-10.615 23.711-23.71 0-13.096-10.616-23.712-23.711-23.712-13.096 0-23.711 10.616-23.711 23.711 0 13.096 10.615 23.711 23.711 23.711Z"
        fill="#FFD330"
      />
      <path
        d="M399.452 241.211c15.615 0 28.274-12.659 28.274-28.274s-12.659-28.274-28.274-28.274c-15.616 0-28.274 12.659-28.274 28.274s12.658 28.274 28.274 28.274Z"
        fill="#fff"
      />
      <path
        d="M399.452 237.544c13.59 0 24.607-11.017 24.607-24.607s-11.017-24.607-24.607-24.607-24.608 11.017-24.608 24.607 11.018 24.607 24.608 24.607Z"
        fill="#FFD330"
      />
      <path
        d="M464.841 62.563c-11.204-3.748-19.311-14.34-19.311-26.807 0-15.604 12.67-28.275 28.274-28.275 7.211 0 13.811 2.73 18.822 7.17M501.141 28.504c.611 2.322.937 4.726.937 7.21 0 12.182-7.7 22.53-18.456 26.523"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M385.03 57.348c15.615 0 28.274-12.659 28.274-28.274S400.645.8 385.03.8c-15.616 0-28.274 12.659-28.274 28.274s12.658 28.274 28.274 28.274Z"
        fill="#fff"
      />
      <path
        d="M385.03 53.274c13.365 0 24.2-10.835 24.2-24.2 0-13.365-10.835-24.2-24.2-24.2-13.366 0-24.2 10.835-24.2 24.2 0 13.365 10.834 24.2 24.2 24.2Z"
        fill="#FFD330"
      />
      <path
        d="M406.704 47.244c-5.174 6.193-12.956 10.104-21.674 10.104-15.604 0-28.274-12.67-28.274-28.274M364.537 9.6C369.67 4.181 376.963.84 385.03.84c15.603 0 28.274 12.671 28.274 28.275M394.93 185.03a27.398 27.398 0 0 1 4.522-.367c15.604 0 28.274 12.67 28.274 28.274 0 15.604-12.67 28.274-28.274 28.274a28.184 28.184 0 0 1-15.726-4.767M372.726 222.144c-1.019-2.892-1.548-5.988-1.548-9.248a28.158 28.158 0 0 1 9.329-20.981"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m414.037 201.693-5.011-3.178-5.011-3.178.448 4.807.163 1.834-6.722 12.141-8.189 14.788 9.9-13.688 8.148-11.245 1.711-.611 4.563-1.67Z"
        fill="#959595"
      />
      <path
        d="m414.037 201.693-5.011-3.178-1.548-.978 2.322 3.748-3.585 1.671-16.5 25.951 9.9-13.688 8.148-11.245 1.711-.611 4.563-1.67Z"
        fill="#C6C6C6"
      />
      <path
        d="m408.211 204.626-5.255-.693M406.419 207.111l-4.889-.652M404.626 209.637l-4.522-.611M402.833 212.122l-4.155-.529M401.041 214.648l-3.789-.529M399.248 217.133l-3.422-.448M397.496 219.659l-3.096-.448M395.704 222.144l-2.73-.366M393.911 224.67l-2.363-.326M392.119 227.156l-1.997-.286"
        stroke="#636363"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M462.111 33.148v8.678l14.789-14.83h-8.678l-6.111 6.152Z" fill="#AEAEAE" />
      <path d="M468.222 47.937h8.678l6.152-6.111v-8.718l-14.83 14.829Z" fill="#7C7C7C" />
      <path d="M476.922 27.003 462.086 41.84l6.136 6.136 14.836-14.836-6.136-6.137Z" fill="#959595" />
      <path
        d="m471.726 23.493-6.152 6.151v8.678l6.152 6.152h8.678l6.152-6.152v-8.678l-6.152-6.151h-8.678Z"
        fill="#C6C6C6"
      />
      <path
        d="M480.078 30.581a5.164 5.164 0 0 1 0 7.334 4.89 4.89 0 0 1-.489.448c-.163.122-.367.285-.57.367-.204.122-.408.203-.612.326a8.801 8.801 0 0 1-.977.285c-.367.081-.774.122-1.141.081-.53 0-1.019-.122-1.548-.285-.734-.244-1.385-.652-1.997-1.222a5.109 5.109 0 0 1-1.222-1.996c-.163-.49-.244-1.019-.285-1.549 0-.366.041-.774.082-1.14a8.79 8.79 0 0 1 .285-.978c.081-.204.203-.408.326-.611.122-.204.244-.367.366-.57.123-.164.286-.327.448-.49 2.038-1.996 5.338-1.996 7.334 0Z"
        fill="#7C7C7C"
      />
      <path
        d="M397.211 45.574 375.537 23.9l-.163-.163a5.926 5.926 0 0 1 0-8.392 5.926 5.926 0 0 1 8.393 0 5.926 5.926 0 0 1 0 8.392c-.285.285-.856.693-.856.693-.081.081-.163.122-.244.203a1.868 1.868 0 0 0 0 2.649 1.869 1.869 0 0 0 2.648 0c.204-.204.448-.367.692-.49a2.378 2.378 0 0 1 2.689.49c.448.448.693 1.059.693 1.67s-.245 1.222-.693 1.67a1.672 1.672 0 0 0 0 2.363l7.741 7.74"
        stroke="#AEAEAE"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m398.433 45.167-21.674-21.674-.163-.163a5.926 5.926 0 0 1 0-8.393 5.927 5.927 0 0 1 8.393 0 5.926 5.926 0 0 1 0 8.393c-.285.285-.856.692-.856.692-.081.082-.163.122-.244.204a1.87 1.87 0 0 0 0 2.648 1.869 1.869 0 0 0 2.648 0c.204-.204.448-.367.693-.489a2.378 2.378 0 0 1 2.689.49c.448.447.692 1.058.692 1.67 0 .61-.244 1.222-.692 1.67a1.672 1.672 0 0 0 0 2.363l7.74 7.74"
        stroke="#7C7C7C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M444.389 201.815a92.858 92.858 0 0 1-17.6 3.992M492.993 163.437a89.052 89.052 0 0 1-3.749 5.582M488.063 60.118c11.693 15.319 18.618 34.467 18.618 55.204 0 2.811-.122 5.582-.366 8.311M412.856 24.226c.896-.04 1.792-.04 2.688-.04a91.058 91.058 0 0 1 30.678 5.296M353.252 48.793a89.813 89.813 0 0 1 7.048-5.99M375.904 197.415c-30.474-14.748-51.497-45.956-51.497-82.093 0-12.955 2.689-25.259 7.578-36.422"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M447.322 97.722c0-17.11-13.852-30.963-30.963-30.963s-30.963 13.852-30.963 30.963h15.645c0-8.433 6.844-15.318 15.318-15.318s15.319 6.844 15.319 15.318c0 4.237-1.711 8.067-4.522 10.837-7.415 7.415-18.904 7.13-18.904 22.163v11.367h17.437v-5.541c0-10.674 3.096-7.496 12.222-16.622 5.785-5.622 9.411-13.485 9.411-22.204ZM425.648 149.83h-17.396v14.055h17.396V149.83Z"
        fill="#E5F0F6"
      />
      <path
        d="M385.437 97.722h15.645c0-8.433 6.844-15.318 15.318-15.318s15.319 6.844 15.319 15.318c0 4.237-1.712 8.067-4.523 10.837-7.414 7.415-18.903 7.13-18.903 22.163v11.367h17.437M425.648 163.885h-17.396V149.83"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M303.752 110.8c6.84 0 12.385-5.545 12.385-12.385s-5.545-12.385-12.385-12.385-12.385 5.545-12.385 12.385 5.545 12.385 12.385 12.385ZM279.919 121.474a5.377 5.377 0 1 0 0-10.755 5.377 5.377 0 0 0 0 10.755Z"
        fill="#fff"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8 297.433v26.441c0 60.704 14.667 99.774 50.03 140.515"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M389.756 619.163h35.2c10.674-6.111 17.844-17.6 17.844-30.759v-.041c0-2.241-2.444-3.667-4.4-2.567a35.37 35.37 0 0 1-17.396 4.563c-16.134 0-29.741-10.796-34.019-25.503-.652-2.16-3.381-2.812-4.97-1.223a35.269 35.269 0 0 0-10.104 24.771c0 13.159 7.211 24.648 17.845 30.759Z"
        fill="#FFD330"
      />
      <path
        d="M382.056 563.633a35.264 35.264 0 0 0-10.104 24.771c0 13.159 7.17 24.648 17.844 30.759M81.796 619.163h384.308M146.044 299.674c-.122 1.752-1.629 3.015-3.381 2.893l-11.285-.897a3.006 3.006 0 0 1-2.771-3.218M145.515 305.744c-.122 1.752-1.63 3.015-3.382 2.893l-8.27-.652c-1.752-.122-3.015-1.63-2.893-3.381"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M145.067 301.752c1.67 1.915 4.685 3.748 10.796 3.422M276.7 604.456V371.907"
        stroke="#0A3A5C"
        strokeWidth={1.6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M279.919 335.444h-13.852v62.537h13.852v-62.537Z" fill="#00416A" />
      <path d="M269.937 335.444h-3.87v62.537h3.87v-62.537Z" fill="#80B3D2" />
      <path
        d="M279.875 337.134h-6.07M279.875 338.846h-3.218M279.875 342.268h-3.218M279.875 345.731h-3.218M279.875 349.153h-3.218M279.875 352.575h-3.218M279.875 355.997h-3.218M279.875 359.46h-3.218M279.875 362.883h-3.218M279.875 366.305h-3.218M279.875 369.727h-3.218M279.875 373.149h-3.218M279.875 376.612h-3.218M279.875 380.034h-3.218M279.875 383.457h-3.218M279.875 386.879h-3.218M279.875 390.342h-3.218M279.875 340.557h-6.07M279.875 343.979h-6.07M279.875 347.442h-6.07M279.875 350.864h-6.07M279.875 354.286h-6.07M279.875 357.709h-6.07M279.875 361.171h-6.07M279.875 364.594h-6.07M279.875 368.016h-6.07M279.875 371.438h-6.07M279.875 374.901h-6.07M279.875 378.323h-6.07M279.875 381.746h-6.07M279.875 385.168h-6.07M279.875 388.631h-6.07M279.875 392.053h-6.07M279.875 395.475h-6.07"
        stroke="#E5F0F6"
        strokeWidth={1.6}
        strokeMiterlimit={10}
      />
      <path d="M338.422 339.885h-83.478v7.374h83.478v-7.374Z" fill="#0A3A5C" />
    </svg>
  );
};
