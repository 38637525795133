import { useField } from "formik";
import * as React from "react";

import { Checkbox } from "./checkbox";

import type { InputProps } from "./interface";
import type { FieldConfig } from "formik";

export const FormikCheckbox = (
  props: InputProps & React.InputHTMLAttributes<HTMLInputElement> & FieldConfig,
): JSX.Element => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  const errorText = (Boolean(meta.touched) && meta.error) || false;

  return <Checkbox {...field} {...props} checked={field.value} errorText={errorText} />;
};
