import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const CreditLimitSmallIcons: BykoReactIconComponentType = ({ iconColor, size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 16 16" width={size} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_878_6007)">
        <path d="M10 1L12 3L7 8L4 9L5 6L10 1Z" stroke={iconColor} strokeLinecap="square" strokeWidth="1.5" />
        <path d="M11.5 6.5H15.5V15.5H0.5V6.5H2.5" stroke={iconColor} strokeLinecap="square" strokeWidth="1.5" />
        <path d="M3.5 11.5H12.5" stroke={iconColor} strokeLinecap="square" strokeWidth="1.5" />
      </g>
      <defs>
        <clipPath id="clip0_878_6007">
          <rect fill="white" height="16" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
};
