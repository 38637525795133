import styled from "styled-components";

import { PageContainer } from "@byko/component-page-container";

export const SceneContainer = styled(PageContainer)`
  display: grid;
  width: 100%;
  max-width: 1780px;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  gap: 8px;
  grid-template-areas:
    "chip-row chip-row"
    "filter-header filters"
    "products filters";

  grid-template-rows: auto auto 1fr;
`;

export const ProductsContainer = styled.div`
  grid-area: products;
  @media screen and (min-width: 896px) {
    width: 328px;
  }
  @media screen and (min-width: 1180px) {
    width: 672px;
  }
  @media screen and (min-width: 1694px) {
    width: 1016px;
  }
`;

export const FacetsContainer = styled.div`
  grid-area: filters;
  @media screen and (max-width: 895px) {
    display: none;
  }
`;

export const ProductContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 16px;
  @media screen and (max-width: 896px) {
    justify-content: center;
  }
  @media screen and (min-width: 896px) {
    max-width: 328px;
  }
  @media screen and (min-width: 1180px) {
    max-width: 672px;
  }
  @media screen and (min-width: 1694px) {
    max-width: 1016px;
  }
`;
