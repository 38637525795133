import * as React from "react";

import { CheckmarkIcons } from "@byko/lib-icons";
import { theme } from "@byko/lib-styles";

import {
  CheckboxInput,
  CheckboxWrapper,
  ErrorText,
  Label,
  QuantityNumberWrapper,
  StyledCheckbox,
  Text,
} from "./styles";

import type { InputProps } from "./interface";

export const Checkbox = ({
  arialabel = "",
  label = "",
  disabled = false,
  white = false,
  reverse = false,
  smallBox = false,
  checked,
  quantityNumber,
  value,
  onClick,
  errorText,
  ...props
}: InputProps): JSX.Element => {
  const ariaLabelText = arialabel ? arialabel : typeof label === "string" ? label : "";

  return (
    <CheckboxWrapper>
      <Label aria-label={ariaLabelText} className="checkbox" reverse={reverse} smallBox={smallBox}>
        <CheckboxInput
          className="checkbox-input"
          type="checkbox"
          onClick={onClick}
          {...props}
          checked={checked}
          disabled={disabled}
          smallBox={smallBox}
          value={value}
          white={white}
        />

        <StyledCheckbox {...props} disabled={disabled} smallBox={smallBox} white={white}>
          {checked && (
            <CheckmarkIcons iconColor={white ? theme.palette.blue.dark : theme.palette.white.main} size={16} />
          )}
        </StyledCheckbox>

        <Text className="checkbox-text" white={white}>
          {label}
        </Text>
        {quantityNumber && (
          <QuantityNumberWrapper className="checkbox-quantity">({quantityNumber})</QuantityNumberWrapper>
        )}
      </Label>

      {Boolean(errorText) && <ErrorText>{errorText}</ErrorText>}
    </CheckboxWrapper>
  );
};
