/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { maxBy } from "lodash";

import { ensureList } from "@byko/lib-utils";

import type { Category, ProductDetail, ProductList } from "@byko/lib-api-rest";

import type { BreadCrumb, CategoryReferenceMap, CategoryTreeItem } from "./interface";

export function buildCategoryReferenceMap(categories: Category[]): CategoryReferenceMap {
  const categoryPairs = categories.map<[number, CategoryTreeItem]>((c) => [
    c.id,
    {
      //
      ...c,
      parentNode: undefined,
      children: [],
    },
  ]);

  const tree = new Map(categoryPairs);

  tree.forEach((item: CategoryTreeItem) => {
    if (item.parent) {
      item.parentNode = tree.get(item.parent);
    }
  });

  tree.forEach((ref: CategoryTreeItem) => {
    ref.parentNode?.children.push(ref);
  });

  return tree;
}

export function filterTopLevelCategories(catReferenceMap: CategoryReferenceMap): CategoryTreeItem[] {
  return Array.from(catReferenceMap.values()).filter((p) => !p.parent);
}

export function traceCategoryAncestry(category: CategoryTreeItem | undefined): CategoryTreeItem[] {
  let cat = category;
  const ret = [];
  while (cat) {
    ret.unshift(cat);
    cat = cat.parentNode;
  }
  return ret;
}

export function dropProductBreadCrumbs(
  catReferenceMap: CategoryReferenceMap,
  product: ProductDetail | ProductList,
  isRentalProduct?: boolean,
): BreadCrumb[] {
  const categoryPaths = product.categories.map((cid) => traceCategoryAncestry(catReferenceMap.get(cid)));
  const longestPath = ensureList(maxBy(categoryPaths, (p) => p.length));
  const rentalProduct = isRentalProduct ?? false;

  return [
    {
      title: "Forsíða",
      url: "/",
    },
    ...longestPath.map((c) => ({
      //
      title: c.name,
      url: `/${rentalProduct ? "bykoleiga" : "voruflokkar"}/${c.slug}`,
    })),
    {
      //
      title: product.name,
      url: `/${rentalProduct ? "leiguvara" : "vara"}/${product.slug}`,
    },
  ];
}

export function dropCategoryBreadCrumbs(category: CategoryTreeItem | undefined): BreadCrumb[] {
  return [
    {
      title: "Forsíða",
      url: "/",
    },
    ...traceCategoryAncestry(category).map((c) => ({
      title: c.name,
      url: `/voruflokkar/${c.slug}`,
    })),
  ];
}

export function dropRentalCategoryBreadCrumbs(category: CategoryTreeItem | undefined): BreadCrumb[] {
  return [
    {
      title: "Forsíða",
      url: "/",
    },
    ...traceCategoryAncestry(category).map((c) => ({
      title: c.name,
      url: `/bykoleiga/${c.slug}`,
    })),
  ];
}
