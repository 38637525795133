import { gql } from "graphql-tag";
import { useQuery } from "react-query";
import { graphQLClient } from "../dato-graphql-client";

import type { GetOpeningHoursQuery } from "../generated-gql/graphql";
import { OpeningHourFragment } from "./fragments";

export function useGetOpeningHours() {
  const { data, isLoading, error } = useQuery("get-opening-hours", async () => {
    const request = await graphQLClient.request<{
      openingHours: GetOpeningHoursQuery;
    }>(gql`
      query GetOpeningHours {
        openingHour {
          ...OpeningHourFragment
        }
      }
      ${OpeningHourFragment}
    `);
    return request;
  });

  return { data: data?.openingHours, isLoading, error };
}
