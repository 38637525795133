/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import type { SalesAvailability } from "@byko/lib-api-products";
import { useProductPrices } from "@byko/lib-api-products";
import DemoUpButton from "demoup-react";
import { IconButton } from "@byko/component-buttons";
import { PageContainer } from "@byko/component-page-container";
import { ArrowLeftIcons, ArrowRightIcons } from "@byko/lib-icons";
import { theme } from "@byko/lib-styles";
import useLightbox from "../../../../../use-lightbox";
import Modal from "./modal";
// Adjust the path if necessary

import {
  ProductContractDetail,
  ProductCustomDetail,
  ProductDisplayDetail,
  ProductLimitedDetail,
  ProductRentDetail,
  ProductStandardDetail,
} from "../product-detail";

import {
  ClickableDemoupSlideWrapper,
  ClickableSliderImageWrapper,
  ElectronicImage,
  ElectronicImageContainer,
  HiddenContainer,
  ImageFlex,
  ImageWrapper,
  LeftWrapper,
  ModalContainer,
  PaginationContainer,
  ProductImage,
  ProductWrapper,
  ProgressBar,
  SliderImage,
  SliderItem,
  TagContainer,
} from "./styles";

import type { Media, ProductImageAndDetailProps } from "./interface";
import type { KeyboardEvent } from "react";
import type { TagItem } from "src/features/tags/interface";
import { Tags } from "src/features/tags";
import { isRentalProduct } from "src/features/search/components/product-list";
import { EnergyA, EnergyB, EnergyC, EnergyD, EnergyE, EnergyF, EnergyG } from "./energy-labels";
import { useAuth } from "@byko/hooks-auth-next";

interface DemoProps {
  mid?: string | undefined;
  trigger?: boolean | undefined;
  buttonwidth?: number | undefined;
  buttonheight?: number | undefined;
  onVideoAvailable?: (() => void) | undefined;
}

function DemoupComponent({ buttonwidth, buttonheight, onVideoAvailable }: DemoProps) {
  const { button: VideoButton } = DemoUpButton("byko.is");

  return <VideoButton buttonheight={buttonheight} buttonwidth={buttonwidth} onVideoAvailable={onVideoAvailable} />;
}

const NO_TAGS: TagItem[] = [];

const placeholderImg = "https://www.datocms-assets.com/65892/1664464657-placeholder-do-not-remove.jpg";

const placeholderImageHandler = (e: unknown): void => {
  /* eslint-disable @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  const target = e.target as HTMLImageElement;
  target.src = placeholderImg;
};

export const ProductImageAndDetail = ({
  product,
  tags,
  brand,
  activeVariant,
  setActiveVariantSKU,
  rental,
}: ProductImageAndDetailProps): JSX.Element => {
  const { user, activeMembership } = useAuth();
  const salesAvailability: SalesAvailability = (product.salesAvailability as SalesAvailability) ?? "standard";

  const [videoIsAvailable, setVideoIsAvailable] = useState(false);
  const [mediaIsAvailable, setMediaIsAvailable] = useState(false);
  const activeVariantSKU = useMemo(() => {
    return activeVariant?.sku as string;
  }, [activeVariant?.sku]);

  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);

  const { prices: skuPrices } = useProductPrices(
    (salesAvailability === "limited" ||
      salesAvailability === "standard" ||
      (salesAvailability === "by_contract" && activeMembership?.account.hasContract)) &&
      !isRentalProduct(product)
      ? [product.id]
      : [],
  );

  const price = skuPrices?.[activeVariantSKU];

  const handleSetSelectedImageIndex = useCallback(
    (index: number) => {
      setSelectedImageIndex(index);
    },
    [setSelectedImageIndex],
  );

  const availableMedia = useMemo(() => {
    const media: Media[] = [];
    const matchedIds: number[] = [];

    // product media sometimes contains duplicate entries from variants, so we need to filter them out
    product.media.forEach((productMedia) => {
      if (
        productMedia?.image?.productGallery &&
        productMedia?.image?.productList &&
        !matchedIds.includes(productMedia.id)
      ) {
        media.push({
          gallery: productMedia.image.productGallery,
          list: productMedia.image.productList,
          id: productMedia.id,
        });
        matchedIds.push(productMedia.id);
      }
    });

    product.variants.forEach((variant) => {
      variant.media.forEach((variantMedia) => {
        if (
          variantMedia?.image?.productGallery &&
          variantMedia?.image?.productList &&
          !matchedIds.includes(variantMedia.id)
        ) {
          media.push({
            gallery: variantMedia.image.productGallery,
            list: variantMedia.image.productList,
            id: variantMedia.id,
          });
          matchedIds.push(variantMedia.id);
        }
      });
    }, []);

    setMediaIsAvailable(media.length > 0);

    return media;
  }, [product.media, product.variants]);

  useEffect(() => {
    // reset selected image index when variant changes
    const activeVariantImageId = activeVariant?.media?.[0]?.id;
    if (activeVariantImageId != null) {
      const activeVariantImageIndex = availableMedia.findIndex((media) => media.id === activeVariantImageId);
      setSelectedImageIndex(activeVariantImageIndex);
    }
  }, [activeVariant?.media, activeVariantSKU, availableMedia, product, product.variants.length]);

  const selectedImage = useMemo(() => {
    return availableMedia[selectedImageIndex];
  }, [availableMedia, selectedImageIndex]);

  const localTags = useMemo((): TagItem[] => {
    if (tags) {
      return tags;
    }

    const list: TagItem[] = [];

    if (price?.discounted?.name === "RETAIL") {
      if (price?.discounted?.percentage) {
        list.push({
          label: `-${price?.discounted?.percentage}% afsláttur`,
          color: "yellow",
        });
      } else {
        list.push({
          label: "Tilboð",
          color: "yellow",
        });
      }
    }

    if (product.attributes["environmentalInfo"]?.length > 0) {
      list.push({
        label: "Umhverfisvæn vara",
        color: "green",
      });
    }
    return list;
  }, [price?.discounted?.name, price?.discounted?.percentage, product, tags]);

  const previousPicture = useCallback(() => {
    if (selectedImageIndex === 0) {
      setSelectedImageIndex(availableMedia.length - 1);
    } else {
      setSelectedImageIndex(selectedImageIndex - 1);
    }
  }, [selectedImageIndex, availableMedia.length]);

  const nextPicture = useCallback(() => {
    if (selectedImageIndex === availableMedia.length - 1) {
      setSelectedImageIndex(0);
    } else {
      setSelectedImageIndex(selectedImageIndex + 1);
    }
  }, [selectedImageIndex, availableMedia.length]);

  const sliderSize = useMemo(() => {
    return `${(1 / product.media.length) * 100}%`;
  }, [product.media.length]);

  const progress = useMemo(() => {
    return `${(selectedImageIndex / availableMedia.length) * 100}%`;
  }, [selectedImageIndex, availableMedia.length]);

  // toggle next or previous image in slider using the keyboard
  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>): void => {
      if (event.key === "ArrowLeft") {
        previousPicture();
      } else if (event.key === "ArrowRight") {
        nextPicture();
      }
    },
    [nextPicture, previousPicture],
  );

  const onVideoAvailable = useCallback(() => {
    setVideoIsAvailable(true);
  }, []);

  const { open } = useLightbox();

  const handleShowElectronicImage = useCallback(() => {
    const image = product?.energyLabel?.image?.productGallery;

    if (image) {
      open(
        <ElectronicImageContainer>
          <ElectronicImage alt="orkumerking" src={image} />
        </ElectronicImageContainer>,
      );
    }
  }, [open, product?.energyLabel?.image?.productGallery]);

  const EnergyLabel = useMemo(() => {
    const energyTag = product?.attributes["energyTag"] as { en: string; "is-IS": string } | undefined;

    if (!energyTag) {
      return null;
    }

    let Tag: JSX.Element | null = null;

    if (energyTag["is-IS"] === "A" || energyTag["is-IS"] === "a") {
      Tag = <EnergyA />;
    }

    if (energyTag["is-IS"] === "B" || energyTag["is-IS"] === "b") {
      Tag = <EnergyB />;
    }

    if (energyTag["is-IS"] === "C" || energyTag["is-IS"] === "c") {
      Tag = <EnergyC />;
    }

    if (energyTag["is-IS"] === "D" || energyTag["is-IS"] === "d") {
      Tag = <EnergyD />;
    }

    if (energyTag["is-IS"] === "E" || energyTag["is-IS"] === "e") {
      Tag = <EnergyE />;
    }

    if (energyTag["is-IS"] === "F" || energyTag["is-IS"] === "f") {
      Tag = <EnergyF />;
    }

    if (energyTag["is-IS"] === "G" || energyTag["is-IS"] === "g") {
      Tag = <EnergyG />;
    }

    if (Tag !== null) {
      return () => {
        return (
          <button type="button" onClick={handleShowElectronicImage}>
            {Tag}
          </button>
        );
      };
    }

    return Tag;
  }, [handleShowElectronicImage, product?.attributes]);

  const DetailComponent = useMemo(() => {
    /*
      "rental": rental products, not sold on web, no price display
      "custom": custom order, not sold on the web, no price display
      "limited": limited availability, not sold on the web, show price info
      "display": display only, not sold on the web, no price display
      "standard": standard products, display as is
      "by_contract": contracted products, only users with contract see price info and can order
    */

    if (rental) {
      return (
        <ProductRentDetail activeVariant={activeVariant} brand={brand} EnergyLabel={EnergyLabel} product={product} />
      );
    } else if (salesAvailability === "by_contract") {
      if (user && activeMembership?.account.hasContract) {
        return (
          <ProductStandardDetail
            activeVariant={activeVariant}
            brand={brand}
            EnergyLabel={EnergyLabel}
            product={product}
            setActiveVariantSKU={setActiveVariantSKU}
            skuPrices={skuPrices}
          />
        );
      } else {
        return (
          <ProductContractDetail
            activeVariant={activeVariant}
            brand={brand}
            EnergyLabel={EnergyLabel}
            product={product}
          />
        );
      }
    } else if (salesAvailability === "custom") {
      return (
        <ProductCustomDetail activeVariant={activeVariant} brand={brand} EnergyLabel={EnergyLabel} product={product} />
      );
    } else if (salesAvailability === "limited") {
      return (
        <ProductLimitedDetail
          activeVariant={activeVariant}
          brand={brand}
          EnergyLabel={EnergyLabel}
          product={product}
          setActiveVariantSKU={setActiveVariantSKU}
          skuPrices={skuPrices}
        />
      );
    } else if (salesAvailability === "display") {
      return (
        <ProductDisplayDetail activeVariant={activeVariant} brand={brand} EnergyLabel={EnergyLabel} product={product} />
      );
    }

    // "Standard" sales availability
    return (
      <ProductStandardDetail
        activeVariant={activeVariant}
        brand={brand}
        EnergyLabel={EnergyLabel}
        product={product}
        setActiveVariantSKU={setActiveVariantSKU}
        skuPrices={skuPrices}
      />
    );
  }, [
    EnergyLabel,
    activeMembership?.account.hasContract,
    activeVariant,
    brand,
    product,
    rental,
    salesAvailability,
    setActiveVariantSKU,
    skuPrices,
    user,
  ]);

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = useCallback(() => {
    if (!mediaIsAvailable) return;
    setIsOpen(true);
  }, [mediaIsAvailable]);

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <PageContainer
      backgroundColor={theme.palette.white.main}
      flexDirection="column"
      justifyContent="center"
      offsetNav={false}
    >
      {localTags && localTags.length > 0 && (
        <TagContainer>
          <Tags tags={localTags ?? NO_TAGS} />
        </TagContainer>
      )}
      <ProductWrapper>
        <LeftWrapper onKeyDown={handleKeyDown}>
          <ImageWrapper>
            <ProductImage
              clickable={mediaIsAvailable}
              placeholder="https://www.datocms-assets.com/65892/1664464657-placeholder-do-not-remove.jpg"
              src={
                selectedImage?.gallery ??
                "https://www.datocms-assets.com/65892/1664464657-placeholder-do-not-remove.jpg"
              }
              onClick={handleOpenModal}
              onError={placeholderImageHandler}
            />
            <Modal open={isOpen} onClose={handleCloseModal}>
              <ModalContainer>
                <IconButton
                  ariaLabel="flakka til baka í myndavali"
                  className="image-selection-button previous-image-button"
                  icon={ArrowLeftIcons}
                  onClick={previousPicture}
                />
                <ProductImage
                  placeholder="https://www.datocms-assets.com/65892/1664464657-placeholder-do-not-remove.jpg"
                  src={
                    selectedImage?.gallery ??
                    "https://www.datocms-assets.com/65892/1664464657-placeholder-do-not-remove.jpg"
                  }
                  onError={placeholderImageHandler}
                />
                <IconButton
                  ariaLabel="flakka áfram í myndavali"
                  className="image-selection-button next-image-button"
                  icon={ArrowRightIcons}
                  onClick={nextPicture}
                />

                <ImageFlex>
                  {videoIsAvailable && (
                    <ClickableDemoupSlideWrapper>
                      <SliderItem transformValue={selectedImageIndex * 168}>
                        <DemoupComponent
                          buttonheight={106}
                          buttonwidth={152}
                          trigger={false}
                          onVideoAvailable={onVideoAvailable}
                        />
                      </SliderItem>
                    </ClickableDemoupSlideWrapper>
                  )}
                  {availableMedia.map((media, index) => (
                    <ClickableSliderImageWrapper
                      key={media.list}
                      // eslint-disable-next-line react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop
                      onClick={(): void => handleSetSelectedImageIndex(index)}
                    >
                      <SliderImage
                        src={media.list}
                        transformValue={selectedImageIndex * 168}
                        onError={placeholderImageHandler}
                      />
                    </ClickableSliderImageWrapper>
                  ))}
                </ImageFlex>
              </ModalContainer>
            </Modal>
            {availableMedia.length > 1 ? (
              <>
                <IconButton
                  ariaLabel="flakka til baka í myndavali"
                  className="image-selection-button previous-image-button"
                  icon={ArrowLeftIcons}
                  onClick={previousPicture}
                />
                <IconButton
                  ariaLabel="flakka áfram í myndavali"
                  className="image-selection-button next-image-button"
                  icon={ArrowRightIcons}
                  onClick={nextPicture}
                />
              </>
            ) : null}
          </ImageWrapper>
          {availableMedia.length > 1 && (
            <>
              <ImageFlex>
                {videoIsAvailable && (
                  <ClickableDemoupSlideWrapper>
                    <SliderItem transformValue={selectedImageIndex * 168}>
                      <DemoupComponent
                        buttonheight={106}
                        buttonwidth={152}
                        trigger={false}
                        onVideoAvailable={onVideoAvailable}
                      />
                    </SliderItem>
                  </ClickableDemoupSlideWrapper>
                )}
                {availableMedia.map((media, index) => (
                  <ClickableSliderImageWrapper
                    key={media.list}
                    // eslint-disable-next-line react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop
                    onClick={(): void => handleSetSelectedImageIndex(index)}
                  >
                    <SliderImage
                      src={media.list}
                      transformValue={selectedImageIndex * 168}
                      onError={placeholderImageHandler}
                    />
                  </ClickableSliderImageWrapper>
                ))}
              </ImageFlex>

              <PaginationContainer>
                <ProgressBar progress={progress} sliderSize={sliderSize} />
                <IconButton ariaLabel="flakka til baka í myndavali" icon={ArrowLeftIcons} onClick={previousPicture} />
                <IconButton ariaLabel="flakka áfram í myndavali" icon={ArrowRightIcons} onClick={nextPicture} />
              </PaginationContainer>
            </>
          )}
          {availableMedia.length <= 1 && videoIsAvailable && (
            <ClickableDemoupSlideWrapper marginTop>
              <SliderItem transformValue={selectedImageIndex * 168}>
                <DemoupComponent
                  buttonheight={106}
                  buttonwidth={152}
                  trigger={false}
                  onVideoAvailable={onVideoAvailable}
                />
              </SliderItem>
            </ClickableDemoupSlideWrapper>
          )}
        </LeftWrapper>
        {DetailComponent}
      </ProductWrapper>
      {!videoIsAvailable && (
        <HiddenContainer>
          <DemoupComponent buttonheight={106} buttonwidth={152} trigger={false} onVideoAvailable={onVideoAvailable} />
        </HiddenContainer>
      )}
    </PageContainer>
  );
};
