/* eslint-disable react-perf/jsx-no-new-array-as-prop */
import * as React from "react";

import { PageContainer } from "@byko/component-page-container";
import { Pagination } from "@byko/component-pagination";
import { ComponentSettingsProvider } from "@byko/lib-component-settings";
import { useWindowSize } from "@byko/lib-styles";

import {
  Container,
  DesktopOnly,
  Flex,
  PaginationContainer,
  ResultsAndPaginationContiner,
  TagsContainer,
} from "./styles";

import type { FilterProps } from "./interface";
import { ResultsBlock } from "./results-block";
import { TagsBlock } from "./tags-block";
import { MobileSortingButtons } from "./filter-dropdown-sorting-block/mobile-sorting-block";
import { AccordionBlock } from "./filter-accordion-block";

export const Filter = ({
  tags = [],
  configuration = [],
  setTag,
  sortingOptions = [],
  children,
  count,
  totalPages,
  currentPage,
  handleMobileSubmit,
  handleReset,
  handleSortClick,
  handleSetPage,
}: FilterProps): JSX.Element => {
  const { activeBreakPoint } = useWindowSize();

  return (
    <>
      <ComponentSettingsProvider platform="mobile">
        <MobileSortingButtons
          configuration={configuration}
          handleReset={handleReset}
          handleSubmit={handleMobileSubmit}
          sortingOptions={sortingOptions}
        />
      </ComponentSettingsProvider>

      <PageContainer flexDirection="column" offsetNav={false}>
        <Container>
          {tags.length >= 1 && (
            <TagsContainer>
              <TagsBlock setTag={setTag} tags={tags} />
            </TagsContainer>
          )}

          <Flex>
            <ResultsAndPaginationContiner>
              <ResultsBlock count={count} handleSortClick={handleSortClick} sortingOptions={sortingOptions}>
                {children}
              </ResultsBlock>

              <PaginationContainer>
                <Pagination
                  align={["xs", "s"].includes(activeBreakPoint) ? "center" : "flex-end"}
                  pageIndex={currentPage}
                  setPageIndex={handleSetPage}
                  totalPages={totalPages}
                />
              </PaginationContainer>
            </ResultsAndPaginationContiner>

            <DesktopOnly>
              <AccordionBlock configuration={configuration} handleReset={handleReset} />
            </DesktopOnly>
          </Flex>
        </Container>
      </PageContainer>
    </>
  );
};
