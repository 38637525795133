import React, { useCallback, useReducer } from "react";

import { Button } from "@byko/component-buttons";

import { AccordionBlockItem } from "./component";
import { reducer } from "./reducer";
import { AccordionWrapper } from "./styles";

import type { AccordionBlockProps } from "./interface";

export const AccordionBlockComponent = ({ configuration, handleReset }: AccordionBlockProps): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, {});
  const handleClick = useCallback(() => {
    if (!handleReset) {
      return;
    }
    handleReset();
  }, [handleReset]);
  const handleToggleActive = useCallback((id: number | string) => {
    dispatch({ type: "toggle", id });
  }, []);
  return (
    <AccordionWrapper active={configuration.length > 0} className="desktop-accordion-block">
      {configuration.map((item) => {
        return (
          <AccordionBlockItem
            key={item.id ?? item.label}
            handleToggleActive={handleToggleActive}
            item={item}
            state={state}
          />
        );
      })}
      <Button
        buttonColor="whiteButton"
        customClassName="clear-filter-button"
        label="Endurstilla síur"
        stretch={true}
        onClick={handleClick}
      />
    </AccordionWrapper>
  );
};
