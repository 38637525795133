import * as React from "react";
import { useCallback, useState } from "react";

import { SearchInput } from "@byko/component-inputs";

import { DropdownSelectList } from "./dropdown-select-list";

import type { DropdownOptionsProps } from "./interface";
import type { CustomerAccountMembership } from "@byko/lib-api-rest";
import type { ChangeEvent } from "react";

export const DropdownSelectSearch = ({ options }: DropdownOptionsProps): JSX.Element | null => {
  const [filteredAccounts, setFilteredAccounts] = useState<CustomerAccountMembership[]>(options.slice(0, 3));
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string | undefined>("");

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setInputValue(e.target.value);

      let unLinked: CustomerAccountMembership[] = [];

      if (options) {
        unLinked = options.filter(
          (option): boolean =>
            (option.account.name || "").toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 ||
            option.account.ssn.indexOf(e.target.value.toLowerCase()) > -1,
        );
      }

      setFilteredAccounts(unLinked);
      setShowSuggestions(true);
    },
    [setInputValue, setFilteredAccounts, setShowSuggestions, options],
  );

  const handleOnClick = useCallback(() => {
    setInputValue("");
    setFilteredAccounts([]);
  }, [setInputValue, setFilteredAccounts]);

  return (
    <>
      <SearchInput
        isDirty={Boolean(inputValue)}
        placeholder="Sláðu inn nafn eða kennitölu"
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onClick={handleOnClick}
      />
      {inputValue === "" ? (
        <DropdownSelectList
          options={options}
          setFilteredAccounts={setFilteredAccounts}
          setInputValue={setInputValue}
          setShowSuggestions={setShowSuggestions}
        />
      ) : null}
      {showSuggestions && inputValue ? (
        <DropdownSelectList
          options={filteredAccounts}
          setFilteredAccounts={setFilteredAccounts}
          setInputValue={setInputValue}
          setShowSuggestions={setShowSuggestions}
        />
      ) : null}
    </>
  );
};
