import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const LongArrowUpIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M12 22V2" stroke={iconColor} strokeMiterlimit="10" strokeWidth="2" />
      <path d="M5 9L12 2L19 9" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
    </svg>
  );
};
