import * as React from "react";
import { useCallback, useMemo, useState } from "react";

import { Subtitle } from "@byko/component-typography";
import { theme } from "@byko/lib-styles";

import { NumericButtonContainer } from "./styles";

import type { NumericButtonProps } from "./interface";

export const NumericButton = ({
  number,
  ariaLabel,
  onClick,
  disabled = false,
  small = false,
  buttonColor = "whiteButton",
  customClassName = "icon-button",
}: NumericButtonProps): JSX.Element => {
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const textColor = useMemo((): string => {
    switch (buttonColor) {
      case "blueButton":
        return theme.palette.yellow.main;
      case "greenButton":
        return theme.palette.white.main;
      case "orangeButton":
        return theme.palette.white.main;
      case "redButton":
        return isHovering ? theme.palette.white.main : theme.palette.triggers.warning.main;
      case "whiteButton":
        return theme.palette.blue.main;
      case "yellowButton":
        return theme.palette.blue.main;
      default:
        return theme.palette.blue.main;
    }
  }, [buttonColor, isHovering]);

  const handleMouseOver = useCallback(() => {
    setIsHovering(true);
  }, []);

  const handleMouseOut = useCallback(() => {
    setIsHovering(false);
  }, []);

  return (
    <NumericButtonContainer
      aria-label={ariaLabel ?? ""}
      buttonColor={buttonColor}
      className={customClassName}
      disabled={disabled}
      role="button"
      small={small}
      title={ariaLabel}
      onClick={onClick}
      onMouseOut={handleMouseOut}
      onMouseOver={handleMouseOver}
    >
      <Subtitle textColor={textColor}>{number}</Subtitle>
    </NumericButtonContainer>
  );
};
