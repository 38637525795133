import * as React from "react";
import { useMemo } from "react";

import { PLargeBold } from "@byko/component-typography";
import { theme } from "@byko/lib-styles";

import { FocusLine, SecondaryButtonWrapper, TheSecondaryButton } from "./styles";

import type { SecondaryButtonProps } from "./interface";

export const SecondaryButton = ({
  label = "",
  ariaLabel = label,
  icon: Icon,
  smallIcon = false,
  darkBlueText = false,
  blueLine = false,
  iconColor = theme.palette.blue.main,
  ...props
}: SecondaryButtonProps): JSX.Element => {
  const noIcon = useMemo((): boolean => {
    return Boolean(Icon);
  }, [Icon]);

  return (
    <SecondaryButtonWrapper {...props} aria-label={label ? label : ariaLabel}>
      <TheSecondaryButton darkBlueText={darkBlueText}>
        {Icon ? (
          <Icon
            iconColor={iconColor ? iconColor : darkBlueText ? theme.palette.blue.dark : theme.palette.blue.main}
            size={smallIcon ? 16 : 24}
          />
        ) : (
          noIcon
        )}
        <PLargeBold textColor={darkBlueText ? theme.palette.blue.dark : theme.palette.blue.main}>{label}</PLargeBold>
      </TheSecondaryButton>
      <FocusLine blueLine={blueLine} noIcon={noIcon} smallIcon={smallIcon} />
    </SecondaryButtonWrapper>
  );
};
