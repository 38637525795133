import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const SmallFenceIcons: BykoReactIconComponentType = ({ size, iconColor = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 16 16" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M5.6731 8L9.82694 8" stroke={iconColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path
        d="M5.6731 13L9.82694 13"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M1 15V4.78571L3.07692 2L5.15385 4.78571V15H1Z"
        stroke={iconColor}
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        d="M10.3462 15V4.78571L12.4231 2L14.5 4.78571V15H10.3462Z"
        stroke={iconColor}
        strokeLinecap="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
