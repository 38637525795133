/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from "styled-components";

import { theme } from "./theme";

import type { FlattenInterpolation, ThemeProps } from "styled-components";

export const lineStyles = (): FlattenInterpolation<ThemeProps<any>> => {
  return css`
    position: relative;

    &:before {
      position: absolute;
      top: 0px;
      width: 20px;
      border-top: 1px solid ${theme.palette.triggers.warning.main};
      content: "";
    }
  `;
};
