import React, { useCallback, useEffect, useState } from "react";

import { PMedium } from "@byko/component-typography";
import { theme } from "@byko/lib-styles";

import { Banner, BannerCloseWrapper, Wrapper } from "./styles";
import { IconButton } from "@byko/component-buttons";
import { CancelLargeIcons } from "@byko/lib-icons";
import { datoQueries } from "@byko/lib-dato";

export const BetaBanner = (): JSX.Element | null => {
  const { data: datoText } = datoQueries.useGetTextOnPage();
  const [showBanner, setShowBanner] = useState<boolean>(true);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const showGlobalBanner = window.sessionStorage.getItem("showGlobalBanner");
      if (showGlobalBanner === "false") {
        setShowBanner(false);
      }
    }
  }, []);

  const handleCloseBanner = useCallback((): void => {
    window.sessionStorage.setItem("showGlobalBanner", "false");
    setShowBanner(false);
  }, []);

  if (
    showBanner === false ||
    datoText == null ||
    datoText.globalBannerText == null ||
    datoText.globalBannerText === ""
  ) {
    return null;
  }
  return (
    <Wrapper>
      <Banner>
        <PMedium textAlign="center" textColor={theme.palette.blue.dark}>
          {datoText.globalBannerText}
        </PMedium>
        <BannerCloseWrapper>
          <IconButton
            ariaLabel={"loka tilkynningu"}
            buttonColor="yellowButton"
            icon={CancelLargeIcons}
            small={true}
            type="button"
            onClick={handleCloseBanner}
          />
        </BannerCloseWrapper>
      </Banner>
    </Wrapper>
  );
};
