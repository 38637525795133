import * as React from "react";
import { useCallback, useState } from "react";

import { Button } from "@byko/component-buttons";
import { drawerState, drawerStateLoading } from "@byko/component-drawer";
import { useAuth } from "@byko/hooks-auth-next";
import { LongArrowSideIcons } from "@byko/lib-icons";
import { useSetRecoilState } from "@byko/lib-recoil";

import type { UserItemProps } from "./interface";

export const UserItem = ({ id, name }: UserItemProps): JSX.Element => {
  const setOpen = useSetRecoilState<boolean>(drawerState);
  const [loading, setLoading] = useState<boolean>(false);
  const { switchAccount, setRequestSwitchAccount } = useAuth();
  const setIsDrawerLoading = useSetRecoilState(drawerStateLoading);

  const handleClick = useCallback(async () => {
    setLoading(true);
    await switchAccount(id);
    setRequestSwitchAccount(false);
    setLoading(false);
    setIsDrawerLoading(false);
    setOpen(false);
  }, [switchAccount, id, setRequestSwitchAccount, setIsDrawerLoading, setOpen]);

  return (
    <Button
      key={id}
      disabled={loading}
      icon={LongArrowSideIcons}
      label={name ?? ""}
      stretch={true}
      onClick={handleClick}
    />
  );
};
