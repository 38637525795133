import * as React from "react";
import { useCallback } from "react";

import { PSmall } from "@byko/component-typography";
import { AddIcons, SubtractIcons } from "@byko/lib-icons";
import { theme } from "@byko/lib-styles";

import {
  QtyButtonContainer,
  QtyButtonWrapper,
  QtyIconButton,
  QtyInputNumber,
  SeperatorLine,
  TextWrapper,
} from "./styles";

import type { QuantityInputProps } from "./interface";

export const DefaultQuantityInput = ({
  quantity = 0,
  quantityUnit = "magn stk",
  setQuantity,
  addClick,
  subtractClick,
  onInputChange,
  addDisabled = false,
  stretch = false,
  textInRow = false,
  uppercase = true,
  ...props
}: QuantityInputProps): JSX.Element => {
  const checkValue = useCallback(() => {
    if (Number.isNaN(quantity)) {
      setQuantity(1);
    }
  }, [quantity, setQuantity]);
  return (
    <QtyButtonContainer className="quantity-input" stretch={stretch} textInRow={textInRow}>
      <TextWrapper textInRow={textInRow} uppercase={uppercase}>
        <PSmall lineHeight="18px" textColor={theme.palette.gray[50]}>
          {quantityUnit}
        </PSmall>
        {textInRow && <SeperatorLine />}
        <QtyInputNumber
          aria-label="quantity"
          name="quantity"
          type="number"
          value={quantity}
          onBlur={checkValue}
          onChange={onInputChange}
          {...props}
        />
      </TextWrapper>

      <QtyButtonWrapper>
        <QtyIconButton aria-label={"Minnka magn"} disabled={quantity < 2} onClick={subtractClick}>
          <SubtractIcons iconColor={theme.palette.blue.main} size={14} />
        </QtyIconButton>

        <QtyIconButton aria-label={"Bæta við magni"} disabled={addDisabled} onClick={addClick}>
          <AddIcons iconColor={theme.palette.blue.main} size={14} />
        </QtyIconButton>
      </QtyButtonWrapper>
    </QtyButtonContainer>
  );
};
