import React, { useCallback, useEffect, useState } from "react";

import { Button } from "@byko/component-buttons";
import { ArrowDownIcons, FilterIcons } from "@byko/lib-icons";

import { ButtonWrapper, MobileSortingBlockContainer } from "./styles";

import type { MobileSortingBlockProps } from "./interface";
import { MobileAccordionBlock, MobileSortingBlock } from "../filter-accordion-block";

export const MobileSortingButtons = ({
  configuration,
  sortingOptions,
  handleSubmit,
  handleReset,
}: MobileSortingBlockProps): JSX.Element | null => {
  const [openAccordianFilter, setOpenAccordianFillter] = useState<boolean>(false);
  const [openSorting, setOpenSorting] = useState<boolean>(false);

  const handleAccordianClick = useCallback(() => {
    if (openSorting === true) {
      setOpenSorting(!openSorting);
    }

    setOpenAccordianFillter(!openAccordianFilter);
  }, [openAccordianFilter, openSorting]);

  const handleSortingClick = useCallback(() => {
    if (openAccordianFilter === true) {
      setOpenAccordianFillter(!openAccordianFilter);
    }
    setOpenSorting(!openSorting);
  }, [openAccordianFilter, openSorting]);

  const handleClose = useCallback(() => {
    setOpenAccordianFillter(false);
  }, []);

  useEffect(() => {
    document.body.classList.toggle("no-scroll", openAccordianFilter || openSorting);
  }, [openAccordianFilter, openSorting]);

  return (
    <MobileSortingBlockContainer>
      <ButtonWrapper openAccordianFilter={openAccordianFilter} openSorting={openSorting}>
        <Button
          customClassName="sorting-button"
          icon={ArrowDownIcons}
          label="Röðun"
          stretch={true}
          onClick={handleSortingClick}
        />
        <Button
          customClassName="accordian-filter"
          icon={FilterIcons}
          label="Síur"
          stretch={true}
          onClick={handleAccordianClick}
        />
      </ButtonWrapper>

      {openSorting && <MobileSortingBlock sortingOptions={sortingOptions} />}

      {openAccordianFilter && (
        <MobileAccordionBlock
          configuration={configuration}
          handleClose={handleClose}
          handleReset={handleReset}
          handleSubmit={handleSubmit}
        />
      )}
    </MobileSortingBlockContainer>
  );
};
