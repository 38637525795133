/* eslint-disable @next/next/no-img-element */
/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import Link from "next/link";
import { useOnClickOutside } from "usehooks-ts";
import { useThrottle } from "react-use";

import { analytics } from "@byko/lib-analytics";
import { theme } from "@byko/lib-styles";
import { getPrice } from "@byko/lib-api-price";
import { ArrowRightIcons, LongArrowSideIcons } from "@byko/lib-icons";
import { PMediumBold } from "@byko/component-typography";
import type { OpeningHours } from "@byko/lib-api-dato-client";
import { useMegaMenuData, useOpeningHours } from "@byko/lib-api-dato-client";
import type { ProductList } from "@byko/lib-api-rest";
import { useAlgoliaInsights } from "@byko/lib-algolia";

import {
  CardWrapper,
  Heading,
  IconContainer,
  ImageContainer,
  ItemContainer,
  Overlay,
  ProductItem,
  ProductListContainer,
  ProductPrice,
  QuickSearchContainer,
  QuickSearchWrapper,
  Section,
  SectionDivider,
  SimpleListItem,
  Text,
} from "./styles";
import { useAlgoliaMultiQuery } from "../../api";
import type { AlgoliaEmployeeHit, AlgoliaPageHit } from "src/features/dato-algolia-sync/processors";
import type { SearchResponse } from "@algolia/client-search";
import {
  useSearchBarHasFocusStore,
  useSearchValue,
  useSetShowQuickSearchResultsValue,
  useShowQuickSearchResultsValue,
} from "src/features/search-provider";
import { OpeningHourCard } from "@byko/component-opening-hour-card";
import type { SalesAvailability } from "@byko/lib-api-products";
import { useAuth } from "@byko/hooks-auth-next";

interface MenuItem {
  description: string;
  slug: string;
  title: string;
  url: string;
  children: MenuItem[];
}

interface AlgoliaParemeters {
  index: string | undefined;
  queryID: string | undefined;
  position: number;
}

const PLACEHOLDER_IMAGE = "https://www.datocms-assets.com/65892/1664464657-placeholder-do-not-remove.jpg";

const openingHoursQueries = [
  "opn",
  "opnu",
  "opnun",
  "opnuna",
  "opnunar",
  "opnunart",
  "opnunartí",
  "opnunarti",
  "opnunartím",
  "opnunartim",
  "opnunartími",
  "opnunartimi",
  "opnunartíma",
  "opnunartima",
  "opnunartimar",
  "opnunartímar",
  "afgreiðslutími",
  "afgreiðslutíma",
  "afgreiðslutimar",
  "afgreiðslutímar",
  "afgreidslutimar",
  "breidd",
  "timburverslun",
  "lagnaverslun",
  "grandi",
  "akureyri",
  "selfoss",
  "suðurnes",
  "sudurnes",
  "fagverslun",
  "leiga og fagverslun",
  "bakkinn",
  "voruhus",
  "voruhús",
  "vöruhús",
  "vöruhus",
  "skemmuvegi",
  "skemmuvegur",
  "fiskislod",
  "fiskislóð",
  "sölufólk",
  "solufolk",
  "verslun",
  "verslanir",
  "selhella",
  "selhellu",
  "vöruhótel",
  "voruhótel",
  "vöruhotel",
  "voruhotel",
  "grófvörulager",
  "grofvorulager",
];

const specifycOpeningHoursQueries = [
  "breidd",
  "timburverslun",
  "lagnaverslun",
  "grandi",
  "akureyri",
  "selfoss",
  "suðurnes",
  "sudurnes",
  "fagverslun",
  "leiga og fagverslun",
  "bakkinn",
  "skemmuvegi",
  "skemmuvegur",
  "fiskislod",
  "fiskislóð",
  "selhella",
  "vöruhótel",
  "voruhótel",
  "vöruhotel",
  "voruhotel",
  "grófvörulager",
  "grofvorulager",
];

const ListProduct = ({
  product,
  listIndex,
  parameters,
  userHasContract,
}: {
  product: ProductList;
  listIndex: number;
  parameters: AlgoliaParemeters;
  userHasContract: boolean;
}) => {
  const setShowQuickSearch = useSetShowQuickSearchResultsValue();
  const { sendClickInsights } = useAlgoliaInsights();
  const salesAvailability: SalesAvailability = (product.salesAvailability as SalesAvailability) ?? "standard";
  /*
    "rental": rental products, not sold on web, no price display
    "custom": custom order, not sold on the web, no price display
    "limited": limited availability, not sold on the web, show price info
    "display": display only, not sold on the web, no price display
    "standard": standard products, display as is
  */

  const isRentProduct = useMemo(() => {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return product.attributes.typeParent.includes("Leigumarkaður");
    } catch {
      return false;
    }
  }, [product]);

  const lowestPrice = useMemo(() => {
    let price = 0;
    let hasVariationPrices = false;
    let hidePrice = false;
    let hidePriceLabel = false;

    if (isRentProduct || salesAvailability === "display" || salesAvailability === "custom") {
      return {
        availableSoon: false,
        hidePrice: true,
        isRentProduct,
        price: "N/A",
        label: "Sérpöntun",
      };
    }

    product.variants.forEach((variant) => {
      if (variant.price.gross != null) {
        if (price === 0 || variant.price.gross < price) {
          price = variant.price.gross;
        }
        if (price !== 0 && variant.price.gross !== price) {
          hasVariationPrices = true;
        }
      }
    });

    if (salesAvailability === "by_contract" && !userHasContract) {
      hidePrice = true;
      hidePriceLabel = true;
    }

    return {
      availableSoon: price === 0,
      isRentProduct,
      hidePrice,
      hidePriceLabel,
      price: getPrice(price)?.humanReadable,
      label: hasVariationPrices ? "Verð frá" : "Verð",
    };
  }, [isRentProduct, product.variants, salesAvailability]);

  const handleClick = useCallback(() => {
    setShowQuickSearch(false);
    analytics.addProductListClick(product, lowestPrice.price ?? "", listIndex, "quick-search-results");
    sendClickInsights({
      eventName: "Click Product Quick Search",
      index: parameters?.index || "",
      queryID: parameters?.queryID || "",
      objectIDs: [product.id.toString() || ""],
      positions: [parameters?.position],
    });
  }, [listIndex, lowestPrice.price, product, parameters, setShowQuickSearch, sendClickInsights]);

  const href = useMemo(() => {
    if (isRentProduct) {
      return `/leiguvara/${product.slug}`;
    }
    return `/vara/${product.slug}`;
  }, [isRentProduct, product.slug]);

  return (
    <Link href={href} passHref={true} onClick={handleClick}>
      <ItemContainer>
        <ImageContainer>
          <img
            alt={product.name}
            height={54}
            src={product.firstImage?.image.productList ?? PLACEHOLDER_IMAGE}
            width={66}
          />
        </ImageContainer>
        <ProductItem>
          <Text>{product.name}</Text>
          {/* 1 1 0 */}
          {lowestPrice.isRentProduct && lowestPrice.hidePrice && !lowestPrice.availableSoon && (
            <ProductPrice>Leiguvara</ProductPrice>
          )}
          {/* 0 0 1 */}
          {!lowestPrice.isRentProduct && !lowestPrice.hidePrice && lowestPrice.availableSoon && (
            <ProductPrice>Vara væntanleg</ProductPrice>
          )}
          {/* 0 0 0 */}
          {!lowestPrice.isRentProduct && !lowestPrice.hidePrice && !lowestPrice.availableSoon && (
            <ProductPrice>{`${lowestPrice.label}: ${lowestPrice.price}`}</ProductPrice>
          )}
          {/* 0 1 0 */}
          {!lowestPrice.isRentProduct &&
            lowestPrice.hidePrice &&
            !lowestPrice.availableSoon &&
            !lowestPrice.hidePriceLabel && <ProductPrice>{`${lowestPrice.label}`}</ProductPrice>}
        </ProductItem>
        <IconContainer>
          <ArrowRightIcons iconColor={theme.palette.gray[30]} size={16} />
        </IconContainer>
      </ItemContainer>
    </Link>
  );
};

const ListPage = ({ page, parameters }: { page: AlgoliaPageHit; parameters: AlgoliaParemeters }) => {
  const { sendClickInsights } = useAlgoliaInsights();

  const handleClick = useCallback(() => {
    sendClickInsights({
      eventName: "Click Content Quick Search",
      index: parameters?.index || "",
      queryID: parameters?.queryID || "",
      objectIDs: [page.objectID],
      positions: [parameters.position],
    });
  }, [page, parameters, sendClickInsights]);

  const absoluteSlug = page.slug[0] === "/" ? page.slug : `/${page.slug}`;

  return (
    <Link href={absoluteSlug} passHref={true} onClick={handleClick}>
      <ItemContainer>
        <SimpleListItem>
          <Text>{page.title}</Text>
        </SimpleListItem>
        <IconContainer>
          <ArrowRightIcons iconColor={theme.palette.gray[30]} size={16} />
        </IconContainer>
      </ItemContainer>
    </Link>
  );
};

const ListEmployee = ({ employee, parameters }: { employee: AlgoliaEmployeeHit; parameters: AlgoliaParemeters }) => {
  const { sendClickInsights } = useAlgoliaInsights();

  const handleClick = useCallback(() => {
    sendClickInsights({
      eventName: "Click Employee Quick Search",
      index: parameters?.index || "",
      queryID: parameters?.queryID || "",
      objectIDs: [employee.objectID],
      positions: [parameters.position],
    });
  }, [employee, parameters, sendClickInsights]);

  const absoluteSlug = `/thjonusta/mannaudur/starfsfolk?employee=${employee.name}`;

  return (
    <Link href={absoluteSlug} passHref={true} onClick={handleClick}>
      <ItemContainer>
        <SimpleListItem>
          <Text>{employee.name}</Text>
        </SimpleListItem>
        <IconContainer>
          <ArrowRightIcons iconColor={theme.palette.gray[30]} size={16} />
        </IconContainer>
      </ItemContainer>
    </Link>
  );
};

export const QuickSearchResults = () => {
  const quickSearchRef = useRef<HTMLDivElement>(null);
  const { user, activeMembership } = useAuth();
  const { data: openingHours } = useOpeningHours({});
  const { data } = useMegaMenuData();
  const searchValue = useSearchValue();
  const setShowQuickSearch = useSetShowQuickSearchResultsValue();
  const showQuickSearch = useShowQuickSearchResultsValue();
  const searchBarHasFocus = useSearchBarHasFocusStore();

  const query = useThrottle(searchValue, 1000);

  const { data: multiSearch } = useAlgoliaMultiQuery({ query, pageSize: 5 });

  const productQuery = multiSearch?.results?.[0] as SearchResponse<ProductList>;
  const contentQuery = multiSearch?.results?.[1] as SearchResponse<AlgoliaPageHit>;
  const employeeQuery = multiSearch?.results?.[2] as SearchResponse<AlgoliaEmployeeHit>;

  const employeeHitsToDisplay = employeeQuery?.hits.length > 5 ? employeeQuery?.hits.slice(0, 5) : employeeQuery?.hits;

  const contentPages = useMemo(() => {
    const collection: AlgoliaPageHit[] = [];
    if (data) {
      const adviceCollection = data.find((a: MenuItem) => a.slug === "god-rad")?.children ?? [];
      const specialService = data.find((a: MenuItem) => a.slug === "serlausnir")?.children ?? [];
      const service = data.find((a: MenuItem) => a.slug === "thjonusta")?.children ?? [];

      (!!contentQuery?.hits?.length &&
        contentQuery?.hits?.forEach((article) => {
          let matchingArticle: MenuItem | undefined;

          [...adviceCollection, ...service, ...specialService].forEach((levelOneItem: MenuItem) => {
            if (article.slug === levelOneItem.slug && levelOneItem.children.length === 0) {
              matchingArticle = levelOneItem;
            } else {
              levelOneItem.children.forEach((levelTwoItem: MenuItem) => {
                if (article.slug === levelTwoItem.slug && levelTwoItem.children.length === 0) {
                  matchingArticle = levelTwoItem;
                } else {
                  levelTwoItem.children.forEach((levelThreeItem: MenuItem) => {
                    if (article.slug === levelThreeItem.slug && levelThreeItem.children.length === 0) {
                      matchingArticle = levelThreeItem;
                    }
                  });
                }
              });
            }
          });

          if (matchingArticle == undefined || matchingArticle?.url == undefined) {
            return;
          }

          if (collection.length === 5) {
            return;
          }

          collection.push({
            ...article,
            slug: matchingArticle?.url,
          });
        })) ??
        [];
    }
    return collection;
  }, [data, contentQuery?.hits]);

  const closeMenu = useCallback(() => {
    setShowQuickSearch(false);
  }, [setShowQuickSearch]);

  useOnClickOutside(quickSearchRef, closeMenu);

  const isOpeningHoursQuery = useMemo(() => {
    const normalizedQuery = normalizeString(query);
    const foundOpeningHoursQuery = openingHoursQueries.find((opening) => {
      const norm = normalizeString(opening);
      return normalizedQuery === norm;
    });
    return foundOpeningHoursQuery != undefined;
  }, [query]);

  const filteredOpeningHours = useMemo(() => {
    const normalizedQuery = normalizeString(query);
    const foundSpecificQuery = specifycOpeningHoursQueries.find((specific) => {
      const norm = normalizeString(specific);
      return normalizedQuery === norm;
    });

    if (normalizedQuery === "" || foundSpecificQuery == undefined) {
      return openingHours;
    }

    const filteredLocation = (openingHours as OpeningHours).location.filter((location) => {
      const normalizedLocationName = normalizeString(location.locationName);
      const normalizedLocationAddress = normalizeString(location.locationAddress);
      const normalizedLocationType = normalizeString(location.locationType);

      return (
        normalizedLocationName.includes(normalizedQuery) ||
        normalizedLocationAddress.includes(normalizedQuery) ||
        normalizedLocationType.includes(normalizedQuery)
      );
    });

    return {
      ...openingHours,
      location: filteredLocation,
    };
  }, [openingHours, query]);

  useEffect(() => {
    if (searchValue === "") {
      setBodyScroll(true);
    } else {
      setBodyScroll(!showQuickSearch);
    }
  }, [showQuickSearch, searchValue]);

  const enableBodyScroll = useCallback(() => {
    setShowQuickSearch(false);
  }, [setShowQuickSearch]);

  if ((showQuickSearch === false && searchBarHasFocus === false) || searchValue === "") {
    return null;
  }

  if (isOpeningHoursQuery && openingHours) {
    return (
      <>
        <Overlay />
        <QuickSearchWrapper>
          <QuickSearchContainer ref={quickSearchRef} className="full-width">
            <Section>
              <Heading>
                <Link href="/thjonusta/um-byko/verslanir" passHref={true} onClick={enableBodyScroll}>
                  <PMediumBold textColor={theme.palette.blue.dark}>
                    Verslanir og afgreiðslutími <LongArrowSideIcons iconColor={theme.palette.blue.main} size={16} />
                  </PMediumBold>
                </Link>
              </Heading>
              <>
                <CardWrapper>
                  {(filteredOpeningHours as OpeningHours).location.map((location) => {
                    return location.openingHours.map((hours) => {
                      return (
                        <OpeningHourCard
                          key={hours.id}
                          id={hours.id}
                          openingTime={hours.hours}
                          storeLocation={location.locationAddress}
                          storeName={location.locationName}
                          storeType={location.locationType}
                        />
                      );
                    });
                  })}
                </CardWrapper>
              </>
            </Section>
          </QuickSearchContainer>
        </QuickSearchWrapper>
      </>
    );
  }

  if (
    searchValue !== "" &&
    (productQuery?.hits.length === 0 || productQuery == undefined) &&
    contentPages.length === 0 &&
    !employeeHitsToDisplay?.length
  ) {
    return (
      <QuickSearchWrapper>
        <QuickSearchContainer ref={quickSearchRef}>
          <Section>
            <Heading>
              <PMediumBold textColor={theme.palette.blue.dark}>Ekkert fannst</PMediumBold>
            </Heading>
          </Section>
        </QuickSearchContainer>
      </QuickSearchWrapper>
    );
  }

  return (
    <QuickSearchWrapper>
      <QuickSearchContainer ref={quickSearchRef}>
        {productQuery && productQuery?.hits.length > 0 && (
          <Section>
            <Heading>
              <PMediumBold textColor={theme.palette.blue.dark}>VÖRUR</PMediumBold>
            </Heading>
            <ProductListContainer>
              {productQuery.hits.map((product: ProductList, index) => (
                <ListProduct
                  key={product.id}
                  listIndex={index + 1}
                  parameters={{ index: productQuery?.index, queryID: productQuery?.queryID, position: index + 1 }}
                  product={product}
                  userHasContract={!!(user && activeMembership?.account.hasContract)}
                />
              ))}
            </ProductListContainer>
          </Section>
        )}
        {contentPages.length > 0 && (
          <>
            {!!productQuery?.hits.length && <SectionDivider />}
            <Section>
              <Heading>
                <PMediumBold textColor={theme.palette.blue.dark}>SÍÐUR</PMediumBold>
              </Heading>
              {contentPages.map((page, index) => (
                <ListPage
                  key={page.objectID}
                  page={page}
                  parameters={{ index: contentQuery?.index, queryID: contentQuery?.queryID, position: index + 1 }}
                />
              ))}
            </Section>
          </>
        )}
        {!!employeeHitsToDisplay?.length && (
          <>
            {(!!productQuery?.hits.length || !!contentPages.length) && <SectionDivider />}
            <Section>
              <Heading>
                <PMediumBold textColor={theme.palette.blue.dark}>STARFSFÓLK</PMediumBold>
              </Heading>
              {employeeHitsToDisplay?.map((employee, index) => (
                <ListEmployee
                  key={employee.objectID}
                  employee={employee}
                  parameters={{ index: employeeQuery?.index, queryID: employeeQuery?.queryID, position: index + 1 }}
                />
              ))}
            </Section>
          </>
        )}
      </QuickSearchContainer>
    </QuickSearchWrapper>
  );
};

function setBodyScroll(on: boolean) {
  if (on) {
    // Re-enable scrolling and remove the added padding.
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "";
    const HTML = document.getElementsByTagName("html");
    const NAVBAR = document.getElementById("navbar");
    const CHATBUTTON = document.getElementById("chat-button");

    if (HTML[0]) {
      HTML[0].style.overflow = "initial";
      HTML[0].style.overflowX = "hidden";
    }

    if (NAVBAR) {
      NAVBAR.style.paddingRight = "25px";
    }

    if (CHATBUTTON) {
      CHATBUTTON.style.right = "32px";
    }
  } else {
    // Disable scrolling and compensate for the scrollbar width.
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.overflow = "hidden";

    document.body.style.paddingRight = `${scrollbarWidth}px`;
    const HTML = document.getElementsByTagName("html");
    const NAVBAR = document.getElementById("navbar");
    const CHATBUTTON = document.getElementById("chat-button");

    if (HTML[0]) {
      HTML[0].style.overflow = "hidden";
    }

    if (NAVBAR) {
      NAVBAR.style.paddingRight = `calc(25px + ${scrollbarWidth}px)`;
    }

    if (CHATBUTTON) {
      CHATBUTTON.style.right = `calc(32px + ${scrollbarWidth}px)`;
    }
  }
}

function normalizeString(str: string) {
  return str
    .toLowerCase()
    .trim()
    .replace(" ", "")
    .replace(/ó/g, "o")
    .replace(/ö/g, "o")
    .replace(/á/g, "a")
    .replace(/í/g, "i")
    .replace(/ú/g, "u")
    .replace(/é/g, "e")
    .replace(/ð/g, "d")
    .replace(/þ/g, "th")
    .replace(/æ/g, "ae")
    .replace(/[^a-zA-Z0-9]/g, "");
}
