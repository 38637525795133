/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const MobileWhiteFilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 32 32" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M9.33335 31.3333C7.86069 31.3333 6.66669 30.085 6.66669 28.5454V3.33329C6.66669 1.86053 7.86059 0.666626 9.33335 0.666626H22.6667C24.1394 0.666626 25.3334 1.9149 25.3334 3.45451V28.5454C25.3334 30.085 24.1394 31.3333 22.6667 31.3333H9.33335Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M14.6667 28.6666C14.6667 27.9299 15.264 27.3333 16 27.3333C16.736 27.3333 17.3334 27.9299 17.3334 28.6666C17.3334 29.4033 16.736 29.9999 16 29.9999C15.264 29.9999 14.6667 29.4033 14.6667 28.6666Z"
        fill="#E6E7E8"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M8 3.99935C8 3.63147 8.29831 3.33325 8.66713 3.33325H23.3329C23.7013 3.33325 24 3.63653 24 3.99935V25.3338C24 25.7017 23.7017 25.9999 23.3329 25.9999H8.66713C8.29868 25.9999 8 25.6966 8 25.3338V3.99935Z"
        fill="#00416A"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M17.3334 16H14.6667C12.8258 16 11.3334 17.4924 11.3334 19.3333C11.3334 19.7015 11.6333 20 12.0046 20H19.9955C20.3662 20 20.6667 19.6979 20.6667 19.3333C20.6667 17.4924 19.1743 16 17.3334 16Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M16 14.6666C17.4728 14.6666 18.6667 13.4727 18.6667 11.9999C18.6667 10.5272 17.4728 9.33325 16 9.33325C14.5273 9.33325 13.3334 10.5272 13.3334 11.9999C13.3334 13.4727 14.5273 14.6666 16 14.6666Z"
        fill="white"
      />
    </svg>
  );
};
