import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const RemoveUserIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1363_7065)">
        <path
          d="M13.5 16H9C5.5 16 2 19.1337 2 23C2 23 23.958 23 24 23"
          stroke={iconColor}
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path d="M18 16L23 16" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
        <circle cx="12.5" cy="7.5" r="5.5" stroke={iconColor} strokeWidth="2" />
      </g>
      <defs>
        <clipPath id="clip0_1363_7065">
          <rect fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
};
