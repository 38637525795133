import styled from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";

import type { ButtonContainerProps } from "./interface";

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  .desktop-accordion-block {
    min-width: 320px;
    max-width: unset;
  }
  @media screen and (max-width: ${gridTheme.breakpoints.xs}px) {
    background-color: ${theme.palette.white.main};
  }
`;

export const ResultRows = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  gap: 80px;
`;

export const ResultsContextWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 56px;
  flex-direction: row;
  justify-content: space-evenly;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
`;

export const ButtonContainer = styled.div<ButtonContainerProps>`
  position: relative;
  width: 100%;
  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: ${(props): string => (props.active ? theme.palette.blue.main : theme.palette.transparent.main)};
    content: "";
    transition: 300ms;
  }
`;

export const ResultsRowWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 25px;
`;

export const ResultsRowHeader = styled.div`
  display: flex;
  width: 100%;
  max-width: ${gridTheme.maxContentWidth}px;
  align-items: center;
  justify-content: space-between;
  margin-right: auto;
  margin-left: auto;
`;

export const ResultsRowHeaderTitle = styled.div`
  display: flex;
  gap: 14px;
  p {
    line-height: 22px;
  }
`;

export const ResultsRowHeaderSeeAll = styled.div`
  display: flex;
  width: 260px;
  justify-content: flex-end;
`;

export const ResultsRowSwiper = styled.div`
  width: 100%;
  @media screen and (max-width: ${gridTheme.container.maxWidth.sm}px) {
    width: 100%;
  }
  .swiper-slide {
    width: 325px !important;
  }
  .product-card {
    width: 100% !important;
  }
  @media screen and (max-width: 375px) {
    .swiper-slide {
      width: calc(100% - 48px) !important;
    }
  }
  &.pages {
    .swiper-slide {
      width: 431px !important;
    }
    .product-card {
      width: 100% !important;
    }
    @media screen and (max-width: 375px) {
      .swiper-slide {
        width: calc(100% - 48px) !important;
      }
    }
  }
`;

export const NothingFoundContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const DesktopOnly = styled.div`
  display: flex;
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    display: none;
  }
`;

export const MobileOnly = styled.div`
  display: none;
  @media screen and (max-width: ${gridTheme.breakpoints.s}px) {
    display: flex;
  }
`;
