/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const GlobeFilledDecorative: BykoReactDecorativeIconComponentType = ({ size }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z"
        fill="#E6E7E8"
      />
      <path
        d="M24 3C12.4209 3 3 12.4204 3 24C3 35.5796 12.4209 45 24 45C35.5791 45 45 35.5796 45 24C45 12.4204 35.5791 3 24 3ZM5.05068 25H13.0256C13.0934 27.8687 13.4465 30.5658 14.0381 33H7.27288C5.975 30.5974 5.20168 27.8801 5.05068 25ZM25 13V5.1318C27.5506 5.7593 29.8107 8.7484 31.2738 13H25ZM31.8866 15C32.5201 17.4156 32.9005 20.1379 32.9752 23H25V15H31.8866ZM23 5.1318V13H16.7262C18.1893 8.7484 20.4494 5.7593 23 5.1318ZM23 15V23H15.0248C15.0995 20.1379 15.4799 17.4156 16.1134 15H23ZM13.0256 23H5.05068C5.20168 20.1199 5.975 17.4026 7.27288 15H14.0381C13.4465 17.4342 13.0934 20.1313 13.0256 23ZM15.0248 25H23V33H16.1134C15.4799 30.5844 15.0995 27.8621 15.0248 25ZM23 35V42.8682C20.4494 42.2407 18.1893 39.2516 16.7262 35H23ZM25 42.8682V35H31.2738C29.8107 39.2516 27.5506 42.2407 25 42.8682ZM25 33V25H32.9752C32.9005 27.8621 32.5201 30.5844 31.8866 33H25ZM34.9744 25H42.9493C42.7983 27.8801 42.025 30.5974 40.7271 33H33.9619C34.5535 30.5658 34.9066 27.8687 34.9744 25ZM34.9744 23C34.9066 20.1313 34.5535 17.4342 33.9619 15H40.7271C42.025 17.4026 42.7983 20.1199 42.9493 23H34.9744ZM39.4631 13H33.404C32.4645 10.0355 31.1542 7.5772 29.5812 5.8395C33.6132 7.0812 37.0651 9.6392 39.4631 13ZM18.4188 5.8395C16.8458 7.5772 15.5355 10.0355 14.596 13H8.53688C10.9349 9.6392 14.3868 7.0812 18.4188 5.8395ZM8.53688 35H14.596C15.5355 37.9645 16.8458 40.4228 18.4188 42.1605C14.3868 40.9188 10.9349 38.3608 8.53688 35ZM29.5812 42.1605C31.1542 40.4228 32.4645 37.9645 33.404 35H39.4631C37.0651 38.3608 33.6132 40.9188 29.5812 42.1605Z"
        fill="#0A3A5C"
      />
      <path
        d="M16 19C17.6569 19 19 17.6569 19 16C19 14.3431 17.6569 13 16 13C14.3431 13 13 14.3431 13 16C13 17.6569 14.3431 19 16 19Z"
        fill="#FFD330"
      />
      <path
        d="M16 20C13.7939 20 12 18.2056 12 16C12 13.7944 13.7939 12 16 12C18.2061 12 20 13.7944 20 16C20 18.2056 18.2061 20 16 20ZM16 14C14.8975 14 14 14.897 14 16C14 17.103 14.8975 18 16 18C17.1025 18 18 17.103 18 16C18 14.897 17.1025 14 16 14Z"
        fill="white"
      />
      <path
        d="M33 26C34.6569 26 36 24.6569 36 23C36 21.3431 34.6569 20 33 20C31.3431 20 30 21.3431 30 23C30 24.6569 31.3431 26 33 26Z"
        fill="#FFD330"
      />
      <path
        d="M33 27C30.7939 27 29 25.2056 29 23C29 20.7944 30.7939 19 33 19C35.2061 19 37 20.7944 37 23C37 25.2056 35.2061 27 33 27ZM33 21C31.8975 21 31 21.897 31 23C31 24.103 31.8975 25 33 25C34.1025 25 35 24.103 35 23C35 21.897 34.1025 21 33 21Z"
        fill="white"
      />
      <path
        d="M24 37C25.6569 37 27 35.6569 27 34C27 32.3431 25.6569 31 24 31C22.3431 31 21 32.3431 21 34C21 35.6569 22.3431 37 24 37Z"
        fill="#FFD330"
      />
      <path
        d="M24 38C21.7939 38 20 36.2056 20 34C20 31.7944 21.7939 30 24 30C26.2061 30 28 31.7944 28 34C28 36.2056 26.2061 38 24 38ZM24 32C22.8975 32 22 32.897 22 34C22 35.103 22.8975 36 24 36C25.1025 36 26 35.103 26 34C26 32.897 25.1025 32 24 32Z"
        fill="white"
      />
    </svg>
  );
};
