import React, { useCallback, useEffect, useMemo, useState } from "react";
import cn from "classnames";

import { Button } from "@byko/component-buttons";
import { Input } from "@byko/component-inputs";
import { DotLoader, MainLoader } from "@byko/component-loaders";
import { useAlgoliaInsights } from "@byko/lib-algolia";

import { QuantityInput } from "@byko/component-quantity-input";
import { Select } from "@byko/component-select";
import { Checkbox } from "@byko/component-selectors";
import { Tooltip } from "@byko/component-tooltip";
import { PSmallBold } from "@byko/component-typography";
import { useAuth } from "@byko/hooks-auth-next";
import { useCart } from "@byko/hooks-cart";
import { useProductInventory, useProductVariants } from "@byko/hooks-product-variants";
import { getPrice } from "@byko/lib-api-price";
import { useCartItemCount } from "@byko/lib-api-products";
import { ArrowDownIcons, ArrowUpIcons, BeraSamanIcons, CartIcons } from "@byko/lib-icons";
import { gridTheme, theme, useWindowSize } from "@byko/lib-styles";
import { analytics } from "@byko/lib-analytics";
import { useCompareProducts } from "@byko/hooks-compare-products";
import { StockStatus } from "./stock-status";
import { useCommonContext } from "src/features/context";

import {
  ActionRow,
  Brand,
  ButtonFlexRow,
  ButtonLoadingWrapper,
  CalculatorRow,
  CheckboxContainer,
  CrossInfoBlock,
  Descriptions,
  DiscountedPrice,
  EnergyWrapper,
  FavAndQtyButtons,
  FlexColumn,
  FlexRow,
  HiddenAttribute,
  LoadingWrapper,
  OriginalPrice,
  Price,
  PriceContainer,
  PriceText,
  ProductDetailContainer,
  ProductName,
  ReadMoreButton,
  ReadMoreContainer,
  SkuNumber,
  TooltipContainer,
  VariantSelectionOptions,
} from "./styles";

import type { ProductDetail as ProductDetailType, VariantDetail } from "@byko/lib-api-rest";
import type { Maybe } from "@byko/types-utils";
import type { ChangeEvent } from "react";
import type { ProductVariantPrices } from "@byko/lib-api-products";

const squareMeterExplanation =
  // eslint-disable-next-line max-len
  "Fjöldi pakka ræðst af því hversu marga fermetra þú þarft. Til að vera viss um að þú hafir nóg vegna afskurðar getur þú hakað í bæta 10% við fermetrafjölda.";

export interface ProductStandardDetailProps {
  product: ProductDetailType;
  brand?: Maybe<string>;
  activeVariant: VariantDetail;
  setActiveVariantSKU: (value: string) => void;
  skuPrices: ProductVariantPrices | undefined;
  EnergyLabel?: React.FC | null;
}

export const ProductStandardDetail = ({
  brand,
  product,
  activeVariant,
  setActiveVariantSKU,
  skuPrices,
  EnergyLabel = null,
}: ProductStandardDetailProps): JSX.Element => {
  const { addProduct, products } = useCompareProducts();
  const { user } = useAuth();
  const activeVariantSKU = useMemo(() => {
    if (activeVariant) {
      return activeVariant.sku as string;
    } else {
      return "";
    }
  }, [activeVariant]);
  const {
    activeColor,
    colorSelectionList,
    permitPurchase,
    sizeSelectionList,
    activeSize,
    handleColorChange,
    handleSizeChange,
    errorMessage,
  } = useProductVariants(product, activeVariantSKU, setActiveVariantSKU, null);

  const { setCartQuantity, isLoading } = useCart();
  const currentCount = useCartItemCount(activeVariant?.id ?? null);
  const [activeCalculation, setActiveCalculation] = useState<"m2" | "package">("m2");
  const [packageInputValue, setPackageInputValue] = useState<string>("1");
  const [m2InputValue, setM2InputValue] = useState<string>("0");
  const [count, setCount] = useState<number>(1);
  const [addTenPercent, setAddTenPercent] = useState<boolean>(false);
  const activeVariantPrice = skuPrices?.[activeVariantSKU];
  const [firedAnalyticsImpression, setFiredAnalyticsImpression] = useState<boolean>(false);
  const { sendAddToCartInsights } = useAlgoliaInsights();

  const { warehouses } = useCommonContext();
  const { inventory, availability } = useProductInventory({ productSlug: product.slug, activeVariantSKU, warehouses });

  const quantityInM2 = useMemo((): string => {
    if (activeVariantPrice?.isSqmProduct) {
      if (activeVariantPrice?.price.perSqm && activeVariantPrice?.packagingQuantity) {
        const pricePerUnit = activeVariantPrice?.price.gross / activeVariantPrice?.packagingQuantity;
        const quantityPerSqm = activeVariantPrice.price.perSqm / pricePerUnit;
        return quantityPerSqm.toString();
      }
    }
    return "0";
  }, [activeVariantPrice]);

  const handleM2InputValueChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const regex = /^[0-9]*[.,]?[0-9]*$/;

      if (regex.test(value)) {
        const priceValue = activeVariantPrice?.packagingQuantity;
        if (priceValue != undefined) {
          const packageValue = (parseFloat(value) * parseFloat(quantityInM2)) / priceValue;
          setPackageInputValue(Math.ceil(packageValue).toString());
        }
        setM2InputValue(value);
      }
    },
    [activeVariantPrice, quantityInM2],
  );

  const handlePackageInputValueChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const regex = /^[0-9]*$/;
      if (regex.test(value)) {
        setPackageInputValue(value);
        const packagingQuantity = activeVariantPrice?.packagingQuantity;
        if (packagingQuantity) {
          const totalPieces = packagingQuantity * parseInt(value, 10);
          const sqm = totalPieces / parseFloat(quantityInM2);
          setM2InputValue(sqm.toFixed(2));
        }
      }
    },
    [activeVariantPrice?.packagingQuantity, quantityInM2],
  );

  const actualM2 = useMemo(() => {
    const packagingQuantity = activeVariantPrice?.packagingQuantity;
    if (packagingQuantity) {
      const totalPieces = packagingQuantity * parseFloat(packageInputValue);
      const sqm = totalPieces / parseFloat(quantityInM2);
      return sqm.toFixed(2);
    }

    return 0;
  }, [activeVariantPrice?.packagingQuantity, packageInputValue, quantityInM2]);

  useEffect(() => {
    if (parseFloat(m2InputValue) === 0 || m2InputValue === "0") {
      setM2InputValue(actualM2.toString());
    }
  }, [actualM2, m2InputValue]);

  const handleCalculationSwap = useCallback(() => {
    if (m2InputValue === "NaN" || m2InputValue === "" || packageInputValue === "NaN" || packageInputValue === "") {
      setM2InputValue("1");
      setPackageInputValue("1");
    }
    if (activeCalculation === "m2") {
      setActiveCalculation("package");
    } else {
      setActiveCalculation("m2");
    }
  }, [activeCalculation, m2InputValue, packageInputValue]);

  const price = useMemo(() => {
    if (activeVariantPrice && activeVariantPrice.discounted && activeVariantPrice.price) {
      return {
        discounted: {
          gross: getPrice(activeVariantPrice?.discounted?.gross ?? 0),
          net: getPrice(activeVariantPrice?.discounted?.net ?? 0),
        },
        price: {
          gross: getPrice(activeVariantPrice?.price?.gross ?? 0),
          net: getPrice(activeVariantPrice?.price?.net ?? 0),
        },
      };
    } else if (activeVariantPrice && activeVariantPrice.price) {
      return {
        discounted: null,
        price: {
          gross: getPrice(activeVariantPrice?.price?.gross ?? 0),
          net: getPrice(activeVariantPrice?.price?.net ?? 0),
        },
      };
    } else {
      return null;
    }
  }, [activeVariantPrice]);

  useEffect(() => {
    if (activeVariantPrice?.isSqmProduct) {
      const sqmItemCount = parseInt(packageInputValue, 10);
      if (!isNaN(sqmItemCount)) {
        setCount(sqmItemCount);
      }
    }
  }, [activeVariantPrice?.isSqmProduct, packageInputValue]);

  const handleCheckboxChange = useCallback(() => {
    const value = parseFloat(m2InputValue);
    const priceValue = activeVariantPrice?.packagingQuantity;

    if (addTenPercent) {
      const result = parseFloat((value / 1.1).toFixed(2));
      setM2InputValue(`${result}`);
      if (priceValue != undefined) {
        const packageValueBefore = (result * parseFloat(quantityInM2)) / priceValue;
        const packageValue = (Math.floor(packageValueBefore * 100) / 100).toFixed(2);
        setPackageInputValue(Math.ceil(parseFloat(packageValue)).toString());
      }
    } else {
      const result = parseFloat((value * 1.1).toFixed(2));
      setM2InputValue(`${result}`);
      if (priceValue != undefined) {
        const packageValue = (result * parseFloat(quantityInM2)) / priceValue;
        setPackageInputValue(Math.ceil(packageValue).toString());
      }
    }
    setAddTenPercent(!addTenPercent);
  }, [activeVariantPrice?.packagingQuantity, addTenPercent, m2InputValue, quantityInM2]);

  const productNotAvailableYet = useMemo(() => {
    return activeVariantPrice?.price.gross === 0;
  }, [activeVariantPrice?.price.gross]);

  const { width } = useWindowSize();

  const unitLabel = useMemo(() => {
    if (activeVariant?.meta.unit) {
      return activeVariant?.meta.unit;
    } else {
      const units = activeVariant?.attributes["unit"]?.toString().split(";") ?? [];
      if (units.length === 0) {
        return "";
      }
      return units[units.length - 1];
    }
  }, [activeVariant?.attributes, activeVariant?.meta.unit]);

  const handleItemToString = useCallback((option: { label?: string | null } | null): string => option?.label ?? "", []);

  const showReadMore = product?.description?.is?.length > 500;

  const [_showMore, setShowMore] = useState<boolean>(false);
  const showMore = _showMore || !showReadMore;

  const handleAddToCart = useCallback(() => {
    setCartQuantity({ variantId: activeVariant?.id, slug: product?.slug ?? "", quantity: count + currentCount });

    sendAddToCartInsights({
      eventName: "Add to Cart",
      objectIDs: [product?.id.toString() || ""],
    });
  }, [setCartQuantity, activeVariant?.id, product?.slug, product?.id, count, currentCount, sendAddToCartInsights]);

  const handleReadMore = useCallback(() => {
    setShowMore(!showMore);
  }, [showMore]);

  const descriptionHTML = useMemo(
    () => ({
      __html: product?.description?.is ?? "",
    }),
    [product?.description?.is],
  );

  const addToComparison = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      addProduct(product.slug);
    },
    [addProduct, product.slug],
  );

  useEffect(() => {
    if (!firedAnalyticsImpression) {
      setFiredAnalyticsImpression(true);
      let analyticsPrice = "0";
      try {
        analyticsPrice = activeVariant?.price?.gross?.toString() ?? "0";
      } catch (e) {
        // silent
      }
      analytics.addProductPageViewImpression(product, analyticsPrice);
    }
  }, [activeVariant?.price?.gross, firedAnalyticsImpression, product]);

  const eanNumber = activeVariant?.attributes["eanNumber"];

  return (
    <ProductDetailContainer>
      <HiddenAttribute id="product-ean-numbers">{eanNumber}</HiddenAttribute>
      <FlexRow>
        <Brand>{brand ?? ""}</Brand>
        <SkuNumber>VNR. {activeVariant?.sku}</SkuNumber>
      </FlexRow>
      <ProductName fontWeight="400" maxFontSize="48px" textColor={theme.palette.blue.dark} uppercase={true}>
        {product?.name}
      </ProductName>
      <Descriptions className={cn({ showMore })} dangerouslySetInnerHTML={descriptionHTML} />
      {EnergyLabel && (
        <EnergyWrapper>
          <EnergyLabel />
        </EnergyWrapper>
      )}
      {showReadMore && (
        <ReadMoreContainer>
          <ReadMoreButton className={cn({ showMore })} onClick={handleReadMore}>
            {showMore ? "Lesa minna " : "Lesa meira"}
            {showMore ? <ArrowUpIcons size={16} /> : <ArrowDownIcons size={16} />}
          </ReadMoreButton>
        </ReadMoreContainer>
      )}
      {productNotAvailableYet ? <Price maxFontSize="24px">Vara væntanleg</Price> : null}
      {!productNotAvailableYet ? (
        <PriceContainer>
          {price !== null ? (
            <FlexRow>
              {activeVariantPrice?.isSqmProduct ? (
                <FlexColumn>
                  <PriceText>Fermetraverð</PriceText>
                  {activeVariantPrice?.discounted?.perSqm ? (
                    <DiscountedPrice maxFontSize="16px">
                      {getPrice(activeVariantPrice?.price?.perSqm ?? 0)?.humanReadable} /m²
                    </DiscountedPrice>
                  ) : null}
                  <Price maxFontSize="36px">
                    {price?.discounted?.gross?.original
                      ? getPrice(activeVariantPrice?.discounted?.perSqm ?? 0)?.humanReadable
                      : getPrice(activeVariantPrice?.price?.perSqm ?? 0)?.humanReadable}{" "}
                    /m²
                  </Price>
                </FlexColumn>
              ) : (
                <FlexColumn>
                  <PriceText>{user ? "MITT VERÐ" : "VERÐ"}</PriceText>
                  <Price maxFontSize="36px">
                    {price?.discounted?.gross?.humanReadable ?? price?.price?.gross?.humanReadable}
                  </Price>
                </FlexColumn>
              )}
              {activeVariantPrice?.isSqmProduct ? (
                <FlexColumn>
                  <PriceText>{user ? "MITT VERÐ" : "VERÐ"}</PriceText>
                  <DiscountedPrice maxFontSize="16px">
                    {price?.discounted?.gross?.original
                      ? `${getPrice(price?.price?.gross?.original ?? 0)?.humanReadable} /pk`
                      : null}
                  </DiscountedPrice>
                  <Price maxFontSize="36px">
                    {price?.discounted?.gross?.original
                      ? `${getPrice(price?.discounted?.gross?.original)?.humanReadable} /pk`
                      : `${getPrice(price?.price?.gross?.original ?? 0)?.humanReadable} /pk`}
                  </Price>
                </FlexColumn>
              ) : (
                <FlexColumn>
                  {price?.discounted?.gross?.humanReadable ? (
                    <>
                      <PriceText>Verð áður</PriceText>
                      <OriginalPrice maxFontSize="36px">{price?.price.gross?.humanReadable}</OriginalPrice>
                    </>
                  ) : null}
                </FlexColumn>
              )}
            </FlexRow>
          ) : (
            <LoadingWrapper>
              <DotLoader size={84} />
            </LoadingWrapper>
          )}
        </PriceContainer>
      ) : null}
      {activeVariantPrice?.isSqmProduct ? (
        <>
          <CalculatorRow>
            {activeCalculation === "package" ? (
              <>
                <TooltipContainer className="short">
                  <Tooltip arrowLocation="left" content={squareMeterExplanation} />
                </TooltipContainer>
                <Input
                  label="Pakkar"
                  stretch={true}
                  value={packageInputValue}
                  onChange={handlePackageInputValueChange}
                />
                <CrossInfoBlock>
                  <PSmallBold textColor={theme.palette.blue.dark}>{actualM2} m2</PSmallBold>
                </CrossInfoBlock>
              </>
            ) : (
              <>
                <TooltipContainer>
                  <Tooltip arrowLocation="left" content={squareMeterExplanation} />
                </TooltipContainer>
                <Input
                  label="Fermetrar"
                  stretch={true}
                  type="number"
                  value={m2InputValue}
                  onChange={handleM2InputValueChange}
                />
                <CrossInfoBlock>
                  <PSmallBold textColor={theme.palette.blue.dark}>
                    {isNaN(parseInt(packageInputValue, 10)) ? 0 : packageInputValue} pk
                  </PSmallBold>
                </CrossInfoBlock>
              </>
            )}
            <Button
              className="desktop-button"
              icon={BeraSamanIcons}
              label={activeCalculation === "package" ? "Breyta í fermetra" : "Breyta í pakka"}
              onClick={handleCalculationSwap}
            />
          </CalculatorRow>

          <ActionRow>
            <Button
              className="mobile-button"
              icon={BeraSamanIcons}
              label={activeCalculation === "package" ? "Breyta í fermetra" : "Breyta í pakka"}
              onClick={handleCalculationSwap}
            />
            {activeCalculation !== "package" ? (
              <CheckboxContainer>
                <Checkbox
                  checked={addTenPercent}
                  disabled={isNaN(parseInt(packageInputValue, 10)) || packageInputValue === "0"}
                  label="Bæta 10% við fermetratölu vegna afskurðar"
                  smallBox={true}
                  onChange={handleCheckboxChange}
                />
              </CheckboxContainer>
            ) : null}
          </ActionRow>
          {isNaN(parseInt(packageInputValue, 10)) === false ? (
            <FlexColumn>
              <PriceText>{"HEILDARVERÐ"}</PriceText>
              <Price maxFontSize="36px">
                {price?.discounted?.gross?.original
                  ? `${
                      getPrice((price?.discounted?.gross?.original ?? 0) * parseInt(packageInputValue, 10))
                        ?.humanReadable
                    }`
                  : `${
                      getPrice((price?.price?.gross?.original ?? 0) * parseInt(packageInputValue, 10))?.humanReadable
                    }`}
              </Price>
            </FlexColumn>
          ) : (
            <FlexColumn>
              <PriceText>{"HEILDARVERÐ"}</PriceText>
              <Price maxFontSize="36px">0 kr.</Price>
            </FlexColumn>
          )}
        </>
      ) : null}
      <ButtonFlexRow>
        {activeVariantPrice?.isSqmProduct !== true ? (
          <FavAndQtyButtons>
            <QuantityInput
              quantity={count}
              quantityUnit={unitLabel ?? ""}
              setQuantity={setCount}
              stretch={width < gridTheme.container.maxWidth.s ? true : false}
            />
          </FavAndQtyButtons>
        ) : null}
        <>
          {isLoading ? (
            <ButtonLoadingWrapper>
              <MainLoader size={54} />
            </ButtonLoadingWrapper>
          ) : (
            <Button
              buttonColor="blueButton"
              disabled={
                productNotAvailableYet ||
                availability.soldOutInWebstore ||
                !permitPurchase ||
                (activeVariantPrice?.isSqmProduct &&
                  (isNaN(parseInt(packageInputValue, 10)) || packageInputValue === "0"))
              }
              icon={CartIcons}
              label={
                availability.soldOutInWebstore && inventory && !productNotAvailableYet
                  ? "Uppselt í vefverslun"
                  : "Bæta í körfu"
              }
              stretch={true}
              onClick={handleAddToCart}
            />
          )}
        </>
      </ButtonFlexRow>
      <VariantSelectionOptions>
        {colorSelectionList.length > 1 && (
          <Select
            align="left"
            // disableInactive={true}  FIXME
            fit={true}
            handleSelectedItemChange={handleColorChange}
            hideDefaultPlaceholder={true}
            hideValue={true}
            items={colorSelectionList}
            itemToString={handleItemToString}
            placeholder={activeColor?.label ?? "Veldu lit"}
            selectedItem={activeColor}
            textColor={activeColor?.label ? theme.palette.blue.main : theme.palette.gray[40]}
          />
        )}
        {sizeSelectionList.length > 1 && (
          <Select
            align="left"
            // disableInactive={true}  FIXME
            fit={true}
            handleSelectedItemChange={handleSizeChange}
            hideDefaultPlaceholder={true}
            hideValue={true}
            items={sizeSelectionList}
            itemToString={handleItemToString}
            placeholder={activeSize?.label ?? "Veldu stærð"}
            selectedItem={activeSize}
            textColor={activeSize?.label ? theme.palette.blue.main : theme.palette.gray[40]}
          />
        )}
        {/*
        {/*
        BYKO-121 Disable compare button temperorarily
        <Button
          disabled={products.length === 4}
          icon={BeraSamanIcons}
          label="Setja í samanburð"
          stretch={true}
          onClick={addToComparison}
        /> */}
      </VariantSelectionOptions>

      <StockStatus activeVariantSKU={activeVariantSKU} errorMessage={errorMessage} productSlug={product.slug} />
    </ProductDetailContainer>
  );
};
