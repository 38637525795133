import { theme } from "@byko/lib-styles";
import styled from "styled-components";

export const Overlay = styled.div`
  position: fixed;
  z-index: 501;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px) grayscale(0.4);
`;

export const QuickSearchWrapper = styled.div`
  position: relative;
  height: 100%;
  background-color: red;
`;

export const QuickSearchContainer = styled.div`
  position: fixed;
  z-index: 502;
  top: 88px;
  left: 392px;
  display: flex;
  width: 819px;
  max-height: calc(100dvh - 110px);
  flex-direction: column;
  border-right: 1px solid ${theme.palette.gray[10]};
  border-bottom: 1px solid ${theme.palette.gray[10]};
  border-left: 1px solid ${theme.palette.gray[10]};
  background-color: ${theme.palette.white.main};
  overflow-y: auto;
  padding-block: 16px;
  padding-inline: 32px;
  @media screen and (max-width: 1220px) {
    width: calc(100vw - 392px);
  }
  @media screen and (max-width: 1078px) {
    left: 0px;
    width: 100%;
    margin-top: 24px;
  }

  &.full-width {
    z-index: 999999;
    left: 0;
    width: 100%;
    max-height: calc(100dvh - 210px);
    padding: 16px;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SectionDivider = styled.div`
  display: flex;
  width: 2px;
  flex-grow: 1;
  margin-top: 48px;
  background-color: ${theme.palette.gray[5]};
`;

export const Heading = styled.div`
  padding-top: 6px;
  background-color: ${theme.palette.gray[5]};
  padding-inline: 8px;
`;

export const CardWrapper = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  .opening-hours-card {
    &:last-child {
      min-width: 100%;
    }
  }
  @media screen and (max-width: 1500px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 11px;
  padding-block: 8px;
  img {
    object-fit: contain;
  }
`;

export const SimpleListItem = styled.div`
  display: flex;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

export const ProductItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 48px;
  padding-bottom: 11px;
  gap: 8px;
`;

export const ProductListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
`;

export const Text = styled.p`
  color: ${theme.palette.blue.dark};
  font-size: 16px;
  user-select: none;
`;

export const ProductPrice = styled.p`
  color: ${theme.palette.gray[50]};
  font-size: 14px;
  user-select: none;
`;

export const ProductPriceLabel = styled.p`
  margin: 0;
  color: ${theme.palette.gray[50]};
  font-size: 14px;
  user-select: none;
`;

export const ItemContainer = styled.div`
  position: relative;
  display: flex;
  border-bottom: 1px solid ${theme.palette.gray[10]};
  transition: 300ms;
  &:hover {
    border-bottom: 1px solid ${theme.palette.blue.main};
    cursor: pointer;
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  right: 24px;
  display: flex;
  height: 100%;
  align-items: center;
`;
