import { useRouter } from "next/router";
import { useCallback, useEffect, useRef } from "react";

import { useDebounce, usePrevious } from "@byko/lib-utils";

import type { RefObject } from "react";
import { useSetSearchValue } from "./features/search-provider";

const ENABLE_LOADER = true;
const SHOW_LOADER_DELAY_MS = 200;
const HIDE_LOADER_DELAY_MS = 100;

export const useLoader = (ref: RefObject<HTMLDivElement>): void => {
  const router = useRouter();
  const handleHideLoaderRef = useRef<ReturnType<typeof useDebounce> | null>(null);
  const handleShowLoaderRef = useRef<ReturnType<typeof useDebounce> | null>(null);
  const previousUrl = usePrevious(router?.pathname);
  const previousUrlRef = useRef(previousUrl);
  previousUrlRef.current = previousUrl;

  const showLoader = useCallback((url: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window as any).HIDE_URL === url) {
      return;
    }
    if (previousUrlRef.current && previousUrlRef.current.startsWith("/mitt")) {
      return;
    }
    if (!ref.current || !ENABLE_LOADER) {
      return;
    }
    ref.current.style.opacity = "1";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const hideLoader = useCallback((url: string) => {
    if (!ref.current || !ENABLE_LOADER) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).HIDE_URL = url;
    handleShowLoaderRef.current?.cancel();
    ref.current.style.opacity = "0";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowLoader = useDebounce(showLoader, SHOW_LOADER_DELAY_MS);
  const handleHideLoader = useDebounce(hideLoader, HIDE_LOADER_DELAY_MS);

  handleShowLoaderRef.current = handleShowLoader;
  handleHideLoaderRef.current = handleHideLoader;

  useEffect(() => {
    if (!router?.events || !router.isReady) {
      return () => {
        // Empty on purpose
      };
    }
    router.events.on("routeChangeComplete", handleHideLoader);
    router.events.on("routeChangeError", handleHideLoader);
    router.events.on("routeChangeStart", handleShowLoader);
    return () => {
      router.events.off("routeChangeComplete", handleHideLoader);
      router.events.off("routeChangeError", handleHideLoader);
      router.events.off("routeChangeStart", handleShowLoader);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const useLoaderClearSearch = ({ searchRoutes }: { searchRoutes: string[] }): void => {
  const setSearch = useSetSearchValue();

  const handleClearSearch = useCallback(
    (url: string) => {
      for (const route of searchRoutes) {
        if (url.startsWith(route)) {
          return;
        }
      }
      setSearch("");
    },
    [searchRoutes, setSearch],
  );
  const handleClearSearchRef = useRef(handleClearSearch);
  handleClearSearchRef.current = handleClearSearch;
  const router = useRouter();

  useEffect(() => {
    if (!router?.events || !router.isReady) {
      return () => {
        // Empty on purpose
      };
    }

    router.events.on("routeChangeComplete", handleClearSearch);
    return () => {
      router.events.off("routeChangeComplete", handleClearSearch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const ClearSearchOnPageLoad = ({ searchRoutes }: { searchRoutes: string[] }): null => {
  useLoaderClearSearch({ searchRoutes });
  return null;
};
