import * as React from "react";

import { CancelSmallIcons, SearchIcons } from "@byko/lib-icons";
import { theme } from "@byko/lib-styles";

import { ClearBtn, FocusLine, InputContainer, Placeholder, PrefixIcon, SearchInputField } from "./styles";

import type { SearchInputProps } from "./interface";

export const SearchInput: React.FC<SearchInputProps> = ({
  placeholder,
  onClick,
  isDirty,
  noIcon = false,
  iconColor,
  border = false,
  onIconClick,
  ...props
}) => {
  return (
    <InputContainer className="search-input-container">
      <SearchInputField
        border={border}
        className="search-input"
        placeholder={placeholder}
        {...props}
        aria-label="leit"
        type="text"
      />
      <Placeholder>{placeholder ? placeholder : "Hvað viltu finna?"}</Placeholder>
      <FocusLine />
      {isDirty ? (
        <ClearBtn onClick={onClick}>
          <PrefixIcon>{!noIcon && <CancelSmallIcons size={22} />}</PrefixIcon>
        </ClearBtn>
      ) : (
        <PrefixIcon>
          {!noIcon && onIconClick && (
            <button type="button" onClick={onIconClick}>
              <SearchIcons iconColor={iconColor || theme.palette.blue.main} size={24} />
            </button>
          )}
          {!noIcon && !onIconClick && <SearchIcons iconColor={iconColor || theme.palette.blue.main} size={24} />}
        </PrefixIcon>
      )}
    </InputContainer>
  );
};
