import styled from "styled-components";

import { theme } from "@byko/lib-styles";

export const CardContainer = styled.div`
  padding: 24px 32px;
  background-color: ${theme.palette.white.main};
  box-shadow: 0px 18px 45px ${theme.palette.shadow.main};
`;

export const CardHeadingContainer = styled.div`
  padding-bottom: 16px;
  border-bottom: 1px solid ${theme.palette.gray[20]};
`;

export const CardContentContainer = styled.div``;
