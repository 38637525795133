/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const PlantGroundLineDecorative: BykoReactDecorativeIconComponentType = ({ size, color = "#0A3A5C" }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 48 48" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36 18H34C28.4771 18 24 22.4771 24 28V32H26C31.5229 32 36 27.5229 36 22V18Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path
        d="M12 8H14C19.5229 8 24 12.4771 24 18V22H22C16.4771 22 12 17.5229 12 12V8Z"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
      <path d="M24 38.2527V2" stroke={color} strokeMiterlimit="10" strokeWidth="2.5" />
      <path
        d="M2 46H12C12 43.7908 13.7909 42 16 42C16.9974 42 17.8983 42.3782 18.5989 42.9821C19.791 40.0618 22.6509 38 26 38C30.4183 38 34 41.5817 34 46H46"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
    </svg>
  );
};
