import { useSelect } from "downshift";
import * as React from "react";

import { ArrowDownIcons, ArrowUpIcons, LongArrowSideIcons } from "@byko/lib-icons";

import {
  FocusLine,
  IconWrapper,
  ListItem,
  Menu,
  MenuWrapper,
  PlaceholderText,
  SelectBtn,
  SelectContainer,
} from "./styles";

import type { ExtendedSelectProps } from "./interface";

export function Select<Item>({
  items,
  placeholder,
  align = "left",
  fit,
  withIcon = false,
  hideValue = false,
  textColor,
  selectedItem: initialSelectedItem,
  hideDefaultPlaceholder = false,
  itemToString = (item): string => item as unknown as string,
  handleSelectedItemChange,
  className = "select-container",
}: ExtendedSelectProps<Item>): JSX.Element {
  const {
    //
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    selectedItem,
    highlightedIndex,
  } = useSelect({
    items,
    selectedItem: initialSelectedItem ? initialSelectedItem : null,
    itemToString,
    onSelectedItemChange: handleSelectedItemChange
      ? handleSelectedItemChange
      : (): void => {
          // Empty on purpose
        },
  });

  return (
    <SelectContainer className={`${className} ${isOpen ? "open" : ""}`} isOpen={isOpen}>
      <SelectBtn aria-label="Veldu möguleika" type="button" {...getToggleButtonProps()}>
        {isOpen && !selectedItem && !hideDefaultPlaceholder && "Veldu möguleika"}

        <PlaceholderText textColor={textColor}>{placeholder}</PlaceholderText>

        <IconWrapper>{isOpen ? <ArrowUpIcons size={24} /> : <ArrowDownIcons size={24} />}</IconWrapper>
        <FocusLine isOpen={isOpen} />
      </SelectBtn>

      <MenuWrapper
        align={align}
        aria-label="veldu möguleika"
        className={`menu-wrapper ${isOpen ? "open" : ""}`}
        fit={!!fit}
        isOpen={isOpen}
        role="listbox"
      >
        <Menu {...getMenuProps()} isOpen={isOpen} role="group">
          {items.map((item, index) => {
            const highlighted = highlightedIndex === index;
            const selected = selectedItem === item;
            const props = getItemProps({ item, index });
            const key = index;

            return (
              <ListItem key={key} role="button" {...props} highlighted={highlighted} selected={selected}>
                {itemToString(item)}
                {withIcon ? (
                  <IconWrapper>
                    <LongArrowSideIcons size={24} />
                  </IconWrapper>
                ) : (
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  <IconWrapper>{hideValue ? "" : (item as any).value}</IconWrapper>
                )}
              </ListItem>
            );
          })}
        </Menu>
      </MenuWrapper>
    </SelectContainer>
  );
}
