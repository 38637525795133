/* eslint-disable max-len */
import * as React from "react";
import { BykoReactIconComponentType } from "@byko/lib-core";

export const AppidIllustration: BykoReactIconComponentType = ({ size = 200 }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 662 611" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M126.69 510.42C149.64 492.87 136.16 403.96 86.57 386.11C63.23 417.42 78.79 503.66 126.69 510.42Z"
        fill="#008B66"
      />
      <path d="M92.846 397.917L90.7085 398.615L126.91 509.556L129.048 508.858L92.846 397.917Z" fill="#87C9B7" />
      <path
        d="M121.59 511.58C107.74 511.58 96.0201 507.23 86.7001 498.58C65.4201 478.89 64.1601 444.13 64.1201 442.66V441.41L65.3701 441.5C65.8801 441.5 78.1201 442.5 91.8001 451.5C104.4 459.77 120.56 476.65 126 510.15L126.2 511.39L124.94 511.45C123.79 511.56 122.68 511.58 121.59 511.58ZM66.4301 443.93C66.8401 450.54 69.6501 479.77 88.2301 496.93C97.5401 505.54 109.42 509.7 123.53 509.27C120.43 491.4 112.19 467.67 90.6301 453.47C83.3288 448.615 75.0806 445.363 66.4301 443.93Z"
        fill="#87C9B7"
      />
      <path
        d="M419.71 249.21C409.71 246.82 396.09 253.97 393.92 255.49C391.75 257.01 393.92 260.04 396.92 260.49C393.01 263.96 391.92 268.73 393.01 271.33C394.1 273.93 399.31 273.93 402.34 272.63C402.34 272.63 403.86 280.87 400.39 285.42C396.92 289.97 384.57 292.13 384.57 292.13C384.57 292.13 389.57 301.67 405.16 301.67C420.75 301.67 424.23 291.67 424.23 291.67L409.49 272.6L419.71 249.21Z"
        fill="#A37160"
      />
      <path
        d="M456.56 371.87C456.56 371.87 454.95 390.68 441.43 397.63C434.98 386.97 436.61 383.63 436.61 383.63C441.201 379.622 444.474 374.321 446 368.42C447.28 363.57 456.56 371.87 456.56 371.87Z"
        fill="#A37160"
      />
      <path
        d="M462.05 498.68L433.1 509.68L434.77 513.68C436.16 517.04 434.77 522.33 432.2 524.78L431.79 525.17C429.977 526.862 427.776 528.081 425.38 528.72C417.81 530.83 417.75 535.34 417.75 535.34H435.23L470.36 510.23L462.05 498.68Z"
        fill="#003C71"
      />
      <path
        d="M309.21 535.42C309.21 535.42 315.75 528.59 328.39 524.86C341.03 521.13 366.39 521.13 366.39 521.13V535.42H309.21Z"
        fill="#003C71"
      />
      <path
        d="M393.96 190.74C422.198 190.74 445.09 167.848 445.09 139.61C445.09 111.372 422.198 88.48 393.96 88.48C365.722 88.48 342.83 111.372 342.83 139.61C342.83 167.848 365.722 190.74 393.96 190.74Z"
        fill="#408DBC"
      />
      <path
        d="M187 279.1C182.101 278.375 177.322 276.996 172.79 275C179.24 271 182.91 266.09 181.2 257.39C178.52 243.74 197.91 254.39 197.91 254.39C197.91 254.39 195.42 270.13 207.91 276.8L208.27 276.99C201.503 279.61 194.15 280.339 187 279.1Z"
        fill="#FDC498"
      />
      <path
        d="M202.71 253.06C202.71 253.06 202.18 267.67 190.05 267.43C176.77 267.18 176.68 252.84 176.68 252.84L202.71 253.06Z"
        fill="#003C71"
      />
      <path
        d="M201.92 243.63C201.92 243.63 208.01 242.63 208.21 246.97C208.33 249.64 204.46 251.25 202.16 250.51C199.86 249.77 201.92 243.63 201.92 243.63Z"
        fill="#FDC498"
      />
      <path
        d="M176.9 243.12C176.9 243.12 172.19 241.4 171.38 245C170.79 247.6 174.17 250 176.57 249.71C178.97 249.42 176.9 243.12 176.9 243.12Z"
        fill="#FDC498"
      />
      <path
        d="M204.64 238.26C204.64 238.26 206.51 262.61 191.02 263.6C175.53 264.59 174.48 244.19 174.61 238.73L204.64 238.26Z"
        fill="#FDC498"
      />
      <path
        d="M183.91 246.43C184.335 246.43 184.68 246.085 184.68 245.66C184.68 245.235 184.335 244.89 183.91 244.89C183.485 244.89 183.14 245.235 183.14 245.66C183.14 246.085 183.485 246.43 183.91 246.43Z"
        fill="#003C71"
      />
      <path
        d="M194.91 246.99C195.335 246.99 195.68 246.645 195.68 246.22C195.68 245.795 195.335 245.45 194.91 245.45C194.485 245.45 194.14 245.795 194.14 246.22C194.14 246.645 194.485 246.99 194.91 246.99Z"
        fill="#003C71"
      />
      <path
        d="M190 244.42C188.15 244.14 188.31 246.85 187.46 248.52C186.61 250.19 185.31 249.95 184.77 250.98C184.23 252.01 185.53 252.98 186.35 253.11C187.17 253.24 187.9 254.77 190.08 253.62C192.08 252.56 193.08 253.01 193.3 251.79C193.69 249.73 191.3 250.21 191.17 247.89C191 246.17 191.14 244.61 190 244.42Z"
        fill="#FFEEE0"
      />
      <path
        d="M190 223.35C185.84 223.353 181.85 225.007 178.908 227.949C175.967 230.89 174.313 234.88 174.31 239.04H205.69C205.69 234.879 204.037 230.888 201.095 227.946C198.152 225.003 194.161 223.35 190 223.35Z"
        fill="#FFD400"
      />
      <path d="M207.13 238.54H173.08V241.35H207.13V238.54Z" fill="#FFE770" />
      <path
        d="M173.08 241.34C173.08 241.34 178.17 243.53 190.08 243.53C201.99 243.53 207.17 241.34 207.17 241.34H173.08Z"
        fill="#FF7D20"
      />
      <path d="M194.14 221.16H186.02V231.86H194.14V221.16Z" fill="#FFD400" />
      <path d="M182 224.72L186 231.02V223.02L182 224.72Z" fill="#FF7D20" />
      <path d="M198.15 224.72L194.15 231.02V223.02L198.15 224.72Z" fill="#FF7D20" />
      <path
        d="M149.55 311.42C149.55 311.42 152.01 278.49 172.75 275.02C193.49 271.55 208.21 277.02 208.21 277.02C208.21 277.02 226 283.61 226.81 311.42H149.55Z"
        fill="#003C71"
      />
      <path d="M174.8 273.84H168.07V354.95H174.8V273.84Z" fill="#FFD400" />
      <path d="M211.66 273.84H204.93V354.95H211.66V273.84Z" fill="#FFD400" />
      <path d="M207.13 286.34H171.31V354.52H207.13V286.34Z" fill="#FFD400" />
      <path
        d="M190 260.52C189.258 260.525 188.518 260.431 187.8 260.24L188.41 258.07C188.59 258.12 192.83 259.23 195.18 255.51L197.09 256.71C196.338 257.904 195.29 258.882 194.047 259.55C192.805 260.218 191.41 260.552 190 260.52Z"
        fill="#003C71"
      />
      <path
        d="M197.42 390.9V493.24H226.81C226.81 442.15 231.81 368.55 221.71 354.52C221.71 354.52 183.1 353.95 168.07 354.95C168.07 354.95 139.95 444.87 163.87 493.24H184.87C170.09 448.24 197.42 390.9 197.42 390.9Z"
        fill="#FFD400"
      />
      <path
        d="M347.3 155.57H228.76C219.36 155.57 211.74 163.19 211.74 172.59V411.54C211.74 420.94 219.36 428.56 228.76 428.56H347.3C356.7 428.56 364.32 420.94 364.32 411.54V172.59C364.32 163.19 356.7 155.57 347.3 155.57Z"
        fill="#82B5D3"
      />
      <path
        d="M338.48 164.48H237.58C228.815 164.48 221.71 171.585 221.71 180.35V403.15C221.71 411.915 228.815 419.02 237.58 419.02H338.48C347.245 419.02 354.35 411.915 354.35 403.15V180.35C354.35 171.585 347.245 164.48 338.48 164.48Z"
        fill="white"
      />
      <path
        d="M233.71 164.48H342.35C345.533 164.48 348.585 165.744 350.835 167.995C353.086 170.245 354.35 173.297 354.35 176.48V207.48H221.71V176.48C221.71 173.297 222.974 170.245 225.225 167.995C227.475 165.744 230.528 164.48 233.71 164.48Z"
        fill="#408DBC"
      />
      <path
        d="M249 161.92H327.4V164.76C327.4 167.314 326.385 169.764 324.579 171.57C322.773 173.376 320.324 174.39 317.77 174.39H258.63C256.076 174.39 253.627 173.376 251.821 171.57C250.015 169.764 249 167.314 249 164.76V161.92Z"
        fill="#82B5D3"
      />
      <path d="M316.65 163.74H275.8V165.99H316.65V163.74Z" fill="white" />
      <path
        d="M262.88 169.78C261.925 169.78 260.991 169.497 260.197 168.966C259.402 168.435 258.783 167.681 258.418 166.798C258.052 165.916 257.956 164.945 258.143 164.008C258.329 163.071 258.789 162.21 259.465 161.535C260.14 160.859 261.001 160.399 261.938 160.213C262.875 160.027 263.846 160.122 264.728 160.488C265.611 160.853 266.365 161.472 266.896 162.267C267.427 163.061 267.71 163.995 267.71 164.95C267.71 166.231 267.201 167.46 266.295 168.365C265.39 169.271 264.161 169.78 262.88 169.78ZM262.88 162.37C262.367 162.368 261.865 162.519 261.437 162.802C261.01 163.086 260.676 163.491 260.479 163.964C260.282 164.438 260.23 164.96 260.329 165.463C260.429 165.966 260.676 166.429 261.039 166.792C261.401 167.154 261.864 167.401 262.367 167.501C262.871 167.6 263.392 167.548 263.866 167.351C264.339 167.154 264.744 166.82 265.028 166.393C265.312 165.965 265.462 165.463 265.46 164.95C265.444 164.275 265.166 163.633 264.684 163.161C264.202 162.688 263.555 162.423 262.88 162.42V162.37Z"
        fill="white"
      />
      <path d="M341.77 181.96H322.61V184.21H341.77V181.96Z" fill="#003C71" />
      <path d="M341.77 188.82H322.61V191.07H341.77V188.82Z" fill="#003C71" />
      <path d="M341.77 195.67H322.61V197.92H341.77V195.67Z" fill="#003C71" />
      <path
        d="M365.44 328.13H363.19V172.59C363.185 168.377 361.509 164.339 358.53 161.36C355.551 158.381 351.513 156.705 347.3 156.7H300.69V154.42H347.3C352.11 154.425 356.72 156.338 360.121 159.739C363.522 163.14 365.435 167.751 365.44 172.56V328.13Z"
        fill="#003C71"
      />
      <path d="M212.86 302.35H210.61V374.79H212.86V302.35Z" fill="#003C71" />
      <path
        d="M236.48 184.42H239.84C240.74 184.42 240.94 184.72 240.94 185.13C240.94 185.54 240.74 185.87 239.84 185.87H236.48V184.42ZM236.48 188.42H240.28C241.16 188.42 241.43 188.81 241.43 189.3C241.43 189.79 241.16 190.18 240.28 190.18H236.48V188.42ZM232.14 192.92H241.76C245.05 192.92 246.15 191.47 246.15 189.27C246.158 188.69 245.975 188.123 245.627 187.659C245.28 187.194 244.789 186.857 244.23 186.7C244.65 186.515 245.004 186.207 245.243 185.815C245.483 185.424 245.597 184.968 245.57 184.51C245.57 182.71 244.57 181.7 242.25 181.7H232.14V192.92Z"
        fill="white"
      />
      <path d="M245.62 195.67H232.14V197.92H245.62V195.67Z" fill="#FFD400" />
      <path
        d="M421.9 251.69C421.9 251.69 425.19 241.42 433 240.42C440.81 239.42 443.07 254.11 458.47 245.14C473.87 236.17 489.78 250.55 489.78 250.55L462.18 278.42C462.18 278.42 461.71 268.26 450.27 271.65C438.83 275.04 431.54 265.56 431.21 260.65C430.88 255.74 425.21 255.57 425.21 255.57L421.9 251.69Z"
        fill="#003C71"
      />
      <path
        d="M154.49 291.75C147.62 307.22 148.49 327.96 152.59 338.58C165.67 348.29 190.48 350.02 226.81 341.52V328.13C226.81 328.13 198.44 313.51 168.07 324.36V310.99L154.49 291.75Z"
        fill="#003C71"
      />
      <path
        d="M236.87 320.42C236.87 320.42 226.81 322.97 226.81 328.12C226.81 333.27 235.51 332.59 235.51 332.59L236.87 320.42Z"
        fill="#FDC498"
      />
      <path
        d="M339.83 298.7H236.18C234.131 298.7 232.47 300.361 232.47 302.41V336.33C232.47 338.379 234.131 340.04 236.18 340.04H339.83C341.879 340.04 343.54 338.379 343.54 336.33V302.41C343.54 300.361 341.879 298.7 339.83 298.7Z"
        fill="#0067A5"
      />
      <path
        d="M250.89 314.27H246.82L245.82 311.54C245.705 311.25 245.505 311 245.246 310.825C244.988 310.649 244.683 310.555 244.37 310.555C244.058 310.555 243.752 310.649 243.494 310.825C243.235 311 243.035 311.25 242.92 311.54L241.92 314.27H238.11C237.858 314.274 237.614 314.356 237.41 314.503C237.206 314.651 237.053 314.857 236.97 315.095C236.887 315.333 236.879 315.59 236.947 315.832C237.015 316.075 237.156 316.29 237.35 316.45L240.26 318.77L238.96 322.27C238.843 322.568 238.821 322.894 238.896 323.205C238.972 323.516 239.142 323.795 239.383 324.006C239.624 324.216 239.924 324.347 240.242 324.38C240.56 324.413 240.881 324.346 241.16 324.19L244.62 322.28L247.49 323.98C247.768 324.147 248.091 324.223 248.415 324.197C248.738 324.171 249.045 324.044 249.292 323.834C249.539 323.624 249.715 323.342 249.793 323.028C249.872 322.713 249.85 322.381 249.73 322.08L248.59 319.01L251.67 316.45C251.872 316.292 252.019 316.074 252.09 315.828C252.161 315.582 252.153 315.32 252.066 315.079C251.98 314.837 251.82 314.63 251.609 314.484C251.398 314.339 251.146 314.264 250.89 314.27Z"
        fill="#FFD400"
      />
      <path
        d="M272.28 314.27H268.21L267.21 311.54C267.095 311.25 266.895 311 266.637 310.825C266.378 310.649 266.073 310.555 265.76 310.555C265.448 310.555 265.142 310.649 264.884 310.825C264.625 311 264.425 311.25 264.31 311.54L263.31 314.27H259.5C259.249 314.274 259.004 314.356 258.8 314.503C258.596 314.651 258.443 314.857 258.36 315.095C258.277 315.333 258.269 315.59 258.337 315.832C258.405 316.075 258.546 316.29 258.74 316.45L261.65 318.77L260.35 322.27C260.233 322.568 260.211 322.894 260.287 323.205C260.362 323.516 260.532 323.795 260.773 324.006C261.014 324.216 261.314 324.347 261.632 324.38C261.951 324.413 262.271 324.346 262.55 324.19L266.01 322.28L268.88 323.98C269.158 324.147 269.481 324.223 269.805 324.197C270.128 324.171 270.435 324.044 270.682 323.834C270.93 323.624 271.105 323.342 271.183 323.028C271.262 322.713 271.24 322.381 271.12 322.08L270 319.01L273 316.42C273.187 316.262 273.322 316.052 273.388 315.817C273.453 315.582 273.447 315.332 273.37 315.101C273.292 314.869 273.147 314.666 272.953 314.518C272.758 314.37 272.524 314.284 272.28 314.27Z"
        fill="#FFD400"
      />
      <path
        d="M293.67 314.27H289.6L288.6 311.54C288.485 311.25 288.285 311 288.026 310.825C287.768 310.649 287.463 310.555 287.15 310.555C286.838 310.555 286.532 310.649 286.274 310.825C286.015 311 285.815 311.25 285.7 311.54L284.7 314.27H280.88C280.628 314.274 280.384 314.356 280.18 314.503C279.976 314.651 279.823 314.857 279.74 315.095C279.657 315.333 279.649 315.59 279.717 315.832C279.785 316.075 279.926 316.29 280.12 316.45L283.03 318.77L281.73 322.27C281.613 322.568 281.591 322.894 281.666 323.205C281.742 323.516 281.912 323.795 282.153 324.006C282.394 324.216 282.694 324.347 283.012 324.38C283.331 324.413 283.651 324.346 283.93 324.19L287.39 322.28L290.26 323.98C290.538 324.147 290.861 324.223 291.185 324.197C291.508 324.171 291.815 324.044 292.062 323.834C292.309 323.624 292.485 323.342 292.563 323.028C292.642 322.713 292.62 322.381 292.5 322.08L291.36 319.01L294.44 316.45C294.641 316.292 294.787 316.076 294.859 315.831C294.93 315.586 294.923 315.324 294.838 315.084C294.753 314.843 294.594 314.635 294.385 314.489C294.175 314.343 293.925 314.266 293.67 314.27Z"
        fill="#FFD400"
      />
      <path
        d="M315.06 314.27H311L310 311.54C309.887 311.249 309.688 310.998 309.431 310.822C309.173 310.645 308.867 310.551 308.555 310.551C308.242 310.551 307.937 310.645 307.679 310.822C307.422 310.998 307.223 311.249 307.11 311.54L306.11 314.27H302.29C302.038 314.274 301.794 314.356 301.59 314.503C301.386 314.651 301.232 314.857 301.15 315.095C301.067 315.333 301.059 315.59 301.127 315.832C301.195 316.075 301.336 316.29 301.53 316.45L304.44 318.77L303.14 322.27C303.023 322.568 303.001 322.894 303.076 323.205C303.152 323.516 303.322 323.795 303.563 324.006C303.804 324.216 304.104 324.347 304.422 324.38C304.74 324.413 305.061 324.346 305.34 324.19L308.8 322.28L311.67 323.98C311.948 324.147 312.271 324.223 312.595 324.197C312.918 324.171 313.225 324.044 313.472 323.834C313.719 323.624 313.895 323.342 313.973 323.028C314.052 322.713 314.03 322.381 313.91 322.08L312.78 319.01L315.85 316.45C316.052 316.291 316.2 316.073 316.271 315.826C316.341 315.579 316.332 315.316 316.245 315.074C316.157 314.832 315.996 314.624 315.783 314.48C315.57 314.335 315.317 314.262 315.06 314.27Z"
        fill="#FFD400"
      />
      <path
        d="M336.45 314.27H332.38L331.38 311.54C331.267 311.249 331.069 310.998 330.811 310.822C330.553 310.645 330.248 310.551 329.935 310.551C329.623 310.551 329.317 310.645 329.06 310.822C328.802 310.998 328.603 311.249 328.49 311.54L327.49 314.27H323.67C323.418 314.274 323.174 314.356 322.97 314.503C322.766 314.651 322.613 314.857 322.53 315.095C322.447 315.333 322.439 315.59 322.507 315.832C322.575 316.075 322.716 316.29 322.91 316.45L325.82 318.77L324.52 322.27C324.403 322.568 324.381 322.894 324.456 323.205C324.532 323.516 324.702 323.795 324.943 324.006C325.184 324.216 325.484 324.347 325.802 324.38C326.121 324.413 326.441 324.346 326.72 324.19L330.18 322.28L333.05 323.98C333.328 324.147 333.651 324.223 333.975 324.197C334.298 324.171 334.605 324.044 334.852 323.834C335.1 323.624 335.275 323.342 335.353 323.028C335.432 322.713 335.41 322.381 335.29 322.08L334.16 319.01L337.23 316.45C337.432 316.292 337.579 316.074 337.65 315.828C337.721 315.582 337.713 315.32 337.626 315.079C337.54 314.837 337.38 314.63 337.169 314.484C336.958 314.339 336.706 314.264 336.45 314.27Z"
        fill="#FFD400"
      />
      <path
        d="M336.45 314.27H332.38L331.38 311.54C331.279 311.264 331.101 311.023 330.867 310.846C330.633 310.669 330.353 310.562 330.06 310.54V322.31L330.17 322.25L333.04 323.95C333.318 324.117 333.641 324.193 333.965 324.167C334.288 324.141 334.595 324.014 334.842 323.804C335.089 323.594 335.265 323.312 335.343 322.998C335.422 322.683 335.4 322.351 335.28 322.05L334.15 318.98L337.22 316.42C337.409 316.259 337.545 316.045 337.609 315.805C337.673 315.566 337.663 315.312 337.579 315.079C337.496 314.845 337.343 314.642 337.141 314.498C336.939 314.354 336.698 314.274 336.45 314.27Z"
        fill="white"
      />
      <path
        d="M226.81 341.52C226.81 341.52 239.47 343.52 249.25 341.32C250.86 340.87 251.46 334.81 251.25 331.08C251.206 330.474 250.928 329.908 250.475 329.503C250.021 329.098 249.428 328.885 248.82 328.91C241.28 329.27 229.09 329.17 226.82 328.13L226.81 341.52Z"
        fill="#FDC498"
      />
      <path d="M251.448 331.299L239.394 332.089L239.541 334.335L251.595 333.544L251.448 331.299Z" fill="#003C71" />
      <path d="M251.089 336.113L240.781 336.788L240.928 339.034L251.236 338.358L251.089 336.113Z" fill="#003C71" />
      <path
        d="M192.13 299.83H182.93C180.476 299.825 178.124 298.848 176.388 297.112C174.653 295.377 173.675 293.024 173.67 290.57V273.84H175.92V290.57C175.92 292.427 176.658 294.207 177.97 295.52C179.283 296.833 181.064 297.57 182.92 297.57H192.12L192.13 299.83Z"
        fill="#003C71"
      />
      <path
        d="M236.13 393.54C234.861 393.54 233.645 393.037 232.747 392.141C231.849 391.245 231.343 390.029 231.34 388.76V358.61C231.343 357.343 231.847 356.129 232.743 355.233C233.639 354.337 234.853 353.833 236.12 353.83H339.88C341.148 353.83 342.364 354.334 343.26 355.23C344.156 356.127 344.66 357.342 344.66 358.61V382.42C344.657 383.687 344.153 384.901 343.257 385.797C342.361 386.693 341.147 387.197 339.88 387.2H246.75C246.189 387.201 245.644 387.388 245.2 387.73L239.06 392.52C238.225 393.178 237.193 393.537 236.13 393.54ZM236.13 356.08C235.459 356.08 234.816 356.347 234.341 356.821C233.867 357.296 233.6 357.939 233.6 358.61V388.76C233.599 389.235 233.731 389.701 233.982 390.104C234.233 390.508 234.592 390.832 235.019 391.041C235.445 391.25 235.922 391.334 236.395 391.284C236.867 391.235 237.316 391.053 237.69 390.76L243.83 385.98C244.669 385.332 245.7 384.98 246.76 384.98H339.89C340.562 384.98 341.207 384.714 341.683 384.24C342.159 383.765 342.427 383.122 342.43 382.45V358.61C342.427 357.938 342.159 357.295 341.683 356.82C341.207 356.346 340.562 356.08 339.89 356.08H236.13Z"
        fill="#D9E8F2"
      />
      <path
        d="M261.87 419.02H259.62V401.97C259.623 400.703 260.127 399.489 261.023 398.593C261.919 397.697 263.133 397.193 264.4 397.19H354.32V399.42H264.4C263.729 399.42 263.086 399.687 262.611 400.161C262.137 400.636 261.87 401.279 261.87 401.95V419.02Z"
        fill="#D9E8F2"
      />
      <path d="M335.48 362.63H288.03V364.88H335.48V362.63Z" fill="#D9E8F2" />
      <path d="M335.48 370.74H288.03V372.99H335.48V370.74Z" fill="#D9E8F2" />
      <path
        d="M466.78 191.75C466.052 191.745 465.335 191.578 464.68 191.26C463.873 190.869 463.193 190.258 462.719 189.497C462.245 188.736 461.995 187.857 462 186.96V156.81C462.003 155.543 462.507 154.329 463.403 153.433C464.299 152.537 465.513 152.033 466.78 152.03H557.32C558.586 152.033 559.799 152.537 560.694 153.434C561.588 154.33 562.09 155.544 562.09 156.81V180.63C562.09 181.896 561.588 183.11 560.694 184.006C559.799 184.903 558.586 185.407 557.32 185.41H477.41C476.844 185.41 476.295 185.6 475.85 185.95L469.71 190.73C468.874 191.387 467.843 191.746 466.78 191.75ZM466.78 154.28C466.108 154.28 465.464 154.546 464.988 155.02C464.511 155.495 464.243 156.138 464.24 156.81V186.96C464.239 187.435 464.371 187.901 464.622 188.304C464.873 188.708 465.232 189.032 465.659 189.241C466.085 189.45 466.562 189.534 467.035 189.484C467.507 189.435 467.956 189.253 468.33 188.96L474.47 184.17C475.313 183.521 476.347 183.17 477.41 183.17H557.32C557.991 183.17 558.635 182.903 559.109 182.429C559.584 181.955 559.85 181.311 559.85 180.64V156.81C559.847 156.14 559.58 155.498 559.106 155.024C558.632 154.55 557.99 154.283 557.32 154.28H466.78Z"
        fill="#D9E8F2"
      />
      <path d="M552.91 160.84H518.68V163.09H552.91V160.84Z" fill="#D9E8F2" />
      <path d="M552.9 168.95H496.9V171.2H552.9V168.95Z" fill="#D9E8F2" />
      <path
        d="M570.47 232.9C569.409 232.903 568.378 232.551 567.54 231.9L561.4 227.12C560.956 226.77 560.406 226.58 559.84 226.58H490.76C489.492 226.58 488.277 226.077 487.38 225.18C486.484 224.284 485.98 223.068 485.98 221.8V197.97C485.983 196.703 486.487 195.489 487.383 194.593C488.279 193.697 489.493 193.193 490.76 193.19H570.48C571.747 193.193 572.961 193.697 573.857 194.593C574.753 195.489 575.258 196.703 575.26 197.97V228.12C575.264 229.015 575.015 229.893 574.54 230.653C574.066 231.412 573.386 232.021 572.58 232.41C571.923 232.731 571.202 232.899 570.47 232.9ZM490.76 195.42C490.089 195.42 489.446 195.687 488.971 196.161C488.497 196.636 488.23 197.279 488.23 197.95V221.77C488.23 222.441 488.497 223.085 488.971 223.559C489.446 224.034 490.089 224.3 490.76 224.3H559.84C560.904 224.3 561.937 224.652 562.78 225.3L568.92 230.09C569.295 230.379 569.744 230.557 570.215 230.605C570.686 230.653 571.161 230.568 571.586 230.36C572.011 230.152 572.37 229.829 572.622 229.428C572.873 229.027 573.008 228.564 573.01 228.09V197.97C573.008 197.3 572.74 196.658 572.266 196.184C571.792 195.71 571.15 195.443 570.48 195.44L490.76 195.42Z"
        fill="#D9E8F2"
      />
      <path d="M518.58 201.99H495.17V204.24H518.58V201.99Z" fill="#D9E8F2" />
      <path d="M540.35 210.11H495.17V212.36H540.35V210.11Z" fill="#D9E8F2" />
      <path d="M335.48 407.54H288.03V409.79H335.48V407.54Z" fill="#D9E8F2" />
      <path
        d="M275.68 406.77C275.68 406.77 276.39 403.26 279.82 404.03C283.25 404.8 281.67 410.81 275.68 413.42C269.68 410.81 267.74 404.84 271.54 404.03C275 403.29 275.68 406.77 275.68 406.77Z"
        fill="#D9E8F2"
      />
      <path
        d="M396 330.55L328.39 524.84H373C373 524.84 368.26 503.18 380.44 491.67C392.62 480.16 398 467.3 396 453.08C394 438.86 403.45 427.7 403.45 427.7C403.45 427.7 410.22 486.25 429.17 516.04L469.11 501.82C469.11 501.82 474.01 486.26 452.19 472.06C431.34 458.5 434.01 439.29 436.62 427.7C448.54 374.8 437.97 355.6 427.14 330.55H396Z"
        fill="#008B66"
      />
      <path
        d="M313.25 241.85C313.25 241.85 317.09 250.47 314.83 257.75C312.57 265.03 307.83 257.75 307.83 257.75L313.25 241.85Z"
        fill="#A37160"
      />
      <path
        d="M288.37 262.72C305.878 262.72 320.07 248.528 320.07 231.02C320.07 213.513 305.878 199.32 288.37 199.32C270.863 199.32 256.67 213.513 256.67 231.02C256.67 248.528 270.863 262.72 288.37 262.72Z"
        fill="#003C71"
      />
      <path
        d="M288.56 227.28C288.56 227.28 290.29 218.69 298.68 220.56C307.07 222.43 303.23 239.87 288.56 246.26C273.89 239.87 269.13 222.55 278.43 220.56C286.89 218.75 288.56 227.28 288.56 227.28Z"
        fill="#FF7D20"
      />
      <path
        d="M246.01 282.23C254.184 282.23 260.81 275.604 260.81 267.43C260.81 259.256 254.184 252.63 246.01 252.63C237.836 252.63 231.21 259.256 231.21 267.43C231.21 275.604 237.836 282.23 246.01 282.23Z"
        fill="#003C71"
      />
      <path
        d="M246.1 265.69C246.1 265.69 246.91 261.69 250.83 262.55C254.75 263.41 252.95 271.55 246.1 274.55C239.25 271.55 237.03 263.49 241.38 262.55C245.32 261.71 246.1 265.69 246.1 265.69Z"
        fill="#40A88D"
      />
      <path
        d="M427.14 330.55L420.41 287.9C420.022 285.45 418.773 283.218 416.886 281.607C415 279.996 412.601 279.11 410.12 279.11H351.66L314.83 257.75L304.67 243.53C304.67 243.53 296.54 249.09 300.67 256.11C307.36 267.43 329.67 291.97 352.32 296.37C374.97 300.77 392.26 311.26 395.98 330.55H427.14Z"
        fill="#A37160"
      />
      <path
        d="M378.21 132.07C378.21 132.07 395.3 129.78 395.84 116.46V109.33C395.84 109.33 405.2 106.39 402.2 127.04C402.2 127.04 416.29 126.96 419.39 128.57C420.044 128.91 420.579 129.44 420.923 130.092C421.267 130.743 421.405 131.484 421.317 132.216C421.229 132.947 420.92 133.635 420.431 134.186C419.942 134.737 419.296 135.126 418.58 135.3C421.34 137.72 420.19 142.48 417.37 143.3C417.97 143.827 418.41 144.511 418.641 145.275C418.872 146.039 418.884 146.853 418.675 147.623C418.467 148.394 418.047 149.09 417.463 149.634C416.879 150.178 416.154 150.547 415.37 150.7C417.52 152.22 413.37 157.7 403.66 157.47C393.95 157.24 378.23 153.3 378.23 153.3L378.21 132.07Z"
        fill="white"
      />
      <path
        d="M375.34 127.29H369.42C367.835 127.29 366.55 128.575 366.55 130.16V154.64C366.55 156.225 367.835 157.51 369.42 157.51H375.34C376.925 157.51 378.21 156.225 378.21 154.64V130.16C378.21 128.575 376.925 127.29 375.34 127.29Z"
        fill="#82B5D3"
      />
      <path
        d="M418.58 136.42H404.75V134.17H418.58C418.778 134.186 418.976 134.157 419.161 134.086C419.347 134.015 419.513 133.904 419.65 133.76C420.132 132.988 420.337 132.074 420.23 131.17L422.46 130.93C422.644 132.466 422.236 134.014 421.32 135.26C420.975 135.641 420.551 135.943 420.078 136.143C419.605 136.343 419.094 136.438 418.58 136.42Z"
        fill="#003C71"
      />
      <path
        d="M417.37 144.26H404.75V142.01H417.37C417.73 142.01 418.96 141.8 418.96 139.77H421.21C421.21 143.42 418.44 144.26 417.37 144.26Z"
        fill="#003C71"
      />
      <path
        d="M415.35 151.42H404.75V149.12H415.35C417.65 149.12 418.18 147.71 418.23 147.55L420.38 148.18C420.35 148.31 419.43 151.42 415.35 151.42Z"
        fill="#003C71"
      />
      <path
        d="M403.31 127.25L401.1 126.82C402.91 117.33 401.32 113.34 399.67 111.67C399.168 111.162 398.557 110.774 397.883 110.535C397.21 110.296 396.491 110.212 395.78 110.29L395.38 108.08C396.45 107.937 397.538 108.043 398.559 108.39C399.581 108.737 400.509 109.315 401.27 110.08C404.23 113.08 404.91 118.85 403.31 127.25Z"
        fill="#003C71"
      />
      <path d="M379.34 132.07H377.09V157.51H379.34V132.07Z" fill="#003C71" />
      <path d="M211.66 353.83H174.8V356.08H211.66V353.83Z" fill="#003C71" />
      <path
        d="M163.87 493.24L146.37 500.63C144.333 501.49 142.594 502.931 141.372 504.774C140.15 506.617 139.499 508.779 139.5 510.99H191.14L184.68 493.24H163.87Z"
        fill="#003C71"
      />
      <path
        d="M197.42 493.24V510.99H247.66C248.66 505.73 245.44 499.85 239.01 497.46L226.81 493.24H197.42Z"
        fill="#003C71"
      />
      <path
        d="M409.52 272.61C409.52 272.61 427.52 268.42 425.75 254.54C424.66 245.95 410.12 249.23 410.12 249.23V256.79C414.93 254.79 417.01 261.52 410.12 264.15L409.52 272.61Z"
        fill="#003C71"
      />
      <path
        d="M458.28 377.6C458.28 377.6 473.35 289.5 410.12 280.14V303.48C410.12 303.48 434.25 315.93 440.01 338C442.697 348.766 444.394 359.755 445.08 370.83L458.28 377.6Z"
        fill="#40A88D"
      />
      <path
        d="M410.12 280.14C404.53 279.14 391.21 276.83 351.66 279.14L335.66 289.83C335.66 289.83 342.1 296.36 362.47 300.03C382.84 303.7 395.86 322.33 396.03 330.58H427.17L422.83 297.21L410.12 280.14Z"
        fill="#40A88D"
      />
      <path
        d="M364.32 287.76C365.491 287.76 366.44 286.811 366.44 285.64C366.44 284.469 365.491 283.52 364.32 283.52C363.149 283.52 362.2 284.469 362.2 285.64C362.2 286.811 363.149 287.76 364.32 287.76Z"
        fill="#8CCBBA"
      />
      <path
        d="M398.15 314.81C399.321 314.81 400.27 313.861 400.27 312.69C400.27 311.519 399.321 310.57 398.15 310.57C396.979 310.57 396.03 311.519 396.03 312.69C396.03 313.861 396.979 314.81 398.15 314.81Z"
        fill="#8CCBBA"
      />
      <path
        d="M417.7 311.53C418.871 311.53 419.82 310.581 419.82 309.41C419.82 308.239 418.871 307.29 417.7 307.29C416.529 307.29 415.58 308.239 415.58 309.41C415.58 310.581 416.529 311.53 417.7 311.53Z"
        fill="#8CCBBA"
      />
      <path
        d="M369.06 296.3C370.231 296.3 371.18 295.351 371.18 294.18C371.18 293.009 370.231 292.06 369.06 292.06C367.889 292.06 366.94 293.009 366.94 294.18C366.94 295.351 367.889 296.3 369.06 296.3Z"
        fill="#8CCBBA"
      />
      <path
        d="M405.74 288.7C406.911 288.7 407.86 287.751 407.86 286.58C407.86 285.409 406.911 284.46 405.74 284.46C404.569 284.46 403.62 285.409 403.62 286.58C403.62 287.751 404.569 288.7 405.74 288.7Z"
        fill="#8CCBBA"
      />
      <path
        d="M388.01 305.88C389.181 305.88 390.13 304.931 390.13 303.76C390.13 302.589 389.181 301.64 388.01 301.64C386.839 301.64 385.89 302.589 385.89 303.76C385.89 304.931 386.839 305.88 388.01 305.88Z"
        fill="#8CCBBA"
      />
      <path
        d="M393.29 294.18C394.803 294.18 396.03 292.953 396.03 291.44C396.03 289.927 394.803 288.7 393.29 288.7C391.777 288.7 390.55 289.927 390.55 291.44C390.55 292.953 391.777 294.18 393.29 294.18Z"
        fill="#8CCBBA"
      />
      <path
        d="M422.38 295.09C424.396 295.09 426.03 293.456 426.03 291.44C426.03 289.424 424.396 287.79 422.38 287.79C420.364 287.79 418.73 289.424 418.73 291.44C418.73 293.456 420.364 295.09 422.38 295.09Z"
        fill="#8CCBBA"
      />
      <path
        d="M434.98 316.34C436.996 316.34 438.63 314.706 438.63 312.69C438.63 310.674 436.996 309.04 434.98 309.04C432.964 309.04 431.33 310.674 431.33 312.69C431.33 314.706 432.964 316.34 434.98 316.34Z"
        fill="#8CCBBA"
      />
      <path
        d="M452.18 335.19C454.196 335.19 455.83 333.556 455.83 331.54C455.83 329.524 454.196 327.89 452.18 327.89C450.164 327.89 448.53 329.524 448.53 331.54C448.53 333.556 450.164 335.19 452.18 335.19Z"
        fill="#8CCBBA"
      />
      <path
        d="M450.28 361.69C452.296 361.69 453.93 360.056 453.93 358.04C453.93 356.024 452.296 354.39 450.28 354.39C448.264 354.39 446.63 356.024 446.63 358.04C446.63 360.056 448.264 361.69 450.28 361.69Z"
        fill="#8CCBBA"
      />
      <path
        d="M402.1 327.9C403.691 327.9 404.98 326.611 404.98 325.02C404.98 323.43 403.691 322.14 402.1 322.14C400.51 322.14 399.22 323.43 399.22 325.02C399.22 326.611 400.51 327.9 402.1 327.9Z"
        fill="#8CCBBA"
      />
      <path
        d="M403 304.39C404.514 304.39 405.74 303.163 405.74 301.65C405.74 300.137 404.514 298.91 403 298.91C401.487 298.91 400.26 300.137 400.26 301.65C400.26 303.163 401.487 304.39 403 304.39Z"
        fill="#8CCBBA"
      />
      <path
        d="M356.47 290.15C357.271 290.15 357.92 289.501 357.92 288.7C357.92 287.899 357.271 287.25 356.47 287.25C355.669 287.25 355.02 287.899 355.02 288.7C355.02 289.501 355.669 290.15 356.47 290.15Z"
        fill="#8CCBBA"
      />
      <path
        d="M346.65 291.6C347.451 291.6 348.1 290.951 348.1 290.15C348.1 289.349 347.451 288.7 346.65 288.7C345.849 288.7 345.2 289.349 345.2 290.15C345.2 290.951 345.849 291.6 346.65 291.6Z"
        fill="#8CCBBA"
      />
      <path
        d="M377.87 287.09C378.671 287.09 379.32 286.441 379.32 285.64C379.32 284.839 378.671 284.19 377.87 284.19C377.069 284.19 376.42 284.839 376.42 285.64C376.42 286.441 377.069 287.09 377.87 287.09Z"
        fill="#8CCBBA"
      />
      <path
        d="M413.33 299.2C414.131 299.2 414.78 298.551 414.78 297.75C414.78 296.949 414.131 296.3 413.33 296.3C412.529 296.3 411.88 296.949 411.88 297.75C411.88 298.551 412.529 299.2 413.33 299.2Z"
        fill="#8CCBBA"
      />
      <path
        d="M423.35 305.21C424.151 305.21 424.8 304.561 424.8 303.76C424.8 302.959 424.151 302.31 423.35 302.31C422.549 302.31 421.9 302.959 421.9 303.76C421.9 304.561 422.549 305.21 423.35 305.21Z"
        fill="#8CCBBA"
      />
      <path
        d="M445.14 313.47C445.941 313.47 446.59 312.821 446.59 312.02C446.59 311.219 445.941 310.57 445.14 310.57C444.339 310.57 443.69 311.219 443.69 312.02C443.69 312.821 444.339 313.47 445.14 313.47Z"
        fill="#8CCBBA"
      />
      <path
        d="M454.98 348.59C455.781 348.59 456.43 347.941 456.43 347.14C456.43 346.339 455.781 345.69 454.98 345.69C454.179 345.69 453.53 346.339 453.53 347.14C453.53 347.941 454.179 348.59 454.98 348.59Z"
        fill="#8CCBBA"
      />
      <path
        d="M455.83 366.66C456.631 366.66 457.28 366.011 457.28 365.21C457.28 364.409 456.631 363.76 455.83 363.76C455.029 363.76 454.38 364.409 454.38 365.21C454.38 366.011 455.029 366.66 455.83 366.66Z"
        fill="#8CCBBA"
      />
      <path
        d="M435.37 302.56C436.585 302.56 437.57 301.575 437.57 300.36C437.57 299.145 436.585 298.16 435.37 298.16C434.155 298.16 433.17 299.145 433.17 300.36C433.17 301.575 434.155 302.56 435.37 302.56Z"
        fill="#8CCBBA"
      />
      <path
        d="M381.09 295.42C381.775 295.42 382.33 294.865 382.33 294.18C382.33 293.495 381.775 292.94 381.09 292.94C380.405 292.94 379.85 293.495 379.85 294.18C379.85 294.865 380.405 295.42 381.09 295.42Z"
        fill="#8CCBBA"
      />
      <path
        d="M443.79 325.25C444.729 325.25 445.49 324.489 445.49 323.55C445.49 322.611 444.729 321.85 443.79 321.85C442.851 321.85 442.09 322.611 442.09 323.55C442.09 324.489 442.851 325.25 443.79 325.25Z"
        fill="#8CCBBA"
      />
      <path
        d="M447.89 342.48C449.238 342.48 450.33 341.388 450.33 340.04C450.33 338.693 449.238 337.6 447.89 337.6C446.543 337.6 445.45 338.693 445.45 340.04C445.45 341.388 446.543 342.48 447.89 342.48Z"
        fill="#8CCBBA"
      />
      <path
        d="M414.56 322.28C415.245 322.28 415.8 321.725 415.8 321.04C415.8 320.355 415.245 319.8 414.56 319.8C413.875 319.8 413.32 320.355 413.32 321.04C413.32 321.725 413.875 322.28 414.56 322.28Z"
        fill="#8CCBBA"
      />
      <path
        d="M407.86 313.93C408.545 313.93 409.1 313.375 409.1 312.69C409.1 312.005 408.545 311.45 407.86 311.45C407.175 311.45 406.62 312.005 406.62 312.69C406.62 313.375 407.175 313.93 407.86 313.93Z"
        fill="#8CCBBA"
      />
      <path
        d="M423.4 252.97C423.4 252.85 421.27 240.86 434.71 237.52C441.45 235.85 445.48 239.21 449.03 242.17C453.35 245.78 457.09 248.89 465.3 244.96L466.3 246.96C456.78 251.51 451.92 247.45 447.62 243.87C444.17 240.99 440.91 238.27 435.28 239.67C423.94 242.49 425.56 252.12 425.64 252.53L423.4 252.97Z"
        fill="#003C71"
      />
      <path
        d="M455.92 283.26L454 282.1L455 282.68L454 282.1C454 282.1 455.69 279.18 454.48 276.47C453.48 274.15 450.63 272.47 446.11 271.6L446.56 269.39C451.85 270.46 455.21 272.54 456.56 275.58C458.21 279.42 456 283.1 455.92 283.26Z"
        fill="#003C71"
      />
      <path
        d="M424.5 256.25C426.422 256.25 427.98 254.692 427.98 252.77C427.98 250.848 426.422 249.29 424.5 249.29C422.578 249.29 421.02 250.848 421.02 252.77C421.02 254.692 422.578 256.25 424.5 256.25Z"
        fill="#FFD400"
      />
      <path
        d="M372 525.42C371.78 525.05 366.7 516.19 373 501.68C375.54 495.76 378.72 492.68 382.09 489.39C386.04 485.54 390.09 481.56 393.66 472.32C396.1 465.93 395.87 460.4 395.66 454.53C395.32 446.29 394.96 437.76 402.59 427.06C428.06 391.71 425.01 368.19 424.97 367.96L427.2 367.63C427.34 368.63 430.48 392.2 404.42 428.37C397.24 438.44 397.57 446.21 397.91 454.44C398.15 460.27 398.4 466.3 395.79 473.12C392.07 482.82 387.62 487.17 383.69 491C380.49 494.12 377.46 497.06 375.09 502.57C369.32 515.96 373.97 524.2 374.02 524.28L372 525.42Z"
        fill="#003C71"
      />
      <path
        d="M575.44 248.17C579.063 248.17 582 245.233 582 241.61C582 237.987 579.063 235.05 575.44 235.05C571.817 235.05 568.88 237.987 568.88 241.61C568.88 245.233 571.817 248.17 575.44 248.17Z"
        fill="#408DBC"
      />
      <path
        d="M459 206.69C462.623 206.69 465.56 203.753 465.56 200.13C465.56 196.507 462.623 193.57 459 193.57C455.377 193.57 452.44 196.507 452.44 200.13C452.44 203.753 455.377 206.69 459 206.69Z"
        fill="#FF7D20"
      />
      <path
        d="M462.27 113.02C458.099 113.018 454.023 111.78 450.556 109.461C447.089 107.142 444.387 103.848 442.793 99.994C441.198 96.1401 440.782 91.8999 441.597 87.8095C442.412 83.7191 444.421 79.9622 447.371 77.0137C450.321 74.0652 454.079 72.0576 458.169 71.2446C462.26 70.4317 466.5 70.8499 470.353 72.4464C474.206 74.043 477.5 76.7461 479.817 80.2142C482.134 83.6822 483.37 87.7593 483.37 91.9301C483.365 97.5236 481.14 102.886 477.184 106.841C473.228 110.795 467.864 113.017 462.27 113.02ZM462.27 73.0801C458.542 73.0801 454.898 74.1856 451.798 76.2569C448.698 78.3282 446.282 81.2721 444.855 84.7165C443.428 88.1609 443.055 91.951 443.782 95.6076C444.51 99.2641 446.305 102.623 448.941 105.259C451.577 107.895 454.936 109.691 458.593 110.418C462.249 111.145 466.039 110.772 469.484 109.345C472.928 107.919 475.872 105.502 477.943 102.403C480.015 99.3027 481.12 95.6583 481.12 91.9301C481.115 86.9324 479.127 82.1409 475.593 78.607C472.059 75.0731 467.268 73.0854 462.27 73.0801Z"
        fill="#D9E8F2"
      />
      <path
        d="M462.57 99.4501L449.82 87.6601L451.35 86.0101L462.06 95.9201L476.68 71.3801L478.61 72.5301L462.57 99.4501Z"
        fill="#4AAD93"
      />
      <path d="M511.67 533.17H265.88V535.42H511.67V533.17Z" fill="#003C71" />
      <path d="M258.26 510.92H116.28V513.17H258.26V510.92Z" fill="#003C71" />
      <path
        d="M404.37 257.81C405.127 257.81 405.74 257.197 405.74 256.44C405.74 255.683 405.127 255.07 404.37 255.07C403.613 255.07 403 255.683 403 256.44C403 257.197 403.613 257.81 404.37 257.81Z"
        fill="#003C71"
      />
    </svg>
  );
};
