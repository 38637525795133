import React from "react";

import { PageContainer } from "@byko/component-page-container";
import { Slider } from "@byko/component-slider";
import { theme } from "@byko/lib-styles";
import { TextBlockVariantOne } from "@byko/text-block-variant-one";

import { Container, ProductSliderWrapper, TextBlockWrapper, TextContainer } from "./styles";

import type { ProductSliderSectionsProps } from "./interface";

export const ProductSliderSection = ({
  heading,
  paragraph,
  collection,
  buttonLabel,
  headingUnderlineColor,
}: ProductSliderSectionsProps): JSX.Element => {
  return (
    <>
      <PageContainer
        backgroundColor={theme.palette.gray[5]}
        flexDirection="column"
        justifyContent="center"
        offsetNav={false}
      >
        <Container>
          <TextContainer>
            <TextBlockWrapper>
              <TextBlockVariantOne
                buttonLabel={buttonLabel ? buttonLabel : ""}
                buttonLink={"/vorur"}
                heading={heading ? heading : ""}
                // eslint-disable-next-line max-len
                headingUnderlineColor={headingUnderlineColor}
                paragraph={paragraph}
              />
            </TextBlockWrapper>
          </TextContainer>
        </Container>
      </PageContainer>
      <ProductSliderWrapper>
        <Slider autoPad={true} paginationGutter={true} slideCollection={collection} />
      </ProductSliderWrapper>
    </>
  );
};
