/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactDecorativeIconComponentType } from "../../../icon-types";

export const CookiesFilledDecorative: BykoReactDecorativeIconComponentType = () => {
  return (
    <svg fill="none" height="138" viewBox="0 0 138 138" width="138" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_812_6351)">
        <path
          clipRule="evenodd"
          d="M67.5228 36.2718C69.3843 30.7262 74.5748 27 80.4398 27C86.0819 27 90.9342 30.4417 92.9999 35.3327C92.8088 35.3542 92.6176 35.4015 92.4309 35.4764C91.6548 35.7881 91.1558 36.4979 91.0677 37.2849C90.6789 37.255 90.2788 37.3114 89.8941 37.4659C88.534 38.012 87.8834 39.5772 88.4413 40.9617C88.56 41.2562 88.7238 41.5188 88.9212 41.7446C88.8732 41.7608 88.8256 41.7782 88.778 41.7973C87.4179 42.3435 86.7673 43.9087 87.3252 45.2931C87.6518 46.1035 88.3197 46.6723 89.0905 46.8953C88.6604 47.497 88.5396 48.3067 88.8405 49.0534C89.071 49.6256 89.5059 50.0532 90.024 50.2835C88.5739 51.7141 86.7889 52.8288 84.7615 53.4995C82.7871 54.1527 74.6168 55.5957 70.5319 51C68.4893 48.4468 65.3213 42.8298 67.5228 36.2718Z"
          fill="#80B3D2"
          fillRule="evenodd"
        />
        <path
          d="M75.6382 40.2766C77.3303 40.2766 78.702 38.9049 78.702 37.2128C78.702 35.5206 77.3303 34.1489 75.6382 34.1489C73.9461 34.1489 72.5743 35.5206 72.5743 37.2128C72.5743 38.9049 73.9461 40.2766 75.6382 40.2766Z"
          fill="#E5F0F6"
        />
        <path
          d="M85.8508 37.2128C86.9789 37.2128 87.8933 36.2983 87.8933 35.1702C87.8933 34.0422 86.9789 33.1277 85.8508 33.1277C84.7227 33.1277 83.8082 34.0422 83.8082 35.1702C83.8082 36.2983 84.7227 37.2128 85.8508 37.2128Z"
          fill="#E5F0F6"
        />
        <path
          d="M82.2763 46.4042C83.6864 46.4042 84.8295 45.2611 84.8295 43.851C84.8295 42.441 83.6864 41.2979 82.2763 41.2979C80.8662 41.2979 79.7231 42.441 79.7231 43.851C79.7231 45.2611 80.8662 46.4042 82.2763 46.4042Z"
          fill="#0A3A5C"
        />
        <path
          clipRule="evenodd"
          d="M44.9998 55.5957C44.9998 44.8962 53.7045 36.1915 64.404 36.1915C75.1035 36.1915 83.8083 44.8962 83.8083 55.5957C83.8083 55.6133 83.8082 55.631 83.8082 55.6486C83.617 55.6138 83.4201 55.5957 83.2191 55.5957C81.9566 55.5957 80.8575 56.3103 80.2901 57.3645C79.7626 57.1037 79.1705 56.9574 78.5448 56.9574C76.3322 56.9574 74.5382 58.7864 74.5382 61.0425C74.5382 61.5224 74.6194 61.983 74.7684 62.4109C74.6921 62.4066 74.6155 62.4042 74.5382 62.4042C72.3256 62.4042 70.5317 64.2332 70.5317 66.4893C70.5317 67.8098 71.146 68.9839 72.0993 69.7307C71.1577 70.3326 70.5317 71.4002 70.5317 72.617C70.5317 73.0793 70.6221 73.5201 70.7858 73.922C68.7863 74.6203 66.6387 75 64.404 75C53.7045 75 44.9998 66.2952 44.9998 55.5957Z"
          fill="#00416A"
          fillRule="evenodd"
        />
        <path
          d="M59.2977 50.4894C60.9898 50.4894 62.3615 49.1177 62.3615 47.4256C62.3615 45.7335 60.9898 44.3618 59.2977 44.3618C57.6056 44.3618 56.2339 45.7335 56.2339 47.4256C56.2339 49.1177 57.6056 50.4894 59.2977 50.4894Z"
          fill="#E5F0F6"
        />
        <path
          d="M74.6169 51.5106C75.745 51.5106 76.6594 50.5962 76.6594 49.4681C76.6594 48.34 75.745 47.4255 74.6169 47.4255C73.4888 47.4255 72.5743 48.34 72.5743 49.4681C72.5743 50.5962 73.4888 51.5106 74.6169 51.5106Z"
          fill="#80B3D2"
        />
        <path
          d="M62.3614 69.8936C63.4895 69.8936 64.404 68.9791 64.404 67.851C64.404 66.723 63.4895 65.8085 62.3614 65.8085C61.2333 65.8085 60.3188 66.723 60.3188 67.851C60.3188 68.9791 61.2333 69.8936 62.3614 69.8936Z"
          fill="#80B3D2"
        />
        <path
          d="M54.1912 59.6808C55.3193 59.6808 56.2338 58.7663 56.2338 57.6383C56.2338 56.5102 55.3193 55.5957 54.1912 55.5957C53.0632 55.5957 52.1487 56.5102 52.1487 57.6383C52.1487 58.7663 53.0632 59.6808 54.1912 59.6808Z"
          fill="#E5F0F6"
        />
        <path
          d="M67.4679 60.7021C69.16 60.7021 70.5317 59.3304 70.5317 57.6383C70.5317 55.9462 69.16 54.5745 67.4679 54.5745C65.7758 54.5745 64.4041 55.9462 64.4041 57.6383C64.4041 59.3304 65.7758 60.7021 67.4679 60.7021Z"
          fill="#E5F0F6"
        />
        <path
          d="M82.0239 66.8433C81.6144 66.106 82.2452 65.2241 83.0752 65.3735L83.9257 65.5266C84.1869 65.5736 84.4186 65.7225 84.57 65.9404L84.9097 66.4297C85.3701 67.0928 84.8955 68 84.0883 68H83.2548C82.8917 68 82.557 67.8031 82.3807 67.4856L82.0239 66.8433Z"
          fill="#00416A"
        />
        <path
          d="M88.1725 59.6091C88.9411 59.3529 89.6793 60.0691 89.4465 60.8451L89.3361 61.2132C89.2499 61.5004 89.0395 61.7337 88.7628 61.849L87.3846 62.4232C86.7259 62.6976 86 62.2136 86 61.5001L86 61.0539C86 60.6235 86.2755 60.2413 86.6838 60.1052L88.1725 59.6091Z"
          fill="#80B3D2"
        />
        <path
          d="M79.1815 60.8081C79.9329 60.4742 80.7437 61.1393 80.5632 61.9414L80.4983 62.2298C80.4358 62.5079 80.2575 62.746 80.0083 62.8845L79.4856 63.1748C78.8191 63.545 78 63.0631 78 62.3006L78 61.983C78 61.5878 78.2328 61.2297 78.5939 61.0692L79.1815 60.8081Z"
          fill="#0A3A5C"
        />
        <path
          d="M89.8081 44.8185C89.4742 44.0671 90.1393 43.2563 90.9414 43.4368L91.2298 43.5017C91.5079 43.5642 91.746 43.7425 91.8845 43.9917L92.1748 44.5144C92.545 45.1809 92.0631 46 91.3006 46H90.983C90.5878 46 90.2297 45.7672 90.0692 45.4061L89.8081 44.8185Z"
          fill="#0A3A5C"
        />
        <path
          d="M91.315 39C91.6564 39 91.9013 39.3291 91.8032 39.6561L91.6746 40.0847C91.6274 40.2421 91.512 40.3701 91.3603 40.4333C90.8913 40.6287 90.4318 40.1363 90.659 39.682L90.8591 39.2817C90.9455 39.1091 91.1219 39 91.315 39Z"
          fill="#00416A"
        />
        <path
          d="M77.4772 68.1904C78.1942 68.1007 78.7667 68.7778 78.559 69.47L78.3361 70.2132C78.2499 70.5004 78.0395 70.7337 77.7628 70.849L76.3846 71.4232C75.7259 71.6976 75 71.2136 75 70.5001L75 69.3828C75 68.8785 75.3755 68.4531 75.876 68.3905L77.4772 68.1904Z"
          fill="#5995BE"
        />
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="138"
          id="filter0_d_812_6351"
          width="138"
          x="-0.000244141"
          y="0"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="18" />
          <feGaussianBlur stdDeviation="22.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0" />
          <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_812_6351" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_812_6351" mode="normal" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
