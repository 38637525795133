import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const CalendarCheckmarkIcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M9 15L11 17L16 12" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path
        // eslint-disable-next-line max-len
        d="M21 3H3C1.89543 3 1 3.89543 1 5V20C1 21.1046 1.89543 22 3 22H21C22.1046 22 23 21.1046 23 20V5C23 3.89543 22.1046 3 21 3Z"
        stroke={iconColor}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path d="M6 1V4" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M18 1V4" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
      <path d="M1 8H23" stroke={iconColor} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
    </svg>
  );
};
