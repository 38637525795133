import React from "react";

import { DefaultQuantityInput, MiniQuantityInput } from "./components";

import type { QuantityInputProps } from "./interface";

export const QuantityInput = ({
  quantity,
  setQuantity,
  quantityUnit = "magn stk",
  stretch = false,
  textInRow = false,
  addDisabled = false,
  uppercase = true,
  miniVersion = false,
}: QuantityInputProps): JSX.Element => {
  const handleAdd = React.useCallback(() => {
    setQuantity(quantity + 1);
  }, [quantity, setQuantity]);

  const handleSubtract = React.useCallback(() => {
    setQuantity(quantity - 1);
  }, [quantity, setQuantity]);

  const handleInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setQuantity(parseInt(e.currentTarget.value, 10));
    },
    [setQuantity],
  );

  return (
    <>
      {miniVersion ? (
        <MiniQuantityInput
          addClick={handleAdd}
          addDisabled={addDisabled}
          quantity={quantity}
          setQuantity={setQuantity}
          stretch={stretch}
          subtractClick={handleSubtract}
          onInputChange={handleInputChange}
        />
      ) : (
        <DefaultQuantityInput
          addClick={handleAdd}
          addDisabled={addDisabled}
          quantity={quantity}
          quantityUnit={quantityUnit}
          setQuantity={setQuantity}
          stretch={stretch}
          subtractClick={handleSubtract}
          textInRow={textInRow}
          uppercase={uppercase}
          onInputChange={handleInputChange}
        />
      )}
    </>
  );
};
