/* eslint-disable max-len */
import * as React from "react";

import type { BykoReactIconComponentType } from "../../icon-types";

export const DiscountPercentcons: BykoReactIconComponentType = ({ size, iconColor }) => {
  return (
    <svg fill="none" height={size} viewBox="0 0 16 16" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="M2 14L14 2" stroke={iconColor} strokeLinecap="square" strokeWidth="1.5" />
      <path
        d="M3.5 6C4.88071 6 6 4.88071 6 3.5C6 2.11929 4.88071 1 3.5 1C2.11929 1 1 2.11929 1 3.5C1 4.88071 2.11929 6 3.5 6Z"
        stroke={iconColor}
        strokeLinecap="square"
        strokeWidth="1.5"
      />
      <path
        d="M12.5 15C13.8807 15 15 13.8807 15 12.5C15 11.1193 13.8807 10 12.5 10C11.1193 10 10 11.1193 10 12.5C10 13.8807 11.1193 15 12.5 15Z"
        stroke={iconColor}
        strokeLinecap="square"
        strokeWidth="1.5"
      />
    </svg>
  );
};
